import compact from 'lodash/compact'
import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { PlusIcon, XCloseIcon, DotsGridIcon } from '@vori/gourmet-icons'

import {
  ButtonNext as Button,
  Clickable,
  colors,
  Divider,
  Flex,
  foundation,
  List,
  Popup,
  PopupClose,
  PopupContent,
  PopupTrigger,
  Select,
  sizing,
  Spacer,
  spacing,
  Text,
} from '@vori/gourmet-components'

import { DataGridGroupingMenuItem } from './DataGridGroupingMenuItem'
import { DataGridGroupingMenuRemoveButton } from './DataGridGroupingMenuRemoveButton'
import { DataGridGroupingMenuTriggerText } from './DataGridGroupingMenuTriggerText'
import { DataGridRowData, DataGridColumnDefinition } from '../../types'
import { DataGridRowGroupingHookReturn } from '../../hooks'

const DraggableItem = styled(Flex)({
  userSelect: 'none',

  '& [data-draggable-icon]': {
    cursor: 'grab',
  },

  '&.dragging [data-draggable-icon], & [data-draggable-icon]:active, & [data-draggable-icon]:focus':
    {
      cursor: 'grabbing',
    },
})

const PopupTriggerContainer = styled(Flex)(
  ({ $hasGroupedColumns }: { $hasGroupedColumns?: boolean }): CSSObject => ({
    '[data-gourmet-popup-trigger]': {
      padding: spacing.input.base,
      ...($hasGroupedColumns && {
        borderBottomRightRadius: 0,
        borderRightWidth: 0,
        borderTopRightRadius: 0,
        paddingRight: 0,
      }),
    },

    '&:focus-within': {
      borderRadius: sizing.radius.base,
      boxShadow: `0 0 0 ${sizing.focusRing} ${colors.button.focusRing.secondary}`,
    },
  }),
)

type Props<TData extends DataGridRowData> =
  DataGridRowGroupingHookReturn<TData> & {
    /**
     * AG Grid `columnDefs`.
     *
     * @see {@link https://www.ag-grid.com/react-data-grid/column-definitions/}
     */
    columnDefinitions: Array<DataGridColumnDefinition<TData>>
    withDivider?: boolean
  }

function DataGridGroupingMenu<TData extends DataGridRowData>({
  addToGroup,
  closeAllGroups,
  columnDefinitions,
  groupedColumns,
  openAllGroups,
  removeAllFromGroup,
  removeFromGroup,
  replaceColumn,
  replaceGroupedColumns,
  withDivider,
}: Props<TData>): JSX.Element | null {
  const [addColumnRowID, resetAddColumnRowID] = useClientId('add-column-row')
  const [showAddColumnRow, setAddColumnRowState] = React.useState(false)
  const draggingGroupIndex = React.useRef<number>(-1)
  const dragOverGroupIndex = React.useRef<number>(-1)

  const groupColumns = columnDefinitions.filter(
    (column) => column.enableRowGroup,
  )

  const selectOptions = groupColumns.map((columnDefinition) => ({
    id: columnDefinition.colId as string,
    name: columnDefinition.headerName as string,
  }))

  const selectedOptionIDs = groupedColumns.map((groupedColumn) =>
    groupedColumn.getColId(),
  )

  const groupedColumnNames = compact(
    groupedColumns.map((groupedColumn) => groupedColumn.getColDef().headerName),
  )

  const hasGroupedColumns = Boolean(groupedColumns.length)

  const [clonedGroupColumns, setClonedGroupColumns] =
    React.useState(groupedColumns)

  React.useEffect(() => {
    setClonedGroupColumns(groupedColumns)
  }, [groupedColumns])

  if (!groupColumns.length) {
    return null
  }

  return (
    <>
      <Popup>
        <PopupTriggerContainer $hasGroupedColumns={hasGroupedColumns}>
          <PopupTrigger
            isActive={hasGroupedColumns}
            noFocusRing
            rightIcon={!hasGroupedColumns ? <PlusIcon /> : null}
          >
            <DataGridGroupingMenuTriggerText
              groupedColumnNames={groupedColumnNames}
              groupedColumnsCount={groupedColumns.length}
              hasGroupedColumns={hasGroupedColumns}
            />
          </PopupTrigger>
          {hasGroupedColumns && (
            <DataGridGroupingMenuRemoveButton onClick={removeAllFromGroup} />
          )}
        </PopupTriggerContainer>

        <PopupContent
          column
          noPadding={!hasGroupedColumns}
          style={{
            padding: hasGroupedColumns ? foundation.spacing.small : 0,
            width: 420,
          }}
        >
          {!hasGroupedColumns ? (
            <>
              <Spacer size="tiny" />
              <Flex centerY fullWidth justifyContent="space-between">
                <Flex fullWidth>
                  <Spacer inline size="tiny" />
                  <Text variant="secondary">Group By</Text>
                  <Spacer inline size="tiny" />
                </Flex>
                <PopupClose>
                  <Button size="small">Cancel</Button>
                </PopupClose>
                <Spacer inline size="tiny" />
              </Flex>
              <Spacer size="tiny" />
              <Divider />
              {groupColumns.length ? (
                <List maxHeight="medium">
                  <Spacer size="tiny" />
                  {groupColumns.map((columnDefinition) => (
                    <DataGridGroupingMenuItem
                      disableFocusRing
                      key={`data-grid-grouping-menu-item-${columnDefinition.colId}`}
                      onClick={() => {
                        addToGroup(columnDefinition.colId as string)
                      }}
                    >
                      {columnDefinition.headerName}
                    </DataGridGroupingMenuItem>
                  ))}
                  <Spacer size="tiny" />
                </List>
              ) : (
                <Flex center column flex={1}>
                  <Spacer />
                  <Text align="center" variant="secondary">
                    There are no columns currently able <br /> to be grouped at
                    this time.
                  </Text>
                  <Spacer />
                </Flex>
              )}
            </>
          ) : (
            <Flex column fullWidth>
              <Flex centerY fullWidth justifyContent="space-between">
                <Flex shrink={0}>
                  <Text>Group By</Text>
                </Flex>
                <Flex fullWidth justifyContent="flex-end">
                  <Spacer inline />
                  <Clickable size="small" onClick={openAllGroups}>
                    Expand All
                  </Clickable>
                  <Clickable size="small" onClick={closeAllGroups}>
                    Collapse All
                  </Clickable>
                </Flex>
              </Flex>

              <Spacer size="small" />

              {clonedGroupColumns.map((groupedColumn, index) => {
                const columnID = groupedColumn.getColId()

                return (
                  <DraggableItem
                    key={`data-grid-grouping-menu-select-${columnID}`}
                    column
                    draggable
                    fullWidth
                    onDragStart={(event) => {
                      draggingGroupIndex.current = index
                      event.dataTransfer.effectAllowed = 'move'
                    }}
                    onDragOver={(event) => {
                      event.preventDefault()
                      event.dataTransfer.dropEffect = 'move'
                    }}
                    onDragEnter={() => {
                      dragOverGroupIndex.current = index
                      const updatedClonedGroupColumns = [...clonedGroupColumns]

                      const draggingGroupColumn =
                        updatedClonedGroupColumns[draggingGroupIndex.current]

                      updatedClonedGroupColumns.splice(
                        draggingGroupIndex.current,
                        1,
                      )

                      updatedClonedGroupColumns.splice(
                        dragOverGroupIndex.current,
                        0,
                        draggingGroupColumn,
                      )

                      draggingGroupIndex.current = dragOverGroupIndex.current
                      dragOverGroupIndex.current = -1

                      setClonedGroupColumns(updatedClonedGroupColumns)
                    }}
                    onDragEnd={() => {
                      replaceGroupedColumns(clonedGroupColumns)
                    }}
                  >
                    <Flex centerY>
                      <Select
                        label="Group by"
                        fullWidth
                        options={selectOptions}
                        getDisabledOptions={(options) =>
                          options.filter(({ id }) =>
                            selectedOptionIDs.includes(id),
                          )
                        }
                        getLabelForTrigger={(_, { originalOptionSelected }) =>
                          originalOptionSelected?.name || 'Group by'
                        }
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        getOriginalOption={(value) =>
                          selectOptions.find(({ id }) => value === id) || null
                        }
                        selectedOption={columnID}
                        onChange={({ state }) => {
                          if (state.selectedOption) {
                            replaceColumn(columnID, state.selectedOption)
                          }
                        }}
                        onMouseDown={(event) => {
                          event.stopPropagation()
                        }}
                        position="matchWidth"
                      />
                      <Spacer size="tiny" inline />
                      <Clickable
                        iconOnly
                        leftIcon={<XCloseIcon />}
                        size="small"
                        onClick={() => {
                          removeFromGroup(columnID)
                        }}
                      />
                      {groupedColumns.length > 1 && (
                        <>
                          <Spacer size="tiny" inline />
                          <DotsGridIcon data-draggable-icon />
                        </>
                      )}
                    </Flex>
                    <Spacer size="small" />
                  </DraggableItem>
                )
              })}

              {showAddColumnRow &&
                groupedColumns.length < groupColumns.length && (
                  <>
                    <Flex>
                      <Select
                        label="Group by"
                        fullWidth
                        key={addColumnRowID}
                        options={selectOptions}
                        getDisabledOptions={(options) =>
                          options.filter(({ id }) =>
                            selectedOptionIDs.includes(id),
                          )
                        }
                        getLabelForTrigger={(_, { originalOptionSelected }) =>
                          originalOptionSelected?.name || 'Group by'
                        }
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        getOriginalOption={(value) =>
                          selectOptions.find(({ id }) => value === id) || null
                        }
                        onChange={({ state }) => {
                          if (state.selectedOption) {
                            addToGroup(state.selectedOption)
                            resetAddColumnRowID()
                          }
                        }}
                        onMouseDown={(event) => {
                          event.stopPropagation()
                        }}
                        position="matchWidth"
                      />
                      <Spacer size="tiny" />
                      <Clickable
                        iconOnly
                        leftIcon={<XCloseIcon />}
                        size="small"
                        onClick={() => {
                          setAddColumnRowState(false)
                        }}
                      />
                    </Flex>
                    <Spacer size="small" />
                  </>
                )}

              {!showAddColumnRow &&
              groupedColumns.length < groupColumns.length ? (
                <Flex centerY justifyContent="space-between">
                  <Button
                    fullWidth
                    rightIcon={<PlusIcon />}
                    onClick={() => {
                      setAddColumnRowState(true)
                    }}
                    variant="secondary"
                  >
                    Add Subgroup
                  </Button>
                  <Spacer inline size="tiny" />
                  <PopupClose>
                    <Button>Close</Button>
                  </PopupClose>
                </Flex>
              ) : (
                <PopupClose>
                  <Button fullWidth>Close</Button>
                </PopupClose>
              )}
            </Flex>
          )}
        </PopupContent>
      </Popup>

      {withDivider && (
        <>
          <Spacer inline size="small" />
          <Divider orientation="vertical" />
          <Spacer inline size="small" />
        </>
      )}
    </>
  )
}

export { DataGridGroupingMenu }
export type { Props as DataGridGroupingMenuProps }
