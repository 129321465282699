import React from 'react'
import { CSSObject } from 'styled-components'
import { renderToString } from 'react-dom/server'

import { foundations } from '@vori/gourmet-tokens'

import { toRem } from '../utils'
import { ButtonProps, ButtonDimensions, ButtonSizing } from './types'

export const dimensions: Record<ButtonSizing, ButtonDimensions> = {
  small: {
    horizontalPadding: foundations.space['space.075'],
    minHeight: 36,
    verticalPadding: foundations.space['space.050'],
  },

  default: {
    horizontalPadding: foundations.space['space.100'],
    minHeight: 40,
    verticalPadding: toRem(10),
  },

  large: {
    horizontalPadding: toRem(18),
    minHeight: 44,
    verticalPadding: toRem(10),
  },

  xLarge: {
    horizontalPadding: foundations.space['space.125'],
    minHeight: 48,
    verticalPadding: foundations.space['space.075'],
  },

  xxLarge: {
    horizontalPadding: toRem(28),
    minHeight: 60,
    verticalPadding: foundations.space['space.100'],
  },
}

export function cssObjectFromDimensions(
  callback: (sizing: ButtonSizing, dimensions: ButtonDimensions) => CSSObject,
): CSSObject {
  return Array.from(Object.entries(dimensions)).reduce(
    (cssObject, [sizing, dimensions]) => ({
      ...cssObject,
      ...callback(sizing as ButtonSizing, dimensions),
    }),
    {},
  )
}

export function svgIconToURL(
  icon: React.ReactElement | null,
  props: Partial<ButtonProps>,
): string {
  if (!icon) {
    return ''
  }

  return `url(data:image/svg+xml;utf8,${encodeURI(renderToString(icon))
    .replace(
      'currentColor',
      props.disabled && props.variant === 'secondary'
        ? '#F4F4F5'
        : props.disabled &&
            (props.variant === 'primary' || props.variant === 'destructive')
          ? '#FFFFFF'
          : props.variant === 'secondary' ||
              props.variant === 'primary' ||
              props.variant === 'destructive'
            ? '#FFFFFF'
            : props.disabled
              ? '#D1D1D6'
              : '#3F3F46',
    )
    .replace('#', '%23')})`
}

export function getBackgroundCSSProperties(
  props: Partial<ButtonProps>,
): CSSObject {
  return {
    backgroundImage: [
      props.leftIcon && svgIconToURL(props.leftIcon || null, props),
      props.rightIcon && svgIconToURL(props.rightIcon || null, props),
    ]
      .filter(Boolean)
      .join(','),
    backgroundPosition: [
      props.leftIcon &&
        `${
          !props.noPadding
            ? dimensions[props.sizing || 'default'].horizontalPadding
            : 0
        } center`,
      props.rightIcon &&
        `${
          !props.noPadding
            ? `calc(100% - ${
                dimensions[props.sizing || 'default'].horizontalPadding
              })`
            : '100%'
        } center`,
    ]
      .filter(Boolean)
      .join(','),
  }
}
