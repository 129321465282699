import { foundations } from '@vori/gourmet-tokens'
import React from 'react'
import styled from 'styled-components'

import { Flex } from '../FlexNext'
import { propsToStyledProps, PropsToStyledProps } from '../utils'
import { SpacerCoreProps, SpacerProps } from './types'
import { spacerStyles } from './styles'

const StyledSpacer = styled(Flex)(
  (props: PropsToStyledProps<SpacerCoreProps>) => ({
    ...spacerStyles(props),
    height: foundations.space[props.$size || 'space.100'],
  }),
)

const defaultProps: Partial<SpacerCoreProps> = {
  size: 'space.100',
}

/**
 * A `<Spacer>` provides a way to add spacing between components without having
 * to alter the component's styling by adding `margin`.
 *
 * @example
 * <Flex column fullWidth>
 *   <Flex>Row 1</Flex>
 *   <Spacer />
 *   <Flex>Row 2</Flex>
 *   <Spacer />
 *   <Flex>Row 3</Flex>
 * </Flex>
 */
const Spacer = React.forwardRef<HTMLDivElement, SpacerProps>(function Spacer(
  { size = defaultProps.size, ...props }: SpacerProps,
  ref,
): JSX.Element {
  return (
    <StyledSpacer
      {...propsToStyledProps({ size })}
      {...props}
      aria-hidden="true"
      data-gourmet-spacer=""
      ref={ref}
      shrink={0}
      tabIndex={-1}
    />
  )
})

Spacer.displayName = 'Spacer'
Spacer.defaultProps = defaultProps

export { Spacer, defaultProps as spacerDefaultProps }
