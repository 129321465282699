import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Flex from '../Flex'
import Logo from '../Logo'

import { colors, Size, sizing } from '../tokens'

type ContainedLogoColorVariant =
  keyof typeof colors.containedLogo.backgroundColor

type ContainedLogoProps = React.SVGAttributes<HTMLDivElement> & {
  size?: keyof typeof sizing.logo
  variant?: ContainedLogoColorVariant
}

const defaultProps: Partial<ContainedLogoProps> = {
  className: '',
  size: 'base',
  variant: 'primary',
}

const Container = styled(Flex)<ContainedLogoProps>`
  background-color: ${({ variant = defaultProps.variant }): string =>
    colors.containedLogo.backgroundColor[variant as ContainedLogoColorVariant]};
  border-radius: calc(
    ${({ size = defaultProps.size }): string => sizing.logo[size as Size]} *
      0.65
  );
  height: calc(
    ${({ size = defaultProps.size }): string => sizing.logo[size as Size]} *
      1.85
  );
  width: calc(
    ${({ size = defaultProps.size }): string => sizing.logo[size as Size]} *
      1.85
  );
`

const StyledContainedLogo = styled(
  forwardRef<HTMLDivElement, ContainedLogoProps>(function ContainedLogo(
    { className, size, variant }: ContainedLogoProps,
    ref,
  ) {
    return (
      <Container
        center
        className={className}
        inline
        ref={ref}
        shrink={0}
        size={size}
        variant={variant}
      >
        <Logo size={size} variant="secondary" />
      </Container>
    )
  }),
)``

StyledContainedLogo.displayName = 'ContainedLogo'
StyledContainedLogo.defaultProps = defaultProps

export type { ContainedLogoProps }
export { defaultProps as ContainedLogoDefaultProps }
export default StyledContainedLogo
