import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChartSquare02IconProps = IconProps

const BarChartSquare02Icon = forwardRef<
  SVGSVGElement,
  BarChartSquare02IconProps
>((props: BarChartSquare02IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 15v2m4-6v6m4-10v10m-8.2 4h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 3 17.88 3 16.2 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21Z"
      fill="none"
    ></path>
  </Icon>
))

BarChartSquare02Icon.displayName = 'BarChartSquare02Icon'
BarChartSquare02Icon.defaultProps = iconDefaultProps

export type { BarChartSquare02IconProps }
export { BarChartSquare02Icon }
