import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleDownLeftIconProps = IconProps

const ArrowCircleDownLeftIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleDownLeftIconProps
>((props: ArrowCircleDownLeftIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 9v6m0 0h6m-6 0 6-6m7 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleDownLeftIcon.displayName = 'ArrowCircleDownLeftIcon'
ArrowCircleDownLeftIcon.defaultProps = iconDefaultProps

export type { ArrowCircleDownLeftIconProps }
export { ArrowCircleDownLeftIcon }
