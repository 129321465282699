import React from 'react'
import styled from 'styled-components'

import { Text, TextProps } from '../TextNext'

import { AvatarTextProps } from './types'

const StyledText = styled(Text)``

function getTextProps(
  avatarSize: AvatarTextProps['avatarSize'],
): Pick<TextProps, 'size' | 'weight'> {
  switch (avatarSize) {
    case 'xs': {
      return { size: 'text-xs', weight: 'medium' }
    }

    case 'sm': {
      return { size: 'text-sm', weight: 'medium' }
    }

    case 'md': {
      return { size: 'text-md', weight: 'medium' }
    }

    case 'lg': {
      return { size: 'text-lg', weight: 'medium' }
    }

    case 'xl': {
      return { size: 'text-xl', weight: 'medium' }
    }

    case 'xxl': {
      return { size: 'heading-xs', weight: 'medium' }
    }

    default: {
      return {}
    }
  }
}

function AvatarText({
  avatarSize,
  children,
  ...props
}: React.PropsWithChildren<AvatarTextProps>): JSX.Element {
  return (
    <StyledText
      {...props}
      {...getTextProps(avatarSize)}
      data-gourmet-avatar-text=""
      decoration="none"
      forwardedAs="abbr"
      transform="uppercase"
    >
      {children}
    </StyledText>
  )
}

AvatarText.displayName = 'AvatarText'

export { AvatarText }
