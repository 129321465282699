import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Rocket02IconProps = IconProps

const Rocket02Icon = forwardRef<SVGSVGElement, Rocket02IconProps>(
  (props: Rocket02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m12 15-3-3m3 3a22.355 22.355 0 0 0 4-2m-4 2v5s3.03-.55 4-2c1.08-1.62 0-5 0-5m-7-1a21.999 21.999 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11m-7-1H4s.55-3.03 2-4c1.62-1.08 5 0 5 0m-6.5 8.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Rocket02Icon.displayName = 'Rocket02Icon'
Rocket02Icon.defaultProps = iconDefaultProps

export type { Rocket02IconProps }
export { Rocket02Icon }
