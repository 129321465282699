import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudSun02IconProps = IconProps

const CloudSun02Icon = forwardRef<SVGSVGElement, CloudSun02IconProps>(
  (props: CloudSun02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.5 1.5v1.6M3.6 10H2m3.451-5.049L4.32 3.82m11.23 1.131 1.13-1.131M19 10h-1.6M6.5 10A4 4 0 0 1 14 8.062M6 22a4 4 0 1 1 1.324-7.775 5.002 5.002 0 0 1 9.352 0A4 4 0 1 1 18 22H6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudSun02Icon.displayName = 'CloudSun02Icon'
CloudSun02Icon.defaultProps = iconDefaultProps

export type { CloudSun02IconProps }
export { CloudSun02Icon }
