import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DotsHorizontalIconProps = IconProps

const DotsHorizontalIcon = forwardRef<SVGSVGElement, DotsHorizontalIconProps>(
  (props: DotsHorizontalIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

DotsHorizontalIcon.displayName = 'DotsHorizontalIcon'
DotsHorizontalIcon.defaultProps = iconDefaultProps

export type { DotsHorizontalIconProps }
export { DotsHorizontalIcon }
