import React from 'react'
import { FullStory, isInitialized } from '@fullstory/browser'

import { ENV_LOCAL } from '@vori/dashboard-env'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

export function useFullStory(): void {
  const { user } = useCurrentUserState()

  // Sets current user information for FullStory when available.
  React.useEffect(() => {
    if (ENV_LOCAL || !isInitialized() || !user.state.isLoggedIn) {
      return
    }

    FullStory('setIdentity', {
      uid: user.data.id,
      properties: {
        companyID: user.data.companyID,
        companyName: user.data.companyName,
        email: user.data.email || '',
        username: user.data.displayName,
      },
    })
  }, [
    user.data.companyID,
    user.data.companyName,
    user.data.displayName,
    user.data.email,
    user.data.id,
    user.state.isLoggedIn,
  ])

  // Removes current user information from FullStory when the user logs out.
  React.useEffect(() => {
    if (ENV_LOCAL || !isInitialized() || user.state.isLoggedIn) {
      return
    }

    FullStory('setIdentity', { anonymous: true })
  }, [user.state, user.state.isLoggedIn])
}
