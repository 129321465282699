import { createRouteUtil } from '@vori/dashboard-utils'

const routes = {
  login: {
    root: createRouteUtil('/auth/login', 'Login'),
  },
  logout: {
    root: createRouteUtil('/auth/logout', 'Logout'),
  },
  passwordRecovery: {
    root: createRouteUtil('/auth/password-recovery', 'Password Recovery'),
  },
  vendorInvitation: {
    root: createRouteUtil(
      '/auth/vendor-invitation/:inviteCode',
      'Vendor Invitation',
    ),
  },
}

export { routes }
