import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LineChartUp02IconProps = IconProps

const LineChartUp02Icon = forwardRef<SVGSVGElement, LineChartUp02IconProps>(
  (props: LineChartUp02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 20.24 3 19.96 3 19.4V3m18 4-5.434 5.434c-.198.198-.297.297-.412.334a.499.499 0 0 1-.309 0c-.114-.037-.213-.136-.41-.334l-1.87-1.868c-.197-.198-.296-.297-.41-.334a.499.499 0 0 0-.31 0c-.114.037-.213.136-.41.334L7 15m14-8h-4m4 0v4"
        fill="none"
      ></path>
    </Icon>
  ),
)

LineChartUp02Icon.displayName = 'LineChartUp02Icon'
LineChartUp02Icon.defaultProps = iconDefaultProps

export type { LineChartUp02IconProps }
export { LineChartUp02Icon }
