import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Upload01IconProps = IconProps

const Upload01Icon = forwardRef<SVGSVGElement, Upload01IconProps>(
  (props: Upload01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 15v1.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 18.72 3 17.88 3 16.2V15m14-7-5-5m0 0L7 8m5-5v12"
        fill="none"
      ></path>
    </Icon>
  ),
)

Upload01Icon.displayName = 'Upload01Icon'
Upload01Icon.defaultProps = iconDefaultProps

export type { Upload01IconProps }
export { Upload01Icon }
