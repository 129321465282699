import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Repeat03IconProps = IconProps

const Repeat03Icon = forwardRef<SVGSVGElement, Repeat03IconProps>(
  (props: Repeat03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m13 22-3-3m0 0 3-3m-3 3h5a7 7 0 0 0 3-13.326M6 18.326A7 7 0 0 1 9 5h5m0 0-3-3m3 3-3 3"
        fill="none"
      ></path>
    </Icon>
  ),
)

Repeat03Icon.displayName = 'Repeat03Icon'
Repeat03Icon.defaultProps = iconDefaultProps

export type { Repeat03IconProps }
export { Repeat03Icon }
