import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Link03IconProps = IconProps

const Link03Icon = forwardRef<SVGSVGElement, Link03IconProps>(
  (props: Link03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
        fill="none"
      ></path>
    </Icon>
  ),
)

Link03Icon.displayName = 'Link03Icon'
Link03Icon.defaultProps = iconDefaultProps

export type { Link03IconProps }
export { Link03Icon }
