import React from 'react'
import styled from 'styled-components'

import { CardBannerCoreProps, CardBannerProps } from './types'
import { cardBannerStyles } from './styles'
import { Flex, flexDefaultProps } from '../FlexNext'

const StyledCardBanner = styled(Flex)(cardBannerStyles)

const defaultProps: Partial<CardBannerCoreProps> = {
  ...flexDefaultProps,
  variant: 'default',
}

/**
 * The `<CardBanner>` component is used to provide a title for `<Card>`
 * components. Use the `variant` prop to denote an intention for the `<Card>`
 * component, e.g. if the `<Card>` component is being used to display errors,
 * then use the `error` `variant` on the `<CardBanner>` component.
 *
 * @extends Flex
 * @example
 * <Card asFlat>
 *   <CardBanner variant="error">
 *     <CardBannerText>Found 2 errors</CardBannerText>
 *   </CardBanner>
 *   <ol>
 *     <li>Error 1</li>
 *     <li>Error 2</li>
 *   <ol>
 * </Card>
 */
const CardBanner = React.forwardRef<HTMLDivElement, CardBannerProps>(
  function CardBanner(
    { children, variant = 'default', ...props }: CardBannerProps,
    ref,
  ): JSX.Element {
    return (
      <StyledCardBanner
        {...props}
        alignItems="center"
        data-gourmet-card-banner=""
        data-variant={variant}
        gap="space.075"
        ref={ref}
      >
        {children}
      </StyledCardBanner>
    )
  },
)

CardBanner.displayName = 'CardBanner'
CardBanner.defaultProps = defaultProps

export { CardBanner, defaultProps as cardBannerDefaultProps }
