import { CSSObject } from 'styled-components'
import { merge } from 'lodash'
import { XCloseIcon } from '@vori/gourmet-icons'
import React from 'react'
import styled from 'styled-components'

import { Button } from '../ButtonNext'
import { CheckboxInput } from '../CheckboxInput'
import { DotIndicator } from '../DotIndicator'
import Flex from '../Flex'

import { composeEventHandlers, toRem } from '../utils'
import { useControlledState } from '../hooks'

import { cssSelectorForEachSize } from './utils'
import { TAG_DEFAULT_SIZING } from './constants'
import { TagProps } from './types'
import { TagTokens } from './tokens'

function styles(): CSSObject {
  return merge(
    {
      backgroundColor: TagTokens.color.root.backgroundColor,
      borderColor: TagTokens.color.root.borderColor,
      borderRadius: TagTokens.sizing.root.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      color: TagTokens.color.root.color,
      cursor: 'default',
      gap: toRem(4),
      transition: 'border-color 350ms cubic-bezier(0.215, 0.61, 0.355, 1)',

      ...cssSelectorForEachSize((sizing) => ({
        fontSize: TagTokens.typography.root[sizing].fontSize,
        fontWeight: TagTokens.typography.root[sizing].fontWeight,
        lineHeight: TagTokens.typography.root[sizing].lineHeight,
        minHeight: TagTokens.sizing.root[sizing].minHeight,
        paddingLeft: TagTokens.spacing.root[sizing].paddingLeft,
        paddingRight: TagTokens.spacing.root[sizing].paddingRight,
      })),

      '&[data-clickable]': {
        cursor: 'pointer',

        '&:hover': {
          borderColor: '#A0A0AB',
        },
      },

      [[
        '&[data-selectable][data-selected="true"]',
        '&[data-checkable][data-checked="true"]',
      ].join(',')]: {
        borderColor: '#A0A0AB',
      },

      '&[data-removable], &[data-has-counter]': {
        paddingRight: toRem(4),
      },

      '&[data-checkable]': {
        paddingLeft: toRem(4),
      },

      '[data-gourmet-tag-content]': {
        flexShrink: 0,
      },

      [[
        '[data-gourmet-tag-left-dot-indicator]',
        '&[data-has-counter] [data-gourmet-tag-right-dot-indicator]',
      ].join(',')]: {
        marginRight: toRem(2),
      },

      [[
        '&[data-checkable] [data-gourmet-tag-left-dot-indicator]',
        '[data-gourmet-tag-right-dot-indicator]',
      ].join(',')]: {
        marginLeft: toRem(2),
      },

      '[data-gourmet-tag-left-icon], [data-gourmet-tag-right-icon]': {
        '&, [data-gourmet-icon]': {
          flexShrink: 0,
          height: toRem(16),
          width: toRem(16),
        },
      },

      '[data-gourmet-tag-counter]': {
        backgroundColor: '#F4F4F5',
        borderRadius: toRem(3),
        color: '#3F3F46',
        flexShrink: 0,
        fontSize: toRem(14),
        fontWeight: 500,
        lineHeight: toRem(20),
        paddingLeft: toRem(4),
        paddingRight: toRem(4),
      },

      '[data-gourmet-tag-remove-button]': {
        '&, [data-gourmet-icon]': {
          color: '#A0A0AB',
        },
      },
    },
    {
      '&[data-sizing="small"]': {
        '[data-gourmet-checkbox-input-control]': {
          height: toRem(14),
          width: toRem(14),
        },

        '[data-gourmet-tag-counter]': {
          fontSize: toRem(12),
          height: toRem(16),
          lineHeight: toRem(18),
          minWidth: toRem(16),
        },

        '[data-gourmet-tag-remove-button] [data-gourmet-icon]': {
          height: toRem(10),
          width: toRem(10),

          path: {
            strokeWidth: toRem(4),
          },
        },
      },

      '&[data-sizing="medium"]': {
        '[data-gourmet-checkbox-input-control]': {
          height: toRem(16),
          width: toRem(16),
        },

        '[data-gourmet-tag-counter]': {
          fontSize: toRem(12),
          height: toRem(18),
          lineHeight: toRem(18),
          minWidth: toRem(18),
        },

        '[data-gourmet-tag-remove-button] [data-gourmet-icon]': {
          height: toRem(12),
          width: toRem(12),

          path: {
            strokeWidth: toRem(3),
          },
        },
      },

      '&[data-sizing="large"]': {
        '[data-gourmet-checkbox-input-control]': {
          height: toRem(18),
          width: toRem(18),
        },

        '[data-gourmet-tag-counter]': {
          height: toRem(20),
          minWidth: toRem(20),
        },

        '[data-gourmet-tag-remove-button] [data-gourmet-icon]': {
          height: toRem(14),
          width: toRem(14),

          path: {
            strokeWidth: toRem(3),
          },
        },
      },
    },
  )
}

const TagFlexContainer = styled(Flex)(styles)

/**
 * Tags are compact elements that allow users to enter information, make selections,
 * filter content, or trigger actions. They’re similar to badges, but are more
 * commonly used in inputs to allow users to select multiple items at once.
 *
 * @example
 * <Tag aria-label="Click to add 'Active' as a possible product status." asClickable>
 *  Active
 * </Tag>
 *
 * ### Interactive vs. Non-interactive
 *
 * A `<Tag>` component is considered "interactive" when using one or more of the
 * following props:
 *
 * - `asCheckable`
 * - `asClickable`
 * - `asRemovable`
 * - `asSelectable`
 *
 * Otherwise, the `<Tag>` component is considered "non-interactive", i.e. just
 * meant to provide contextual and/or additional information.
 *
 * ### Accessibility
 *
 * When a `<Tag>` component is **interactive**, it's important to provide more context
 * to screen readers and other assistive technology through the `aria-label` or
 * `aria-labelledby` properties.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
 *
 * When a `<Tag>` component is **non-interactive**, it's important to provide more context,
 * **if needed**, to screen readers and other assistive technology through the `aria-description` or
 * `aria-describedby` properties.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
 */
const Tag = React.forwardRef<
  HTMLSpanElement,
  React.PropsWithChildren<TagProps>
>(function Tag(
  {
    asCheckable,
    asClickable,
    asRemovable,
    asSelectable,
    children,
    counter,
    isChecked: controlledIsChecked,
    isSelected: controlledIsSelected,
    label,
    leftDotIndicator,
    leftIcon,
    onTagChecked,
    onTagClick,
    onTagRemove,
    onTagSelect,
    rightDotIndicator,
    rightIcon,
    sizing = TAG_DEFAULT_SIZING,
    ...props
  }: React.PropsWithChildren<TagProps>,
  ref,
): JSX.Element {
  if (process.env.NODE_ENV !== 'production') {
    if (asCheckable && (asClickable || asSelectable)) {
      throw new Error(
        'The `asCheckable` cannot be used alongside the `asClickable` and `asSelectable` props.',
      )
    }

    if (asSelectable && (asClickable || asRemovable || asCheckable)) {
      throw new Error(
        'The `asSelectable` cannot be used alongside the `asClickable`, `asRemovable` and `asCheckable` props.',
      )
    }
  }

  const [isSelected, setIsSelected] = useControlledState({
    componentName: 'Tag',
    controlledValue: controlledIsSelected,
    defaultValue: false,
  })

  const [isChecked, setIsChecked] = useControlledState({
    componentName: 'Tag',
    controlledValue: controlledIsChecked,
    defaultValue: false,
  })

  const asClickableProps = React.useMemo<
    Partial<TagProps & { [dataAttribute: `data-${string}`]: string }>
  >(
    () => ({
      'data-clickable': '',
      onClick: onTagClick,
      role: 'button',
      tabIndex: 0,
    }),
    [onTagClick],
  )

  const asRemovableProps = React.useMemo<
    Partial<TagProps & { [dataAttribute: `data-${string}`]: string }>
  >(
    () => ({
      'data-removable': '',
    }),
    [],
  )

  const asSelectableProps = React.useMemo<
    Partial<TagProps & { [dataAttribute: `data-${string}`]: string }>
  >(
    () => ({
      ...(!isSelected && {
        ...asClickableProps,
        onClick: composeEventHandlers(onTagClick, (event) => {
          setIsSelected(true)
          onTagSelect?.(event, true)
        }),
      }),
      ...(isSelected && { 'data-removable': '' }),
      'data-selectable': '',
      'data-selected': isSelected ? 'true' : 'false',
    }),
    [asClickableProps, isSelected, onTagClick, onTagSelect, setIsSelected],
  )

  const asCheckableProps = React.useMemo<
    Partial<TagProps & { [dataAttribute: `data-${string}`]: string }>
  >(
    () => ({
      'data-checkable': '',
      'data-checked': isChecked ? 'true' : 'false',
    }),
    [isChecked],
  )

  return (
    <TagFlexContainer
      {...props}
      {...(counter !== undefined && { 'data-has-counter': '' })}
      {...(asClickable && asClickableProps)}
      {...(asRemovable && asRemovableProps)}
      {...(asSelectable && asSelectableProps)}
      {...(asCheckable && asCheckableProps)}
      centerY
      data-gourmet-tag=""
      data-sizing={sizing}
      inline
      ref={ref}
    >
      {asCheckable && (
        <CheckboxInput
          checked={isChecked}
          label=""
          noFocusRing
          onChange={composeEventHandlers(onTagChecked, (event) => {
            setIsChecked(event.target.checked)
          })}
          onMouseUp={(event) => {
            event.stopPropagation()
          }}
        />
      )}
      {leftDotIndicator && (
        <DotIndicator
          {...(typeof leftDotIndicator !== 'boolean' && leftDotIndicator)}
          data-gourmet-tag-left-dot-indicator=""
        />
      )}
      {leftIcon && (
        <span aria-hidden="true" data-gourmet-tag-left-icon="">
          {leftIcon}
        </span>
      )}
      {children}
      {rightIcon && (
        <span aria-hidden="true" data-gourmet-tag-right-icon="">
          {rightIcon}
        </span>
      )}
      {rightDotIndicator && (
        <DotIndicator
          {...(typeof rightDotIndicator !== 'boolean' && rightDotIndicator)}
          data-gourmet-tag-right-dot-indicator=""
        />
      )}
      {counter !== undefined && (
        <Flex center data-gourmet-tag-counter="" inline>
          {counter}
        </Flex>
      )}
      {(asRemovable || (asSelectable && isSelected)) && (
        <Button
          aria-label="Remove"
          asClickable
          asIconButton
          data-gourmet-tag-remove-button=""
          leftIcon={<XCloseIcon />}
          noFocusRing
          noPadding
          onClick={composeEventHandlers(onTagRemove, (event) => {
            if (asSelectable) {
              setIsSelected(false)
              onTagSelect?.(event, false)
            }
          })}
          sizing="small"
        />
      )}
    </TagFlexContainer>
  )
})

Tag.displayName = 'Tag'
Tag.defaultProps = {}

export { Tag, styles as tagStyles }
