import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FilePlus01IconProps = IconProps

const FilePlus01Icon = forwardRef<SVGSVGElement, FilePlus01IconProps>(
  (props: FilePlus01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 10.5V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22H12m6-1v-6m-3 3h6"
        fill="none"
      ></path>
    </Icon>
  ),
)

FilePlus01Icon.displayName = 'FilePlus01Icon'
FilePlus01Icon.defaultProps = iconDefaultProps

export type { FilePlus01IconProps }
export { FilePlus01Icon }
