import { useFuzzySearch, usePreviousValue } from '@vori/react-hooks'
import React, { useCallback, useEffect, useMemo, useReducer } from 'react'

import { Flex } from '../../FlexNext'
import { ListItemMemo, ListProps } from '../../List'
import { Text } from '../../TextNext'
import ListBox, { ListBoxProps } from '../../ListBox'
import SelectRemoveValueTrigger from './RemoveValueTrigger'
import VirtualOption from './VirtualOption'

import {
  useGetOriginalOption,
  useGetOptionDisabled,
  useGetOptionLabel,
  useGetOptionLoading,
  useGetOptionLoadingLabel,
  useOnSearchInputChange,
  useOnSearchInputKeyDown,
  useRenderFooter,
  useRenderHeader,
  useRenderLoadingOptionForSelect,
} from '../utils'

import { assignRef } from '../../utils'
import { getDefaultReducerState, reducer } from './utils/reducer'
import { optionIsSelected } from './utils/reducer-utils'

import {
  Internals,
  OptionData,
  Props,
  ReducerAction,
  ReducerState,
} from './utils/types'

const DEFAULT_CONTENT_ROW_HEIGHT = 44

function Select<T>({
  canToggleOptions,
  disableEndIndicator,
  emptyStateMessage,
  endIndicatorProps,
  errorStateMessage,
  fuzzySearchOptions,
  getDisabledOptions,
  getLabelForTrigger: defaultGetLabelForTrigger,
  getOptionDisabled: defaultGetOptionDisabled,
  getOptionKey,
  getOptionLabel: defaultGetOptionLabel,
  getOptionLoading: defaultGetOptionLoading,
  getOptionLoadingLabel: defaultGetOptionLoadingLabel,
  getOptionMenuItemProps,
  getOptionValue,
  getOriginalOption: defaultGetOriginalOption,
  initialState,
  label,
  listProps: defaultListProps,
  loaderProps,
  onChange,
  options: defaultOptions,
  removeButtonProps,
  renderFooter: defaultRenderFooter,
  renderHeader: defaultRenderHeader,
  renderLoadingOption: defaultRenderLoadingOption,
  renderOption: defaultRenderOption,
  searchInputProps,
  selectedOption,
  showEmpty: defaultShowEmpty,
  showEmptyState: defaultShowEmptyState,
  showErrorState,
  showInitialLoadingState,
  showLoadingState,
  virtualizeOptions,
  withFuzzySearch,
  withRemoveButton,
  ...props
}: Props<T>): JSX.Element {
  const [state, dispatch] = useReducer<
    React.Reducer<ReducerState, ReducerAction>
  >(
    reducer,
    getDefaultReducerState(
      initialState || {
        ...(label != null ? { defaultLabel: label } : {}),
        ...(selectedOption != null ? { selectedOption } : {}),
      },
    ),
  )

  const showEmpty = useMemo(
    () => defaultShowEmpty || defaultShowEmptyState,
    [defaultShowEmpty, defaultShowEmptyState],
  )

  const previousState = usePreviousValue(state)

  const { results, search } = useFuzzySearch<T>(
    defaultOptions,
    fuzzySearchOptions?.fuseOptions || {},
    fuzzySearchOptions?.debounce || 0,
  )

  const options = useMemo(
    () => (withFuzzySearch ? results.map(({ item }) => item) : defaultOptions),
    [defaultOptions, results, withFuzzySearch],
  )

  const getOriginalOption = useGetOriginalOption(defaultGetOriginalOption)

  const originalOptionAdded = useMemo(
    () =>
      state.optionAdded !== null ? getOriginalOption(state.optionAdded) : null,
    [getOriginalOption, state.optionAdded],
  )

  const originalOptionRemoved = useMemo(
    () =>
      state.optionRemoved !== null
        ? getOriginalOption(state.optionRemoved)
        : null,
    [getOriginalOption, state.optionRemoved],
  )

  const originalOptionSelected = useMemo(
    () => getOriginalOption(state.selectedOption),
    [getOriginalOption, state.selectedOption],
  )

  const getOptionLabel = useGetOptionLabel(defaultGetOptionLabel)

  const getOptionDisabled = useGetOptionDisabled(
    defaultGetOptionDisabled,
    options,
    getDisabledOptions,
  )

  const getOptionLoadingLabel = useGetOptionLoadingLabel(
    defaultGetOptionLoadingLabel,
    getOptionLabel,
  )

  const getOptionLoading = useGetOptionLoading(defaultGetOptionLoading)

  const onSearchInputChange = useOnSearchInputChange(search, searchInputProps)
  const onSearchInputKeyDown = useOnSearchInputKeyDown(searchInputProps)

  const onChangeHandler = useCallback(
    (option: string) => {
      dispatch({
        type: canToggleOptions
          ? optionIsSelected(state, option)
            ? 'REMOVE_OPTION'
            : 'ADD_OPTION'
          : 'ADD_OPTION',
        option,
      })
    },
    [canToggleOptions, state],
  )

  const deselectOption = useCallback(() => {
    dispatch({ type: 'REMOVE_OPTION' })
  }, [])

  const selectOption = useCallback((option: string) => {
    dispatch({ type: 'ADD_OPTION', option })
  }, [])

  const internals = useMemo(
    () => ({
      deselectOption,
      originalOptionAdded,
      originalOptionRemoved,
      originalOptionSelected,
      selectOption,
      state,
    }),
    [
      deselectOption,
      originalOptionAdded,
      originalOptionRemoved,
      originalOptionSelected,
      selectOption,
      state,
    ],
  )

  const renderLoadingOption = useRenderLoadingOptionForSelect(
    defaultRenderLoadingOption,
    getOptionLoadingLabel,
    internals,
  )

  const renderFooter = useRenderFooter<T, Internals<T>>(
    showLoadingState,
    showInitialLoadingState,
    showErrorState,
    showEmpty,
    errorStateMessage,
    options,
    emptyStateMessage,
    defaultRenderFooter,
    internals,
  )

  const renderHeader = useRenderHeader(
    withFuzzySearch,
    searchInputProps,
    onSearchInputChange,
    onSearchInputKeyDown,
    defaultRenderHeader,
    internals,
  )

  const getLabelForTrigger = useCallback<
    NonNullable<ListBoxProps<T>['getLabelForTrigger']>
  >(
    (option) => {
      if (defaultGetLabelForTrigger != null) {
        return defaultGetLabelForTrigger(option, internals)
      }

      if (option) {
        const originalOption = getOriginalOption(option)
        return originalOption ? getOptionLabel(originalOption) : state.label
      }

      return state.label
    },
    [
      defaultGetLabelForTrigger,
      getOptionLabel,
      getOriginalOption,
      internals,
      state.label,
    ],
  )

  const renderOption = useCallback<
    NonNullable<ListBoxProps<T>['renderOption']>
  >(
    (option, isSelected) => {
      if (getOptionLoading(option)) {
        return renderLoadingOption(option, isSelected, internals)
      }

      if (defaultRenderOption != null) {
        return defaultRenderOption(option, isSelected, internals)
      }

      const isDisabled = getOptionDisabled(option)

      return (
        <Flex centerY fullWidth>
          <Text size="text-sm" variant={isDisabled ? 'secondary' : 'default'}>
            {getOptionLabel(option)}
          </Text>
        </Flex>
      )
    },
    [
      defaultRenderOption,
      getOptionDisabled,
      getOptionLabel,
      getOptionLoading,
      internals,
      renderLoadingOption,
    ],
  )

  const hideEndIndicator = useMemo(
    () =>
      showEmpty ||
      showLoadingState ||
      showInitialLoadingState ||
      disableEndIndicator,
    [disableEndIndicator, showEmpty, showInitialLoadingState, showLoadingState],
  )

  const listProps = useMemo<ListProps<T, OptionData<T>>>(
    () => ({
      ...(defaultListProps && { ...defaultListProps }),
      virtualize: virtualizeOptions,
      virtualizeProps: {
        isItemLoaded: (index) =>
          !defaultListProps?.virtualizeProps?.infiniteLoaderProps
            ?.canLoadMoreItems || index < options.length,
        itemCount: options.length + (hideEndIndicator ? 0 : 1),
        ...(defaultListProps?.virtualizeProps || {}),
        items: options,
        itemsData: {
          disableEndIndicator: hideEndIndicator,
          endIndicatorProps,
          getOptionDisabled,
          getOptionKey,
          getOptionLabel,
          getOptionMenuItemProps,
          getOptionValue,
          getOriginalOption,
          internals,
          loaderProps,
          renderOption,
        },
        renderItem: VirtualOption as unknown as ListItemMemo<T, OptionData<T>>,
        virtualListProps: {
          itemSize: () => DEFAULT_CONTENT_ROW_HEIGHT,
          ...(defaultListProps?.virtualizeProps?.virtualListProps || {}),
          outerRef: (ref) => {
            if (defaultListProps?.virtualizeProps?.virtualListProps?.outerRef) {
              assignRef(
                defaultListProps.virtualizeProps.virtualListProps.outerRef,
                ref,
              )
            }
          },
          style: {
            overflow: 'visible',
            overflowY: 'auto',
          },
        },
      },
    }),
    [
      defaultListProps,
      virtualizeOptions,
      options,
      hideEndIndicator,
      endIndicatorProps,
      getOptionDisabled,
      getOptionKey,
      getOptionLabel,
      getOptionMenuItemProps,
      getOptionValue,
      getOriginalOption,
      internals,
      loaderProps,
      renderOption,
    ],
  )

  useEffect(() => {
    if (
      onChange != null &&
      previousState !== undefined &&
      state.selectedOption !== previousState.selectedOption
    ) {
      onChange({
        deselectOption,
        originalOptionAdded,
        originalOptionRemoved,
        originalOptionSelected,
        selectOption,
        state,
      })
    }
  }, [
    deselectOption,
    getOriginalOption,
    onChange,
    originalOptionAdded,
    originalOptionRemoved,
    originalOptionSelected,
    previousState,
    selectOption,
    state,
  ])

  useEffect(() => {
    dispatch({
      type: 'RESET',
      nextState: {
        ...(label != null ? { defaultLabel: label } : {}),
        ...(selectedOption != null ? { selectedOption } : {}),
      },
    })
  }, [label, selectedOption])

  return (
    <ListBox
      listProps={listProps as ListProps<T>}
      position={
        showInitialLoadingState || showEmpty || options.length === 0
          ? 'matchWidth'
          : 'default'
      }
      {...props}
      canToggleOptions={canToggleOptions}
      getLabelForTrigger={getLabelForTrigger}
      getOptionDisabled={getOptionDisabled}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      getOptionMenuItemProps={getOptionMenuItemProps}
      getOptionValue={getOptionValue}
      label={state.label}
      onChange={onChangeHandler}
      options={showInitialLoadingState || showEmpty ? [] : options}
      renderFooter={renderFooter}
      renderHeader={renderHeader}
      renderOption={renderOption}
      renderTrigger={
        props.renderTrigger != null
          ? (_, isExpanded) =>
              props.renderTrigger?.(
                isExpanded,
                {
                  deselectOption,
                  originalOptionAdded,
                  originalOptionRemoved,
                  originalOptionSelected,
                  selectOption,
                  state,
                },
                getLabelForTrigger?.(state.selectedOption) || state.label,
              ) ||
              (getLabelForTrigger != null
                ? getLabelForTrigger(state.selectedOption)
                : undefined) ||
              state.label ||
              state.defaultLabel
          : withRemoveButton
            ? (_, isExpanded) => (
                <SelectRemoveValueTrigger
                  label={
                    getLabelForTrigger != null
                      ? getLabelForTrigger(state.selectedOption)
                      : undefined
                  }
                  {...(typeof removeButtonProps === 'function'
                    ? removeButtonProps(internals)
                    : removeButtonProps != null
                      ? removeButtonProps
                      : {})}
                  isOpen={isExpanded}
                  internals={{
                    deselectOption,
                    originalOptionAdded,
                    originalOptionRemoved,
                    originalOptionSelected,
                    selectOption,
                    state,
                  }}
                />
              )
            : undefined
      }
      value={state.selectedOption || ''}
    />
  )
}

export default Select
