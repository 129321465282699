import React from 'react'
import styled from 'styled-components'

import {
  FlexNext as Flex,
  FlexNextProps as FlexProps,
  foundation,
} from '@vori/gourmet-components'

import { DATAGRID_HEADER_HEIGHT } from './constants'

const Container = styled(Flex)(({
  $noPadding,
  $noPaddingY,
  $noPaddingX,
}: {
  $noPadding?: boolean
  $noPaddingY?: boolean
  $noPaddingX?: boolean
}) => {
  const paddingY = $noPaddingY ? 0 : foundation.spacing.tiny
  const paddingX = $noPaddingX ? 0 : foundation.spacing.medium

  return {
    backgroundColor: '#FFFFFF',
    minHeight: DATAGRID_HEADER_HEIGHT,
    padding: !$noPadding ? `${paddingY} ${paddingX}` : 0,
  }
})

interface Props {
  flexProps?: FlexProps
  noDivider?: boolean
  noPadding?: boolean
  noPaddingX?: boolean
  noPaddingY?: boolean
}

function DataGridHeader({
  children,
  flexProps,
  noPadding,
  noPaddingY,
  noPaddingX,
  ...props
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <Container
      data-datagrid-header
      $noPadding={noPadding}
      $noPaddingY={noPaddingY}
      $noPaddingX={noPaddingX}
      centerY
      fullWidth
      justifyContent="space-between"
      {...flexProps}
      {...props}
    >
      {children}
    </Container>
  )
}

export { DataGridHeader }
export type { Props as DataGridHeaderProps }
