import { InlineFlex, TextNext as Text } from '@vori/gourmet-components'
import { foundations } from '@vori/gourmet-tokens'
import React from 'react'
import styled from 'styled-components'

import { CardBrand, PaymentType } from '@vori/dashboard-rest'

import { AmexCardIcon } from './AmexCardIcon'
import { CashIcon } from './CashIcon'
import { DiscoverCardIcon } from './DiscoverCardIcon'
import { EBTCardIcon } from './EBTCardIcon'
import { MasterCardIcon } from './MasterCardIcon'
import { VisaCardIcon } from './VisaCardIcon'

import { DebitCardIcon } from './DebitCardIcon'
import { GiftCardIcon } from './GiftCardIcon'
import { PaymentIconCoreProps, PaymentIconProps } from './types'

const StyledText = styled(Text)({
  color: foundations.color['color.gray-600'],
})

const defaultProps: Partial<PaymentIconCoreProps> = {}

function getIcon(
  paymentType: PaymentType,
  cardBrand: CardBrand | null,
): JSX.Element | null {
  if (paymentType === PaymentType.Cash) {
    return <CashIcon />
  }

  if (
    paymentType === PaymentType.EbtCash ||
    paymentType === PaymentType.EbtFoodstamp
  ) {
    return <EBTCardIcon />
  }

  if (paymentType === PaymentType.GiftCard) {
    return <GiftCardIcon />
  }

  if (paymentType === PaymentType.Debit || paymentType === PaymentType.Credit) {
    switch (cardBrand) {
      case CardBrand.AmericanExpress: {
        return <AmexCardIcon />
      }

      case CardBrand.Discover: {
        return <DiscoverCardIcon />
      }

      case CardBrand.Mastercard: {
        return <MasterCardIcon />
      }

      case CardBrand.Visa: {
        return <VisaCardIcon />
      }
      case CardBrand.Debit: {
        return <DebitCardIcon />
      }

      default: {
        return null
      }
    }
  }

  return null
}

const PaymentIcon = React.forwardRef<HTMLSpanElement, PaymentIconProps>(
  function PaymentIcon(
    { cardBrand, lastFourDigits, paymentType }: PaymentIconProps,
    ref,
  ): JSX.Element | null {
    return (
      <InlineFlex center columnGap="space.075" ref={ref}>
        <svg
          fill="none"
          height="32"
          viewBox="0 0 46 32"
          width="46"
          xmlns="http://www.w3.org/2000/svg"
        >
          {getIcon(paymentType, cardBrand)}
        </svg>
        {paymentType !== PaymentType.Cash && lastFourDigits != null && (
          <StyledText size="text-sm">•••• {lastFourDigits}</StyledText>
        )}
      </InlineFlex>
    )
  },
)

PaymentIcon.displayName = 'PaymentIcon'
PaymentIcon.defaultProps = defaultProps

export { PaymentIcon, defaultProps as paymentIconDefaultProps }
