import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MinusIconProps = IconProps

const MinusIcon = forwardRef<SVGSVGElement, MinusIconProps>(
  (props: MinusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12h14"
        fill="none"
      ></path>
    </Icon>
  ),
)

MinusIcon.displayName = 'MinusIcon'
MinusIcon.defaultProps = iconDefaultProps

export type { MinusIconProps }
export { MinusIcon }
