import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { ButtonColorVariant } from '../Button'
import { colors } from '../tokens'

import ClickableNav, {
  ClickableNavDefaultProps,
  ClickableNavProps,
} from '../ClickableNav'

type LinkProps = ClickableNavProps

const defaultProps: Partial<LinkProps> = {
  ...ClickableNavDefaultProps,
}

/**
 * @deprecated Use `<LinkNext>` instead.
 */
const StylesLink = styled(
  forwardRef<HTMLAnchorElement, LinkProps>(function Link(
    { ...props }: LinkProps,
    ref,
  ) {
    return <ClickableNav {...props} data-gourmet-link ref={ref} />
  }),
)<LinkProps>(({ variant = defaultProps.variant }) => ({
  backgroundColor: colors.clickable.backgroundColor,
  borderColor: colors.clickable.borderColor,
  color: colors.clickable.color[variant as ButtonColorVariant],
  justifyContent: 'flex-start',
  padding: 0,
  textAlign: 'left',
  textDecoration: 'underline',
  width: 'auto',

  '&:hover, &:focus, &:active': {
    color: colors.clickable.hover.color[variant as ButtonColorVariant],
    backgroundColor: colors.clickable.hover.backgroundColor,
    borderColor: colors.clickable.hover.borderColor,
    textDecoration: 'underline',
  },
}))

StylesLink.displayName = 'Link'
StylesLink.defaultProps = defaultProps

export type { LinkProps }
export { defaultProps as LinkDefaultProps }
export default StylesLink
