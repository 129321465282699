import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronLeftDoubleIconProps = IconProps

const ChevronLeftDoubleIcon = forwardRef<
  SVGSVGElement,
  ChevronLeftDoubleIconProps
>((props: ChevronLeftDoubleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m18 17-5-5 5-5m-7 10-5-5 5-5"
      fill="none"
    ></path>
  </Icon>
))

ChevronLeftDoubleIcon.displayName = 'ChevronLeftDoubleIcon'
ChevronLeftDoubleIcon.defaultProps = iconDefaultProps

export type { ChevronLeftDoubleIconProps }
export { ChevronLeftDoubleIcon }
