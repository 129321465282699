import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Toggle03RightIconProps = IconProps

const Toggle03RightIcon = forwardRef<SVGSVGElement, Toggle03RightIconProps>(
  (props: Toggle03RightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 12a6 6 0 0 1 6-6h8a6 6 0 0 1 0 12H8a6 6 0 0 1-6-6Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Toggle03RightIcon.displayName = 'Toggle03RightIcon'
Toggle03RightIcon.defaultProps = iconDefaultProps

export type { Toggle03RightIconProps }
export { Toggle03RightIcon }
