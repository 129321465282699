import React from 'react'

export function DebitCardIcon(): JSX.Element {
  return (
    <g clipPath="url(#clip0_1374_8971)">
      <rect width="46" height="32" rx="4" fill="white" />
      <path
        d="M7.75313 18.104H8.31713C9.78113 18.104 10.4291 17.336 10.4291 15.74V15.644C10.4291 14.012 9.78113 13.316 8.29313 13.316H7.75313V18.104ZM5.28113 20V11.42H8.40113C11.4491 11.42 12.9971 12.944 12.9971 15.608V15.704C12.9971 18.38 11.4131 20 8.38913 20H5.28113ZM14.1991 20V11.42H20.1271V13.316H16.6471V14.792H19.4191V16.544H16.6471V18.104H20.3191V20H14.1991ZM21.5468 20V11.42H25.3748C27.4028 11.42 28.2668 12.332 28.2668 13.664V13.712C28.2668 14.672 27.7747 15.308 26.8148 15.56C27.8708 15.74 28.5308 16.376 28.5308 17.564V17.612C28.5308 19.148 27.5348 20 25.4108 20H21.5468ZM23.9468 18.356H24.9668C25.7468 18.356 26.1068 17.996 26.1068 17.36V17.312C26.1068 16.664 25.7588 16.328 24.8948 16.328H23.9468V18.356ZM23.9468 14.828H24.8828C25.6508 14.828 25.9388 14.564 25.9388 13.952V13.904C25.9388 13.316 25.6268 13.052 24.8828 13.052H23.9468V14.828ZM29.7393 20V11.42H32.2233V20H29.7393ZM35.554 20V13.316H33.334V11.42H40.246V13.316H38.026V20H35.554Z"
        fill="#3F3F46"
      />
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" stroke="#F4F4F5" />
    </g>
  )
}
