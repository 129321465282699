import React, { cloneElement, forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'
import { XCircleIcon, CheckCircleIcon } from '@vori/gourmet-icons'

import Flex, { FlexDefaultProps, FlexProps } from '../Flex'

import { colors, base, Size, sizing, spacing, typography } from '../tokens'
import toTransitions from '../utils/toTransitions'

type InputSize = Extract<Size, 'small' | 'base' | 'large'>
type InputColorVariant = keyof typeof colors.input.borderColor

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  containerProps?: FlexProps
  disableFocusRing?: boolean
  disableFocusStyles?: boolean
  fullWidth?: boolean
  leftIcon?: React.ReactElement | null
  noPadding?: boolean
  rightIcon?: React.ReactElement | null
  size?: InputSize
  variant?: InputColorVariant
}

const defaultProps: Partial<InputProps> = {
  className: '',
  containerProps: FlexDefaultProps,
  disableFocusRing: false,
  disableFocusStyles: false,
  fullWidth: false,
  noPadding: false,
  size: 'base',
}

function containerStyles(props: { fullWidth?: boolean }): CSSObject {
  return {
    ...(props.fullWidth && { width: '100%' }),
    display: 'inline-block',
    position: 'relative',
  }
}

const Container = styled.span(containerStyles)

function iconContainerStyles(props: {
  noPadding?: boolean
  position: 'left' | 'right'
  variant?: InputColorVariant
}): CSSObject {
  return {
    ...(!props.noPadding &&
      props.position === 'left' && {
        left: spacing.input.iconContainer,
      }),
    ...(!props.noPadding &&
      props.position === 'right' && {
        right: spacing.input.iconContainer,
      }),
    color: colors.input.icon[props.variant || 'default'],
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    height: '100%',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    zIndex: 5,
  }
}

const IconContainer = styled.span(iconContainerStyles)

function getIconSize(inputSize: InputSize): Size {
  switch (inputSize) {
    case 'small': {
      return 'small'
    }

    case 'large': {
      return 'base'
    }

    default: {
      return 'medium'
    }
  }
}

function styles(props: InputProps): CSSObject {
  const variant: InputColorVariant = props['aria-invalid']
    ? 'negative'
    : props.variant || 'default'

  return {
    backgroundColor: colors.input.backgroundColor,
    borderRadius: sizing.radius.base,
    border: `1px solid ${colors.input.borderColor[variant]}`,
    color: colors.input.color,
    cursor: 'text',
    fontSize: typography.input[props.size as InputSize].fontSize,
    fontWeight: typography.input[props.size as InputSize].fontWeight,
    lineHeight: typography.input[props.size as InputSize].lineHeight,
    height: '100%',
    maxHeight: sizing.input[props.size as InputSize],
    margin: 0,
    width: '100%',
    transition: toTransitions(
      ['border-color', 'box-shadow', 'opacity'],
      'ease',
    ),

    padding: props.noPadding
      ? 0
      : props.size === 'small'
        ? spacing.input.small
        : props.size === 'large'
          ? spacing.input.large
          : spacing.input.base,
    ...(props.leftIcon && {
      paddingLeft: `calc(${props.noPadding ? '0px' : base.spacing.small} + ${
        sizing.icon.medium
      } + ${base.spacing.tiny})`,
    }),
    ...(props.rightIcon && {
      paddingRight: `calc(${props.noPadding ? '0px' : base.spacing.small} + ${
        sizing.icon.medium
      } + ${base.spacing.tiny})`,
    }),

    [[
      ':active:not(:read-only:not([data-gourmet-input-container]))',
      ':focus:not(:read-only:not([data-gourmet-input-container]))',
      ':focus-within:not(:read-only:not([data-gourmet-input-container]))',
      '&[data-state-active="true"]:not(:read-only:not([data-gourmet-input-container]))',
      '&[data-state-focus="true"]:not(:read-only:not([data-gourmet-input-container]))',
    ].join(',')]: {
      backgroundColor: colors.input.focus.backgroundColor,
      borderColor: props.disableFocusStyles
        ? colors.input.borderColor[variant]
        : colors.input.focus.borderColor[variant],
      color: colors.input.focus.color,
      outline: 'none',
      textDecoration: 'none',
    },

    [[
      ':focus:not(:read-only:not([data-gourmet-input-container]))',
      ':focus-within:not(:read-only:not([data-gourmet-input-container]))',
      '&[data-state-focus="true"]:not(:read-only:not([data-gourmet-input-container]))',
    ].join(',')]: {
      boxShadow:
        props.disableFocusRing || props.disableFocusStyles
          ? 'none'
          : `0 0 0 ${sizing.focusRing} ${colors.input.focusRing}, 0 0 0 1px ${colors.input.focus.borderColor[variant]} inset`,
    },

    [[
      ':read-only:not([data-gourmet-input-container]:not([data-readonly])):not([data-gourmet-editable])',
      '[data-readonly]',
    ].join(',')]: {
      backgroundColor: colors.input.readOnly.backgroundColor,
      borderColor: colors.input.readOnly.borderColor[variant],
      boxShadow: 'none',
      color: colors.input.readOnly.color,
      cursor: 'default',
    },

    ':disabled, &[data-state-disabled="true"]': {
      backgroundColor: '#FAFAFA',
      borderColor: '#D1D1D6',
      color: '#70707B',
      pointerEvents: 'none',
    },

    '::placeholder': {
      color: colors.input.placeholder,
    },

    '::-webkit-search-cancel-button': {
      cursor: 'pointer',
    },
  }
}

/**
 * @deprecated Use `<InputNext>` instead, unless you need to use the input as a
 * "container".
 */
const StyledInput = styled(
  forwardRef<HTMLInputElement, InputProps>(function Input(
    {
      children,
      containerProps,
      disableFocusRing,
      disableFocusStyles,
      fullWidth,
      leftIcon,
      noPadding,
      rightIcon,
      size = 'base',
      variant: providedVariant,
      ...props
    }: InputProps,
    ref,
  ) {
    const variant: InputColorVariant =
      !providedVariant && props['aria-invalid']
        ? 'negative'
        : providedVariant || 'default'

    return (
      <Container fullWidth={fullWidth as boolean}>
        {leftIcon && (
          <IconContainer data-input-icon noPadding={noPadding} position="left">
            {cloneElement(
              leftIcon,
              {
                'aria-hidden': true,
                size: getIconSize(size),
              },
              null,
            )}
          </IconContainer>
        )}

        {/**
         * If `children` are passed to `<Input/> then we assume you are
         * trying to render an input-like component or a component meant to
         * look and feel like an input, so we render `children` in place of
         * the input.
         */}
        {children != null ? (
          <Flex
            data-gourmet-input-container
            {...(props.readOnly && { 'data-readonly': 'true' })}
            {...containerProps}
            {...props}
          >
            {children}
          </Flex>
        ) : (
          <input data-gourmet-input type="text" {...props} ref={ref} />
        )}

        {variant === 'default' && rightIcon && (
          <IconContainer
            data-input-icon
            noPadding={noPadding}
            position="right"
            variant={variant as InputColorVariant}
          >
            {cloneElement(
              rightIcon,
              {
                'aria-hidden': true,
                size: getIconSize(size),
              },
              null,
            )}
          </IconContainer>
        )}

        {variant === 'positive' && (
          <IconContainer
            data-input-icon
            noPadding={noPadding}
            position="right"
            variant="positive"
          >
            <CheckCircleIcon size={getIconSize(size)} />
          </IconContainer>
        )}

        {variant === 'negative' && (
          <IconContainer
            data-input-icon
            noPadding={noPadding}
            position="right"
            variant="negative"
          >
            <XCircleIcon size={getIconSize(size)} />
          </IconContainer>
        )}
      </Container>
    )
  }),
)(styles)

StyledInput.displayName = 'Input'
StyledInput.defaultProps = defaultProps

export type { InputColorVariant, InputProps, InputSize }

export {
  containerStyles as IconContainerStyles,
  defaultProps as InputDefaultProps,
  getIconSize,
  iconContainerStyles as InputIconContainerStyles,
  styles as InputStyles,
}

export default StyledInput
