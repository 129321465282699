import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Star07IconProps = IconProps

const Star07Icon = forwardRef<SVGSVGElement, Star07IconProps>(
  (props: Star07IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12h-2m-.929 7.071-1.414-1.414M4 12H2m4.343-5.657L4.929 4.93M12 4V2m5.657 4.343L19.07 4.93M12 22v-2m-7.071-.929 1.414-1.414M12 7l1.545 3.13 3.455.505-2.5 2.435.59 3.44L12 14.885 8.91 16.51l.59-3.44L7 10.635l3.455-.505L12 7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Star07Icon.displayName = 'Star07Icon'
Star07Icon.defaultProps = iconDefaultProps

export type { Star07IconProps }
export { Star07Icon }
