import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LayoutRightFilledIconProps = IconProps

const LayoutRightFilledIcon = forwardRef<
  SVGSVGElement,
  LayoutRightFilledIconProps
>((props: LayoutRightFilledIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.759 2c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C2 6.29 2 6.954 2 7.758v8.483c0 .805 0 1.47.044 2.01.046.563.145 1.08.392 1.565a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C6.29 22 6.954 22 7.758 22h8.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01V7.758c0-.805 0-1.47-.044-2.01-.046-.563-.145-1.08-.392-1.565a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C17.71 2 17.046 2 16.242 2H7.758ZM14 4H7.8c-.857 0-1.439 0-1.889.038-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819C4 6.361 4 6.943 4 7.8v8.4c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 0 0 .874.874c.156.08.38.145.819.18C6.361 20 6.943 20 7.8 20H14V4Z"
      clipRule="evenodd"
      stroke="none"
    ></path>
  </Icon>
))

LayoutRightFilledIcon.displayName = 'LayoutRightFilledIcon'
LayoutRightFilledIcon.defaultProps = iconDefaultProps

export type { LayoutRightFilledIconProps }
export { LayoutRightFilledIcon }
