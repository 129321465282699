import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LetterSpacing01IconProps = IconProps

const LetterSpacing01Icon = forwardRef<SVGSVGElement, LetterSpacing01IconProps>(
  (props: LetterSpacing01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 13h6m-8 4 4.272-9.398c.231-.509.347-.763.507-.842a.5.5 0 0 1 .442 0c.16.079.276.333.507.842L17 17m4-14v18M3 3v18"
        fill="none"
      ></path>
    </Icon>
  ),
)

LetterSpacing01Icon.displayName = 'LetterSpacing01Icon'
LetterSpacing01Icon.defaultProps = iconDefaultProps

export type { LetterSpacing01IconProps }
export { LetterSpacing01Icon }
