import { FieldMergeFunction, Reference } from '@apollo/client'
import { RelayFieldPolicy } from '@apollo/client/utilities/policies/pagination'
import { relayStylePagination as originalRelayStylePagination } from '@apollo/client/utilities'

import {
  KeyArgsFunction,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies'

const merge = (
  existing: Record<string, unknown>,
  incoming: Record<string, unknown>,
): Record<string, unknown> => {
  if (!incoming) return existing
  if (!existing) return incoming

  return {
    ...existing,
    ...incoming,
    nodes: [
      ...(existing as { nodes: unknown[] }).nodes,
      ...(incoming as { nodes: unknown[] }).nodes,
    ],
  }
}

// The original `relayStylePagination` is expecting `args.after` to be defined,
// but we put all inputs under `input`, so we have to patch `args.after` to
// point to `args.input.after` when needed.
function relayStylePagination<TNode = Reference>(
  keyArgs?: KeySpecifier | KeyArgsFunction | false,
): RelayFieldPolicy<TNode> {
  return {
    ...originalRelayStylePagination(keyArgs),
    merge: (existing, incoming, options) =>
      (originalRelayStylePagination(keyArgs).merge as FieldMergeFunction)(
        existing,
        incoming,
        {
          ...options,
          args: {
            ...options.args,
            ...(options.args && {
              after: options.args?.input?.after || options.args?.after,
              before: options.args?.input?.before || options.args?.before,
            }),
          },
        },
      ),
  }
}

export { merge, relayStylePagination }
