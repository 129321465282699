import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenDownRightIconProps = IconProps

const ArrowCircleBrokenDownRightIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenDownRightIconProps
>((props: ArrowCircleBrokenDownRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.41 2.34a9.996 9.996 0 0 1 9.661 2.589c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0a9.996 9.996 0 0 1-2.59-9.66M15 9v6m0 0H9m6 0L5 5"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenDownRightIcon.displayName = 'ArrowCircleBrokenDownRightIcon'
ArrowCircleBrokenDownRightIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenDownRightIconProps }
export { ArrowCircleBrokenDownRightIcon }
