import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SunriseIconProps = IconProps

const SunriseIcon = forwardRef<SVGSVGElement, SunriseIconProps>(
  (props: SunriseIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 18H2m4.314-5.686L4.9 10.9m12.786 1.414L19.1 10.9M22 18h-2M7 18a5 5 0 0 1 10 0m5 4H2M16 6l-4-4m0 0L8 6m4-4v7"
        fill="none"
      ></path>
    </Icon>
  ),
)

SunriseIcon.displayName = 'SunriseIcon'
SunriseIcon.defaultProps = iconDefaultProps

export type { SunriseIconProps }
export { SunriseIcon }
