import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CornerRightDownIconProps = IconProps

const CornerRightDownIcon = forwardRef<SVGSVGElement, CornerRightDownIconProps>(
  (props: CornerRightDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 4h3.4c3.36 0 5.04 0 6.324.654a6 6 0 0 1 2.622 2.622C16 8.56 16 10.24 16 13.6V20m0 0-5-5m5 5 5-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CornerRightDownIcon.displayName = 'CornerRightDownIcon'
CornerRightDownIcon.defaultProps = iconDefaultProps

export type { CornerRightDownIconProps }
export { CornerRightDownIcon }
