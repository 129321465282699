import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowsLeftIconProps = IconProps

const ArrowsLeftIcon = forwardRef<SVGSVGElement, ArrowsLeftIconProps>(
  (props: ArrowsLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 17H4m0 0 4 4m-4-4 4-4m12-6H9m0 0 4 4M9 7l4-4"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowsLeftIcon.displayName = 'ArrowsLeftIcon'
ArrowsLeftIcon.defaultProps = iconDefaultProps

export type { ArrowsLeftIconProps }
export { ArrowsLeftIcon }
