import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart09IconProps = IconProps

const BarChart09Icon = forwardRef<SVGSVGElement, BarChart09IconProps>(
  (props: BarChart09IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21H6.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3 19.48 3 18.92 3 17.8V3m4 2.5v12m4.5-9v9m4.5-6v6m4.5-3v3"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart09Icon.displayName = 'BarChart09Icon'
BarChart09Icon.defaultProps = iconDefaultProps

export type { BarChart09IconProps }
export { BarChart09Icon }
