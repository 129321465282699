import {
  AddOperation,
  CopyOperation,
  GetOperation,
  MoveOperation,
  RemoveOperation,
  ReplaceOperation,
  TestOperation,
} from 'fast-json-patch'

enum ChangesetRowStatus {
  STAGED = 'STAGED',
  UNSTAGED = 'UNSTAGED',
}

enum PatchType {
  GROUP = 'GROUP',
  ROW = 'ROW',
}

type PatchOperation<TChanges> =
  | AddOperation<TChanges>
  | CopyOperation
  | GetOperation<TChanges>
  | MoveOperation
  | RemoveOperation
  | ReplaceOperation<TChanges>
  | TestOperation<TChanges>

interface APIGroupPatch<TChanges> {
  type: PatchType.GROUP
  patch: Array<PatchOperation<TChanges>>
  groupKeys: string[]
  columns: string[]
  timestamp: string
  status: ChangesetRowStatus
}

interface APIRowPatch<TChanges = Record<string, unknown>> {
  type: PatchType.ROW
  id: string
  patch: Array<PatchOperation<TChanges>>
  before: Record<string, unknown>
  timestamp: string
  status: ChangesetRowStatus
}

type APIPatch<T> = APIGroupPatch<T> | APIRowPatch<T>

type JSONPatchEnvelope<TChanges = Record<string, unknown>> = {
  before: Partial<TChanges>
  id: string
  patch: Array<PatchOperation<TChanges>>
  timestamp: string
}

export { ChangesetRowStatus, PatchType }

export type {
  APIGroupPatch,
  APIPatch,
  APIRowPatch,
  JSONPatchEnvelope,
  PatchOperation,
}
