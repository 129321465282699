import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GitBranch01IconProps = IconProps

const GitBranch01Icon = forwardRef<SVGSVGElement, GitBranch01IconProps>(
  (props: GitBranch01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 3v10.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 18 6.12 18 7.8 18H15m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0ZM3 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

GitBranch01Icon.displayName = 'GitBranch01Icon'
GitBranch01Icon.defaultProps = iconDefaultProps

export type { GitBranch01IconProps }
export { GitBranch01Icon }
