import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web'

import {
  ENV_OTEL_ENDPOINT,
  ENV_OTEL_TRACE_HEADER_CORS_URL,
  ENVIRONMENT,
} from '@vori/dashboard-env'

const PROPAGATE_TRACE_HEADER_CORS_URLS = [
  new RegExp(ENV_OTEL_TRACE_HEADER_CORS_URL),
]

export function initializeOpenTelemetry(): void {
  const openTelemetrySDK = new HoneycombWebSDK({
    endpoint: ENV_OTEL_ENDPOINT,
    skipOptionsValidation: true,
    resourceAttributes: {
      [ATTR_SERVICE_NAME]: 'dashboard',
      environment: ENVIRONMENT,
    },
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-document-load': {
          ignoreNetworkEvents: true,
        },
        '@opentelemetry/instrumentation-fetch': {
          ignoreNetworkEvents: true,
          propagateTraceHeaderCorsUrls: PROPAGATE_TRACE_HEADER_CORS_URLS,
        },
        // Loads custom configuration for xml-http-request instrumentation.
        '@opentelemetry/instrumentation-xml-http-request': {
          ignoreNetworkEvents: true,
          propagateTraceHeaderCorsUrls: PROPAGATE_TRACE_HEADER_CORS_URLS,
        },
      }),
    ],
  })

  openTelemetrySDK.start()
}
