import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type XCircleIconProps = IconProps

const XCircleIcon = forwardRef<SVGSVGElement, XCircleIconProps>(
  (props: XCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m15 9-6 6m0-6 6 6m7-3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

XCircleIcon.displayName = 'XCircleIcon'
XCircleIcon.defaultProps = iconDefaultProps

export type { XCircleIconProps }
export { XCircleIcon }
