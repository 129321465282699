import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Percent01IconProps = IconProps

const Percent01Icon = forwardRef<SVGSVGElement, Percent01IconProps>(
  (props: Percent01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 5 5 19M7.5 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm11 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Percent01Icon.displayName = 'Percent01Icon'
Percent01Icon.defaultProps = iconDefaultProps

export type { Percent01IconProps }
export { Percent01Icon }
