import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenUpLeftIconProps = IconProps

const ArrowCircleBrokenUpLeftIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenUpLeftIconProps
>((props: ArrowCircleBrokenUpLeftIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.59 21.66a9.996 9.996 0 0 1-9.661-2.589c-3.905-3.905-3.905-10.237 0-14.142 3.905-3.905 10.237-3.905 14.142 0a9.996 9.996 0 0 1 2.59 9.66M9 15V9m0 0h6M9 9l10 10"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenUpLeftIcon.displayName = 'ArrowCircleBrokenUpLeftIcon'
ArrowCircleBrokenUpLeftIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenUpLeftIconProps }
export { ArrowCircleBrokenUpLeftIcon }
