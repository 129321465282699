import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudRaining03IconProps = IconProps

const CloudRaining03Icon = forwardRef<SVGSVGElement, CloudRaining03IconProps>(
  (props: CloudRaining03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 15.242a4.5 4.5 0 0 0-2.08-8.223 6.002 6.002 0 0 0-11.84 0A4.5 4.5 0 0 0 4 15.242M12.25 15l-2.8 7m7.6-9-2.8 7m-5.2-7-2.8 7"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudRaining03Icon.displayName = 'CloudRaining03Icon'
CloudRaining03Icon.defaultProps = iconDefaultProps

export type { CloudRaining03IconProps }
export { CloudRaining03Icon }
