import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PresentationChart02IconProps = IconProps

const PresentationChart02Icon = forwardRef<
  SVGSVGElement,
  PresentationChart02IconProps
>((props: PresentationChart02IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 16v5m6 0-3.951-3.293c-.73-.607-1.094-.91-1.5-1.027a2 2 0 0 0-1.098 0c-.406.116-.77.42-1.5 1.027L6 21m2-10v1m4-3v3m4-5v5m6-9H2m1 0h18v8.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 16 17.88 16 16.2 16H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 13.72 3 12.88 3 11.2V3Z"
      fill="none"
    ></path>
  </Icon>
))

PresentationChart02Icon.displayName = 'PresentationChart02Icon'
PresentationChart02Icon.defaultProps = iconDefaultProps

export type { PresentationChart02IconProps }
export { PresentationChart02Icon }
