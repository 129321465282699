import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Hurricane02IconProps = IconProps

const Hurricane02Icon = forwardRef<SVGSVGElement, Hurricane02IconProps>(
  (props: Hurricane02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 12a6 6 0 0 1-12 0m12 0a6 6 0 0 0-12 0m12 0a8 8 0 1 1-16 0m4 0a8 8 0 1 1 16 0m-9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Hurricane02Icon.displayName = 'Hurricane02Icon'
Hurricane02Icon.defaultProps = iconDefaultProps

export type { Hurricane02IconProps }
export { Hurricane02Icon }
