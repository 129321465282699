import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CircleCutIconProps = IconProps

const CircleCutIcon = forwardRef<SVGSVGElement, CircleCutIconProps>(
  (props: CircleCutIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 20.662A9.995 9.995 0 0 0 12 12a9.996 9.996 0 0 0-5-8.662M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CircleCutIcon.displayName = 'CircleCutIcon'
CircleCutIcon.defaultProps = iconDefaultProps

export type { CircleCutIconProps }
export { CircleCutIcon }
