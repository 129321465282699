import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MarkerPin06IconProps = IconProps

const MarkerPin06Icon = forwardRef<SVGSVGElement, MarkerPin06IconProps>(
  (props: MarkerPin06IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 13.374c3.532.695 6 2.28 6 4.126 0 2.485-4.477 4.5-10 4.5S2 19.985 2 17.5c0-1.845 2.468-3.431 6-4.126M12 17V9m0 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MarkerPin06Icon.displayName = 'MarkerPin06Icon'
MarkerPin06Icon.defaultProps = iconDefaultProps

export type { MarkerPin06IconProps }
export { MarkerPin06Icon }
