import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignRightIconProps = IconProps

const AlignRightIcon = forwardRef<SVGSVGElement, AlignRightIconProps>(
  (props: AlignRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 10H8m13-4H4m17 8H4m17 4H8"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignRightIcon.displayName = 'AlignRightIcon'
AlignRightIcon.defaultProps = iconDefaultProps

export type { AlignRightIconProps }
export { AlignRightIcon }
