/* tslint:disable */
/* eslint-disable */
/**
 * Vori API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CardBrand = {
    AmericanExpress: 'american_express',
    BillMeLater: 'bill_me_later',
    Debit: 'debit',
    DinersClub: 'diners_club',
    Discover: 'discover',
    Jcb: 'jcb',
    Mastercard: 'mastercard',
    Other: 'other',
    Telecheck: 'telecheck',
    Undetermined: 'undetermined',
    RevolutionMoney: 'revolution_money',
    Visa: 'visa',
    Voyager: 'voyager',
    WrightExpress: 'wright_express',
    Atm: 'atm',
    Ebt: 'ebt'
} as const;
export type CardBrand = typeof CardBrand[keyof typeof CardBrand];


export function CardBrandFromJSON(json: any): CardBrand {
    return CardBrandFromJSONTyped(json, false);
}

export function CardBrandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardBrand {
    return json as CardBrand;
}

export function CardBrandToJSON(value?: CardBrand | null): any {
    return value as any;
}

