import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UsersMinusIconProps = IconProps

const UsersMinusIcon = forwardRef<SVGSVGElement, UsersMinusIconProps>(
  (props: UsersMinusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 18h6M15.5 3.29a4.001 4.001 0 0 1 0 7.42M12 15H8c-1.864 0-2.796 0-3.53.305a4 4 0 0 0-2.166 2.164C2 18.204 2 19.136 2 21M13.5 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UsersMinusIcon.displayName = 'UsersMinusIcon'
UsersMinusIcon.defaultProps = iconDefaultProps

export type { UsersMinusIconProps }
export { UsersMinusIcon }
