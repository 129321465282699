import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Glasses01IconProps = IconProps

const Glasses01Icon = forwardRef<SVGSVGElement, Glasses01IconProps>(
  (props: Glasses01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 11.535a4.008 4.008 0 0 1 4 0M8.828 9.172a4 4 0 1 1-5.657 5.656 4 4 0 0 1 5.657-5.656Zm12 0a4 4 0 1 1-5.656 5.656 4 4 0 0 1 5.656-5.656Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Glasses01Icon.displayName = 'Glasses01Icon'
Glasses01Icon.defaultProps = iconDefaultProps

export type { Glasses01IconProps }
export { Glasses01Icon }
