import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SunSetting03IconProps = IconProps

const SunSetting03Icon = forwardRef<SVGSVGElement, SunSetting03IconProps>(
  (props: SunSetting03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.06 20.913c1.21.55 2.75.55 3.96 0s2.75-.55 3.96 0 2.75.55 3.96 0M12 3v2m-8 8H2m4.314-5.686L4.9 5.9m12.786 1.414L19.1 5.9M22 13h-2M7 13a5 5 0 0 1 10 0M2.1 17.413c1.21-.55 2.75-.55 3.96 0s2.75.55 3.96 0 2.75-.55 3.96 0 2.75.55 3.96 0 2.75-.55 3.96 0"
        fill="none"
      ></path>
    </Icon>
  ),
)

SunSetting03Icon.displayName = 'SunSetting03Icon'
SunSetting03Icon.defaultProps = iconDefaultProps

export type { SunSetting03IconProps }
export { SunSetting03Icon }
