import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SearchLgIconProps = IconProps

const SearchLgIcon = forwardRef<SVGSVGElement, SearchLgIconProps>(
  (props: SearchLgIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m21 21-3.5-3.5m2.5-6a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

SearchLgIcon.displayName = 'SearchLgIcon'
SearchLgIcon.defaultProps = iconDefaultProps

export type { SearchLgIconProps }
export { SearchLgIcon }
