import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Lightning01IconProps = IconProps

const Lightning01Icon = forwardRef<SVGSVGElement, Lightning01IconProps>(
  (props: Lightning01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 2 4.093 12.688c-.348.418-.523.628-.525.804a.5.5 0 0 0 .185.397c.138.111.41.111.955.111H12l-1 8 8.907-10.688c.348-.418.523-.628.525-.804a.5.5 0 0 0-.185-.397c-.138-.111-.41-.111-.955-.111H12l1-8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Lightning01Icon.displayName = 'Lightning01Icon'
Lightning01Icon.defaultProps = iconDefaultProps

export type { Lightning01IconProps }
export { Lightning01Icon }
