import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MarkerPin03IconProps = IconProps

const MarkerPin03Icon = forwardRef<SVGSVGElement, MarkerPin03IconProps>(
  (props: MarkerPin03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c1-5 8-5.582 8-12a8 8 0 1 0-16 0c0 6.418 7 7 8 12Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MarkerPin03Icon.displayName = 'MarkerPin03Icon'
MarkerPin03Icon.defaultProps = iconDefaultProps

export type { MarkerPin03IconProps }
export { MarkerPin03Icon }
