import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PlusIconProps = IconProps

const PlusIcon = forwardRef<SVGSVGElement, PlusIconProps>(
  (props: PlusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 5v14m-7-7h14"
        fill="none"
      ></path>
    </Icon>
  ),
)

PlusIcon.displayName = 'PlusIcon'
PlusIcon.defaultProps = iconDefaultProps

export type { PlusIconProps }
export { PlusIcon }
