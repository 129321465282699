import { Link, LinkProps, useRouteMatch } from 'react-router-dom'
import React, { cloneElement, forwardRef } from 'react'
import styled from 'styled-components'

import Flex, { FlexProps } from '../Flex'
import Spacer from '../Spacer'

import { colors, typography } from '../tokens'
import toTransitions from '../utils/toTransitions'

type BreadcrumbProps = LinkProps & {
  exact?: boolean
  icon?: React.ReactElement | null
}

const StyledLink = styled(Link)`
  align-items: center;
  display: inline-flex;
  justify-content: center;
`

const StyledBreadcrumb = styled(
  forwardRef<HTMLAnchorElement, BreadcrumbProps>(function Breadcrumb(
    { children, className, exact, icon, to, ...props }: BreadcrumbProps,
    ref,
  ) {
    const match = useRouteMatch({
      exact,
      path: to as string,
    })

    return (
      <StyledLink
        {...props}
        className={match ? `${className} active` : className}
        ref={ref}
        to={to}
      >
        {icon && (
          <Flex centerY inline shrink={0}>
            {cloneElement(icon, { 'aria-hidden': true, size: 'medium' }, null)}
            <Spacer inline size="small" />
          </Flex>
        )}

        {children}
      </StyledLink>
    )
  }),
)<BreadcrumbProps>`
  white-space: break-spaces;

  &,
  :visited {
    color: ${colors.breadcrumb.color};
    font-size: ${typography.breadcrumb.fontSize};
    font-weight: ${typography.breadcrumb.fontWeight};
    letter-spacing: 1px;
    line-height: ${typography.breadcrumb.lineHeight};
    text-decoration: none;
    text-transform: uppercase;
    transition: ${toTransitions(['color'], 'ease')};
  }

  :hover,
  :active,
  :focus,
  &.active {
    color: ${colors.breadcrumb.hover.color};
    text-decoration: none;
  }
`

type BreadcrumbsProps = React.HTMLAttributes<HTMLDivElement> &
  FlexProps & {
    breadcrumbs: (LinkProps & {
      exact?: boolean
      icon?: React.ReactElement | null
      label: React.ReactNode
    })[]
  }

const defaultProps: Partial<BreadcrumbsProps> = {
  className: '',
}

const StyledBreadcrumbs = styled(
  forwardRef<HTMLDivElement, BreadcrumbsProps>(function Breadcrumbs(
    { breadcrumbs, centerY, shrink, ...props }: BreadcrumbsProps,
    ref,
  ) {
    return (
      <Flex {...props} centerY shrink={0} ref={ref}>
        {breadcrumbs.map(({ icon, label, to, ...breadcrumbProps }) => (
          <StyledBreadcrumb
            {...breadcrumbProps}
            icon={icon}
            key={`breadcrumb-${to}`}
            to={to}
          >
            {label}
            {breadcrumbs.length > 1 && ' / '}
          </StyledBreadcrumb>
        ))}
      </Flex>
    )
  }),
)``

StyledBreadcrumbs.displayName = 'Breadcrumbs'
StyledBreadcrumbs.defaultProps = defaultProps

export type { BreadcrumbsProps }
export { defaultProps as BreadcrumbsDefaultProps }
export default StyledBreadcrumbs
