import * as CSS from 'csstype'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import AspectRatio, { AspectRatioProps } from '../AspectRatio'

import { Color, base } from '../tokens'
import toTransitions from '../utils/toTransitions'

type BackgroundProps = React.HTMLAttributes<HTMLDivElement> &
  AspectRatioProps & {
    clip?: CSS.Property.BackgroundClip
    color?: Color | CSS.Property.BackgroundColor
    imageSize?: CSS.Property.BackgroundSize<number>
    imageUrl?: CSS.Property.BackgroundImage
    origin?: CSS.Property.BackgroundOrigin
    position?: CSS.Property.BackgroundPosition<number>
    repeat?: CSS.Property.BackgroundRepeat
  }

const defaultProps: Partial<BackgroundProps> = {
  className: '',
}

const StyledBackground = styled(
  forwardRef<HTMLDivElement, BackgroundProps>(function Background(
    {
      clip,
      imageSize,
      color,
      imageUrl,
      origin,
      repeat,
      position,
      ...props
    }: BackgroundProps,
    ref,
  ) {
    return <AspectRatio {...props} ref={ref} />
  }),
).attrs(({ imageUrl }) =>
  imageUrl != null
    ? {
        style: {
          backgroundImage: `url(${imageUrl})`,
        },
      }
    : undefined,
)<BackgroundProps>`
  ${({ clip }) => (clip != null ? `background-clip: ${clip};` : '')}
  ${({ imageSize }) =>
    imageSize != null
      ? `background-size: ${
          typeof imageSize === 'number' ? `${imageSize}px` : imageSize
        };`
      : ''}
  ${({ color }) =>
    color != null
      ? `background-color: ${base.colors[color as Color] || color};`
      : ''}
  ${({ origin }) => (origin != null ? `background-origin: ${origin};` : '')}
  ${({ repeat }) => (repeat != null ? `background-repeat: ${repeat};` : '')}
  ${({ position }) =>
    position != null
      ? `background-position: ${
          typeof position === 'number' ? `${position}px` : position
        };`
      : ''}
  transition: ${toTransitions(
    ['background-color', 'background-position', 'background-size'],
    'ease',
  )};
`

StyledBackground.displayName = 'Background'
StyledBackground.defaultProps = defaultProps

export type { BackgroundProps }
export { defaultProps as BackgroundDefaultProps }
export default StyledBackground
