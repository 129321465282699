import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PresentationChart03IconProps = IconProps

const PresentationChart03Icon = forwardRef<
  SVGSVGElement,
  PresentationChart03IconProps
>((props: PresentationChart03IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 16v5m0-5 6 5m-6-5-6 5M8 7v5m4-3v3m4-1v1m6-9H2m1 0h18v8.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 16 17.88 16 16.2 16H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 13.72 3 12.88 3 11.2V3Z"
      fill="none"
    ></path>
  </Icon>
))

PresentationChart03Icon.displayName = 'PresentationChart03Icon'
PresentationChart03Icon.defaultProps = iconDefaultProps

export type { PresentationChart03IconProps }
export { PresentationChart03Icon }
