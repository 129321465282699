import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AirpodsIconProps = IconProps

const AirpodsIcon = forwardRef<SVGSVGElement, AirpodsIconProps>(
  (props: AirpodsIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 7.625a4.125 4.125 0 0 0 4.125 4.125c.306 0 .459 0 .538.027a.445.445 0 0 1 .31.31c.027.08.027.203.027.452v6.336a1.625 1.625 0 1 0 3.25 0V7.625a4.125 4.125 0 0 0-8.25 0Zm20 0a4.125 4.125 0 0 1-4.125 4.125c-.306 0-.459 0-.538.027a.445.445 0 0 0-.31.31c-.027.08-.027.203-.027.452v6.336a1.625 1.625 0 1 1-3.25 0V7.625a4.125 4.125 0 1 1 8.25 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

AirpodsIcon.displayName = 'AirpodsIcon'
AirpodsIcon.defaultProps = iconDefaultProps

export type { AirpodsIconProps }
export { AirpodsIcon }
