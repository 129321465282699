import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockRefreshIconProps = IconProps

const ClockRefreshIcon = forwardRef<SVGSVGElement, ClockRefreshIconProps>(
  (props: ClockRefreshIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.453 12.893A8.5 8.5 0 0 1 4.638 16.25l-.25-.433m-.842-4.71A8.5 8.5 0 0 1 19.361 7.75l.25.433M3.493 18.066l.732-2.732 2.732.732m10.085-8.132 2.732.732.732-2.732M12 7.5V12l2.5 1.5"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClockRefreshIcon.displayName = 'ClockRefreshIcon'
ClockRefreshIcon.defaultProps = iconDefaultProps

export type { ClockRefreshIconProps }
export { ClockRefreshIcon }
