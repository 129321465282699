import React from 'react'

export type HookOptions = {
  /**
   * A boolean flag to determine if the scroll lock is active.
   */
  isActive?: boolean
}

/**
 * Temporarily disables scrolling on the document body.
 */
export function useBodyScrollLock({ isActive }: HookOptions): void {
  const originalStyle = React.useRef(document.body.style.overflow)

  React.useEffect(() => {
    const originalStyleRef = originalStyle.current

    if (!isActive) {
      return
    }

    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = originalStyleRef
    }
  }, [isActive])
}
