import * as CSS from 'csstype'
import Portal from '@reach/portal'
import React, { cloneElement, forwardRef } from 'react'
import styled from 'styled-components'

import {
  Position,
  TooltipPopup,
  TriggerParams,
  useTooltip,
} from '@reach/tooltip'

import { assignRef, toPx, toRem } from '../utils'
import { colors, Size, sizing, spacing, typography } from '../tokens'

import Text from '../Text'

type Rect = Pick<Parameters<Position>, '0'>['0']

type TooltipProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * If `true`, the tooltip will not be displayed on hover. Use this prop to
   * conditionally render the tooltip based on its contents (`children`).
   */
  disabled?: boolean
  /**
   * A boolean flag used to programmatically control the tooltip's visibility.
   */
  isVisible?: boolean
  label?: React.ReactNode
  size?: Size
  textAlign?: CSS.Property.TextAlign
}

const defaultProps: Partial<TooltipProps> = {
  className: '',
}

const positionCenter: Position = (triggerRect, tooltipRect) => {
  const triggerCenter = (triggerRect?.left || 0) + (triggerRect?.width || 0) / 2
  const left = triggerCenter - (tooltipRect?.width || 0) / 2
  const maxLeft = window.innerWidth - (tooltipRect?.width || 0) - 2

  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top:
      (triggerRect?.bottom || 0) +
      toPx(sizing.tooltipTick) +
      window.scrollY +
      toPx(spacing.tooltip.gap) / 2,
  }
}

const Popup = styled(TooltipPopup)<{
  $size?: Size
  $textAlign?: TooltipProps['textAlign']
}>`
  background-color: ${colors.tooltip.backgroundColor};
  border-radius: ${sizing.radius.small};
  border: 0 none;
  box-shadow: 0 ${sizing.shadowYOffset} ${sizing.shadowRadius} ${colors.shadow};
  color: ${colors.tooltip.color};
  font-size: ${typography.tooltip.fontSize};
  font-weight: ${typography.tooltip.fontWeight};
  line-height: ${typography.tooltip.lineHeight};
  text-align: ${({ $textAlign }) => $textAlign || 'center'};
  white-space: pre-line;
  padding: ${spacing.tooltip.popup};
  pointer-events: none;
  position: absolute;
  z-index: 1000;

  ${Text} {
    color: ${colors.tooltip.color};
  }

  ${({ $size = defaultProps.size }): string =>
    $size != null
      ? `max-width: ${sizing.tooltip[$size as Size]}; width: 100%;`
      : ''}
`

const TooltipTick = styled.div<{ $triggerRect?: Rect }>`
  border-left: ${sizing.tooltipTick} solid transparent;
  border-right: ${sizing.tooltipTick} solid transparent;
  border-bottom: ${sizing.tooltipTick} solid ${colors.tooltip.backgroundColor};
  left: ${({ $triggerRect }) =>
    toRem(
      ($triggerRect?.left || 0) -
        toPx(sizing.tooltipTick) +
        ($triggerRect?.width || 0) / 2,
    )};
  top: ${({ $triggerRect }) =>
    toRem(
      ($triggerRect?.bottom || 0) +
        window.scrollY +
        toPx(spacing.tooltip.tick) / 2,
    )};
  height: 0;
  position: absolute;
  width: 0;
  z-index: 1000;
`

const StyledTooltip = styled(
  forwardRef<HTMLDivElement, TooltipProps>(function Tooltip(
    {
      'aria-label': ariaLabel,
      children,
      disabled,
      label,
      size,
      textAlign,
      isVisible,
      ...props
    }: TooltipProps,
    ref,
  ) {
    const [trigger, tooltip] = useTooltip(props)

    if (disabled) {
      return <>{children}</>
    }

    return (
      <>
        {cloneElement(
          children as React.ReactElement<TriggerParams<HTMLElement>>,
          {
            ...props,
            ...trigger,
            ref: (value: HTMLElement) => {
              assignRef(trigger.ref, value)
              assignRef(ref, value)
            },
          },
        )}

        {(tooltip.isVisible || isVisible) && (
          <Portal>
            <TooltipTick $triggerRect={tooltip.triggerRect} />
          </Portal>
        )}

        <Popup
          {...tooltip}
          $size={size}
          $textAlign={textAlign}
          aria-label={ariaLabel || label?.toLocaleString()}
          isVisible={tooltip.isVisible || isVisible}
          label={label}
          position={positionCenter}
        />
      </>
    )
  }),
)``

StyledTooltip.displayName = 'Tooltip'
StyledTooltip.defaultProps = defaultProps

export type { TooltipProps }
export { defaultProps as TooltipDefaultProps }
export default StyledTooltip
