import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChromeCastIconProps = IconProps

const ChromeCastIcon = forwardRef<SVGSVGElement, ChromeCastIconProps>(
  (props: ChromeCastIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 12.05A9 9 0 0 1 9.95 20M2 16.1A5 5 0 0 1 5.9 20M2 20h.01M14 21h3.2c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 18.72 22 17.88 22 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 3 18.88 3 17.2 3H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 5.28 2 6.12 2 7.8V8"
        fill="none"
      ></path>
    </Icon>
  ),
)

ChromeCastIcon.displayName = 'ChromeCastIcon'
ChromeCastIcon.defaultProps = iconDefaultProps

export type { ChromeCastIconProps }
export { ChromeCastIcon }
