import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type InfoHexagonIconProps = IconProps

const InfoHexagonIcon = forwardRef<SVGSVGElement, InfoHexagonIconProps>(
  (props: InfoHexagonIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 16v-4m0-4h.01M3 7.942v8.117c0 .342 0 .514.05.666a1 1 0 0 0 .215.364c.109.119.258.202.558.368l7.4 4.111c.284.158.425.237.575.268.133.027.27.027.403 0 .15-.031.292-.11.576-.268l7.4-4.11c.3-.167.45-.25.558-.369a.999.999 0 0 0 .215-.364c.05-.152.05-.324.05-.666V7.942c0-.343 0-.514-.05-.667a1 1 0 0 0-.215-.364c-.109-.119-.258-.202-.558-.368l-7.4-4.111c-.284-.158-.425-.237-.575-.267a1 1 0 0 0-.403 0c-.15.03-.292.11-.576.267l-7.4 4.11c-.3.167-.45.25-.558.369a1 1 0 0 0-.215.364C3 7.428 3 7.599 3 7.942Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

InfoHexagonIcon.displayName = 'InfoHexagonIcon'
InfoHexagonIcon.defaultProps = iconDefaultProps

export type { InfoHexagonIconProps }
export { InfoHexagonIcon }
