import React from 'react'

export function EBTCardIcon(): JSX.Element {
  return (
    <g>
      <rect width="46" height="32" rx="4" fill="#FFFFFF" />
      <path
        d="M11.7046 20V10.705H18.1266V12.759H14.3566V14.358H17.3596V16.256H14.3566V17.946H18.3346V20H11.7046ZM19.6646 20V10.705H23.8116C26.0086 10.705 26.9446 11.693 26.9446 13.136V13.188C26.9446 14.228 26.4116 14.917 25.3716 15.19C26.5156 15.385 27.2306 16.074 27.2306 17.361V17.413C27.2306 19.077 26.1516 20 23.8506 20H19.6646ZM22.2646 18.219H23.3696C24.2146 18.219 24.6046 17.829 24.6046 17.14V17.088C24.6046 16.386 24.2276 16.022 23.2916 16.022H22.2646V18.219ZM22.2646 14.397H23.2786C24.1106 14.397 24.4226 14.111 24.4226 13.448V13.396C24.4226 12.759 24.0846 12.473 23.2786 12.473H22.2646V14.397ZM30.1419 20V12.759H27.7369V10.705H35.2249V12.759H32.8199V20H30.1419Z"
        fill="#3F3F46"
      />
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" stroke="#F4F4F5" />
    </g>
  )
}
