import React from 'react'

export function VisaCardIcon(): JSX.Element {
  return (
    <g>
      <rect width="46" height="32" rx="4" fill="#FFFFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3341 21.1444H11.5877L9.52833 13.0565C9.43058 12.6845 9.22304 12.3556 8.91774 12.2006C8.15584 11.811 7.31628 11.501 6.40039 11.3446V11.0332H10.8245C11.4351 11.0332 11.893 11.501 11.9694 12.0442L13.0379 17.8782L15.7829 11.0332H18.4529L14.3341 21.1444ZM19.9794 21.1444H17.3857L19.5214 11.0332H22.1151L19.9794 21.1444ZM25.4707 13.8343C25.547 13.2898 26.0049 12.9784 26.5392 12.9784C27.3788 12.9002 28.2933 13.0566 29.0565 13.4448L29.5145 11.2678C28.7512 10.9564 27.9117 10.8 27.1498 10.8C24.6324 10.8 22.8007 12.2006 22.8007 14.1444C22.8007 15.6231 24.0982 16.3995 25.0141 16.8673C26.0049 17.3337 26.3865 17.6451 26.3102 18.1114C26.3102 18.811 25.547 19.1224 24.7851 19.1224C23.8692 19.1224 22.9533 18.8892 22.1151 18.4997L21.6571 20.678C22.573 21.0662 23.5639 21.2226 24.4798 21.2226C27.3024 21.2994 29.0565 19.9002 29.0565 17.8001C29.0565 15.1553 25.4707 15.0003 25.4707 13.8343ZM38.1337 21.1444L36.0743 11.0332H33.8623C33.4043 11.0332 32.9464 11.3446 32.7937 11.811L28.9802 21.1444H31.6502L32.1831 19.667H35.4637L35.769 21.1444H38.1337ZM34.2439 13.7562L35.0058 17.5669H32.8701L34.2439 13.7562Z"
        fill="#3F3F46"
      />
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" stroke="#F4F4F5" />
    </g>
  )
}
