import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SearchRefractionIconProps = IconProps

const SearchRefractionIcon = forwardRef<
  SVGSVGElement,
  SearchRefractionIconProps
>((props: SearchRefractionIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m21 21-4.35-4.35M11 6a5 5 0 0 1 5 5m3 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
      fill="none"
    ></path>
  </Icon>
))

SearchRefractionIcon.displayName = 'SearchRefractionIcon'
SearchRefractionIcon.defaultProps = iconDefaultProps

export type { SearchRefractionIconProps }
export { SearchRefractionIcon }
