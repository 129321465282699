import {
  tokens as spaceTokens,
  tokensWithMetadata as spaceTokensWithMetadata,
} from './space'

import {
  tokens as sizeTokens,
  tokensWithMetadata as sizeTokensWithMetadata,
} from './size'

import {
  tokens as colorTokens,
  tokensWithMetadata as colorTokensWithMetadata,
} from './color'

import {
  tokens as typographyTokens,
  tokensWithMetadata as typographyTokensWithMetadata,
} from './typography'

import {
  tokens as elevationTokens,
  tokensWithMetadata as elevationTokensWithMetadata,
} from './elevation'

export const foundations = {
  color: colorTokens,
  elevation: elevationTokens,
  size: sizeTokens,
  space: spaceTokens,
  typography: typographyTokens,
} as const

export const foundationsWithMetadata = {
  color: colorTokensWithMetadata,
  elevation: elevationTokensWithMetadata,
  size: sizeTokensWithMetadata,
  space: spaceTokensWithMetadata,
  typography: typographyTokensWithMetadata,
} as const

export type { TokenFoundationColor } from './color'
export { TOKEN_SPACE_BASE_UNIT } from './space'
export type { TokenFoundationSpace } from './space'
export { TOKEN_SIZE_CONTAINER_BASE_UNIT } from './size'

export type {
  TokenFoundationSize,
  TokenFoundationSizeAvatar,
  TokenFoundationSizeContainer,
  TokenFoundationSizeIcon,
} from './size'

export { TOKEN_TYPOGRAPHY_BASE_FONT_SIZE } from './typography'

export type {
  TokenFoundationTypography,
  TokenFoundationTypographyHeadingLineHeight,
  TokenFoundationTypographyHeadingSize,
  TokenFoundationTypographyLineHeight,
  TokenFoundationTypographySize,
  TokenFoundationTypographyTextLineHeight,
  TokenFoundationTypographyTextSize,
  TokenFoundationTypographyWeight,
} from './typography'

export type {
  TokenFoundationElevation,
  TokenFoundationElevationShadow,
} from './elevation'
