import React from 'react'

import { CalendarInput, CalendarInputProps } from '../CalendarInput'
import { formatDate } from '../utils'

type Props = CalendarInputProps

/**
 * Uses the `<CalendarInput />` component internally to provide a calendar
 * input component used to selected a single date.
 *
 * @example
 * <DateInput
 *   renderTriggerLabel={({ selectedDates }) =>
 *     selectedDates.length ? formatDate(selectedDates[0]) : 'Select Date'
 *   }
 * />
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/}
 */
const DateInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(function DateInput(
  { calendarOptions, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  return (
    <CalendarInput
      dialogAriaLabel="Select Date"
      calendarOptions={{ ...calendarOptions, maxSelected: 1 }}
      renderTriggerLabel={
        props.renderTriggerLabel ||
        (({ selectedDates }) =>
          selectedDates.length > 0
            ? formatDate(selectedDates[0])
            : 'Select Date')
      }
      {...props}
      ref={ref}
    />
  )
})

DateInput.displayName = 'DateInput'
DateInput.defaultProps = {}

export { DateInput }
export type { Props as DateInputProps }
