import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BluetoothConnectIconProps = IconProps

const BluetoothConnectIcon = forwardRef<
  SVGSVGElement,
  BluetoothConnectIconProps
>((props: BluetoothConnectIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m3 7 12 10-6 5V2l6 5L3 17m15-5h.01M15 12h.01M21 12h.01"
      fill="none"
    ></path>
  </Icon>
))

BluetoothConnectIcon.displayName = 'BluetoothConnectIcon'
BluetoothConnectIcon.defaultProps = iconDefaultProps

export type { BluetoothConnectIconProps }
export { BluetoothConnectIcon }
