import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Trash04IconProps = IconProps

const Trash04Icon = forwardRef<SVGSVGElement, Trash04IconProps>(
  (props: Trash04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 3h6M3 6h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.485c-.34-.598-.394-1.387-.499-2.966L5 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Trash04Icon.displayName = 'Trash04Icon'
Trash04Icon.defaultProps = iconDefaultProps

export type { Trash04IconProps }
export { Trash04Icon }
