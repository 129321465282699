import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Cursor03IconProps = IconProps

const Cursor03Icon = forwardRef<SVGSVGElement, Cursor03IconProps>(
  (props: Cursor03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.712 3.674c-.541-.212-.811-.319-.984-.262a.5.5 0 0 0-.316.317c-.057.172.049.442.262.983l5.3 13.456c.172.435.258.652.398.74a.5.5 0 0 0 .408.055c.158-.047.298-.234.579-.609l2.14-2.854 3.45 4.743c.19.26.284.391.405.446a.5.5 0 0 0 .338.026c.128-.035.242-.149.47-.377l3.176-3.176c.227-.228.341-.342.377-.47a.5.5 0 0 0-.027-.337c-.054-.121-.185-.216-.446-.406L15.5 12.5l2.854-2.14c.374-.281.561-.422.608-.58a.5.5 0 0 0-.055-.408c-.087-.14-.305-.225-.74-.397l-13.455-5.3Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Cursor03Icon.displayName = 'Cursor03Icon'
Cursor03Icon.defaultProps = iconDefaultProps

export type { Cursor03IconProps }
export { Cursor03Icon }
