import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { CheckIcon } from '@vori/gourmet-icons'

import Flex from '../Flex'
import Spacer from '../Spacer'
import Text from '../Text'

import { colors, base, sizing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type StepProps = React.HTMLAttributes<HTMLDivElement> & {
  description?: string
  isActive?: boolean
  isCompleted?: boolean
  label: string
  vertical?: boolean
  withTrack?: boolean
}

const defaultProps: Partial<StepProps> = {
  className: '',
  description: '',
  isActive: false,
  isCompleted: false,
  vertical: false,
  withTrack: false,
}

const StepNumber = styled(Flex)<{
  $isActive?: boolean
  $isCompleted?: boolean
}>`
  background-color: ${({ $isActive, $isCompleted }) =>
    $isActive || $isCompleted
      ? colors.progress.step.number.active.backgroundColor
      : colors.progress.step.number.backgroundColor};
  color: ${({ $isActive, $isCompleted }) =>
    $isActive || $isCompleted
      ? colors.progress.step.number.active.color
      : colors.progress.step.number.color};
  border-radius: ${sizing.radius.rounded};
  height: ${sizing.progressStep};
  width: ${sizing.progressStep};
  transition: ${toTransitions(['background-color', 'color'], 'ease')};

  [data-gourmet-icon],
  ${Text} {
    color: ${({ $isActive, $isCompleted }) =>
      $isActive || $isCompleted
        ? colors.progress.step.number.active.color
        : colors.progress.step.number.color};
    transition: ${toTransitions(['color'], 'ease')};
  }
`

const StyledStep = styled(
  forwardRef<HTMLDivElement, StepProps>(function Step(
    {
      children,
      className,
      description,
      isActive,
      isCompleted,
      label,
      vertical,
      withTrack,
      ...props
    }: Partial<StepProps>,
    ref,
  ) {
    return (
      <>
        <Flex
          {...props}
          aria-label={description}
          center
          className={className}
          column={!vertical}
          flex="1 1 0px"
          ref={ref}
        >
          <StepNumber center $isActive={isActive} $isCompleted={isCompleted}>
            {isCompleted ? <CheckIcon /> : <Text size="body">{children}</Text>}
          </StepNumber>
          <Spacer inline={vertical} size="tiny" />
          <Text
            align="center"
            bold={isActive}
            size="body"
            variant={isActive ? 'primary' : 'secondary'}
          >
            {label}
          </Text>
        </Flex>
        {withTrack && <Spacer inline={!vertical} size="xLarge" />}
      </>
    )
  }),
)<StepProps>`
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
    margin-right: 0;
  }

  ${({ vertical, withTrack }) =>
    withTrack
      ? `  &::after {
      content: '';
      background-color: ${colors.progress.step.track.backgroundColor};
      border-radius: ${sizing.radius.pill};
      height: ${
        vertical
          ? `calc(100% + ${base.spacing.xLarge} - ${sizing.progressStep} - (${base.spacing.small} * 2))`
          : '1.5px'
      };
      left: ${
        vertical
          ? `calc(${sizing.progressStep} / 2 - 0.75px)`
          : `calc(50% + ${sizing.progressStep})`
      };
      top: ${
        vertical
          ? `calc(${sizing.progressStep} + ${base.spacing.small})`
          : `calc(${sizing.progressStep} / 2 - 0.75px)`
      };
      position: absolute;
      width: ${vertical ? '1.5px' : '100%'};
    }`
      : ''}
`

StyledStep.displayName = 'Step'
StyledStep.defaultProps = defaultProps

export type { StepProps }
export { defaultProps as StepDefaultProps }
export default StyledStep
