import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GridDotsRightIconProps = IconProps

const GridDotsRightIcon = forwardRef<SVGSVGElement, GridDotsRightIconProps>(
  (props: GridDotsRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 3h.01m-.01 9h.01m-.01 9h.01M16.5 3h.01m-.01 9h.01m-.01 9h.01M12 3h.01M12 12h.01M12 21h.01M12 16.5h.01m-.01-9h.01M3 3h.01M3 12h.01M3 21h.01M3 16.5h.01M3 7.5h.01M21 21V3"
        fill="none"
      ></path>
    </Icon>
  ),
)

GridDotsRightIcon.displayName = 'GridDotsRightIcon'
GridDotsRightIcon.defaultProps = iconDefaultProps

export type { GridDotsRightIconProps }
export { GridDotsRightIcon }
