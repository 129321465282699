import React from 'react'
import { foundations } from '@vori/gourmet-tokens'

import {
  AppFrame as OriginalAppFrame,
  AppFrameProps as OriginalAppFrameProps,
  AppHeader,
  AppHeaderProps,
  FlexNext as Flex,
  Loader,
  TextNext as Text,
} from '@vori/gourmet-components'

import { ClockRewindIcon } from '@vori/gourmet-icons'

import {
  AppSidebar,
  DevUserGate,
  RetailUserGate,
  VendorUserGate,
} from '@vori/dashboard-components'

import { getTimeZone, toReadableEnum } from '@vori/dashboard-utils'

import { routes as retailRoutes } from '@vori/dashboard-routes/retail'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'
import { useIntegrations } from '@vori/dashboard-integrations/useIntegrations'
import { usePageContainerProps } from '@vori/dashboard-hooks/usePageContainerProps'
import { usePageHeaderActions } from '@vori/dashboard-hooks/usePageHeaderActions'
import { usePageTitle } from '@vori/dashboard-hooks/usePageTitle'

import { AuthRoutes } from './auth'
import { useGlobalStore } from '../state-management/store'

import {
  Modal,
  ModalContent,
  ModalHeader,
  useModal,
} from 'gourmet/web-components/src/ModalNext'
import { GlobalModalSlice } from '../state-management/GlobalModalStore'
import { UserSlice } from '../state-management/UserStore'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'
import { FeatureFlags } from '@vori/dashboard-constants'
import ProductSearchTrigger from '@vori/dashboard-components/products/ProductSearch/ProductSearchTrigger'

const DevRoutes = React.lazy(() => import('./dev'))
const RetailRoutes = React.lazy(() => import('./retail'))
const VendorRoutes = React.lazy(() => import('./vendor'))

type AppFrameProps = {
  isUsingStoreTimeZone: boolean
  pageContainerProps: OriginalAppFrameProps['containerProps']
  pageHeaderActions: NonNullable<AppHeaderProps['rightContent']>
  pageTitle: string
  storeTimeZone: string
  showProductSearch?: boolean
}

function AppFrame({
  children,
  isUsingStoreTimeZone,
  pageContainerProps,
  pageHeaderActions,
  pageTitle,
  storeTimeZone,
  showProductSearch = false,
}: React.PropsWithChildren<AppFrameProps>): JSX.Element {
  const { user } = useCurrentUserState()
  const canUseGlobalProductSearch = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_GLOBAL_PRODUCT_SEARCH,
  )

  const selectedStoreID = useGlobalStore(
    (state: UserSlice) => state.metadata?.selectedStoreID,
  )

  const appHeaderRightContentCreation = () => {
    const content = user.state.isLoggedIn
      ? pageHeaderActions.concat([
          <Flex key="header-timeZone" centerY columnGap="space.025">
            <ClockRewindIcon />
            <Text style={{ color: foundations.color['color.base-white'] }}>
              {isUsingStoreTimeZone
                ? storeTimeZone
                : getTimeZone().split('/').map(toReadableEnum).join('/')}
            </Text>
          </Flex>,
        ])
      : []
    return content
  }

  const appHeaderCenterContentCreation = () => {
    const content = []
    if (showProductSearch && selectedStoreID && canUseGlobalProductSearch) {
      content.push(<ProductSearchTrigger />)
    }
    return content
  }
  return (
    <OriginalAppFrame
      containerProps={pageContainerProps}
      header={
        <AppHeader
          centerContent={appHeaderCenterContentCreation()}
          rightContent={appHeaderRightContentCreation()}
          title={pageTitle}
        />
      }
      sidebar={<AppSidebar />}
    >
      {children}
    </OriginalAppFrame>
  )
}

export function MainRoutes(): JSX.Element {
  useIntegrations()

  const pageContainerProps = usePageContainerProps()
  const pageHeaderActions = usePageHeaderActions()
  const pageTitle = usePageTitle()
  const { authenticationError, user } = useCurrentUserState()

  const isOpen = useGlobalStore((state: GlobalModalSlice) => state.isOpen)
  const showModal = useGlobalStore(
    (state: GlobalModalSlice) => state.toggleDisplay,
  )
  const modalTitle = useGlobalStore(
    (state: GlobalModalSlice) => state.modalTitle,
  )
  const showModalHeader = useGlobalStore(
    (state: GlobalModalSlice) => state.showModalHeader,
  )

  const { ...topLevelModal } = useModal({
    asModal: true,
    closeOnClickOutside: true,
    open: isOpen,
    onClose: () => {
      showModal(false)
    },
  })

  const modalDisplayContent = useGlobalStore(
    (state: GlobalModalSlice) => state.displayContent,
  )

  const fullBleedContent = useGlobalStore(
    (state: GlobalModalSlice) => state.fullBleedContent,
  )

  const isUsingStoreTimeZone = [
    retailRoutes.transactions.root.match(),
    retailRoutes.tills.root.match(),
  ].some(Boolean)

  const handleClickUpOutside = (event: React.MouseEvent<HTMLDialogElement>) => {
    const trueTarget = event.target as HTMLElement
    const containerClicked = Object.prototype.hasOwnProperty.call(
      trueTarget.attributes,
      'data-gourmet-modal-container',
    )
    if (containerClicked) {
      showModal(false)
    }
  }

  if (!user.state.isLoggedIn && user.state.isAuthenticating) {
    return (
      <AppFrame
        isUsingStoreTimeZone={isUsingStoreTimeZone}
        pageContainerProps={pageContainerProps}
        pageHeaderActions={pageHeaderActions || []}
        pageTitle={pageTitle}
        storeTimeZone={user.metadata.timeZone}
      >
        <Flex center direction="column" flex={1} fullWidth rowGap="space.025">
          <Loader size="large" />
          <Text variant="secondary">Authenticating session...</Text>
        </Flex>
      </AppFrame>
    )
  }

  if (!user.state.isLoggedIn && authenticationError) {
    return (
      <AppFrame
        isUsingStoreTimeZone={isUsingStoreTimeZone}
        pageContainerProps={pageContainerProps}
        pageHeaderActions={pageHeaderActions || []}
        pageTitle={pageTitle}
        storeTimeZone={user.metadata.timeZone}
      >
        <Flex center flex={1} fullWidth>
          <Text variant="negative">
            There was an error while authenticating your account, please try
            again.
          </Text>
        </Flex>
      </AppFrame>
    )
  }

  return (
    <AppFrame
      isUsingStoreTimeZone={isUsingStoreTimeZone}
      pageContainerProps={pageContainerProps}
      pageHeaderActions={pageHeaderActions || []}
      pageTitle={pageTitle}
      storeTimeZone={user.metadata.timeZone}
      showProductSearch={user.data.isRetailUser || false}
    >
      <React.Suspense
        fallback={
          <Flex center direction="column" flex={1} fullWidth rowGap="space.025">
            <Loader size="large" />
            <Text variant="secondary">Loading view...</Text>
          </Flex>
        }
      >
        <AuthRoutes />
        <RetailUserGate>
          <RetailRoutes
            key={`selected-store-${user.metadata.selectedStoreID}`}
          />
        </RetailUserGate>
        <VendorUserGate>
          <VendorRoutes />
        </VendorUserGate>
        <DevUserGate>
          <DevRoutes />
        </DevUserGate>
      </React.Suspense>
      <Modal {...topLevelModal} onClick={handleClickUpOutside}>
        <ModalContent {...(fullBleedContent && { style: { padding: 0 } })}>
          {showModalHeader === true && <ModalHeader title={modalTitle || ''} />}
          {modalDisplayContent}
        </ModalContent>
      </Modal>
    </AppFrame>
  )
}
