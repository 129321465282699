import * as HoverCard from '@radix-ui/react-hover-card'
import { keyframes, styled } from '@stitches/react'
import { FC } from 'react'
const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

export const HoverCardRoot: FC<HoverCard.HoverCardProps> = HoverCard.Root
export const HoverCardPortal: FC<HoverCard.HoverCardPortalProps> =
  HoverCard.Portal
export const HoverCardTrigger: FC<HoverCard.HoverCardTriggerProps> =
  HoverCard.Trigger

export const HoverCardContent: FC<HoverCard.HoverCardContentProps> = styled(
  HoverCard.Content,
  {
    borderRadius: 6,
    padding: 20,
    width: 300,
    zIndex: 999,
    backgroundColor: 'white',
    boxShadow:
      'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
)

export const HoverCardArrow: FC<HoverCard.HoverCardArrowProps> = styled(
  HoverCard.Arrow,
  {
    fill: 'white',
  },
)
