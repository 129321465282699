import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Wallet03IconProps = IconProps

const Wallet03Icon = forwardRef<SVGSVGElement, Wallet03IconProps>(
  (props: Wallet03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.5 14h.01M3 5v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H5a2 2 0 0 1-2-2Zm0 0a2 2 0 0 1 2-2h12m0 11a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Wallet03Icon.displayName = 'Wallet03Icon'
Wallet03Icon.defaultProps = iconDefaultProps

export type { Wallet03IconProps }
export { Wallet03Icon }
