import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CalendarCheck02IconProps = IconProps

const CalendarCheck02Icon = forwardRef<SVGSVGElement, CalendarCheck02IconProps>(
  (props: CalendarCheck02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 10H3m18 2.5V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 4 17.88 4 16.2 4H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 6.28 3 7.12 3 8.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 22 6.12 22 7.8 22H12m4-20v4M8 2v4m6.5 13 2 2 4.5-4.5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CalendarCheck02Icon.displayName = 'CalendarCheck02Icon'
CalendarCheck02Icon.defaultProps = iconDefaultProps

export type { CalendarCheck02IconProps }
export { CalendarCheck02Icon }
