import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Speedometer03IconProps = IconProps

const Speedometer03Icon = forwardRef<SVGSVGElement, Speedometer03IconProps>(
  (props: Speedometer03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12a7 7 0 0 1 7-7m4.5 2.5L12 12m10 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Speedometer03Icon.displayName = 'Speedometer03Icon'
Speedometer03Icon.defaultProps = iconDefaultProps

export type { Speedometer03IconProps }
export { Speedometer03Icon }
