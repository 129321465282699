import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Globe01IconProps = IconProps

const Globe01Icon = forwardRef<SVGSVGElement, Globe01IconProps>(
  (props: Globe01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.632 2.368c4.49 4.491 4.49 11.773 0 16.264-4.301 4.3-11.161 4.483-15.679.546-.197-.172-.295-.258-.34-.375a.521.521 0 0 1-.01-.322c.036-.12.134-.218.33-.415L5.15 15.85M18 10.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Globe01Icon.displayName = 'Globe01Icon'
Globe01Icon.defaultProps = iconDefaultProps

export type { Globe01IconProps }
export { Globe01Icon }
