import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Expand05IconProps = IconProps

const Expand05Icon = forwardRef<SVGSVGElement, Expand05IconProps>(
  (props: Expand05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m15 9 6-6m0 0h-6m6 0v6M9 9 3 3m0 0v6m0-6h6m0 12-6 6m0 0h6m-6 0v-6m12 0 6 6m0 0v-6m0 6h-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Expand05Icon.displayName = 'Expand05Icon'
Expand05Icon.defaultProps = iconDefaultProps

export type { Expand05IconProps }
export { Expand05Icon }
