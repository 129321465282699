import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowUpIconProps = IconProps

const ArrowUpIcon = forwardRef<SVGSVGElement, ArrowUpIconProps>(
  (props: ArrowUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 19V5m0 0-7 7m7-7 7 7"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowUpIcon.displayName = 'ArrowUpIcon'
ArrowUpIcon.defaultProps = iconDefaultProps

export type { ArrowUpIconProps }
export { ArrowUpIcon }
