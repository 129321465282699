import React from 'react'

type AccordionContextValue = {
  activeIndexes: Set<number>
  setActiveIndex: (index: number) => void
  unsetActiveIndex: (index: number) => void
}

const AccordionContext = React.createContext<AccordionContextValue>({
  activeIndexes: new Set(),
  setActiveIndex: () => void 0,
  unsetActiveIndex: () => void 0,
})

AccordionContext.displayName = 'AccordionContext'

type AccordionItemContextValue = {
  disabled: boolean
  isActive: boolean
  itemIndex: number
  panelID: string
  triggerID: string
}

const AccordionItemContext = React.createContext<AccordionItemContextValue>({
  disabled: false,
  isActive: false,
  itemIndex: 0,
  panelID: '',
  triggerID: '',
})

AccordionItemContext.displayName = 'AccordionItemContext'

export { AccordionContext, AccordionItemContext }
export type { AccordionContextValue, AccordionItemContextValue }
