import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SwitchHorizontal01IconProps = IconProps

const SwitchHorizontal01Icon = forwardRef<
  SVGSVGElement,
  SwitchHorizontal01IconProps
>((props: SwitchHorizontal01IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 17H4m0 0 4-4m-4 4 4 4M4 7h16m0 0-4-4m4 4-4 4"
      fill="none"
    ></path>
  </Icon>
))

SwitchHorizontal01Icon.displayName = 'SwitchHorizontal01Icon'
SwitchHorizontal01Icon.defaultProps = iconDefaultProps

export type { SwitchHorizontal01IconProps }
export { SwitchHorizontal01Icon }
