import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Bell01IconProps = IconProps

const Bell01Icon = forwardRef<SVGSVGElement, Bell01IconProps>(
  (props: Bell01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.354 21c.705.622 1.632 1 2.646 1s1.94-.378 2.646-1M18 8A6 6 0 1 0 6 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.089 1.936.015.182.054.252.2.36.133.099.732.099 1.928.099H18.61c1.196 0 1.795 0 1.927-.098.147-.11.186-.179.2-.361.014-.165-.353-.755-1.088-1.936C18.78 13.206 18 11.09 18 8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Bell01Icon.displayName = 'Bell01Icon'
Bell01Icon.defaultProps = iconDefaultProps

export type { Bell01IconProps }
export { Bell01Icon }
