import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Heading01IconProps = IconProps

const Heading01Icon = forwardRef<SVGSVGElement, Heading01IconProps>(
  (props: Heading01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 4v16M18 4v16M8 4H4m14 8H6m2 8H4m16 0h-4m4-16h-4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Heading01Icon.displayName = 'Heading01Icon'
Heading01Icon.defaultProps = iconDefaultProps

export type { Heading01IconProps }
export { Heading01Icon }
