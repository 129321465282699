import { TokensWithMetadataMap } from '../types'

export const tokens = {
  /**
   * Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.
   */
  'elevation.shadow-xs': '0 0.063rem 0.125rem 0 rgba(16, 24, 40, 0.05)',
  /**
   * Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.
   */
  'elevation.shadow-sm':
    '0 0.063rem 0.125rem 0 rgba(16, 24, 40, 0.06), 0 0.063rem 0.188rem 0 rgba(16, 24, 40, 0.10)',
  /**
   * Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.
   */
  'elevation.shadow-md':
    '0 0.125rem 0.25rem -0.125rem rgba(16, 24, 40, 0.06), 0 0.25rem 0.5rem -0.125rem rgba(16, 24, 40, 0.10)',
  /**
   * Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.
   */
  'elevation.shadow-lg':
    '0 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03), 0 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08)',
  /**
   * Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.
   */
  'elevation.shadow-xl':
    '0 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03), 0 1.25rem 1.5rem -0.25rem rgba(16, 24, 40, 0.08)',
  /**
   * Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.
   */
  'elevation.shadow-xxl': '0 1.5rem 3rem -0.75rem rgba(16, 24, 40, 0.18)',
  /**
   * Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.
   */
  'elevation.shadow-xxxl': '0 2rem 4rem -0.75rem rgba(16, 24, 40, 0.14)',
} as const

export const tokensWithMetadata: TokensWithMetadataMap<
  'elevation',
  typeof tokens
> = {
  'elevation.shadow-xs': {
    description:
      'Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.',
    introducedIn: 'v0.2.0',
    name: 'elevation.shadow-xs',
    type: 'elevation',
    value: tokens['elevation.shadow-xs'],
    metadata: {},
  },
  'elevation.shadow-sm': {
    description:
      'Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.',
    introducedIn: 'v0.2.0',
    name: 'elevation.shadow-sm',
    type: 'elevation',
    value: tokens['elevation.shadow-sm'],
    metadata: {},
  },
  'elevation.shadow-md': {
    description:
      'Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.',
    introducedIn: 'v0.2.0',
    name: 'elevation.shadow-md',
    type: 'elevation',
    value: tokens['elevation.shadow-md'],
    metadata: {},
  },
  'elevation.shadow-lg': {
    description:
      'Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.',
    introducedIn: 'v0.2.0',
    name: 'elevation.shadow-lg',
    type: 'elevation',
    value: tokens['elevation.shadow-lg'],
    metadata: {},
  },
  'elevation.shadow-xl': {
    description:
      'Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.',
    introducedIn: 'v0.2.0',
    name: 'elevation.shadow-xl',
    type: 'elevation',
    value: tokens['elevation.shadow-xl'],
    metadata: {},
  },
  'elevation.shadow-xxl': {
    description:
      'Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.',
    introducedIn: 'v0.2.0',
    name: 'elevation.shadow-xxl',
    type: 'elevation',
    value: tokens['elevation.shadow-xxl'],
    metadata: {},
  },
  'elevation.shadow-xxxl': {
    description:
      'Shadows allow you to add depth and realism to designs by positioning elements on a z-axis.',
    introducedIn: 'v0.2.0',
    name: 'elevation.shadow-xxxl',
    type: 'elevation',
    value: tokens['elevation.shadow-xxxl'],
    metadata: {},
  },
} as const

export type TokenFoundationElevation = keyof typeof tokens

export type TokenFoundationElevationShadow = Extract<
  TokenFoundationElevation,
  | 'elevation.shadow-xs'
  | 'elevation.shadow-sm'
  | 'elevation.shadow-md'
  | 'elevation.shadow-lg'
  | 'elevation.shadow-xl'
  | 'elevation.shadow-xxl'
  | 'elevation.shadow-xxxl'
>
