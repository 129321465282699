import React, { forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { colors, sizing } from '../tokens'

import Flex from '../Flex'

type AppSidebarProps = React.HTMLAttributes<HTMLDivElement>

const defaultProps: Partial<AppSidebarProps> = {
  className: '',
}

const styles: CSSObject = {
  backgroundColor: colors.appSidebar.backgroundColor,
  boxShadow: `${sizing.shadowXOffset} 0 ${sizing.shadowRadius} ${colors.shadow}`,

  '&:hover': {
    minWidth: sizing.sidebar,
    zIndex: 100,
  },

  '& .gourmet-appsidebar__link': {
    justifyContent: 'center',
  },

  '&:hover .gourmet-appsidebar__link': {
    justifyContent: 'flex-start',
  },

  '& .gourmet-appsidebar__toggle': {
    display: 'none',
  },

  '&:hover .gourmet-appsidebar__toggle': {
    display: 'inherit',
  },
}

const StyledAppSidebar = styled(
  forwardRef<HTMLDivElement, AppSidebarProps>(function AppSidebar(
    { children, ...props }: AppSidebarProps,
    ref,
  ) {
    return (
      <Flex
        {...props}
        column
        fullHeight
        justifyContent="space-between"
        ref={ref}
      >
        {children}
      </Flex>
    )
  }),
)<AppSidebarProps>(styles)

StyledAppSidebar.displayName = 'AppSidebar'
StyledAppSidebar.defaultProps = defaultProps

export type { AppSidebarProps }
export { defaultProps as AppSidebarDefaultProps, styles as AppSidebarStyles }
export default StyledAppSidebar
