import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Input from '../Input'
import InfoInput from '../InfoInput'

import Flex, { FlexProps } from '../Flex'

type InputGroupProps = React.HTMLAttributes<HTMLDivElement> &
  FlexProps & {
    withoutGaps?: boolean
  }

const defaultProps: Partial<InputGroupProps> = {
  ...Flex.defaultProps,
  withoutGaps: false,
}

/**
 * @deprecated Use `<FormFieldLayout>` in conjunction with `<InputNext>`.
 */
const StyledInputGroup = styled(
  forwardRef<HTMLDivElement, InputGroupProps>(function InputGroup(
    { children, withoutGaps, ...props }: InputGroupProps,
    ref,
  ) {
    return (
      <Flex {...props} alignItems="stretch" ref={ref}>
        {children}
      </Flex>
    )
  }),
)`
  ${Input}, ${InfoInput} {
    position: relative;
    z-index: 1;

    :focus,
    :focus-within,
    &[data-state-active='true'],
    &[data-state-focus='true'] {
      z-index: 2;
    }
  }

  > span:not(:last-child),
  > div:not(:last-child) {
    ${({ column, withoutGaps }): string =>
      column
        ? `margin-bottom: ${withoutGaps ? '-1px' : '1px'};`
        : `margin-right: ${withoutGaps ? '-1px' : '1px'};`}
  }

  > span,
  > div {
    :first-child ${Input}, :first-child ${InfoInput} {
      ${({ column }): string =>
        column
          ? 'border-bottom-right-radius: 0;'
          : 'border-top-right-radius: 0;'}

      ${({ column }): string =>
        column
          ? 'border-bottom-left-radius: 0;'
          : 'border-bottom-right-radius: 0;'}
    }

    :last-child ${Input}, :last-child ${InfoInput} {
      ${({ column }): string =>
        column ? 'border-top-left-radius: 0;' : 'border-top-left-radius: 0;'}

      ${({ column }): string =>
        column
          ? 'border-top-right-radius: 0;'
          : 'border-bottom-left-radius: 0;'}

      ${({ column }): string =>
        column ? 'margin-bottom: 0;' : 'margin-right: 0;'}
    }

    :not(:first-child):not(:last-child) {
      ${Input}, ${InfoInput} {
        border-radius: 0;
      }
    }
  }
`

StyledInputGroup.displayName = 'InputGroup'
StyledInputGroup.defaultProps = defaultProps

export type { InputGroupProps }
export { defaultProps as InputGroupDefaultProps }
export default StyledInputGroup
