import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BellOff01IconProps = IconProps

const BellOff01Icon = forwardRef<SVGSVGElement, BellOff01IconProps>(
  (props: BellOff01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.633 3.034A6 6 0 0 1 18 8c0 2.1.27 3.751.648 5.032M6.258 6.257A5.998 5.998 0 0 0 6 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.089 1.936.015.182.054.252.2.36.133.099.732.099 1.928.099H17m-7.646 4c.705.622 1.632 1 2.646 1s1.94-.378 2.646-1M21 21 3 3"
        fill="none"
      ></path>
    </Icon>
  ),
)

BellOff01Icon.displayName = 'BellOff01Icon'
BellOff01Icon.defaultProps = iconDefaultProps

export type { BellOff01IconProps }
export { BellOff01Icon }
