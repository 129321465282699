import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockRewindIconProps = IconProps

const ClockRewindIcon = forwardRef<SVGSVGElement, ClockRewindIconProps>(
  (props: ClockRewindIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m22.7 13.5-2-2-2 2M21 12a9 9 0 1 1-1.245-4.57M12 7v5l3 2"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClockRewindIcon.displayName = 'ClockRewindIcon'
ClockRewindIcon.defaultProps = iconDefaultProps

export type { ClockRewindIconProps }
export { ClockRewindIcon }
