import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ZapFastIconProps = IconProps

const ZapFastIcon = forwardRef<SVGSVGElement, ZapFastIconProps>(
  (props: ZapFastIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 17.5H3.5m3-5.5H2m7-5.5H4M17 3l-6.596 9.235c-.292.409-.438.613-.432.784a.5.5 0 0 0 .194.377c.135.104.386.104.889.104H16L15 21l6.596-9.235c.292-.409.438-.613.432-.784a.5.5 0 0 0-.194-.377c-.135-.104-.386-.104-.889-.104H16L17 3Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ZapFastIcon.displayName = 'ZapFastIcon'
ZapFastIcon.defaultProps = iconDefaultProps

export type { ZapFastIconProps }
export { ZapFastIcon }
