import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignTopArrow01IconProps = IconProps

const AlignTopArrow01Icon = forwardRef<SVGSVGElement, AlignTopArrow01IconProps>(
  (props: AlignTopArrow01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 3H3m9 18V7m0 0-7 7m7-7 7 7"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignTopArrow01Icon.displayName = 'AlignTopArrow01Icon'
AlignTopArrow01Icon.defaultProps = iconDefaultProps

export type { AlignTopArrow01IconProps }
export { AlignTopArrow01Icon }
