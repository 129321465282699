import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AtSignIconProps = IconProps

const AtSignIcon = forwardRef<SVGSVGElement, AtSignIconProps>(
  (props: AtSignIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

AtSignIcon.displayName = 'AtSignIcon'
AtSignIcon.defaultProps = iconDefaultProps

export type { AtSignIconProps }
export { AtSignIcon }
