import omit from 'lodash/omit'
import pick from 'lodash/pick'
import React from 'react'

import {
  composeEventHandlers,
  composeRefs,
  getInputAriaProps,
  triggerInputOnChange,
} from '../utils'

import { Flex } from '../FlexNext'
import { Input, InputProps } from '../InputNext'
import { RangeInputCoreProps, RangeInputProps } from './types'
import { Text } from '../TextNext'
import { useControlledState } from '../hooks'
import { VisuallyHiddenInput } from '../VisuallyHiddenInput'

const INHERITED_ARIA_PROPS = [
  'aria-describedby',
  'aria-errormessage',
  'aria-invalid',
  'aria-labelledby',
  'data-gourmet-formfield-input',
  'id',
] as const

const defaultProps: Partial<RangeInputCoreProps> = {}

/**
 * The `<RangeInput>` component enables the user to enter a range value.
 *
 * @example
 * <FormField
 *   description="Please enter a time range"
 *   label="Filter by transaction time"
 * >
 *   <RangeInput
 *     maxValueInputProps={{ leftIcon: <Hourglass03Icon />, type: 'time' }}
 *     minValueInputProps={{ leftIcon: <Hourglass03Icon />, type: 'time' }}
 *   />
 * </FormField>
 */
const RangeInput = React.forwardRef<HTMLInputElement, RangeInputProps>(
  function RangeInput(
    {
      defaultMaxValue,
      defaultMinValue,
      defaultValue,
      descriptionID,
      errorID,
      labelID,
      maxValue: controlledMaxValue,
      maxValueInputProps: controlledMaxValueInputProps,
      maxValueInputRef,
      minValue: controlledMinValue,
      minValueInputProps: controlledMinValueInputProps,
      minValueInputRef,
      renderMaxInput,
      renderMinInput,
      value: controlledValue,
      ...props
    }: RangeInputProps,
    ref,
  ): JSX.Element {
    const minValueInheritedAriaProps = React.useMemo(
      () => pick(props, INHERITED_ARIA_PROPS),
      [props],
    )

    const inputInheritedAriaProps = React.useMemo(
      () => omit(props, INHERITED_ARIA_PROPS),
      [props],
    )

    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(
      null,
    )

    const [value, setValue] = useControlledState<
      React.InputHTMLAttributes<HTMLInputElement>['value']
    >({
      componentName: 'RangeInput (value)',
      controlledValue: controlledValue,
      defaultValue: defaultValue || '',
    })

    const [maxValue, setMaxValue] = useControlledState<
      string | number | undefined
    >({
      componentName: 'RangeInput (maxValue)',
      controlledValue: Array.isArray(controlledMaxValue)
        ? controlledMaxValue[1]
        : controlledMaxValue,
      defaultValue:
        (value
          ? Array.isArray(value)
            ? value[1]
            : String(value).split(',')[1]
          : defaultMaxValue) ||
        controlledMaxValueInputProps?.defaultValue ||
        '',
    })

    const [minValue, setMinValue] = useControlledState<
      string | number | undefined
    >({
      componentName: 'RangeInput (minValue)',
      controlledValue: Array.isArray(controlledMinValue)
        ? controlledMinValue[0]
        : controlledMinValue,
      defaultValue:
        (value
          ? Array.isArray(value)
            ? value[0]
            : String(value).split(',')[0]
          : defaultMinValue) ||
        controlledMinValueInputProps?.defaultValue ||
        '',
    })

    const minValueInputProps = React.useMemo<InputProps>(
      () => ({
        fullWidth: true,
        placeholder: 'Min',
        ...controlledMinValueInputProps,
        ...minValueInheritedAriaProps,
        value: minValue,
        onChange: composeEventHandlers(
          controlledMinValueInputProps?.onChange,
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setMinValue(event.target.value)

            triggerInputOnChange(
              inputRef,
              maxValue
                ? [event.target.value, String(maxValue)]
                : [event.target.value],
            )
          },
        ),
      }),
      [
        controlledMinValueInputProps,
        inputRef,
        maxValue,
        minValue,
        minValueInheritedAriaProps,
        setMinValue,
      ],
    )

    const maxValueInputProps = React.useMemo<InputProps>(() => {
      const { id, ...minValueInheritedAriaPropsWithoutID } =
        minValueInheritedAriaProps

      return {
        fullWidth: true,
        placeholder: 'Max',
        ...controlledMaxValueInputProps,
        ...minValueInheritedAriaPropsWithoutID,
        value: maxValue,
        onChange: composeEventHandlers(
          controlledMaxValueInputProps?.onChange,
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setMaxValue(event.target.value)

            triggerInputOnChange(
              inputRef,
              minValue
                ? [String(minValue), event.target.value]
                : ['', event.target.value],
            )
          },
        ),
      }
    }, [
      controlledMaxValueInputProps,
      inputRef,
      maxValue,
      minValue,
      minValueInheritedAriaProps,
      setMaxValue,
    ])

    return (
      <Flex
        center
        data-gourmet-range-input=""
        data-maximum-value={maxValue}
        data-minimum-value={minValue}
        data-value={[minValue, maxValue]}
        gap="space.075"
        justifyContent="space-between"
        role="group"
      >
        <VisuallyHiddenInput
          {...inputInheritedAriaProps}
          {...getInputAriaProps({ labelID, descriptionID, errorID })}
          {...((Boolean(minValueInputProps['aria-invalid']) ||
            Boolean(maxValueInputProps['aria-invalid'])) && {
            'aria-invalid': 'true',
          })}
          ref={composeRefs([setInputRef, ref])}
          onChange={composeEventHandlers(
            inputInheritedAriaProps.onChange,
            (event) => {
              setValue(event.target.value)
            },
          )}
          value={value}
        />

        {renderMinInput != null ? (
          renderMinInput(minValueInputProps)
        ) : (
          <Input {...minValueInputProps} ref={maxValueInputRef} />
        )}

        <Text data-hidden="true">—</Text>

        {renderMaxInput != null ? (
          renderMaxInput(maxValueInputProps)
        ) : (
          <Input {...maxValueInputProps} ref={minValueInputRef} />
        )}
      </Flex>
    )
  },
)

RangeInput.displayName = 'RangeInput'
RangeInput.defaultProps = defaultProps

export { RangeInput, defaultProps as rangeInputDefaultProps }
