import { useClientId, usePreviousValue } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { sizing } from '../tokens'
import { useControlledState } from '../hooks'

import {
  composeRefs,
  createOnChangeEvent,
  noop,
  toRem,
  toTransitions,
} from '../utils'

import { Flex } from '../FlexNext'
import { Text } from '../TextNext'
import { VisuallyHiddenInput } from '../VisuallyHiddenInput'

import { RadioInputProps } from './types'
import { useRadioInputFieldContext } from '../RadioInputField'

function styles(): CSSObject {
  return {
    '&': {
      position: 'relative',

      '&:hover [data-gourmet-radio-input-control]': {
        backgroundColor: '#EBE9FE',
        borderColor: '#6038EF',
      },

      '[data-gourmet-radio-input-control-container]': {
        cursor: 'pointer',

        transition: toTransitions(
          ['background-color', 'border-color', 'box-shadow'],
          'ease',
        ),
      },

      '[data-gourmet-radio-input-control]': {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderColor: '#D1D1D6',
        borderRadius: sizing.radius.rounded,
        borderStyle: 'solid',
        borderWidth: toRem(1),
        display: 'inline-flex',
        flexShrink: 0,
        height: toRem(20),
        justifyContent: 'center',
        width: toRem(20),

        transition: toTransitions(
          ['background-color', 'border-color', 'box-shadow'],
          'ease',
        ),

        '&::after': {
          backgroundColor: 'transparent',
          borderRadius: sizing.radius.rounded,
          content: '""',
          display: 'block',
          height: toRem(8),
          width: toRem(8),

          transition: toTransitions(
            ['background-color', 'border-color', 'box-shadow'],
            'ease',
          ),
        },
      },

      '[data-gourmet-radio-input-input]': {
        '&:checked + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-control]':
          {
            backgroundColor: '#EBE9FE',
            borderColor: '#6038EF',

            '&::after': {
              backgroundColor: '#6038EF',
            },
          },

        [[
          '&:focus + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-control]',
          '&:focus:checked + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-control]',
          '&:active:not(:disabled) + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-control]',
          '&:active:checked:not(:disabled) + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-control]',
        ].join(',')]: {
          backgroundColor: '#F4F3FF',
          borderColor: '#BDB4FE',
          boxShadow: `0 0 0 ${toRem(2)} #F4F3FF, 0 0 0 ${toRem(4)} #6038EF`,
        },

        '&:disabled + [data-gourmet-radio-input-control-container]': {
          cursor: 'default',

          '& [data-gourmet-radio-input-control]': {
            backgroundColor: '#F4F4F5',
            borderColor: '#D1D1D6',
          },
        },

        '&:disabled + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-label]':
          {
            color: '#3F3F46',
          },

        '&:checked:disabled + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-control]::after':
          {
            backgroundColor: '#D1D1D6',
          },
      },

      '&[data-variant="container"] [data-gourmet-radio-input-control-container]':
        {
          backgroundColor: '#FFFFFF',
          borderColor: '#E4E4E7',
          borderRadius: toRem(8),
          borderStyle: 'solid',
          borderWidth: toRem(1),
          padding: toRem(16),

          '&:hover': {
            borderColor: '#BDB4FE',
          },
        },

      '&[data-variant="container"] [data-gourmet-radio-input-input]': {
        '&:focus + [data-gourmet-radio-input-control-container]': {
          borderColor: '#BDB4FE',
          boxShadow: `0 0 0 ${toRem(4)} #F4EBFF`,
        },

        '&:checked + [data-gourmet-radio-input-control-container]': {
          backgroundColor: '#F4F3FF',
          borderColor: '#6038EF',
        },

        '&:checked:not(:disabled) + [data-gourmet-radio-input-control-container] [data-gourmet-radio-input-description]':
          {
            color: '#6038EF',
          },

        '&:disabled + [data-gourmet-radio-input-control-container]': {
          backgroundColor: '#FAFAFA',
          borderColor: '#E4E4E7',
        },
      },
    },
  }
}

const RadioInputContainer = styled.label(styles)

/**
 * Radio buttons are a common way to allow users to make a single selection
 * from a list of options. Since only one radio button can be selected at a
 * time (within the same group), each available choice must be its own
 * item and label.
 *
 * @example
 * <>
 *  <RadioInput label="1" name="some_radio" value="1" />
 *  <RadioInput label="2" name="some_radio" value="2" />
 * </>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/radio/}
 */
const RadioInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<RadioInputProps>
>(function RadioInput(
  {
    defaultChecked,
    description,
    fullWidth,
    id: controlledID,
    label,
    withContainer,
    withRightAlignedInput,
    ...props
  }: React.PropsWithChildren<RadioInputProps>,
  ref,
): JSX.Element {
  const [defaultID] = useClientId('gourmet-radio')

  const [inputID] = useControlledState({
    componentName: 'RadioInput',
    controlledValue: controlledID,
    defaultValue: defaultID,
  })

  const [elementRef, setElementRef] = React.useState<HTMLInputElement | null>(
    null,
  )

  const radioInputFieldContext = useRadioInputFieldContext()

  const propsFromContext =
    React.useMemo<React.InputHTMLAttributes<HTMLInputElement> | null>(() => {
      if (!radioInputFieldContext) {
        return null
      }

      const isSelectedInput =
        radioInputFieldContext.isCheckedValue(props.value) ||
        defaultChecked === props.value

      const calculatedPropsFromContext = {
        checked: isSelectedInput,
        tabIndex: radioInputFieldContext.isFocusedValue(props.value) ? 0 : -1,
      }

      return calculatedPropsFromContext
    }, [defaultChecked, props.value, radioInputFieldContext])

  const prevChecked = usePreviousValue(propsFromContext?.checked)

  React.useLayoutEffect(() => {
    if (
      elementRef &&
      prevChecked !== undefined &&
      prevChecked !== propsFromContext?.checked &&
      propsFromContext?.checked
    ) {
      /**
       * Since this component supports the "roving tabindex" pattern when
       * used within a `<RadioInputField>`, we need to be able to trigger
       * the `onChange` event when the radio input becomes `checked` as a
       * result of receiving focus through keyboard navigation.
       *
       * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/radio/examples/radio/}
       */
      props.onChange?.(createOnChangeEvent(elementRef))
    }
  }, [
    elementRef,
    prevChecked,
    props,
    propsFromContext,
    propsFromContext?.checked,
  ])

  return (
    <RadioInputContainer
      data-gourmet-radio-input=""
      data-value={props.value}
      data-variant={withContainer ? 'container' : 'default'}
      htmlFor={inputID}
      onMouseDown={(event) => {
        if (radioInputFieldContext != null) {
          event.stopPropagation()
        }

        /**
         * We explicitly focus the input due to browsers like Safari, which will only accept focus
         * on text-only inputs.
         *
         * @see {@link https://bugs.webkit.org/show_bug.cgi?id=22261}
         */
        if (elementRef) {
          elementRef.focus()
        }
      }}
    >
      <VisuallyHiddenInput
        {...props}
        {...(!props.checked &&
          !propsFromContext?.checked && { defaultChecked })}
        {...(radioInputFieldContext?.descriptionID && {
          'aria-describedby': radioInputFieldContext.descriptionID,
        })}
        {...(radioInputFieldContext?.errorID && {
          'aria-errormessage': radioInputFieldContext.errorID,
          'aria-invalid': 'true',
        })}
        data-gourmet-radio-input-field={radioInputFieldContext?.focusTriggerID}
        data-gourmet-radio-input-input=""
        focusable
        id={inputID}
        onChange={propsFromContext ? noop : props.onChange}
        ref={composeRefs([setElementRef, ref])}
        tabIndex={0}
        type="radio"
        {...propsFromContext}
      />

      <Flex
        data-gourmet-radio-input-control-container=""
        fullWidth={fullWidth}
        direction={withRightAlignedInput ? 'row-reverse' : 'row'}
        columnGap="space.050"
      >
        <span aria-hidden="true" data-gourmet-radio-input-control="" />

        {label || description ? (
          <Flex direction="column" fullWidth>
            <Text
              data-gourmet-radio-input-label=""
              size="text-sm"
              weight="medium"
            >
              {label}
            </Text>
            {description != null && (
              <Text
                data-gourmet-radio-input-description=""
                size="text-sm"
                variant="secondary"
              >
                {description}
              </Text>
            )}
          </Flex>
        ) : null}
      </Flex>
    </RadioInputContainer>
  )
})

RadioInput.displayName = 'RadioInput'
RadioInput.defaultProps = {}

export { RadioInput, styles as RadioInputStyles }
