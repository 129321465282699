import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DropperIconProps = IconProps

const DropperIcon = forwardRef<SVGSVGElement, DropperIconProps>(
  (props: DropperIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m10.5 6.5 7 7M2 22s4.5-.5 7-3L21 7a2.828 2.828 0 1 0-4-4L5 15c-2.5 2.5-3 7-3 7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

DropperIcon.displayName = 'DropperIcon'
DropperIcon.defaultProps = iconDefaultProps

export type { DropperIconProps }
export { DropperIcon }
