import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenDownLeftIconProps = IconProps

const ArrowCircleBrokenDownLeftIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenDownLeftIconProps
>((props: ArrowCircleBrokenDownLeftIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 9v6m0 0h6m-6 0L19 5m2.66 4.41a9.996 9.996 0 0 1-2.589 9.661c-3.905 3.905-10.237 3.905-14.142 0-3.905-3.905-3.905-10.237 0-14.142a9.996 9.996 0 0 1 9.66-2.59"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenDownLeftIcon.displayName = 'ArrowCircleBrokenDownLeftIcon'
ArrowCircleBrokenDownLeftIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenDownLeftIconProps }
export { ArrowCircleBrokenDownLeftIcon }
