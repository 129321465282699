import { renderToString } from 'react-dom/server'
import { useId } from '@reach/auto-id'
import React, { cloneElement, forwardRef } from 'react'
import styled from 'styled-components'
import { CheckIcon } from '@vori/gourmet-icons'

import { radioStyles } from '../Radio'

import Flex from '../Flex'
import Spacer from '../Spacer'
import Text from '../Text'

import { colors, sizing, spacing } from '../tokens'
import toTransitions from '../utils/toTransitions'

const FauxRadio = styled.span(() => ({
  ...radioStyles({ $size: 'small' }),
  borderWidth: 2,

  '::before': {
    backgroundColor: 'transparent',
  },
}))

const FauxButton = styled.span({
  alignItems: 'center',
  backgroundColor: colors.radioButton.backgroundColor,
  borderColor: colors.radioButton.borderColor,
  borderRadius: sizing.radius.large,
  borderStyle: 'solid',
  borderWidth: 1,
  color: colors.radioButton.color,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  justifyContent: 'center',
  padding: spacing.radioButton,
  transition: toTransitions(
    ['background-color', 'border-color', 'box-shadow', 'color', 'opacity'],
    'ease',
  ),

  [Text]: {
    color: colors.radioButton.color,
  },
})

const Input = styled.input({
  height: '100%',
  margin: 0,
  opacity: 0,
  padding: 0,
  position: 'absolute',
  width: '100%',
  zIndex: 1,
})

const Content = styled(Flex)({
  marginTop: `calc(${sizing.radio.small} * -1)`,
})

const Label = styled.label<{
  disabled?: boolean
  $disableFocusRing?: boolean
  $fullWidth?: boolean
}>((props) => ({
  ...(props.$fullWidth && {
    width: '100%',
    [FauxButton]: {
      width: '100%',
    },
  }),
  cursor: props.disabled ? 'default' : 'pointer',
  position: 'relative',

  [`:hover ${FauxButton}, :focus ${FauxButton}`]: {
    backgroundColor: colors.radioButton.hover.backgroundColor,
    borderColor: colors.radioButton.hover.borderColor,
    color: colors.radioButton.hover.color,
  },

  [Input]: {
    cursor: props.disabled ? 'default' : 'pointer',
  },

  [`&& ${Input}:checked + ${FauxButton}`]: {
    backgroundColor: colors.radioButton.active.backgroundColor,
    borderColor: colors.radioButton.active.borderColor,
    color: colors.radioButton.active.color,

    [FauxRadio]: {
      backgroundImage: `url(data:image/svg+xml;utf8,${encodeURI(
        renderToString(<CheckIcon variant="positive" />),
      )
        .replace(
          'currentColor',
          colors.radioButton.activeRadioInput.borderColor,
        )
        .replace('#', '%23')})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '70%',
      borderColor: colors.radioButton.activeRadioInput.borderColor,
      color: colors.radioButton.activeRadioInput.borderColor,

      '::before': {
        backgroundColor: 'transparent',
      },
    },

    [Text]: {
      color: colors.radioButton.active.color,
    },
  },

  [`${Input}:focus + ${FauxButton}`]: {
    boxShadow: !props.$disableFocusRing
      ? `0 0 0 ${sizing.focusRing} ${colors.checkbox.focusRing}`
      : 'none',
  },

  [`${Input}:disabled + ${FauxButton}`]: {
    opacity: 0.5,
    pointerEvents: 'none',
  },

  [`${Input}:checked + ${FauxButton} ${FauxRadio}`]: {
    borderColor: colors.radio.active.borderColor,

    '::before': {
      opacity: 1,
    },
  },
}))

const Description = styled(Text)({
  flexBasis: '75%',
})

type RadioButtonProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> & {
  description?: string
  disableFocusRing?: boolean
  fullWidth?: boolean
  icon?: React.ReactElement | null
  label: string
}

const defaultProps: Partial<RadioButtonProps> = {
  className: '',
  disableFocusRing: false,
  fullWidth: false,
  icon: undefined,
}

/**
 * @deprecated Use `<RadioInput withContainer>` and `<RadioInputField>` instead.
 */
const StyledRadioButton = styled(
  forwardRef<HTMLLabelElement, RadioButtonProps>(function RadioButton(
    {
      children,
      className,
      description,
      disableFocusRing,
      fullWidth,
      icon,
      label,
      ...props
    }: RadioButtonProps,
    ref,
  ) {
    const labelId = `radioButton--${useId()}`
    const inputId = `radioButton--input--${useId()}`

    return (
      <Label
        $disableFocusRing={disableFocusRing}
        $fullWidth={fullWidth}
        className={className}
        disabled={props.disabled}
        htmlFor={inputId}
        id={labelId}
        ref={ref}
      >
        <Input {...props} id={inputId} type="radio" />
        <FauxButton>
          <Flex fullWidth justifyContent="flex-end" shrink={0}>
            <FauxRadio />
          </Flex>
          {description != null ? (
            <Content column>
              <Text aria-labelledby={labelId} forwardedAs="span" size="h5">
                {label}
              </Text>
              <Spacer size="tiny" />
              <Flex
                alignItems="flex-end"
                fullWidth
                justifyContent="space-between"
              >
                <Description aria-describedby={labelId} size="body">
                  {description}
                </Description>
                {icon && (
                  <Flex shrink={0}>
                    <Spacer inline />
                    {cloneElement(icon, {
                      'aria-hidden': true,
                      size: 'medium',
                    })}
                  </Flex>
                )}
              </Flex>
            </Content>
          ) : (
            <Content center column>
              {icon && (
                <>
                  {cloneElement(icon, { 'aria-hidden': true, size: 'medium' })}
                  <Spacer size="tiny" />
                </>
              )}

              <Text aria-labelledby={labelId} forwardedAs="span" size="h5">
                {label}
              </Text>
            </Content>
          )}
        </FauxButton>
      </Label>
    )
  }),
)``

StyledRadioButton.displayName = 'RadioButton'
StyledRadioButton.defaultProps = defaultProps

export type { RadioButtonProps }
export { defaultProps as RadioButtonDefaultProps }
export default StyledRadioButton
