import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart04IconProps = IconProps

const BarChart04Icon = forwardRef<SVGSVGElement, BarChart04IconProps>(
  (props: BarChart04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 11v10m12-10v10M9 3v18M21 3v18"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart04Icon.displayName = 'BarChart04Icon'
BarChart04Icon.defaultProps = iconDefaultProps

export type { BarChart04IconProps }
export { BarChart04Icon }
