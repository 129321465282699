import { NavLink, NavLinkProps } from 'react-router-dom'
import React, { cloneElement, forwardRef, Fragment } from 'react'
import styled from 'styled-components'

import { ButtonDefaultProps, ButtonProps, styles } from '../Button'
import Spacer from '../Spacer'

type NavButtonProps = NavLinkProps & ButtonProps

const defaultProps: Partial<NavButtonProps> =
  ButtonDefaultProps as Partial<NavButtonProps>

/**
 * @deprecated use `<NavButtonNext>` instead
 */
const StyledNavButton = styled(
  forwardRef<HTMLAnchorElement, NavButtonProps>(function NavButton(
    {
      children,
      disabled,
      disableFocusRing,
      fullWidth,
      iconOnly,
      leftIcon,
      rightIcon,
      rounded,
      size,
      type,
      variant,
      ...props
    }: NavButtonProps,
    ref,
  ) {
    return (
      <NavLink
        {...props}
        {...(disabled && { ['data-disabled']: '' })}
        ref={ref}
      >
        {iconOnly && <Fragment>&zwnj;</Fragment>}
        {leftIcon &&
          cloneElement(
            leftIcon,
            {
              'aria-hidden': true,
              size: 'medium',
            },
            null,
          )}
        {leftIcon && !iconOnly && <Spacer inline size="tiny" />}
        {children}
        {rightIcon && !iconOnly && <Spacer inline size="tiny" />}
        {rightIcon &&
          cloneElement(
            rightIcon,
            {
              'aria-hidden': true,
              size: 'medium',
            },
            null,
          )}
      </NavLink>
    )
  }),
)<NavButtonProps>`
  ${styles}
`

StyledNavButton.displayName = 'NavButton'
StyledNavButton.defaultProps = defaultProps

export type { NavButtonProps }
export { defaultProps as NavButtonDefaultProps }
export default StyledNavButton
