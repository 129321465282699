import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleDownRightIconProps = IconProps

const ArrowCircleDownRightIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleDownRightIconProps
>((props: ArrowCircleDownRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 9v6m0 0H9m6 0L9 9m13 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleDownRightIcon.displayName = 'ArrowCircleDownRightIcon'
ArrowCircleDownRightIcon.defaultProps = iconDefaultProps

export type { ArrowCircleDownRightIconProps }
export { ArrowCircleDownRightIcon }
