import { arrayToSet, setToArray } from '@vori/utils-set'
import { useFuzzySearch, usePreviousValue } from '@vori/react-hooks'
import { compact } from 'lodash'

import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'

import { ListItemMemo, ListProps } from '../../List'
import ListBox, { ListBoxProps } from '../../ListBox'
import MultiSelectFooterActions from './FooterActions'
import MultiSelectOptionContent from './OptionContent'
import MultiSelectRemoveValueTrigger from './RemoveValueTrigger'
import VirtualOption from './VirtualOption'

import {
  useGetOriginalOption,
  useGetOptionDisabled,
  useGetOptionLabel,
  useGetOptionLoading,
  useGetOptionLoadingLabel,
  useOnSearchInputChange,
  useOnSearchInputKeyDown,
  useRenderFooter,
  useRenderHeader,
  useRenderLoadingOptionForMultiSelect,
} from '../utils'

import { assignRef } from '../../utils'
import { getDefaultReducerState, reducer } from './utils/reducer'
import { optionIsSelected } from './utils/reducer-utils'

import {
  Internals,
  OptionData,
  Props,
  ReducerAction,
  ReducerState,
} from './utils/types'

const DEFAULT_CONTENT_ROW_HEIGHT = 44

function MultiSelect<T = string>({
  canToggleOptions = true,
  disableEndIndicator,
  emptyStateMessage,
  endIndicatorProps,
  errorStateMessage,
  footerActionsProps,
  fuzzySearchOptions,
  getDisabledOptions,
  getLabelForTrigger: defaultGetLabelForTrigger,
  getOptionDisabled: defaultGetOptionDisabled,
  getOptionKey,
  getOptionLabel: defaultGetOptionLabel,
  getOptionLoading: defaultGetOptionLoading,
  getOptionLoadingLabel: defaultGetOptionLoadingLabel,
  getOptionMenuItemProps: defaultGetOptionMenuItemProps,
  getOptionValue,
  getOriginalOption: defaultGetOriginalOption,
  initialState,
  label,
  listProps: defaultListProps,
  loaderProps,
  onChange,
  onClear,
  options: defaultOptions,
  removeButtonProps,
  renderFooter: defaultRenderFooter,
  renderHeader: defaultRenderHeader,
  renderLoadingOption: defaultRenderLoadingOption,
  renderOption: defaultRenderOption,
  searchInputProps,
  selectedOptions,
  showEmpty: defaultShowEmpty,
  showEmptyState: defaultShowEmptyState,
  showErrorState,
  showInitialLoadingState,
  showLoadingState,
  virtualizeOptions,
  withFuzzySearch,
  withoutFooterActions,
  withRemoveButton,
  ...props
}: Props<T>): JSX.Element {
  const clearButtonPressed = useRef(false)

  const [state, dispatch] = useReducer<
    React.Reducer<ReducerState, ReducerAction>
  >(
    reducer,
    getDefaultReducerState(
      initialState || {
        ...(label != null ? { defaultLabel: label } : {}),
        ...(selectedOptions != null
          ? {
              selectedOptions: Array.isArray(selectedOptions)
                ? arrayToSet(selectedOptions)
                : selectedOptions,
            }
          : {}),
      },
    ),
  )

  const showEmpty = useMemo(
    () => defaultShowEmpty || defaultShowEmptyState,
    [defaultShowEmpty, defaultShowEmptyState],
  )

  const previousState = usePreviousValue(state)

  const { results, search } = useFuzzySearch<T>(
    defaultOptions,
    fuzzySearchOptions?.fuseOptions || {},
    fuzzySearchOptions?.debounce || 0,
  )

  const options = useMemo(
    () => (withFuzzySearch ? results.map(({ item }) => item) : defaultOptions),
    [defaultOptions, results, withFuzzySearch],
  )

  const getOriginalOption = useGetOriginalOption(defaultGetOriginalOption)
  const getOptionLabel = useGetOptionLabel(defaultGetOptionLabel)

  const getOptionDisabled = useGetOptionDisabled(
    defaultGetOptionDisabled,
    options,
    getDisabledOptions,
  )

  const getOptionLoadingLabel = useGetOptionLoadingLabel(
    defaultGetOptionLoadingLabel,
    getOptionLabel,
  )

  const getOptionLoading = useGetOptionLoading(defaultGetOptionLoading)

  const onSearchInputChange = useOnSearchInputChange(search, searchInputProps)
  const onSearchInputKeyDown = useOnSearchInputKeyDown(searchInputProps)

  const onChangeHandler = useCallback(
    (option: string) => {
      dispatch({
        type: canToggleOptions
          ? optionIsSelected(state, option)
            ? 'REMOVE_OPTION'
            : 'ADD_OPTION'
          : 'ADD_OPTION',
        option,
      })
    },
    [canToggleOptions, state],
  )

  const deselectAll = useCallback(() => {
    dispatch({
      type: 'RESET',
      nextState: {
        ...(label != null ? { defaultLabel: label } : {}),
        selectedOptions: new Set(),
      },
    })
  }, [label])

  const deselectOption = useCallback((option: string) => {
    dispatch({ type: 'REMOVE_OPTION', option })
  }, [])

  const selectAll = useCallback(() => {
    dispatch({
      type: 'RESET',
      nextState: {
        selectedOptions: arrayToSet(
          getOptionValue != null
            ? options.map(getOptionValue)
            : options.map(String),
        ),
      },
    })
  }, [options, getOptionValue])

  const selectOption = useCallback((option: string) => {
    dispatch({ type: 'ADD_OPTION', option })
  }, [])

  const internals = useMemo(
    () => ({
      deselectAll,
      deselectOption,
      selectAll,
      selectOption,
      state,
      originalOptionAdded:
        state.optionAdded !== null
          ? getOriginalOption(state.optionAdded)
          : null,
      originalOptionRemoved:
        state.optionRemoved !== null
          ? getOriginalOption(state.optionRemoved)
          : null,
      originalOptionsSelected: compact(
        setToArray(state.selectedOptions).map(getOriginalOption),
      ),
    }),
    [
      deselectAll,
      deselectOption,
      getOriginalOption,
      selectAll,
      selectOption,
      state,
    ],
  )

  const renderLoadingOption = useRenderLoadingOptionForMultiSelect(
    defaultRenderLoadingOption,
    getOptionLoadingLabel,
    internals,
  )

  const getLabelForTrigger = useCallback<
    NonNullable<ListBoxProps<T>['getLabelForTrigger']>
  >(
    (option) => {
      if (defaultGetLabelForTrigger != null) {
        return defaultGetLabelForTrigger(option, internals)
      }

      if (option) {
        const originalOption = getOriginalOption(option)
        return originalOption ? getOptionLabel(originalOption) : state.label
      }

      return state.label
    },
    [
      defaultGetLabelForTrigger,
      getOptionLabel,
      getOriginalOption,
      internals,
      state.label,
    ],
  )

  useEffect(() => {
    if (
      onChange != null &&
      previousState !== undefined &&
      state.selectedOptions.size !== previousState.selectedOptions.size
    ) {
      onChange(internals)
    }

    if (onClear != null && clearButtonPressed.current === true) {
      clearButtonPressed.current = false
      onClear(internals)
    }
  }, [getOriginalOption, internals, onChange, onClear, previousState, state])

  const renderFooterWithActions = useCallback<
    NonNullable<ListBoxProps<T>['renderFooter']>
  >(
    () => (
      <>
        {!withoutFooterActions &&
          !showLoadingState &&
          !showInitialLoadingState &&
          !showErrorState &&
          !showEmpty && (
            <MultiSelectFooterActions
              {...footerActionsProps}
              clearBtnProps={{
                ...footerActionsProps?.clearBtnProps,
                disabled: !internals.state.hasSelectedOptions,
                onClick: (event) => {
                  clearButtonPressed.current = true

                  internals.deselectAll()

                  if (footerActionsProps?.clearBtnProps?.onClick != null) {
                    footerActionsProps.clearBtnProps.onClick(event)
                  }
                },
              }}
            />
          )}

        {defaultRenderFooter != null && defaultRenderFooter(internals)}
      </>
    ),
    [
      defaultRenderFooter,
      footerActionsProps,
      internals,
      showEmpty,
      showErrorState,
      showInitialLoadingState,
      showLoadingState,
      withoutFooterActions,
    ],
  )

  const renderFooter = useRenderFooter<T, Internals<T>>(
    showLoadingState,
    showInitialLoadingState,
    showErrorState,
    showEmpty,
    errorStateMessage,
    options,
    emptyStateMessage,
    renderFooterWithActions,
    internals,
  )

  const renderHeader = useRenderHeader<T, Internals<T>>(
    withFuzzySearch,
    searchInputProps,
    onSearchInputChange,
    onSearchInputKeyDown,
    defaultRenderHeader,
    internals,
  )

  const getOptionMenuItemProps = useMemo<
    NonNullable<ListBoxProps<T>['getOptionMenuItemProps']>
  >(() => {
    return defaultGetOptionMenuItemProps != null
      ? (option) => {
          const { icon, ...menuItemProps } =
            defaultGetOptionMenuItemProps(option)

          return { ...menuItemProps, disableActiveState: true }
        }
      : () => ({ disableActiveState: true })
  }, [defaultGetOptionMenuItemProps])

  const renderOption = useCallback<
    NonNullable<ListBoxProps<T>['renderOption']>
  >(
    (option, isSelected) => {
      const isDisabled = getOptionDisabled(option)

      const menuItemProps =
        defaultGetOptionMenuItemProps != null
          ? defaultGetOptionMenuItemProps(option)
          : null

      return (
        <MultiSelectOptionContent
          icon={menuItemProps?.icon || null}
          isDisabled={isDisabled}
          isSelected={isSelected}
          labelContent={
            getOptionLoading(option)
              ? renderLoadingOption(option, isSelected, internals)
              : defaultRenderOption != null
                ? defaultRenderOption(option, isSelected, internals)
                : getOptionLabel(option)
          }
        />
      )
    },
    [
      defaultGetOptionMenuItemProps,
      defaultRenderOption,
      getOptionDisabled,
      getOptionLabel,
      getOptionLoading,
      internals,
      renderLoadingOption,
    ],
  )

  const hideEndIndicator = useMemo(
    () =>
      showEmpty ||
      showLoadingState ||
      showInitialLoadingState ||
      disableEndIndicator,
    [disableEndIndicator, showEmpty, showInitialLoadingState, showLoadingState],
  )

  const listProps = useMemo<ListProps<T, OptionData<T>>>(
    () => ({
      ...(defaultListProps && { ...defaultListProps }),
      virtualize: virtualizeOptions,
      virtualizeProps: {
        isItemLoaded: (index) =>
          !defaultListProps?.virtualizeProps?.infiniteLoaderProps
            ?.canLoadMoreItems || index < options.length,
        itemCount: options.length + (hideEndIndicator ? 0 : 1),
        ...(defaultListProps?.virtualizeProps || {}),
        items: options,
        itemsData: {
          disableEndIndicator: hideEndIndicator,
          endIndicatorProps,
          getOptionDisabled,
          getOptionKey,
          getOptionLabel,
          getOptionMenuItemProps,
          getOptionValue,
          getOriginalOption,
          internals,
          loaderProps,
          renderOption,
        },
        renderItem: VirtualOption as unknown as ListItemMemo<T, OptionData<T>>,
        virtualListProps: {
          itemSize: () => DEFAULT_CONTENT_ROW_HEIGHT,
          ...(defaultListProps?.virtualizeProps?.virtualListProps || {}),
          outerRef: (ref) => {
            if (defaultListProps?.virtualizeProps?.virtualListProps?.outerRef) {
              assignRef(
                defaultListProps.virtualizeProps.virtualListProps.outerRef,
                ref,
              )
            }
          },
          style: {
            overflow: 'visible',
            overflowY: 'auto',
          },
        },
      },
    }),
    [
      defaultListProps,
      virtualizeOptions,
      options,
      hideEndIndicator,
      endIndicatorProps,
      getOptionDisabled,
      getOptionKey,
      getOptionLabel,
      getOptionMenuItemProps,
      getOptionValue,
      getOriginalOption,
      internals,
      loaderProps,
      renderOption,
    ],
  )

  useEffect(() => {
    dispatch({
      type: 'RESET',
      nextState: {
        ...(label != null ? { defaultLabel: label } : {}),
        ...(selectedOptions != null
          ? {
              selectedOptions: Array.isArray(selectedOptions)
                ? arrayToSet(selectedOptions)
                : selectedOptions,
            }
          : {}),
      },
    })
  }, [label, selectedOptions])

  return (
    <ListBox
      listProps={listProps as ListProps<T>}
      position={showInitialLoadingState ? 'matchWidth' : 'default'}
      {...props}
      canToggleOptions={canToggleOptions}
      getLabelForTrigger={getLabelForTrigger}
      getOptionDisabled={getOptionDisabled}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      getOptionMenuItemProps={getOptionMenuItemProps}
      getOptionValue={getOptionValue}
      label={state.label}
      multipleValues
      onChange={onChangeHandler}
      options={showInitialLoadingState || showEmpty ? [] : options}
      renderFooter={renderFooter}
      renderHeader={renderHeader}
      renderOption={renderOption}
      renderTrigger={
        props.renderTrigger != null
          ? (_, isExpanded) =>
              props.renderTrigger?.(
                isExpanded,
                internals,
                getLabelForTrigger?.(
                  setToArray(state.selectedOptions).join(','),
                ) || state.label,
              ) ||
              (getLabelForTrigger != null
                ? getLabelForTrigger(
                    setToArray(state.selectedOptions).join(','),
                  )
                : undefined) ||
              state.label ||
              state.defaultLabel
          : withRemoveButton
            ? (_, isExpanded) => (
                <MultiSelectRemoveValueTrigger
                  label={
                    getLabelForTrigger != null
                      ? getLabelForTrigger(
                          setToArray(state.selectedOptions).join(','),
                        )
                      : undefined
                  }
                  {...(typeof removeButtonProps === 'function'
                    ? removeButtonProps(internals)
                    : removeButtonProps != null
                      ? removeButtonProps
                      : {})}
                  isOpen={isExpanded}
                  internals={internals}
                />
              )
            : undefined
      }
      value={
        state.hasSelectedOptions
          ? setToArray(state.selectedOptions).join(',')
          : ''
      }
    />
  )
}

export { MultiSelectOptionContent }
export default MultiSelect
