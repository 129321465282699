import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Announcement01IconProps = IconProps

const Announcement01Icon = forwardRef<SVGSVGElement, Announcement01IconProps>(
  (props: Announcement01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 8v4M10.25 5.5H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.31C2 7.78 2 8.62 2 10.3v1.2c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083c.367.152.833.152 1.765.152v4.25c0 .232 0 .348.01.446a2 2 0 0 0 1.794 1.794c.098.01.214.01.446.01s.348 0 .446-.01a2 2 0 0 0 1.794-1.794c.01-.098.01-.214.01-.446V14.5h.75c1.766 0 3.927.947 5.594 1.856.973.53 1.46.795 1.778.756a.946.946 0 0 0 .691-.411c.187-.26.187-.783.187-1.827V5.126c0-1.044 0-1.566-.187-1.827a.946.946 0 0 0-.691-.411c-.319-.039-.805.226-1.778.756-1.667.909-3.828 1.856-5.594 1.856Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Announcement01Icon.displayName = 'Announcement01Icon'
Announcement01Icon.defaultProps = iconDefaultProps

export type { Announcement01IconProps }
export { Announcement01Icon }
