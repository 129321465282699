import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenRightIconProps = IconProps

const ArrowCircleBrokenRightIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenRightIconProps
>((props: ArrowCircleBrokenRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3.338 7A9.996 9.996 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.996 9.996 0 0 1-8.662-5M12 16l4-4m0 0-4-4m4 4H2"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenRightIcon.displayName = 'ArrowCircleBrokenRightIcon'
ArrowCircleBrokenRightIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenRightIconProps }
export { ArrowCircleBrokenRightIcon }
