import React from 'react'
import styled from 'styled-components'

import { useImage } from '@vori/react-hooks'
import { User01Icon } from '@vori/gourmet-icons'

import { DotIndicator } from '../DotIndicator'
import { InlineFlex } from '../FlexNext'

import { AvatarCoreProps, AvatarProps } from './types'
import { avatarStyles } from './styles'
import { propsToStyledProps } from '../utils'

import { AvatarDefaultIcon } from './AvatarDefaultIcon'
import { AvatarLoader } from './AvatarLoader'
import { AvatarText } from './AvatarText'

const StyledAvatar = styled(InlineFlex)(avatarStyles)

const defaultProps: Partial<AvatarCoreProps> = {
  size: 'md',
}

/**
 * The `<Avatar>` component provides a graphic representation of an entity. It
 * can display an image, text, or fallback to an icon.
 *
 * @example
 * <Avatar alt="Juan del Pueblo's avatar image" src="https://some.image.com" size="lg" />
 */
const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>(function Avatar(
  {
    alt,
    dotIndicator,
    entityInitials,
    entityName,
    fallBackIcon,
    size = 'md',
    src,
    ...props
  }: AvatarProps,
  ref,
): JSX.Element {
  const { hasFailed, isLoading } = useImage(src || null)

  const derivedEntityInitials =
    entityInitials ||
    entityName?.match(/\b\w/g)?.join('').toLocaleUpperCase() ||
    ''

  const styledProps = propsToStyledProps<AvatarProps>({
    alt,
    entityInitials: derivedEntityInitials,
    entityName,
    fallBackIcon,
    size,
    src,
  })

  const avatarProps = {
    ...styledProps,
    ...props,
    'data-gourmet-avatar': '',
    'data-size': size,
    center: true,
    ref,
  }

  if (isLoading) {
    return (
      <StyledAvatar {...avatarProps}>
        <AvatarLoader avatarSize={size} />
        {dotIndicator && (
          <DotIndicator
            {...(typeof dotIndicator !== 'boolean' && dotIndicator)}
            data-gourmet-avatar-dot-indicator=""
          />
        )}
      </StyledAvatar>
    )
  }

  if (!src || (hasFailed && entityName)) {
    return (
      <StyledAvatar {...avatarProps}>
        <AvatarText avatarSize={size} title={entityName}>
          {derivedEntityInitials}
        </AvatarText>
        {dotIndicator && (
          <DotIndicator
            {...(typeof dotIndicator !== 'boolean' && dotIndicator)}
            data-gourmet-avatar-dot-indicator=""
          />
        )}
      </StyledAvatar>
    )
  }

  if (hasFailed) {
    return (
      <StyledAvatar {...avatarProps}>
        <AvatarDefaultIcon
          avatarSize={size}
          icon={fallBackIcon || <User01Icon />}
        />
        {dotIndicator && (
          <DotIndicator
            {...(typeof dotIndicator !== 'boolean' && dotIndicator)}
            data-gourmet-avatar-dot-indicator=""
          />
        )}
      </StyledAvatar>
    )
  }

  return (
    <StyledAvatar {...avatarProps}>
      <img alt={alt} src={src} />
      {dotIndicator && (
        <DotIndicator
          {...(typeof dotIndicator !== 'boolean' && dotIndicator)}
          data-gourmet-avatar-dot-indicator=""
        />
      )}
    </StyledAvatar>
  )
})

Avatar.displayName = 'Avatar'
Avatar.defaultProps = defaultProps

export { Avatar, defaultProps as avatarDefaultProps }
