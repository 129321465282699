import { Link, LinkProps, useRouteMatch } from 'react-router-dom'
import React, { cloneElement, forwardRef, Fragment } from 'react'
import styled from 'styled-components'

import Spacer from '../Spacer'
import Text from '../Text'
import Flex, { FlexDefaultProps, FlexProps } from '../Flex'

import { colors, spacing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type TabProps = LinkProps & {
  isActive?: boolean | null
  disabled?: boolean
  exact?: boolean
  icon?: React.ReactElement | null
  label: React.ReactNode
}

function renderTabWithContainer(
  props: LinkProps & Pick<TabProps, 'disabled'>,
  className: string,
  content: React.ReactNode,
) {
  if (props.disabled) {
    return <span className={className}>{content}</span>
  } else {
    return (
      <Link {...props} className={className}>
        {content}
      </Link>
    )
  }
}

const StyledTab = styled(function Tab({
  isActive,
  className,
  exact,
  icon,
  label,
  to,
  ...props
}: TabProps) {
  const match = useRouteMatch({
    exact: exact,
    path: to as string,
  })

  return renderTabWithContainer(
    { className, to, ...props },
    match || isActive ? `${className} active` : className || '',
    <>
      {icon && (
        <Flex inline shrink={0}>
          {cloneElement(icon, { 'aria-hidden': true, size: 'medium' }, null)}
          <Spacer inline size="small" />
        </Flex>
      )}

      <Text
        align="center"
        size="h6"
        variant={props.disabled ? 'secondary' : 'default'}
      >
        {label}
      </Text>
    </>,
  )
})<TabProps>`
  border-bottom: 5px solid ${colors.tab.borderColor};
  padding: ${spacing.tab};
  position: relative;

  &,
  ${Text}, :visited {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'cursor')};
    color: ${colors.tab.color};
    text-decoration: none;
    transition: ${toTransitions(['color', 'border-color'], 'ease')};
  }

  :hover,
  :active,
  :focus,
  &.active {
    &,
    ${Text} {
      color: ${colors.tab.hover.color};
      text-decoration: none;
    }
  }

  &.active {
    border-bottom-color: ${colors.tab.active.borderColor};
  }
`

type TabsProps = React.HTMLAttributes<HTMLDivElement> &
  Pick<FlexProps, 'basis' | 'flex' | 'fullWidth' | 'grow' | 'shrink'> & {
    tabs: Array<TabProps>
  }

const defaultProps: Partial<TabsProps> = {
  basis: FlexDefaultProps.basis,
  className: '',
  flex: FlexDefaultProps.flex,
  fullWidth: FlexDefaultProps.fullWidth,
  grow: FlexDefaultProps.grow,
  shrink: FlexDefaultProps.shrink,
}

const StyledTabs = styled(
  forwardRef<HTMLDivElement, TabsProps>(function Tabs(
    { tabs, ...props }: TabsProps,
    ref,
  ) {
    return (
      <Flex {...props} centerY shrink={0} ref={ref}>
        {tabs.map(({ label, ...tabProps }, index) => (
          <StyledTab
            {...tabProps}
            key={`tab-${label}-${index}`}
            label={label}
          />
        ))}
      </Flex>
    )
  }),
)``

StyledTabs.displayName = 'Tabs'
StyledTabs.defaultProps = defaultProps

export type { TabsProps }
export { defaultProps as TabsDefaultProps }
export default StyledTabs
