import tokens from './base'

export default {
  autocomplete: {
    popup: tokens.spacing.tiny,
  },

  button: {
    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    base: `${tokens.spacing.small} 30px`,
    large: tokens.spacing.base,
    small: `${tokens.spacing.tiny} ${tokens.spacing.medium}`,

    withIcon: {
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      base: `${tokens.spacing.small} 25px`,
      large: tokens.spacing.base,
      small: `${tokens.spacing.tiny} ${tokens.spacing.medium}`,
    },
  },

  calendar: {
    navigation: tokens.spacing.tiny,
    navigationButton: `0 ${tokens.spacing.tiny}`,
  },

  card: tokens.spacing.base,
  error: tokens.spacing.small,

  infoInput: {
    iconContainer: tokens.spacing.small,
  },

  input: {
    base: `${tokens.spacing.small} ${tokens.spacing.medium}`,
    large: tokens.spacing.base,
    small: `${tokens.spacing.tiny} ${tokens.spacing.medium}`,
    iconContainer: tokens.spacing.small,
  },

  label: tokens.spacing.medium,

  listbox: {
    popup: tokens.spacing.tiny,
  },

  menuItem: `${tokens.spacing.small} ${tokens.spacing.medium}`,

  modal: {
    closeButton: tokens.spacing.base,
    footer: tokens.spacing.medium,
    header: `${tokens.spacing.small} ${tokens.spacing.medium}`,
    overlay: tokens.spacing.base,
  },

  radioButton: tokens.spacing.small,
  section: tokens.spacing.medium,

  panel: {
    content: tokens.spacing.base,
    closeButton: tokens.spacing.base,
  },

  table: {
    cell: tokens.spacing.small,
  },

  tab: `${tokens.spacing.medium} ${tokens.spacing.base}`,
  toast: `${tokens.spacing.base} ${tokens.spacing.medium}`,
  toggle: `${tokens.spacing.tiny} ${tokens.spacing.base}`,

  tooltip: {
    gap: tokens.spacing.tiny,
    popup: tokens.spacing.tiny,
    tick: tokens.spacing.tiny,
  },

  /** v2  */
  appHeader: `${tokens.spacing.medium} ${tokens.spacing.base}`,
  appSidebarLink: tokens.spacing.medium,
  appSidebarHeader: tokens.spacing.base,

  appFrame: {
    x: tokens.spacing.large,
    y: tokens.spacing.base,
  },

  timeline: tokens.spacing.large,
  fileModal: {
    header: tokens.spacing.medium,
    sidebar: tokens.spacing.tiny,
  },
  multiSelect: {
    footerActions: tokens.spacing.small,
  },
}
