import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudSnowing01IconProps = IconProps

const CloudSnowing01Icon = forwardRef<SVGSVGElement, CloudSnowing01IconProps>(
  (props: CloudSnowing01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 15.242a4.5 4.5 0 0 0-2.08-8.223 6.002 6.002 0 0 0-11.84 0A4.5 4.5 0 0 0 4 15.242M8 15h.01M8 19h.01M12 17h.01M12 21h.01M16 15h.01M16 19h.01"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudSnowing01Icon.displayName = 'CloudSnowing01Icon'
CloudSnowing01Icon.defaultProps = iconDefaultProps

export type { CloudSnowing01IconProps }
export { CloudSnowing01Icon }
