import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Download01IconProps = IconProps

const Download01Icon = forwardRef<SVGSVGElement, Download01IconProps>(
  (props: Download01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 15v1.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 18.72 3 17.88 3 16.2V15m14-5-5 5m0 0-5-5m5 5V3"
        fill="none"
      ></path>
    </Icon>
  ),
)

Download01Icon.displayName = 'Download01Icon'
Download01Icon.defaultProps = iconDefaultProps

export type { Download01IconProps }
export { Download01Icon }
