import { DocumentNode, gql } from '@apollo/client'
import { mergeTypeDefs } from '@graphql-tools/merge'

enum TypeDefs {
  DEFAULT,
  AUTO_INVOICE_MODULE,
}

export const DEFAULT_TYPE_DEFS = gql`
  directive @client on FIELD
  scalar Cursor
`

export function getTypeDefs(typeDefs?: Array<TypeDefs>): DocumentNode {
  return mergeTypeDefs(
    (Array.isArray(typeDefs)
      ? typeDefs
      : (Object.values(TypeDefs) as Array<TypeDefs>)
    ).reduce<Array<DocumentNode>>((typeDefs, typeDef) => {
      switch (typeDef) {
        case TypeDefs.DEFAULT: {
          typeDefs.push(DEFAULT_TYPE_DEFS)
          break
        }
      }

      return typeDefs
    }, []),
  )
}

const typeDefs = mergeTypeDefs([DEFAULT_TYPE_DEFS])

export default typeDefs
