import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FramerIconProps = IconProps

const FramerIcon = forwardRef<SVGSVGElement, FramerIconProps>(
  (props: FramerIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15.5v7l-7-7m0 0v-7h7m-7 7h14l-7-7m0 0h7v-7H5l7 7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FramerIcon.displayName = 'FramerIcon'
FramerIcon.defaultProps = iconDefaultProps

export type { FramerIconProps }
export { FramerIcon }
