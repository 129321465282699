import { remToEm, remToPx } from '../utils'
import { TokensWithMetadataMap } from '../types'

export const TOKEN_TYPOGRAPHY_BASE_FONT_SIZE = 16 as const

export const tokens = {
  /**
   * Font size for extra small text used for copy and controls — Equivalent to 12px.
   */
  'typography.text-xs-font-size': '0.75rem',
  /**
   * Line height for extra small text used for copy and controls — Equivalent to 18px.
   */
  'typography.text-xs-line-height': '1.125rem',
  /**
   * Font size for small text used for copy and controls — Equivalent to 14px.
   */
  'typography.text-sm-font-size': '0.875rem',
  /**
   * Line height for small text used for copy and controls — Equivalent to 20px.
   */
  'typography.text-sm-line-height': '1.25rem',
  /**
   * Font size for medium text used for copy and controls — Equivalent to 16px.
   */
  'typography.text-md-font-size': '1rem',
  /**
   * Line height for medium text used for copy and controls — Equivalent to 24px.
   */
  'typography.text-md-line-height': '1.50rem',
  /**
   * Font size for large text used for copy and controls — Equivalent to 18px.
   */
  'typography.text-lg-font-size': '1.125rem',
  /**
   * Line height for large text used for copy and controls — Equivalent to 28px.
   */
  'typography.text-lg-line-height': '1.75rem',
  /**
   * Font size for extra large text used for copy and controls — Equivalent to 20px.
   */
  'typography.text-xl-font-size': '1.25rem',
  /**
   * Line height for extra large text used for copy and controls — Equivalent to 30px.
   */
  'typography.text-xl-line-height': '1.875rem',
  /**
   * Font size for extra small text used for headings — Equivalent to 24px.
   */
  'typography.heading-xs-font-size': '1.50rem',
  /**
   * Line height for extra small text used for headings — Equivalent to 32px.
   */
  'typography.heading-xs-line-height': '2rem',
  /**
   * Font size for small text used for headings — Equivalent to 30px.
   */
  'typography.heading-sm-font-size': '1.875rem',
  /**
   * Line height for small text used for headings — Equivalent to 38px.
   */
  'typography.heading-sm-line-height': '2.375rem',
  /**
   * Font size for medium text used for headings — Equivalent to 36px.
   */
  'typography.heading-md-font-size': '2.25rem',
  /**
   * Line height for medium text used for headings — Equivalent to 44px.
   */
  'typography.heading-md-line-height': '2.75rem',
  /**
   * Font size for large text used for headings — Equivalent to 48px.
   */
  'typography.heading-lg-font-size': '3rem',
  /**
   * Line height for large text used for headings — Equivalent to 60px.
   */
  'typography.heading-lg-line-height': '3.75rem',
  /**
   * Font size for extra large text used for headings — Equivalent to 60px.
   */
  'typography.heading-xl-font-size': '3.75rem',
  /**
   * Line height for extra large text used for headings — Equivalent to 72px.
   */
  'typography.heading-xl-line-height': '4.5rem',
  /**
   * Font size for extra extra large text used for headings — Equivalent to 72px.
   */
  'typography.heading-xxl-font-size': '4.5rem',
  /**
   * Line height for extra extra large text used for headings — Equivalent to 90px.
   */
  'typography.heading-xxl-line-height': '5.625rem',
  /**
   * Regular font weight for copy and controls text, as well as, headings.
   */
  'typography.font-weight-regular': '400',
  /**
   * Medium font weight for copy and controls text, as well as, headings.
   */
  'typography.font-weight-medium': '500',
  /**
   * Semibold font weight for copy and controls text, as well as, headings.
   */
  'typography.font-weight-semibold': '600',
  /**
   * Bold font weight for copy and controls text, as well as, headings.
   */
  'typography.font-weight-bold': '700',
} as const

export const tokensWithMetadata: TokensWithMetadataMap<
  'typography',
  typeof tokens
> = {
  'typography.text-xs-font-size': {
    description:
      'Font size for extra small text used for copy and controls — Equivalent to 12px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-xs-font-size',
    type: 'typography',
    value: tokens['typography.text-xs-font-size'],
    metadata: {
      em: remToEm(tokens['typography.text-xs-font-size']),
      px: remToPx(
        tokens['typography.text-xs-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-xs-font-size'],
    },
  },
  'typography.text-xs-line-height': {
    description:
      'Line height for extra small text used for copy and controls — Equivalent to 18px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-xs-line-height',
    type: 'typography',
    value: tokens['typography.text-xs-line-height'],
    metadata: {
      em: remToEm(tokens['typography.text-xs-line-height']),
      px: remToPx(
        tokens['typography.text-xs-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-xs-line-height'],
    },
  },
  'typography.text-sm-font-size': {
    description:
      'Font size for small text used for copy and controls — Equivalent to 14px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-sm-font-size',
    type: 'typography',
    value: tokens['typography.text-sm-font-size'],
    metadata: {
      em: remToEm(tokens['typography.text-sm-font-size']),
      px: remToPx(
        tokens['typography.text-sm-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-sm-font-size'],
    },
  },
  'typography.text-sm-line-height': {
    description:
      'Line height for small text used for copy and controls — Equivalent to 20px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-sm-line-height',
    type: 'typography',
    value: tokens['typography.text-sm-line-height'],
    metadata: {
      em: remToEm(tokens['typography.text-sm-line-height']),
      px: remToPx(
        tokens['typography.text-sm-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-sm-line-height'],
    },
  },
  'typography.text-md-font-size': {
    description:
      'Font size for medium text used for copy and controls — Equivalent to 16px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-md-font-size',
    type: 'typography',
    value: tokens['typography.text-md-font-size'],
    metadata: {
      em: remToEm(tokens['typography.text-md-font-size']),
      px: remToPx(
        tokens['typography.text-md-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-md-font-size'],
    },
  },
  'typography.text-md-line-height': {
    description:
      'Line height for medium text used for copy and controls — Equivalent to 24px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-md-line-height',
    type: 'typography',
    value: tokens['typography.text-md-line-height'],
    metadata: {
      em: remToEm(tokens['typography.text-md-line-height']),
      px: remToPx(
        tokens['typography.text-md-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-md-line-height'],
    },
  },
  'typography.text-lg-font-size': {
    description:
      'Font size for large text used for copy and controls — Equivalent to 18px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-lg-font-size',
    type: 'typography',
    value: tokens['typography.text-lg-font-size'],
    metadata: {
      em: remToEm(tokens['typography.text-lg-font-size']),
      px: remToPx(
        tokens['typography.text-lg-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-lg-font-size'],
    },
  },
  'typography.text-lg-line-height': {
    description:
      'Line height for large text used for copy and controls — Equivalent to 28px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-lg-line-height',
    type: 'typography',
    value: tokens['typography.text-lg-line-height'],
    metadata: {
      em: remToEm(tokens['typography.text-lg-line-height']),
      px: remToPx(
        tokens['typography.text-lg-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-lg-line-height'],
    },
  },
  'typography.text-xl-font-size': {
    description:
      'Font size for extra large text used for copy and controls — Equivalent to 20px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-xl-font-size',
    type: 'typography',
    value: tokens['typography.text-xl-font-size'],
    metadata: {
      em: remToEm(tokens['typography.text-xl-font-size']),
      px: remToPx(
        tokens['typography.text-xl-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-xl-font-size'],
    },
  },
  'typography.text-xl-line-height': {
    description:
      'Line height for extra large text used for copy and controls — Equivalent to 30px.',
    introducedIn: 'v0.0.1',
    name: 'typography.text-xl-line-height',
    type: 'typography',
    value: tokens['typography.text-xl-line-height'],
    metadata: {
      em: remToEm(tokens['typography.text-xl-line-height']),
      px: remToPx(
        tokens['typography.text-xl-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.text-xl-line-height'],
    },
  },
  'typography.heading-xs-font-size': {
    description:
      'Font size for extra small text used for headings — Equivalent to 24px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-xs-font-size',
    type: 'typography',
    value: tokens['typography.heading-xs-font-size'],
    metadata: {
      em: remToEm(tokens['typography.heading-xs-font-size']),
      px: remToPx(
        tokens['typography.heading-xs-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-xs-font-size'],
    },
  },
  'typography.heading-xs-line-height': {
    description:
      'Line height for extra small text used for headings — Equivalent to 32px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-xs-line-height',
    type: 'typography',
    value: tokens['typography.heading-xs-line-height'],
    metadata: {
      em: remToEm(tokens['typography.heading-xs-line-height']),
      px: remToPx(
        tokens['typography.heading-xs-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-xs-line-height'],
    },
  },
  'typography.heading-sm-font-size': {
    description:
      'Font size for small text used for headings — Equivalent to 30px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-sm-font-size',
    type: 'typography',
    value: tokens['typography.heading-sm-font-size'],
    metadata: {
      em: remToEm(tokens['typography.heading-sm-font-size']),
      px: remToPx(
        tokens['typography.heading-sm-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-sm-font-size'],
    },
  },
  'typography.heading-sm-line-height': {
    description:
      'Line height for small text used for headings — Equivalent to 38px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-sm-line-height',
    type: 'typography',
    value: tokens['typography.heading-sm-line-height'],
    metadata: {
      em: remToEm(tokens['typography.heading-sm-line-height']),
      px: remToPx(
        tokens['typography.heading-sm-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-sm-line-height'],
    },
  },
  'typography.heading-md-font-size': {
    description:
      'Font size for medium text used for headings — Equivalent to 36px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-md-font-size',
    type: 'typography',
    value: tokens['typography.heading-md-font-size'],
    metadata: {
      em: remToEm(tokens['typography.heading-md-font-size']),
      px: remToPx(
        tokens['typography.heading-md-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-md-font-size'],
    },
  },
  'typography.heading-md-line-height': {
    description:
      'Line height for medium text used for headings — Equivalent to 44px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-md-line-height',
    type: 'typography',
    value: tokens['typography.heading-md-line-height'],
    metadata: {
      em: remToEm(tokens['typography.heading-md-line-height']),
      px: remToPx(
        tokens['typography.heading-md-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-md-line-height'],
    },
  },
  'typography.heading-lg-font-size': {
    description:
      'Font size for large text used for headings — Equivalent to 48px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-lg-font-size',
    type: 'typography',
    value: tokens['typography.heading-lg-font-size'],
    metadata: {
      em: remToEm(tokens['typography.heading-lg-font-size']),
      px: remToPx(
        tokens['typography.heading-lg-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-lg-font-size'],
    },
  },
  'typography.heading-lg-line-height': {
    description:
      'Line height for large text used for headings — Equivalent to 60px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-lg-line-height',
    type: 'typography',
    value: tokens['typography.heading-lg-line-height'],
    metadata: {
      em: remToEm(tokens['typography.heading-lg-line-height']),
      px: remToPx(
        tokens['typography.heading-lg-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-lg-line-height'],
    },
  },
  'typography.heading-xl-font-size': {
    description:
      'Font size for extra large text used for headings — Equivalent to 60px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-xl-font-size',
    type: 'typography',
    value: tokens['typography.heading-xl-font-size'],
    metadata: {
      em: remToEm(tokens['typography.heading-xl-font-size']),
      px: remToPx(
        tokens['typography.heading-xl-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-xl-font-size'],
    },
  },
  'typography.heading-xl-line-height': {
    description:
      'Line height for extra large text used for headings — Equivalent to 72px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-xl-line-height',
    type: 'typography',
    value: tokens['typography.heading-xl-line-height'],
    metadata: {
      em: remToEm(tokens['typography.heading-xl-line-height']),
      px: remToPx(
        tokens['typography.heading-xl-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-xl-line-height'],
    },
  },
  'typography.heading-xxl-font-size': {
    description:
      'Font size for extra extra large text used for headings — Equivalent to 72px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-xxl-font-size',
    type: 'typography',
    value: tokens['typography.heading-xxl-font-size'],
    metadata: {
      em: remToEm(tokens['typography.heading-xxl-font-size']),
      px: remToPx(
        tokens['typography.heading-xxl-font-size'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-xxl-font-size'],
    },
  },
  'typography.heading-xxl-line-height': {
    description:
      'Line height for extra extra large text used for headings — Equivalent to 90px.',
    introducedIn: 'v0.0.1',
    name: 'typography.heading-xxl-line-height',
    type: 'typography',
    value: tokens['typography.heading-xxl-line-height'],
    metadata: {
      em: remToEm(tokens['typography.heading-xxl-line-height']),
      px: remToPx(
        tokens['typography.heading-xxl-line-height'],
        TOKEN_TYPOGRAPHY_BASE_FONT_SIZE,
      ),
      rem: tokens['typography.heading-xxl-line-height'],
    },
  },
  'typography.font-weight-regular': {
    description:
      'Regular font weight for copy and controls text, as well as, headings.',
    introducedIn: 'v0.0.1',
    name: 'typography.font-weight-regular',
    type: 'typography',
    value: tokens['typography.font-weight-regular'],
    metadata: {
      em: tokens['typography.font-weight-regular'],
      px: tokens['typography.font-weight-regular'],
      rem: tokens['typography.font-weight-regular'],
    },
  },
  'typography.font-weight-medium': {
    description:
      'Medium font weight for copy and controls text, as well as, headings.',
    introducedIn: 'v0.0.1',
    name: 'typography.font-weight-medium',
    type: 'typography',
    value: tokens['typography.font-weight-medium'],
    metadata: {
      em: tokens['typography.font-weight-medium'],
      px: tokens['typography.font-weight-medium'],
      rem: tokens['typography.font-weight-medium'],
    },
  },
  'typography.font-weight-semibold': {
    description:
      'Semibold font weight for copy and controls text, as well as, headings.',
    introducedIn: 'v0.0.1',
    name: 'typography.font-weight-semibold',
    type: 'typography',
    value: tokens['typography.font-weight-semibold'],
    metadata: {
      em: tokens['typography.font-weight-semibold'],
      px: tokens['typography.font-weight-semibold'],
      rem: tokens['typography.font-weight-semibold'],
    },
  },
  'typography.font-weight-bold': {
    description:
      'Bold font weight for copy and controls text, as well as, headings.',
    introducedIn: 'v0.0.1',
    name: 'typography.font-weight-bold',
    type: 'typography',
    value: tokens['typography.font-weight-bold'],
    metadata: {
      em: tokens['typography.font-weight-bold'],
      px: tokens['typography.font-weight-bold'],
      rem: tokens['typography.font-weight-bold'],
    },
  },
} as const

export type TokenFoundationTypography = keyof typeof tokens

export type TokenFoundationTypographyHeadingSize = Extract<
  TokenFoundationTypography,
  | 'typography.heading-xs-font-size'
  | 'typography.heading-sm-font-size'
  | 'typography.heading-md-font-size'
  | 'typography.heading-lg-font-size'
  | 'typography.heading-xl-font-size'
  | 'typography.heading-xxl-font-size'
>

export type TokenFoundationTypographyHeadingLineHeight = Extract<
  TokenFoundationTypography,
  | 'typography.heading-xs-line-height'
  | 'typography.heading-sm-line-height'
  | 'typography.heading-md-line-height'
  | 'typography.heading-lg-line-height'
  | 'typography.heading-xl-line-height'
  | 'typography.heading-xxl-line-height'
>

export type TokenFoundationTypographyTextSize = Extract<
  TokenFoundationTypography,
  | 'typography.text-xs-font-size'
  | 'typography.text-sm-font-size'
  | 'typography.text-md-font-size'
  | 'typography.text-lg-font-size'
  | 'typography.text-xl-font-size'
>

export type TokenFoundationTypographyTextLineHeight = Extract<
  TokenFoundationTypography,
  | 'typography.text-xs-line-height'
  | 'typography.text-sm-line-height'
  | 'typography.text-md-line-height'
  | 'typography.text-lg-line-height'
  | 'typography.text-xl-line-height'
>

export type TokenFoundationTypographyWeight = Extract<
  TokenFoundationTypography,
  | 'typography.font-weight-regular'
  | 'typography.font-weight-medium'
  | 'typography.font-weight-semibold'
  | 'typography.font-weight-bold'
>

export type TokenFoundationTypographySize =
  | TokenFoundationTypographyTextSize
  | TokenFoundationTypographyHeadingSize

export type TokenFoundationTypographyLineHeight =
  | TokenFoundationTypographyTextLineHeight
  | TokenFoundationTypographyHeadingLineHeight
