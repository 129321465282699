import { useCallback, useState } from 'react'

type HookOptions = {
  /**
   * An optional prefix string to apply to the generated ID.
   */
  prefix?: string
}

type HookReturn = [
  /**
   * `clientID` - A unique 8 character string.
   */
  string,
  /**
   * `resetClientID` - Function used to update the `clientID`.
   */
  () => void,
]

function uid(prefix: HookOptions['prefix']): string {
  return `${prefix ? `${prefix}-` : ''}${(
    performance.now().toString(36) + Math.random().toString(36)
  )
    .replace(/\./g, '')
    .slice(0, 8)
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('')}`
}

/**
 * Returns an updateable unique 8 character string with an optional `prefix`.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Performance/now}
 */
function useClientID(prefix?: string): HookReturn {
  const [clientID, setClientID] = useState(uid(prefix))

  const resetClientID = useCallback(() => {
    setClientID(uid(prefix))
  }, [prefix])

  return [clientID, resetClientID]
}

export type { HookOptions, HookReturn }
export default useClientID
