import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignVerticalCenter01IconProps = IconProps

const AlignVerticalCenter01Icon = forwardRef<
  SVGSVGElement,
  AlignVerticalCenter01IconProps
>((props: AlignVerticalCenter01IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 12h18M12 2v6.5m0 0 4-4m-4 4-4-4M12 22v-6.5m0 0 4 4m-4-4-4 4"
      fill="none"
    ></path>
  </Icon>
))

AlignVerticalCenter01Icon.displayName = 'AlignVerticalCenter01Icon'
AlignVerticalCenter01Icon.defaultProps = iconDefaultProps

export type { AlignVerticalCenter01IconProps }
export { AlignVerticalCenter01Icon }
