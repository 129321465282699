import React from 'react'
import styled from 'styled-components'

import {
  Flex,
  foundation,
  Spacer,
  Text,
  toTransitions,
} from '@vori/gourmet-components'

import { pluralize } from '@vori/dashboard-utils'

import { DATAGRID_HEADER_HEIGHT } from './constants'

const Container = styled(Flex)<{ $withSelectedState?: boolean }>(
  ({ $withSelectedState }) => ({
    backgroundColor: $withSelectedState ? '#F4F3FF' : '#FFFFFF',
    minHeight: DATAGRID_HEADER_HEIGHT,
    padding: `${foundation.spacing.tiny} ${foundation.spacing.medium}`,
    transition: toTransitions(['background-color', 'border-color'], 'ease'),
  }),
)

interface Props {
  defaultSelectedLabel?: React.ReactNode
  renderActions?: () => React.ReactNode
  renderFilters?: () => React.ReactNode
  renderSelectedLabel?: () => React.ReactNode
  selectedCounter?: number
}

function getSelectedLabel({
  defaultSelectedLabel,
  renderSelectedLabel,
  selectedCounter,
}: Pick<
  Props,
  'defaultSelectedLabel' | 'renderSelectedLabel' | 'selectedCounter'
>): React.ReactNode {
  if (
    selectedCounter == null ||
    (selectedCounter === 0 && !defaultSelectedLabel)
  ) {
    return <Text size="body">Select items to take an action</Text>
  }

  if (selectedCounter === 0 && defaultSelectedLabel != null) {
    return typeof defaultSelectedLabel === 'string' ? (
      <Text size="body">{defaultSelectedLabel}</Text>
    ) : (
      defaultSelectedLabel
    )
  }

  if (selectedCounter > 0) {
    if (renderSelectedLabel != null) {
      const selectedLabel = renderSelectedLabel()

      return typeof selectedLabel === 'string' ? (
        <Text size="body">{selectedLabel}</Text>
      ) : (
        selectedLabel
      )
    } else {
      return (
        <Text size="body">
          {selectedCounter} {pluralize('item', selectedCounter)} selected
        </Text>
      )
    }
  }

  return null
}

function DataGridActions({
  defaultSelectedLabel,
  renderActions,
  renderFilters,
  renderSelectedLabel,
  selectedCounter,
}: Props): JSX.Element {
  return (
    <Container
      centerY
      fullWidth
      justifyContent="space-between"
      $withSelectedState={Boolean(selectedCounter && selectedCounter > 0)}
    >
      <Flex centerY shrink={0}>
        {getSelectedLabel({
          defaultSelectedLabel,
          renderSelectedLabel,
          selectedCounter,
        })}
      </Flex>
      <Spacer />
      {renderFilters != null && renderFilters()}
      <Spacer />
      {renderActions != null && (
        <Flex centerY fullWidth justifyContent="flex-end">
          {renderActions()}
        </Flex>
      )}
    </Container>
  )
}

export { DataGridActions }
export type { Props as DataGridActionsProps }
