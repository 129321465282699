import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenLeftIconProps = IconProps

const ArrowCircleBrokenLeftIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenLeftIconProps
>((props: ArrowCircleBrokenLeftIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20.662 17A9.996 9.996 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.996 9.996 0 0 1 8.662 5M12 8l-4 4m0 0 4 4m-4-4h14"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenLeftIcon.displayName = 'ArrowCircleBrokenLeftIcon'
ArrowCircleBrokenLeftIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenLeftIconProps }
export { ArrowCircleBrokenLeftIcon }
