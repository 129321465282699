/* eslint-disable @typescript-eslint/no-namespace */

import { ApolloProvider } from '@apollo/client'
import { createBrowserHistory } from 'history'
import { GlobalStyles, ToastProvider } from '@vori/gourmet-components'
import { Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'

import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import React from 'react'
import ReactDOM from 'react-dom'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { CourierProvider } from '@vori/dashboard-integrations/Courier/CourierProvider'
import { CurrentUserProvider } from '@vori/dashboard-hooks/useCurrentUser'
import { ENV_LOCAL, ENV_USE_MOCKS } from '@vori/dashboard-env'
import { GlobalErrorBoundary } from '@vori/dashboard-components'
import { initializeIntegrations } from '@vori/dashboard-integrations/initializeIntegrations'

import { getApolloClient } from './graphql/client'
import { MainRoutes } from './pages'
import MaterialUITheme from './styles/MaterialUITheme'

declare global {
  interface Window {
    crypto: Crypto
    msCrypto: Crypto
  }

  namespace JSX {
    interface IntrinsicElements {
      'explo-report-builder': unknown
    }
  }
}

initializeIntegrations()

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)

const history = createBrowserHistory()

if (!window.crypto) {
  window.crypto = window.msCrypto
}

async function withMocks() {
  if (!ENV_LOCAL || !ENV_USE_MOCKS) {
    return
  }

  const { setupWorker } = await import('./mocks/browser')

  return setupWorker().start()
}

const AppWrapper = (): JSX.Element => {
  return (
    <Sentry.ErrorBoundary>
      <GlobalErrorBoundary>
        <Router history={history}>
          <ApolloProvider client={getApolloClient()}>
            <CurrentUserProvider>
              <GlobalStyles />
              <ThemeProvider theme={MaterialUITheme}>
                <ToastProvider
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                  <CourierProvider>
                    <MainRoutes />
                  </CourierProvider>
                </ToastProvider>
              </ThemeProvider>
            </CurrentUserProvider>
          </ApolloProvider>
        </Router>
      </GlobalErrorBoundary>
    </Sentry.ErrorBoundary>
  )
}

withMocks().then(() => {
  ReactDOM.render(<AppWrapper />, document.getElementById('root'))
})
