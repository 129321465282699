import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudRaining04IconProps = IconProps

const CloudRaining04Icon = forwardRef<SVGSVGElement, CloudRaining04IconProps>(
  (props: CloudRaining04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 18.5V21m-8-2.5V21m4-1.5V22m-5-7a5 5 0 1 1 .1-9.999 5.502 5.502 0 0 1 10.195 1.004A4.5 4.5 0 1 1 17.5 15H6.999Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudRaining04Icon.displayName = 'CloudRaining04Icon'
CloudRaining04Icon.defaultProps = iconDefaultProps

export type { CloudRaining04IconProps }
export { CloudRaining04Icon }
