import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Bold01IconProps = IconProps

const Bold01Icon = forwardRef<SVGSVGElement, Bold01IconProps>(
  (props: Bold01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 12h8a4 4 0 0 0 0-8H6v8Zm0 0h9a4 4 0 0 1 0 8H6v-8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Bold01Icon.displayName = 'Bold01Icon'
Bold01Icon.defaultProps = iconDefaultProps

export type { Bold01IconProps }
export { Bold01Icon }
