import { ReducerState } from './types'

export function hasSelectedOptions(
  selectedOptions: ReducerState['selectedOptions'],
): boolean {
  return selectedOptions.size > 0
}

export function optionIsSelected(state: ReducerState, option: string): boolean {
  return state.selectedOptions.has(option)
}

export function getLabel(
  selectedOptions: ReducerState['selectedOptions'],
  defaultLabel = '',
): string {
  return `${
    selectedOptions.size > 0
      ? Array.from(selectedOptions).join(', ')
      : defaultLabel
  }`
}
