import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FaceHappyIconProps = IconProps

const FaceHappyIcon = forwardRef<SVGSVGElement, FaceHappyIconProps>(
  (props: FaceHappyIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 9h.01M9 9h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-6.5-3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-6 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm2.5 8.5c2.5 0 4.5-1.833 4.5-3.5h-9c0 1.667 2 3.5 4.5 3.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FaceHappyIcon.displayName = 'FaceHappyIcon'
FaceHappyIcon.defaultProps = iconDefaultProps

export type { FaceHappyIconProps }
export { FaceHappyIcon }
