import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Shuffle02IconProps = IconProps

const Shuffle02Icon = forwardRef<SVGSVGElement, Shuffle02IconProps>(
  (props: Shuffle02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 16v5m0 0h-5m5 0-6-6M3 3l6 6m7-6h5m0 0v5m0-5L3 21"
        fill="none"
      ></path>
    </Icon>
  ),
)

Shuffle02Icon.displayName = 'Shuffle02Icon'
Shuffle02Icon.defaultProps = iconDefaultProps

export type { Shuffle02IconProps }
export { Shuffle02Icon }
