import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TrendUp01IconProps = IconProps

const TrendUp01Icon = forwardRef<SVGSVGElement, TrendUp01IconProps>(
  (props: TrendUp01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m22 7-7.869 7.869c-.396.396-.594.594-.822.668a1 1 0 0 1-.618 0c-.228-.074-.426-.272-.822-.668L9.13 12.13c-.396-.396-.594-.594-.822-.668a1 1 0 0 0-.618 0c-.228.074-.426.272-.822.668L2 17M22 7h-7m7 0v7"
        fill="none"
      ></path>
    </Icon>
  ),
)

TrendUp01Icon.displayName = 'TrendUp01Icon'
TrendUp01Icon.defaultProps = iconDefaultProps

export type { TrendUp01IconProps }
export { TrendUp01Icon }
