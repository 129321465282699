import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Divide03IconProps = IconProps

const Divide03Icon = forwardRef<SVGSVGElement, Divide03IconProps>(
  (props: Divide03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8h.01M12 16h.01M7 12h10m-4.5-4a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm0 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm9.5-4c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Divide03Icon.displayName = 'Divide03Icon'
Divide03Icon.defaultProps = iconDefaultProps

export type { Divide03IconProps }
export { Divide03Icon }
