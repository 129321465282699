import { foundation } from '../tokens'

const AccordionTokens = {
  colors: {
    item: {
      borderColor: foundation.colors.light4,
    },
  },

  typography: {
    itemTrigger: {
      fontSize: foundation.typography.fontSizes.h4,
      fontWeight: foundation.typography.fontWeights.bold,
      lineHeight: foundation.typography.lineHeights.h4,
    },
  },
} as const

export { AccordionTokens }
