import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FolderDownloadIconProps = IconProps

const FolderDownloadIcon = forwardRef<SVGSVGElement, FolderDownloadIconProps>(
  (props: FolderDownloadIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m13 7-1.116-2.231c-.32-.642-.481-.963-.72-1.198a2 2 0 0 0-.748-.462C10.1 3 9.74 3 9.022 3H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 4.52 2 5.08 2 6.2V7m0 0h15.2c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C22 9.28 22 10.12 22 11.8v4.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.72 21 18.88 21 17.2 21H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C2 18.72 2 17.88 2 16.2V7Zm7 7 3 3m0 0 3-3m-3 3v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

FolderDownloadIcon.displayName = 'FolderDownloadIcon'
FolderDownloadIcon.defaultProps = iconDefaultProps

export type { FolderDownloadIconProps }
export { FolderDownloadIcon }
