import React from 'react'
import { AppSidebarLink, AppSidebarLinks } from '@vori/gourmet-components'

import {
  Receipt02Icon,
  UserCircleIcon,
  BookOpen01Icon,
  Home03Icon,
  Tag01Icon,
  Building02Icon,
} from '@vori/gourmet-icons'

import { routes } from '../routes'

/**
 * @todo Moving forwards, these links will be enabled based on the current
 * user's permissions. For the time being, only "store admins" have access
 * to navigation within the retail dashboard.
 */
const links: Record<string, JSX.Element> = {
  home: (
    <AppSidebarLink
      href={routes.home.root.path}
      icon={<Home03Icon />}
      key="sidebar-navigation-home"
      label="Home"
    />
  ),
  profile: (
    <AppSidebarLink
      href={routes.profile.root.path}
      icon={<UserCircleIcon />}
      key="sidebar-navigation-profile"
      label="Profile"
    />
  ),
  catalog: (
    <AppSidebarLink
      href={routes.products.root.path}
      icon={<BookOpen01Icon />}
      key="sidebar-navigation-catalog"
      label="Catalog"
    />
  ),
  crm: (
    <AppSidebarLink
      href={`${routes.crm.root.path}?status=all`}
      icon={<Building02Icon />}
      key="sidebar-navigation-crm"
      label="Customers"
    />
  ),
  promotions: (
    <AppSidebarLink
      href={routes.promotions.root.path}
      icon={<Tag01Icon />}
      key="sidebar-navigation-promotions"
      label="Promotions"
    />
  ),
  orders: (
    <AppSidebarLink
      href={routes.orders.needsAction.path}
      icon={<Receipt02Icon />}
      key="sidebar-navigation-orders"
      label="Orders"
    />
  ),
}

function VendorNavigation(): JSX.Element | null {
  return <AppSidebarLinks>{Object.values(links)}</AppSidebarLinks>
}

export { VendorNavigation }
