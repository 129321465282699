export function cleanInitialValue(
  value: React.InputHTMLAttributes<HTMLInputElement>['value'],
  decimalPlaces: number,
): string {
  if (!value) {
    return ''
  }

  const parsedValue = Number.parseFloat(
    `${value}`.replace(/[^.\d]/g, ''),
  ).toFixed(decimalPlaces)

  if (parsedValue === 'NaN') {
    return ''
  }

  return parsedValue
}

export function formatValueToCurrency(
  value: string,
  decimalPlaces: number,
  withSymbol?: boolean,
): string {
  if (!value) {
    return ''
  }

  let sanitizedValue = value

  if (withSymbol) {
    sanitizedValue = sanitizedValue.replace('$', '')
  }

  sanitizedValue = value.replace(/[^\d]/g, '') // Remove any non-digit characters

  if (sanitizedValue.length > decimalPlaces) {
    return `${withSymbol ? '$' : ''}${sanitizedValue
      .slice(0, -decimalPlaces)
      .replace(/^0+(?=\d)/, '')}.${sanitizedValue.slice(-decimalPlaces)}`
  }

  return `${withSymbol ? '$' : ''}0.${'0'.repeat(
    decimalPlaces - sanitizedValue.length,
  )}${sanitizedValue}`
}
