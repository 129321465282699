import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyRupeeCircleIconProps = IconProps

const CurrencyRupeeCircleIcon = forwardRef<
  SVGSVGElement,
  CurrencyRupeeCircleIconProps
>((props: CurrencyRupeeCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.5 10h7m-7-3.5h7M14 18l-5.5-4.5H10c4.445 0 4.445-7 0-7M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

CurrencyRupeeCircleIcon.displayName = 'CurrencyRupeeCircleIcon'
CurrencyRupeeCircleIcon.defaultProps = iconDefaultProps

export type { CurrencyRupeeCircleIconProps }
export { CurrencyRupeeCircleIcon }
