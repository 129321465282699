import React, { useCallback } from 'react'
import styled from 'styled-components'

import { base as tokens } from '../tokens'
import { ListBoxProps } from '../ListBox'

import SearchInput from '../SearchInput'
import Flex from '../Flex'
import Loader from '../Loader'
import Spacer from '../Spacer'
import Text from '../Text'

import { CommonProps } from './types'

import {
  Props as SelectProps,
  Internals as SelectInternals,
} from './Select/utils/types'

import {
  Props as MultiSelectProps,
  Internals as MultiSelectInternals,
} from './MultiSelect/utils/types'

const LoadingText = styled(Text)<{ $isSelected?: boolean }>`
  && {
    ${({ $isSelected }) =>
      $isSelected === true
        ? `color: ${tokens.colors.pureWhite} !important;`
        : ''}
  }
`

function LoadingOption({
  isSelected,
  label,
}: {
  isSelected: boolean
  label: string
}): JSX.Element {
  return (
    <Flex centerY fullWidth justifyContent="space-between">
      <Flex centerY fullWidth>
        <Flex inline shrink={0}>
          <Loader size="small" variant={isSelected ? 'secondary' : 'primary'} />
        </Flex>
        <Spacer size="small" />
        <LoadingText
          $isSelected={isSelected}
          size="display"
          variant="secondary"
        >
          {label}
        </LoadingText>
      </Flex>
    </Flex>
  )
}

export function useGetOriginalOption<T>(
  defaultGetOriginalOption: CommonProps<T>['getOriginalOption'],
): NonNullable<CommonProps<T>['getOriginalOption']> {
  const getOriginalOption = useCallback<
    NonNullable<CommonProps<T>['getOriginalOption']>
  >(
    (option: string | null) => {
      if (defaultGetOriginalOption != null) {
        return defaultGetOriginalOption(option)
      }

      return option as unknown as T
    },
    [defaultGetOriginalOption],
  )

  return getOriginalOption
}

export function useGetOptionLabel<T>(
  defaultGetOptionLabel: CommonProps<T>['getOptionLabel'],
): NonNullable<CommonProps<T>['getOptionLabel']> {
  const getOptionLabel = useCallback<
    NonNullable<CommonProps<T>['getOptionLabel']>
  >(
    (option: T) => {
      if (defaultGetOptionLabel != null) {
        return defaultGetOptionLabel(option)
      }

      return String(option)
    },
    [defaultGetOptionLabel],
  )

  return getOptionLabel
}

export function useGetOptionDisabled<T>(
  defaultGetOptionDisabled: CommonProps<T>['getOptionDisabled'],
  options: Array<T>,
  getDisabledOptions: CommonProps<T>['getDisabledOptions'],
): NonNullable<CommonProps<T>['getOptionDisabled']> {
  const getOptionDisabled = useCallback<
    NonNullable<CommonProps<T>['getOptionDisabled']>
  >(
    (option: T) => {
      const isDisabled =
        getDisabledOptions != null
          ? getDisabledOptions(options).includes(option)
          : false

      if (defaultGetOptionDisabled != null) {
        return defaultGetOptionDisabled(option) || isDisabled
      }

      return isDisabled
    },
    [defaultGetOptionDisabled, getDisabledOptions, options],
  )

  return getOptionDisabled
}

export function useGetOptionLoadingLabel<T>(
  defaultGetOptionLoadingLabel: CommonProps<T>['getOptionLoadingLabel'],
  getOptionLabel: NonNullable<CommonProps<T>['getOptionLabel']>,
): NonNullable<CommonProps<T>['getOptionLoadingLabel']> {
  const getOptionLoadingLabel = useCallback<
    NonNullable<CommonProps<T>['getOptionLoadingLabel']>
  >(
    (option: T) => {
      if (defaultGetOptionLoadingLabel != null) {
        return defaultGetOptionLoadingLabel(option)
      }

      return String(getOptionLabel(option))
    },
    [defaultGetOptionLoadingLabel, getOptionLabel],
  )

  return getOptionLoadingLabel
}

export function useGetOptionLoading<T>(
  defaultGetOptionLoading: CommonProps<T>['getOptionLoading'],
): NonNullable<CommonProps<T>['getOptionLoading']> {
  const getOptionLoading = useCallback<
    NonNullable<CommonProps<T>['getOptionLoading']>
  >(
    (option: T) => {
      if (defaultGetOptionLoading != null) {
        return defaultGetOptionLoading(option)
      }

      return false
    },
    [defaultGetOptionLoading],
  )

  return getOptionLoading
}

export function useRenderLoadingOptionForSelect<T>(
  defaultRenderLoadingOption: SelectProps<T>['renderLoadingOption'],
  getOptionLoadingLabel: NonNullable<CommonProps<T>['getOptionLoadingLabel']>,
  internals: SelectInternals<T>,
): NonNullable<SelectProps<T>['renderLoadingOption']> {
  const renderLoadingOption = useCallback<
    NonNullable<SelectProps<T>['renderLoadingOption']>
  >(
    (option: T, isSelected: boolean) => {
      if (defaultRenderLoadingOption != null) {
        return defaultRenderLoadingOption(option, isSelected, internals)
      }

      return (
        <LoadingOption
          isSelected={isSelected}
          label={getOptionLoadingLabel(option)}
        />
      )
    },
    [defaultRenderLoadingOption, getOptionLoadingLabel, internals],
  )

  return renderLoadingOption
}

export function useRenderLoadingOptionForMultiSelect<T>(
  defaultRenderLoadingOption: MultiSelectProps<T>['renderLoadingOption'],
  getOptionLoadingLabel: NonNullable<CommonProps<T>['getOptionLoadingLabel']>,
  internals: MultiSelectInternals<T>,
): NonNullable<MultiSelectProps<T>['renderLoadingOption']> {
  const renderLoadingOption = useCallback<
    NonNullable<MultiSelectProps<T>['renderLoadingOption']>
  >(
    (option: T, isSelected: boolean) => {
      if (defaultRenderLoadingOption != null) {
        return defaultRenderLoadingOption(option, isSelected, internals)
      }

      return (
        <LoadingOption
          isSelected={isSelected}
          label={getOptionLoadingLabel(option)}
        />
      )
    },
    [defaultRenderLoadingOption, getOptionLoadingLabel, internals],
  )

  return renderLoadingOption
}

export function useOnSearchInputChange<T>(
  search: (keyword: string) => void,
  searchInputProps: CommonProps<T>['searchInputProps'],
): (event: React.ChangeEvent<HTMLInputElement>) => void {
  const onSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      search(event.target.value)

      if (searchInputProps?.onChange != null) {
        searchInputProps.onChange(event)
      }
    },
    [search, searchInputProps],
  )

  return onSearchInputChange
}

export function useOnSearchInputKeyDown<T>(
  searchInputProps: CommonProps<T>['searchInputProps'],
): (event: React.KeyboardEvent<HTMLInputElement>) => void {
  const onSearchInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'ArrowDown' && event.key !== 'Escape') {
        event.stopPropagation()
      }

      if (searchInputProps?.onKeyDown != null) {
        searchInputProps.onKeyDown(event)
      }
    },
    [searchInputProps],
  )

  return onSearchInputKeyDown
}

export function useRenderFooter<T, Internals>(
  showLoadingState: CommonProps<T>['showLoadingState'],
  showInitialLoadingState: CommonProps<T>['showInitialLoadingState'],
  showErrorState: CommonProps<T>['showErrorState'],
  showEmptyState: CommonProps<T>['showEmptyState'],
  errorStateMessage: CommonProps<T>['errorStateMessage'],
  options: Array<T>,
  emptyStateMessage: CommonProps<T>['emptyStateMessage'],
  defaultRenderFooter:
    | ((
        internals: Internals,
      ) => ReturnType<NonNullable<ListBoxProps<T>['renderFooter']>>)
    | undefined,
  internals: Internals,
): NonNullable<ListBoxProps<T>['renderFooter']> {
  const renderFooter = useCallback<
    NonNullable<ListBoxProps<T>['renderFooter']>
  >(
    () => (
      <>
        {(showLoadingState || showInitialLoadingState) && (
          <Flex center column fullWidth>
            <Spacer size={showInitialLoadingState ? 'base' : 'tiny'} />
            <Loader />
            <Spacer size={showInitialLoadingState ? 'base' : 'tiny'} />
          </Flex>
        )}

        {showErrorState && (
          <Flex center column fullWidth>
            <Spacer />
            {errorStateMessage == null ||
            typeof errorStateMessage === 'string' ? (
              <Text align="center" variant="negative">
                {errorStateMessage || 'There was an error, please try again...'}
              </Text>
            ) : (
              errorStateMessage
            )}
            <Spacer />
          </Flex>
        )}

        {!showLoadingState &&
          !showInitialLoadingState &&
          !showErrorState &&
          (options.length === 0 || showEmptyState) && (
            <Flex center column fullWidth>
              <Spacer />
              {emptyStateMessage == null ||
              typeof emptyStateMessage === 'string' ? (
                <Text align="center" variant="secondary">
                  {emptyStateMessage || 'No results...'}
                </Text>
              ) : (
                emptyStateMessage
              )}
              <Spacer />
            </Flex>
          )}

        {defaultRenderFooter != null ? defaultRenderFooter(internals) : null}
      </>
    ),
    [
      showLoadingState,
      showInitialLoadingState,
      showErrorState,
      errorStateMessage,
      options.length,
      showEmptyState,
      emptyStateMessage,
      defaultRenderFooter,
      internals,
    ],
  )

  return renderFooter
}

export function useRenderHeader<T, Internals>(
  withFuzzySearch: CommonProps<T>['withFuzzySearch'],
  searchInputProps: CommonProps<T>['searchInputProps'],
  onSearchInputChange: ReturnType<typeof useOnSearchInputChange>,
  onSearchInputKeyDown: ReturnType<typeof useOnSearchInputKeyDown>,
  defaultRenderHeader:
    | ((
        internals: Internals,
      ) => ReturnType<NonNullable<ListBoxProps<T>['renderFooter']>>)
    | undefined,
  internals: Internals,
): NonNullable<ListBoxProps<T>['renderHeader']> {
  const renderHeader = useCallback<
    NonNullable<ListBoxProps<T>['renderHeader']>
  >(
    () => (
      <>
        {withFuzzySearch ? (
          <SearchInput
            {...searchInputProps}
            data-test-id="listbox-search"
            onChange={onSearchInputChange}
            onKeyDown={onSearchInputKeyDown}
            tabIndex={0}
          />
        ) : null}
        {defaultRenderHeader != null ? defaultRenderHeader(internals) : null}
      </>
    ),
    [
      defaultRenderHeader,
      internals,
      onSearchInputChange,
      onSearchInputKeyDown,
      searchInputProps,
      withFuzzySearch,
    ],
  )

  return renderHeader
}
