import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Italic01IconProps = IconProps

const Italic01Icon = forwardRef<SVGSVGElement, Italic01IconProps>(
  (props: Italic01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 4h-9m4 16H5M15 4 9 20"
        fill="none"
      ></path>
    </Icon>
  ),
)

Italic01Icon.displayName = 'Italic01Icon'
Italic01Icon.defaultProps = iconDefaultProps

export type { Italic01IconProps }
export { Italic01Icon }
