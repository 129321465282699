import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Asterisk02IconProps = IconProps

const Asterisk02Icon = forwardRef<SVGSVGElement, Asterisk02IconProps>(
  (props: Asterisk02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4v16m6-14L6 18m14-6H4m14 6L6 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Asterisk02Icon.displayName = 'Asterisk02Icon'
Asterisk02Icon.defaultProps = iconDefaultProps

export type { Asterisk02IconProps }
export { Asterisk02Icon }
