import React from 'react'

import {
  ButtonNext as Button,
  ButtonNextProps as ButtonProps,
  spacing,
} from '@vori/gourmet-components'

import { XCloseIcon } from '@vori/gourmet-icons'

function DataGridGroupingMenuRemoveButton(props: ButtonProps): JSX.Element {
  return (
    <Button
      {...props}
      aria-label="Remove group"
      asIconButton
      data-active=""
      leftIcon={<XCloseIcon />}
      variant="secondary"
      style={{
        borderBottomLeftRadius: 0,
        borderLeftWidth: 0,
        borderTopLeftRadius: 0,
        padding: spacing.input.base,
      }}
    />
  )
}

export { DataGridGroupingMenuRemoveButton }
