import { CSSObject } from 'styled-components'
import React from 'react'
import styled from 'styled-components'

import { DotIndicatorVariant } from './types'
import { DotIndicatorTokens } from './tokens'
import { DOT_INDICATOR_DEFAULT_VARIANT } from './constants'

function styles(props: { $variant?: Props['variant'] }): CSSObject {
  return {
    backgroundColor:
      DotIndicatorTokens.color.root[
        props.$variant || DOT_INDICATOR_DEFAULT_VARIANT
      ].backgroundColor,
    borderRadius: DotIndicatorTokens.sizing.root.borderRadius,
    height: DotIndicatorTokens.sizing.root.height,
    width: DotIndicatorTokens.sizing.root.width,
  }
}

const DotIndicatorContainer = styled.span(styles)

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * Specifies the variant or color of the `<DotIndicator>` component, which
   * is `'default'` by default.
   */
  variant?: DotIndicatorVariant
}

/**
 * Dot indicators provide an extra visual cue as to the status of something and
 * it's most commonly used alongside `<Badge>` and `<Tag>` components. It's
 * important to note that since it acts as strictly a visual cue, you should
 * provide the adequate context through the `label` prop of `<Badge>` and `<Tag>`
 * components or any other component that contains it.
 *
 * @example
 * <Badge label="Changes to this product are currently pending">
 *   <DotIndicator variant="warning" />
 *   <Spacer inline size="tiny" />
 *   Pending
 * </Badge>
 */
const DotIndicator = React.forwardRef<HTMLSpanElement, Props>(
  function DotIndicator(
    { variant = DOT_INDICATOR_DEFAULT_VARIANT, ...props }: Props,
    ref,
  ): JSX.Element {
    return (
      <DotIndicatorContainer
        $variant={variant}
        aria-hidden="true"
        data-gourmet-dot-indicator=""
        data-variant={variant}
        ref={ref}
        {...props}
      />
    )
  },
)

DotIndicator.displayName = 'DotIndicator'
DotIndicator.defaultProps = {}

export { DotIndicator, styles as DotIndicatorStyles }
export type { Props as DotIndicatorProps }
