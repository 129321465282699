import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Cryptocurrency03IconProps = IconProps

const Cryptocurrency03Icon = forwardRef<
  SVGSVGElement,
  Cryptocurrency03IconProps
>((props: Cryptocurrency03IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m2 2 2 2m18-2-2 2m2 18-2-2M2 22l2-2m-2-4h1.5M8 2v1.5M22 8h-1.5M16 22v-1.5m2-4.5h3.5M16 2v4M2 8h4m2 14v-4m8-6a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
      fill="none"
    ></path>
  </Icon>
))

Cryptocurrency03Icon.displayName = 'Cryptocurrency03Icon'
Cryptocurrency03Icon.defaultProps = iconDefaultProps

export type { Cryptocurrency03IconProps }
export { Cryptocurrency03Icon }
