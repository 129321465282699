import React from 'react'
import styled from 'styled-components'

import { show as showIntercom } from '@intercom/messenger-js-sdk'

import {
  AppHeaderProps,
  NavButtonNext as NavButton,
  foundation,
} from '@vori/gourmet-components'

import { CourierInbox } from '@vori/dashboard-integrations/Courier/CourierInbox'
import { ENV_PROD } from '@vori/dashboard-env'
import { EventNames, trackEvent } from '@vori/dashboard-integrations/Segment'
import { routes } from '@vori/dashboard-routes/auth'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

const Link = styled(NavButton)({
  color: `${foundation.colors.pureWhite} !important`,

  ':hover, :active, :focus, &.active': {
    backgroundColor: 'transparent !important',
    color: `${foundation.colors.pureWhite} !important`,
    textDecoration: 'underline',
  },

  ':visited': {
    color: `${foundation.colors.pureWhite} !important`,
  },
})

function usePageHeaderActions(): AppHeaderProps['rightContent'] {
  const { user } = useCurrentUserState()

  const actions = React.useMemo<AppHeaderProps['rightContent']>(() => {
    if (!user.state.isLoggedIn) {
      return []
    }

    return [
      <CourierInbox key="headerAction-notificationInbox" />,
      <Link
        key="headerAction-logout"
        asClickable
        noPadding
        size="small"
        to={routes.logout.root.path}
      >
        Logout
      </Link>,
      <Link
        key="headerAction-getSupport"
        asClickable
        noPadding
        onClick={(event) => {
          event.preventDefault()

          if (ENV_PROD) {
            trackEvent(EventNames.VENDOR_NAV_GET_SUPPORT_CLICKED)
            showIntercom()
          }
        }}
        size="small"
        to="/help"
      >
        Get Support
      </Link>,
    ]
  }, [user.state.isLoggedIn])

  return actions
}

export { usePageHeaderActions }
