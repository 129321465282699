import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import Flex, { FlexProps } from '../Flex'

import { AccordionContext, AccordionItemContext } from './AccordionContext'
import { AccordionState } from './types'
import { AccordionTokens } from './tokens'

interface Props {
  /**
   * Disables the use of this `<AccordionItem>` component and its children.
   */
  disabled?: boolean
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: FlexProps
  /**
   * **Warning:** This prop is for internal use only, and should not be
   * manually set.
   */
  itemIndex?: number
}

function styles(): CSSObject {
  return {
    borderTopColor: AccordionTokens.colors.item.borderColor,
    borderTopStyle: 'solid',
    borderTopWidth: 1,

    '&:last-child': {
      borderBottomColor: AccordionTokens.colors.item.borderColor,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
  }
}

const StyledAccordionItem = styled(Flex)(styles)

const AccordionItem = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<Props>
>(function AccordionItem(
  { children, disabled, flexProps, itemIndex }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [panelID] = useClientId('gourmet-accordion-item-panel')
  const [triggerID] = useClientId('gourmet-accordion-item-trigger')
  const { activeIndexes } = React.useContext(AccordionContext)

  const isActive = React.useMemo(
    () => activeIndexes.has(itemIndex as number),
    [activeIndexes, itemIndex],
  )

  return (
    <AccordionItemContext.Provider
      value={{
        isActive,
        itemIndex: itemIndex as number,
        disabled: Boolean(disabled),
        panelID,
        triggerID,
      }}
    >
      <StyledAccordionItem
        column
        fullWidth
        {...flexProps}
        data-gourmet-accordion-item={itemIndex}
        data-state={isActive ? AccordionState.OPEN : AccordionState.COLLAPSED}
        {...(disabled && { 'data-disabled': '' })}
        ref={ref}
      >
        {children}
      </StyledAccordionItem>
    </AccordionItemContext.Provider>
  )
})

AccordionItem.displayName = 'AccordionItem'
AccordionItem.defaultProps = {}

export { AccordionItem, styles as AccordionItemStyles }
export type { Props as AccordionItemProps }
