import React from 'react'
import { CourierProvider as OriginalCourierProvider } from '@trycourier/react-provider'
import { Toast } from '@trycourier/react-toast'
import { useHistory } from 'react-router-dom'

import { FeatureFlags } from '@vori/dashboard-constants'
import { useCourierToken } from '@vori/dashboard-integrations/Courier/useCourierToken'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import {
  ENV_COURIER_BRAND_ID,
  ENV_COURIER_CLIENT_KEY,
  ENV_USE_MOCKS,
} from '@vori/dashboard-env'

/**
 * Fetches the courier JWT and conditionally renders the courier provider if the
 * user has the feature flag enabled.
 *
 * Note: The `<OriginalCourierProvider>` component will emit an error on the
 * console, as well as a failed HTTP request upon the first render. These errors
 * are expected. They’re the agreed upon middle ground we reached with the Courier
 * team when they built the React integration, as they assumed clients would be
 * using server-side rendering and would always have a valid JWT on initial load.
 *
 * They made some changes to allow us to not have one on initial load, but that
 * console error and failed HTTP request is a side-effect of this implementation.
 */
export function CourierProvider({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element | null {
  const history = useHistory()
  const { user } = useCurrentUserState()

  const canSeeCourierInbox = useFeatureConfig(
    FeatureFlags.WEB_NOTIFICATIONS_INBOX,
  )

  const courierToken = useCourierToken()

  return canSeeCourierInbox && !ENV_USE_MOCKS ? (
    <OriginalCourierProvider
      authorization={courierToken || undefined}
      brandId={ENV_COURIER_BRAND_ID}
      clientKey={ENV_COURIER_CLIENT_KEY}
      onRouteChange={(route) => {
        history.push(route)
      }}
      userId={user.data.userSqlID || undefined}
    >
      {children}
      <Toast position="bottom-right" />
    </OriginalCourierProvider>
  ) : (
    <>{children}</>
  )
}
