import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { foundations } from '@vori/gourmet-tokens'
import { XCloseIcon, IconProps } from '@vori/gourmet-icons'

import { Button } from '../ButtonNext'
import { Flex, InlineFlex } from '../FlexNext'
import { Text } from '../TextNext'
import { toRem } from '../utils'

import { AlertVariant } from './types'

const color: Record<AlertVariant, string> = {
  default: foundations.color['color.gray-700'],
  negative: foundations.color['color.error-700'],
  positive: foundations.color['color.success-700'],
  primary: foundations.color['color.primary-700'],
  warning: foundations.color['color.warning-700'],
}

const backgroundColor: Record<AlertVariant, string> = {
  default: foundations.color['color.gray-25'],
  negative: foundations.color['color.error-25'],
  positive: foundations.color['color.success-25'],
  primary: foundations.color['color.primary-25'],
  warning: foundations.color['color.warning-25'],
}

const borderColor: Record<AlertVariant, string> = {
  default: foundations.color['color.gray-300'],
  negative: foundations.color['color.error-300'],
  positive: foundations.color['color.success-300'],
  primary: foundations.color['color.primary-300'],
  warning: foundations.color['color.warning-300'],
}

function styles(props: {
  $hasIcon?: boolean
  $variant?: Props['variant']
}): CSSObject {
  return {
    backgroundColor: backgroundColor[props.$variant || 'default'],
    borderColor: borderColor[props.$variant || 'default'],
    borderRadius: toRem(8),
    borderStyle: 'solid',
    borderWidth: 1,
    padding: toRem(16),

    '&, & [data-gourmet-text], & a, & button': {
      color: color[props.$variant || 'default'],
    },
  }
}

const StyledAlert = styled(Flex)(styles)

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * An icon to be displayed on the left side of the `<Alert>` component.
   *
   * @default undefined
   * @example
   * <Alert icon={<InfoCircleIcon />}>This is an alert</Alert>
   */
  icon?: React.ReactElement<IconProps> | null
  /**
   * Callback function to be called when clicking on the close button when using
   * the `withCloseButton` prop.
   *
   * @default undefined
   */
  onClickClose?: React.MouseEventHandler<HTMLButtonElement>
  /**
   * An optional title to be displayed on top of the component's content, i.e.
   * `children`.
   *
   * @default undefined
   */
  title?: React.ReactNode
  /**
   * Specifies the value for the `aria-live` attribute.
   *
   * @default "polite"
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions}
   * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/alert/examples/alert/#rps_label}
   */
  type?: React.AriaAttributes['aria-live']
  /**
   * Specifies the variant or color of the `<Alert>` component.
   *
   * @default "default"
   */
  variant?: AlertVariant
  /**
   * If `true`, the <Alert> component will contain a close button that you can
   * hook up to through the `onClickClose` prop.
   *
   * @default undefined
   */
  withCloseButton?: boolean
}

/**
 * An alert is an element that displays a brief, important message in a
 * way that attracts the user's attention without interrupting the user's task.
 *
 * @example
 * <Alert
 *   icon={<InfoCircleIcon />}
 *   title="Some title here"
 *   variant="warning"
 *   withCloseButton
 * >
 *   Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid
 *   pariatur, ipsum similique veniam.
 * </Alert>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/alert/}
 * @see {@link https://www.figma.com/file/5QbwzWVAPzcXsA4pXsjFDa/Gourmet---Web?type=design&node-id=2-26&mode=dev}
 */
const Alert = React.forwardRef<HTMLDivElement, Props>(function Alert(
  {
    children,
    icon,
    onClickClose,
    title,
    type,
    variant,
    withCloseButton,
    ...props
  }: Props,
  ref,
) {
  return (
    <StyledAlert
      {...props}
      $hasIcon={Boolean(icon)}
      $variant={variant}
      alignItems="flex-start"
      aria-live={type || 'polite'}
      data-gourmet-alert=""
      fullWidth
      gap="space.075"
      ref={ref}
      role="alert"
    >
      {icon && <InlineFlex data-gourmet-alert-icon="">{icon}</InlineFlex>}
      <InlineFlex data-gourmet-alert-content="" direction="column" fullWidth>
        {title && (
          <Text size="text-sm" weight="medium">
            {title}
          </Text>
        )}
        <Text size="text-sm">{children}</Text>
      </InlineFlex>
      {withCloseButton && (
        <Button
          aria-label="Close alert"
          asClickable
          data-gourmet-alert-close-button=""
          asIconButton
          leftIcon={<XCloseIcon />}
          noPadding
          onClick={onClickClose}
          sizing="small"
        />
      )}
    </StyledAlert>
  )
})

Alert.displayName = 'Alert'
Alert.defaultProps = { variant: 'default' }

export type { Props as AlertProps }
export { Alert, styles as AlertStyles }
