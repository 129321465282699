import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BookmarkXIconProps = IconProps

const BookmarkXIcon = forwardRef<SVGSVGElement, BookmarkXIconProps>(
  (props: BookmarkXIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9.5 7.5 5 5m0-5-5 5M19 21V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C16.72 3 15.88 3 14.2 3H9.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C5 5.28 5 6.12 5 7.8V21l7-4 7 4Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

BookmarkXIcon.displayName = 'BookmarkXIcon'
BookmarkXIcon.defaultProps = iconDefaultProps

export type { BookmarkXIconProps }
export { BookmarkXIcon }
