import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignBottom01IconProps = IconProps

const AlignBottom01Icon = forwardRef<SVGSVGElement, AlignBottom01IconProps>(
  (props: AlignBottom01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 21h18M12 3v14m0 0 7-7m-7 7-7-7"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignBottom01Icon.displayName = 'AlignBottom01Icon'
AlignBottom01Icon.defaultProps = iconDefaultProps

export type { AlignBottom01IconProps }
export { AlignBottom01Icon }
