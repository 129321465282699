import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LifeBuoy02IconProps = IconProps

const LifeBuoy02Icon = forwardRef<SVGSVGElement, LifeBuoy02IconProps>(
  (props: LifeBuoy02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.464 8.464 4.93 4.93m0 14.142 3.535-3.536m7.072 0 3.535 3.536m0-14.142-3.536 3.535M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-5 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LifeBuoy02Icon.displayName = 'LifeBuoy02Icon'
LifeBuoy02Icon.defaultProps = iconDefaultProps

export type { LifeBuoy02IconProps }
export { LifeBuoy02Icon }
