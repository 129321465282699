import { useId } from '@reach/auto-id'
import React, { forwardRef, Fragment } from 'react'
import styled from 'styled-components'

import Spacer from '../Spacer'
import Text, { TextDefaultProps, TextProps } from '../Text'

import { Flex } from '..'
import { colors, sizing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type SwitchSize = keyof typeof sizing.switch

type SwitchProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  disableFocusRing?: boolean
  fullWidth?: boolean
  alignItems?: 'center' | 'flex-start'
  label?: React.ReactNode
  size?: SwitchSize
  textProps?: TextProps
  description?: React.ReactNode
}

const defaultProps: Partial<SwitchProps> = {
  className: '',
  disableFocusRing: false,
  fullWidth: false,
  label: null,
  size: 'base',
  textProps: TextDefaultProps,
}

const FauxSwitch = styled.div<{ $size?: SwitchSize | null }>`
  background-color: ${colors.switch.backgroundColor};
  border: 1px solid ${colors.switch.borderColor};
  border-radius: ${sizing.radius.pill};
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${({ $size = defaultProps.size }) =>
    sizing.switch[$size as SwitchSize]};
  width: calc(
    ${({ $size = defaultProps.size }) => sizing.switch[$size as SwitchSize]} * 2
  );
  transition: ${toTransitions(
    ['background-color', 'border-color', 'box-shadow', 'color', 'opacity'],
    'ease',
  )};

  :after {
    content: '';
    background-color: ${colors.switch.knob.backgroundColor};
    border-radius: ${sizing.radius.pill};
    display: block;
    flex-shrink: 0;
    height: calc(
      ${({ $size = defaultProps.size }) =>
        `${sizing.switch[$size as SwitchSize]} - (${
          sizing.switch[$size as SwitchSize]
        } / 4)`}
    );
    width: calc(
      ${({ $size = defaultProps.size }) =>
        `${sizing.switch[$size as SwitchSize]} - (${
          sizing.switch[$size as SwitchSize]
        } / 4)`}
    );
    transform: translate3d(
      ${({ $size = defaultProps.size }) => ($size === 'base' ? '2px' : '1px')},
      0,
      0
    );
    transition: ${toTransitions(['transform'], 'ease')};
  }
`

const Input = styled.input`
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const Label = styled.label<{
  $disableFocusRing: boolean
  $fullWidth: boolean
  $size?: SwitchSize | null
  disabled: boolean
  $alignItems?: string
}>`
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%;' : '')}
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  align-items: ${({ $alignItems }): string =>
    $alignItems ? $alignItems : 'center'};
  display: flex;
  position: relative;

  ${Input} {
    cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  }

  :hover ${FauxSwitch}, ${Input}:focus + ${FauxSwitch} {
    border-color: ${({ disabled }): string =>
      disabled ? colors.switch.borderColor : colors.switch.hover.borderColor};
  }

  ${Input}:focus + ${FauxSwitch} {
    box-shadow: ${({ $disableFocusRing }) =>
      !$disableFocusRing
        ? `0 0 0 ${sizing.focusRing} ${colors.switch.focusRing}`
        : 'none'};
  }

  ${Input}:checked + ${FauxSwitch} {
    background-color: ${colors.switch.active.backgroundColor};
    border-color: ${colors.switch.active.borderColor};

    :after {
      transform: translate3d(
        calc(
          ${({ $size = defaultProps.size }) =>
            `${sizing.switch[$size as SwitchSize]} + ${
              $size === 'base' ? '2px' : '1px'
            }`}
        ),
        0,
        0
      );
    }
  }

  ${Input}:disabled + ${FauxSwitch} {
    opacity: 0.5;
  }
`

/**
 * @deprecated Use `<SwitchInput>` instead
 */
const StyledSwitch = styled(
  forwardRef<HTMLLabelElement, SwitchProps>(function Switch(
    {
      className = '',
      disableFocusRing,
      fullWidth,
      label = null,
      alignItems,
      size,
      textProps,
      description,
      ...props
    }: SwitchProps,
    ref,
  ) {
    const labelId = `switch--${useId()}`
    const inputId = `switch--input--${useId()}`

    return (
      <Label
        $disableFocusRing={disableFocusRing || false}
        $fullWidth={fullWidth || false}
        $size={size}
        $alignItems={alignItems}
        className={className}
        disabled={props.disabled || false}
        htmlFor={inputId}
        id={labelId}
        ref={ref}
      >
        <Input {...props} id={inputId} type="checkbox" />
        <FauxSwitch $size={size} />
        {(label || description) && <Spacer size="small" />}
        <Flex column>
          {label && (
            <Fragment>
              {typeof label === 'number' || typeof label === 'string' ? (
                <Text {...textProps} aria-labelledby={labelId}>
                  {label}
                </Text>
              ) : (
                label
              )}
            </Fragment>
          )}
          {description != null && (
            <Text
              data-gourmet-switch-input-description=""
              enableVariantOverride
              notSemantic
              size="body"
              variant="secondary"
            >
              {description}
            </Text>
          )}
        </Flex>
      </Label>
    )
  }),
)``

StyledSwitch.displayName = 'Switch'
StyledSwitch.defaultProps = defaultProps

export { defaultProps as SwitchDefaultProps }
export type { SwitchProps }
export default StyledSwitch
