import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyDollarIconProps = IconProps

const CurrencyDollarIcon = forwardRef<SVGSVGElement, CurrencyDollarIconProps>(
  (props: CurrencyDollarIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 16a4 4 0 0 0 4 4h4a4 4 0 0 0 0-8h-4a4 4 0 0 1 0-8h4a4 4 0 0 1 4 4m-6-6v20"
        fill="none"
      ></path>
    </Icon>
  ),
)

CurrencyDollarIcon.displayName = 'CurrencyDollarIcon'
CurrencyDollarIcon.defaultProps = iconDefaultProps

export type { CurrencyDollarIconProps }
export { CurrencyDollarIcon }
