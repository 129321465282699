import React from 'react'

import { TabsContext, TabsContextValue } from './TabsContext'

/**
 * Returns the current `value` for the parent `<TabsContext>`, if any.
 */
export function useTabsContext(): TabsContextValue {
  const context = React.useContext(TabsContext)

  if (context === undefined) {
    throw new Error(
      'The `useTabsContext` hook must be used within `<TabsProvider>`',
    )
  }

  return context
}
