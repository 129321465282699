import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Repeat04IconProps = IconProps

const Repeat04Icon = forwardRef<SVGSVGElement, Repeat04IconProps>(
  (props: Repeat04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20.5a8.5 8.5 0 0 0 5-15.375M13 22.4l-2-2 2-2M12 3.5a8.5 8.5 0 0 0-5 15.375M11 5.6l2-2-2-2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Repeat04Icon.displayName = 'Repeat04Icon'
Repeat04Icon.defaultProps = iconDefaultProps

export type { Repeat04IconProps }
export { Repeat04Icon }
