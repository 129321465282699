import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileShield03IconProps = IconProps

const FileShield03Icon = forwardRef<SVGSVGElement, FileShield03IconProps>(
  (props: FileShield03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 19.72 20 18.88 20 17.2V8m-6-6 6 6m-6-6v6h6m-8 10s3-1.43 3-3.575v-2.502l-2.188-.782a2.41 2.41 0 0 0-1.626 0L9 11.923v2.502C9 16.57 12 18 12 18Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileShield03Icon.displayName = 'FileShield03Icon'
FileShield03Icon.defaultProps = iconDefaultProps

export type { FileShield03IconProps }
export { FileShield03Icon }
