import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileCheck02IconProps = IconProps

const FileCheck02Icon = forwardRef<SVGSVGElement, FileCheck02IconProps>(
  (props: FileCheck02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 12.5V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22H12m2-11H8m2 4H8m8-8H8m6.5 12 2 2 4.5-4.5"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileCheck02Icon.displayName = 'FileCheck02Icon'
FileCheck02Icon.defaultProps = iconDefaultProps

export type { FileCheck02IconProps }
export { FileCheck02Icon }
