import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Heading02IconProps = IconProps

const Heading02Icon = forwardRef<SVGSVGElement, Heading02IconProps>(
  (props: Heading02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 4v16M18 4v16M9.5 4v16m2-16H4m14 8H9.5m2 8H4m16 0h-4m4-16h-4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Heading02Icon.displayName = 'Heading02Icon'
Heading02Icon.defaultProps = iconDefaultProps

export type { Heading02IconProps }
export { Heading02Icon }
