import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Recording01IconProps = IconProps

const Recording01Icon = forwardRef<SVGSVGElement, Recording01IconProps>(
  (props: Recording01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 10v4m4.5-8v12M12 3v18m4.5-15v12m4.5-8v4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Recording01Icon.displayName = 'Recording01Icon'
Recording01Icon.defaultProps = iconDefaultProps

export type { Recording01IconProps }
export { Recording01Icon }
