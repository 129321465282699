import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SlashCircle01IconProps = IconProps

const SlashCircle01Icon = forwardRef<SVGSVGElement, SlashCircle01IconProps>(
  (props: SlashCircle01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m4.93 4.93 14.14 14.14M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

SlashCircle01Icon.displayName = 'SlashCircle01Icon'
SlashCircle01Icon.defaultProps = iconDefaultProps

export type { SlashCircle01IconProps }
export { SlashCircle01Icon }
