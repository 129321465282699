import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Toggle01LeftIconProps = IconProps

const Toggle01LeftIcon = forwardRef<SVGSVGElement, Toggle01LeftIconProps>(
  (props: Toggle01LeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 17h10a5 5 0 0 0 0-10H7m0 10A5 5 0 0 1 7 7m0 10A5 5 0 0 0 7 7"
        fill="none"
      ></path>
    </Icon>
  ),
)

Toggle01LeftIcon.displayName = 'Toggle01LeftIcon'
Toggle01LeftIcon.defaultProps = iconDefaultProps

export type { Toggle01LeftIconProps }
export { Toggle01LeftIcon }
