import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockCheckIconProps = IconProps

const ClockCheckIcon = forwardRef<SVGSVGElement, ClockCheckIconProps>(
  (props: ClockCheckIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m14.5 19 2 2 4.5-4.5m.985-3.95c.01-.182.015-.366.015-.55 0-5.523-4.477-10-10-10S2 6.477 2 12c0 5.435 4.337 9.858 9.739 9.997M12 6v6l3.738 1.87"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClockCheckIcon.displayName = 'ClockCheckIcon'
ClockCheckIcon.defaultProps = iconDefaultProps

export type { ClockCheckIconProps }
export { ClockCheckIcon }
