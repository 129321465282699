import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Wind03IconProps = IconProps

const Wind03Icon = forwardRef<SVGSVGElement, Wind03IconProps>(
  (props: Wind03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.764 6.5a3 3 0 1 1 2.236 5h-6M6.764 4A3 3 0 1 1 9 9H2m8.764 11A3 3 0 1 0 13 15H2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Wind03Icon.displayName = 'Wind03Icon'
Wind03Icon.defaultProps = iconDefaultProps

export type { Wind03IconProps }
export { Wind03Icon }
