import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MoveIconProps = IconProps

const MoveIcon = forwardRef<SVGSVGElement, MoveIconProps>(
  (props: MoveIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m5 9-3 3m0 0 3 3m-3-3h20M9 5l3-3m0 0 3 3m-3-3v20m3-3-3 3m0 0-3-3M19 9l3 3m0 0-3 3"
        fill="none"
      ></path>
    </Icon>
  ),
)

MoveIcon.displayName = 'MoveIcon'
MoveIcon.defaultProps = iconDefaultProps

export type { MoveIconProps }
export { MoveIcon }
