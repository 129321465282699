import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyEuroIconProps = IconProps

const CurrencyEuroIcon = forwardRef<SVGSVGElement, CurrencyEuroIconProps>(
  (props: CurrencyEuroIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 5.519a8.5 8.5 0 1 0 0 12.962M3 14h10M3 10h10"
        fill="none"
      ></path>
    </Icon>
  ),
)

CurrencyEuroIcon.displayName = 'CurrencyEuroIcon'
CurrencyEuroIcon.defaultProps = iconDefaultProps

export type { CurrencyEuroIconProps }
export { CurrencyEuroIcon }
