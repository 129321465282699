import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Code01IconProps = IconProps

const Code01Icon = forwardRef<SVGSVGElement, Code01IconProps>(
  (props: Code01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 18 6-6-6-6M8 6l-6 6 6 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Code01Icon.displayName = 'Code01Icon'
Code01Icon.defaultProps = iconDefaultProps

export type { Code01IconProps }
export { Code01Icon }
