import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type StickerSquareIconProps = IconProps

const StickerSquareIcon = forwardRef<SVGSVGElement, StickerSquareIconProps>(
  (props: StickerSquareIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 3.5v2.7c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C15.28 11 16.12 11 17.8 11h2.7m.5 1.988V16.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 18.72 3 17.88 3 16.2V7.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.28 3 6.12 3 7.8 3h3.212c.733 0 1.1 0 1.446.083.306.073.598.195.867.36.303.185.562.444 1.08.963l5.19 5.188c.518.519.777.778.963 1.081.164.269.286.561.36.867.082.346.082.713.082 1.446Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

StickerSquareIcon.displayName = 'StickerSquareIcon'
StickerSquareIcon.defaultProps = iconDefaultProps

export type { StickerSquareIconProps }
export { StickerSquareIcon }
