import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DamagedIconProps = IconProps

const DamagedIcon = forwardRef<SVGSVGElement, DamagedIconProps>(
  (props: DamagedIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <g fill="currentColor" clipPath="url(#a)" stroke="none">
        <path
          d="M23.96 16.911a.751.751 0 0 0-.431-.455l-4.814-1.932a.749.749 0 0 0-.999.487l-1.181 4.046-2.313.28a.75.75 0 0 0-.645.593.755.755 0 0 0 .355.803l5.382 3.163a.747.747 0 0 0 1.026-.268l3.558-6.092a.754.754 0 0 0 .061-.625ZM18.752 0H12v6.02H7.5V0H.75A.747.747 0 0 0 0 .753v18.062a.754.754 0 0 0 .75.752h9.79a.744.744 0 0 0 .454-.154l2.96-2.256a.75.75 0 0 0 .274-.781l-1.3-5.218 5.935-.895a.75.75 0 0 0 .64-.745V.753a.755.755 0 0 0-.464-.696.747.747 0 0 0-.287-.057Z"
          fill="none"
          stroke="none"
        ></path>
      </g>
      <defs fill="none" stroke="none">
        <clipPath id="a" fill="none" stroke="none">
          <path fill="currentColor" d="M0 0h24v24H0z" stroke="none"></path>
        </clipPath>
      </defs>
    </Icon>
  ),
)

DamagedIcon.displayName = 'DamagedIcon'
DamagedIcon.defaultProps = iconDefaultProps

export type { DamagedIconProps }
export { DamagedIcon }
