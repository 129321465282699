import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BracketsEllipsesIconProps = IconProps

const BracketsEllipsesIcon = forwardRef<
  SVGSVGElement,
  BracketsEllipsesIconProps
>((props: BracketsEllipsesIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18.57 20a2.286 2.286 0 0 0 2.287-2.286v-4.571L22 12l-1.143-1.143V6.286A2.285 2.285 0 0 0 18.57 4M5.429 4a2.285 2.285 0 0 0-2.286 2.286v4.571L2 12l1.143 1.143v4.571A2.285 2.285 0 0 0 5.429 20M7.5 12h.01M12 12h.01m4.49 0h.01M8 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      fill="none"
    ></path>
  </Icon>
))

BracketsEllipsesIcon.displayName = 'BracketsEllipsesIcon'
BracketsEllipsesIcon.defaultProps = iconDefaultProps

export type { BracketsEllipsesIconProps }
export { BracketsEllipsesIcon }
