import { initializeFullStory } from './FullStory/initializeFullStory'
import { initializeSegment } from './Segment'
import { initializeSentry } from './Sentry/initializeSentry'
import { initializeOpenTelemetry } from './OpenTelemetry/initializeOpenTelemetry'

export function initializeIntegrations() {
  initializeOpenTelemetry()
  initializeSentry()
  initializeFullStory()
  initializeSegment()
}
