import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type InfinityIconProps = IconProps

const InfinityIcon = forwardRef<SVGSVGElement, InfinityIconProps>(
  (props: InfinityIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.178 8c5.096 0 5.096 8 0 8-5.095 0-7.133-8-12.74-8-4.584 0-4.584 8 0 8 5.607 0 7.645-8 12.74-8h0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

InfinityIcon.displayName = 'InfinityIcon'
InfinityIcon.defaultProps = iconDefaultProps

export type { InfinityIconProps }
export { InfinityIcon }
