import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Lightning02IconProps = IconProps

const Lightning02Icon = forwardRef<SVGSVGElement, Lightning02IconProps>(
  (props: Lightning02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.25 2H8.493c-.18 0-.27 0-.348.027a.5.5 0 0 0-.187.116c-.06.058-.1.139-.18.3l-4.2 8.4c-.192.383-.288.574-.265.73a.5.5 0 0 0 .208.337c.129.09.343.09.772.09h6.205l-3 10L19.694 9.355c.411-.426.617-.64.629-.822a.5.5 0 0 0-.177-.415C20.005 8 19.71 8 19.117 8h-7.118l2.25-6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Lightning02Icon.displayName = 'Lightning02Icon'
Lightning02Icon.defaultProps = iconDefaultProps

export type { Lightning02IconProps }
export { Lightning02Icon }
