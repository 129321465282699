import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Grid02IconProps = IconProps

const Grid02Icon = forwardRef<SVGSVGElement, Grid02IconProps>(
  (props: Grid02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 3H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 4.52 3 5.08 3 6.2V8m5 13H6.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3 19.48 3 18.92 3 17.8V16m18-8V6.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C19.48 3 18.92 3 17.8 3H16m5 13v1.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C19.48 21 18.92 21 17.8 21H16m-4-4V7m-5 5h10"
        fill="none"
      ></path>
    </Icon>
  ),
)

Grid02Icon.displayName = 'Grid02Icon'
Grid02Icon.defaultProps = iconDefaultProps

export type { Grid02IconProps }
export { Grid02Icon }
