import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CodeSquare02IconProps = IconProps

const CodeSquare02Icon = forwardRef<SVGSVGElement, CodeSquare02IconProps>(
  (props: CodeSquare02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m14 17 3-3-3-3m-4-4-3 3 3 3m-2.2 8h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 3 17.88 3 16.2 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CodeSquare02Icon.displayName = 'CodeSquare02Icon'
CodeSquare02Icon.defaultProps = iconDefaultProps

export type { CodeSquare02IconProps }
export { CodeSquare02Icon }
