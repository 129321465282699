import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileAttachment03IconProps = IconProps

const FileAttachment03Icon = forwardRef<
  SVGSVGElement,
  FileAttachment03IconProps
>((props: FileAttachment03IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 7v-.2c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h3.7m5.5-4v-5.5a1.5 1.5 0 0 1 3 0V18a3 3 0 1 1-6 0v-4"
      fill="none"
    ></path>
  </Icon>
))

FileAttachment03Icon.displayName = 'FileAttachment03Icon'
FileAttachment03Icon.defaultProps = iconDefaultProps

export type { FileAttachment03IconProps }
export { FileAttachment03Icon }
