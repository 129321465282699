import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FilterLinesIconProps = IconProps

const FilterLinesIcon = forwardRef<SVGSVGElement, FilterLinesIconProps>(
  (props: FilterLinesIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 12h12M3 6h18M9 18h6"
        fill="none"
      ></path>
    </Icon>
  ),
)

FilterLinesIcon.displayName = 'FilterLinesIcon'
FilterLinesIcon.defaultProps = iconDefaultProps

export type { FilterLinesIconProps }
export { FilterLinesIcon }
