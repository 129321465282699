import React from 'react'

import { CalendarInput, CalendarInputProps } from '../CalendarInput'
import { formatDate } from '../utils'

type Props = CalendarInputProps

/**
 * Uses the `<CalendarInput />` component internally to provide a calendar
 * input component used to selected date ranges.
 *
 * @example
 * <DateRangeInput
 *   renderTriggerLabel={({ selectedDates }) =>
 *     selectedDates.length
 *       ? selectedDates.map(formatDate).join(' - ')
 *       : 'Select 2 Dates'
 *   }
 * />
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/}
 */
const DateRangeInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(function DateRangeInput(
  { calendarOptions, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  return (
    <CalendarInput
      dialogAriaLabel="Select Dates"
      calendarOptions={{ ...calendarOptions, rangeSelection: true }}
      renderTriggerLabel={
        props.renderTriggerLabel ||
        (({ selectedDates }) =>
          selectedDates.length > 0
            ? selectedDates.map(formatDate).join(' - ')
            : 'Select Dates')
      }
      {...props}
      ref={ref}
    />
  )
})

DateRangeInput.displayName = 'DateRangeInput'
DateRangeInput.defaultProps = {}

export { DateRangeInput }
export type { Props as DateRangeInputProps }
