import React, { forwardRef } from 'react'
import styled, { keyframes } from 'styled-components'

import { colors, Size, sizing } from '../tokens'

type LoaderColorVariant = keyof typeof colors.loader.borderColor

type LoaderProps = React.HTMLAttributes<HTMLSpanElement> & {
  size?: Size
  variant?: LoaderColorVariant
}

const defaultProps: Partial<LoaderProps> = {
  className: '',
  size: 'base',
  variant: 'primary',
}

const animation = keyframes`
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const StyledLoader = styled(
  forwardRef<HTMLSpanElement, LoaderProps>(function Loader(
    { size, variant, ...props }: LoaderProps,
    ref,
  ) {
    return (
      <span
        {...props}
        aria-busy="true"
        aria-live="polite"
        ref={ref}
        role="progressbar"
      />
    )
  }),
)<LoaderProps>`
  background: transparent;
  border: 2px solid
    ${({ variant = defaultProps.variant }): string =>
      colors.loader.borderColor[variant as LoaderColorVariant]};
  border-bottom-color: transparent;
  border-radius: 100%;
  display: inline-block;
  height: ${({ size = defaultProps.size }): string =>
    sizing.loader[size as Size]};
  width: ${({ size = defaultProps.size }): string =>
    sizing.loader[size as Size]};
  animation: ${animation} 0.75s 0s linear infinite;
`

StyledLoader.displayName = 'Loader'
StyledLoader.defaultProps = defaultProps

export type { LoaderProps }
export { defaultProps as LoaderDefaultProps }
export default StyledLoader
