import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { DialogPropsFromHook } from '../Dialog'
import { Divider } from '../Divider'
import { SidePanelCloseButton } from './SidePanelCloseButton'
import { toRem } from '../utils'
import { useSidePanelContext } from './SidePanelContext'
import Flex, { FlexProps } from '../Flex'
import Spacer from '../Spacer'
import Text from '../Text'

function styles(): CSSObject {
  return {
    padding: toRem(24),
  }
}

const StyledSidePanelHeader = styled(Flex)(styles)

const SidePanelHeaderTitle = styled(Text)({
  fontSize: toRem(20),
  fontWeight: 500,
  lineHeight: toRem(30),
})

const SidePanelHeaderDescription = styled(Text)({
  fontSize: toRem(14),
  fontWeight: 400,
  lineHeight: toRem(20),
})

type ChildrenFn = (
  sidePanelHeaderProps: DialogPropsFromHook & {
    descriptionID: string
    titleID: string
  },
) => React.ReactNode

type Props = Omit<FlexProps, 'children'> &
  (
    | {
        /**
         * Use the function-as-children approach when you want to render a custom
         * header for the `<SidePanel>`. The callback function will provide the
         * IDs for the title and description of the side panel to attach to HTML
         * elements.
         *
         * @example
         * function CustomHeader(): React.ReactElement {
         *   const { ref, ...sidePanel } = useSidePanel()
         *
         *   return (
         *     <SidePanel ref={ref} {...sidePanel}>
         *       <SidePanelHeader>
         *         {({ descriptionID, titleID }) => (
         *           <Flex
         *             alignItems="flex-start"
         *             fullWidth
         *             justifyContent="space-between"
         *           >
         *             <Flex column fullWidth>
         *               <Text id={titleID}>Some title</Text>
         *               <Text id={descriptionID}>Some description</Text>
         *             </Flex>
         *             <Spacer inline />
         *             <SidePanelCloseButton />
         *           </Flex>
         *         )}
         *       </SidePanelHeader>
         *     </SidePanel>
         *   )
         * }
         */
        children: ChildrenFn
        /**
         * Provides a description for the side panel.
         */
        description?: string
        /**
         * The ID, if any, of the HTML element used to described the side panel.
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
         */
        descriptionID?: string
        /**
         * Provides a title for the side panel.
         */
        title?: string
        /**
         * The ID, if any, of the HTML element used to label the side panel.
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
         */
        titleID?: string
      }
    | ({
        /**
         * Use the function-as-children approach when you want to render a custom
         * header for the `<SidePanel>`. The callback function will provide the
         * IDs for the title and description of the side panel to attach to HTML
         * elements.
         *
         * @example
         * function CustomHeader(): React.ReactElement {
         *   const { ref, ...sidePanel } = useSidePanel()
         *
         *   return (
         *     <SidePanel ref={ref} {...sidePanel}>
         *       <SidePanelHeader>
         *         {({ descriptionID, titleID }) => (
         *           <Flex
         *             alignItems="flex-start"
         *             fullWidth
         *             justifyContent="space-between"
         *           >
         *             <Flex column fullWidth>
         *               <Text id={titleID}>Some title</Text>
         *               <Text id={descriptionID}>Some description</Text>
         *             </Flex>
         *             <Spacer inline />
         *             <SidePanelCloseButton />
         *           </Flex>
         *         )}
         *       </SidePanelHeader>
         *     </SidePanel>
         *   )
         * }
         */
        children?: ChildrenFn
      } & ((
        | {
            /**
             * Provides a title for the side panel.
             */
            title: string
            /**
             * The ID, if any, of the HTML element used to label the side panel.
             *
             * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
             */
            titleID?: string
          }
        | {
            /**
             * Provides a title for the side panel.
             */
            title?: string
            /**
             * The ID of the HTML element used to label the side panel.
             *
             * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
             */
            titleID: string
          }
      ) & {
        /**
         * Provides a description for the side panel.
         */
        description?: string
        /**
         * The ID, if any, of the HTML element used to described the side panel.
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
         */
        descriptionID?: string
      }))
  )

/**
 * Provides basic styling for rendering the header of a `<SidePanel>` component.
 * It also provides functionality to provide an accessible name and description
 * for a dialog, through the `aria-label` or `aria-labelledby` attributes.
 *
 * @see {@link https://w3c.github.io/aria/#aria-label}
 * @see {@link https://w3c.github.io/aria/#aria-labelledby}
 *
 * @example
 * function Basic(): React.ReactElement {
 *   const { ref, ...sidePanel } = useSidePanel()
 *
 *   return (
 *     <SidePanel ref={ref} {...sidePanel}>
 *       <SidePanelHeader title="Some title" description="Some description" />
 *     </SidePanel>
 *   )
 * }
 *
 * function CustomHeader(): React.ReactElement {
 *   const { ref, ...sidePanel } = useSidePanel()
 *
 *   return (
 *     <SidePanel ref={ref} {...sidePanel}>
 *       <SidePanelHeader>
 *         {({ descriptionID, titleID }) => (
 *           <Flex
 *             alignItems="flex-start"
 *             fullWidth
 *             justifyContent="space-between"
 *           >
 *             <Flex column fullWidth>
 *               <Text id={titleID}>Some title</Text>
 *               <Text id={descriptionID}>Some description</Text>
 *             </Flex>
 *             <Spacer inline />
 *             <SidePanelCloseButton />
 *           </Flex>
 *         )}
 *       </SidePanelHeader>
 *     </SidePanel>
 *   )
 * }
 */
const SidePanelHeader = React.forwardRef<HTMLDivElement, Props>(
  function SidePanelHeader(
    { children, description, descriptionID, title, titleID, ...props }: Props,
    ref,
  ): JSX.Element {
    const sidePanelContext = useSidePanelContext()

    return (
      <>
        {children ? (
          <StyledSidePanelHeader
            {...props}
            data-gourmet-side-panel-header=""
            fullWidth
            ref={ref}
          >
            {children(sidePanelContext)}
          </StyledSidePanelHeader>
        ) : (
          <StyledSidePanelHeader
            {...props}
            alignItems="flex-start"
            data-gourmet-side-panel-header=""
            fullWidth
            justifyContent="space-between"
            ref={ref}
          >
            <Flex column fullWidth>
              <SidePanelHeaderTitle
                id={titleID || sidePanelContext.titleID}
                size="h2"
              >
                {title}
              </SidePanelHeaderTitle>
              <SidePanelHeaderDescription
                id={descriptionID || sidePanelContext.descriptionID}
                size="body"
                variant="secondary"
              >
                {description}
              </SidePanelHeaderDescription>
            </Flex>
            <Spacer inline />
            <SidePanelCloseButton />
          </StyledSidePanelHeader>
        )}
        <Divider />
      </>
    )
  },
)

SidePanelHeader.displayName = 'SidePanelHeader'
SidePanelHeader.defaultProps = {}

export { SidePanelHeader, SidePanelHeaderDescription, SidePanelHeaderTitle }
export type { Props as SidePanelHeaderProps }
