import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Type02IconProps = IconProps

const Type02Icon = forwardRef<SVGSVGElement, Type02IconProps>(
  (props: Type02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 7c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C5.602 4 6.068 4 7 4h10c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C20 5.602 20 6.068 20 7M8 20h8M10.25 4v16m3.5-16v16"
        fill="none"
      ></path>
    </Icon>
  ),
)

Type02Icon.displayName = 'Type02Icon'
Type02Icon.defaultProps = iconDefaultProps

export type { Type02IconProps }
export { Type02Icon }
