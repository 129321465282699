import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Menu04IconProps = IconProps

const Menu04Icon = forwardRef<SVGSVGElement, Menu04IconProps>(
  (props: Menu04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12h18M3 6h18M9 18h12"
        fill="none"
      ></path>
    </Icon>
  ),
)

Menu04Icon.displayName = 'Menu04Icon'
Menu04Icon.defaultProps = iconDefaultProps

export type { Menu04IconProps }
export { Menu04Icon }
