import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronSelectorHorizontalIconProps = IconProps

const ChevronSelectorHorizontalIcon = forwardRef<
  SVGSVGElement,
  ChevronSelectorHorizontalIconProps
>((props: ChevronSelectorHorizontalIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m9 7-5 5 5 5m6-10 5 5-5 5"
      fill="none"
    ></path>
  </Icon>
))

ChevronSelectorHorizontalIcon.displayName = 'ChevronSelectorHorizontalIcon'
ChevronSelectorHorizontalIcon.defaultProps = iconDefaultProps

export type { ChevronSelectorHorizontalIconProps }
export { ChevronSelectorHorizontalIcon }
