import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FlashIconProps = IconProps

const FlashIcon = forwardRef<SVGSVGElement, FlashIconProps>(
  (props: FlashIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 2 4.093 12.688c-.348.418-.523.628-.525.804a.5.5 0 0 0 .185.397c.138.111.41.111.955.111H12l-1 8 8.907-10.688c.348-.418.523-.628.525-.804a.5.5 0 0 0-.185-.397c-.138-.111-.41-.111-.955-.111H12l1-8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FlashIcon.displayName = 'FlashIcon'
FlashIcon.defaultProps = iconDefaultProps

export type { FlashIconProps }
export { FlashIcon }
