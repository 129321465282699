import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CheckSquareBrokenIconProps = IconProps

const CheckSquareBrokenIcon = forwardRef<
  SVGSVGElement,
  CheckSquareBrokenIconProps
>((props: CheckSquareBrokenIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m9 11 3 3L22 4m-6-1H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V12"
      fill="none"
    ></path>
  </Icon>
))

CheckSquareBrokenIcon.displayName = 'CheckSquareBrokenIcon'
CheckSquareBrokenIcon.defaultProps = iconDefaultProps

export type { CheckSquareBrokenIconProps }
export { CheckSquareBrokenIcon }
