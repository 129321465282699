import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CornerUpRightIconProps = IconProps

const CornerUpRightIcon = forwardRef<SVGSVGElement, CornerUpRightIconProps>(
  (props: CornerUpRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 20v-1.4c0-3.36 0-5.04.654-6.324a6 6 0 0 1 2.622-2.622C8.56 9 10.24 9 13.6 9H20m0 0-5 5m5-5-5-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CornerUpRightIcon.displayName = 'CornerUpRightIcon'
CornerUpRightIcon.defaultProps = iconDefaultProps

export type { CornerUpRightIconProps }
export { CornerUpRightIcon }
