import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Expand04IconProps = IconProps

const Expand04Icon = forwardRef<SVGSVGElement, Expand04IconProps>(
  (props: Expand04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 14v2.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.48 20 17.92 20 16.8 20H14M10 4H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C4 5.52 4 6.08 4 7.2V10m11-1 6-6m0 0h-6m6 0v6M9 15l-6 6m0 0h6m-6 0v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Expand04Icon.displayName = 'Expand04Icon'
Expand04Icon.defaultProps = iconDefaultProps

export type { Expand04IconProps }
export { Expand04Icon }
