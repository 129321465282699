import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileQuestion03IconProps = IconProps

const FileQuestion03Icon = forwardRef<SVGSVGElement, FileQuestion03IconProps>(
  (props: FileQuestion03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13m-9.88 4.001a2.248 2.248 0 0 1 4.37.75c0 1.499-2.25 2.248-2.25 2.248m.03 3h.01M14 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 19.72 20 18.88 20 17.2V8l-6-6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileQuestion03Icon.displayName = 'FileQuestion03Icon'
FileQuestion03Icon.defaultProps = iconDefaultProps

export type { FileQuestion03IconProps }
export { FileQuestion03Icon }
