import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MarkerPin02IconProps = IconProps

const MarkerPin02Icon = forwardRef<SVGSVGElement, MarkerPin02IconProps>(
  (props: MarkerPin02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 12.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c2-4 8-6.582 8-12a8 8 0 1 0-16 0c0 5.418 6 8 8 12Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MarkerPin02Icon.displayName = 'MarkerPin02Icon'
MarkerPin02Icon.defaultProps = iconDefaultProps

export type { MarkerPin02IconProps }
export { MarkerPin02Icon }
