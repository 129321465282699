import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CreditCardSearchIconProps = IconProps

const CreditCardSearchIcon = forwardRef<
  SVGSVGElement,
  CreditCardSearchIconProps
>((props: CreditCardSearchIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m22 22-1.5-1.5M22 10H2m20 2V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 5 19.92 5 18.8 5H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 6.52 2 7.08 2 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 19 4.08 19 5.2 19h5.3m11-1a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
      fill="none"
    ></path>
  </Icon>
))

CreditCardSearchIcon.displayName = 'CreditCardSearchIcon'
CreditCardSearchIcon.defaultProps = iconDefaultProps

export type { CreditCardSearchIconProps }
export { CreditCardSearchIcon }
