import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { TimelineEntry } from './TimelineEntry'

function styles(props: Props): CSSObject {
  return {
    ...(props.fullWidth && { width: '100%' }),
    listStyle: 'none',
    margin: 0,
    padding: 0,
  }
}

const OrderedList = styled.ol(styles)

type Props = React.HTMLAttributes<HTMLOListElement> & {
  fullWidth?: boolean
}

/**
 * An ordered list of events tied to a date and time.
 *
 * @example
 * <Timeline>
 *  <TimelineEntry icon={<CalendarIcon />}>
 *    <TimelineEntryTitle date="2023-02-23T00:37:44.412Z">
 *      Emel C.
 *    </TimelineEntryTitle>
 *    <TimelineEntryText>
 *      Synced <a href="#">Invoice #1234567</a> to Quickbooks
 *    </TimelineEntryText>
 *  </TimelineEntry>
 *  <TimelineEntry icon={<CalendarIcon />}>
 *    <TimelineEntryTitle date="2023-02-12T00:12:24.312Z">
 *      Enrique S.
 *    </TimelineEntryTitle>
 *    <TimelineEntryText>
 *      Sent a <Link to="/credit-request">credit request for $42.85</Link>
 *    </TimelineEntryText>
 *  </TimelineEntry>
 * </Timeline>
 */
const Timeline = React.forwardRef<
  HTMLOListElement,
  React.PropsWithChildren<Props>
>(function Timeline(
  { children, fullWidth, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  // Warns if the immediate `children` are not `<TimelineEntry>` components.
  if (
    process.env.NODE_ENV !== 'production' &&
    React.Children.toArray(children).some(
      (child) =>
        !React.isValidElement(child) ||
        (React.isValidElement(child) && child.type !== TimelineEntry),
    )
  ) {
    console.warn(
      'A <Timeline> component should only contain <TimelineEntry> components.',
    )
  }

  return (
    <OrderedList
      {...props}
      data-gourmet-timeline
      fullWidth={fullWidth}
      ref={ref}
    >
      {children}
    </OrderedList>
  )
})

Timeline.displayName = 'Timeline'
Timeline.defaultProps = {}

export { Timeline, styles as TimelineStyles }
export type { Props as TimelineProps }
