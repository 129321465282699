import { ModalPropsFromHook } from '@vori/gourmet-components'
import { GetState, SetState } from './storeTypes'
import { GlobalStoreType } from './storeTypes'

type GlobalModalState = {
  opening: boolean
  closing: boolean
  isOpen: boolean
  isClosed: boolean
  showModalHeader: boolean
  fullBleedContent: boolean
  modalTitle: null | string
  modalProps: ModalPropsFromHook | null
  displayContent: React.ComponentType | JSX.Element | null
}

type GlobalModalActions = {
  setDisplayContent: (content: React.ComponentType | JSX.Element | null) => void
  toggleDisplay: (forceOpen?: boolean, shouldBeFullBleed?: boolean) => void
  setModalProps: (modalProps: ModalPropsFromHook) => void
  setAndDisplayContent: (
    content: React.ComponentType | JSX.Element,
    shouldBeFullBleed?: boolean,
  ) => void
  toggleFullBleedContent: (shouldBeFullBleed: boolean) => void
}

export type GlobalModalSlice = GlobalModalState & GlobalModalActions

export const createGlobalModalSlice = (
  set: SetState<GlobalStoreType>,
  get: GetState<GlobalStoreType>,
): GlobalModalSlice => ({
  modalProps: null,
  opening: false,
  closing: false,
  isOpen: false,
  isClosed: true,
  fullBleedContent: false,
  modalTitle: null,
  showModalHeader: false,
  displayContent: null,
  setModalProps: (modalProps) => {
    set(() => ({
      modalProps,
    }))
  },
  toggleFullBleedContent: (shouldBeFullBleed = false) => {
    set(() => ({
      fullBleedContent: shouldBeFullBleed,
    }))
  },
  toggleDisplay: (forceOpen, shouldBeFullBleed = false) => {
    const openState = forceOpen === undefined ? !get().isOpen : forceOpen
    set((state) => ({
      isOpen: forceOpen === undefined ? !state.isOpen : forceOpen,
      isClosed: forceOpen === undefined ? !state.isClosed : !forceOpen,
      fullBleedContent: shouldBeFullBleed,
    }))

    const modalControls = get().modalProps
    if (modalControls) {
      if (openState) {
        modalControls.show()
      } else {
        modalControls.close()
      }
    }
  },
  setDisplayContent: (content) => {
    if (!content) {
      throw new Error('A component or some element to display is required')
    }
    set(() => ({ displayContent: content }))
  },
  setAndDisplayContent: (content, shouldBeFullBleed = false) => {
    get().setDisplayContent(content)
    get().toggleDisplay(true, shouldBeFullBleed)
  },
})
