import React from 'react'
import styled from 'styled-components'
import { Flex, FlexProps, sizing } from '@vori/gourmet-components'

import { DataGridDivider } from './DataGridDivider'
import { DataGridHeaderProps } from './DataGridHeader'

const Container = styled(Flex)({
  position: 'relative',
  zIndex: 10,

  '[data-datagrid-header]:first-child': {
    borderTopLeftRadius: sizing.radius.base,
    borderTopRightRadius: sizing.radius.base,
  },
})

interface Props {
  flexProps?: FlexProps
}

function DataGridHeaders({
  children,
  flexProps,
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <Container {...flexProps} column fullWidth>
      {React.Children.map(children, (child, index) => (
        <React.Fragment key={`datagrid-header-${index}`}>
          {child}
          {React.isValidElement<DataGridHeaderProps>(child) &&
            !child.props.noDivider && <DataGridDivider />}
        </React.Fragment>
      ))}
    </Container>
  )
}

export { DataGridHeaders }
