import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockSnoozeIconProps = IconProps

const ClockSnoozeIcon = forwardRef<SVGSVGElement, ClockSnoozeIconProps>(
  (props: ClockSnoozeIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.5 17h5l-5 5h5m.45-9c.033-.329.05-.662.05-1 0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10a10.11 10.11 0 0 0 1-.05M12 6v6l3.738 1.87"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClockSnoozeIcon.displayName = 'ClockSnoozeIcon'
ClockSnoozeIcon.defaultProps = iconDefaultProps

export type { ClockSnoozeIconProps }
export { ClockSnoozeIcon }
