import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CornerRightUpIconProps = IconProps

const CornerRightUpIcon = forwardRef<SVGSVGElement, CornerRightUpIconProps>(
  (props: CornerRightUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 20h3.4c3.36 0 5.04 0 6.324-.654a6 6 0 0 0 2.622-2.622C16 15.44 16 13.76 16 10.4V4m0 0-5 5m5-5 5 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CornerRightUpIcon.displayName = 'CornerRightUpIcon'
CornerRightUpIcon.defaultProps = iconDefaultProps

export type { CornerRightUpIconProps }
export { CornerRightUpIcon }
