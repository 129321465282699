import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LayersTwo02IconProps = IconProps

const LayersTwo02Icon = forwardRef<SVGSVGElement, LayersTwo02IconProps>(
  (props: LayersTwo02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m7 12-5 2.5 9.642 4.821c.131.066.197.099.266.111.06.012.123.012.184 0 .069-.012.135-.045.266-.11L22 14.5 17 12M2 9.5l9.642-4.821c.131-.066.197-.098.266-.111a.5.5 0 0 1 .184 0c.069.013.135.045.266.111L22 9.5l-9.642 4.821a1.028 1.028 0 0 1-.266.111.501.501 0 0 1-.184 0c-.069-.012-.135-.045-.266-.11L2 9.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LayersTwo02Icon.displayName = 'LayersTwo02Icon'
LayersTwo02Icon.defaultProps = iconDefaultProps

export type { LayersTwo02IconProps }
export { LayersTwo02Icon }
