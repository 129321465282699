import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GitBranch02IconProps = IconProps

const GitBranch02Icon = forwardRef<SVGSVGElement, GitBranch02IconProps>(
  (props: GitBranch02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 3v12m0 0a3 3 0 1 0 3 3m-3-3a3 3 0 0 1 3 3m9-9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 0a9 9 0 0 1-9 9"
        fill="none"
      ></path>
    </Icon>
  ),
)

GitBranch02Icon.displayName = 'GitBranch02Icon'
GitBranch02Icon.defaultProps = iconDefaultProps

export type { GitBranch02IconProps }
export { GitBranch02Icon }
