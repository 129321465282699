import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudSun01IconProps = IconProps

const CloudSun01Icon = forwardRef<SVGSVGElement, CloudSun01IconProps>(
  (props: CloudSun01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.368 12.405A5 5 0 1 0 12 8m0 0a5.5 5.5 0 0 0-4.9 3.001L7 11a5 5 0 0 0 0 10h10.5a4.5 4.5 0 1 0-.206-8.995A5.502 5.502 0 0 0 12 8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudSun01Icon.displayName = 'CloudSun01Icon'
CloudSun01Icon.defaultProps = iconDefaultProps

export type { CloudSun01IconProps }
export { CloudSun01Icon }
