import { MenuPopover } from '@reach/menu-button'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import {
  positionDefault,
  positionMatchWidth,
  positionRight,
} from '@reach/popover'

import Card, { CardProps } from '../Card'
import Spacer from '../Spacer'
import { Size, sizing } from '../tokens'

type Position = 'default' | 'right' | 'matchWidth'

interface Props extends CardProps {
  /**
   * Controls the positioning of the popup.
   */
  zIndex?: number
  position?: Position
}

const Popover = styled(MenuPopover)(
  (props: {
    $size?: NonNullable<Props['size']>
    $zIndex?: number
  }): CSSObject => ({
    maxWidth: props.$size != null ? sizing.card[props.$size as Size] : 'auto',
    width: props.$size != null ? '100%' : 'auto',
    zIndex: props.$zIndex || 50,
  }),
)

const PopupContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function PopupContent(
  { children, position, ...props }: React.PropsWithChildren<Props>,
  ref,
) {
  return (
    <Popover
      $size={props.size}
      $zIndex={props.zIndex}
      data-gourmet-popup-content=""
      portal
      position={
        position === 'default'
          ? positionDefault
          : position === 'right'
            ? positionRight
            : position === 'matchWidth'
              ? positionMatchWidth
              : positionDefault
      }
      ref={ref}
    >
      <Spacer size="small" />
      <Card {...props}>{children}</Card>
      <Spacer size="small" />
    </Popover>
  )
})

PopupContent.displayName = 'PopupContent'
PopupContent.defaultProps = {}

export { PopupContent }
export type { Props as PopupContentProps }
