import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { Dialog, DialogProps, useDialogContext } from '../Dialog'
import { Flex } from '../FlexNext'
import { SidePanelContext, SidePanelContextValue } from './SidePanelContext'
import { toRem } from '../utils'
import { useControlledState } from '../hooks'

function styles(props: { $fullWidth?: boolean }): CSSObject {
  return {
    bottom: 'auto',
    height: '100%',
    left: 'auto',
    maxHeight: '100%',
    maxWidth: props.$fullWidth ? '100%' : 600,
    overflow: 'hidden',
    right: 0,
    top: 0,
    width: '100%',

    '&[open]': {
      alignItems: 'stretch',
      display: 'flex',
    },

    '&[data-as-dialog]': {
      boxShadow: `0 ${toRem(8)} ${toRem(8)} -${toRem(
        4,
      )} rgba(16, 24, 40, 0.03), 0 ${toRem(20)} ${toRem(24)} -${toRem(
        4,
      )} rgba(16, 24, 40, 0.08)`,
      position: 'fixed',
      zIndex: 1000,
    },

    '[data-gourmet-side-panel-container]': {
      backgroundColor: '#FFFFFF',
    },
  }
}

const SidePanelDialog = styled(Dialog)(styles)

type Props = Omit<DialogProps, 'aria-label' | 'aria-labelledby'> &
  Pick<
    React.DialogHTMLAttributes<HTMLDialogElement>,
    'aria-label' | 'aria-labelledby'
  > & {
    /**
     * The ID, if any, of the HTML element used to described the side panel.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
     */
    descriptionID?: string
    /**
     * If `true`, the side panel will take all available horizontal space.
     */
    fullWidth?: boolean
    /**
     * The ID, if any, of the HTML element used to label the side panel.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
     */
    titleID?: string
  }

function SidePanelCore({
  children,
  descriptionID,
  titleID,
}: React.PropsWithChildren<
  Required<Pick<Props, 'descriptionID' | 'titleID'>>
>): JSX.Element {
  const dialogContextValue = useDialogContext()

  const contextValue = React.useMemo<SidePanelContextValue>(
    () => ({ ...dialogContextValue, descriptionID, titleID }),
    [descriptionID, dialogContextValue, titleID],
  )

  return (
    <SidePanelContext.Provider value={contextValue}>
      <Flex
        data-gourmet-side-panel-container=""
        direction="column"
        flex={1}
        justifyContent="flex-start"
      >
        {children}
      </Flex>
    </SidePanelContext.Provider>
  )
}

/**
 * The side panel slides out from the right edge of the screen and can be useful
 * when you need users to complete a task or view some details without leaving
 * the current page.
 *
 * Side panels are treated as modal dialogs, so like the `<Dialog>` component,
 * you'll have to use the `useSidePanel()` hook to access the API via the `ref`
 * property returned by the hook due to the imperative nature of the HTML
 * `<dialog>` element API.
 *
 * @example
 * const { ref, triggerProps, ...sidePanel } = useSidePanel()
 *
 * <Button {...triggerProps} onClick={sidePane.open}>Open</Button>
 *
 * <SidePanel ref={ref} {...sidePanel}>
 *   <SidePanelHeader
 *     title="This is a side panel"
 *     description="Here is the description for this side panel"
 *   />
 *   <SidePanelContent>
 *     <Text>This is a side panel</Text>
 *   </SidePanelContent>
 *   <SidePanelFooter justifyContent="flex-end">
 *     <Button
 *       onClick={() => {
 *         sidePanel.close()
 *       }}
 *     >
 *       Cancel
 *     </Button>
 *     <Spacer inline size="small" />
 *     <Button variant="tertiary">Save</Button>
 *   </SidePanelFooter>
 * </SidePanel>
 */
const SidePanel = React.forwardRef<
  HTMLDialogElement,
  React.PropsWithChildren<Props>
>(function SidePanel(
  {
    children,
    descriptionID: controlledDescriptionID,
    fullWidth,
    titleID: controlledTitleID,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [defaultDescriptionID] = useClientId('gourmet-side-panel-description')
  const [defaultTitleID] = useClientId('gourmet-side-panel-title')

  const [descriptionID] = useControlledState({
    componentName: 'SidePanel',
    controlledValue: controlledDescriptionID,
    defaultValue: defaultDescriptionID,
  })

  const [titleID] = useControlledState({
    componentName: 'SidePanel',
    controlledValue: controlledTitleID,
    defaultValue: defaultTitleID,
  })

  return (
    <SidePanelDialog
      {...props}
      {...(!props.isModal && { 'data-as-dialog': '' })}
      $fullWidth={fullWidth}
      aria-describedby={descriptionID}
      aria-labelledby={titleID}
      data-gourmet-side-panel=""
      ref={ref}
    >
      <SidePanelCore descriptionID={descriptionID} titleID={titleID}>
        {children}
      </SidePanelCore>
    </SidePanelDialog>
  )
})

SidePanel.displayName = 'SidePanel'
SidePanel.defaultProps = {}

export { SidePanel }
export type { Props as SidePanelProps }
