import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ZoomOutIconProps = IconProps

const ZoomOutIcon = forwardRef<SVGSVGElement, ZoomOutIconProps>(
  (props: ZoomOutIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m21 21-4.35-4.35M8 11h6m5 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ZoomOutIcon.displayName = 'ZoomOutIcon'
ZoomOutIcon.defaultProps = iconDefaultProps

export type { ZoomOutIconProps }
export { ZoomOutIcon }
