import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Laptop01IconProps = IconProps

const Laptop01Icon = forwardRef<SVGSVGElement, Laptop01IconProps>(
  (props: Laptop01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 16V7.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C4.52 4 5.08 4 6.2 4h11.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C21 5.52 21 6.08 21 7.2V16h-5.337c-.245 0-.367 0-.482.028a.998.998 0 0 0-.29.12c-.1.061-.187.148-.36.32l-.062.063c-.173.173-.26.26-.36.322a.998.998 0 0 1-.29.12c-.115.027-.237.027-.482.027h-2.674c-.245 0-.367 0-.482-.028a.998.998 0 0 1-.29-.12c-.1-.061-.187-.148-.36-.32l-.062-.063c-.173-.173-.26-.26-.36-.322a.998.998 0 0 0-.29-.12C8.704 16 8.582 16 8.337 16H3Zm0 0a1 1 0 0 0-1 1v.333c0 .62 0 .93.068 1.185a2 2 0 0 0 1.414 1.414c.255.068.565.068 1.185.068h14.666c.62 0 .93 0 1.185-.068a2 2 0 0 0 1.414-1.414c.068-.255.068-.565.068-1.185 0-.31 0-.465-.034-.592a1 1 0 0 0-.707-.707C21.132 16 20.977 16 20.667 16H20"
        fill="none"
      ></path>
    </Icon>
  ),
)

Laptop01Icon.displayName = 'Laptop01Icon'
Laptop01Icon.defaultProps = iconDefaultProps

export type { Laptop01IconProps }
export { Laptop01Icon }
