import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Mail03IconProps = IconProps

const Mail03Icon = forwardRef<SVGSVGElement, Mail03IconProps>(
  (props: Mail03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m2 7 5.194 3.463c.3.2.45.3.611.37.144.063.295.108.45.136.174.031.354.031.714.031h6.062c.36 0 .54 0 .714-.031.155-.028.306-.073.45-.136.162-.07.311-.17.611-.37L22 7M6.8 20h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 17.72 22 16.88 22 15.2V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 4 18.88 4 17.2 4H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 6.28 2 7.12 2 8.8v6.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 20 5.12 20 6.8 20Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Mail03Icon.displayName = 'Mail03Icon'
Mail03Icon.defaultProps = iconDefaultProps

export type { Mail03IconProps }
export { Mail03Icon }
