import { base } from '@vori/gourmet-components'
import styled from 'styled-components'

// Container for the product search component
export const ProductSearchContainer = styled.div`
  --containerHeight: 50vh;
  --headerHeight: 40px;
  --footerHeight: calc(var(--headerHeight) * 0.75);
  --highlightColor: ${base.colors.darkPurple};

  overflow: hidden;

  [cmdk-root] {
    display: flex;
    flex-direction: column;
    min-height: var(--containerHeight);
    max-height: var(--containerHeight);
    height: var(--containerHeight);
    outline: none;
    border-radius: inherit;
  }
`

// Header of the product search
const ProductSearchHeaderProps = {
  display: 'grid',
  gridTemplateColumns: '25px 1fr',
  height: '40px',
  minHeight: '40px',
  paddingLeft: '5px',
  borderTopRightRadius: 'inherit',
  borderTopLeftRadius: 'inherit',
  borderBottom: '1px solid gray',
  span: {
    marginTop: '7px',
  },
  svg: {
    marginTop: '10px',
  },
  input: {
    border: 'none',
    width: '100%',
    'font-size': '16px',
    'border-radius': '0.5rem',
    'padding-left': '5px',
  },
}

export const ProductSearchHeader = styled.div(ProductSearchHeaderProps)

// Wrapper for the product search results section
export const ProductSearchResult = styled.div({
  flexGrow: 1,
  overflow: 'hidden',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(50%, 1fr))',
  'max-height': 'calc(var(--containerHeight) - var(--headerHeight))',

  '.product-search-results-left, .product-search-results-right': {
    overflowY: 'scroll',
    maxHeight:
      'calc(var(--containerHeight) - (var(--headerHeight) + var(--footerHeight)))',
  },

  '.product-search-result-item': {
    padding: '5px',
    fontSize: '0.8rem',

    h6: {
      margin: 0,
      lineHeight: '1rem',
    },

    '*': {
      pointerEvents: 'none',
    },
  },

  '.product-search-selected-item': {
    borderLeft: '3px solid var(--highlightColor)',
    backgroundColor: 'rgba(150, 150, 150, 0.15)',
  },

  '.product-search-results-right': {
    padding: '0 0.2rem',
    borderLeft: '1px solid gray',
  },

  '[cmdk-item]': {
    cursor: 'pointer',
    minHeight: '30px',

    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.05)',
    },

    '&[data-selected="true"]': {
      backgroundColor: 'rgba(150, 150, 150, 0.15)',
    },
  },
})

// Footer of the product search
const ProductSearchFooterProps = {
  minHeight: 'var(--footerHeight)',
  maxHeight: 'var(--footerHeight)',
  borderTop: '1px solid gray',
  lineHeight: '1.8rem',
  padding: '0 0.5rem',
  display: 'grid',
  gap: '1.8rem',
  'grid-template-columns': 'repeat(3, minmax(min-content, max-content))',
}

export const ProductSearchFooter = styled.div(ProductSearchFooterProps)

export const ProductSearchFooterIcons = styled.span({
  padding: '0 0.4rem 0.1rem 0.4rem',
  'margin-right': '2px',
  border: `1px solid ${base.colors.light4}`,
  backgroundColor: 'rgba(150, 150, 150, 0.15)',
  textAlign: 'center',
  'border-radius': '0.3rem',
})
