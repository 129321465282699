import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CornerDownLeftIconProps = IconProps

const CornerDownLeftIcon = forwardRef<SVGSVGElement, CornerDownLeftIconProps>(
  (props: CornerDownLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 4v1.4c0 3.36 0 5.04-.654 6.324a6 6 0 0 1-2.622 2.622C15.44 15 13.76 15 10.4 15H4m0 0 5-5m-5 5 5 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CornerDownLeftIcon.displayName = 'CornerDownLeftIcon'
CornerDownLeftIcon.defaultProps = iconDefaultProps

export type { CornerDownLeftIconProps }
export { CornerDownLeftIcon }
