import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Hash02IconProps = IconProps

const Hash02Icon = forwardRef<SVGSVGElement, Hash02IconProps>(
  (props: Hash02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9.5 3-3 18m11-18-3 18m6-13h-17m16 8h-17"
        fill="none"
      ></path>
    </Icon>
  ),
)

Hash02Icon.displayName = 'Hash02Icon'
Hash02Icon.defaultProps = iconDefaultProps

export type { Hash02IconProps }
export { Hash02Icon }
