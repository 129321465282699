import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Code02IconProps = IconProps

const Code02Icon = forwardRef<SVGSVGElement, Code02IconProps>(
  (props: Code02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m17 17 5-5-5-5M7 7l-5 5 5 5m7-14-4 18"
        fill="none"
      ></path>
    </Icon>
  ),
)

Code02Icon.displayName = 'Code02Icon'
Code02Icon.defaultProps = iconDefaultProps

export type { Code02IconProps }
export { Code02Icon }
