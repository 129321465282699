import { GlobalHTTPHeaders } from '@vori/dashboard-hooks/useGlobalHTTPHeaders'

import { CurrentUserData, CurrentUserMetadata } from './types'

export const getDefaultUserMetadata = (
  userData: CurrentUserData,
  httpHeaders?: GlobalHTTPHeaders | null,
): CurrentUserMetadata => {
  /**
   * @todo Remove this once we have a better way to determine the default store
   * or have a store selector in the UI.
   */
  if (userData.companyID === 'UwVEiy0LFS4cWAoRihCL') {
    return {
      selectedStoreID: '352',
      selectedStoreName: 'Berkeley Bowl West',
      timeZone: 'America/Los_Angeles',
    }
  }

  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const storeIDFromHTTPHeaders = httpHeaders?.['selected-store-sql-id']

  const defaultStore = userData.retailUser?.retailCompany.store
    ? userData.retailUser?.retailCompany.store
    : storeIDFromHTTPHeaders
      ? userData.retailUser?.retailCompany.banner?.stores?.nodes.find(
          (store) => {
            store.serialID === storeIDFromHTTPHeaders
          },
        ) || userData.retailUser?.retailCompany.banner?.stores?.nodes[0]
      : userData.retailUser?.retailCompany.banner?.stores?.nodes[0]

  return defaultStore
    ? {
        selectedStoreID: defaultStore.serialID || null,
        selectedStoreName: defaultStore.name || defaultStore.serialID || null,
        timeZone: defaultStore.local_iana_timezone || defaultTimezone,
      }
    : {
        selectedStoreID: null,
        selectedStoreName: null,
        timeZone: defaultTimezone,
      }
}
