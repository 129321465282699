import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Link02IconProps = IconProps

const Link02Icon = forwardRef<SVGSVGElement, Link02IconProps>(
  (props: Link02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 17H7A5 5 0 0 1 7 7h2m6 10h2a5 5 0 0 0 0-10h-2m-8 5h10"
        fill="none"
      ></path>
    </Icon>
  ),
)

Link02Icon.displayName = 'Link02Icon'
Link02Icon.defaultProps = iconDefaultProps

export type { Link02IconProps }
export { Link02Icon }
