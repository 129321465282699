import { foundation } from '../tokens'
import { toRem } from '../utils'

export const TabsTokens = {
  colors: {
    tab: {
      borderColor: 'transparent',
      color: '#70707B',

      selected: {
        borderColor: '#26272B',
        color: '#26272B',
      },
      focus: {
        backgroundColor: '#FAFAFA',
      },
    },
    tabList: {
      borderColor: '#E4E4E7',
    },
  },

  typography: {
    tab: {
      fontSize: foundation.typography.fontSizes.body,
      fontWeight: foundation.typography.fontWeights.medium,
      lineHeight: foundation.typography.lineHeights.body,
    },
  },

  spacing: {
    tab: {
      marginRight: toRem(16),
      paddingBottom: toRem(12),
      paddingLeft: toRem(12),
      paddingRight: toRem(12),
      paddingTop: toRem(12),
    },
  },

  sizing: {
    tab: {
      borderWidth: toRem(2),
    },
    tabList: {
      borderWidth: toRem(1),
    },
  },
} as const
