import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronDownDoubleIconProps = IconProps

const ChevronDownDoubleIcon = forwardRef<
  SVGSVGElement,
  ChevronDownDoubleIconProps
>((props: ChevronDownDoubleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m7 13 5 5 5-5M7 6l5 5 5-5"
      fill="none"
    ></path>
  </Icon>
))

ChevronDownDoubleIcon.displayName = 'ChevronDownDoubleIcon'
ChevronDownDoubleIcon.defaultProps = iconDefaultProps

export type { ChevronDownDoubleIconProps }
export { ChevronDownDoubleIcon }
