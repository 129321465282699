import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Database03IconProps = IconProps

const Database03Icon = forwardRef<SVGSVGElement, Database03IconProps>(
  (props: Database03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 20a2 2 0 1 1-4 0m4 0a2 2 0 0 0-2-2m2 2h7m-11 0a2 2 0 0 1 2-2m-2 2H3m9-2v-4m9-9c0 1.657-4.03 3-9 3S3 6.657 3 5m18 0c0-1.657-4.03-3-9-3S3 3.343 3 5m18 0v6c0 1.66-4 3-9 3M3 5v6c0 1.66 4 3 9 3"
        fill="none"
      ></path>
    </Icon>
  ),
)

Database03Icon.displayName = 'Database03Icon'
Database03Icon.defaultProps = iconDefaultProps

export type { Database03IconProps }
export { Database03Icon }
