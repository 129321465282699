import React, { forwardRef, useCallback, useRef } from 'react'
import styled from 'styled-components'

import {
  Combobox,
  ComboboxInput,
  ComboboxInputProps,
  ComboboxList,
  ComboboxListProps,
  ComboboxOption,
  ComboboxPopover,
  ComboboxProps,
} from '@reach/combobox'

import { Flex } from '../FlexNext'
import { Input, InputProps } from '../InputNext'
import { Spacer } from '../SpacerNext'
import { Text } from '../TextNext'
import { Card, CardProps } from '../CardNext'
import List, { ListProps } from '../List'
import Loader from '../Loader'
import MenuItem, { MenuItemProps } from '../MenuItem'

import { composeEventHandlers } from '../utils'
import { colors, Size, sizing, spacing } from '../tokens'

const StyledCombobox = styled(Combobox)<{ $fullWidth?: boolean }>(
  ({ $fullWidth }) => ({
    ...($fullWidth && { width: '100%' }),
  }),
)

const StyledComboboxPopover = styled(ComboboxPopover)<{ $size?: Size | null }>`
  ${({ $size }) =>
    $size != null
      ? `max-width: ${sizing.container[$size]}; width: 100% !important;`
      : ''}
  z-index: 1000;
`
const StyledCard = styled(Card)`
  padding: ${spacing.autocomplete.popup};
  overflow: hidden;
`

const ListContainer = styled.div<{
  $hasFooter?: boolean
  $hasHeader?: boolean
}>`
  margin-bottom: ${({ $hasFooter }) =>
    !$hasFooter
      ? `-${spacing.autocomplete.popup}`
      : spacing.autocomplete.popup};
  margin-left: -${spacing.autocomplete.popup};
  margin-right: -${spacing.autocomplete.popup};
  margin-top: ${({ $hasHeader }) =>
    !$hasHeader
      ? `-${spacing.autocomplete.popup}`
      : spacing.autocomplete.popup};
  width: calc(100% + ${spacing.autocomplete.popup} * 2);

  ${({ $hasHeader }) =>
    $hasHeader
      ? `
    ${MenuItem}:first-of-type {
      border-radius: 0;
    }
  `
      : ''}

  ${({ $hasFooter }) =>
    $hasFooter
      ? `
    ${MenuItem}:last-of-type {
      border-radius: 0;
    }
  `
      : ''}
`

type AutocompleteProps<T = void> = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onSelect'
> &
  Omit<ComboboxProps, 'children' | 'onSelect'> &
  Omit<ComboboxListProps, 'as'> & {
    getOptionMenuItemProps?: (option: T) => MenuItemProps
    getOptionValue?: (option: T) => string
    inputProps?: ComboboxInputProps &
      Omit<InputProps, 'value'> & {
        ref?: React.Ref<HTMLInputElement>
        value?: string
      }
    listProps?: ListProps<T>
    onSelect?: (value: string, option?: T) => void
    options: T[]
    popupProps?: CardProps & {
      ref?: React.Ref<HTMLDivElement>
    }
    renderFooter?: (() => React.ReactNode) | null
    renderHeader?: (() => React.ReactNode) | null
    renderOption?: ((option: T) => React.ReactNode) | null
    renderPopupInline?: boolean
    showEmpty?: boolean
    showLoader?: boolean
    useEditable?: boolean
  }

const defaultProps: Partial<AutocompleteProps> = {
  className: '',
  inputProps: {},
  listProps: {},
  options: [],
  popupProps: {},
  renderPopupInline: false,
  showEmpty: false,
  showLoader: false,
  useEditable: false,
}

type ListBoxOptionProps = React.HTMLAttributes<HTMLDivElement> & {
  menuItemProps?: MenuItemProps
  value: string
}

const StyledAutocompleteOption = styled(
  forwardRef<HTMLDivElement, ListBoxOptionProps>(function AutocompleteOption(
    { children, menuItemProps, value, ...props }: ListBoxOptionProps,
    ref: React.Ref<HTMLDivElement>,
  ) {
    return (
      <ComboboxOption
        {...(menuItemProps != null && menuItemProps)}
        {...props}
        as={MenuItem}
        ref={ref}
        value={value}
      >
        {children}
      </ComboboxOption>
    )
  }),
)`
  &[disabled] {
    background-color: ${colors.autocomplete.item.disabled.backgroundColor};
    opacity: 0.85;
    pointer-events: none;

    [data-gourmet-text] {
      color: ${colors.autocomplete.item.disabled.color};
    }
  }
`

const StyledAutocomplete = styled(
  forwardRef<HTMLDivElement, AutocompleteProps>(function Autocomplete<T>(
    {
      className,
      getOptionMenuItemProps,
      getOptionValue = (option) => String(option),
      inputProps,
      listProps,
      onSelect: originalOnSelect,
      options,
      persistSelection,
      popupProps,
      renderFooter,
      renderHeader,
      renderOption = (option) => String(option),
      renderPopupInline,
      showEmpty,
      showLoader,
      useEditable,
      ...props
    }: AutocompleteProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const listRef = useRef<HTMLDivElement | null>(null)
    const [hasFocus, setHasFocus] = React.useState(false)

    const onKeyDown = useCallback(
      (event) => {
        if (inputProps?.onKeyDown) {
          inputProps.onKeyDown(event)
        }

        if (!event.isDefaultPrevented()) {
          const container = listRef.current

          if (container != null) {
            window.requestAnimationFrame(() => {
              const element: HTMLElement | null = container.querySelector(
                '[aria-selected=true]',
              )

              if (element != null) {
                element.scrollIntoView({
                  block: 'nearest',
                })
              }
            })
          }
        }
      },
      [inputProps],
    )

    const onSelect = useCallback(
      (value: string) => {
        if (originalOnSelect != null) {
          originalOnSelect(
            value,
            options.find((option) => getOptionValue(option) === value),
          )
        }
      },
      [getOptionValue, options, originalOnSelect],
    )

    const header = React.useMemo(
      () => (renderHeader ? renderHeader() : null),
      [renderHeader],
    )

    const footer = React.useMemo(
      () => (renderFooter ? renderFooter() : null),
      [renderFooter],
    )

    const shouldShowPopup = React.useMemo(
      () =>
        hasFocus &&
        (options.length > 0 ||
          showEmpty ||
          showLoader ||
          header != null ||
          footer != null),
      [footer, hasFocus, header, options.length, showEmpty, showLoader],
    )

    return (
      <StyledCombobox
        {...props}
        $fullWidth={inputProps?.fullWidth}
        className={className}
        data-gourmet-autocomplete=""
        onSelect={onSelect}
        ref={ref}
      >
        <ComboboxInput
          {...inputProps}
          as={Input}
          {...(useEditable && { asEditable: true, noFocusRing: true })}
          onKeyDown={onKeyDown}
          onFocus={composeEventHandlers(inputProps?.onFocus, () => {
            setHasFocus(true)
          })}
          onBlur={composeEventHandlers(inputProps?.onBlur, () => {
            setHasFocus(false)
          })}
          data-gourmet-autocomplete-input=""
        />

        {shouldShowPopup && (
          <StyledComboboxPopover
            portal={!renderPopupInline}
            $size={popupProps?.size}
          >
            {!renderPopupInline && <Spacer size="space.050" />}
            <StyledCard
              {...popupProps}
              onMouseDown={composeEventHandlers(
                popupProps?.onMouseDown,
                (event) => {
                  event.preventDefault()
                },
              )}
              direction="column"
            >
              {header}
              {showEmpty && (
                <Flex direction="column" center flex={1}>
                  <Spacer />
                  <Text align="center" size="text-md" variant="secondary">
                    No results found...
                  </Text>
                  <Spacer />
                </Flex>
              )}
              {showLoader && (
                <Flex direction="column" center flex={1}>
                  <Spacer />
                  <Loader size="base" />
                  <Spacer size="space.025" />
                  <Text align="center" size="text-md" variant="secondary">
                    Loading results...
                  </Text>
                  <Spacer />
                </Flex>
              )}
              <ComboboxList as="div" persistSelection={persistSelection}>
                {options.length > 0 && (
                  <ListContainer
                    $hasFooter={footer != null}
                    $hasHeader={header != null}
                  >
                    <List {...listProps} ref={listRef}>
                      {listProps?.virtualize
                        ? null
                        : options.map((option, index) => (
                            <StyledAutocompleteOption
                              menuItemProps={
                                getOptionMenuItemProps != null
                                  ? getOptionMenuItemProps(option)
                                  : {}
                              }
                              key={index}
                              value={getOptionValue(option)}
                            >
                              {renderOption != null && renderOption(option)}
                            </StyledAutocompleteOption>
                          ))}
                    </List>
                  </ListContainer>
                )}
              </ComboboxList>
              {footer}
            </StyledCard>
          </StyledComboboxPopover>
        )}
      </StyledCombobox>
    )
  }),
)``

StyledAutocomplete.displayName = 'Autocomplete'
StyledAutocomplete.defaultProps = defaultProps

export type { AutocompleteProps }

export {
  defaultProps as AutocompleteDefaultProps,
  StyledAutocompleteOption as AutocompleteOption,
}

/**
 * Need to re-cast due to the forwardRef return value.
 * @see {@link https://stackoverflow.com/a/58473012}
 */
export default StyledAutocomplete as <T>(
  props: AutocompleteProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement
