import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { VisuallyHiddenStyles } from '../VisuallyHidden'

const StyledVisuallyHiddenInput = styled.input(
  (): CSSObject => ({
    ...VisuallyHiddenStyles(),
  }),
)

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  focusable?: boolean
}

/**
 * Provides a way to visually hide input elements, which is useful when dealing
 * with custom and/or complex form elements.
 *
 * @example
 * <>
 *   <VisuallyHiddenInput value={value} />
 *   <CustomMarkupForComponent onChange={setValue} />
 * </>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-hidden}
 * @see {@link https://www.a11yproject.com/posts/how-to-hide-content/}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/hidden#validation}
 * @see {@link https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe}
 */
const VisuallyHiddenInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(function Divider(
  { children, focusable, ...props }: React.PropsWithChildren<Props>,
  ref,
) {
  return (
    <StyledVisuallyHiddenInput
      {...props}
      aria-hidden={focusable ? 'false' : 'true'}
      data-gourmet-visually-hidden-input
      ref={ref}
      tabIndex={focusable ? props.tabIndex || 0 : -1}
    />
  )
})

VisuallyHiddenInput.displayName = 'VisuallyHiddenInput'
VisuallyHiddenInput.defaultProps = {}

export type { Props as VisuallyHiddenInputProps }
export { VisuallyHiddenInput }
