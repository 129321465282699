import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GitMergeIconProps = IconProps

const GitMergeIcon = forwardRef<SVGSVGElement, GitMergeIconProps>(
  (props: GitMergeIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 18a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm0 0a9 9 0 0 1-9-9m0 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 0v12"
        fill="none"
      ></path>
    </Icon>
  ),
)

GitMergeIcon.displayName = 'GitMergeIcon'
GitMergeIcon.defaultProps = iconDefaultProps

export type { GitMergeIconProps }
export { GitMergeIcon }
