import { XCloseIcon } from '@vori/gourmet-icons'
import React from 'react'

import { composeEventHandlers } from '../utils'
import { useModalContext } from './ModalContext'
import { Button, ButtonProps } from '../ButtonNext'

type Props = ButtonProps

/**
 * Used within a `<Modal>`, primarily inside `<ModalHeader>`, to close
 * it.
 *
 * @example
 * function ExampleModal(): React.ReactElement {
 *   const { ref, ...modal } = useModal()
 *
 *   return (
 *    <Modal ref={ref} {...modal}>
 *      <ModalContent>
 *        <ModalHeader>
 *          {({ descriptionID, titleID }) => (
 *            <Flex
 *              alignItems="flex-start"
 *              fullWidth
 *              justifyContent="space-between"
 *            >
 *              <Flex column fullWidth>
 *                <Text id={titleID}>Some title</Text>
 *                <Text id={descriptionID}>Some description</Text>
 *              </Flex>
 *              <InlineSpacer />
 *              <ModalCloseButton />
 *            </Flex>
 *          )}
 *        </ModalHeader>
 *        <Text>This is a modal</Text>
 *        <ModalFooter>
 *          <Button
 *            onClick={() => {
 *              modal.close()
 *            }}
 *          >
 *            Cancel
 *          </Button>
 *          <Button variant="tertiary">Save</Button>
 *        </ModalFooter>
 *      </ModalContent>
 *    </Modal>
 *   )
 * }
 */
const ModalCloseButton = React.forwardRef<HTMLButtonElement, Props>(
  function ModalCloseButton(props: Props, ref): JSX.Element {
    const modalContext = useModalContext()

    return (
      <Button
        {...props}
        aria-label="Close modal window"
        asClickable
        asIconButton
        data-gourmet-modal-close-button=""
        leftIcon={<XCloseIcon />}
        ref={ref}
        size="small"
        onClick={composeEventHandlers(props.onClick, () => {
          modalContext.close()
        })}
      />
    )
  },
)

ModalCloseButton.displayName = 'ModalCloseButton'
ModalCloseButton.defaultProps = {}

export { ModalCloseButton }
export type { Props as ModalCloseButtonProps }
