import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Download02IconProps = IconProps

const Download02Icon = forwardRef<SVGSVGElement, Download02IconProps>(
  (props: Download02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21H3m15-10-6 6m0 0-6-6m6 6V3"
        fill="none"
      ></path>
    </Icon>
  ),
)

Download02Icon.displayName = 'Download02Icon'
Download02Icon.defaultProps = iconDefaultProps

export type { Download02IconProps }
export { Download02Icon }
