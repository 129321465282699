import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowNarrowDownIconProps = IconProps

const ArrowNarrowDownIcon = forwardRef<SVGSVGElement, ArrowNarrowDownIconProps>(
  (props: ArrowNarrowDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4v16m0 0 6-6m-6 6-6-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowNarrowDownIcon.displayName = 'ArrowNarrowDownIcon'
ArrowNarrowDownIcon.defaultProps = iconDefaultProps

export type { ArrowNarrowDownIconProps }
export { ArrowNarrowDownIcon }
