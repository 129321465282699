import React from 'react'
import styled from 'styled-components'

import { HeadingCoreProps, HeadingProps } from './types'
import { propsToStyledProps } from '../utils'
import { sizeToHeadingTag } from './utils'
import { textStyles } from './styles'

const StyledHeading = styled.h1(textStyles)

const defaultProps: Partial<HeadingCoreProps> = {
  size: 'heading-md',
  variant: 'default',
  weight: 'medium',
}

/**
 * The `<Heading>` component extends the `<Text>` component to render a
 * heading (`<h1>` – `<h6>`) element based on the given `size`.
 *
 * @example
 * <Heading>This is a heading</Heading>
 *
 * @see {@link https://vori-gourmet-1bdf0.web.app/?path=/docs/typography-text--default}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements}
 */
const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  function Heading(
    {
      align,
      children,
      decoration,
      fullWidth,
      lineHeight,
      overflow,
      overflowWrap,
      size,
      transform,
      truncate,
      variant,
      weight,
      whiteSpace,
      wordBreak,
      ...props
    }: HeadingProps,
    ref,
  ): JSX.Element {
    return (
      <StyledHeading
        {...propsToStyledProps({
          align,
          decoration,
          fullWidth,
          lineHeight,
          overflow,
          overflowWrap,
          size,
          transform,
          truncate,
          variant,
          weight,
          whiteSpace,
          wordBreak,
        })}
        {...props}
        as={sizeToHeadingTag(size)}
        data-gourmet-text=""
        data-gourmet-heading=""
        ref={ref}
      >
        {children}
      </StyledHeading>
    )
  },
)

Heading.displayName = 'Heading'
Heading.defaultProps = defaultProps

export { Heading, defaultProps as headingDefaultProps }
