import { CSSObject } from 'styled-components'
import { foundations, TokenFoundationSpace } from '@vori/gourmet-tokens'
import * as CSS from 'csstype'

export function gap(
  value: CSS.Property.Gap<TokenFoundationSpace>,
): CSS.Property.Gap {
  return foundations.space[value as TokenFoundationSpace] || value
}

export function offset(
  value: [TokenFoundationSpace, TokenFoundationSpace],
): CSSObject {
  return {
    ...(value[1] && {
      marginLeft: `-${foundations.space[value[1]] || value[1]}`,
      marginRight: `-${foundations.space[value[1]] || value[1]}`,
      width: `calc(100% + (${foundations.space[value[1]] || value[1]} * 2))`,
    }),

    ...(value[0] && {
      marginTop: `-${foundations.space[value[0]] || value[0]}`,
      marginBottom: `-${foundations.space[value[0]] || value[0]}`,
    }),
  }
}
