import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Microphone02IconProps = IconProps

const Microphone02Icon = forwardRef<SVGSVGElement, Microphone02IconProps>(
  (props: Microphone02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 12v1a8 8 0 1 1-16 0v-1m8 5a4 4 0 0 1-4-4V7a4 4 0 1 1 8 0v6a4 4 0 0 1-4 4Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Microphone02Icon.displayName = 'Microphone02Icon'
Microphone02Icon.defaultProps = iconDefaultProps

export type { Microphone02IconProps }
export { Microphone02Icon }
