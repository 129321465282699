import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PlaceholderIconProps = IconProps

const PlaceholderIcon = forwardRef<SVGSVGElement, PlaceholderIconProps>(
  (props: PlaceholderIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

PlaceholderIcon.displayName = 'PlaceholderIcon'
PlaceholderIcon.defaultProps = iconDefaultProps

export type { PlaceholderIconProps }
export { PlaceholderIcon }
