import { Clickable, colors } from '@vori/gourmet-components'
import styled from 'styled-components'

const DataGridGroupingMenuItem = styled(Clickable)({
  borderRadius: 0,
  textAlign: 'left',
  justifyContent: 'flex-start',
  width: '100%',

  '&:hover, &:focus': {
    backgroundColor: colors.button.hover.backgroundColor.secondary,
  },
})

export { DataGridGroupingMenuItem }
