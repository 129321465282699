import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileQuestion02IconProps = IconProps

const FileQuestion02Icon = forwardRef<SVGSVGElement, FileQuestion02IconProps>(
  (props: FileQuestion02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 9.5V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22H14m0-11H8m2 4H8m8-8H8m8.5 8.002a2.249 2.249 0 0 1 4.37.75c0 1.499-2.25 2.248-2.25 2.248m.03 3h.01"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileQuestion02Icon.displayName = 'FileQuestion02Icon'
FileQuestion02Icon.defaultProps = iconDefaultProps

export type { FileQuestion02IconProps }
export { FileQuestion02Icon }
