import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Reflect02IconProps = IconProps

const Reflect02Icon = forwardRef<SVGSVGElement, Reflect02IconProps>(
  (props: Reflect02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 3v3m0 4.5v3m0 4.5v3m10-9h-6.5m0 0 4 4m-4-4 4-4M2 12h6.5m0 0-4 4m4-4-4-4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Reflect02Icon.displayName = 'Reflect02Icon'
Reflect02Icon.defaultProps = iconDefaultProps

export type { Reflect02IconProps }
export { Reflect02Icon }
