import React from 'react'
import { Intercom, shutdown } from '@intercom/messenger-js-sdk'

import { ENV_INTERCOM_APP_ID, ENV_LOCAL } from '@vori/dashboard-env'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

let intercomIsInitialized = false

export function useIntercom(): void {
  const { user } = useCurrentUserState()

  React.useEffect(() => {
    if (ENV_LOCAL) {
      return
    }

    if (!user.state.isLoggedIn) {
      if (intercomIsInitialized) {
        intercomIsInitialized = false
        shutdown()
      }

      return
    }

    Intercom({
      app_id: ENV_INTERCOM_APP_ID,
      email: user.data.email || '',
      hide_default_launcher: true,
      job_title: user.data.userType,
      name: user.data.displayName || '',
      user_id: user.data.id,
    })

    intercomIsInitialized = true
  }, [
    user.data.displayName,
    user.data.email,
    user.data.id,
    user.data.userType,
    user.state.isLoggedIn,
  ])
}
