import React from 'react'

import { CardBannerButtonCoreProps, CardBannerButtonProps } from './types'
import { Button, buttonDefaultProps } from '../ButtonNext'

const { variant, ...buttonDefaultPropsWithoutVariant } = buttonDefaultProps

const defaultProps: Partial<CardBannerButtonCoreProps> =
  buttonDefaultPropsWithoutVariant

/**
 * The `<CardBannerButton>` component extends the `<Button>` component to
 * provide specific styling when used within the `<CardBanner>` component.
 *
 * @extends Button
 * @example
 * <Card asFlat fullWidth>
 *   <CardBanner>
 *     <CardBannerText>Review changes</CardBannerText>
 *     <CardBannerButton leftIcon={<ReverseLeftIcon />}>
 *       Skip
 *     </CardBannerButton>
 *   </CardBanner>
 * </Card>
 */
const CardBannerButton = React.forwardRef<
  HTMLButtonElement,
  CardBannerButtonProps
>(function CardBannerButton(
  { children, variant = 'default', ...props }: CardBannerButtonProps,
  ref,
): JSX.Element {
  return (
    <Button
      {...props}
      asClickable
      asIconButton={false}
      data-gourmet-card-banner-button=""
      data-variant={variant}
      ref={ref}
      size="small"
    >
      {children}
    </Button>
  )
})

CardBannerButton.displayName = 'CardBannerButton'
CardBannerButton.defaultProps = defaultProps

export { CardBannerButton, defaultProps as cardBannerButtonDefaultProps }
