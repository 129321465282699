import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart01IconProps = IconProps

const BarChart01Icon = forwardRef<SVGSVGElement, BarChart01IconProps>(
  (props: BarChart01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 20V10m-6 10V4M6 20v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart01Icon.displayName = 'BarChart01Icon'
BarChart01Icon.defaultProps = iconDefaultProps

export type { BarChart01IconProps }
export { BarChart01Icon }
