import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Award01IconProps = IconProps

const Award01Icon = forwardRef<SVGSVGElement, Award01IconProps>(
  (props: Award01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.967 14.722 7 22l4.588-2.753c.15-.09.225-.135.305-.152a.5.5 0 0 1 .214 0c.08.017.155.062.305.152L17 22l-.966-7.279M19 9A7 7 0 1 1 5 9a7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Award01Icon.displayName = 'Award01Icon'
Award01Icon.defaultProps = iconDefaultProps

export type { Award01IconProps }
export { Award01Icon }
