import React from 'react'

type DisclosureContextValue = {
  id: string
  isOpen: boolean
  setState: (isOpen: boolean) => void
}

const DisclosureContext = React.createContext<
  DisclosureContextValue | undefined
>(undefined)

DisclosureContext.displayName = 'DisclosureContext'

/**
 * Returns the current `value` for the parent `<DisclosureContext>`, if any.
 */
export function useDisclosureContext(): DisclosureContextValue {
  const value = React.useContext(DisclosureContext)

  if (value === undefined) {
    throw new Error(
      'The `useDisclosureContext` hook must be used within `<DisclosureContext.Provider>`',
    )
  }

  return value
}

export { DisclosureContext }
export type { DisclosureContextValue }
