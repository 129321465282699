import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Menu01IconProps = IconProps

const Menu01Icon = forwardRef<SVGSVGElement, Menu01IconProps>(
  (props: Menu01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12h18M3 6h18M3 18h18"
        fill="none"
      ></path>
    </Icon>
  ),
)

Menu01Icon.displayName = 'Menu01Icon'
Menu01Icon.defaultProps = iconDefaultProps

export type { Menu01IconProps }
export { Menu01Icon }
