import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CursorClick02IconProps = IconProps

const CursorClick02Icon = forwardRef<SVGSVGElement, CursorClick02IconProps>(
  (props: CursorClick02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 3.5V2M5.06 5.06 4 4m1.06 9L4 14.06m9-9L14.06 4M3.5 9H2m6.5-.5 4.111 12.778 2.889-2.89L19.111 22 22 19.111 18.389 15.5l2.889-2.889L8.5 8.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CursorClick02Icon.displayName = 'CursorClick02Icon'
CursorClick02Icon.defaultProps = iconDefaultProps

export type { CursorClick02IconProps }
export { CursorClick02Icon }
