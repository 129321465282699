import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type EqualIconProps = IconProps

const EqualIcon = forwardRef<SVGSVGElement, EqualIconProps>(
  (props: EqualIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 9h14M5 15h14"
        fill="none"
      ></path>
    </Icon>
  ),
)

EqualIcon.displayName = 'EqualIcon'
EqualIcon.defaultProps = iconDefaultProps

export type { EqualIconProps }
export { EqualIcon }
