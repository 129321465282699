import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudRaining05IconProps = IconProps

const CloudRaining05Icon = forwardRef<SVGSVGElement, CloudRaining05IconProps>(
  (props: CloudRaining05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 18.5 15 21m-7-2.5L7 21m5-2.5L11 21m-4-6a5 5 0 1 1 .1-9.999 5.502 5.502 0 0 1 10.195 1.004A4.5 4.5 0 1 1 17.5 15H6.999Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudRaining05Icon.displayName = 'CloudRaining05Icon'
CloudRaining05Icon.defaultProps = iconDefaultProps

export type { CloudRaining05IconProps }
export { CloudRaining05Icon }
