import React from 'react'
import { ChevronDownIcon, ChevronUpIcon, XCloseIcon } from '@vori/gourmet-icons'

import { Button, ButtonProps } from '../../ButtonNext'
import { composeEventHandlers } from '../../utils'
import { FormFieldLayout } from '../../FormFieldLayout'

import { Props as SelectProps } from './utils/types'

interface Props<T> {
  internals: Parameters<NonNullable<SelectProps<T>['renderTrigger']>>[1]
  isOpen: Parameters<NonNullable<SelectProps<T>['renderTrigger']>>[0]
  label?: string | null
  leftButtonProps?: ButtonProps
  rightButtonProps?: ButtonProps
}

function SelectRemoveValueTrigger<T>({
  internals,
  isOpen,
  label,
  leftButtonProps,
  rightButtonProps,
}: Props<T>): JSX.Element {
  const onRightButtonClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    if (internals.state.hasSelectedOption) {
      internals.deselectOption()
    }
  }, [internals])

  const onRightButtonMouseDown = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (event) => {
      if (internals.state.hasSelectedOption) {
        event.stopPropagation()
      }
    },
    [internals.state.hasSelectedOption],
  )

  return (
    <FormFieldLayout>
      <Button
        data-gourmet-listbox-button=""
        data-gourmet-listbox-button-left=""
        fullWidth
        withLeftAlignedText
        {...leftButtonProps}
      >
        {label || internals.state.label}
      </Button>
      <Button
        {...rightButtonProps}
        data-gourmet-listbox-button=""
        data-gourmet-listbox-button-right=""
        leftIcon={
          internals.state.hasSelectedOption ? (
            <XCloseIcon />
          ) : isOpen ? (
            <ChevronUpIcon />
          ) : (
            <ChevronDownIcon />
          )
        }
        aria-label={
          internals.state.hasSelectedOption
            ? 'Remove option'
            : isOpen
              ? 'Collapse options'
              : 'Expand options'
        }
        asIconButton
        onClick={composeEventHandlers(
          rightButtonProps?.onClick,
          onRightButtonClick,
        )}
        onMouseDown={composeEventHandlers(
          rightButtonProps?.onMouseDown,
          onRightButtonMouseDown,
        )}
      />
    </FormFieldLayout>
  )
}

export type { Props as SelectRemoveValueTriggerProps }
export default SelectRemoveValueTrigger
