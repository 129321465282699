import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type User02IconProps = IconProps

const User02Icon = forwardRef<SVGSVGElement, User02IconProps>(
  (props: User02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15c-3.17 0-5.99 1.53-7.784 3.906-.386.511-.58.767-.573 1.112.005.267.172.604.382.769.272.213.649.213 1.402.213h13.146c.753 0 1.13 0 1.402-.213.21-.165.377-.502.382-.769.006-.345-.187-.6-.573-1.112C17.989 16.531 15.17 15 12 15Zm0-3a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

User02Icon.displayName = 'User02Icon'
User02Icon.defaultProps = iconDefaultProps

export type { User02IconProps }
export { User02Icon }
