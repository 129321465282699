import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart02IconProps = IconProps

const BarChart02Icon = forwardRef<SVGSVGElement, BarChart02IconProps>(
  (props: BarChart02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 20V4M6 20v-4m6 4V10"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart02Icon.displayName = 'BarChart02Icon'
BarChart02Icon.defaultProps = iconDefaultProps

export type { BarChart02IconProps }
export { BarChart02Icon }
