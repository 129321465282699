import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CreditCardShieldIconProps = IconProps

const CreditCardShieldIcon = forwardRef<
  SVGSVGElement,
  CreditCardShieldIconProps
>((props: CreditCardShieldIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 10H2m20 1V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 5 19.92 5 18.8 5H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 6.52 2 7.08 2 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 19 4.08 19 5.2 19h6.3m6.5 2s3-1.43 3-3.575v-2.502l-2.188-.782a2.41 2.41 0 0 0-1.626 0L15 14.923v2.502C15 19.57 18 21 18 21Z"
      fill="none"
    ></path>
  </Icon>
))

CreditCardShieldIcon.displayName = 'CreditCardShieldIcon'
CreditCardShieldIcon.defaultProps = iconDefaultProps

export type { CreditCardShieldIconProps }
export { CreditCardShieldIcon }
