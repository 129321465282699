import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart06IconProps = IconProps

const BarChart06Icon = forwardRef<SVGSVGElement, BarChart06IconProps>(
  (props: BarChart06IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 8v13m12-4v4M3 3v18m12-8v8"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart06Icon.displayName = 'BarChart06Icon'
BarChart06Icon.defaultProps = iconDefaultProps

export type { BarChart06IconProps }
export { BarChart06Icon }
