import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Toggle02LeftIconProps = IconProps

const Toggle02LeftIcon = forwardRef<SVGSVGElement, Toggle02LeftIconProps>(
  (props: Toggle02LeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 16h8a4 4 0 0 0 0-8h-8m2 4a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Toggle02LeftIcon.displayName = 'Toggle02LeftIcon'
Toggle02LeftIcon.defaultProps = iconDefaultProps

export type { Toggle02LeftIconProps }
export { Toggle02LeftIcon }
