import React from 'react'

import Flex, { FlexProps } from '../Flex'
import { TabPanel, TabPanelProps } from './TabPanel'

interface Props {
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: FlexProps
  /**
   * If `true`, tab panels will not render their content while inactive, i.e
   * not selected.
   */
  preventRenderingPanelsWhenInactive?: boolean
}

/**
 * Parent component for <TabPanel> components.
 */
const TabPanels = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function TabPanels(
  {
    children,
    flexProps,
    preventRenderingPanelsWhenInactive,
  }: React.PropsWithChildren<Props>,
  ref,
) {
  // Provides a warning when trying to render a component within <TabPanels> that
  // is not a <TabPanel> component.
  if (
    process.env.NODE_ENV !== 'production' &&
    React.Children.toArray(children).find(
      (child) => !React.isValidElement(child) || child.type !== TabPanel,
    ) !== undefined
  ) {
    console.warn(
      'You are trying to render non <TabPanel> components inside a <TabPanels> component. This might lead to unintended consequences.',
    )
  }

  return (
    <Flex fullWidth {...flexProps} data-gourmet-tabpanels ref={ref}>
      {React.Children.map(children, (child, index) =>
        child != null &&
        React.isValidElement<TabPanelProps>(child) &&
        child.type === TabPanel
          ? React.cloneElement<TabPanelProps>(child, {
              ...(preventRenderingPanelsWhenInactive && {
                preventRenderingWhenInactive: true,
              }),
              value: child.props.value ?? index,
            })
          : null,
      )}
    </Flex>
  )
})

TabPanels.displayName = 'TabPanels'
TabPanels.defaultProps = {}

export { TabPanels }
export type { Props as TabPanelsProps }
