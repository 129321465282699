import { sizing } from '../tokens'
import { toRem } from '../utils'

export const SegmentedProgressBarTokens = {
  colors: {
    segmentedProgressBarSegment: {
      backgroundColor: '#E4E4E7',

      completed: {
        backgroundColor: '#15141B',
      },
    },
    tabList: {
      borderColor: '#E4E4E7',
    },
  },

  typography: {
    segmentedProgressBarSegmentLabel: {
      current: {
        fontWeight: 500,
      },
    },
  },

  spacing: {
    segmentedProgressBarSegment: {
      marginRight: toRem(20),
    },

    segmentedProgressBarSegmentLabel: {
      marginTop: toRem(10),
    },
  },

  sizing: {
    segmentedProgressBarSegment: {
      borderRadius: sizing.radius.pill,
      minHeight: toRem(8),
    },
  },
} as const
