import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MoonEclipseIconProps = IconProps

const MoonEclipseIcon = forwardRef<SVGSVGElement, MoonEclipseIconProps>(
  (props: MoonEclipseIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.002 6A10.006 10.006 0 0 1 20 18.002M12 22a9.96 9.96 0 0 0 4.38-1.008 9 9 0 1 1 0-17.984A9.96 9.96 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MoonEclipseIcon.displayName = 'MoonEclipseIcon'
MoonEclipseIcon.defaultProps = iconDefaultProps

export type { MoonEclipseIconProps }
export { MoonEclipseIcon }
