import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart03IconProps = IconProps

const BarChart03Icon = forwardRef<SVGSVGElement, BarChart03IconProps>(
  (props: BarChart03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 20V4m12 16v-4m-6 4V10"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart03Icon.displayName = 'BarChart03Icon'
BarChart03Icon.defaultProps = iconDefaultProps

export type { BarChart03IconProps }
export { BarChart03Icon }
