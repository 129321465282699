import React, { forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { colors, Size, sizing, typography } from '../tokens'

import Flex, { FlexDefaultProps, FlexProps } from '../Flex'

type AvatarSize = keyof typeof sizing.avatar

type AvatarProps = React.HTMLAttributes<HTMLDivElement> &
  FlexProps & {
    alt?: string
    noBorder?: boolean
    notRounded?: boolean
    size?: keyof typeof sizing.avatar
    src?: string | null
    withInitials?: string | null
  }

const defaultProps: Partial<AvatarProps> = {
  ...FlexDefaultProps,
  className: '',
  noBorder: false,
  notRounded: false,
  size: 'base',
  withInitials: '',
}

function styles({
  noBorder,
  notRounded,
  size = defaultProps.size,
  withInitials,
}: AvatarProps): CSSObject {
  return {
    backgroundColor: colors.avatar.backgroundColor,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: notRounded ? sizing.radius.base : sizing.radius.rounded,
    boxShadow: `0 0 0 1px ${
      noBorder ? 'transparent' : colors.avatar.borderColor
    }`,
    color: colors.avatar.color,
    fontSize: typography.avatar[size as AvatarSize].fontSize,
    fontWeight: typography.avatar[size as AvatarSize].fontWeight,
    lineHeight: typography.avatar[size as AvatarSize].lineHeight,
    height: sizing.avatar[size as Size],
    width: sizing.avatar[size as Size],

    ...(withInitials &&
      withInitials != null &&
      withInitials !== '' && {
        '&::before': {
          content: `"${withInitials}"`,
          display: 'inline-flex',
          textTransform: 'uppercase',
        },
      }),
  }
}

/**
 * @deprecated Use `<AvatarNext>` instead.
 */
const StyledAvatar = styled(
  forwardRef<HTMLDivElement, AvatarProps>(function Avatar(
    {
      alt,
      noBorder,
      notRounded,
      size,
      src,
      withInitials,
      ...props
    }: AvatarProps,
    ref,
  ) {
    return (
      <Flex
        {...props}
        aria-label={alt || undefined}
        center
        data-gourmet-avatar
        inline
        ref={ref}
        role="img"
        shrink={0}
      />
    )
  }),
).attrs<AvatarProps>(({ src, withInitials }) =>
  src != null && (withInitials == null || withInitials === '')
    ? {
        style: {
          backgroundImage: `url(${src})`,
        },
      }
    : undefined,
)<AvatarProps>(styles)

StyledAvatar.displayName = 'Avatar'
StyledAvatar.defaultProps = defaultProps

export type { AvatarProps }
export { defaultProps as AvatarDefaultProps, styles as AvatarStyles }
export default StyledAvatar
