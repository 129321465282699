import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { Button } from '../ButtonNext'
import { ChevronUpIcon, ChevronDownIcon } from '@vori/gourmet-icons'
import { FormFieldLayout } from '../FormFieldLayout'
import { Input, InputProps } from '../InputNext'
import { Select } from '../Select'
import Flex from '../Flex'
import SearchInput, { SearchInputProps } from '../SearchInput'

type MultiSearchOption = {
  id: string
  label: string
}

type MultiSearchInputProps = InputProps & {
  /**
   * Whether the input is used for search or used for regular text.
   * A search input will have a search icon and will have "type=search" as
   * opposed to "type=text"
   */
  multiSearchDefaultLabel?: string
  multiSearchOptions: MultiSearchOption[]
  /**
   * The id of the currently selected multi-search option. If left undefined,
   * the multi-search selector will be uncontrolled.
   */
  multiSearchValue?: string
  onSearchOptionChange: (option: string) => void
}

const defaultProps: Partial<MultiSearchInputProps> = {
  multiSearchDefaultLabel: 'Search By',
  multiSearchOptions: [],
}

const StyledMultiSearchInput = styled(
  forwardRef<HTMLInputElement, MultiSearchInputProps>(function MultiSearchInput(
    {
      className,
      multiSearchDefaultLabel,
      multiSearchOptions,
      multiSearchValue,
      onSearchOptionChange,
      type = 'search',
      ...props
    }: MultiSearchInputProps,
    ref,
  ) {
    return (
      <FormFieldLayout fullWidth={props.fullWidth} className={className}>
        {type === 'search' && (
          <SearchInput
            {...(props as SearchInputProps)}
            noFocusRing
            fullWidth
            ref={ref}
            type={type}
          />
        )}
        {type !== 'search' && (
          <Input {...props} noFocusRing fullWidth ref={ref} type={type} />
        )}
        <Flex shrink={0}>
          <Select
            renderTrigger={(isExpanded, { state }) => (
              <Button
                rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              >
                {state.selectedOption
                  ? multiSearchOptions.find(
                      ({ id }) => state.selectedOption === id,
                    )?.label || state.selectedOption
                  : multiSearchDefaultLabel}
              </Button>
            )}
            disableTriggerActiveState
            getOptionLabel={(item) => item.label}
            getOptionValue={(item) => item.id}
            getOriginalOption={(id) =>
              multiSearchOptions.find((option) => option.id === id) || null
            }
            label={multiSearchDefaultLabel || ''}
            onChange={({ state }) =>
              onSearchOptionChange?.(state.selectedOption as string)
            }
            options={multiSearchOptions || []}
            position="right"
            initialState={{
              hasSelectedOption: Boolean(multiSearchValue),
              selectedOption: multiSearchValue,
            }}
          />
        </Flex>
      </FormFieldLayout>
    )
  }),
)``

StyledMultiSearchInput.displayName = 'MultiSearchInput'
StyledMultiSearchInput.defaultProps = defaultProps

export type { MultiSearchInputProps }
export { defaultProps as MultiSearchInputDefaultProps }
export default StyledMultiSearchInput
