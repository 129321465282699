import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Award02IconProps = IconProps

const Award02Icon = forwardRef<SVGSVGElement, Award02IconProps>(
  (props: Award02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 15.09V22l4.703-1.881c.11-.044.165-.066.221-.075a.5.5 0 0 1 .152 0c.056.009.111.03.221.075L17 22v-6.91m2.5-5.59a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Award02Icon.displayName = 'Award02Icon'
Award02Icon.defaultProps = iconDefaultProps

export type { Award02IconProps }
export { Award02Icon }
