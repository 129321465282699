import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Italic02IconProps = IconProps

const Italic02Icon = forwardRef<SVGSVGElement, Italic02IconProps>(
  (props: Italic02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m13.25 4-6 16m9.5-16-6 16M19.5 4h-10m5 16h-10"
        fill="none"
      ></path>
    </Icon>
  ),
)

Italic02Icon.displayName = 'Italic02Icon'
Italic02Icon.defaultProps = iconDefaultProps

export type { Italic02IconProps }
export { Italic02Icon }
