import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyBitcoinIconProps = IconProps

const CurrencyBitcoinIcon = forwardRef<SVGSVGElement, CurrencyBitcoinIconProps>(
  (props: CurrencyBitcoinIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 2v2m0 16v2m4-20v2m0 16v2m-6-18H14a4 4 0 0 1 0 8H7.5 15a4 4 0 0 1 0 8H7.5m0-16h-2m2 0v16m0 0h-2"
        fill="none"
      ></path>
    </Icon>
  ),
)

CurrencyBitcoinIcon.displayName = 'CurrencyBitcoinIcon'
CurrencyBitcoinIcon.defaultProps = iconDefaultProps

export type { CurrencyBitcoinIconProps }
export { CurrencyBitcoinIcon }
