import React from 'react'

import Loader, { LoaderProps } from '../Loader'

import { AvatarLoaderProps } from './types'

function getLoaderProps(
  avatarSize: AvatarLoaderProps['avatarSize'],
): NonNullable<Pick<LoaderProps, 'size'>> {
  switch (avatarSize) {
    case 'xs':
    case 'sm':
    case 'md': {
      return { size: 'small' }
    }

    case 'xxl': {
      return { size: 'base' }
    }

    default: {
      return { size: 'medium' }
    }
  }
}

function AvatarLoader({
  avatarSize,
  ...props
}: AvatarLoaderProps): JSX.Element {
  return (
    <Loader
      {...props}
      {...getLoaderProps(avatarSize)}
      data-gourmet-avatar-loader=""
    />
  )
}

AvatarLoader.displayName = 'AvatarLoader'

export { AvatarLoader }
