import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LayerSingleIconProps = IconProps

const LayerSingleIcon = forwardRef<SVGSVGElement, LayerSingleIconProps>(
  (props: LayerSingleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.358 7.179a1.066 1.066 0 0 0-.266-.111.5.5 0 0 0-.184 0 1.066 1.066 0 0 0-.266.111L2 12l9.642 4.821c.131.066.197.099.266.111.06.012.123.012.184 0 .069-.012.135-.045.266-.11L22 12l-9.642-4.82Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LayerSingleIcon.displayName = 'LayerSingleIcon'
LayerSingleIcon.defaultProps = iconDefaultProps

export type { LayerSingleIconProps }
export { LayerSingleIcon }
