import React from 'react'
import styled from 'styled-components'

import { ChevronDownIcon, ChevronRightIcon } from '@vori/gourmet-icons'

import { Button, ButtonProps } from '../ButtonNext'
import { AccordionContext, AccordionItemContext } from './AccordionContext'
import { AccordionState } from './types'
import { AccordionTokens } from './tokens'

const AccordionItemTriggerClickable = styled(Button)({
  ...AccordionTokens.typography.itemTrigger,
  justifyContent: 'space-between',
  textAlign: 'left',

  '&[data-sizing="xLarge"]': {
    paddingRight: 0,
    paddingLeft: 0,
  },
})

interface Props {
  /**
   * Props to be passed to the inner `<Clickable>` component.
   */
  clickableProps?: ButtonProps
  /**
   * Heading element (`h3` by default) used to wrap the button (`<Clickable>`):
   *
   * Each accordion header `button` is wrapped in an element with role `heading` that has a value set for `aria-level` that is appropriate for the information architecture of the page:
   * - If the native host language has an element with an implicit `heading` and `aria-level`, such as an HTML heading tag, a native host language element may be used.
   * - The `button` element is the only element inside the `heading` element. That is, if there are other visually persistent elements, they are not included inside the `heading` element.
   *
   * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/accordion/#wai-aria-roles-states-and-properties}
   */
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const AccordionItemTrigger = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<Props>
>(function AccordionItemTrigger(
  { children, clickableProps, heading }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const { setActiveIndex, unsetActiveIndex } =
    React.useContext(AccordionContext)

  const { disabled, isActive, itemIndex, panelID, triggerID } =
    React.useContext(AccordionItemContext)

  return React.createElement(
    heading || 'h3',
    {
      'data-gourmet-accordion-item-trigger-heading': itemIndex,
      style: { margin: 0 },
    },
    <AccordionItemTriggerClickable
      asClickable
      fullWidth
      rightIcon={isActive ? <ChevronDownIcon /> : <ChevronRightIcon />}
      sizing="xLarge"
      {...clickableProps}
      {...(disabled && { tabIndex: -1 })}
      aria-controls={panelID}
      aria-expanded={isActive ? 'true' : 'false'}
      data-gourmet-accordion-item-trigger={itemIndex}
      data-state={isActive ? AccordionState.OPEN : AccordionState.COLLAPSED}
      disabled={disabled}
      id={triggerID}
      ref={ref}
      onClick={(event) => {
        if (isActive) {
          unsetActiveIndex(itemIndex)
        } else {
          setActiveIndex(itemIndex)
        }

        if (clickableProps?.onClick) {
          clickableProps.onClick(event)
        }
      }}
    >
      {children}
    </AccordionItemTriggerClickable>,
  )
})

AccordionItemTrigger.displayName = 'AccordionItemTrigger'
AccordionItemTrigger.defaultProps = {}

export { AccordionItemTrigger }
export type { Props as AccordionItemTriggerProps }
