import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GridDotsLeftIconProps = IconProps

const GridDotsLeftIcon = forwardRef<SVGSVGElement, GridDotsLeftIconProps>(
  (props: GridDotsLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 3h.01m-.01 9h.01m-.01 9h.01M16.5 3h.01m-.01 9h.01m-.01 9h.01M12 3h.01M12 12h.01M12 21h.01M12 16.5h.01m-.01-9h.01M21 3h.01M21 12h.01M21 21h.01M21 16.5h.01m-.01-9h.01M3 21V3"
        fill="none"
      ></path>
    </Icon>
  ),
)

GridDotsLeftIcon.displayName = 'GridDotsLeftIcon'
GridDotsLeftIcon.defaultProps = iconDefaultProps

export type { GridDotsLeftIconProps }
export { GridDotsLeftIcon }
