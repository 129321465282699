import * as Sentry from '@sentry/react'

import {
  ENV_LOCAL,
  ENV_SENTRY_DSN,
  ENV_VERSION,
  ENVIRONMENT,
} from '@vori/dashboard-env'

export function initializeSentry(): void {
  if (ENV_LOCAL) {
    return
  }

  Sentry.init({
    dsn: ENV_SENTRY_DSN,
    environment: ENVIRONMENT,
    release: ENV_VERSION,
    tracesSampleRate: 0.15,
  })
}
