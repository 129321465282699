import React from 'react'
import styled from 'styled-components'

import { ChevronDownIcon, ChevronUpIcon, XCloseIcon } from '@vori/gourmet-icons'
import { foundations } from '@vori/gourmet-tokens'

import { Button } from '../ButtonNext'
import { FormFieldLayout } from '../FormFieldLayout'

import { FilterMenuBarChipProps } from './types'
import { toRem } from '../utils'

const StyledFormFieldLayout = styled(FormFieldLayout)({
  borderColor: foundations.color['color.gray-800'],
  borderRadius: toRem(8),
  borderStyle: 'solid',
  borderWidth: toRem(2),
  maxHeight: toRem(38),
  transition: 'all 350ms cubic-bezier(0.215, 0.61, 0.355, 1)',

  '[aria-expanded="true"] > &, &:focus-within': {
    boxShadow: `0 0 0 ${toRem(2)} #FFFFFF, 0 0 0 ${toRem(4)} #6038EF`,
  },

  '&&& [data-gourmet-filter-menubar-chip]': {
    borderWidth: 0,
    minHeight: 'fit-content',

    '&:hover': {
      backgroundColor: foundations.color['color.gray-100'],
    },
  },

  '&&& [data-gourmet-filter-menubar-chip-left]': {
    justifyContent: 'flex-start',
    textAlign: 'left',
    paddingRight: `calc(${foundations.space['space.075']} / 2.25)`,
  },

  '&&& [data-gourmet-filter-menubar-chip-right]': {
    paddingLeft: `calc(${foundations.space['space.075']} / 2.25)`,
  },
})

const Chip = styled(Button)({
  backgroundColor: foundations.color['color.base-white'],
  color: foundations.color['color.gray-800'],
})

const defaultProps: Partial<FilterMenuBarChipProps> = {
  hasSelectedValue: false,
  isOpen: false,
  withoutRemoveButton: false,
}

const FilterMenuBarChip = React.forwardRef<
  HTMLButtonElement,
  FilterMenuBarChipProps
>(function FilterMenuBarChip(
  {
    hasSelectedValue,
    isOpen,
    label,
    onClickClose,
    withoutRemoveButton,
    ...props
  }: FilterMenuBarChipProps,
  ref,
): JSX.Element {
  return (
    <>
      {hasSelectedValue && !withoutRemoveButton ? (
        <StyledFormFieldLayout>
          <Chip
            {...props}
            data-gourmet-filter-menubar-chip=""
            data-gourmet-filter-menubar-chip-left=""
            noFocusRing
            ref={ref}
            sizing="small"
          >
            {label}
          </Chip>
          <Chip
            aria-label="Remove filter"
            asIconButton
            data-gourmet-filter-menubar-chip=""
            data-gourmet-filter-menubar-chip-right=""
            leftIcon={<XCloseIcon />}
            noFocusRing
            sizing="small"
            onMouseDown={(event) => {
              event.stopPropagation()
            }}
            onClick={() => {
              onClickClose()
            }}
          />
        </StyledFormFieldLayout>
      ) : (
        <Chip
          {...props}
          ref={ref}
          data-gourmet-filter-menubar-chip=""
          rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          sizing="small"
        >
          {label}
        </Chip>
      )}
    </>
  )
})

FilterMenuBarChip.displayName = 'FilterMenuBarChip'
FilterMenuBarChip.defaultProps = defaultProps

export { FilterMenuBarChip, defaultProps as filterMenuBarChipDefaultProps }
