import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlarmClockIconProps = IconProps

const AlarmClockIcon = forwardRef<SVGSVGElement, AlarmClockIconProps>(
  (props: AlarmClockIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 3 2 6m20 0-3-3M6 19l-2 2m14-2 2 2M12 9v4l2 2m-2 6a8 8 0 1 0 0-16.001A8 8 0 0 0 12 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlarmClockIcon.displayName = 'AlarmClockIcon'
AlarmClockIcon.defaultProps = iconDefaultProps

export type { AlarmClockIconProps }
export { AlarmClockIcon }
