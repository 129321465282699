import InfiniteLoader from 'react-window-infinite-loader'
import React, { forwardRef, useMemo, useRef } from 'react'
import styled from 'styled-components'

import {
  ListChildComponentProps,
  ListOnItemsRenderedProps,
  VariableSizeList,
  VariableSizeListProps,
} from 'react-window'

import MenuItem from '../MenuItem'

import { assignRef, toPx, toRem } from '../utils'
import { Size, sizing } from '../tokens'
import { useAvailableHeight } from '../hooks'

type OnItemsRendered = (props: ListOnItemsRenderedProps) => unknown

type InfiniteLoaderProps = {
  isItemLoaded: (index: number) => boolean
  loadMoreItems: (
    startIndex: number,
    stopIndex: number,
  ) => Promise<unknown> | null
  itemCount: number
  children: (props: {
    onItemsRendered: OnItemsRendered
    ref: React.Ref<unknown>
  }) => React.ReactNode
  threshold?: number
  minimumBatchSize?: number
}

type ItemData<T = void, D = void> = {
  isItemLoaded: (index: number) => boolean
  itemCount: number
  items: T[]
  itemsData?: D
}

type RenderItemProps<T = void, D = void> = Omit<
  ListChildComponentProps,
  'data'
> & {
  data: ItemData<T, D>
}

type RenderItemMemo<T = void, D = void> = React.ExoticComponent<
  RenderItemProps<T, D>
>

type ListProps<T = void, D = void> = React.HTMLAttributes<HTMLDivElement> & {
  fullHeight?: boolean
  fullHeightOffset?: number
  maxHeight?: keyof typeof sizing.list
  /**
   * Will render the list without any top or bottom padding. This is
   * useful when rendering lists with custom vertical spacing due to stacking
   * siblings.
   *
   * @example
   * <Flex column fullWidth>
   *  <Text>A list:</Text>
   *  <Spacer />
   *  <List noVerticalPadding>
   *    {items.map(({ content }) => <Text>{content}</Text>)}
   *  </List>
   *  <Spacer />
   *  <Button>Click Me</Button>
   */
  noVerticalPadding?: boolean
  renderFooter?: (() => React.ReactNode) | null
  renderHeader?: (() => React.ReactNode) | null
  virtualize?: boolean
  virtualizeProps?: {
    infiniteLoaderProps?: Omit<
      InfiniteLoaderProps,
      'children' | 'isItemLoaded' | 'itemCount' | 'ref'
    >
    isItemLoaded?: (index: number) => boolean
    itemCount: number
    items: T[]
    itemsData?: D
    virtualListProps: Omit<
      VariableSizeListProps,
      | 'children'
      | 'height'
      | 'itemCount'
      | 'itemData'
      | 'onItemsRendered'
      | 'ref'
      | 'width'
    >
    renderItem: RenderItemMemo<T, D>
    infiniteLoaderRef?: React.Ref<InfiniteLoader | null>
    virtualListRef?: React.Ref<VariableSizeList | null>
  }
  /**
   * Use this prop when rendering items that have a shadow. It will make sure
   * that the shadows are properly displayed without being cropped due to hidden
   * x and y overflows used for scrolling.
   */
  withVisibleOverflow?: boolean
  /**
   * Use this prop when rendering items that have a focus ring, e.g.,
   * `<Button />` or `<Clickable />`. It will make sure that the focus rings are
   * properly displayed without being cropped due to hidden x and y overflows
   * used for scrolling.
   */
  withVisibleFocusRingOverflow?: boolean
}

const defaultProps: Partial<ListProps> = {
  className: '',
  fullHeight: false,
  fullHeightOffset: 0,
  maxHeight: 'small',
  virtualize: false,
  withVisibleOverflow: false,
}

const StyledVariableSizeList = styled(VariableSizeList)<{
  $fullHeight?: boolean
  $noVerticalPadding?: boolean
  $withVisibleOverflow?: boolean
  $withVisibleFocusRingOverflow?: boolean
}>`
  margin: 0
    ${({ $withVisibleOverflow }): string =>
      $withVisibleOverflow ? `-${sizing.shadowRadius}` : '0px'};

  padding: ${({
    $withVisibleOverflow,
    $withVisibleFocusRingOverflow,
  }): string =>
    $withVisibleOverflow
      ? `calc(${sizing.shadowRadius} + (${sizing.focusRing} * 2))`
      : $withVisibleFocusRingOverflow
        ? `calc(${sizing.focusRing} * 2)`
        : '0px'};

  width: ${({ $withVisibleOverflow }): string =>
    $withVisibleOverflow
      ? `calc(100% + (${sizing.shadowRadius} * 2))`
      : '100%'};

  ${({ $noVerticalPadding }) =>
    $noVerticalPadding ? 'padding-bottom: 0; padding-top: 0;' : ''}

  ${MenuItem}:first-of-type {
    border-radius: ${sizing.radius.large} ${sizing.radius.large} 0 0;
  }

  ${MenuItem}:last-of-type {
    border-radius: 0 0 ${sizing.radius.large} ${sizing.radius.large};
  }
`

const Container = styled.div<{
  $fullHeight?: boolean
  $maxHeight?: string
  $noVerticalPadding?: boolean
  $withVisibleOverflow?: boolean
  $withVisibleFocusRingOverflow?: boolean
}>`
  overflow-y: scroll;
  height: 100%;

  margin: 0
    ${({ $withVisibleOverflow }): string =>
      $withVisibleOverflow ? `-${sizing.shadowRadius}` : '0px'};

  max-height: ${({
    $fullHeight,
    $maxHeight = defaultProps.maxHeight,
    $withVisibleOverflow,
  }): string =>
    $fullHeight && $maxHeight
      ? `calc(${$maxHeight} + ${
          $withVisibleOverflow ? sizing.shadowRadius : '0px'
        })`
      : $maxHeight || 'auto'};

  padding: ${({
    $withVisibleOverflow,
    $withVisibleFocusRingOverflow,
  }): string =>
    $withVisibleOverflow
      ? `calc(${sizing.shadowRadius} + (${sizing.focusRing} * 2))`
      : $withVisibleFocusRingOverflow
        ? `calc(${sizing.focusRing} * 2)`
        : '0px'};

  width: ${({ $withVisibleOverflow }): string =>
    $withVisibleOverflow
      ? `calc(100% + (${sizing.shadowRadius} * 2))`
      : '100%'};

  ${({ $noVerticalPadding }) =>
    $noVerticalPadding ? 'padding-bottom: 0; padding-top: 0;' : ''}

  ${MenuItem}:first-of-type {
    border-radius: ${sizing.radius.large} ${sizing.radius.large} 0 0;
  }

  ${MenuItem}:last-of-type {
    border-radius: 0 0 ${sizing.radius.large} ${sizing.radius.large};
  }

  > div {
    position: relative;
  }
`

const StyledList = styled(
  forwardRef<HTMLDivElement, ListProps>(function List<T, D>(
    {
      children,
      className,
      fullHeight,
      fullHeightOffset,
      maxHeight,
      noVerticalPadding,
      renderFooter,
      renderHeader,
      virtualize,
      virtualizeProps,
      withVisibleOverflow,
      withVisibleFocusRingOverflow,
      ...props
    }: ListProps<T, D>,
    ref: React.Ref<HTMLDivElement | VariableSizeList>,
  ) {
    const [setListRef, listHeight] = useAvailableHeight(fullHeightOffset || 0)
    const infiniteLoaderRef = useRef<InfiniteLoader | null>(null)
    const virtualListRef = useRef<VariableSizeList | null>(null)

    const listItemData = useMemo<ItemData<T, D>>(
      () => ({
        isItemLoaded: virtualizeProps?.isItemLoaded || (() => true),
        itemCount: virtualizeProps?.itemCount || 0,
        items: virtualizeProps?.items || [],
        itemsData: virtualizeProps?.itemsData || ({} as D),
      }),
      [virtualizeProps],
    )

    const virtualHeight = useMemo(
      () =>
        fullHeight
          ? listHeight
          : Math.min(
              toPx(sizing.list[maxHeight as Size]),
              (virtualizeProps?.virtualListProps.itemSize(0) || 0) *
                listItemData.itemCount,
            ),
      [
        fullHeight,
        listHeight,
        listItemData.itemCount,
        maxHeight,
        virtualizeProps?.virtualListProps,
      ],
    )

    const content =
      virtualizeProps != null ? (
        <>
          {renderHeader ? renderHeader() : null}
          <InfiniteLoader
            {...(virtualizeProps.infiniteLoaderProps || {
              loadMoreItems: () => null,
            })}
            isItemLoaded={listItemData.isItemLoaded}
            itemCount={listItemData.itemCount}
            ref={infiniteLoaderRef}
          >
            {({ onItemsRendered, ref: passedInfiniteLoaderRef }) => (
              <StyledVariableSizeList
                {...virtualizeProps.virtualListProps}
                height={listItemData.itemCount > 0 ? virtualHeight : 0}
                itemCount={listItemData.itemCount}
                itemData={listItemData}
                onItemsRendered={onItemsRendered}
                outerRef={(outerRef) => {
                  assignRef(ref, outerRef)

                  if (virtualizeProps.virtualListProps.outerRef) {
                    assignRef(
                      virtualizeProps.virtualListProps.outerRef,
                      outerRef,
                    )
                  }
                }}
                ref={(virtualList) => {
                  assignRef(passedInfiniteLoaderRef, virtualList)
                  assignRef(virtualListRef, virtualList)

                  if (virtualizeProps.infiniteLoaderRef) {
                    assignRef(
                      virtualizeProps.infiniteLoaderRef,
                      infiniteLoaderRef.current,
                    )
                  }

                  if (virtualizeProps.virtualListRef) {
                    assignRef(virtualizeProps.virtualListRef, virtualList)
                  }
                }}
                width={
                  withVisibleOverflow
                    ? `calc(100% + ${sizing.shadowRadius} * 2) !important`
                    : '100%'
                }
                $fullHeight={fullHeight}
                $noVerticalPadding={noVerticalPadding}
                $withVisibleOverflow={withVisibleOverflow}
                $withVisibleFocusRingOverflow={withVisibleFocusRingOverflow}
              >
                {virtualizeProps.renderItem}
              </StyledVariableSizeList>
            )}
          </InfiniteLoader>
          {renderFooter ? renderFooter() : null}
        </>
      ) : null

    return (
      <>
        <span ref={setListRef} />
        {virtualize && virtualizeProps != null ? (
          listItemData.itemCount <= 0 ? (
            <div
              style={{
                height: virtualHeight,
              }}
            >
              {content}
            </div>
          ) : (
            content
          )
        ) : (
          <Container
            {...props}
            $fullHeight={fullHeight}
            $maxHeight={
              fullHeight ? toRem(listHeight) : sizing.list[maxHeight as Size]
            }
            $noVerticalPadding={noVerticalPadding}
            $withVisibleOverflow={withVisibleOverflow}
            $withVisibleFocusRingOverflow={withVisibleFocusRingOverflow}
            className={className}
            ref={ref as React.Ref<HTMLDivElement>}
          >
            {renderHeader ? renderHeader() : null}
            {children}
            {renderFooter ? renderFooter() : null}
          </Container>
        )}
      </>
    )
  }),
)``

StyledList.displayName = 'List'
StyledList.defaultProps = defaultProps

export { defaultProps as ListDefaultProps }

export type {
  InfiniteLoader,
  ListProps,
  RenderItemMemo as ListItemMemo,
  RenderItemProps as ListItemProps,
  VariableSizeList as VirtualList,
}

/**
 * Need to re-cast due to the forwardRef return value.
 * @see {@link https://stackoverflow.com/a/58473012}
 */
export default StyledList as <T, D>(
  props: ListProps<T, D> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement
