import React from 'react'

import { AnalyticsBrowser } from '@segment/analytics-next'
import { useLocation } from 'react-router-dom'

import { ENV_LOCAL, ENV_SEGMENT_WRITE_KEY } from '@vori/dashboard-env'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

let Segment: AnalyticsBrowser | null = null

export function isInitialized(): boolean {
  return Boolean(Segment)
}

export function initializeSegment() {
  if (ENV_LOCAL || isInitialized()) {
    return
  }

  Segment = AnalyticsBrowser.load({ writeKey: ENV_SEGMENT_WRITE_KEY })
}

export function useSegment(): void {
  const { user } = useCurrentUserState()
  const location = useLocation()

  // Sets current user information for Sentry when available.
  React.useEffect(() => {
    if (ENV_LOCAL || !isInitialized() || !user.state.isLoggedIn) {
      return
    }

    Segment?.identify(user.data.id, {
      company: {
        id: user.data.companyID,
        name: user.data.companyName,
      },
      avatar: user.data.companyImageURL,
      email: user.data.email,
      id: user.data.id,
      username: user.data.displayName,
    })
  }, [
    user.data.companyID,
    user.data.companyImageURL,
    user.data.companyName,
    user.data.displayName,
    user.data.email,
    user.data.id,
    user.state.isLoggedIn,
  ])

  // Removes current user information from Sentry when the user logs out.
  React.useEffect(() => {
    if (ENV_LOCAL || !isInitialized() || user.state.isLoggedIn) {
      return
    }

    Segment?.reset()
  }, [user.state.isLoggedIn])

  // Track page views
  React.useEffect(() => {
    if (ENV_LOCAL || !isInitialized()) {
      return
    }

    Segment?.page()
  }, [location])
}

/**
 * Using this enum for `track` calls makes attribution more consistent,
 * and makes downstream data easier to debug
 */
export enum EventNames {
  // Global
  GLOBAL_RESTRICTED_ACCESS = 'gobal_restricted_access',

  // Products Side Panel
  PRODUCTS_SIDE_PANEL_OPENED = 'products_side_panel_opened',
  PRODUCTS_SIDE_PANEL_FIELD_CHANGED = 'products_side_panel_field_changed',
  PRODUCTS_SIDE_PANEL_AUTOFILL_CLICKED = 'products_side_panel_autofill_clicked',
  PRODUCTS_SIDE_PANEL_PRICE_SUGGESTION_CLICKED = 'products_side_panel_price_suggestion_clicked',
  PRODUCTS_SIDE_PANEL_COST_CONFIGURATION_DROPDOWN_CHANGED = 'products_side_panel_cost_configuration_dropdown_changed',
  PRODUCTS_SIDE_PANEL_DELETE_COST_CLICKED = 'products_side_panel_cost_delete_clicked',
  PRODUCTS_SIDE_PANEL_CLOSE_COST_CLICKED = 'products_side_panel_cost_close_clicked',
  PRODUCTS_SIDE_PANEL_REVERT_COST_CLICKED = 'products_side_panel_cost_revert_clicked',
  PRODUCTS_SIDE_PANEL_ADD_VENDOR_CLICKED = 'products_side_panel_add_vendor_clicked',
  PRODUCTS_SIDE_PANEL_DEACTIVATE_CLICKED = 'products_side_panel_deactivate_clicked',
  PRODUCTS_SIDE_PANEL_CANCEL_CLICKED = 'products_side_panel_cancel_clicked',
  PRODUCTS_SIDE_PANEL_UPDATE_CLICKED = 'products_side_panel_update_clicked',
  PRODUCTS_SIDE_PANEL_PRODUCT_CREATED = 'products_side_panel_product_created',
  PRODUCTS_SIDE_PANEL_PRODUCT_UPDATED = 'products_side_panel_product_updated',
  PRODUCTS_SIDE_PANEL_PRODUCT_COST_UPDATED = 'products_side_panel_product_cost_updated',
  PRODUCTS_SIDE_PANEL_PRODUCT_PRICE_UPDATED = 'products_side_panel_product_price_updated',
  PRODUCTS_SIDE_PANEL_BULK_PRODUCTS_UPDATED = 'products_side_panel_bulk_products_updated',

  // Vendors: home page
  VENDOR_HOME_ONBOARDING_UPDATE_CATALOG_CLICKED = 'vendor_home_onboarding_update_catalog_clicked',
  VENDOR_HOME_ONBOARDING_SKIP_CATALOG_CLICKED = 'vendor_home_onboarding_skip_catalog_clicked',
  VENDOR_HOME_ONBOARDING_REVIEW_PROFILE_CLICKED = 'vendor_home_onboarding_review_profile_clicked',
  VENDOR_HOME_ONBOARDING_CREATE_PROMO_BANNER_CLICKED = 'vendor_home_onboarding_create_promo_banner_clicked',
  VENDOR_HOME_CARD_REVIEW_ORDER_CLICKED = 'vendor_home_card_review_order_clicked',
  VENDOR_HOME_CARD_UPDATE_CATALOG_CLICKED = 'vendor_home_card_update_catalog_clicked',
  VENDOR_HOME_CARD_MANAGE_ACCOUNTS_CLICKED = 'vendor_home_card_manage_accounts_clicked',
  VENDOR_HOME_CARD_BOOK_DEMO_CLICKED = 'vendor_home_card_book_demo_clicked',

  // Vendors: main nav
  VENDOR_NAV_GET_SUPPORT_CLICKED = 'vendor_nav_get_support_clicked',

  // Vendors: Catalog
  VENDOR_CATALOG_INITIATE_ADD_SINGLE_PRODUCT_CLICKED = 'vendor_catalog_initiate_add_single_product_clicked',
  VENDOR_CATALOG_INITIATE_BULK_UPLOAD_CLICKED = 'vendor_catalog_initiate_bulk_upload_clicked',
  VENDOR_CATALOG_CREATE_PRODUCT_CLICKED = 'vendor_catalog_create_product_clicked',
  VENDOR_CATALOG_UPDATE_PRODUCT_CLICKED = 'vendor_catalog_update_product_clicked',
  VENDOR_CATALOG_DELETE_PRODUCT_CLICKED = 'vendor_catalog_delete_product_clicked',
  VENDOR_CATALOG_DOWNLOAD_CATALOG_CLICKED = 'vendor_catalog_download_catalog_clicked',
  VENDOR_CATALOG_DOWNLOAD_TEMPLATE_CLICKED = 'vendor_catalog_download_template_clicked',
  VENDOR_CATALOG_UPLOAD_CATALOG_INITIATE_CLICKED = 'vendor_catalog_upload_catalog_initiate_clicked',
  VENDOR_CATALOG_UPLOAD_DOWNLOAD_ERRORS_CLICKED = 'vendor_catalog_upload_download_errors_clicked',
  VENDOR_CATALOG_UPLOAD_VALIDATE_UPLOAD_CLICKED = 'vendor_catalog_upload_validate_upload_clicked',

  // Vendors: Customers
  VENDOR_CUSTOMERS_CREATE_SINGLE_CUSTOMER_INITIATE_CLICKED = 'vendor_customers_create_single_customer_initiate_clicked',
  VENDOR_CUSTOMERS_CREATE_SINGLE_CUSTOMER_CONFIRM_CLICKED = 'vendor_customers_create_single_customer_confirm_clicked',
  VENDOR_CUSTOMERS_CREATE_BULK_UPLOAD_INITIATE_CLICKED = 'vendor_customers_create_bulk_upload_initiate_clicked',
  VENDOR_CUSTOMERS_CREATE_BULK_UPLOAD_FILE_UPLOADED = 'vendor_customers_create_bulk_upload_file_uploaded',
  VENDOR_CUSTOMERS_INVITE_ALL_ELIGIBLE_CUSTOMERS_CLICKED = 'vendor_customers_invite_all_eligible_customers_clicked',
  VENDOR_CUSTOMERS_INVITE_SELECTED_CUSTOMERS_CLICKED = 'vendor_customers_invite_selected_customers_clicked',
  VENDOR_CUSTOMERS_SEND_INVITATION_CLICKED = 'vendor_customers_send_invitation_clicked',
  VENDOR_CUSTOMERS_EXPORT_CUSTOMERS_CLICKED = 'vendor_customers_export_customers_clicked',
  VENDOR_CUSTOMERS_ACCOUNT_DETAIL_CLICKED = 'vendor_customers_account_detail_clicked',
  VENDOR_CUSTOMERS_ACCOUNT_DETAIL_ORDER_HISTORY_TAB_CLICKED = 'vendor_customers_account_detail_order_history_tab_clicked',
  VENDOR_CUSTOMERS_ACCOUNT_DETAIL_STORE_ACCOUNT_TAB_CLICKED = 'vendor_customers_account_detail_store_account_tab_clicked',
  VENDOR_CUSTOMERS_ACCOUNT_DETAIL_ACCOUNT_EDITED = 'vendor_customers_account_detail_account_edited',

  // Vendors: Promotions
  VENDOR_PROMOTIONS_NO_PROMOS_GET_STARTED_CLICKED = 'vendor_promotions_no_promos_get_started_clicked',
  VENDOR_PROMOTIONS_ADD_NEW_PROMOTION_CLICKED = 'vendor_promotions_add_new_promotion_clicked',
  VENDOR_PROMOTIONS_PUBLISH_PROMOTION_CLICKED = 'vendor_promotions_publish_promotion_clicked',
  VENDOR_PROMOTIONS_VIEW_PROMOTION_DETAILS_CLICKED = 'vendor_promotions_view_promotion_details_clicked',
  VENDOR_PROMOTIONS_EDIT_PROMOTION_CLICKED = 'vendor_promotions_edit_promotion_clicked',
  VENDOR_PROMOTIONS_DUPLICATE_PROMOTION_FROM_TEMPLATE_CLICKED = 'vendor_promotions_duplicate_promotion_from_template_clicked',
  VENDOR_PROMOTIONS_DELETE_PROMOTION = 'vendor_promotions_delete_promotion',

  // Retailers: Home
  RETAILER_HOME_PROMOTIONS_ALERT_BUTTON_CLICK = 'retailer_home_promotions_alert_button_clicked',
  RETAILER_HOME_ORDERS_BUTTON_CLICK = 'retailer_home_orders_button_clicked',
  RETAILER_HOME_CATALOGS_BUTTON_CLICK = 'retailer_home_catalogs_button_clicked',
  RETAILER_HOME_ORDER_GUIDES_BUTTON_CLICK = 'retailer_home_order_guides_button_clicked',
  RETAILER_HOME_VENDOR_INVITE_BUTTON_CLICK = 'retailer_home_vendor_invite_button_clicked',

  // Retailers: Promotions
  RETAILER_PROMOTIONS_ADD_BUTTON_CLICKED = 'retailer_promotions_add_button_clicked',
  RETAILER_PROMOTIONS_EXPIRING_LINK_CLICKED = 'retailer_promotions_expiring_link_clicked',
  RETAILER_PROMOTIONS_FILTERED_BY_DEAL = 'retailer_promotions_filtered_by_deal',
  RETAILER_PROMOTIONS_FILTERED_BY_VENDOR = 'retailer_promotions_filtered_by_vendor',
  RETAILER_PROMOTIONS_NEW_LINK_CLICKED = 'retailer_promotions_new_link_clicked',
  RETAILER_PROMOTIONS_ORDER_GUIDE_ALL_DESELECTED = 'retailer_promotions_order_guide_all_deselected',
  RETAILER_PROMOTIONS_ORDER_GUIDE_ALL_SELECTED = 'retailer_promotions_order_guide_all_selected',
  RETAILER_PROMOTIONS_ORDER_GUIDE_DESELECTED = 'retailer_promotions_order_guide_deselected',
  RETAILER_PROMOTIONS_ORDER_GUIDE_SELECTED = 'retailer_promotions_order_guide_selected',
  RETAILER_PROMOTIONS_ORDER_GUIDES_SEARCHED = 'retailer_promotions_order_guides_searched',
  RETAILER_PROMOTIONS_ORDER_GUIDES_UPDATED = 'retailer_promotions_order_guides_updated',
  RETAILER_PROMOTIONS_REMOVED_FILTER_BY_DEAL = 'retailer_promotions_removed_filter_by_deal',
  RETAILER_PROMOTIONS_REMOVED_FILTER_BY_VENDOR = 'retailer_promotions_remove_filter_by_vendor',

  RETAIL_PRODUCTS_PRODUCT_CREATED_SCAN_NOT_FOUND = 'retail_products_product_created_scan_not_found',
  RETAIL_PRODUCTS_EXPORT = 'retail_products_export',
  RETAIL_PRODUCTS_START_NEW_PRODUCT = 'retail_products_start_new_product',
  RETAIL_PRODUCTS_SCAN_SEARCH = 'retail_products_scan_search',
  RETAIL_PRODUCTS_TEXT_SEARCH = 'retail_products_text_search',

  RETAIL_TRANSACTIONS_VIEW_DETAILS = 'retail_transactions_view_details',
  RETAIL_TRANSACTIONS_TEXT_SEARCH = 'retail_transactions_text_search',

  RETAIL_MEMBERS_TEXT_SEARCH = 'retail_members_text_search',
}

type TrackTailParameters<T extends Parameters<AnalyticsBrowser['track']>> =
  T extends [infer _, ...infer Tail] ? Tail : never

export function trackEvent(
  eventName: EventNames,
  ...args: TrackTailParameters<Parameters<AnalyticsBrowser['track']>>
): void {
  if (ENV_LOCAL || !isInitialized()) {
    return
  }

  Segment?.track(eventName, ...args)
}

export { Segment }
