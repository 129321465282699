import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Strikethrough01IconProps = IconProps

const Strikethrough01Icon = forwardRef<SVGSVGElement, Strikethrough01IconProps>(
  (props: Strikethrough01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 16a4 4 0 0 0 4 4h4a4 4 0 0 0 0-8m4-4a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4m-3 4h18"
        fill="none"
      ></path>
    </Icon>
  ),
)

Strikethrough01Icon.displayName = 'Strikethrough01Icon'
Strikethrough01Icon.defaultProps = iconDefaultProps

export type { Strikethrough01IconProps }
export { Strikethrough01Icon }
