import { useId } from '@reach/auto-id'
import React, { forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import Spacer from '../Spacer'
import Text, { TextProps } from '../Text'

import { colors, sizing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type RadioSize = keyof typeof sizing.radio

type RadioProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  disableFocusRing?: boolean
  fullWidth?: boolean
  label?: React.ReactNode
  size?: RadioSize
  textProps?: TextProps
}

const defaultProps: Partial<RadioProps> = {
  className: '',
  disableFocusRing: false,
  fullWidth: false,
  label: null,
  size: 'base',
  textProps: {},
}

function radioStyles(props: { $size?: RadioSize | null }): CSSObject {
  return {
    alignItems: 'center',
    backgroundColor: colors.radio.backgroundColor,
    borderColor: colors.radio.borderColor,
    borderRadius: sizing.radius.rounded,
    borderStyle: 'solid',
    borderWidth: 1,
    color: colors.radio.color,
    display: 'flex',
    flexShrink: 0,
    height: sizing.radio[props.$size as RadioSize],
    justifyContent: 'center',
    transition: toTransitions(
      ['background-color', 'border-color', 'box-shadow', 'color', 'opacity'],
      'ease',
    ),
    width: sizing.radio[props.$size as RadioSize],

    '::before': {
      backgroundColor: colors.radio.active.backgroundColor,
      borderRadius: sizing.radius.rounded,
      content: '""',
      height: `calc(${sizing.radio[props.$size as RadioSize]} / 2)`,
      opacity: 0,
      transition: toTransitions(['opacity'], 'ease'),
      width: `calc(${sizing.radio[props.$size as RadioSize]} / 2)`,
    },
  }
}

function labelStyles(props: {
  disabled: boolean
  $disableFocusRing: boolean
  $fullWidth: boolean
}): CSSObject {
  return {
    ...(props.$fullWidth && { width: '100%' }),
    cursor: props.disabled ? 'default' : 'pointer',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',

    [Input]: {
      cursor: props.disabled ? 'default' : 'pointer',
    },

    [`:hover ${FauxRadio}, ${Input}:focus + ${FauxRadio}`]: {
      borderColor: props.disabled
        ? colors.radio.borderColor
        : colors.radio.hover.borderColor,
    },

    [`${Input}:focus + ${FauxRadio}`]: {
      boxShadow: !props.$disableFocusRing
        ? `0 0 0 ${sizing.focusRing} ${colors.radio.focusRing}`
        : 'none',
    },

    [`${Input}:checked + ${FauxRadio}`]: {
      borderColor: colors.radio.active.borderColor,

      '::before': {
        opacity: 1,
      },
    },

    [`${Input}:disabled + ${FauxRadio}`]: {
      opacity: 0.5,
    },
  }
}

const FauxRadio = styled.div<{ $size?: RadioSize | null }>(radioStyles)

const Input = styled.input({
  height: '100%',
  margin: 0,
  opacity: 0,
  padding: 0,
  position: 'absolute',
  width: '100%',
  zIndex: 1,
})

const Label = styled.label<{
  disabled: boolean
  $disableFocusRing: boolean
  $fullWidth: boolean
}>(labelStyles)

/**
 * @deprecated Use `<RadioInput>` and `<RadioInputField>` instead.
 */
const StyledRadio = styled(
  forwardRef<HTMLLabelElement, RadioProps>(function Radio(
    {
      className = '',
      disableFocusRing,
      fullWidth,
      label = null,
      size,
      textProps,
      ...props
    }: RadioProps,
    ref,
  ) {
    const labelId = `radio--${useId()}`
    const inputId = `radio--input--${useId()}`

    return (
      <Label
        $disableFocusRing={disableFocusRing || false}
        $fullWidth={fullWidth || false}
        className={className}
        disabled={props.disabled || false}
        htmlFor={inputId}
        id={labelId}
        ref={ref}
      >
        <Input {...props} id={inputId} type="radio" />
        <FauxRadio $size={size} />
        {label && (
          <>
            <Spacer size="small" />
            {typeof label === 'number' || typeof label === 'string' ? (
              <Text {...textProps} aria-labelledby={labelId}>
                {label}
              </Text>
            ) : (
              label
            )}
          </>
        )}
      </Label>
    )
  }),
)``

StyledRadio.displayName = 'Radio'
StyledRadio.defaultProps = defaultProps

export type { RadioProps }
export { defaultProps as RadioDefaultProps, radioStyles, labelStyles }
export default StyledRadio
