import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { AlertCircleIcon } from '@vori/gourmet-icons'

import Label, { LabelDefaultProps, LabelProps } from '../Label'
import Spacer from '../Spacer'

import { spacing } from '../tokens'

type ErrorProps = React.HTMLAttributes<HTMLSpanElement> &
  LabelProps & {
    fullWidth?: boolean
  }

const defaultProps: Partial<ErrorProps> = {
  ...LabelDefaultProps,
  className: '',
  fullWidth: false,
}

const Container = styled(({ fullWidth, ...props }) => (
  <Label {...props} />
))<ErrorProps>`
  ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  padding: ${({ noPadding }): string => (noPadding ? '0px' : spacing.error)};
`

const StyledError = styled(
  forwardRef<HTMLSpanElement, ErrorProps>(function Error(
    { children, ...props }: ErrorProps,
    ref,
  ) {
    return (
      <Container {...props} ref={ref} outlined variant="negative">
        <AlertCircleIcon />
        <Spacer inline size="tiny" />
        {children}
      </Container>
    )
  }),
)``

StyledError.displayName = 'Error'
StyledError.defaultProps = defaultProps

export type { ErrorProps }
export { defaultProps as ErrorDefaultProps }
export default StyledError
