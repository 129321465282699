import React from 'react'

import { PropsFromHook } from './types'

type DialogContextValue = PropsFromHook

const DialogContext = React.createContext<DialogContextValue | undefined>(
  undefined,
)

DialogContext.displayName = 'DialogContext'

/**
 * Returns the current `value` for the parent `<DialogContext>`, if any.
 */
function useDialogContext(): DialogContextValue {
  const value = React.useContext(DialogContext)

  if (value === undefined) {
    throw new Error(
      'The `useDialogContext` hook must be used within `<DialogContext.Provider>`',
    )
  }

  return value
}

export { DialogContext, useDialogContext }
export type { DialogContextValue }
