import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockFastForwardIconProps = IconProps

const ClockFastForwardIcon = forwardRef<
  SVGSVGElement,
  ClockFastForwardIconProps
>((props: ClockFastForwardIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m22.7 11.5-2 2-2-2m2.245 1.5A9 9 0 1 0 19 17.657M12 7v5l3 2"
      fill="none"
    ></path>
  </Icon>
))

ClockFastForwardIcon.displayName = 'ClockFastForwardIcon'
ClockFastForwardIcon.defaultProps = iconDefaultProps

export type { ClockFastForwardIconProps }
export { ClockFastForwardIcon }
