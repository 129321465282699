import { useId } from '@reach/auto-id'
import React, { Fragment, forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'
import { CheckIcon } from '@vori/gourmet-icons'

import Spacer from '../Spacer'
import Text, { TextProps } from '../Text'

import { colors, sizing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type CheckboxSize = keyof typeof sizing.checkbox

type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> & {
  disableFocusRing?: boolean
  fullWidth?: boolean
  label?: React.ReactNode
  size?: CheckboxSize
  textProps?: TextProps
}

const defaultProps: Partial<CheckboxProps> = {
  className: '',
  disableFocusRing: false,
  fullWidth: false,
  label: null,
  size: 'base',
  textProps: {},
}

const fauxCheckboxStyles = (props: {
  $size?: CheckboxSize | null
}): CSSObject => ({
  backgroundColor: colors.checkbox.backgroundColor,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.checkbox.borderColor,
  borderRadius: sizing.radius.small,
  color: colors.checkbox.color,
  alignItems: 'center',
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  height: sizing.checkbox[props.$size as CheckboxSize],
  width: `calc(${sizing.checkbox[props.$size as CheckboxSize]})`,
  transition: toTransitions(
    ['background-color', 'border-color', 'box-shadow', 'color', 'opacity'],
    'ease',
  ),

  '> svg': {
    transition: toTransitions(['opacity'], 'ease'),
    opacity: 0,
  },
})

const FauxCheckbox = styled.div<{ $size?: CheckboxSize | null }>(
  fauxCheckboxStyles,
)

const Input = styled.input`
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const Label = styled.label<{
  disabled: boolean
  $disableFocusRing: boolean
  $fullWidth: boolean
}>`
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%;' : '')}
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  align-items: center;
  display: flex;
  position: relative;

  ${Input} {
    cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  }

  :hover ${FauxCheckbox}, ${Input}:focus + ${FauxCheckbox} {
    border-color: ${({ disabled }): string =>
      disabled
        ? colors.checkbox.borderColor
        : colors.checkbox.hover.borderColor};
  }

  ${Input}:focus + ${FauxCheckbox} {
    box-shadow: ${({ $disableFocusRing }) =>
      !$disableFocusRing
        ? `0 0 0 ${sizing.focusRing} ${colors.checkbox.focusRing}`
        : 'none'};
  }

  ${Input}:checked + ${FauxCheckbox} {
    background-color: ${colors.checkbox.active.backgroundColor};
    border-color: ${colors.checkbox.active.borderColor};

    > svg {
      opacity: 1;
    }
  }

  ${Input}:disabled + ${FauxCheckbox} {
    opacity: 0.5;
  }
`

const StyledCheckbox = styled(
  forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
    {
      className = '',
      disableFocusRing,
      fullWidth,
      label = null,
      size,
      textProps,
      ...props
    }: CheckboxProps,
    ref,
  ) {
    const labelId = `checkbox--${useId()}`
    const inputId = `checkbox--input--${useId()}`

    return (
      <Label
        $disableFocusRing={disableFocusRing || false}
        $fullWidth={fullWidth || false}
        className={className}
        disabled={props.disabled || false}
        htmlFor={inputId}
        id={labelId}
      >
        <Input {...props} ref={ref} id={inputId} type="checkbox" />
        <FauxCheckbox $size={size}>
          <CheckIcon size={size === 'small' ? 'sm' : 'md'} variant="primary" />
        </FauxCheckbox>
        {label && (
          <Fragment>
            <Spacer size="small" />
            {typeof label === 'number' || typeof label === 'string' ? (
              <Text {...textProps} aria-labelledby={labelId}>
                {label}
              </Text>
            ) : (
              label
            )}
          </Fragment>
        )}
      </Label>
    )
  }),
)``

StyledCheckbox.displayName = 'Checkbox'
StyledCheckbox.defaultProps = defaultProps

export type { CheckboxProps }
export { defaultProps as CheckboxDefaultProps, fauxCheckboxStyles }
export default StyledCheckbox
