import animations from '../tokens/animations'

const toTransitions = (
  cssProps: string[],
  transitionName: keyof typeof animations.transitions,
): string =>
  cssProps
    .map((cssProp) => `${cssProp} ${animations.transitions[transitionName]}`)
    .join(',')

export default toTransitions
