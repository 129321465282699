import React from 'react'

import { useStableCallback } from './useStableCallback'

/**
 * Enables you to add event listeners to a target element, or to the `document`
 * by default, within a React component.
 */
export function useEventListener<
  TEvent extends keyof GlobalEventHandlersEventMap,
  THandler extends (event: GlobalEventHandlersEventMap[TEvent]) => unknown,
  TElement extends HTMLElement,
>(
  /**
   * A case-sensitive string representing the event type to listen for.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Events}
   */
  event: TEvent,
  /**
   * A callback that accepts a single parameter: an object based on Event
   * describing the event that has occurred, and it returns nothing.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#the_event_listener_callback}
   */
  handler?: THandler,
  options?: {
    /**
     * HTMLElement to attach the event listener to.
     */
    element?: TElement | Window | null
    /**
     * An object that specifies characteristics about the event listener.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#options}
     */
    options?: boolean | AddEventListenerOptions
  },
): void {
  const stableHandler = useStableCallback(handler)

  const element = React.useMemo(
    () => options?.element || document,
    [options?.element],
  )

  React.useEffect(() => {
    element.addEventListener(
      event,
      stableHandler as unknown as EventListener,
      options?.options,
    )

    return () => {
      element.removeEventListener(
        event,
        stableHandler as unknown as EventListener,
      )
    }
  }, [element, event, stableHandler, options?.options])
}

export default useEventListener
