import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronUpIconProps = IconProps

const ChevronUpIcon = forwardRef<SVGSVGElement, ChevronUpIconProps>(
  (props: ChevronUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18 15-6-6-6 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ChevronUpIcon.displayName = 'ChevronUpIcon'
ChevronUpIcon.defaultProps = iconDefaultProps

export type { ChevronUpIconProps }
export { ChevronUpIcon }
