import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { Button } from '../ButtonNext'

import { composeEventHandlers, toTransitions } from '../utils'

import { getTabID, getTabPanelID } from './utils'
import { useTabsContext } from './hooks'

import { TabState, TabValue } from './types'
import { TabsTokens } from './tokens'

function styles(props: { $isSelected?: boolean }): CSSObject {
  return {
    '&[data-clickable][data-variant]': {
      ...TabsTokens.typography.tab,
      borderRadius: 0,
      color: props.$isSelected
        ? TabsTokens.colors.tab.selected.color
        : TabsTokens.colors.tab.color,
      marginRight: TabsTokens.spacing.tab.marginRight,
      paddingBottom: TabsTokens.spacing.tab.paddingBottom,
      paddingLeft: TabsTokens.spacing.tab.paddingLeft,
      paddingRight: TabsTokens.spacing.tab.paddingRight,
      paddingTop: TabsTokens.spacing.tab.paddingTop,
      position: 'relative',
      transition: toTransitions(['border-color', 'color'], 'ease'),
    },

    '&[data-clickable][data-variant], &[data-clickable][data-variant]:hover, &[data-clickable][data-variant]:focus, &[data-clickable][data-variant]:active':
      {
        borderWidth: 0,
        borderBottomColor: props.$isSelected
          ? TabsTokens.colors.tab.selected.borderColor
          : 'transparent',
        borderBottomWidth: TabsTokens.sizing.tab.borderWidth,
        zIndex: props.$isSelected ? 2 : 0,
      },

    '&[data-clickable][data-variant]:hover': {
      color: TabsTokens.colors.tab.selected.color,
    },

    '&[data-clickable][data-variant]:focus, &[data-clickable][data-variant]:active':
      {
        ...(!props.$isSelected && { zIndex: 0 }),
        backgroundColor: TabsTokens.colors.tab.focus.backgroundColor,
        boxShadow: 'none',
        color: props.$isSelected
          ? TabsTokens.colors.tab.selected.color
          : TabsTokens.colors.tab.color,
      },
  }
}

const TabClickable = styled(Button)(styles)

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The value used to match the corresponding <TabPanel> for this <Tab>. If
   * no value is provided, the index of the <Tab> in relation to its parent
   * <TabList> component will be used as the `value`.
   */
  value?: TabValue
}

/**
 * A grouping label providing a mechanism for selecting the tab content that is
 * to be rendered to the user.
 *
 * @see {@link https://w3c.github.io/aria/#tab}
 */
const Tab = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<Props>>(
  function Tab(
    { children, value, ...props }: React.PropsWithChildren<Props>,
    ref,
  ): JSX.Element | null {
    const { id: tabsID, isSelectedValue, setSelectedValue } = useTabsContext()

    // This check is mostly for the TS compiler, as internally, we ensure that
    // `value` is always set for <Tab> components rendered within <Tabs>
    // and <TabList>.
    if (value == null) {
      return null
    }

    const isSelected = isSelectedValue(value)

    return (
      <TabClickable
        {...props}
        {...((props.disabled || !isSelected) && { tabIndex: -1 })}
        {...(isSelected && { tabIndex: 0 })}
        $isSelected={isSelected}
        aria-controls={getTabPanelID(tabsID, value)}
        aria-selected={isSelected ? 'true' : 'false'}
        asClickable
        data-gourmet-tab
        data-state={isSelected ? TabState.ACTIVE : TabState.INACTIVE}
        id={getTabID(tabsID, value)}
        ref={ref}
        role="tab"
        onClick={composeEventHandlers(props.onClick, (event) => {
          if (!isSelected) {
            setSelectedValue(value)
          }

          // Because Safari "special":
          // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#clicking_and_focus
          event.currentTarget.focus()
        })}
      >
        {children}
      </TabClickable>
    )
  },
)

Tab.displayName = 'Tab'
Tab.defaultProps = {}

export { Tab, styles as TabStyles }
export type { Props as TabProps }
