import { foundations } from '@vori/gourmet-tokens'
import toRem from '../utils/toRem'

export default {
  calendarDate: toRem(24),
  focusRing: toRem(3),
  panelCloseButton: toRem(26),
  progressStep: toRem(30),
  shadowRadius: `${toRem(16)}`,
  shadowXOffset: `${toRem(6)}`,
  shadowYOffset: `${toRem(6)}`,
  tooltipTick: toRem(6),

  radius: {
    tiny: toRem(2),
    small: toRem(4),
    medium: toRem(6),
    base: toRem(8),
    large: toRem(12),
    xLarge: toRem(24),
    pill: toRem(9999),
    rounded: '50%',
  },

  icon: {
    tiny: toRem(8),
    small: toRem(12),
    medium: toRem(16),
    base: toRem(24),
    large: toRem(48),
    xLarge: toRem(64),
    xs: foundations.size['size.icon-xs'],
    sm: foundations.size['size.icon-sm'],
    md: foundations.size['size.icon-md'],
    lg: foundations.size['size.icon-lg'],
    xl: foundations.size['size.icon-xl'],
    xxl: foundations.size['size.icon-xxl'],
  },

  loader: {
    tiny: toRem(8),
    small: toRem(12),
    medium: toRem(16),
    base: toRem(24),
    large: toRem(48),
    xLarge: toRem(64),
  },

  /** x1.5 */
  logo: {
    tiny: toRem(24),
    small: toRem(36),
    medium: toRem(54),
    base: toRem(82),
    large: toRem(124),
    xLarge: toRem(168),
  },

  /** x1.25  */
  avatar: {
    tiny: toRem(21.504),
    small: toRem(26.88),
    medium: toRem(33.6),
    base: toRem(42),
    large: toRem(52.5),
    xLarge: toRem(65.625),
  },

  container: {
    tiny: toRem(120),
    small: toRem(300),
    medium: toRem(540),
    base: toRem(720),
    large: toRem(960),
    xLarge: toRem(1140),
  },

  card: {
    tiny: toRem(120),
    small: toRem(300),
    medium: toRem(540),
    base: toRem(720),
    large: toRem(960),
    xLarge: toRem(1140),
  },

  popup: {
    tiny: toRem(120),
    small: toRem(300),
    medium: toRem(540),
    base: toRem(720),
    large: toRem(960),
    xLarge: toRem(1140),
  },

  grid: {
    tiny: toRem(120),
    small: toRem(300),
    medium: toRem(540),
    base: toRem(720),
    large: toRem(960),
    xLarge: toRem(1140),
  },

  list: {
    tiny: toRem(60),
    small: toRem(150),
    medium: toRem(270),
    base: toRem(360),
    large: toRem(480),
    xLarge: toRem(570),
  },

  switch: {
    small: toRem(18),
    base: toRem(24),
  },

  checkbox: {
    small: toRem(18),
    base: toRem(22),
  },

  panel: {
    tiny: '15vw',
    small: '25vw',
    medium: '35vw',
    base: '45vw',
    large: '55vw',
    xLarge: '65vw',
  },

  button: {
    small: toRem(30),
    base: toRem(40),
    large: toRem(70),
  },

  input: {
    small: toRem(30),
    base: toRem(40),
    large: toRem(70),
  },

  label: {
    small: toRem(24),
    base: toRem(28),
  },

  /** x1.5 */
  tooltip: {
    tiny: toRem(58),
    small: toRem(88),
    medium: toRem(132),
    base: toRem(198),
    large: toRem(297),
    xLarge: toRem(445.5),
  },

  scrollbar: {
    thumb: toRem(14),
    track: toRem(4),
  },

  timeline: {
    entry: toRem(10),
    offset: toRem(2),
    track: toRem(14),
  },

  radio: {
    small: toRem(18),
    base: toRem(22),
  },

  textarea: toRem(58),
  sidebar: '22vw',
}
