import { searchStoreProducts } from '@vori/dashboard-rest-next/products/products'
import { queryClientInstance } from './networking/queryClient'
import { GetState, GlobalStoreType, SetState } from './storeTypes'
import { StoreProductTypeaheadSearchResultDto } from '@vori/dashboard-rest-next/schemas'

export type ProductsSlice = {
  loading: boolean
  error: null | unknown
  canShowRecentSearches: boolean
  searchHistory: string[]
  productSearchResults: StoreProductTypeaheadSearchResultDto[]
  activeAbortController: null | AbortController
  searchProduct: (storeId: string | string[], query: string) => void
  clearSearchHistory: () => void
  clearSearchResults: (clearSearchHistory?: boolean) => void
}

export const createProductSlice = (
  set: SetState<GlobalStoreType>,
  get: GetState<GlobalStoreType>,
): ProductsSlice => ({
  loading: false,
  error: null,
  canShowRecentSearches: true,
  searchHistory: [],
  productSearchResults: [],
  activeAbortController: null,
  clearSearchHistory: () => {
    set(() => ({ searchHistory: [] }))
  },
  clearSearchResults: (clearSavedHistory = false) => {
    if (clearSavedHistory) {
      get().clearSearchHistory()
    }

    set(() => ({
      productSearchResults: [],
      canShowRecentSearches: true,
    }))
  },
  searchProduct: async (storeId, query) => {
    if (query.length === 0) {
      get().clearSearchResults()
      return
    }

    const prevController = get().activeAbortController
    set(() => ({ productSearchResults: [], canShowRecentSearches: false }))
    if (prevController) {
      prevController.abort()
    }

    const currentController = new AbortController()
    const upcomingHistory = new Set(get().searchHistory)
    upcomingHistory.add(query)

    set(() => ({
      activeAbortController: currentController,
      loading: true,
      error: null,
      searchHistory: Array.from(upcomingHistory),
    }))

    const storeIdParam = Array.isArray(storeId) ? storeId : [storeId]

    try {
      const { data } = await queryClientInstance.fetchQuery(
        ['product-serach', query],
        () =>
          searchStoreProducts(
            { store_ids: storeIdParam, query, take: 20 },
            { signal: currentController.signal },
          ),
      )

      set(() => ({
        loading: false,
        productSearchResults: data,
        activeAbortController: null,
      }))
    } catch (error) {
      // oportunity to send error to an error management slice
      set(() => ({ error, loading: false, activeAbortController: null }))
    }
  },
})
