import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlarmClockOffIconProps = IconProps

const AlarmClockOffIcon = forwardRef<SVGSVGElement, AlarmClockOffIconProps>(
  (props: AlarmClockOffIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.5 5.142a7.999 7.999 0 0 1 9.358 9.358m-1.722 3.634A8 8 0 1 1 6.862 6.869M4 4 2 6m20 0-3-3M6 19l-2 2m17 0L3 3"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlarmClockOffIcon.displayName = 'AlarmClockOffIcon'
AlarmClockOffIcon.defaultProps = iconDefaultProps

export type { AlarmClockOffIconProps }
export { AlarmClockOffIcon }
