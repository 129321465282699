import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type HourglassIconProps = IconProps

const HourglassIcon = forwardRef<SVGSVGElement, HourglassIconProps>(
  (props: HourglassIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <g clipPath="url(#a)" fill="none" stroke="none">
        <path
          fill="currentColor"
          d="M21.004 21.6h-.819a13.105 13.105 0 0 0-5.162-9.596A13.102 13.102 0 0 0 20.186 2.4h.818a1.2 1.2 0 0 0 0-2.4h-18a1.2 1.2 0 1 0 0 2.4h.809a13.104 13.104 0 0 0 5.165 9.604A13.097 13.097 0 0 0 3.813 21.6h-.809A1.198 1.198 0 0 0 1.8 22.8 1.201 1.201 0 0 0 3.004 24h18a1.2 1.2 0 0 0 0-2.4ZM7.82 21.597a.75.75 0 0 1-.53-1.28l4.185-4.19a.75.75 0 0 1 1.061 0l4.186 4.19a.75.75 0 0 1-.53 1.28H7.818Z"
          stroke="none"
        ></path>
      </g>
      <defs fill="none" stroke="none">
        <clipPath id="a" fill="none" stroke="none">
          <path fill="currentColor" d="M0 0h24v24H0z" stroke="none"></path>
        </clipPath>
      </defs>
    </Icon>
  ),
)

HourglassIcon.displayName = 'HourglassIcon'
HourglassIcon.defaultProps = iconDefaultProps

export type { HourglassIconProps }
export { HourglassIcon }
