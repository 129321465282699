import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  body,
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-font-variant-ligatures: none;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    font-size: 100%;
    font-smoothing: antialiased;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    margin: 0;
    min-height: 100vh;
    padding: 0;
  }

  *,
  *:after,
  *:before {
    color: inherit;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    box-sizing: inherit;
  }

  [hidden], template {
    && {
      display: none;
    }
  }

  dl, dt, dd {
    margin: 0;
  }

  /*
  * Disable WebKit's autofill color on input fields
  * Combination of techniques from:
  * https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  */
  @-webkit-keyframes autofill {
    to {
      background: transparent;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-transition-delay: 9999s;
    -webkit-transition-property: background-color, color;
  }

  /**
   * https://github.com/reach/reach-ui/blob/a0c7b3672835b2a9f84732663bb0e78992541211/website/src/pages/styling.mdx#skip-including-styles
   */
  :root {
    --reach-accordion: 1;
    --reach-checkbox: 1;
    --reach-combobox: 1;
    --reach-dialog: 1;
    --reach-listbox: 1;
    --reach-menu-button: 1;
    --reach-skip-nav: 1;
    --reach-slider: 1;
    --reach-tabs: 1;
    --reach-tooltip: 1;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }
`

export default GlobalStyles
