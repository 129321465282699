import React from 'react'
import { Inbox } from '@trycourier/react-inbox'

import { Bell01Icon } from '@vori/gourmet-icons'

import { FeatureFlags } from '@vori/dashboard-constants'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import { useCourierToken } from './useCourierToken'

export function CourierInbox(): JSX.Element | null {
  const canSeeCourierInbox = useFeatureConfig(
    FeatureFlags.WEB_NOTIFICATIONS_INBOX,
  )

  const courierToken = useCourierToken()

  const canRenderCourierInbox = React.useMemo(() => {
    return canSeeCourierInbox && courierToken != null
  }, [canSeeCourierInbox, courierToken])

  if (!canRenderCourierInbox) {
    return null
  }

  return (
    <Inbox
      key="headerAction-notificationInbox"
      renderIcon={() => {
        return <Bell01Icon size="md" />
      }}
    />
  )
}
