import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserRight01IconProps = IconProps

const UserRight01Icon = forwardRef<SVGSVGElement, UserRight01IconProps>(
  (props: UserRight01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m19 21 3-3m0 0-3-3m3 3h-6m-4-2.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserRight01Icon.displayName = 'UserRight01Icon'
UserRight01Icon.defaultProps = iconDefaultProps

export type { UserRight01IconProps }
export { UserRight01Icon }
