import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ThumbsUpIconProps = IconProps

const ThumbsUpIcon = forwardRef<SVGSVGElement, ThumbsUpIconProps>(
  (props: ThumbsUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 22V11m-5 2v7a2 2 0 0 0 2 2h13.426a3 3 0 0 0 2.965-2.544l1.077-7A3 3 0 0 0 18.503 9H15a1 1 0 0 1-1-1V4.466A2.466 2.466 0 0 0 11.534 2a.822.822 0 0 0-.75.488l-3.52 7.918A1 1 0 0 1 6.35 11H4a2 2 0 0 0-2 2Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ThumbsUpIcon.displayName = 'ThumbsUpIcon'
ThumbsUpIcon.defaultProps = iconDefaultProps

export type { ThumbsUpIconProps }
export { ThumbsUpIcon }
