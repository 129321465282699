import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Microphone01IconProps = IconProps

const Microphone01Icon = forwardRef<SVGSVGElement, Microphone01IconProps>(
  (props: Microphone01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 10v2a7 7 0 0 1-7 7m-7-9v2a7 7 0 0 0 7 7m0 0v3m-4 0h8m-4-7a3 3 0 0 1-3-3V5a3 3 0 1 1 6 0v7a3 3 0 0 1-3 3Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Microphone01Icon.displayName = 'Microphone01Icon'
Microphone01Icon.defaultProps = iconDefaultProps

export type { Microphone01IconProps }
export { Microphone01Icon }
