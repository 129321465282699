import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Pilcrow02IconProps = IconProps

const Pilcrow02Icon = forwardRef<SVGSVGElement, Pilcrow02IconProps>(
  (props: Pilcrow02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.5 4v16m2-16H9a4 4 0 1 0 0 8h5m0-8v16m-2 0h7.5"
        fill="none"
      ></path>
    </Icon>
  ),
)

Pilcrow02Icon.displayName = 'Pilcrow02Icon'
Pilcrow02Icon.defaultProps = iconDefaultProps

export type { Pilcrow02IconProps }
export { Pilcrow02Icon }
