import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ColorsIconProps = IconProps

const ColorsIcon = forwardRef<SVGSVGElement, ColorsIconProps>(
  (props: ColorsIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20.472a6 6 0 1 0 5.58-10.262m-11.16 0a6 6 0 1 0 7.16 3.58M18 8A6 6 0 1 1 6 8a6 6 0 0 1 12 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ColorsIcon.displayName = 'ColorsIcon'
ColorsIcon.defaultProps = iconDefaultProps

export type { ColorsIconProps }
export { ColorsIcon }
