import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { SearchSmIcon } from '@vori/gourmet-icons'

import { Input, InputProps } from '../InputNext'

type SearchInputProps = InputProps & { fullWidth?: boolean }

const defaultProps: Partial<SearchInputProps> = {
  fullWidth: false,
}

const StyledSearchInput = styled(
  forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
    { className, fullWidth, ...props }: SearchInputProps,
    ref,
  ) {
    return (
      <Input
        {...props}
        className={className}
        data-gourmet-search-input=""
        fullWidth={fullWidth}
        leftIcon={<SearchSmIcon variant="secondary" />}
        ref={ref}
        type="search"
      />
    )
  }),
)``

StyledSearchInput.displayName = 'SearchInput'
StyledSearchInput.defaultProps = defaultProps

export type { SearchInputProps }
export { defaultProps as SearchInputDefaultProps }
export default StyledSearchInput
