import React from 'react'

import { ModalContent, ModalContentProps } from '../ModalNext'

type Props = ModalContentProps

/**
 * Provides basic styling for rendering the contents of a `<AlertModal>` component.
 *
 * @example
 * function ExampleAlertModal(): React.ReactElement {
 *   const { ref, ...alertModal } = useAlertModal()
 *
 *   return (
 *     <AlertModal ref={ref} {...alertModal}>
 *       <AlertModalContent>
 *         <Text>This is the alert modal content</Text>
 *       </AlertModalContent>
 *     </Modal>
 *   )
 * }
 */
const AlertModalContent = React.forwardRef<HTMLDivElement, Props>(
  function AlertModalContent(props: Props, ref): JSX.Element {
    return (
      <ModalContent
        size="container-25"
        {...props}
        ref={ref}
        data-gourmet-alert-modal-content=""
      />
    )
  },
)

AlertModalContent.displayName = 'AlertModalContent'
AlertModalContent.defaultProps = {}

export { AlertModalContent }
export type { Props as AlertModalContentProps }
