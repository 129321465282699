import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleUpIconProps = IconProps

const ArrowCircleUpIcon = forwardRef<SVGSVGElement, ArrowCircleUpIconProps>(
  (props: ArrowCircleUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 12-4-4m0 0-4 4m4-4v8m10-4c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowCircleUpIcon.displayName = 'ArrowCircleUpIcon'
ArrowCircleUpIcon.defaultProps = iconDefaultProps

export type { ArrowCircleUpIconProps }
export { ArrowCircleUpIcon }
