import { base } from '@vori/gourmet-components'
import { SearchSmIcon } from '@vori/gourmet-icons'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import ProductSearch from './ProductSearch'
import { GlobalModalSlice } from 'web/dashboard/src/state-management/GlobalModalStore'
import { useGlobalStore } from 'web/dashboard/src/state-management/store'

const StyledDivProps = {
  display: 'grid',
  gridTemplateColumns: '25px 1fr 1fr',
  minHeight: '1.5rem',
  maxHeight: '1.9rem',
  border: `1px solid ${base.colors.dark2}`,
  borderRadius: '0.5rem',
  color: base.colors.dark2,
  overflow: 'hidden',
  width: '100%',
  lineHeight: '21px',
  padding: '5px',
  paddingTop: '3px',
  marginTop: '-5px',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: `${base.colors.dark1}`,
    color: base.colors.light4,
  },
  svg: {
    marginTop: '1px',
  },
  div: {
    'text-align': 'right',
    'padding-right': '0.5rem',
  },
}

const BorderedTrigger = styled.div(StyledDivProps)

const ProductSearchTrigger = () => {
  const setAndDisplayContent = useGlobalStore(
    (state: GlobalModalSlice) => state.setAndDisplayContent,
  )

  const isSearchModalOpen = useGlobalStore(
    (state: GlobalModalSlice) => state.isOpen,
  )
  const handleTriggerProductSearchModal = useCallback(() => {
    if (isSearchModalOpen) {
      return
    }
    setAndDisplayContent(<ProductSearch />, true)
  }, [isSearchModalOpen, setAndDisplayContent])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
        event.preventDefault()
        handleTriggerProductSearchModal()
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleTriggerProductSearchModal])

  return (
    <BorderedTrigger
      key="product-search-trigger"
      onClick={handleTriggerProductSearchModal}
    >
      <SearchSmIcon /> <span>Search for Products</span>
      <div>&#8984;K</div>
    </BorderedTrigger>
  )
}

export default ProductSearchTrigger
