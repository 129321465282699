import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Power03IconProps = IconProps

const Power03Icon = forwardRef<SVGSVGElement, Power03IconProps>(
  (props: Power03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 7v10m10-5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Power03Icon.displayName = 'Power03Icon'
Power03Icon.defaultProps = iconDefaultProps

export type { Power03IconProps }
export { Power03Icon }
