import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BellRinging01IconProps = IconProps

const BellRinging01Icon = forwardRef<SVGSVGElement, BellRinging01IconProps>(
  (props: BellRinging01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.354 21A3.99 3.99 0 0 0 12 22c1.015 0 1.94-.378 2.646-1M2.294 5.82A4.007 4.007 0 0 1 4.326 2.3m17.376 3.52A4.007 4.007 0 0 0 19.67 2.3M18 8A6 6 0 1 0 6 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.088 1.936.014.182.053.252.2.36.133.099.73.099 1.927.099h13.222c1.197 0 1.795 0 1.927-.098.147-.11.186-.179.2-.361.014-.165-.353-.755-1.087-1.936C18.78 13.206 18 11.09 18 8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

BellRinging01Icon.displayName = 'BellRinging01Icon'
BellRinging01Icon.defaultProps = iconDefaultProps

export type { BellRinging01IconProps }
export { BellRinging01Icon }
