import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Power01IconProps = IconProps

const Power01Icon = forwardRef<SVGSVGElement, Power01IconProps>(
  (props: Power01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2v10m6.36-5.36a9 9 0 1 1-12.73 0"
        fill="none"
      ></path>
    </Icon>
  ),
)

Power01Icon.displayName = 'Power01Icon'
Power01Icon.defaultProps = iconDefaultProps

export type { Power01IconProps }
export { Power01Icon }
