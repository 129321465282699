import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MarkerPin01IconProps = IconProps

const MarkerPin01Icon = forwardRef<SVGSVGElement, MarkerPin01IconProps>(
  (props: MarkerPin01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c4-4 8-7.582 8-12a8 8 0 1 0-16 0c0 4.418 4 8 8 12Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MarkerPin01Icon.displayName = 'MarkerPin01Icon'
MarkerPin01Icon.defaultProps = iconDefaultProps

export type { MarkerPin01IconProps }
export { MarkerPin01Icon }
