import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowsRightIconProps = IconProps

const ArrowsRightIcon = forwardRef<SVGSVGElement, ArrowsRightIconProps>(
  (props: ArrowsRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 7h11m0 0-4 4m4-4-4-4M4 17h16m0 0-4 4m4-4-4-4"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowsRightIcon.displayName = 'ArrowsRightIcon'
ArrowsRightIcon.defaultProps = iconDefaultProps

export type { ArrowsRightIconProps }
export { ArrowsRightIcon }
