export function getFocusableElementsInsideFocusTrap<
  TElement extends HTMLElement,
>(focusTrapEl: TElement): NodeListOf<HTMLElement> {
  const specialNonFocusableElements = focusTrapEl.querySelectorAll(
    '[data-gourmet-radio-input-field] [data-gourmet-radio-input-input]',
  )

  return focusTrapEl.querySelectorAll<HTMLElement>(
    [
      '[tabindex]',
      '[contenteditable]:not([contenteditable="false"])',
      'a[href]',
      'audio[controls]',
      'button',
      'details > summary:first-of-type',
      'details',
      'input:not([type="hidden"])',
      'select',
      'textarea',
      'video[controls]',
    ]
      .map(
        (selector) =>
          `${selector}:not([disabled]):not([tabindex^="-"])${Array.from(
            specialNonFocusableElements,
          )
            .map(
              (specialNonFocusableElement) =>
                `:not(#${specialNonFocusableElement.id})`,
            )
            .join('')}`,
      )
      .join(','),
  )
}

export function focusOnFirstFocusableElementInsideFocusTrap<
  TElement extends HTMLElement,
>(focusTrapEl: TElement): HTMLElement | null {
  const firstFocusableElement = Array.from(
    getFocusableElementsInsideFocusTrap(focusTrapEl),
  )[0]

  if (firstFocusableElement != null) {
    return firstFocusableElement
  }

  return null
}

export function focusOnLastFocusableElementInsideFocusTrap<
  TElement extends HTMLElement,
>(focusTrapEl: TElement): HTMLElement | null {
  const lastFocusableElement = Array.from(
    getFocusableElementsInsideFocusTrap(focusTrapEl),
  ).slice(-1)[0]

  if (lastFocusableElement != null) {
    return lastFocusableElement
  }

  return null
}

export function focusOnNextFocusableElementInsideFocusTrap<
  TElement extends HTMLElement,
>(focusTrapEl: TElement): HTMLElement | null {
  let focusedElement = document.activeElement

  const focusableElements = Array.from(
    getFocusableElementsInsideFocusTrap(focusTrapEl),
  )

  if (focusableElements.length === 0) {
    return null
  }

  // Handles <RadioInputField> components, since that component does
  // internal focus management of its own.
  if (
    focusedElement instanceof HTMLInputElement &&
    focusedElement.dataset.gourmetRadioInputField
  ) {
    const dataGourmetRadioInputFocusTrigger =
      document.querySelector<HTMLInputElement>(
        `#${focusedElement.dataset.gourmetRadioInputField}`,
      )

    if (dataGourmetRadioInputFocusTrigger) {
      focusedElement = dataGourmetRadioInputFocusTrigger
    }
  }

  const focusedElementIndex = focusableElements.findIndex(
    (focusableElement) => focusableElement === focusedElement,
  )

  if (
    focusedElementIndex === -1 ||
    focusableElements.length === 1 ||
    focusedElement === focusableElements.slice(-1)[0]
  ) {
    return focusOnFirstFocusableElementInsideFocusTrap(focusTrapEl)
  } else {
    if (focusableElements[focusedElementIndex + 1] == null) {
      return focusOnFirstFocusableElementInsideFocusTrap(focusTrapEl)
    } else if (focusableElements[focusedElementIndex + 1] != null) {
      return focusableElements[focusedElementIndex + 1]
    } else {
      return focusOnFirstFocusableElementInsideFocusTrap(focusTrapEl)
    }
  }
}

export function focusOnPreviousFocusableElementInsideFocusTrap<
  TElement extends HTMLElement,
>(focusTrapEl: TElement): HTMLElement | null {
  let focusedElement = document.activeElement

  const focusableElements = Array.from(
    getFocusableElementsInsideFocusTrap(focusTrapEl),
  )

  if (focusableElements.length === 0) {
    return null
  }

  // Handles <RadioInputField> components, since that component does
  // internal focus management of its own.
  if (
    focusedElement instanceof HTMLInputElement &&
    focusedElement.dataset.gourmetRadioInputField
  ) {
    const dataGourmetRadioInputFocusTrigger =
      document.querySelector<HTMLInputElement>(
        `#${focusedElement.dataset.gourmetRadioInputField}`,
      )

    if (dataGourmetRadioInputFocusTrigger) {
      focusedElement = dataGourmetRadioInputFocusTrigger
    }
  }

  const focusedElementIndex = focusableElements.findIndex(
    (target) => target === focusedElement,
  )

  if (
    focusedElementIndex === -1 ||
    focusableElements.length === 1 ||
    focusedElement === focusableElements[0]
  ) {
    return focusOnLastFocusableElementInsideFocusTrap(focusTrapEl)
  } else {
    if (focusableElements[focusedElementIndex - 1] == null) {
      return focusOnFirstFocusableElementInsideFocusTrap(focusTrapEl)
    } else if (focusableElements[focusedElementIndex - 1] != null) {
      return focusableElements[focusedElementIndex - 1]
    } else {
      return focusOnFirstFocusableElementInsideFocusTrap(focusTrapEl)
    }
  }
}

export function isFocusableElementInsideFocusTrap<TElement extends HTMLElement>(
  focusTrapEl: TElement,
  target: EventTarget | null,
): boolean {
  const focusableElements = Array.from(
    getFocusableElementsInsideFocusTrap(focusTrapEl),
  )

  return Boolean(
    focusableElements.find((focusableElement) => focusableElement === target),
  )
}
