import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Glasses02IconProps = IconProps

const Glasses02Icon = forwardRef<SVGSVGElement, Glasses02IconProps>(
  (props: Glasses02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 14.535a4.008 4.008 0 0 1 4 0M2 15l.701-7.015c.027-.266.04-.399.06-.513A3 3 0 0 1 5.485 5.01C5.599 5 5.733 5 6 5m16 10-.701-7.015c-.027-.266-.04-.399-.06-.513a3 3 0 0 0-2.723-2.463C18.402 5 18.268 5 18 5m-9.172 7.172a4 4 0 1 1-5.656 5.656 4 4 0 0 1 5.656-5.656Zm12 0a4 4 0 1 1-5.656 5.656 4 4 0 0 1 5.656-5.656Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Glasses02Icon.displayName = 'Glasses02Icon'
Glasses02Icon.defaultProps = iconDefaultProps

export type { Glasses02IconProps }
export { Glasses02Icon }
