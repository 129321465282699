import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Divide02IconProps = IconProps

const Divide02Icon = forwardRef<SVGSVGElement, Divide02IconProps>(
  (props: Divide02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 12h16m-6-6a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm0 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Divide02Icon.displayName = 'Divide02Icon'
Divide02Icon.defaultProps = iconDefaultProps

export type { Divide02IconProps }
export { Divide02Icon }
