import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CodeCircle02IconProps = IconProps

const CodeCircle02Icon = forwardRef<SVGSVGElement, CodeCircle02IconProps>(
  (props: CodeCircle02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m14 17 3-3-3-3m-4-4-3 3 3 3m12-1c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CodeCircle02Icon.displayName = 'CodeCircle02Icon'
CodeCircle02Icon.defaultProps = iconDefaultProps

export type { CodeCircle02IconProps }
export { CodeCircle02Icon }
