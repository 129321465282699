import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Type01IconProps = IconProps

const Type01Icon = forwardRef<SVGSVGElement, Type01IconProps>(
  (props: Type01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 7c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C5.602 4 6.068 4 7 4h10c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C20 5.602 20 6.068 20 7M9 20h6M12 4v16"
        fill="none"
      ></path>
    </Icon>
  ),
)

Type01Icon.displayName = 'Type01Icon'
Type01Icon.defaultProps = iconDefaultProps

export type { Type01IconProps }
export { Type01Icon }
