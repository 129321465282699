import React, { forwardRef, Fragment } from 'react'
import styled from 'styled-components'
import { ChevronLeftIcon, ChevronRightIcon } from '@vori/gourmet-icons'

import { colors, sizing, spacing, typography } from '../tokens'
import Clickable, { ClickableProps } from '../Clickable'
import Flex from '../Flex'
import Spacer from '../Spacer'
import Text from '../Text'

import { CalendarDateOptions } from './useCalendar'
import Card, { CardProps } from '../Card'

type CalendarProps = React.HTMLAttributes<HTMLDivElement> &
  CardProps & {
    dates: CalendarDateOptions[][]
    month?: string
    onClickNext?: React.MouseEventHandler<HTMLButtonElement>
    onClickPrev?: React.MouseEventHandler<HTMLButtonElement>
    renderDate?: (date: CalendarDateOptions) => React.ReactNode
    showDateRange?: boolean
    year?: string
  }

const defaultProps: Partial<CalendarProps> = {
  ...Card.defaultProps,
  className: '',
  size: 'small',
}

const Navigation = styled(Flex)({
  backgroundColor: colors.calendar.navigation.backgroundColor,
  borderTopLeftRadius: sizing.radius.large,
  borderTopRightRadius: sizing.radius.large,
  margin: `0 -${spacing.card}`,
  padding: spacing.calendar.navigation,
  width: `calc(100% + (${spacing.card} * 2))`,
})

const NavigationTitle = styled(Text)({
  color: colors.calendar.navigationButton.color,
})

const NavigationButton = styled(Clickable)({
  color: colors.calendar.navigationButton.color,
  padding: spacing.calendar.navigationButton,

  '&:hover, &:active, &:focus': {
    color: colors.calendar.navigationButton.color,
  },
})

const CalendarDay = styled(Text)`
  color: ${colors.calendar.day.color};
  align-items: center;
  display: flex;
  height: ${sizing.calendarDate};
  justify-content: center;
  width: ${sizing.calendarDate};
`

type CalendarDateProps = React.HTMLAttributes<HTMLDivElement> &
  ClickableProps &
  Partial<CalendarDateOptions> & {
    isWithinRange?: boolean
  }

const StyledCalendarDate = styled(
  forwardRef<HTMLButtonElement, CalendarDateProps>(function CalendarDate(
    {
      className,
      date,
      isAfterToday,
      isBeforeToday,
      isDisabled,
      isFromCurrentMonth,
      isSelected,
      isToday,
      isWithinRange,
      removeDate,
      selectDate,
      toggleDate,
      ...props
    }: CalendarDateProps,
    ref,
  ) {
    return (
      <Clickable
        {...props}
        className={`${className} ${
          isWithinRange ? 'gourmet-calendar__date--within-range' : ''
        } ${isSelected ? 'gourmet-calendar__date--selected' : ''}`.trim()}
        ref={ref}
      />
    )
  }),
)<CalendarDateProps>`
  && {
    background-color: ${({ isWithinRange, isSelected }): string =>
      isSelected
        ? colors.calendar.date.active.backgroundColor
        : isWithinRange
          ? colors.calendar.date.hover.backgroundColor
          : colors.calendar.date.backgroundColor};
    border-radius: ${sizing.radius.small};
    color: ${({
      isDisabled,
      isFromCurrentMonth,
      isSelected,
      isToday,
      isWithinRange,
    }): string =>
      isDisabled
        ? colors.calendar.dateDisabled.color
        : isSelected
          ? colors.calendar.dateSelected.color
          : isToday
            ? colors.calendar.dateToday.color
            : isFromCurrentMonth || isWithinRange
              ? colors.calendar.dateFromCurrentMonth.color
              : colors.calendar.dateFromOtherMonth.color};
    font-size: ${typography.calendar.date.fontSize};
    font-weight: ${typography.calendar.date.fontWeight};
    line-height: ${typography.calendar.date.lineHeight};
    height: ${sizing.calendarDate};
    padding: 0;
    transition: none;
    width: ${sizing.calendarDate};

    :hover,
    :focus,
    :active,
    &[data-selected] {
      color: ${({
        isWithinRange,
        isDisabled,
        isFromCurrentMonth,
        isSelected,
        isToday,
      }): string =>
        isDisabled
          ? colors.calendar.dateDisabled.color
          : isSelected || isWithinRange
            ? colors.calendar.dateSelected.color
            : isToday
              ? colors.calendar.dateToday.color
              : isFromCurrentMonth
                ? colors.calendar.dateFromCurrentMonth.color
                : colors.calendar.dateFromOtherMonth.hover.color};

      background-color: ${({ isWithinRange, isSelected }): string =>
        isSelected || isWithinRange
          ? colors.calendar.date.active.backgroundColor
          : colors.calendar.date.hover.backgroundColor};
    }
  }
`

/**
 * @deprecated Use new `<Calendar>` component exported as `<CalendarNext>`
 */
const StyledCalendar = styled(
  forwardRef<HTMLDivElement, CalendarProps>(function Calendar(
    {
      children,
      dates,
      month,
      onClickNext,
      onClickPrev,
      renderDate,
      showDateRange,
      year,
      ...props
    }: CalendarProps,
    ref,
  ) {
    return (
      <Card {...props} column ref={ref}>
        <Navigation centerY fullWidth justifyContent="space-between">
          <NavigationButton
            iconOnly
            leftIcon={<ChevronLeftIcon />}
            onClick={onClickPrev || undefined}
            size="small"
          />
          <NavigationTitle size="body">
            {month} {year}
          </NavigationTitle>
          <NavigationButton
            iconOnly
            onClick={onClickNext || undefined}
            rightIcon={<ChevronRightIcon />}
            size="small"
          />
        </Navigation>
        <Spacer size="medium" />
        <Flex centerY fullWidth justifyContent="space-between">
          <CalendarDay align="center" size="body" variant="secondary">
            Su
          </CalendarDay>
          <CalendarDay align="center" size="body" variant="secondary">
            Mo
          </CalendarDay>
          <CalendarDay align="center" size="body" variant="secondary">
            Tu
          </CalendarDay>
          <CalendarDay align="center" size="body" variant="secondary">
            We
          </CalendarDay>
          <CalendarDay align="center" size="body" variant="secondary">
            Th
          </CalendarDay>
          <CalendarDay align="center" size="body" variant="secondary">
            Fr
          </CalendarDay>
          <CalendarDay align="center" size="body" variant="secondary">
            Sa
          </CalendarDay>
        </Flex>
        <Spacer size="tiny" />
        {dates.map((week, index) => (
          <Fragment key={`week-${index}`}>
            {index !== 0 && <Spacer size="tiny" />}
            <Flex
              className="gourmet-calendar__row"
              centerY
              fullWidth
              justifyContent="space-between"
            >
              {week.map(
                ({
                  date,
                  isDisabled,
                  isFromCurrentMonth,
                  isInSelectedRange,
                  isSelected,
                  isToday,
                  toggleDate,
                  ...rest
                }) => {
                  const currentDate = date.getDate()

                  return renderDate ? (
                    renderDate({
                      date,
                      isDisabled,
                      isFromCurrentMonth,
                      isInSelectedRange,
                      isSelected,
                      isToday,
                      toggleDate,
                      ...rest,
                    })
                  ) : (
                    <StyledCalendarDate
                      isWithinRange={showDateRange && isInSelectedRange}
                      disabled={isDisabled}
                      isDisabled={isDisabled}
                      isFromCurrentMonth={isFromCurrentMonth}
                      isSelected={isSelected}
                      isToday={isToday}
                      key={`day-${currentDate}`}
                      onClick={toggleDate}
                    >
                      {currentDate}
                    </StyledCalendarDate>
                  )
                },
              )}
            </Flex>
          </Fragment>
        ))}
        {children}
      </Card>
    )
  }),
)({
  paddingTop: 0,
})

StyledCalendar.displayName = 'Calendar'
StyledCalendar.defaultProps = defaultProps

export type { CalendarProps }

export {
  defaultProps as CalendarDefaultProps,
  StyledCalendarDate as CalendarDate,
}

export default StyledCalendar
