import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PlaneIconProps = IconProps

const PlaneIcon = forwardRef<SVGSVGElement, PlaneIconProps>(
  (props: PlaneIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.745 2.813a2.483 2.483 0 0 1 3.58 3.442l-2.78 2.91c-.217.228-.326.342-.393.475a.983.983 0 0 0-.104.38c-.01.148.025.302.096.61l1.728 7.487c.072.315.109.473.097.626a.999.999 0 0 1-.11.386c-.072.136-.186.25-.415.479l-.37.37c-.607.607-.91.91-1.22.965a1 1 0 0 1-.77-.182c-.253-.188-.389-.594-.66-1.408l-2.01-6.029-3.345 3.345c-.2.2-.3.3-.366.418a.997.997 0 0 0-.118.336c-.022.134-.006.274.025.555l.184 1.653c.03.28.046.42.025.554a.999.999 0 0 1-.118.337c-.067.117-.166.217-.366.417l-.198.198c-.473.473-.71.71-.972.778a1 1 0 0 1-.69-.069c-.244-.118-.43-.397-.8-.953L6.106 18.54a1.845 1.845 0 0 0-.138-.194.998.998 0 0 0-.111-.112c-.045-.038-.095-.071-.194-.138L3.31 16.528c-.556-.371-.835-.557-.954-.8a1 1 0 0 1-.068-.69c.069-.263.305-.5.778-.973l.198-.197c.2-.2.3-.3.417-.366.104-.06.218-.1.336-.118.134-.022.274-.006.555.025l1.653.184c.281.03.421.046.555.025a.998.998 0 0 0 .336-.118c.118-.067.218-.166.417-.366l3.346-3.345-6.03-2.01c-.813-.271-1.22-.407-1.407-.66a1 1 0 0 1-.182-.77c.055-.31.358-.613.964-1.22l.37-.37c.23-.23.344-.344.48-.414a1 1 0 0 1 .386-.111c.153-.012.31.024.626.097l7.459 1.721c.31.072.465.108.615.097a1 1 0 0 0 .411-.122c.132-.072.242-.186.464-.415l2.71-2.799Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

PlaneIcon.displayName = 'PlaneIcon'
PlaneIcon.defaultProps = iconDefaultProps

export type { PlaneIconProps }
export { PlaneIcon }
