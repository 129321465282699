import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type HeartRoundedIconProps = IconProps

const HeartRoundedIcon = forwardRef<SVGSVGElement, HeartRoundedIconProps>(
  (props: HeartRoundedIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.111 3C19.633 3 22 6.353 22 9.48 22 15.814 12.178 21 12 21c-.178 0-10-5.186-10-11.52C2 6.352 4.367 3 7.889 3 9.91 3 11.233 4.024 12 4.924 12.767 4.024 14.089 3 16.111 3Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

HeartRoundedIcon.displayName = 'HeartRoundedIcon'
HeartRoundedIcon.defaultProps = iconDefaultProps

export type { HeartRoundedIconProps }
export { HeartRoundedIcon }
