import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Beaker01IconProps = IconProps

const Beaker01Icon = forwardRef<SVGSVGElement, Beaker01IconProps>(
  (props: Beaker01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 2v4.66c0 .218 0 .326-.033.413a.47.47 0 0 1-.138.198c-.07.06-.183.102-.409.185a7.5 7.5 0 1 0 5.16 0c-.226-.083-.339-.125-.409-.185a.469.469 0 0 1-.138-.198C14 6.986 14 6.878 14 6.66V2M8.5 2h7"
        fill="none"
      ></path>
    </Icon>
  ),
)

Beaker01Icon.displayName = 'Beaker01Icon'
Beaker01Icon.defaultProps = iconDefaultProps

export type { Beaker01IconProps }
export { Beaker01Icon }
