import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyYenCircleIconProps = IconProps

const CurrencyYenCircleIcon = forwardRef<
  SVGSVGElement,
  CurrencyYenCircleIconProps
>((props: CurrencyYenCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 18v-6m0 0 4-5m-4 5L8 7m8 5H8m7.5 3h-7M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

CurrencyYenCircleIcon.displayName = 'CurrencyYenCircleIcon'
CurrencyYenCircleIcon.defaultProps = iconDefaultProps

export type { CurrencyYenCircleIconProps }
export { CurrencyYenCircleIcon }
