import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Headphones02IconProps = IconProps

const Headphones02Icon = forwardRef<SVGSVGElement, Headphones02IconProps>(
  (props: Headphones02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 17v-4c0-5.523-4.477-10-10-10S2 7.477 2 13v4m5.5 4A2.5 2.5 0 0 1 5 18.5v-3a2.5 2.5 0 0 1 5 0v3A2.5 2.5 0 0 1 7.5 21Zm9 0a2.5 2.5 0 0 1-2.5-2.5v-3a2.5 2.5 0 0 1 5 0v3a2.5 2.5 0 0 1-2.5 2.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Headphones02Icon.displayName = 'Headphones02Icon'
Headphones02Icon.defaultProps = iconDefaultProps

export type { Headphones02IconProps }
export { Headphones02Icon }
