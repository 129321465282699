import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Edit03IconProps = IconProps

const Edit03Icon = forwardRef<SVGSVGElement, Edit03IconProps>(
  (props: Edit03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20h9M3 20h1.675c.489 0 .733 0 .964-.055.204-.05.399-.13.578-.24.201-.123.374-.296.72-.642L19.5 6.5a2.121 2.121 0 0 0-3-3L3.937 16.063c-.346.346-.519.519-.642.72a2 2 0 0 0-.24.578c-.055.23-.055.475-.055.965V20Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Edit03Icon.displayName = 'Edit03Icon'
Edit03Icon.defaultProps = iconDefaultProps

export type { Edit03IconProps }
export { Edit03Icon }
