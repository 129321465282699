import React from 'react'
import styled, { CSSObject } from 'styled-components'

import Text from '../Text'
// import Spacer from '../Spacer'

import { toTransitions } from '../utils'

function styles(): CSSObject {
  return {
    color: '#4F4F4F',
    transition: toTransitions(['color'], 'ease'),
  }
}

const TextContainer = styled(Text)(styles)

type Props = React.HTMLAttributes<HTMLHeadingElement>

const SegmentedProgressBarSegmentLabel = React.forwardRef<
  HTMLHeadingElement,
  React.PropsWithChildren<Props>
>(function SegmentedProgressBarSegmentLabel(
  { children, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  return (
    <TextContainer
      {...props}
      data-gourmet-segmented-progressbar-segment-label
      ref={ref}
      role="presentation"
      size="h5"
    >
      {children}
    </TextContainer>
  )
})

SegmentedProgressBarSegmentLabel.displayName =
  'SegmentedProgressBarSegmentLabel'
SegmentedProgressBarSegmentLabel.defaultProps = {}

export {
  SegmentedProgressBarSegmentLabel,
  styles as SegmentedProgressBarSegmentLabelStyles,
}

export type { Props as SegmentedProgressBarSegmentLabelProps }
