export enum InvoiceStatus {
  PROCESSING = 'PROCESSING',
  READY_FOR_RECEIVING = 'READY_FOR_RECEIVING',
  RECEIVED = 'RECEIVED',
  APPROVED = 'APPROVED',
  DONE = 'DONE',
}

export enum InvoiceCreationViewMode {
  CHOOSE = 'CHOOSE',
  MANUAL = 'MANUAL',
}
