import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Cloud02IconProps = IconProps

const Cloud02Icon = forwardRef<SVGSVGElement, Cloud02IconProps>(
  (props: Cloud02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 19a4 4 0 0 1-.999-7.874L5 11a7 7 0 0 1 13.96-.758A4.502 4.502 0 0 1 17.5 19H6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Cloud02Icon.displayName = 'Cloud02Icon'
Cloud02Icon.defaultProps = iconDefaultProps

export type { Cloud02IconProps }
export { Cloud02Icon }
