import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCcw05IconProps = IconProps

const RefreshCcw05Icon = forwardRef<SVGSVGElement, RefreshCcw05IconProps>(
  (props: RefreshCcw05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.547 19.767A8.5 8.5 0 0 0 19.362 7.75l-.25-.433M4.638 16.25A8.5 8.5 0 0 1 15.453 4.233m-12.96 12.1 2.732.733.733-2.732m12.085-4.668.732-2.732 2.732.732"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCcw05Icon.displayName = 'RefreshCcw05Icon'
RefreshCcw05Icon.defaultProps = iconDefaultProps

export type { RefreshCcw05IconProps }
export { RefreshCcw05Icon }
