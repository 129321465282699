import React from 'react'

import {
  getSessionStorageRecord,
  updateSessionStorageRecord,
} from '@vori/dashboard-utils'

export type GlobalHTTPHeaders = {
  'selected-store-sql-id': string
}

export function getGlobalHTTPHeadersFromSessionStorage(): GlobalHTTPHeaders | null {
  return getSessionStorageRecord<GlobalHTTPHeaders>('http-headers')
}

/**
 * Provides a tuple consisting of a getter and setter for dealing with global
 * HTTP headers that are persisted through SessionStorage.
 */
export function useGlobalHTTPHeaders(): [
  GlobalHTTPHeaders | null,
  (headers: GlobalHTTPHeaders) => void,
] {
  const [headers, setValue] = React.useState<GlobalHTTPHeaders | null>(
    getGlobalHTTPHeadersFromSessionStorage(),
  )

  const setHeaders = React.useCallback((headers: GlobalHTTPHeaders) => {
    updateSessionStorageRecord('http-headers', headers)
    setValue(getGlobalHTTPHeadersFromSessionStorage())
  }, [])

  return React.useMemo(() => [headers, setHeaders], [headers, setHeaders])
}
