import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Flex, { FlexProps } from '../Flex'

import toTransitions from '../utils/toTransitions'

type AspectRatioProps = React.HTMLAttributes<HTMLDivElement> &
  FlexProps & {
    ratio?: string
  }

const defaultProps: Partial<AspectRatioProps> = {
  className: '',
  ratio: '1:1',
}

const StyledAspectRatio = styled(
  forwardRef<HTMLDivElement, AspectRatioProps>(function AspectRatio(
    { ratio, ...props }: AspectRatioProps,
    ref,
  ) {
    return <Flex {...props} ref={ref} />
  }),
)<AspectRatioProps>`
  position: relative;

  ::before {
    content: '';
    display: block;
    padding-bottom: calc(
      100% /
        (
          ${({ ratio = defaultProps.ratio }) =>
            ratio ? ratio.replace(':', '/') : 1}
        )
    );

    transition: ${toTransitions(['padding-bottom'], 'ease')};
  }

  > img {
    height: auto;
  }
`

StyledAspectRatio.displayName = 'AspectRatio'
StyledAspectRatio.defaultProps = defaultProps

export type { AspectRatioProps }
export { defaultProps as AspectRatioDefaultProps }
export default StyledAspectRatio
