import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CommandIconProps = IconProps

const CommandIcon = forwardRef<SVGSVGElement, CommandIconProps>(
  (props: CommandIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 9V6a3 3 0 1 0-3 3h3Zm0 0v6m0-6h6m-6 6v3a3 3 0 1 1-3-3h3Zm0 0h6m0 0h3a3 3 0 1 1-3 3v-3Zm0 0V9m0 0V6a3 3 0 1 1 3 3h-3Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CommandIcon.displayName = 'CommandIcon'
CommandIcon.defaultProps = iconDefaultProps

export type { CommandIconProps }
export { CommandIcon }
