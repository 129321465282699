import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MusicIconProps = IconProps

const MusicIcon = forwardRef<SVGSVGElement, MusicIconProps>(
  (props: MusicIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.5 18V5.589c0-.857 0-1.286.18-1.544a1 1 0 0 1 .674-.416c.312-.046.695.145 1.462.529L20.5 6m-6 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-8-8V4m-3 3h6"
        fill="none"
      ></path>
    </Icon>
  ),
)

MusicIcon.displayName = 'MusicIcon'
MusicIcon.defaultProps = iconDefaultProps

export type { MusicIconProps }
export { MusicIcon }
