import { CurrentUserMetadata } from '@vori/dashboard-hooks/useCurrentUser'
import { GlobalStoreType, SetState } from './storeTypes'

type UserActions = {
  setUserMetadata: (incomingMetadata: CurrentUserMetadata) => void
}

export type UserSlice = {
  metadata: CurrentUserMetadata | null
} & UserActions

export const createUserSlice = (set: SetState<GlobalStoreType>): UserSlice => ({
  metadata: null,
  setUserMetadata: (incomingMetadata: CurrentUserMetadata) => {
    // add an event loop delay to ensure the state is not being updated while the app is being setup
    // this is an affordance because of the useContext update happening at startup
    // eventually all user setup will be handled here and this will not be needed
    // TODO: https://linear.app/vori/issue/VOR-9396
    setTimeout(() => {
      set(() => ({
        metadata: incomingMetadata,
      }))
    }, 0)
  },
})
