import React from 'react'
import styled from 'styled-components'

import { FlexCoreProps, FlexProps } from './types'
import { flexStyles } from './styles'
import { propsToStyledProps } from '../utils'

const StyledFlex = styled.div(flexStyles)

const defaultProps: Partial<FlexCoreProps> = {}

/**
 * The `<Flex>` component exposes CSS properties of the flexible box layout
 * module, e.g. `align-items`, `flex-shrink`, etc., as React props. Use this
 * component to build one-dimensional (either horizontal or vertical) layouts.
 *
 * @example
 * <Flex direction="column" fullWidth>
 *   <Flex>Row 1</Flex>
 *   <Flex>Row 2</Flex>
 * </Flex>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_flexible_box_layout}
 */
const Flex = React.forwardRef<HTMLDivElement, FlexProps>(function Flex(
  {
    alignItems,
    alignSelf,
    basis,
    center,
    centerX,
    centerY,
    children,
    columnGap,
    columnOffset,
    direction,
    flex,
    fullHeight,
    fullWidth,
    gap,
    grow,
    justifyContent,
    offset,
    rowGap,
    rowOffset,
    shrink,
    size,
    wrap,
    ...props
  }: FlexProps,
  ref,
): JSX.Element {
  return (
    <StyledFlex
      {...propsToStyledProps({
        alignItems,
        alignSelf,
        basis,
        center,
        centerX,
        centerY,
        columnGap,
        columnOffset,
        direction,
        flex,
        fullHeight,
        fullWidth,
        gap,
        grow,
        justifyContent,
        offset,
        rowGap,
        rowOffset,
        shrink,
        size,
        wrap,
      })}
      {...props}
      data-gourmet-flex=""
      ref={ref}
    >
      {children}
    </StyledFlex>
  )
})

Flex.displayName = 'Flex'
Flex.defaultProps = defaultProps

export { Flex, defaultProps as flexDefaultProps }
