/* tslint:disable */
/* eslint-disable */
/**
 * Vori API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LoyaltyBonusType = {
    Anniversary: 'anniversary',
    Birthday: 'birthday',
    Signup: 'signup'
} as const;
export type LoyaltyBonusType = typeof LoyaltyBonusType[keyof typeof LoyaltyBonusType];


export function LoyaltyBonusTypeFromJSON(json: any): LoyaltyBonusType {
    return LoyaltyBonusTypeFromJSONTyped(json, false);
}

export function LoyaltyBonusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyBonusType {
    return json as LoyaltyBonusType;
}

export function LoyaltyBonusTypeToJSON(value?: LoyaltyBonusType | null): any {
    return value as any;
}

