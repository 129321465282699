import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Thermometer01IconProps = IconProps

const Thermometer01Icon = forwardRef<SVGSVGElement, Thermometer01IconProps>(
  (props: Thermometer01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.5 4.5a2.5 2.5 0 0 0-5 0v9.258a4.5 4.5 0 1 0 5 0V4.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Thermometer01Icon.displayName = 'Thermometer01Icon'
Thermometer01Icon.defaultProps = iconDefaultProps

export type { Thermometer01IconProps }
export { Thermometer01Icon }
