import { toRem } from '../utils'

const TagTokens = {
  color: {
    root: {
      backgroundColor: '#FFFFFF',
      borderColor: '#D1D1D6',
      color: '#3F3F46',
    },
  },

  typography: {
    root: {
      small: {
        fontSize: toRem(12),
        fontWeight: 500,
        lineHeight: 1.15,
      },
      medium: {
        fontSize: toRem(14),
        fontWeight: 500,
        lineHeight: 1.15,
      },
      large: {
        fontSize: toRem(16),
        fontWeight: 500,
        lineHeight: 1.2,
      },
    },
  },

  spacing: {
    root: {
      small: {
        paddingLeft: toRem(8),
        paddingRight: toRem(8),
      },
      medium: {
        paddingLeft: toRem(10),
        paddingRight: toRem(10),
      },
      large: {
        paddingLeft: toRem(10),
        paddingRight: toRem(10),
      },
    },

    contentLeft: {
      marginRight: toRem(6),
    },

    contentRight: {
      marginLeft: toRem(6),
    },
  },

  sizing: {
    root: {
      borderRadius: toRem(6),

      small: {
        minHeight: toRem(24),
      },
      medium: {
        minHeight: toRem(24),
      },
      large: {
        minHeight: toRem(28),
      },
    },
  },
} as const

export { TagTokens }
