import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Speaker02IconProps = IconProps

const Speaker02Icon = forwardRef<SVGSVGElement, Speaker02IconProps>(
  (props: Speaker02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 12h.01M18 6h.01M6 6h.01M18 18h.01M6 18h.01m.79 4h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 19.72 22 18.88 22 17.2V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 2 18.88 2 17.2 2H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 4.28 2 5.12 2 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 22 5.12 22 6.8 22Zm5.7-10a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm4.5 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Speaker02Icon.displayName = 'Speaker02Icon'
Speaker02Icon.defaultProps = iconDefaultProps

export type { Speaker02IconProps }
export { Speaker02Icon }
