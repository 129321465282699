import React from 'react'
import { useEventListener } from '@vori/react-hooks'

import { KEY_TAB } from './constants'

import {
  focusOnFirstFocusableElementInsideFocusTrap,
  focusOnNextFocusableElementInsideFocusTrap,
  focusOnPreviousFocusableElementInsideFocusTrap,
  getFocusableElementsInsideFocusTrap,
} from './utils'

type Props = {
  /**
   * If `true`, the first focusable element inside the `<FocusTrap>` component
   * will be focused when mounted.
   */
  autoFocus?: boolean
  /**
   * Uses the children-as-function technique to expose a ref callback to set the
   * DOM node that will become the focus trap and other minor props.
   *
   * @see {@link https://react.dev/reference/react-dom/components/common#ref-callback}
   */
  children: (
    /**
     * ref callback to set the DOM node that will become the focus trap
     */
    focusTrapRef: React.RefCallback<HTMLElement>,
    /**
     * HTML attributes to pass to the focus trap DOM node.
     */
    focusTrapProps: React.HTMLAttributes<HTMLElement> & {
      [dataAttribute: `data-${string}`]: string
    },
  ) => React.ReactNode
  /**
   * Disables the `<FocusTrap>`, making it possible to focus on elements outside
   * of it.
   */
  disabled?: boolean
}

/**
 * Traps focus within a DOM node, so that when a user hits `Tab`, `Shift+Tab` or
 * clicks around, they can't escape the cycle of focusable elements.
 *
 * Tab:
 * - Moves focus to the next tabbable element inside the focus trap.
 * - If focus is on the last tabbable element inside the focus trap, moves focus
 * to the first tabbable element inside the focus trap.
 *
 * Shift + Tab:
 * - Moves focus to the previous tabbable element inside the focus trap.
 * - If focus is on the first tabbable element inside the focus trap, moves focus
 * to the last tabbable element inside the focus trap.
 *
 * @example
 * <Flex centerY fullWidth>
 *   <Button>No Focus Here</Button>
 *   <Spacer inline />
 *   <FocusTrap>
 *     {(focusTrapRef, focusTrapProps) => (
 *       <Flex ref={focusTrapRef} {...focusTrapProps}>
 *         <Button>A</Button>
 *         <Spacer inline />
 *         <Button>B</Button>
 *         <Spacer inline />
 *         <Button disabled>C (No focus here)</Button>
 *         <Spacer inline />
 *         <Button>D</Button>
 *         <Spacer inline />
 *         <Button tabIndex={-1}>E (No focus here)</Button>
 *       </Flex>
 *     )}
 *   </FocusTrap>
 * </Flex>
 */
function FocusTrap({ autoFocus, children, disabled }: Props): JSX.Element {
  const hasAutoFocusedRef = React.useRef(false)
  const [shouldFocus, setShouldFocus] = React.useState(false)

  const [focusTrapElement, setFocusTrapElement] =
    React.useState<HTMLElement | null>(null)

  const [elementWithFocus, setElementWithFocus] =
    React.useState<HTMLElement | null>(null)

  const [originalElementWithFocus, setOriginalElementWithFocus] =
    React.useState<HTMLElement | null>(null)

  const onKeyDown = React.useCallback<(event: KeyboardEvent) => void>(
    (event) => {
      if (!focusTrapElement || disabled) {
        return
      }

      if (event.key === KEY_TAB) {
        event.preventDefault()

        if (event.shiftKey) {
          const nextElementToFocusOn =
            focusOnPreviousFocusableElementInsideFocusTrap(focusTrapElement)
          setElementWithFocus(nextElementToFocusOn)
          setShouldFocus(Boolean(nextElementToFocusOn))
        } else {
          const nextElementToFocusOn =
            focusOnNextFocusableElementInsideFocusTrap(focusTrapElement)
          setElementWithFocus(nextElementToFocusOn)
          setShouldFocus(Boolean(nextElementToFocusOn))
        }
      }
    },
    [disabled, focusTrapElement, setShouldFocus],
  )

  const onFocus = React.useCallback<(event: FocusEvent) => void>(
    (event) => {
      if (!focusTrapElement || disabled) {
        return
      }

      const focusableElements = Array.from(
        getFocusableElementsInsideFocusTrap(focusTrapElement),
      )

      if (
        focusableElements.find(
          (focusableElement) =>
            focusableElement === event.target ||
            (event.target instanceof HTMLInputElement &&
              Boolean(event.target.dataset.gourmetRadioInputField)),
        ) === undefined
      ) {
        event.preventDefault()
      }
    },
    [disabled, focusTrapElement],
  )

  const restoreFocus = React.useCallback(() => {
    if (elementWithFocus != null) {
      elementWithFocus.blur()
    }

    if (originalElementWithFocus !== null) {
      originalElementWithFocus.focus()
    }
  }, [elementWithFocus, originalElementWithFocus])

  const setFocusTrapRef = React.useCallback<React.RefCallback<HTMLElement>>(
    (element) => {
      setFocusTrapElement(element)
    },
    [],
  )

  useEventListener('focus', onFocus)
  useEventListener('focusin', onFocus)
  useEventListener('keydown', onKeyDown)

  React.useEffect(() => {
    if (disabled) {
      return restoreFocus()
    }

    if (!originalElementWithFocus) {
      setOriginalElementWithFocus(
        document.activeElement ? (document.activeElement as HTMLElement) : null,
      )
    }

    if (autoFocus && focusTrapElement && !hasAutoFocusedRef.current) {
      hasAutoFocusedRef.current = true

      setElementWithFocus(
        focusOnFirstFocusableElementInsideFocusTrap(focusTrapElement),
      )

      setShouldFocus(true)
    }

    return () => {
      restoreFocus()
    }
  }, [
    autoFocus,
    disabled,
    elementWithFocus,
    focusTrapElement,
    onFocus,
    onKeyDown,
    originalElementWithFocus,
    restoreFocus,
    setShouldFocus,
  ])

  React.useEffect(() => {
    if (
      elementWithFocus !== null &&
      document.activeElement !== elementWithFocus &&
      shouldFocus
    ) {
      setShouldFocus(false)
      elementWithFocus.focus()
    }
  }, [elementWithFocus, setShouldFocus, shouldFocus])

  return (
    <>
      {children(setFocusTrapRef, {
        'data-gourmet-focus-trap': '',
        'data-gourmet-focus-trap-enabled': `${!disabled}`,
      })}
    </>
  )
}

FocusTrap.displayName = 'FocusTrap'
FocusTrap.defaultProps = {}

export { FocusTrap }
export type { Props as FocusTrapProps }
