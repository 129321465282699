import { compact } from 'lodash'
import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { FormFieldLayout } from '../FormFieldLayout'
import { Input, InputProps } from '../InputNext'
import { VisuallyHidden } from '../VisuallyHidden'
import Flex from '../Flex'

import { toRem } from '../utils'
import { useControlledState } from '../hooks'

function styles(): CSSObject {
  return {
    '[data-gourmet-contextual-input-contextual-text]': {
      backgroundColor: '#FFFFFF',
      borderColor: '#D1D1D6',
      borderRadius: toRem(8),
      borderStyle: 'solid',
      borderWidth: 1,
      color: '#70707B',
      cursor: 'default',
      fontSize: toRem(16),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: toRem(24),
      minHeight: toRem(40),
      paddingBottom: toRem(8),
      paddingLeft: toRem(32),
      paddingRight: toRem(32),
      paddingTop: toRem(8),
      textAlign: 'center',
    },
  }
}

const StyledContextualInput = styled(FormFieldLayout)(styles)

type Props = InputProps & {
  /**
   * The contextual text you want to associate with the user input.
   */
  contextualText: string
  /**
   * Used when the provided contextual text is not enough to inform users using
   * assistive technologies, e.g. When you want to display "lbs" but want to inform
   * users that the user input relates to "pounds".
   */
  contextualTextMeaning?: string
  /**
   * When provided, the text contained by the label identified though this
   * prop (`labelID`), will be prepended to the generated label for the input.
   * Thus, the generated label for the input will be composed of:
   * "label text + input value + contextual text"
   */
  labelID?: string
}

/**
 * Provides meaningful labeling that consists of more than one label string, by
 * using `aria-labelledby` to concatenate a label from several text nodes.
 *
 * @example
 * <FormField
 *   description="If a member has not shopped in some number of days, send them a reward to get them back in the store."
 *   label="Nudge valid for"
 * >
 *   <FormFieldInput>
 *     {(inputProps, labelID) => (
 *       <ContextualInput
 *         {...inputProps}
 *         contextualText="days"
 *         labelID={labelID}
 *       />
 *     )}
 *   </FormFieldInput>
 * </FormField>
 *
 * @see {@link https://www.w3.org/TR/WCAG20-TECHS/ARIA9.html}
 */
const ContextualInput = React.forwardRef<HTMLInputElement, Props>(
  function ContextualInput(
    {
      contextualText,
      contextualTextMeaning,
      id: controlledInputID,
      labelID,
      ...props
    }: Props,
    ref,
  ) {
    const [defaultInputID] = useClientId('gourmet-contextual-input')

    const [contextualTextMeaningID] = useClientId(
      'gourmet-contextual-text-meaning',
    )

    const [contextualTextID] = useClientId(
      'gourmet-contextual-input-contextual-text',
    )

    const [inputID] = useControlledState({
      componentName: 'FormField',
      controlledValue: controlledInputID,
      defaultValue: defaultInputID,
    })

    return (
      <>
        <StyledContextualInput
          data-gourmet-contextual-input=""
          fullWidth={props.fullWidth}
        >
          <Input
            {...props}
            aria-labelledby={compact([
              labelID,
              inputID,
              contextualTextMeaningID || contextualTextID,
            ]).join(' ')}
            data-gourmet-contextual-input-input=""
            fullWidth
            id={inputID}
            ref={ref}
          />
          <Flex
            aria-hidden="true"
            center
            data-gourmet-contextual-input-contextual-text=""
            id={contextualTextID}
            inline
            tabIndex={-1}
          >
            {contextualText}
          </Flex>
        </StyledContextualInput>
        {contextualTextMeaning && (
          <VisuallyHidden aria-hidden="true" id={contextualTextMeaningID}>
            {contextualTextMeaning}
          </VisuallyHidden>
        )}
      </>
    )
  },
)

ContextualInput.displayName = 'ContextualInput'
ContextualInput.defaultProps = {}

export type { Props as ContextualInputProps }
export { ContextualInput, styles as ContextualInputStyles }
