import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Thermometer03IconProps = IconProps

const Thermometer03Icon = forwardRef<SVGSVGElement, Thermometer03IconProps>(
  (props: Thermometer03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 3h-6m6 4h-6m6 4h-6m-9.5 2.758V4.5a2.5 2.5 0 0 1 5 0v9.258a4.5 4.5 0 1 1-5 0ZM9 17.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Thermometer03Icon.displayName = 'Thermometer03Icon'
Thermometer03Icon.defaultProps = iconDefaultProps

export type { Thermometer03IconProps }
export { Thermometer03Icon }
