import React from 'react'

/**
 * Returns a new `Set` object based on the given `originalSet` and updated
 * with the given `values`.
 *
 * @example
 * const someSet = new Set<string>(['hello'])
 * const newAndUpdatedSet = addValuesToSet(someSet, 'world')
 * console.log(setToArray(newAndUpdatedSet)) // ['hello', 'world']
 */
export function addValuesToSet<T>(
  originalSet: Set<T>,
  values: T | Array<T>,
): Set<T> {
  const newSet = new Set(originalSet)
  const valuesArray = Array.isArray(values) ? values : [values]

  valuesArray.forEach((value) => {
    newSet.add(value)
  })

  return newSet
}

/**
 * Returns a React `useState` callback function that returns a new new `Set`
 * object based on the given `originalSet` and updated with the given `values`.
 *
 * @example
 * const [productIDs, setProductIDs] = useState<Set<string>>(new Set())
 * setProductIDs(addValuesToSetInState('abc123'))
 * console.log(setToArray(productIDs)) // ['abc123']
 */
export function addValuesToSetInState<T>(
  values: T | Array<T>,
): React.SetStateAction<Set<T>> {
  return (previousState) => addValuesToSet(previousState, values)
}

/**
 * Returns a new `Set` object based on the given `originalSet` and without the
 * given `values`.
 *
 * @example
 * const someSet = new Set<string>(['hello', 'world'])
 * const newAndUpdatedSet = removeValuesFromSet(someSet, 'hello')
 * console.log(setToArray(newAndUpdatedSet)) // ['world']
 */
export function removeValuesFromSet<T>(
  originalSet: Set<T>,
  values: T | Array<T>,
): Set<T> {
  const newSet = new Set(originalSet)
  const valuesArray = Array.isArray(values) ? values : [values]

  valuesArray.forEach((value) => {
    newSet.delete(value)
  })

  return newSet
}

/**
 * Returns a React `useState` callback function that returns a new new `Set`
 * object based on the given `originalSet` and without the given `values`.
 *
 * @example
 * const [productIDs, setProductIDs] = useState<Set<string>>(new Set(['hello', 'world']))
 * setProductIDs(removeValuesFromSetInState('world'))
 * console.log(setToArray(productIDs)) // ['hello']
 */
export function removeValuesFromSetInState<T>(
  values: T | Array<T>,
): React.SetStateAction<Set<T>> {
  return (previousState) => removeValuesFromSet(previousState, values)
}

/**
 * Returns an array based on the values from the given `set`.
 *
 * @example
 * const someSet = new Set<string>(['hello'])
 * console.log(setToArray(someSet)) // ['hello']
 */
export function setToArray<T>(set: Set<T>): Array<T> {
  return Array.from(set.values())
}

/**
 * Returns an array based on the values from the given `set`.
 *
 * @example
 * const someArray = ['hello']
 * console.log(arrayToSet(someArray)) // Set {"hello"}
 */
export function arrayToSet<T>(array: Array<T>): Set<T> {
  return new Set(array)
}

/**
 * Returns a mapped array based on the values from the given `set`.
 *
 * @example
 * const someSet = new Set<string>(['hello', 'world'])
 * console.log(mapSet(someSet, (value, index) => `${value} - ${index}`)) // ["hello - 0", "world - 1"]
 */
export function mapSet<T>(
  set: Set<T>,
  mapCallback: Parameters<Array<T>['map']>['0'],
): Array<T> {
  return setToArray(set).map(mapCallback) as Array<T>
}

/**
 * Returns `true` if the given sets have the same values.
 *
 * @example
 * areEqualSets(new Set<string>(['hello', 'world']), new Set<string>(['world', 'hello'])) // true
 */
export function areEqualSets<T>(setA: Set<T>, setB: Set<T>): boolean {
  return (
    setA.size === setB.size &&
    setToArray(setA).every((value) => setB.has(value))
  )
}
