import React from 'react'
import { setToArray } from '@vori/utils-set'

import { MultiSelect, MultiSelectInternals, MultiSelectProps } from '../Select'

type CategoryOption = { _category: string }

type Props<T = string> = Omit<MultiSelectProps<T>, 'options'> & {
  options: { [key in string]: MultiSelectProps<T>['options'] }
  showParentCategory?: boolean
  withoutNesting?: boolean
}

function isCategoryOption<T = string>(option: T): boolean {
  return (
    option != null && Boolean((option as unknown as CategoryOption)._category)
  )
}

function asCategoryOption<T = string>(option: T): CategoryOption {
  return option as unknown as CategoryOption
}

function NestedMultiSelect<T = string>({
  options,
  showParentCategory,
  withoutNesting,
  ...props
}: Props<T>): JSX.Element {
  const internalOptions = React.useMemo<Array<T>>(
    () =>
      Object.entries(options)
        .map((category) => [
          { _category: category[0] } as unknown as T,
          ...category[1],
        ])
        .flat(),
    [options],
  )

  const getOptionValue = React.useCallback<
    NonNullable<Props<T>['getOptionValue']>
  >(
    (option) => {
      if (isCategoryOption(option)) {
        return asCategoryOption(option)._category
      }

      return props.getOptionValue?.(option) || ''
    },
    [props],
  )

  const getOptionsForCategory = React.useCallback<
    (category: string) => Array<T>
  >((category) => options[category] || [], [options])

  const getCategoryForOption = React.useCallback<(option: T) => string | null>(
    (option) =>
      Object.entries(options).find(([, categoryOptions]) =>
        categoryOptions.map(getOptionValue).includes(getOptionValue(option)),
      )?.[0] || null,
    [getOptionValue, options],
  )

  const getOptionsWithoutCategory = React.useCallback<
    (options: Array<T>) => Array<T>
  >((options) => options.filter((option) => !isCategoryOption(option)), [])

  const removeCategoriesFromSelectedOptions = React.useCallback<
    (selectedOptions: Array<string>) => Array<string>
  >(
    (selectedOptions) =>
      selectedOptions.filter(
        (selectedOption) => options[selectedOption] == null,
      ),
    [options],
  )

  const removeSelectedCategoriesFromInternals = React.useCallback<
    (internals: MultiSelectInternals<T>) => MultiSelectInternals<T>
  >(
    (internals) => ({
      ...internals,
      originalOptionsSelected: getOptionsWithoutCategory(
        internals.originalOptionsSelected,
      ),
      state: {
        ...internals.state,
        selectedOptions: new Set(
          removeCategoriesFromSelectedOptions(
            Array.from(internals.state.selectedOptions),
          ),
        ),
      },
    }),
    [getOptionsWithoutCategory, removeCategoriesFromSelectedOptions],
  )

  const getLabelForTrigger = React.useCallback<
    NonNullable<Props<T>['getLabelForTrigger']>
  >(
    (currentValue, internals) => {
      return (
        props.getLabelForTrigger?.(
          currentValue,
          removeSelectedCategoriesFromInternals(internals),
        ) ||
        internals.state.label ||
        internals.state.defaultLabel
      )
    },
    [props, removeSelectedCategoriesFromInternals],
  )

  const getOptionLabel = React.useCallback<
    NonNullable<Props<T>['getOptionLabel']>
  >(
    (option) => {
      if (isCategoryOption(option)) {
        return asCategoryOption(option)._category
      }

      return props.getOptionLabel?.(option) || String(option)
    },
    [props],
  )

  const getOriginalOption = React.useCallback<
    NonNullable<Props<T>['getOriginalOption']>
  >(
    (option) => {
      if (option && options[option] != null) {
        return { _category: option } as unknown as T
      }

      return props.getOriginalOption?.(option) || null
    },
    [options, props],
  )

  const getOptionKey = React.useCallback<NonNullable<Props<T>['getOptionKey']>>(
    (option) => {
      if (isCategoryOption(option)) {
        return asCategoryOption(option)._category
      }

      return props.getOptionKey?.(option) || String(option)
    },
    [props],
  )

  const getOptionMenuItemProps = React.useCallback<
    NonNullable<Props<T>['getOptionMenuItemProps']>
  >(
    (option) => {
      if (!isCategoryOption(option)) {
        return {
          ...(showParentCategory && {
            withParentCategory: getCategoryForOption(option) || '',
          }),
          isNested: !withoutNesting,
        }
      }

      return props.getOptionMenuItemProps?.(option) || {}
    },
    [getCategoryForOption, props, showParentCategory, withoutNesting],
  )

  const getDisabledOptions = React.useCallback<
    NonNullable<Props<T>['getDisabledOptions']>
  >(
    (options) => {
      return (
        props.getDisabledOptions?.(getOptionsWithoutCategory(options)) || []
      )
    },
    [getOptionsWithoutCategory, props],
  )

  const getOptionLoading = React.useCallback<
    NonNullable<Props<T>['getOptionLoading']>
  >(
    (option) => {
      return isCategoryOption(option)
        ? false
        : props.getOptionLoading?.(option) || false
    },
    [props],
  )

  const getOptionLoadingLabel = React.useCallback<
    NonNullable<Props<T>['getOptionLoadingLabel']>
  >(
    (option) => {
      return isCategoryOption(option)
        ? 'Loading...'
        : props.getOptionLoadingLabel?.(option) || 'Loading...'
    },
    [props],
  )

  const renderLoadingOption = React.useCallback<
    NonNullable<Props<T>['renderLoadingOption']>
  >(
    (option, isSelected, internals) => {
      return props.renderLoadingOption?.(
        option,
        isSelected,
        removeSelectedCategoriesFromInternals(internals),
      )
    },
    [props, removeSelectedCategoriesFromInternals],
  )

  const renderOption = React.useCallback<NonNullable<Props<T>['renderOption']>>(
    (option, isSelected, internals) => {
      return props.renderOption?.(
        option,
        isSelected,
        removeSelectedCategoriesFromInternals(internals),
      )
    },
    [props, removeSelectedCategoriesFromInternals],
  )

  const onClear = React.useCallback<NonNullable<Props<T>['onClear']>>(
    (internals) => {
      props.onClear?.(removeSelectedCategoriesFromInternals(internals))
    },
    [props, removeSelectedCategoriesFromInternals],
  )

  const onChange = React.useCallback<NonNullable<Props<T>['onChange']>>(
    (internals) => {
      if (internals.originalOptionAdded) {
        if (isCategoryOption(internals.originalOptionAdded)) {
          getOptionsForCategory(
            asCategoryOption(internals.originalOptionAdded)._category,
          ).forEach((option) => {
            internals.selectOption(getOptionValue(option))
          })
        } else {
          const category = getCategoryForOption(internals.originalOptionAdded)

          if (
            category &&
            options[category].filter((categoryOption) =>
              internals.state.selectedOptions.has(
                getOptionValue(categoryOption),
              ),
            ).length === options[category].length
          ) {
            internals.selectOption(category)
          }
        }
      } else if (internals.originalOptionRemoved) {
        if (isCategoryOption(internals.originalOptionRemoved)) {
          const optionsForCategory = getOptionsForCategory(
            asCategoryOption(internals.originalOptionRemoved)._category,
          )

          if (
            !optionsForCategory.filter(
              (optionForCategory) =>
                !internals.state.selectedOptions.has(
                  getOptionValue(optionForCategory),
                ),
            ).length
          ) {
            getOptionsForCategory(
              asCategoryOption(internals.originalOptionRemoved)._category,
            ).forEach((option) => {
              internals.deselectOption(getOptionValue(option))
            })
          }
        } else {
          const category = getCategoryForOption(internals.originalOptionRemoved)

          if (category) {
            internals.deselectOption(category)
          }
        }
      }

      props.onChange?.(removeSelectedCategoriesFromInternals(internals))
    },
    [
      getCategoryForOption,
      getOptionValue,
      getOptionsForCategory,
      options,
      props,
      removeSelectedCategoriesFromInternals,
    ],
  )

  const renderHeader = React.useCallback<NonNullable<Props<T>['renderHeader']>>(
    (internals) =>
      props.renderHeader?.(removeSelectedCategoriesFromInternals(internals)),
    [props, removeSelectedCategoriesFromInternals],
  )

  const renderFooter = React.useCallback<NonNullable<Props<T>['renderFooter']>>(
    (internals) =>
      props.renderFooter?.(removeSelectedCategoriesFromInternals(internals)),
    [props, removeSelectedCategoriesFromInternals],
  )

  const renderTrigger = React.useCallback<
    NonNullable<Props<T>['renderTrigger']>
  >(
    (isOpen, internals) =>
      props.renderTrigger?.(
        isOpen,
        removeSelectedCategoriesFromInternals(internals),
        getLabelForTrigger?.(
          setToArray(internals.state.selectedOptions).join(','),
          internals,
        ) || internals.state.label,
      ),
    [getLabelForTrigger, props, removeSelectedCategoriesFromInternals],
  )

  return (
    <MultiSelect
      {...props}
      data-gourmet-nested-multiselect=""
      getDisabledOptions={
        props.getDisabledOptions ? getDisabledOptions : undefined
      }
      getLabelForTrigger={
        props.getLabelForTrigger ? getLabelForTrigger : undefined
      }
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      getOptionLoading={props.getOptionLoading ? getOptionLoading : undefined}
      getOptionLoadingLabel={
        props.getOptionLoadingLabel ? getOptionLoadingLabel : undefined
      }
      getOptionMenuItemProps={getOptionMenuItemProps}
      getOptionValue={props.getOptionValue ? getOptionValue : undefined}
      getOriginalOption={
        props.getOriginalOption ? getOriginalOption : undefined
      }
      onChange={onChange}
      onClear={props.onClear ? onClear : undefined}
      options={internalOptions}
      renderFooter={props.renderFooter ? renderFooter : undefined}
      renderHeader={props.renderHeader ? renderHeader : undefined}
      renderLoadingOption={
        props.renderLoadingOption ? renderLoadingOption : undefined
      }
      renderOption={props.renderOption ? renderOption : undefined}
      renderTrigger={props.renderTrigger ? renderTrigger : undefined}
    />
  )
}

NestedMultiSelect.displayName = 'NestedMultiSelect'
NestedMultiSelect.defaultProps = {}

export { NestedMultiSelect }
export type { Props as NestedMultiSelectProps }
