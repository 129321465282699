import React from 'react'
import { AlertCircleIcon } from '@vori/gourmet-icons'
import { CSSObject } from 'styled-components'
import { renderToString } from 'react-dom/server'

import { toRem } from '../utils'
import { InputProps } from './Input'

export function svgIconToURL(
  icon: React.ReactElement | null,
  invalid?: boolean,
): string {
  return icon
    ? `url(data:image/svg+xml;utf8,${encodeURI(renderToString(icon))
        .replace('currentColor', invalid ? '#F0374A' : '#A0A0AB')
        .replace('#', '%23')})`
    : ''
}

export function getBackgroundCSSProperties(
  props: Partial<InputProps>,
): CSSObject {
  const isInvalid =
    props['aria-invalid'] === 'true' || props['aria-invalid'] === true

  return {
    backgroundImage: [
      props.leftIcon && svgIconToURL(props.leftIcon || null, isInvalid),
      (props.rightIcon || isInvalid) &&
        svgIconToURL(
          !isInvalid ? props.rightIcon || null : <AlertCircleIcon />,
          isInvalid,
        ),
    ]
      .filter(Boolean)
      .join(','),
    backgroundPosition: [
      props.leftIcon && `${!props.noPadding ? toRem(12) : 0} center`,
      (props.rightIcon || isInvalid) &&
        `${!props.noPadding ? `calc(100% - ${toRem(12)})` : '100%'} center`,
    ]
      .filter(Boolean)
      .join(','),
  }
}
