import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Button from '../Button'
import NavButton from '../NavButton'

import Flex, { FlexProps } from '../Flex'

type ButtonGroupProps = React.HTMLAttributes<HTMLDivElement> &
  FlexProps & {
    withoutGaps?: boolean
  }

const defaultProps: Partial<ButtonGroupProps> = {
  ...Flex.defaultProps,
  withoutGaps: false,
}

/**
 * @deprecated Use `<FormFieldLayout>` in conjunction with `<ButtonNext>`.
 */
const StyledButtonGroup = styled(
  forwardRef<HTMLDivElement, ButtonGroupProps>(function ButtonGroup(
    { children, withoutGaps, ...props }: ButtonGroupProps,
    ref,
  ) {
    return (
      <Flex {...props} alignItems="stretch" ref={ref}>
        {children}
      </Flex>
    )
  }),
)`
  ${Button}, ${NavButton} {
    position: relative;
    z-index: 1;

    :focus,
    :focus-within {
      z-index: 2;
    }

    :not(:last-child) {
      ${({ column, withoutGaps }): string =>
        column
          ? `margin-bottom: ${withoutGaps ? '-1px' : '1px'};`
          : `margin-right: ${withoutGaps ? '-1px' : '1px'};`}
    }
  }

  ${Button}, ${NavButton} {
    :first-child {
      ${({ column }): string =>
        column
          ? 'border-bottom-right-radius: 0;'
          : 'border-top-right-radius: 0;'}

      ${({ column }): string =>
        column
          ? 'border-bottom-left-radius: 0;'
          : 'border-bottom-right-radius: 0;'}
    }

    :last-child {
      ${({ column }): string =>
        column ? 'border-top-left-radius: 0;' : 'border-top-left-radius: 0;'}

      ${({ column }): string =>
        column
          ? 'border-top-right-radius: 0;'
          : 'border-bottom-left-radius: 0;'}

      ${({ column }): string =>
        column ? 'margin-bottom: 0;' : 'margin-right: 0;'}
    }

    :not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
`

StyledButtonGroup.displayName = 'ButtonGroup'
StyledButtonGroup.defaultProps = defaultProps

export type { ButtonGroupProps }
export { defaultProps as ButtonGroupDefaultProps }
export default StyledButtonGroup
