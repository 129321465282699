import { CSSObject } from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'

export function cardRowStyles(): CSSObject {
  return {
    '&:focus-within, &:has([data-gourmet-radio-input-input]:checked)': {
      borderColor: foundations.color['color.primary-300'],
    },
  }
}
