import React from 'react'

import Flex, { FlexProps } from '../Flex'

import { getTabID, getTabPanelID } from './utils'
import { useTabsContext } from './hooks'

import { TabState, TabValue } from './types'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: FlexProps
  /**
   * If `true`, the panel's content will not be rendered when the tab is
   * inactive, i.e. not selected.
   */
  preventRenderingWhenInactive?: boolean
  /**
   * The value used to match the corresponding <Tab> for this <TabPanel>. If
   * no value is provided, the index of the <TabPanel> in relation to its parent
   * <TabPanels> component will be used as the `value`.
   */
  value?: TabValue
}

/**
 * The component that contains the content associated with a <Tab>.
 *
 * @see {@link https://w3c.github.io/aria/#tabpanel}
 */
const TabPanel = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<Props>
>(function TabPanel(
  {
    children,
    flexProps,
    preventRenderingWhenInactive,
    value,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  const { id: tabsID, isSelectedValue } = useTabsContext()

  // This check is mostly for the TS compiler, as internally, we ensure that
  // `value` is always set for <TabPanel> components rendered within <Tabs>
  // and <TabPanels>.
  if (value == null) {
    return null
  }

  const isSelected = isSelectedValue(value)

  return (
    <Flex
      {...props}
      {...flexProps}
      {...(!isSelected && { hidden: true })}
      aria-labelledby={getTabID(tabsID, value)}
      data-gourmet-tabpanel
      data-state={isSelected ? TabState.ACTIVE : TabState.INACTIVE}
      id={getTabPanelID(tabsID, value)}
      ref={ref}
      role="tabpanel"
      tabIndex={isSelected ? 0 : -1}
    >
      {preventRenderingWhenInactive && !isSelected ? null : children}
    </Flex>
  )
})

TabPanel.displayName = 'TabPanel'
TabPanel.defaultProps = {}

export { TabPanel }
export type { Props as TabPanelProps }
