import { foundations } from '@vori/gourmet-tokens'
import { useClientId, usePreviousValue } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
} from '@vori/gourmet-icons'

import {
  add as addToDate,
  endOfWeek,
  startOfWeek,
  sub as subtractFromDate,
} from 'date-fns'

import { useControlledState } from '../hooks'
import { foundation, sizing } from '../tokens'
import { CalendarDate, HookMethods, HookReturn } from './hook'

import {
  getMaximumTimeFromSelectedDates,
  getMinimumTimeFromSelectedDates,
  getTimeFromDate,
  updateFocus,
} from './utils'

import {
  composeEventHandlers,
  composeRefs,
  toRem,
  toTransitions,
} from '../utils'

import { Button } from '../ButtonNext'
import { Flex } from '../FlexNext'
import { FormField } from '../FormFieldNext'
import { Input, InputProps } from '../InputNext'
import { RangeInput, RangeInputProps } from '../RangeInput'
import { Spacer } from '../SpacerNext'
import { Text } from '../TextNext'

function styles(): CSSObject {
  return {
    '&': {
      alignItems: 'flex-start',
      borderCollapse: 'collapse',
      display: 'flex',
      flexDirection: 'column',

      '[data-gourmet-calendar-header-controls]': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },

      '[data-gourmet-calendar-title]': {
        flex: 1,
      },

      tr: {
        alignItems: 'center',
        display: 'flex',
        height: toRem(42),
        justifyContent: 'center',

        th: {
          alignItems: 'center',
          display: 'flex',
          height: toRem(40),
          justifyContent: 'center',
          width: toRem(40),
        },

        td: {
          margin: 0,
          padding: 0,

          '&[data-gourmet-calendar-date]': {
            color: '#3F3F46',
            borderRadius: sizing.radius.rounded,
            position: 'relative',

            '&::before, &::after': {
              backgroundColor: 'transparent',
              borderRadius: sizing.radius.rounded,
              content: '""',
              height: toRem(40),
              left: 0,
              position: 'absolute',
              top: 0,
              transition: toTransitions(['background-color'], 'ease'),
              width: toRem(40),
              zIndex: 1,
            },

            '&::after': {
              zIndex: 2,
            },

            [[
              '&:hover:not([data-selected], [data-disabled])::before',
              '&:focus-within:not([data-selected], [data-disabled])::before',
            ].join(',')]: {
              backgroundColor: '#F4F4F5',
            },

            [[
              '&:hover:not([data-selected])[data-is-within-selected-range]::after',
              '&:focus-within:not([data-selected])[data-is-within-selected-range]::after',
            ].join(',')]: {
              backgroundColor: '#FAFAFA',
            },

            '[data-gourmet-button]': {
              borderRadius: sizing.radius.rounded,
              color: '#3F3F46',
              fontWeight: foundation.typography.fontWeights.normal,
              height: toRem(40),
              position: 'relative',
              width: toRem(40),
              zIndex: 3,
            },

            '&[data-is-today]:not([data-today-dot-disabled]) [data-gourmet-button]':
              {
                fontWeight: foundation.typography.fontWeights.medium,

                '&::before': {
                  backgroundColor: '#26272B',
                  borderRadius: sizing.radius.rounded,
                  content: '""',
                  height: toRem(4),
                  left: `calc(50% - (${toRem(4)} / 2))`,
                  position: 'absolute',
                  bottom: toRem(4),
                  transition: toTransitions(
                    ['background-color', 'opacity'],
                    'ease',
                  ),
                  width: toRem(4),
                  zIndex: 1,
                },
              },

            '&[data-selected]': {
              zIndex: 2,

              [[
                '&[data-is-first-selected]::before',
                '&[data-is-last-selected]:not(:first-child)::before',
              ].join(',')]: {
                backgroundColor: '#F4F4F5',
                borderRadius: 0,
                height: toRem(40),
                right: toRem(20),
                width: toRem(20),
              },

              '&[data-is-first-selected]::before': {
                left: toRem(20),
              },

              '&[data-is-last-selected]:not(:first-child)::before': {
                right: toRem(20),
              },

              '&[data-is-today] [data-gourmet-button]::before': {
                backgroundColor: '#FFFFFF',
              },

              '&::after': {
                backgroundColor: '#26272B',
              },

              '[data-gourmet-button], [data-gourmet-button]:hover': {
                backgroundColor: 'transparent',
                color: '#FFFFFF',
                fontWeight: foundation.typography.fontWeights.medium,
              },
            },

            '&[data-is-within-selected-range]': {
              '&:not([data-selected])': {
                '&::before': {
                  backgroundColor: '#F4F4F5',
                  borderRadius: 0,
                },

                '&::after': {
                  backgroundColor: '#F4F4F5',
                  zIndex: 2,
                },

                '[data-gourmet-button]': {
                  fontWeight: foundation.typography.fontWeights.medium,
                },
              },
            },

            '&:not([data-is-from-current-month]):not([data-selected]) [data-gourmet-button]':
              {
                color: '#70707B',
              },
          },
        },
      },

      'tbody tr': {
        td: {
          '&:first-child[data-is-within-selected-range]:not([data-selected])': {
            '&::before': {
              display: 'none',
            },

            '&::after': {
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
            },
          },

          '&:last-child[data-is-within-selected-range]:not([data-selected])': {
            '&::before': {
              display: 'none',
            },

            '&::after': {
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
            },
          },

          '&:last-child[data-selected]:not([data-is-last-selected])::before': {
            display: 'none',
          },
        },

        '&:not([data-has-range-selected]) [data-selected]::before': {
          display: 'none',
        },
      },

      tfoot: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: foundations.space['space.100'],
      },

      'tfoot, tfoot tr, tfoot tr td': {
        width: '100%',
      },
    },
  }
}

const CalendarContainer = styled.table(styles)

type Props = React.HTMLAttributes<HTMLTableElement> &
  HookReturn & {
    /**
     * If `true`, the dot won't show on today's date. This is a stopgap until
     * we can solve the primary issue of the Calendar not considering a
     * different timezone from the user's timezone.
     */
    disableTodayDot?: boolean
    /**
     * Overrides the default `aria-label` for the next button. You'll want
     * to provide this when changing the default functionality of the next
     * button through `onClickNext`.
     *
     * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/#rps_label_4}
     */
    nextButtonAriaLabel?: string
    /**
     * Overrides the default `aria-label` for the previous button. You'll want
     * to provide this when changing the default functionality of the previous
     * button through `onClickPrev`.
     *
     * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/#rps_label_4}
     */
    prevButtonAriaLabel?: string
    /**
     * If `true` the calendar and all its controls will be disabled.
     */
    disabled?: boolean
    /**
     * A callback function to be executed when the user clicks on a
     * date in the calendar.
     */
    onClickDate?: (
      /**
       * The date clicked by the user.
       */
      date: CalendarDate,
    ) => void
    /**
     * A callback function to be executed when the user clicks on the next
     * button.
     *
     * **Note:** Remember to provide a valid `nextButtonAriaLabel` value when
     * providing `onClickNext`.
     */
    onClickNext?: React.MouseEventHandler<HTMLButtonElement>
    /**
     * A callback function to be executed when the user clicks on the previous
     * button.
     *
     * **Note:** Remember to provide a valid `prevButtonAriaLabel` value when
     * providing `onClickPrev`.
     */
    onClickPrev?: React.MouseEventHandler<HTMLButtonElement>
    /**
     * A render prop that allows you to provide custom rendering of dates in
     * the calendar.
     */
    renderDate?: (
      /**
       * The date being currently rendered.
       */
      date: CalendarDate,
    ) => React.ReactNode
    /**
     * A rendered prop used to render a footer for the calendar.
     *
     * **Hint:** Use this to render custom controls
     */
    renderFooterControls?: (
      /**
       * Utility functions that allow to to control certain aspects of the
       * calendar, e.g. the current month.
       */
      dateMethods: HookMethods,
    ) => React.ReactNode
    /**
     * A rendered prop used to render a header for the calendar.
     *
     * **Hint:** Use this to render custom controls
     */
    renderHeaderControls?: (
      /**
       * Utility functions that allow to to control certain aspects of the
       * calendar, e.g. the current month.
       */
      dateMethods: HookMethods,
    ) => React.ReactNode
    /**
     * Will add an input where users can set the time of the selected date(s).
     */
    withTimeInput?: boolean
    /**
     * Will add a range input where users can set a time range for the selected date(s).
     */
    withTimeRangeInput?: boolean
    /**
     * Props to be passed directly to the time input component.
     */
    timeInputProps?: InputProps
    /**
     * Props to be passed directly to the time range input component.
     */
    timeInputRangeProps?: RangeInputProps
  }

/**
 * Displays a calendar interface given the options returned from
 * the `useCalendar()` hook.
 *
 * Use this component if you need custom functionality not provided by
 * the `<DateInput>` and `<DateRangeInput>` components.
 *
 *
 * @example
 * function SomeCalendar(): JSX.Element {
 *   const calendar = useCalendar()
 *
 *   return (
 *     <Calendar
 *       {...calendar}
 *       onClickDate={({ date }) => calendar.toggle(date)}
 *       onClickNext={calendar.setNextMonth}
 *       onClickPrev={calendar.setPrevMonth}
 *     />
 *   )
 * }
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/}
 */
const Calendar = React.forwardRef<
  HTMLTableElement,
  React.PropsWithChildren<Props>
>(function Calendar(
  {
    dates,
    datesWithinRangeOfSelectedDates,
    deselect,
    deselectAll,
    disabled,
    disabledDates,
    disableFutureDates,
    disablePastDates,
    disableTodayDot,
    formattedMonth,
    formattedYear,
    id: controlledID,
    maxSelected,
    month,
    nextButtonAriaLabel,
    onClickDate,
    onClickNext,
    onClickPrev,
    prevButtonAriaLabel,
    rangeSelection,
    renderDate,
    renderFooterControls,
    renderHeaderControls,
    select,
    selectedDates,
    selectedTimes,
    selectedDaysAreVisible,
    setMonth,
    setNextMonth,
    setNextYear,
    setPrevMonth,
    setPrevYear,
    setSelected,
    setTime,
    setYear,
    timeInputProps,
    timeInputRangeProps,
    todayIsVisible,
    toggle,
    weekStartsOn,
    withTimeInput,
    withTimeRangeInput,
    year,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [defaultID] = useClientId('gourmet-calendar')

  const [inputID] = useControlledState({
    componentName: 'Calendar',
    controlledValue: controlledID,
    defaultValue: defaultID,
  })

  const [elementRef, setElementRef] = React.useState<HTMLTableElement | null>(
    null,
  )

  const dateStringToFocusAfterUpdate = React.useRef<string | null>(null)
  const focusIsControlled = React.useRef(false)
  const previousMonth = usePreviousValue(month)
  const previousYear = usePreviousValue(year)

  /**
   * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/#kbd_label_5}
   */
  const handleKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLDivElement>
  >(
    (event) => {
      if (!elementRef) {
        return
      }

      const focusedElement = elementRef.querySelector<HTMLTableCellElement>(
        '[data-is-focusable]',
      )

      if (!focusedElement) {
        return
      }

      const focusedDate = focusedElement.dataset.value

      if (!focusedDate) {
        return
      }

      const focusedButton =
        focusedElement.querySelector<HTMLButtonElement>('button')

      if (!focusedButton) {
        return
      }

      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowLeft': {
          const nextDateToFocus = subtractFromDate(new Date(focusedDate), {
            ...(event.key === 'ArrowUp' && { weeks: 1 }),
            ...(event.key === 'ArrowLeft' && { days: 1 }),
          })

          const nextDateString = nextDateToFocus.toLocaleDateString()

          const focusWasUpdated = updateFocus(
            elementRef,
            focusedElement,
            nextDateString,
          )

          focusIsControlled.current = true

          if (!focusWasUpdated) {
            dateStringToFocusAfterUpdate.current = nextDateString
            setPrevMonth()
          }

          break
        }

        case 'ArrowDown':
        case 'ArrowRight': {
          const nextDateToFocus = addToDate(new Date(focusedDate), {
            ...(event.key === 'ArrowDown' && { weeks: 1 }),
            ...(event.key === 'ArrowRight' && { days: 1 }),
          })

          const nextDateString = nextDateToFocus.toLocaleDateString()

          const focusWasUpdated = updateFocus(
            elementRef,
            focusedElement,
            nextDateString,
          )

          focusIsControlled.current = true

          if (!focusWasUpdated) {
            dateStringToFocusAfterUpdate.current = nextDateString
            setNextMonth()
          }

          break
        }

        case 'Home': {
          const nextDateToFocus = startOfWeek(new Date(focusedDate))
          const nextDateString = nextDateToFocus.toLocaleDateString()

          const focusWasUpdated = updateFocus(
            elementRef,
            focusedElement,
            nextDateString,
          )

          focusIsControlled.current = true

          if (!focusWasUpdated) {
            dateStringToFocusAfterUpdate.current = nextDateString
            setPrevMonth()
          }

          break
        }

        case 'End': {
          const nextDateToFocus = endOfWeek(new Date(focusedDate))
          const nextDateString = nextDateToFocus.toLocaleDateString()

          const focusWasUpdated = updateFocus(
            elementRef,
            focusedElement,
            nextDateString,
          )

          focusIsControlled.current = true

          if (!focusWasUpdated) {
            dateStringToFocusAfterUpdate.current = nextDateString
            setNextMonth()
          }

          break
        }

        case 'PageUp': {
          const nextDateToFocus = subtractFromDate(new Date(focusedDate), {
            ...(event.shiftKey ? { years: 1 } : { months: 1 }),
          })

          const nextDateString = nextDateToFocus.toLocaleDateString()

          const focusWasUpdated = updateFocus(
            elementRef,
            focusedElement,
            nextDateString,
          )

          focusIsControlled.current = true

          if (!focusWasUpdated) {
            dateStringToFocusAfterUpdate.current = nextDateString

            if (event.shiftKey) {
              setPrevYear()
            } else {
              setPrevMonth()
            }
          }

          break
        }

        case 'PageDown': {
          const nextDateToFocus = addToDate(new Date(focusedDate), {
            ...(event.shiftKey ? { years: 1 } : { months: 1 }),
          })

          const nextDateString = nextDateToFocus.toLocaleDateString()

          const focusWasUpdated = updateFocus(
            elementRef,
            focusedElement,
            nextDateString,
          )

          focusIsControlled.current = true

          if (!focusWasUpdated) {
            dateStringToFocusAfterUpdate.current = nextDateString

            if (event.shiftKey) {
              setNextYear()
            } else {
              setNextMonth()
            }
          }

          break
        }

        default: {
          break
        }
      }
    },
    [elementRef, setNextMonth, setNextYear, setPrevMonth, setPrevYear],
  )

  React.useEffect(() => {
    if (
      elementRef &&
      ((previousMonth && previousMonth !== month) ||
        (previousYear && previousYear !== year)) &&
      dateStringToFocusAfterUpdate.current
    ) {
      focusIsControlled.current = true

      updateFocus(
        elementRef,
        elementRef.querySelector<HTMLTableCellElement>('[data-is-focusable]') ||
          null,
        dateStringToFocusAfterUpdate.current,
      )

      dateStringToFocusAfterUpdate.current = null
    }
  }, [elementRef, month, previousMonth, previousYear, year])

  return (
    <>
      <CalendarContainer
        {...props}
        aria-labelledby={`${inputID}-gourmet-calendar-grid-label`}
        data-gourmet-calendar=""
        onKeyDown={composeEventHandlers(props.onKeyDown, handleKeyDown)}
        ref={composeRefs([setElementRef, ref])}
        role="grid"
      >
        <thead>
          <tr data-gourmet-calendar-header-controls="">
            <th scope="col">
              <Button
                asIconButton
                asClickable
                aria-label={prevButtonAriaLabel || 'Go to the previous month'}
                disabled={disabled}
                leftIcon={<ChevronLeftIcon />}
                onClick={onClickPrev}
              />
            </th>
            <th data-gourmet-calendar-title="" scope="col">
              <Text
                id={`${inputID}-gourmet-calendar-grid-label`}
                aria-live="polite"
                size="text-md"
                weight="medium"
              >
                {formattedMonth} {formattedYear}
              </Text>
            </th>
            <th scope="col">
              <Button
                asClickable
                asIconButton
                aria-label={nextButtonAriaLabel || 'Go to the next month'}
                disabled={disabled}
                leftIcon={<ChevronRightIcon />}
                onClick={onClickNext}
              />
            </th>
          </tr>
          {renderHeaderControls?.({
            deselect,
            deselectAll,
            select,
            setMonth,
            setNextMonth,
            setNextYear,
            setPrevMonth,
            setPrevYear,
            setSelected,
            setTime,
            setYear,
            toggle,
          })}
          <tr data-gourmet-calendar-header="">
            <th scope="col" abbr="Sunday">
              <Text size="text-sm" weight="medium">
                Su
              </Text>
            </th>
            <th scope="col" abbr="Monday">
              <Text size="text-sm" weight="medium">
                Mo
              </Text>
            </th>
            <th scope="col" abbr="Tuesday">
              <Text size="text-sm" weight="medium">
                Tu
              </Text>
            </th>
            <th scope="col" abbr="Wednesday">
              <Text size="text-sm" weight="medium">
                We
              </Text>
            </th>
            <th scope="col" abbr="Thursday">
              <Text size="text-sm" weight="medium">
                Th
              </Text>
            </th>
            <th scope="col" abbr="Friday">
              <Text size="text-sm" weight="medium">
                Fr
              </Text>
            </th>
            <th scope="col" abbr="Saturday">
              <Text size="text-sm" weight="medium">
                Sa
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {dates.map((week, index) => (
            <tr
              {...(selectedDates.length > 1 &&
                rangeSelection && { 'data-has-range-selected': '' })}
              key={`week-${index}`}
            >
              {week.map(
                ({
                  date,
                  isDisabled,
                  isFirstDateSelected,
                  isFirstDateSelectedFromCurrentMonth,
                  isFirstDayOfMonth,
                  isFromCurrentMonth,
                  isInSelectedRange,
                  isLastDateSelected,
                  isLastDayOfMonth,
                  isSelected,
                  isToday,
                  ...rest
                }) => {
                  const dateIsDisabled = isDisabled || Boolean(disabled)
                  let shouldBeFocusable = false

                  if (!focusIsControlled.current) {
                    if (selectedDaysAreVisible) {
                      shouldBeFocusable = isFirstDateSelectedFromCurrentMonth
                    } else if (todayIsVisible) {
                      shouldBeFocusable = isToday
                    } else {
                      shouldBeFocusable = isFirstDayOfMonth
                    }
                  }

                  return renderDate != null ? (
                    renderDate({
                      date,
                      isDisabled: dateIsDisabled,
                      isFirstDateSelected,
                      isFirstDateSelectedFromCurrentMonth,
                      isFirstDayOfMonth,
                      isFromCurrentMonth,
                      isInSelectedRange,
                      isLastDateSelected,
                      isLastDayOfMonth,
                      isSelected,
                      isToday,
                      ...rest,
                    })
                  ) : (
                    <td
                      {...(dateIsDisabled && { 'data-disabled': '' })}
                      {...(disableTodayDot && {
                        'data-today-dot-disabled': '',
                      })}
                      {...(isFirstDateSelected && {
                        'data-is-first-selected': '',
                      })}
                      {...(isFirstDayOfMonth && {
                        'data-is-first-day-of-month': '',
                      })}
                      {...(isFromCurrentMonth && {
                        'data-is-from-current-month': '',
                      })}
                      {...(isInSelectedRange && {
                        'data-is-within-selected-range': '',
                      })}
                      {...(isLastDateSelected && {
                        'data-is-last-selected': '',
                      })}
                      {...(isLastDayOfMonth && {
                        'data-is-last-day-of-month': '',
                      })}
                      {...(isSelected && {
                        'aria-selected': 'true',
                        'data-selected': '',
                        role: 'gridcell',
                      })}
                      {...(isToday && { 'data-is-today': '' })}
                      {...(shouldBeFocusable && { 'data-is-focusable': '' })}
                      data-gourmet-calendar-date=""
                      data-value={date.toLocaleDateString()}
                      key={`${inputID}-${date.toLocaleDateString()}`}
                    >
                      <Button
                        asClickable
                        disabled={dateIsDisabled}
                        noPadding
                        noFocusRing
                        onClick={() => {
                          onClickDate?.({
                            date,
                            isDisabled: dateIsDisabled,
                            isFirstDateSelected,
                            isFirstDateSelectedFromCurrentMonth,
                            isFirstDayOfMonth,
                            isFromCurrentMonth,
                            isInSelectedRange,
                            isLastDateSelected,
                            isLastDayOfMonth,
                            isSelected,
                            isToday,
                            ...rest,
                          })
                        }}
                        tabIndex={shouldBeFocusable ? 0 : -1}
                      >
                        {date.getDate()}
                      </Button>
                    </td>
                  )
                },
              )}
            </tr>
          ))}
        </tbody>
      </CalendarContainer>

      {withTimeInput && (
        <>
          <Spacer size="space.050" />
          <FormField label="Time" flexProps={{ fullWidth: true }}>
            <Input
              leftIcon={<ClockIcon />}
              fullWidth
              {...timeInputProps}
              defaultValue={
                timeInputProps?.defaultValue || selectedDates.length
                  ? getTimeFromDate(selectedDates[0])
                  : undefined
              }
              onChange={composeEventHandlers(
                timeInputProps?.onChange,
                (event) => {
                  setTime([event.target.value || null])
                },
              )}
              type="time"
            />
          </FormField>
        </>
      )}

      {withTimeRangeInput && (
        <Flex direction="column" columnOffset="space.025">
          <Spacer size="space.050" />
          <FormField label="Time" flexProps={{ fullWidth: true }}>
            <RangeInput
              {...timeInputRangeProps}
              maxValueInputProps={{
                fullWidth: true,
                leftIcon: <ClockIcon />,
                ...(timeInputRangeProps?.maxValueInputProps || {}),
                defaultValue:
                  timeInputRangeProps?.maxValueInputProps?.defaultValue ||
                  getMaximumTimeFromSelectedDates(selectedDates),
                onChange: composeEventHandlers(
                  timeInputRangeProps?.maxValueInputProps?.onChange,
                  (event) => {
                    const value = event.target.value || null

                    setTime(
                      selectedTimes.length >= 1
                        ? [selectedTimes[0], value]
                        : [value],
                    )
                  },
                ),
                type: 'time',
              }}
              minValueInputProps={{
                fullWidth: true,
                leftIcon: <ClockIcon />,
                ...(timeInputRangeProps?.minValueInputProps || {}),
                defaultValue:
                  timeInputRangeProps?.minValueInputProps?.defaultValue ||
                  getMinimumTimeFromSelectedDates(selectedDates),
                onChange: composeEventHandlers(
                  timeInputRangeProps?.maxValueInputProps?.onChange,
                  (event) => {
                    const value = event.target.value || null

                    setTime(
                      selectedTimes.length >= 1
                        ? [value, selectedTimes[1]]
                        : [value],
                    )
                  },
                ),
                type: 'time',
              }}
            />
          </FormField>
        </Flex>
      )}

      {renderFooterControls?.({
        deselect,
        deselectAll,
        select,
        setMonth,
        setNextMonth,
        setNextYear,
        setPrevMonth,
        setPrevYear,
        setSelected,
        setTime,
        setYear,
        toggle,
      })}
    </>
  )
})

Calendar.displayName = 'Calendar'
Calendar.defaultProps = {}

export { Calendar, styles as CalendarStyles }
export type { Props as CalendarProps }
