import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileShield01IconProps = IconProps

const FileShield01Icon = forwardRef<SVGSVGElement, FileShield01IconProps>(
  (props: FileShield01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 10V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h3.7m5.5-1s3-1.43 3-3.575v-2.502l-2.188-.782a2.41 2.41 0 0 0-1.626 0L15 14.923v2.502C15 19.57 18 21 18 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileShield01Icon.displayName = 'FileShield01Icon'
FileShield01Icon.defaultProps = iconDefaultProps

export type { FileShield01IconProps }
export { FileShield01Icon }
