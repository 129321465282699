import { Loader as GoogleLoader } from '@googlemaps/js-api-loader'
import React, { useEffect, useState } from 'react'
import { ENV_GOOGLE_MAPS_API_KEY } from '@vori/dashboard-env'

import {
  Autocomplete,
  AutocompleteProps,
  Flex,
  Text,
} from '@vori/gourmet-components'

import { MarkerPin01Icon } from '@vori/gourmet-icons'

const loader = new GoogleLoader({
  apiKey: ENV_GOOGLE_MAPS_API_KEY,
  libraries: ['places'],
  version: 'weekly',
})

type ServiceStatus = 'loading' | 'loaded' | 'error'

function AddressInput({
  inputProps,
  ...props
}: Partial<AutocompleteProps<google.maps.places.PlaceResult>>): JSX.Element {
  const [status, setStatus] = useState<ServiceStatus>('loading')

  const [service, setService] =
    useState<google.maps.places.PlacesService | null>(null)

  const [suggestions, setSuggestions] = useState<
    google.maps.places.PlaceResult[]
  >([])

  useEffect(() => {
    loader
      .load()
      .then(() => {
        setStatus('loaded')
        setService(
          new google.maps.places.PlacesService(document.createElement('div')),
        )
      })
      .catch(() => {
        setStatus('error')
      })
  }, [])

  return (
    <Autocomplete
      {...props}
      options={suggestions}
      getOptionMenuItemProps={() => ({ icon: <MarkerPin01Icon /> })}
      getOptionValue={({ formatted_address, name }) =>
        formatted_address || name
      }
      renderOption={({ formatted_address, name }) =>
        formatted_address && name ? (
          <Flex column fullWidth>
            <Text>{name}</Text>
            <Text size="body" variant="secondary">
              {formatted_address}
            </Text>
          </Flex>
        ) : (
          formatted_address || name
        )
      }
      inputProps={{
        ...(inputProps && { ...inputProps }),
        onChange: (event) => {
          setStatus('loading')

          service?.textSearch(
            { query: event.currentTarget.value },
            (suggestion) => {
              setStatus('loaded')
              setSuggestions(suggestion || [])
            },
          )

          if (inputProps?.onChange) {
            inputProps.onChange(event)
          }
        },
      }}
      showLoader={status === 'loading' && !suggestions.length}
      showEmpty={
        status === 'error' || (status === 'loaded' && !suggestions.length)
      }
    />
  )
}

export { AddressInput }
