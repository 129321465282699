import { ReducerState } from './types'

export function hasSelectedOption(
  selectedOption: ReducerState['selectedOption'],
): boolean {
  return selectedOption !== null
}

export function optionIsSelected(state: ReducerState, option: string): boolean {
  return state.selectedOption === option
}

export function getLabel(
  selectedOption: ReducerState['selectedOption'],
  defaultLabel = '',
): string {
  return `${hasSelectedOption(selectedOption) ? selectedOption : defaultLabel}`
}
