import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LockUnlocked03IconProps = IconProps

const LockUnlocked03Icon = forwardRef<SVGSVGElement, LockUnlocked03IconProps>(
  (props: LockUnlocked03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 11V8a5 5 0 0 1 9.9-1M8.8 21h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 18.72 20 17.88 20 16.2v-.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 11 16.88 11 15.2 11H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 13.28 4 14.12 4 15.8v.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 21 7.12 21 8.8 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LockUnlocked03Icon.displayName = 'LockUnlocked03Icon'
LockUnlocked03Icon.defaultProps = iconDefaultProps

export type { LockUnlocked03IconProps }
export { LockUnlocked03Icon }
