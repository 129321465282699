import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockPlusIconProps = IconProps

const ClockPlusIcon = forwardRef<SVGSVGElement, ClockPlusIconProps>(
  (props: ClockPlusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.92 13.265c.053-.414.08-.837.08-1.265 0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10c.435 0 .864-.028 1.285-.082M12 6v6l3.738 1.87M19 22v-6m-3 3h6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClockPlusIcon.displayName = 'ClockPlusIcon'
ClockPlusIcon.defaultProps = iconDefaultProps

export type { ClockPlusIconProps }
export { ClockPlusIcon }
