import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BracketsPlusIconProps = IconProps

const BracketsPlusIcon = forwardRef<SVGSVGElement, BracketsPlusIconProps>(
  (props: BracketsPlusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.57 20a2.286 2.286 0 0 0 2.287-2.286v-4.571L22 12l-1.143-1.143V6.286A2.285 2.285 0 0 0 18.57 4M5.429 4a2.285 2.285 0 0 0-2.286 2.286v4.571L2 12l1.143 1.143v4.571A2.285 2.285 0 0 0 5.429 20M12 8v8m-4-4h8"
        fill="none"
      ></path>
    </Icon>
  ),
)

BracketsPlusIcon.displayName = 'BracketsPlusIcon'
BracketsPlusIcon.defaultProps = iconDefaultProps

export type { BracketsPlusIconProps }
export { BracketsPlusIcon }
