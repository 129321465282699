import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MessageTextCircle02IconProps = IconProps

const MessageTextCircle02Icon = forwardRef<
  SVGSVGElement,
  MessageTextCircle02IconProps
>((props: MessageTextCircle02IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 9.5h4M8 13h7m-3 8a9 9 0 1 0-8.342-5.616c.081.2.122.3.14.381a.907.907 0 0 1 .024.219c0 .083-.015.173-.045.353l-.593 3.558c-.062.373-.093.56-.035.694a.5.5 0 0 0 .262.262c.135.058.321.027.694-.035l3.558-.593c.18-.03.27-.045.353-.045.081 0 .14.006.219.024.08.018.18.059.38.14A8.973 8.973 0 0 0 12 21Z"
      fill="none"
    ></path>
  </Icon>
))

MessageTextCircle02Icon.displayName = 'MessageTextCircle02Icon'
MessageTextCircle02Icon.defaultProps = iconDefaultProps

export type { MessageTextCircle02IconProps }
export { MessageTextCircle02Icon }
