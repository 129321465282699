import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Wallet04IconProps = IconProps

const Wallet04Icon = forwardRef<SVGSVGElement, Wallet04IconProps>(
  (props: Wallet04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 9.5V7.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C18.48 4 17.92 4 16.8 4H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 5.52 2 6.08 2 7.2v9.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 20 4.08 20 5.2 20h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C20 18.48 20 17.92 20 16.8v-2.3M15 12c0-.465 0-.697.038-.89a2 2 0 0 1 1.572-1.572c.193-.038.425-.038.89-.038h2c.465 0 .697 0 .89.038a2 2 0 0 1 1.572 1.572c.038.193.038.425.038.89s0 .697-.038.89a2 2 0 0 1-1.572 1.572c-.193.038-.425.038-.89.038h-2c-.465 0-.697 0-.89-.038a2 2 0 0 1-1.572-1.572C15 12.697 15 12.465 15 12Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Wallet04Icon.displayName = 'Wallet04Icon'
Wallet04Icon.defaultProps = iconDefaultProps

export type { Wallet04IconProps }
export { Wallet04Icon }
