import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type WifiIconProps = IconProps

const WifiIcon = forwardRef<SVGSVGElement, WifiIconProps>(
  (props: WifiIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 19.5h.01M22.806 8.7A15.942 15.942 0 0 0 12 4.5c-4.166 0-7.96 1.592-10.807 4.2m3.539 3.543A10.958 10.958 0 0 1 12 9.5a10.96 10.96 0 0 1 7.268 2.743m-3.57 3.532A5.974 5.974 0 0 0 12 14.5c-1.416 0-2.718.49-3.745 1.312"
        fill="none"
      ></path>
    </Icon>
  ),
)

WifiIcon.displayName = 'WifiIcon'
WifiIcon.defaultProps = iconDefaultProps

export type { WifiIconProps }
export { WifiIcon }
