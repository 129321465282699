import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserLeft02IconProps = IconProps

const UserLeft02Icon = forwardRef<SVGSVGElement, UserLeft02IconProps>(
  (props: UserLeft02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m19 9-3-3m0 0 3-3m-3 3h6m-6 15v-1.2c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C13.72 15 12.88 15 11.2 15H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 17.28 2 18.12 2 19.8V21M12.5 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserLeft02Icon.displayName = 'UserLeft02Icon'
UserLeft02Icon.defaultProps = iconDefaultProps

export type { UserLeft02IconProps }
export { UserLeft02Icon }
