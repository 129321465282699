import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart07IconProps = IconProps

const BarChart07Icon = forwardRef<SVGSVGElement, BarChart07IconProps>(
  (props: BarChart07IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21H6.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3 19.48 3 18.92 3 17.8V3m4 7.5v7m4.5-12v12m4.5-7v7m4.5-12v12"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart07Icon.displayName = 'BarChart07Icon'
BarChart07Icon.defaultProps = iconDefaultProps

export type { BarChart07IconProps }
export { BarChart07Icon }
