import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GitCommitIconProps = IconProps

const GitCommitIcon = forwardRef<SVGSVGElement, GitCommitIconProps>(
  (props: GitCommitIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 12a4 4 0 0 1-8 0m8 0a4 4 0 0 0-8 0m8 0h6M8 12H2"
        fill="none"
      ></path>
    </Icon>
  ),
)

GitCommitIcon.displayName = 'GitCommitIcon'
GitCommitIcon.defaultProps = iconDefaultProps

export type { GitCommitIconProps }
export { GitCommitIcon }
