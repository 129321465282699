import React from 'react'

import { TabListProps } from './TabList'
import { TabPanelProps } from './TabPanel'
import { TabPanelsProps } from './TabPanels'
import { TabProps } from './Tab'
import { TabValue } from './types'

export function getTabID(tabsID: string, value: TabValue): string {
  return `${tabsID}--tab--${value}`
}

export function getTabPanelID(tabsID: string, value: TabValue): string {
  return `${tabsID}--tabpanel--${value}`
}

export function getComponentValues<
  TParentProps extends TabListProps | TabPanelsProps,
  TChildProps extends TabProps | TabPanelProps,
>(
  children: React.ReactNode,
  parentComponent: React.ForwardRefExoticComponent<TParentProps>,
  childComponent: React.ForwardRefExoticComponent<TChildProps>,
): Array<TabValue> {
  const tabPanelsComponent = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === parentComponent,
  )

  if (!tabPanelsComponent) {
    return []
  }

  const tabPanelComponents = React.Children.toArray(
    (
      tabPanelsComponent as React.ReactElement<
        React.PropsWithChildren<TParentProps>
      >
    ).props.children,
  ).filter(
    (child) =>
      React.isValidElement<TChildProps>(child) && child.type === childComponent,
  )

  if (!tabPanelComponents.length) {
    return []
  }

  return tabPanelComponents.map(
    (tabComponent, index) =>
      (tabComponent as React.ReactElement<TChildProps>).props.value || index,
  )
}

// Used to get the next tab element to be focused based on the direction
// of the keyboard-based navigation and the currently selected and focused
// tab(s).
export function getNextTabElementToFocus(
  tabElements: Array<HTMLButtonElement>,
  values: Array<TabValue>,
  index: number,
  value: TabValue,
  direction: 'left' | 'right',
): HTMLButtonElement {
  let nextTabElement: HTMLButtonElement
  const getNextIndex = (currentIndex: number): number =>
    direction === 'left' ? currentIndex - 1 : currentIndex + 1

  if (index !== -1) {
    nextTabElement = tabElements[getNextIndex(index)]
  } else {
    nextTabElement = tabElements[getNextIndex(values.indexOf(value))]
  }

  if (!nextTabElement) {
    nextTabElement =
      direction === 'left' ? tabElements.slice(-1)[0] : tabElements[0]
  }

  return nextTabElement.disabled && tabElements.length > 1
    ? getNextTabElementToFocus(
        tabElements,
        values,
        getNextIndex(index),
        values[getNextIndex(values.indexOf(value))],
        direction,
      )
    : nextTabElement
}
