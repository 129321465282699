import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Droplets02IconProps = IconProps

const Droplets02Icon = forwardRef<SVGSVGElement, Droplets02IconProps>(
  (props: Droplets02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 21.5a7 7 0 0 0 7-7c0-2-1-3.9-3-5.5s-3.5-4-4-6.5C11.5 5 10 7.4 8 9c-2 1.6-3 3.5-3 5.5a7 7 0 0 0 7 7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Droplets02Icon.displayName = 'Droplets02Icon'
Droplets02Icon.defaultProps = iconDefaultProps

export type { Droplets02IconProps }
export { Droplets02Icon }
