/* eslint-disable @typescript-eslint/ban-types */
import { compact } from 'lodash'
import styled, { css } from 'styled-components'

import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  TableCellProps as OriginalTableCellProps,
  TableHeaderProps as OriginalTableHeaderProps,
  TableRowProps,
  useFlexLayout,
  useTable,
  UseTableInstanceProps,
  UseTableOptions,
} from 'react-table'

import { assignRef } from '../utils'
import { colors, sizing, spacing } from '../tokens'

import Card, { CardDefaultProps, CardProps } from '../Card'
import Flex, { FlexProps } from '../Flex'
import List, { ListItemMemo, ListItemProps, ListProps } from '../List'
import Loader, { LoaderProps } from '../Loader'
import Logo, { LogoProps } from '../Logo'
import Spacer from '../Spacer'
import Text from '../Text'

type TableCellProps = OriginalTableCellProps & {
  isLastStickyCell?: boolean
  isSticky?: boolean
}

type TableHeaderProps = OriginalTableHeaderProps & {
  isLastStickyCell?: boolean
  isSticky?: boolean
}

const DEFAULT_HEADER_ROW_HEIGHT = 45
const DEFAULT_CONTENT_ROW_HEIGHT = 60

const TableContainerStyles = css<{ $hasStickyColumns?: boolean }>`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  flex-direction: column;
  ${({ $hasStickyColumns }) => ($hasStickyColumns ? 'overflow: hidden;' : '')}
`

const StickyCellStyles = css<
  Pick<TableCellProps, 'isSticky' | 'isLastStickyCell'>
>`
  border-right: 1px solid ${colors.table.cell.borderColor};
  left: 0;
  position: static; /** IE fallback */
  position: sticky;
  top: 0;
  z-index: 5;

  ${({ isLastStickyCell }) =>
    isLastStickyCell
      ? `border-right: 2px solid ${colors.table.cell.sticky.borderColor};`
      : ''}
`

const TableCardContainer = styled(Card)<
  CardProps & { $hasStickyColumns?: boolean }
>`
  ${TableContainerStyles}
`

const TableHeader = styled.div<{ $hasStickyColumns?: boolean }>`
  border-top-left-radius: ${sizing.radius.base};
  border-top-right-radius: ${sizing.radius.base};
  ${({ $hasStickyColumns }) => ($hasStickyColumns ? 'overflow-x: scroll;' : '')}

  /* Firefox */
  scrollbar-width: none;
  /* Internet Explorer 10+ */
  -ms-overflow-style: none;

  /* WebKit */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const TableHeaderCell = styled(({ isSticky, isLastStickyCell, ...props }) => (
  <Flex {...props} />
))<FlexProps & Pick<TableCellProps, 'isSticky' | 'isLastStickyCell'>>`
  background-color: ${colors.table.headerCell.backgroundColor};
  border-bottom: 1px solid ${colors.table.headerCell.borderColor};
  padding: ${spacing.table.cell};
  ${({ isSticky }) => (isSticky ? StickyCellStyles : '')}

  &:first-child {
    border-top-left-radius: ${sizing.radius.base};
  }

  &:last-child {
    border-top-right-radius: ${sizing.radius.base};
  }
`

const TableCell = styled(({ isSticky, isLastStickyCell, ...props }) => (
  <Flex {...props} />
))<TableCellProps>`
  background-color: ${colors.table.cell.backgroundColor};
  border-bottom: 1px solid ${colors.table.row.borderColor};
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.table.cell};
  ${({ isSticky }) => (isSticky ? StickyCellStyles : '')}
`

const TableRowContainer = styled.div<TableRowProps>`
  &:last-of-type ${TableCell} {
    border-bottom: 0 none;
  }
`

const TableCellContentTextWrap = styled(Text)`
  word-break: break-all;
`

const TableCellContentTextNoWrap = styled(Text)`
  white-space: nowrap;
`

const TableCellContentTextEllipsis = styled(Text).attrs(({ children }) => ({
  title: children?.toString(),
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(95%);
`

const TableCellContentCentered = styled(Flex)`
  align-items: center;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const CenteredFlex = styled(Flex)({
  maxHeight: '95%',
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
})

const TableFlexContainer = styled(Flex)<
  FlexProps & { $hasStickyColumns?: boolean }
>`
  ${TableContainerStyles}

  ${TableHeaderCell} {
    background-color: transparent;
  }
`

type ContainerProps = Pick<
  TableProps,
  'children' | 'className' | 'containerProps' | 'withoutContainer'
> & {
  $hasStickyColumns?: boolean
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(function Container(
  {
    $hasStickyColumns,
    children,
    className,
    containerProps,
    withoutContainer,
  }: ContainerProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return withoutContainer ? (
    <TableFlexContainer
      {...containerProps}
      $hasStickyColumns={$hasStickyColumns}
      className={className}
      column
      fullWidth
      ref={ref}
    >
      {children}
    </TableFlexContainer>
  ) : (
    <TableCardContainer
      noPadding
      {...containerProps}
      $hasStickyColumns={$hasStickyColumns}
      className={className}
      fullWidth
      noShadow
      ref={ref}
    >
      {children}
    </TableCardContainer>
  )
})

type ReactTableHookOptions<T extends object = {}> = Omit<
  UseTableOptions<T>,
  'columns' | 'data'
>

type TableListProps<T extends object = {}> = Omit<
  ListProps<Row<T>, RowData<T>>,
  'virtualizeProps' | 'virtualize'
> & {
  virtualizeProps?: {
    canLoadMoreItems?: boolean
    isItemLoaded?: NonNullable<
      ListProps<Row<T>, RowData<T>>['virtualizeProps']
    >['isItemLoaded']
    itemCount?: NonNullable<
      ListProps<Row<T>, RowData<T>>['virtualizeProps']
    >['itemCount']
    infiniteLoaderProps?: NonNullable<
      ListProps<Row<T>, RowData<T>>['virtualizeProps']
    >['infiniteLoaderProps']
    infiniteLoaderRef?: NonNullable<
      ListProps<Row<T>, RowData<T>>['virtualizeProps']
    >['infiniteLoaderRef']
    virtualListProps?: NonNullable<
      ListProps<Row<T>, RowData<T>>['virtualizeProps']
    >['virtualListProps']
    virtualListRef?: NonNullable<
      ListProps<Row<T>, RowData<T>>['virtualizeProps']
    >['virtualListRef']
  }
}

type TableColumn<T extends object = {}> = Omit<
  Column<T>,
  'accessor' | 'Cell'
> & {
  // Types from react-table are crap ¯\_(ツ)_/¯
  // https://github.com/tannerlinsley/react-table/issues/1591

  /**
   * - This string/function is used to build the data model for your column.
   * - The data returned by an accessor should be primitive and sortable.
   * - If a string is passed, the column's value will be looked up on the
   * original row via that key, eg. If your column's accessor is `firstName`
   * then its value would be read from `row['firstName']`. You can also
   * specify deeply nested values with accessors like info.hobbies or even
   * `address[0].street`.
   * - If a function is passed, the column's value will be looked up on the
   * original row using this accessor function, eg. If your column's
   * accessor is row => `row.firstName`, then its value would be determined
   * by passing the row to this function and using the resulting value.
   *
   * {@link https://react-table-omega.vercel.app/docs/api/useTable#column-options}
   */
  accessor: Column<T>['accessor']
  /**
   * - Defaults to `({ value }) => String(value)`.
   * - Receives the table instance and cell model as props.
   * - Must return valid JSX.
   * - This function (or component) is primarily used for formatting the
   * column value, eg. If your column accessor returns a date object, you can
   * use a Cell function to format that date to a readable format.
   *
   * {@link https://react-table-omega.vercel.app/docs/api/useTable#column-options}
   */
  Cell?: React.ReactNode | ((cell: Cell<T, unknown>) => React.ReactNode)
  /**
   * Makes the column stick to the left, allowing the table to be scrolled
   * horizontally to reveal more content.
   *
   * **Remember to make sure that sticky columns are defined first, from
   * left to right.**
   */
  sticky?: boolean
  /**
   * If `true` the cell will not render the default long dash (—) when the
   * its original value is `''`, `null` or `undefined`. This allows you to
   * define custom rendering logic for dealing with empty cell values.
   */
  disableEmptyPlaceholder?: boolean
}

type TableProps<T extends object = {}> =
  React.HTMLAttributes<HTMLDivElement> & {
    /**
     * The core columns configuration object for the entire table.
     * {@link https://react-table-omega.vercel.app/docs/api/useTable#table-options}
     */
    columns: Array<TableColumn<T> | null>
    /**
     * Props to be passed to the inner `<Card>` component used as the table's
     * container.
     */
    containerProps?: CardProps
    /**
     * The raw data used to render the table.
     */
    data: T[]
    /**
     * If `true`, the Vori logo at the on of the table will not be displayed.
     */
    disableEndIndicator?: boolean
    /**
     * Properties to pass to the end indicator <Logo /> component.
     */
    endIndicatorProps?: LogoProps
    /**
     * Used to render your own empty state message if needed.
     */
    emptyStateText?: React.ReactNode
    /**
     * Props to be passed to the inner `<List>` component used to render the
     * table's content.
     */
    listProps?: TableListProps<T>
    /**
     * Properties to pass to the end indicator <Loader /> component.
     */
    loaderProps?: LoaderProps
    /**
     * Props to be passed to the `react-table` hook when creating the table's
     * props.
     * {@link https://react-table-omega.vercel.app/docs/api/useTable#table-options}
     */
    reactTableProps?: ReactTableHookOptions<T>
    /**
     * Custom render function used to render table cells.
     */
    renderCell?: (
      cell: Cell<T>,
      props: TableCellProps,
      helpers: {
        renderContent: () => JSX.Element
      },
    ) => React.ReactNode
    /**
     * Custom render function used to render table header cells.
     */
    renderHeaderCell?: (
      column: Column<T>,
      props: TableHeaderProps,
      helpers: {
        renderContent: () => JSX.Element
      },
    ) => React.ReactNode
    /**
     * Custom render function used to render table rows.
     */
    renderRow?: (
      row: Row<T>,
      props: TableRowProps,
      helpers: {
        getCellProps: (cell: Cell<T>, index: number) => TableCellProps
        renderCell: (cell: Cell<T>, index: number) => React.ReactNode
      },
    ) => React.ReactNode
    /**
     * If `true`, the table's empty state will be displayed.
     */
    showEmpty?: boolean
    /**
     * If `true`, the table's initial loading state will be displayed.
     */
    showLoader?: boolean
    /**
     * If `true`, the table will be rendered without the `<Card>` component used
     * as its container.
     */
    withoutContainer?: boolean
  }

const defaultProps: Partial<TableProps> = {
  className: '',
  containerProps: CardDefaultProps,
  disableEndIndicator: false,
  endIndicatorProps: {},
  loaderProps: {},
  showEmpty: false,
  showLoader: false,
  withoutContainer: false,
}

type RowData<T extends object = {}> = Pick<
  TableProps<T>,
  | 'disableEndIndicator'
  | 'endIndicatorProps'
  | 'loaderProps'
  | 'renderCell'
  | 'renderRow'
> & {
  columns: Array<TableColumn<T>>
  fixRowWidth: boolean
  prepareRow: UseTableInstanceProps<T>['prepareRow']
}

function getCellProps<T extends object = {}>(
  stickyCells: Array<Cell<T>>,
  data: ListItemProps<Row<T>, RowData<T>>['data'],
  row: Row<T>,
  cell: Cell<T>,
  index: number,
): TableCellProps {
  const isSticky = data.itemsData?.columns[index]?.sticky

  const isLastStickyCell =
    !data.itemsData?.columns[index + 1]?.sticky && isSticky

  const previousCellsWidth: number =
    isSticky && index > 0
      ? stickyCells.reduce(
          (width, stickyCell) =>
            width +
              Number.parseFloat(`${stickyCell.getCellProps().style?.width}`) ||
            0,
          0,
        ) || 0
      : 0

  if (isSticky) {
    stickyCells.push(cell)
  }

  return {
    ...cell.getCellProps(),
    isLastStickyCell,
    isSticky,
    key: `table-cell-${cell.column.id}-${row.id}`,
    style: {
      ...cell.getCellProps().style,
      ...(isSticky && {
        left: index === 0 ? 0 : previousCellsWidth || 0,
      }),
    },
  }
}

const TableRow = memo(function InnerTableRow<T extends object = {}>({
  data,
  index,
  style,
}: ListItemProps<Row<T>, RowData<T>>) {
  if (!data.isItemLoaded(index)) {
    return (
      <Flex center data-last-row fullWidth key="table-row-loader" style={style}>
        <Loader size="base" {...data.itemsData?.loaderProps} />
      </Flex>
    )
  }

  if (index === data.itemCount - 1 && !data.itemsData?.disableEndIndicator) {
    return (
      <Flex center data-last-row fullWidth key="table-row-logo" style={style}>
        <Logo size="tiny" {...data.itemsData?.endIndicatorProps} />
      </Flex>
    )
  }

  const row = data.items[index]
  data.itemsData?.prepareRow(row)
  const { key, ...rowPropsWithoutKey } = row.getRowProps({ style })

  const stickyCells: Array<Cell<T>> = []

  const renderCell = (cell: Cell<T>, index: number) => {
    const cellProps = getCellProps(stickyCells, data, row, cell, index)

    const content = (
      <>
        {!data.itemsData?.columns[index].disableEmptyPlaceholder &&
        (cell.value === '' || cell.value == null) ? (
          <Text size="body" variant="secondary">
            —
          </Text>
        ) : (
          <Text size="body" noSemantic>
            {cell.render('Cell') || cell.value}
          </Text>
        )}
      </>
    )

    return data.itemsData?.renderCell != null ? (
      data.itemsData.renderCell(cell, cellProps, {
        renderContent: () => content,
      })
    ) : (
      <TableCell {...cellProps}>{content}</TableCell>
    )
  }

  if (data.itemsData?.columns.some((column) => column.sticky)) {
    rowPropsWithoutKey.style = {
      ...(rowPropsWithoutKey.style || {}),
      width: data.itemsData.fixRowWidth ? '100%' : 'unset',
    }
  }

  return (
    <>
      {data.itemsData?.renderRow != null ? (
        data.itemsData.renderRow(
          row,
          {
            ...rowPropsWithoutKey,
            ...({
              'data-content-row': '',
            } as React.DataHTMLAttributes<HTMLDivElement>),
            key: `table-row-${row.id}`,
          },
          {
            getCellProps: (cell, index) =>
              getCellProps(stickyCells, data, row, cell, index),
            renderCell,
          },
        )
      ) : (
        <TableRowContainer
          {...rowPropsWithoutKey}
          data-content-row
          key={`table-row-${row.id}`}
        >
          {row.cells.map(renderCell)}
        </TableRowContainer>
      )}
    </>
  )
})

const StyledTable = styled(
  forwardRef<HTMLDivElement, TableProps>(function Table<T extends object = {}>(
    {
      className,
      columns: originalColumns,
      containerProps,
      data: originalData,
      disableEndIndicator,
      emptyStateText = (
        <Text size="h3" variant="secondary">
          No results found...
        </Text>
      ),
      endIndicatorProps,
      listProps: originalListProps,
      loaderProps,
      reactTableProps,
      renderCell,
      renderHeaderCell,
      renderRow,
      showEmpty,
      showLoader,
      withoutContainer,
    }: TableProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const [tableHeaderElement, setTableHeaderElement] =
      useState<HTMLDivElement | null>(null)

    const [virtualListOuterElement, setVirtualListOuterElement] =
      useState<HTMLDivElement | null>(null)

    const [fixRowWidth, setFixRowWidth] = useState(false)

    const data = useMemo(() => originalData, [originalData])

    const columns = useMemo(
      () =>
        compact(originalColumns).sort(
          // Sort columns by sticky to group sticky columns
          // to the left of the table
          (columnA, columnB) =>
            Number(Boolean(columnB.sticky)) - Number(Boolean(columnA.sticky)),
        ),
      [originalColumns],
    )

    const hasStickyColumns = useMemo(
      () => columns.some((column) => column.sticky),
      [columns],
    )

    const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } =
      useTable(
        {
          ...reactTableProps,
          columns: columns as Array<Column<T>>,
          data: showEmpty || showLoader ? [] : data,
        },
        useFlexLayout,
      )

    const hideEndIndicator = useMemo(
      () => showEmpty || showLoader || disableEndIndicator,
      [disableEndIndicator, showEmpty, showLoader],
    )

    const listProps = useMemo<ListProps<Row<T>, RowData<T>>>(
      () => ({
        ...(originalListProps && { ...originalListProps }),
        fullHeightOffset:
          (originalListProps?.fullHeightOffset || 0) +
          (showEmpty || showLoader ? 0 : DEFAULT_HEADER_ROW_HEIGHT), // We need to subtract the height of the table's header row.
        virtualizeProps: {
          isItemLoaded: (index) =>
            !originalListProps?.virtualizeProps?.canLoadMoreItems ||
            index < rows.length,
          itemCount: rows.length + (hideEndIndicator ? 0 : 1),
          ...(originalListProps?.virtualizeProps || {}),
          items: rows,
          itemsData: {
            columns,
            disableEndIndicator: hideEndIndicator,
            endIndicatorProps,
            fixRowWidth,
            loaderProps,
            prepareRow,
            renderCell,
            renderRow,
          },
          renderItem: TableRow as unknown as ListItemMemo<Row<T>, RowData<T>>,
          virtualListProps: {
            itemSize: () => DEFAULT_CONTENT_ROW_HEIGHT,
            ...(originalListProps?.virtualizeProps?.virtualListProps || {}),
            outerRef: (ref) => {
              setVirtualListOuterElement(ref as HTMLDivElement)

              if (
                originalListProps?.virtualizeProps?.virtualListProps?.outerRef
              ) {
                assignRef(
                  originalListProps.virtualizeProps.virtualListProps.outerRef,
                  ref,
                )
              }
            },
            style: {
              overflow: 'visible',
              overflowY: 'auto',
            },
          },
        },
      }),
      [
        columns,
        endIndicatorProps,
        fixRowWidth,
        hideEndIndicator,
        loaderProps,
        originalListProps,
        prepareRow,
        renderCell,
        renderRow,
        rows,
        showEmpty,
        showLoader,
      ],
    )

    const onScrollHeader = useCallback(
      (event: Event) => {
        if (virtualListOuterElement != null) {
          const scrollLeft = (event.target as HTMLDivElement).scrollLeft

          const lastRowEl =
            virtualListOuterElement.querySelector<HTMLDivElement>(
              '[data-last-row]',
            )

          virtualListOuterElement.scrollLeft = scrollLeft

          if (lastRowEl != null) {
            lastRowEl.style.left = `${scrollLeft}px`
          }
        }
      },
      [virtualListOuterElement],
    )

    const onScrollContent = useCallback(
      (event: Event) => {
        if (tableHeaderElement != null) {
          const scrollLeft = (event.target as HTMLDivElement).scrollLeft

          const lastRowEl = (
            event.target as HTMLDivElement
          ).querySelector<HTMLDivElement>('[data-last-row]')

          tableHeaderElement.scrollLeft = scrollLeft

          if (lastRowEl != null) {
            lastRowEl.style.left = `${scrollLeft}px`
          }
        }
      },
      [tableHeaderElement],
    )

    const onMouseEnterContent = useCallback(() => {
      if (tableHeaderElement != null) {
        tableHeaderElement.removeEventListener('scroll', onScrollHeader)
      }
    }, [onScrollHeader, tableHeaderElement])

    const onMouseLeaveContent = useCallback(() => {
      if (tableHeaderElement != null) {
        tableHeaderElement.addEventListener('scroll', onScrollHeader, {
          passive: true,
        })
      }
    }, [onScrollHeader, tableHeaderElement])

    const onMouseEnterHeader = useCallback(() => {
      if (virtualListOuterElement != null) {
        virtualListOuterElement.removeEventListener('scroll', onScrollContent)
      }
    }, [onScrollContent, virtualListOuterElement])

    const onMouseLeaveHeader = useCallback(() => {
      if (virtualListOuterElement != null) {
        virtualListOuterElement.addEventListener('scroll', onScrollContent, {
          passive: true,
        })
      }
    }, [onScrollContent, virtualListOuterElement])

    /**
     * ### Scroll Syncing
     * We have to sync scrolling between the table's header and its content
     * so anytime we scroll within either of them, both parts of the table
     * move at the same time, giving the illusion that it's a single scrollable
     * element.
     *
     * This is needed because elements using `position: sticky;` can only
     * stick to the nearest ancestor with an explicit `overflow` CSS rule
     * defined, even if that ancestor isn't the nearest scrolling ancestor.
     *
     * In this case, the `<List>` component used to render the table's content
     * makes those cells only stick when scrolling within the `<List>`, so
     * then horizontally scrolling the table's header does not trigger the
     * sticky positioning for table content cells.
     *
     * **References**
     * - {@link https://developer.mozilla.org/en-US/docs/Web/CSS/position#sticky}
     */
    useEffect(() => {
      if (!hasStickyColumns) {
        return void 0
      }

      if (tableHeaderElement != null) {
        tableHeaderElement.addEventListener('mouseenter', onMouseEnterHeader)
        tableHeaderElement.addEventListener('mouseleave', onMouseLeaveHeader)

        tableHeaderElement.addEventListener('scroll', onScrollHeader, {
          passive: true,
        })
      }

      if (virtualListOuterElement != null) {
        virtualListOuterElement.addEventListener(
          'mouseenter',
          onMouseEnterContent,
        )
        virtualListOuterElement.addEventListener(
          'mouseleave',
          onMouseLeaveContent,
        )

        virtualListOuterElement.addEventListener('scroll', onScrollContent, {
          passive: true,
        })
      }

      return () => {
        if (tableHeaderElement != null) {
          tableHeaderElement.removeEventListener(
            'mouseenter',
            onMouseEnterHeader,
          )

          tableHeaderElement.removeEventListener(
            'mouseleave',
            onMouseLeaveHeader,
          )

          tableHeaderElement.removeEventListener('scroll', onScrollHeader)
        }

        if (virtualListOuterElement != null) {
          virtualListOuterElement.removeEventListener(
            'mouseenter',
            onMouseEnterContent,
          )
          virtualListOuterElement.removeEventListener(
            'mouseleave',
            onMouseLeaveContent,
          )
          virtualListOuterElement.removeEventListener('scroll', onScrollContent)
        }
      }
    }, [
      hasStickyColumns,
      onMouseEnterContent,
      onMouseEnterHeader,
      onMouseLeaveContent,
      onMouseLeaveHeader,
      onScrollContent,
      onScrollHeader,
      tableHeaderElement,
      virtualListOuterElement,
    ])

    return (
      <Container
        {...containerProps}
        {...getTableProps()}
        $hasStickyColumns={hasStickyColumns}
        className={className}
        ref={(container) => {
          assignRef(ref, container)

          if (container != null) {
            const contentRow =
              container.querySelector<HTMLDivElement>('[data-content-row]')

            if (contentRow != null) {
              setFixRowWidth(container.offsetWidth > contentRow.offsetWidth)
            }
          }
        }}
        withoutContainer={withoutContainer}
      >
        <List
          {...listProps}
          {...getTableBodyProps()}
          renderHeader={() => (
            <>
              {headerGroups.map((headerGroup) => {
                const stickyHeaders: Array<HeaderGroup<T>> = []

                return (
                  <TableHeader
                    {...headerGroup.getHeaderGroupProps()}
                    $hasStickyColumns={hasStickyColumns}
                    key={`table-header-group-${headerGroup.id}`}
                    ref={(ref) => {
                      setTableHeaderElement(ref)
                    }}
                  >
                    {headerGroup.headers.map((column, index) => {
                      const isSticky = columns[index].sticky

                      const isLastStickyCell =
                        !columns[index + 1]?.sticky && isSticky

                      const previousHeadersWidth: number =
                        isSticky && index > 0
                          ? stickyHeaders.reduce(
                              (width, stickyCell) =>
                                width +
                                  Number.parseFloat(
                                    `${
                                      stickyCell.getHeaderProps().style?.width
                                    }`,
                                  ) || 0,
                              0,
                            ) || 0
                          : 0

                      if (isSticky) {
                        stickyHeaders.push(column)
                      }

                      return renderHeaderCell != null ? (
                        renderHeaderCell(
                          column,
                          {
                            ...column.getHeaderProps(),
                            isLastStickyCell,
                            isSticky: columns[index].sticky,
                          },
                          {
                            renderContent: function renderHeaderCellContent() {
                              return <>{column.render('Header')}</>
                            },
                          },
                        )
                      ) : (
                        <TableHeaderCell
                          {...column.getHeaderProps()}
                          alignItems="center"
                          isLastStickyCell={isLastStickyCell}
                          isSticky={columns[index].sticky}
                          key={`table-header-colum-${column.id}`}
                          style={{
                            ...column.getHeaderProps().style,
                            ...(isSticky && {
                              left: index === 0 ? 0 : previousHeadersWidth || 0,
                            }),
                          }}
                        >
                          <Text size="label">{column.render('Header')}</Text>
                        </TableHeaderCell>
                      )
                    })}
                  </TableHeader>
                )
              })}

              {showEmpty && (
                <CenteredFlex center column flex={1} fullWidth>
                  <Spacer />
                  {emptyStateText}
                  <Spacer />
                </CenteredFlex>
              )}

              {showLoader && (
                <CenteredFlex center column flex={1} fullWidth>
                  <Spacer />
                  <Loader />
                  <Spacer />
                </CenteredFlex>
              )}
            </>
          )}
          virtualize
        />
      </Container>
    )
  }),
)``

StyledTable.displayName = 'Table'
StyledTable.defaultProps = defaultProps

export type {
  TableCellProps,
  TableColumn,
  TableHeaderProps,
  TableProps,
  TableRowProps,
}

export {
  defaultProps as TableDefaultProps,
  TableCell,
  TableCellContentCentered,
  TableCellContentTextEllipsis,
  TableCellContentTextNoWrap,
  TableCellContentTextWrap,
  TableHeaderCell,
  TableRowContainer as TableRow,
}

// Need to re-cast due to the forwardRef return value.
// @see {@link https://stackoverflow.com/a/58473012}

export default StyledTable as <T extends object = {}>(
  props: TableProps<T> & { ref?: React.Ref<HTMLSpanElement> },
) => React.ReactElement
