import * as Sentry from '@sentry/browser'

export function getLocalStorageRecord<T extends Record<string, unknown>>(
  key: string,
): T | null {
  const value = localStorage.getItem(key) || null

  try {
    return value ? JSON.parse(value) : null
  } catch (err) {
    Sentry.captureException(err, { extra: { key } })
  }

  return null
}

export function setLocalStorageRecord<T extends Record<string, unknown>>(
  key: string,
  value: T,
): void {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    Sentry.captureException(err, { extra: { key, value } })
  }
}

export function updateLocalStorageRecord<T extends Record<string, unknown>>(
  key: string,
  value: T,
): void {
  const prevValue = getLocalStorageRecord<T>(key)
  setLocalStorageRecord(key, prevValue ? { ...prevValue, ...value } : value)
}
