import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import {
  addDays,
  differenceInCalendarWeeks,
  eachDayOfInterval,
  endOfMonth,
  isAfter,
  isBefore,
  isFuture,
  isPast,
  isSameDay,
  isThisMonth,
  isToday,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
} from 'date-fns'

type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type WeekStart = 0 | 1 | 2 | 3 | 4 | 5 | 6

interface Options {
  daysInWeek?: number
  disableDatesAfter?: Date
  disableDatesBefore?: Date
  disabledDates?: Date[]
  disableFutureDates?: boolean
  disablePastDates?: boolean
  maxSelected?: number
  month?: Month
  rangeSelection?: boolean
  selectedDates?: Date[]
  weekStartsOn?: WeekStart
  year?: number
}

interface CalendarDate {
  date: Date
  isAfterToday: boolean
  isBeforeToday: boolean
  isDisabled: boolean
  isFromCurrentMonth: boolean
  isInSelectedRange: boolean
  isSelected: boolean
  isToday: boolean
  removeDate: () => void
  selectDate: () => void
  toggleDate: () => void
}

type Action =
  | { type: 'CLEAR_DATES'; dispatch: (action: Action) => void }
  | { type: 'GET_NEXT_MONTH'; dispatch: (action: Action) => void }
  | { type: 'GET_PREV_MONTH'; dispatch: (action: Action) => void }
  | { type: 'REMOVE_DATE'; date: Date; dispatch: (action: Action) => void }
  | { type: 'SELECT_DATE'; date: Date; dispatch: (action: Action) => void }
  | { type: 'TOGGLE_DATE'; date: Date; dispatch: (action: Action) => void }
  | {
      type: 'SETUP'
      dispatch: (action: Action) => void
      options: Required<Options>
    }

type State = Required<
  Omit<Options, 'disableDatesAfter' | 'disableDatesBefore'>
> & {
  dates?: CalendarDate[][]
  datesWithinRangeOfSelectedDates: Array<Date>
  disableDatesAfter?: Date
  disableDatesBefore?: Date
  formattedMonth?: string
  formattedYear?: string
}

interface HookReturn extends Required<State> {
  clearDates: () => void
  getNextMonth: () => void
  getPrevMonth: () => void
}

type GenerateDatesOptions = Required<
  Omit<Options, 'maxSelected' | 'disableDatesAfter' | 'disableDatesBefore'>
> & {
  disableDatesAfter?: Date
  disableDatesBefore?: Date
  dispatch: (action: Action) => void
}

const TODAY_DATE = new Date()

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  year: 'numeric',
})

function sortDates(dates: Array<Date>): Array<Date> {
  return dates.sort((dateA, dateB) => dateA.getTime() - dateB.getTime())
}

function getSelectedDatesRange(selectedDates: Array<Date>): Array<Date> {
  const sortedSelectedDates = sortDates(selectedDates)

  return sortedSelectedDates.length > 0
    ? eachDayOfInterval({
        start: sortedSelectedDates[0],
        end: sortedSelectedDates.slice(-1)[0],
      })
    : []
}

function isWithinSelectedRange(
  date: Date,
  selectedDates: Array<Date>,
): boolean {
  const sortedSelectedDates = sortDates(selectedDates)

  return sortedSelectedDates.length > 0
    ? isWithinInterval(date, {
        start: sortedSelectedDates[0],
        end: sortedSelectedDates.slice(-1)[0],
      })
    : false
}

function generateDates({
  daysInWeek,
  disableDatesAfter,
  disableDatesBefore,
  disabledDates,
  disableFutureDates,
  disablePastDates,
  dispatch,
  month,
  selectedDates,
  weekStartsOn,
  year,
}: GenerateDatesOptions): CalendarDate[][] {
  const date = new Date(year, month)
  const startDay = startOfMonth(date)
  const lastDay = endOfMonth(date)
  const startDate = startOfWeek(startDay, { weekStartsOn })

  const rows =
    differenceInCalendarWeeks(lastDay, startDay, { weekStartsOn }) + 1

  const cols = daysInWeek
  const totalDays = rows * cols

  return Array.from({ length: totalDays })
    .map((_, index) => addDays(startDate, index))
    .reduce((dates, _current, index, days) => {
      if (index % cols !== 0) {
        return dates
      }

      return [
        ...dates,
        days.slice(index, index + cols).map(
          (currentDate): CalendarDate => ({
            date: currentDate,
            isAfterToday: isFuture(currentDate),
            isBeforeToday: isPast(currentDate),
            isDisabled:
              disabledDates.find((selectedDate) =>
                isSameDay(selectedDate, currentDate),
              ) !== undefined ||
              (disablePastDates &&
                !isToday(currentDate) &&
                isBefore(currentDate, TODAY_DATE)) ||
              (disableFutureDates &&
                !isToday(currentDate) &&
                isAfter(currentDate, TODAY_DATE)) ||
              (disableDatesAfter !== undefined &&
                isAfter(currentDate, disableDatesAfter)) ||
              (disableDatesBefore !== undefined &&
                isBefore(currentDate, disableDatesBefore)),
            isFromCurrentMonth: isThisMonth(currentDate),
            isInSelectedRange: isWithinSelectedRange(
              currentDate,
              selectedDates,
            ),
            isSelected:
              selectedDates.find((selectedDate) =>
                isSameDay(selectedDate, currentDate),
              ) !== undefined,
            isToday: isToday(currentDate),
            removeDate: (): void =>
              dispatch({ type: 'REMOVE_DATE', date: currentDate, dispatch }),
            selectDate: (): void =>
              dispatch({ type: 'SELECT_DATE', date: currentDate, dispatch }),
            toggleDate: (): void =>
              dispatch({ type: 'TOGGLE_DATE', date: currentDate, dispatch }),
          }),
        ) as CalendarDate[],
      ]
    }, [] as CalendarDate[][])
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'GET_NEXT_MONTH': {
      const { dates, ...options } = state
      const nextMonth = (state.month >= 12 ? 1 : state.month + 1) as Month
      const nextYear = state.month >= 12 ? state.year + 1 : state.year
      const nextDate = new Date(nextYear, nextMonth)

      return {
        ...state,
        dates: generateDates({
          ...options,
          dispatch: action.dispatch,
          month: nextMonth,
          year: nextYear,
        }),
        formattedMonth: dateFormat
          .formatToParts(nextDate)
          .find(({ type }) => type === 'month')?.value,
        formattedYear: dateFormat
          .formatToParts(nextDate)
          .find(({ type }) => type === 'year')?.value,
        month: nextMonth,
        year: nextYear,
      }
    }

    case 'GET_PREV_MONTH': {
      const { dates, ...options } = state
      const nextMonth = (state.month <= 1 ? 12 : state.month - 1) as Month
      const nextYear = state.month <= 1 ? state.year - 1 : state.year
      const nextDate = new Date(nextYear, nextMonth)

      return {
        ...state,
        dates: generateDates({
          ...options,
          dispatch: action.dispatch,
          month: nextMonth,
          year: nextYear,
        }),
        formattedMonth: dateFormat
          .formatToParts(nextDate)
          .find(({ type }) => type === 'month')?.value,
        formattedYear: dateFormat
          .formatToParts(nextDate)
          .find(({ type }) => type === 'year')?.value,
        month: nextMonth,
        year: nextYear,
      }
    }

    case 'CLEAR_DATES': {
      const { dates, ...options } = state

      return {
        ...state,
        dates: generateDates({
          ...options,
          dispatch: action.dispatch,
          selectedDates: [],
        }),
        datesWithinRangeOfSelectedDates: [],
        selectedDates: [],
      }
    }

    case 'REMOVE_DATE': {
      const { dates, ...options } = state

      const selectedDates =
        state.selectedDates.find((selectedDate) =>
          isSameDay(selectedDate, action.date),
        ) === undefined
          ? state.selectedDates.filter((date) => !isSameDay(date, action.date))
          : state.selectedDates

      return {
        ...state,
        dates: generateDates({
          ...options,
          dispatch: action.dispatch,
          selectedDates,
        }),
        datesWithinRangeOfSelectedDates: getSelectedDatesRange(selectedDates),
        selectedDates,
      }
    }

    case 'SELECT_DATE': {
      const { dates, ...options } = state

      const selectedDates =
        state.selectedDates.find((selectedDate) =>
          isSameDay(selectedDate, action.date),
        ) === undefined
          ? state.rangeSelection && state.selectedDates.length === 2
            ? [state.selectedDates[0], action.date]
            : [
                ...state.selectedDates.slice(
                  state.selectedDates.length >= state.maxSelected ? 1 : 0,
                ),
                action.date,
              ]
          : state.selectedDates

      return {
        ...state,
        dates: generateDates({
          ...options,
          dispatch: action.dispatch,
          selectedDates,
        }),
        datesWithinRangeOfSelectedDates: getSelectedDatesRange(selectedDates),
        selectedDates,
      }
    }

    case 'TOGGLE_DATE': {
      const { dates, ...options } = state

      const selectedDates =
        state.selectedDates.find((selectedDate) =>
          isSameDay(selectedDate, action.date),
        ) === undefined
          ? state.rangeSelection && state.selectedDates.length === 2
            ? [action.date]
            : [
                ...state.selectedDates.slice(
                  state.selectedDates.length >= state.maxSelected ? 1 : 0,
                ),
                action.date,
              ]
          : state.selectedDates.filter((date) => !isSameDay(date, action.date))

      return {
        ...state,
        dates: generateDates({
          ...options,
          dispatch: action.dispatch,
          selectedDates,
        }),
        datesWithinRangeOfSelectedDates: getSelectedDatesRange(selectedDates),
        selectedDates,
      }
    }

    case 'SETUP': {
      const date = new Date(action.options.year, action.options.month)

      return {
        ...action.options,
        dates: generateDates({ ...action.options, dispatch: action.dispatch }),
        datesWithinRangeOfSelectedDates: getSelectedDatesRange(
          action.options.selectedDates || [],
        ),
        formattedMonth: dateFormat
          .formatToParts(date)
          .find(({ type }) => type === 'month')?.value,
        formattedYear: dateFormat
          .formatToParts(date)
          .find(({ type }) => type === 'year')?.value,
      }
    }

    default: {
      return state
    }
  }
}

/**
 * @deprecated Use new `useCalendar` hook from `<CalendarNext>`
 */
const useCalendar = (
  {
    daysInWeek = 7,
    disableDatesAfter = undefined,
    disableDatesBefore = undefined,
    disabledDates = [],
    disableFutureDates = false,
    disablePastDates = false,
    maxSelected = Infinity,
    month = new Date().getMonth() as Month,
    rangeSelection = false,
    selectedDates = [],
    weekStartsOn = 0,
    year = new Date().getFullYear(),
  }: Options = {
    daysInWeek: 7,
    disableDatesAfter: undefined,
    disableDatesBefore: undefined,
    disabledDates: [],
    disableFutureDates: false,
    disablePastDates: false,
    maxSelected: Infinity,
    month: new Date().getMonth() as Month,
    rangeSelection: false,
    selectedDates: [],
    weekStartsOn: 0,
    year: new Date().getFullYear(),
  },
): HookReturn => {
  const options = useMemo(
    () => ({
      daysInWeek,
      disableDatesAfter,
      disableDatesBefore,
      disabledDates,
      disableFutureDates,
      disablePastDates,
      maxSelected: rangeSelection ? 2 : maxSelected,
      month,
      rangeSelection,
      selectedDates,
      weekStartsOn,
      year,
    }),
    [
      daysInWeek,
      disableDatesAfter,
      disableDatesBefore,
      disabledDates,
      disableFutureDates,
      disablePastDates,
      maxSelected,
      month,
      rangeSelection,
      selectedDates,
      weekStartsOn,
      year,
    ],
  )

  const previousOptions = useRef<Options | null>(null)

  const [state, dispatch] = useReducer(reducer, {
    ...options,
    dates: [],
    datesWithinRangeOfSelectedDates: [],
    formattedMonth: '',
    formattedYear: '',
  })

  const clearDates = useCallback(
    () => dispatch({ type: 'CLEAR_DATES', dispatch }),
    [],
  )

  const getNextMonth = useCallback(
    (): void => dispatch({ type: 'GET_NEXT_MONTH', dispatch }),
    [],
  )

  const getPrevMonth = useCallback(
    (): void => dispatch({ type: 'GET_PREV_MONTH', dispatch }),
    [],
  )

  useEffect(() => {
    if (!isEqual(options, previousOptions.current)) {
      previousOptions.current = options

      dispatch({
        type: 'SETUP',
        dispatch,
        options: options as Required<Options>,
      })
    }
  }, [options])

  return {
    ...(state as Required<State>),
    clearDates,
    getNextMonth,
    getPrevMonth,
  }
}

export type {
  CalendarDate as CalendarDateOptions,
  HookReturn as CalendarHookReturn,
  Options as CalendarOptions,
}

export default useCalendar
