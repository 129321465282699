import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Crop02IconProps = IconProps

const Crop02Icon = forwardRef<SVGSVGElement, Crop02IconProps>(
  (props: Crop02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 6h4.8c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C18 7.52 18 8.08 18 9.2V14M2 6h4m12 12v4m4-4H9.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C6 16.48 6 15.92 6 14.8V2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Crop02Icon.displayName = 'Crop02Icon'
Crop02Icon.defaultProps = iconDefaultProps

export type { Crop02IconProps }
export { Crop02Icon }
