import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCw01IconProps = IconProps

const RefreshCw01Icon = forwardRef<SVGSVGElement, RefreshCw01IconProps>(
  (props: RefreshCw01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 10s-2.005-2.732-3.634-4.362a9 9 0 1 0 2.282 8.862M21 10V4m0 6h-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCw01Icon.displayName = 'RefreshCw01Icon'
RefreshCw01Icon.defaultProps = iconDefaultProps

export type { RefreshCw01IconProps }
export { RefreshCw01Icon }
