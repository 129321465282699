import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Rss02IconProps = IconProps

const Rss02Icon = forwardRef<SVGSVGElement, Rss02IconProps>(
  (props: Rss02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 13.023c5.185-.78 8.756 2.792 7.977 7.977M3 8.038c7.938-.78 13.742 5.024 12.962 12.962M3 3.052C13.692 2.274 21.726 10.308 20.948 21M5 21a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Rss02Icon.displayName = 'Rss02Icon'
Rss02Icon.defaultProps = iconDefaultProps

export type { Rss02IconProps }
export { Rss02Icon }
