import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleUpRightIconProps = IconProps

const ArrowCircleUpRightIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleUpRightIconProps
>((props: ArrowCircleUpRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 15V9m0 0H9m6 0-6 6m13-3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleUpRightIcon.displayName = 'ArrowCircleUpRightIcon'
ArrowCircleUpRightIcon.defaultProps = iconDefaultProps

export type { ArrowCircleUpRightIconProps }
export { ArrowCircleUpRightIcon }
