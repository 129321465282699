import React from 'react'
import styled from 'styled-components'

import { GridItemCoreProps, GridItemProps } from './types'
import { gridItemStyles } from './styles'
import { propsToStyledProps } from '../utils'

const StyledGridItem = styled.div(gridItemStyles)
const defaultProps: Partial<GridItemCoreProps> = {}

/**
 * The `<GridItem>` component exposes CSS properties of the grid layout module,
 * that are specific to grid items, e.g. `align-self`, `grid-row-end`, etc.
 *
 * @example
 * <Grid templateColumns="1fr 1fr" fullWidth>
 *   <GridItem>Item 1</GridItem>
 *   <GridItem>Item 2</GridItem>
 * </Grid>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout}
 */
const GridItem = React.forwardRef<HTMLDivElement, GridItemProps>(
  function GridItem(
    {
      alignSelf,
      area,
      children,
      column,
      columnEnd,
      columnStart,
      justifySelf,
      placeSelf,
      row,
      rowEnd,
      rowStart,
      ...props
    }: GridItemProps,
    ref,
  ): JSX.Element {
    return (
      <StyledGridItem
        {...propsToStyledProps({
          alignSelf,
          area,
          column,
          columnEnd,
          columnStart,
          justifySelf,
          placeSelf,
          row,
          rowEnd,
          rowStart,
        })}
        {...props}
        data-gourmet-grid-item=""
        ref={ref}
      >
        {children}
      </StyledGridItem>
    )
  },
)

GridItem.displayName = 'GridItem'
GridItem.defaultProps = defaultProps

export { GridItem, defaultProps as gridItemDefaultProps }
