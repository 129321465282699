import {
  TokenFoundationColor,
  TokenFoundationTypographyHeadingSize,
  TokenFoundationTypographyLineHeight,
  TokenFoundationTypographySize,
} from '@vori/gourmet-tokens'

import { TextColorVariant, TextSize } from './types'

export function colorVariantToColorToken(
  variant: TextColorVariant,
): TokenFoundationColor {
  switch (variant) {
    case 'default': {
      return 'color.gray-800'
    }

    case 'negative': {
      return 'color.error-600'
    }

    case 'positive': {
      return 'color.success-600'
    }

    case 'primary': {
      return 'color.primary-500'
    }

    case 'secondary': {
      return 'color.gray-500'
    }

    case 'warning': {
      return 'color.warning-600'
    }

    default: {
      return 'color.gray-800'
    }
  }
}

export function sizeToHeadingTag(
  size?: TextSize<TokenFoundationTypographyHeadingSize>,
): 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' {
  switch (size) {
    case 'heading-xs': {
      return 'h6'
    }

    case 'heading-sm': {
      return 'h5'
    }

    case 'heading-md': {
      return 'h4'
    }

    case 'heading-lg': {
      return 'h3'
    }

    case 'heading-xl': {
      return 'h2'
    }

    case 'heading-xxl': {
      return 'h1'
    }

    default: {
      return 'h1'
    }
  }
}

export function lineHeightFromSize(
  size: TextSize<TokenFoundationTypographySize>,
): TokenFoundationTypographyLineHeight {
  switch (size) {
    case 'heading-xs': {
      return 'typography.heading-xs-line-height'
    }

    case 'heading-sm': {
      return 'typography.heading-sm-line-height'
    }

    case 'heading-md': {
      return 'typography.heading-md-line-height'
    }

    case 'heading-lg': {
      return 'typography.heading-lg-line-height'
    }

    case 'heading-xl': {
      return 'typography.heading-xl-line-height'
    }

    case 'heading-xxl': {
      return 'typography.heading-xxl-line-height'
    }

    case 'text-xs': {
      return 'typography.text-xs-line-height'
    }

    case 'text-sm': {
      return 'typography.text-sm-line-height'
    }

    case 'text-md': {
      return 'typography.text-md-line-height'
    }

    case 'text-lg': {
      return 'typography.text-lg-line-height'
    }

    case 'text-xl': {
      return 'typography.text-xl-line-height'
    }

    default: {
      return 'typography.text-md-line-height'
    }
  }
}
