import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CheckIconProps = IconProps

const CheckIcon = forwardRef<SVGSVGElement, CheckIconProps>(
  (props: CheckIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 6 9 17l-5-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CheckIcon.displayName = 'CheckIcon'
CheckIcon.defaultProps = iconDefaultProps

export type { CheckIconProps }
export { CheckIcon }
