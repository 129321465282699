import React from 'react'

type HookOptions<T> = {
  /**
   * The name of the component where the hook is being used. This is used for
   * debugging purposes on development environments.
   */
  componentName?: string
  /**
   * The controlled value, if any.
   */
  controlledValue?: T
  /**
   * The default value, if any.
   */
  defaultValue: T
}

/**
 * Returns a tuple containing the value, a setter and finally a boolean flag
 * that denotes if the value is a controlled value.
 */
type HookReturn<T> = [T, React.Dispatch<React.SetStateAction<T>>, boolean]

/**
 * Returns a value and setter based on whether the component is controlled
 * or uncontrolled.
 *
 * @see {@link https://react.dev/reference/react-dom/components/textarea#im-getting-an-error-a-component-is-changing-an-uncontrolled-input-to-be-controlled}
 */
function useControlledState<T>({
  controlledValue,
  defaultValue,
  componentName = 'A component',
}: HookOptions<T>): HookReturn<T> {
  const wasControlled = controlledValue !== undefined
  const isControlledRef = React.useRef(wasControlled)

  if (process.env.NODE_ENV !== 'production') {
    if (!isControlledRef.current && wasControlled) {
      console.warn(
        `${componentName} is changing from controlled to uncontrolled, which should be avoided.`,
      )
    }

    if (isControlledRef.current && !wasControlled) {
      console.warn(
        `${componentName} is changing from uncontrolled to controlled, which should be avoided.`,
      )
    }
  }

  const [value, setValue] = React.useState<T>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isControlledRef.current ? controlledValue! : defaultValue,
  )

  const setter: React.Dispatch<React.SetStateAction<T>> = React.useCallback(
    (newValue) => {
      if (!isControlledRef.current) {
        setValue(newValue)
      }
    },
    [],
  )

  return [
    isControlledRef.current ? (controlledValue as T) : value,
    setter,
    isControlledRef.current,
  ]
}

export { useControlledState }
