import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { colors } from '../tokens'

import Input, { InputDefaultProps, InputProps } from '../Input'

type EditableProps = InputProps

const defaultProps: Partial<EditableProps> = InputDefaultProps

/**
 * @deprecated Use `<InputNext asEditable />` instead.
 */
const StyledEditable = styled(
  forwardRef<HTMLInputElement, EditableProps>(function Editable(
    props: EditableProps,
    ref,
  ) {
    return <Input {...props} data-gourmet-editable ref={ref} />
  }),
)<EditableProps>`
  &&&,
  &&&:active,
  &&&:focus,
  &&&:focus-within,
  &&&:read-only,
  &&&[data-readonly],
  &&&[data-state-active='true'],
  &&&[data-state-focus='true'] {
    background-color: ${colors.editable.backgroundColor};
    border-color: ${colors.editable.borderColor};
    box-shadow: none;
  }
`

StyledEditable.displayName = 'Editable'
StyledEditable.defaultProps = defaultProps

export type { EditableProps }
export { defaultProps as EditableDefaultProps }
export default StyledEditable
