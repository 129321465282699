import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserLeft01IconProps = IconProps

const UserLeft01Icon = forwardRef<SVGSVGElement, UserLeft01IconProps>(
  (props: UserLeft01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m19 21-3-3m0 0 3-3m-3 3h6m-10-2.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserLeft01Icon.displayName = 'UserLeft01Icon'
UserLeft01Icon.defaultProps = iconDefaultProps

export type { UserLeft01IconProps }
export { UserLeft01Icon }
