import React, { forwardRef, useState } from 'react'
import styled, { CSSObject } from 'styled-components'

import { colors, spacing } from '../tokens'

import Flex, { FlexProps } from '../Flex'

type AppFrameColorVariant = keyof typeof colors.appFrame.backgroundColor

const FixedContainer = styled(Flex)<{ $topOffset?: number }>(
  ({ $topOffset }) => ({
    ...($topOffset != null && { height: `calc(100% - ${$topOffset}px)` }),
    left: 0,
    position: 'fixed',
    top: $topOffset != null ? `${$topOffset}px` : 0,
    zIndex: 100,
  }),
)

type ContainerProps = {
  $noPaddingBottom?: boolean
  $noPaddingLeft?: boolean
  $noPaddingRight?: boolean
  $noPaddingTop?: boolean
  $paddingLeft: number
  $paddingTop: number
  $variant?: AppFrameColorVariant
}

const ContentContainer = styled(Flex)<ContainerProps>(
  ({
    $noPaddingBottom,
    $noPaddingLeft,
    $noPaddingRight,
    $noPaddingTop,
    $paddingLeft,
    $paddingTop,
    $variant,
  }) => ({
    backgroundColor: colors.appFrame.backgroundColor[$variant || 'default'],
    minHeight: '100vh',
    paddingBottom: $noPaddingBottom ? 0 : spacing.appFrame.y,
    paddingLeft: $noPaddingLeft
      ? `${$paddingLeft}px`
      : `calc(${$paddingLeft}px + ${spacing.appFrame.x})`,
    paddingRight: $noPaddingRight ? 0 : spacing.appFrame.x,
    paddingTop: `calc(${$paddingTop}px + ${
      $noPaddingTop ? '0px' : spacing.appFrame.y
    })`,
  }),
)

type AppFrameProps = React.HTMLAttributes<HTMLDivElement> & {
  containerProps?: FlexProps & {
    noPaddingBottom?: boolean
    noPaddingLeft?: boolean
    noPaddingRight?: boolean
    noPaddingTop?: boolean
    variant?: AppFrameColorVariant
  }
  header: React.ReactElement
  sidebar?: React.ReactElement
}

const defaultProps: Partial<AppFrameProps> = {
  className: '',
  containerProps: {},
}

const styles: CSSObject = {
  minHeight: '100vh',
  maxWidth: '100vw',
  position: 'relative',
}

const StyledAppFrame = styled(
  forwardRef<HTMLDivElement, AppFrameProps>(function AppFrame(
    { children, containerProps = {}, header, sidebar, ...props }: AppFrameProps,
    ref,
  ) {
    const [headerHeight, setHeaderHeight] = useState(0)
    const [sidebarWidth, setSidebarWidth] = useState(0)

    const {
      noPaddingBottom,
      noPaddingLeft,
      noPaddingRight,
      noPaddingTop,
      variant,
      ...flexProps
    } = containerProps

    return (
      <Flex
        {...props}
        alignItems="flex-start"
        flex={1}
        fullWidth
        justifyContent="flex-start"
        ref={ref}
      >
        <FixedContainer
          fullWidth
          ref={(fixedContainer) =>
            setHeaderHeight((prevHeaderHeight) =>
              prevHeaderHeight === 0
                ? fixedContainer?.offsetHeight || 0
                : prevHeaderHeight,
            )
          }
        >
          {header}
        </FixedContainer>
        {sidebar != null && (
          <FixedContainer
            $topOffset={headerHeight}
            fullHeight
            ref={(fixedContainer) =>
              setSidebarWidth((prevSidebarWidth) =>
                prevSidebarWidth === 0
                  ? fixedContainer?.offsetWidth || 0
                  : prevSidebarWidth,
              )
            }
          >
            {sidebar}
          </FixedContainer>
        )}
        <ContentContainer
          alignItems="flex-start"
          flex={1}
          fullWidth
          justifyContent="flex-start"
          {...flexProps}
          $noPaddingBottom={noPaddingBottom}
          $noPaddingLeft={noPaddingLeft}
          $noPaddingRight={noPaddingRight}
          $noPaddingTop={noPaddingTop}
          $paddingLeft={sidebarWidth}
          $paddingTop={headerHeight}
          $variant={variant}
        >
          {children}
        </ContentContainer>
      </Flex>
    )
  }),
)<AppFrameProps>(styles)

StyledAppFrame.displayName = 'AppFrame'
StyledAppFrame.defaultProps = defaultProps

export type { AppFrameProps }
export { defaultProps as AppFrameDefaultProps, styles as AppFrameStyles }
export default StyledAppFrame
