import React from 'react'

/**
 * Converts a callback to a React ref to avoid triggering re-renders when
 * passed as a prop and exposed as a "stable" function to avoid executing effects
 * when passed as a dependency.
 *
 * @see {@link https://react.dev/learn/referencing-values-with-refs}
 */
function useCreateStableCallbackHook<T extends (...args: never[]) => unknown>(
  useEffectHook: (
    effect: React.EffectCallback,
    deps?: React.DependencyList | undefined,
  ) => void,
  callback: T | null | undefined,
): T {
  const callbackRef = React.useRef(callback)

  useEffectHook(() => {
    callbackRef.current = callback
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(
    ((...args) => {
      if (callbackRef.current != null) {
        return callbackRef.current(...args)
      }
    }) as T,
    [],
  )
}

/**
 * Converts a callback to a ref to avoid triggering re-renders when passed as a
 * prop and exposed as a "stable" function to avoid executing effects when passed
 * as a dependency.
 *
 * @see {@link https://react.dev/learn/referencing-values-with-refs}
 */
export function useStableCallback<T extends (...args: never[]) => unknown>(
  callback: T | null | undefined,
): T {
  return useCreateStableCallbackHook(React.useEffect, callback)
}

/**
 * Converts a callback to a ref to avoid triggering re-renders when passed as a
 * prop and exposed as a "stable" function to avoid executing effects when passed
 * as a dependency.
 *
 * Use this over `useStableCallback` when you want the callback to be cached in
 * `useLayoutEffect` instead of `useEffect` to deal with timing issues only when
 * needed.
 *
 * @see {@link https://react.dev/learn/referencing-values-with-refs}
 * @see {@link https://react.dev/reference/react/useLayoutEffect#useinsertioneffect}
 */
export function useStableLayoutCallback<
  T extends (...args: never[]) => unknown,
>(callback: T | null | undefined): T {
  return useCreateStableCallbackHook(React.useLayoutEffect, callback)
}
