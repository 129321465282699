import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FaceWinkIconProps = IconProps

const FaceWinkIcon = forwardRef<SVGSVGElement, FaceWinkIconProps>(
  (props: FaceWinkIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 14s1.5 2 4 2 4-2 4-2m-1-5h.01M8 9h2m12 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-6.5-3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FaceWinkIcon.displayName = 'FaceWinkIcon'
FaceWinkIcon.defaultProps = iconDefaultProps

export type { FaceWinkIconProps }
export { FaceWinkIcon }
