import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CheckCircleIconProps = IconProps

const CheckCircleIcon = forwardRef<SVGSVGElement, CheckCircleIconProps>(
  (props: CheckCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m7.5 12 3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CheckCircleIcon.displayName = 'CheckCircleIcon'
CheckCircleIcon.defaultProps = iconDefaultProps

export type { CheckCircleIconProps }
export { CheckCircleIcon }
