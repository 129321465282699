import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Ticket02IconProps = IconProps

const Ticket02Icon = forwardRef<SVGSVGElement, Ticket02IconProps>(
  (props: Ticket02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 8V7m0 5.5v-1M8 17v-1m-1.2 4h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 17.72 22 16.88 22 15.2V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 4 18.88 4 17.2 4H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 6.28 2 7.12 2 8.8v6.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 20 5.12 20 6.8 20Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Ticket02Icon.displayName = 'Ticket02Icon'
Ticket02Icon.defaultProps = iconDefaultProps

export type { Ticket02IconProps }
export { Ticket02Icon }
