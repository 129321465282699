import { LoyaltyBonusType, PromotionStatus } from '@vori/dashboard-rest'
import { RouteUtil, createRouteUtil } from '@vori/dashboard-utils'

import { IncentiveType } from './pages/campaigns/types/incentive'
import { LoyaltyTab } from './pages/loyalty/types/types'
import {
  OldReportingTab,
  REPORTING_SECTIONS,
  ReportingTab,
} from './pages/reporting/types'
import { InvoiceCreationViewMode, InvoiceStatus } from './types'

const reportingRoutes: Record<
  ReportingTab,
  RouteUtil<`/retail/reporting/:section(${string})/:subsection(${string})`>
> = {} as Record<
  ReportingTab,
  RouteUtil<`/retail/reporting/:section(${string})/:subsection(${string})`>
>

Object.entries(REPORTING_SECTIONS).forEach(([sectionID, subsection]) => {
  reportingRoutes[sectionID as ReportingTab] = createRouteUtil(
    `/retail/reporting/:section(${sectionID})/:subsection(${Object.keys(subsection).join('|')})`,
    'Reporting',
  )
})

const routes = {
  catalogs: {
    /**
     * Lists all vendor catalogs.
     */
    root: createRouteUtil('/retail/catalogs', 'Vendor Catalogs'),
    /**
     * Displays a specific vendor catalog
     */
    detail: createRouteUtil('/retail/catalogs/:vendorID', 'Vendor Catalog'),
    /**
     * Displays a specific vendor product
     */
    productDetail: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID',
      'Product Details',
    ),
    /**
     * Displays a specific vendor product's order guides
     */
    productDetailOrderGuides: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID/order-guides',
      'Product Order Guides',
    ),
    /**
     * Displays a specific vendor product's pricing
     */
    productDetailPricing: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID/pricing',
      'Product Pricing',
    ),
    /**
     * Displays a specific vendor product's status
     */
    productDetailStatus: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID/status',
      'Product Status',
    ),
  },

  stores: {
    /**
     * Lists all stores.
     */
    root: createRouteUtil('/retail/stores', 'Stores'),
    /**
     * Lists all buyers from a specific store
     */
    buyers: createRouteUtil('/retail/stores/:storeID/buyers', 'Buyers'),
    /**
     * Displays form to create a new department for a specific store.
     */
    createBuyer: createRouteUtil(
      '/retail/stores/:storeID/buyers/new',
      'Create Buyer',
    ),
    /**
     * Displays a specific store
     */
    detail: createRouteUtil('/retail/stores/:storeID', 'Store'),
    /**
     * Lists all orders from a specific store
     */
    orders: createRouteUtil('/retail/stores/:id/orders', 'Store Orders'),
    /**
     * Lists all vendors from a specific store
     */
    vendors: createRouteUtil(
      '/retail/stores/:storeID/vendors',
      'Store Vendors',
    ),
    /**
     * Lists all departments for a specific store.
     */
    departments: createRouteUtil(
      '/retail/stores/:storeID/departments',
      'Store Departments',
    ),
    /**
     * Displays form to create a new department for a specific store.
     */
    createDepartment: createRouteUtil(
      '/retail/stores/:storeID/departments/new',
      'Create Store Department',
    ),
  },

  buyers: {
    /**
     * Displays a specific buyer
     */
    detail: createRouteUtil('/retail/stores/:storeID/buyer/:buyerID', 'Buyer'),
    /**
     * Displays a specific buyer's order guides
     */
    orderGuides: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/order-guides',
      'Buyer Order Guides',
    ),
    /**
     * Root orders route (catch-all); Will defer to
     * router for redirection.
     */
    ordersRoot: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders',
      'Buyer Orders',
    ),
    /**
     * Lists all orders from a specific buyer.
     */
    orders: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders/:filter',
      'Buyer Orders',
    ),
    /**
     * Lists all sent orders from a specific buyer.
     */
    ordersSent: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders/sent',
      'Buyer Orders',
    ),
    /**
     * Lists all confirmed orders from a specific buyer.
     */
    ordersConfirmed: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders/confirmed',
      'Buyer Orders',
    ),
  },

  profile: {
    /**
     * Lists all store-related account settings.
     */
    root: createRouteUtil('/retail/profile', 'Profile'),
  },

  home: {
    /**
     * Renders home page which lists numerous banner stats.
     */
    root: createRouteUtil('/retail/home', 'Home'),
  },

  itemModifiers: {
    root: createRouteUtil('/retail/item-modifiers', 'Item Modifiers'),
    create: createRouteUtil(
      '/retail/item-modifiers/new',
      'Create Item Modifier',
    ),
    update: createRouteUtil(
      '/retail/item-modifiers/:itemID/update',
      'Item Modifier Details',
    ),
  },

  departments: {
    /**
     * Displays a specific department.
     */
    root: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID',
      'Store Department',
    ),

    /**
     * Lists all vendors for a specific department.
     */
    vendors: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors',
      'Store Department Vendors',
    ),
    /**
     * Lists all departments.
     */
    list: createRouteUtil('/departments', 'Store Departments'),
    /**
     * Displays the add department modal
     */
    create: createRouteUtil('/departments/new', 'New Store Departments'),
    /**
     * Displays the add sub-department modal
     */
    createSubDepartment: createRouteUtil(
      '/departments/sub/new',
      'New Store Sub-Departments',
    ),
    /**
     * Displays the update department modal
     */
    update: createRouteUtil(
      '/departments/:departmentID/update',
      'Update Store Departments',
    ),
    /**
     * Lists a specific VSDA for a specific department.
     */
    vendorDetail: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors/:vendorID',
      'Store Department Vendor',
    ),
    /**
     * Lists a specific VSDA's update form for a specific department.
     */
    vendorDetailUpdate: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors/:vendorID/update',
      'Update Store Department Vendor',
    ),
    /**
     * Lists vendor products on a specific VSDA for a specific department.
     */
    vendorDetailProducts: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors/:vendorID/products',
      'Store Department Products',
    ),
    /**
     * Lists all orders for a specific department.
     */
    orders: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/orders',
      'Store Department Orders',
    ),
    /**
     * Lists a specific order for a specific department.
     */
    orderDetail: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/orders/:orderID',
      'Store Department Order',
    ),
  },

  search: {
    /**
     * Search for specific products across all vendors.
     */
    root: createRouteUtil('/retail/search', 'Universal Product Search'),
    /**
     * Displays a specific vendor product
     */
    productDetail: createRouteUtil(
      '/retail/search/:vendorID/product/:productID',
      'Universal Product Search',
    ),
    /**
     * Displays a specific vendor product's order guides
     */
    productDetailOrderGuides: createRouteUtil(
      '/retail/search/:vendorID/product/:productID/order-guides',
      'Universal Product Search',
    ),
    /**
     * Displays a specific vendor product's pricing
     */
    productDetailPricing: createRouteUtil(
      '/retail/search/:vendorID/product/:productID/pricing',
      'Universal Product Search',
    ),
    /**
     * Displays a specific vendor product's status
     */
    productDetailStatus: createRouteUtil(
      '/retail/search/:vendorID/product/:productID/status',
      'Universal Product Search',
    ),
  },

  invoices: {
    root: createRouteUtil('/retail/invoices', 'Invoices'),

    status: createRouteUtil(
      `/retail/invoices/status/:status(${Object.values(InvoiceStatus).join(
        '|',
      )})`,
      'Invoices',
    ),

    newInvoice: createRouteUtil(
      `/retail/invoices/new/:mode(${Object.values(InvoiceCreationViewMode).join(
        '|',
      )})`,
      'Invoices | New Invoice',
    ),

    newInvoiceAddVendor: createRouteUtil(
      `/retail/invoices/new/:mode(${Object.values(InvoiceCreationViewMode).join(
        '|',
      )})/vendor/add`,
      'Invoices | New Invoice | Add Vendor',
    ),

    invoiceDetail: createRouteUtil(
      '/retail/invoices/:invoiceID',
      'Invoices | Invoice Detail',
    ),

    invoiceOriginalFile: createRouteUtil(
      '/retail/invoices/:invoiceID/file',
      'Invoices | Invoice File',
    ),

    invoiceProductDetail: createRouteUtil(
      '/retail/invoices/:invoiceID/product/:productID',
      'Invoices | Product Detail',
    ),

    invoiceHistory: createRouteUtil(
      '/retail/invoices/:invoiceID/history',
      'Invoices | Invoice History',
    ),

    editInvoice: createRouteUtil(
      '/retail/invoices/:invoiceID/edit',
      'Invoices | Edit Invoice',
    ),

    editInvoiceAddVendor: createRouteUtil(
      '/retail/invoices/:invoiceID/edit/vendor/add',
      'Invoices | Edit Invoice | Add Vendor',
    ),
  },

  integrations: {
    root: createRouteUtil('/retail/integrations', 'Integrations'),
    integrationType: createRouteUtil(
      '/retail/integrations/:integrationType',
      'Integrations',
    ),
  },

  products: {
    root: createRouteUtil('/retail/products', 'Products'),
    detail: createRouteUtil('/retail/products/:productID', 'Product Details'),
    update: createRouteUtil(
      '/retail/products/:productID/update',
      'Update Product',
    ),
    bulkUpdate: createRouteUtil(
      '/retail/products/bulk-update',
      'Bulk Update Products',
    ),
    create: createRouteUtil('/retail/products/new', 'Create Product'),
    upload: createRouteUtil('/retail/products/upload', 'Upload Products'),
  },

  unauthorizedLineItems: {
    root: createRouteUtil('/retail/unauthorized-items', 'Unauthorized Items'),
  },

  taxes: {
    root: createRouteUtil('/taxes', 'Taxes'),
    create: createRouteUtil('/taxes/new', 'Create Tax'),
    update: createRouteUtil('/taxes/:taxId/update', 'Update Tax'),
  },

  eslUpload: {
    root: createRouteUtil('/retail/esl-upload', 'ESL Upload'),
  },

  vendors: {
    root: createRouteUtil('/vendors', 'Vendors'),
    create: createRouteUtil('/vendors/new', 'Create Vendor'),
    update: createRouteUtil('/vendors/:vendorID/update', 'Update Vendor'),
  },

  frontOffice: {
    root: createRouteUtil('/retail/front-office', 'Front Office'),
  },

  promotions: {
    root: createRouteUtil('/retail/promotions', 'Promotions'),

    status: createRouteUtil(
      `/retail/promotions/status/:status(All|${Object.values(
        PromotionStatus,
      ).join('|')})`,
      'Promotions',
    ),

    import: createRouteUtil('/retail/promotions/import', 'Promotions | Import'),

    create: createRouteUtil('/retail/promotions/create', 'Promotions | New'),

    detail: createRouteUtil(
      '/retail/promotions/detail/:promotionID',
      'Promotions | Details',
    ),

    view: createRouteUtil(
      '/retail/promotions/view/:promotionID',
      'Promotions | Details',
    ),

    update: createRouteUtil(
      '/retail/promotions/update/:promotionID',
      'Promotions | Update',
    ),

    review: createRouteUtil(
      '/retail/promotions/review/:promotionID',
      'Promotions | Review',
    ),

    notFound: createRouteUtil(
      '/retail/promotions/not-found',
      'Promotions | Not Found',
    ),
  },

  loyalty: {
    root: createRouteUtil('/retail/loyalty', 'Loyalty Program'),

    tab: createRouteUtil(
      `/retail/loyalty/:loyaltyTab(${Object.values(LoyaltyTab).join('|')})`,
      `Loyalty Program`,
    ),

    rootInTab: createRouteUtil(
      '/retail/loyalty/rewards',
      'Loyalty Program | Rewards',
    ),

    detail: createRouteUtil(
      '/retail/loyalty/rewards/:loyaltyProgramID',
      'Loyalty Program | Details',
    ),

    createReward: createRouteUtil(
      '/retail/loyalty/rewards/:loyaltyProgramID/rewards/new',
      'Loyalty Program | Create Reward',
    ),

    updateReward: createRouteUtil(
      '/retail/loyalty/rewards/:loyaltyProgramID/rewards/:rewardID',
      'Loyalty Program | Update Reward',
    ),
  },

  members: {
    root: createRouteUtil('/retail/loyalty/members', 'Loyalty | Members'),

    list: createRouteUtil('/retail/loyalty/members', 'Loyalty | Members'),

    detail: createRouteUtil(
      '/retail/loyalty/members/:shopperID',
      'Loyalty | Member Overview',
    ),

    update: createRouteUtil(
      '/retail/loyalty/members/:shopperID/update',
      'Loyalty | Update Member',
    ),

    updatePointBalance: createRouteUtil(
      '/retail/loyalty/members/:shopperID/update-point-balance',
      'Loyalty | Update Member Point Balance',
    ),

    notFound: createRouteUtil(
      '/retail/loyalty/members/not-found',
      'Loyalty | Member Not Found',
    ),
  },

  giftCards: {
    root: createRouteUtil('/retail/loyalty/gift-cards', 'Loyalty | Gift Cards'),
    create: createRouteUtil(
      '/retail/loyalty/gift-cards/create',
      'Loyalty | New Gift Card',
    ),
    detail: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID',
      'Loyalty | Gift Card',
    ),
    deactivate: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID/deactivate',
      'Loyalty | Deactivate Gift Card',
    ),
    update: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID/update',
      'Loyalty | Update Gift Card',
    ),
    updateDetails: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID/update-details',
      'Loyalty | Update Gift Card Details',
    ),
    notFound: createRouteUtil(
      '/retail/loyalty/gift-cards/not-found',
      'Loyalty | Gift Card Not Found',
    ),
  },

  campaigns: {
    root: createRouteUtil('/retail/campaigns', 'Campaigns'),

    list: createRouteUtil('/retail/campaigns/:loyaltyProgramID', 'Campaigns'),

    create: createRouteUtil(
      '/retail/campaigns/:loyaltyProgramID/create',
      'Campaigns | Create Message',
    ),

    update: createRouteUtil(
      '/retail/campaigns/:loyaltyProgramID/update/:campaignID',
      'Campaigns | Update Message',
    ),

    detail: createRouteUtil(
      '/retail/campaigns/:loyaltyProgramID/detail/:campaignID',
      'Campaigns | Custom Message',
    ),

    updateBasketLiftIncentive: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/incentives/${IncentiveType.BasketLift}`,
      'Campaigns | Update Incentive',
    ),

    updateVisitNudgeIncentive: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/incentives/${IncentiveType.StoreVisitNudge}`,
      'Campaigns | Update Incentive',
    ),

    createBirthdayBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/${LoyaltyBonusType.Birthday}/create`,
      'Campaigns | Birthday Bonus',
    ),

    createAnniversaryBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/${LoyaltyBonusType.Anniversary}/create`,
      'Campaigns | Anniversary Bonus',
    ),

    createSignupBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/${LoyaltyBonusType.Signup}/create`,
      'Campaigns | Signup Bonus',
    ),

    updateBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/:bonusID`,
      'Campaigns | Update Bonus',
    ),
  },

  files: {
    root: createRouteUtil('/retail/files', 'Files'),
  },

  reporting: {
    root: createRouteUtil('/retail/reporting', 'Reporting'),
    ...reportingRoutes,
    endOfDay: createRouteUtil('/retail/reporting/end-of-day', 'Reporting'),
    tab: createRouteUtil(
      `/retail/reporting/:reportingTab(${Object.values(OldReportingTab).join(
        '|',
      )})`,
      'Reporting',
    ),
  },

  transactions: {
    root: createRouteUtil(
      '/retail/reporting/transactions',
      'Reporting | Transactions',
    ),
    list: createRouteUtil(
      '/retail/reporting/transactions',
      'Reporting | Transactions',
    ),
    detail: createRouteUtil(
      '/retail/reporting/transactions/:transactionID',
      'Reporting | Transaction Details',
    ),
    lineItemDetail: createRouteUtil(
      '/retail/reporting/transactions/:transactionID/line-item/:lineItemID',
      'Reporting | Transaction Item Details',
    ),
    notFound: createRouteUtil(
      '/retail/reporting/transactions/not-found',
      'Reporting | Transaction Not Found',
    ),
  },

  tills: {
    root: createRouteUtil('/retail/reporting/tills', 'Reporting | Tills'),
    settings: createRouteUtil(
      '/retail/reporting/tills/settings',
      'Reporting | Tills',
    ),
    detailOverview: createRouteUtil(
      '/retail/reporting/tills/:tillID/overview',
      'Reporting | Till Overview',
    ),
    detailHistory: createRouteUtil(
      '/retail/reporting/tills/:tillID/history',
      'Reporting | Till History',
    ),
    review: createRouteUtil(
      '/retail/reporting/tills/:tillID/review',
      'Reporting | Till Review',
    ),
    notFound: createRouteUtil(
      '/retail/reporting/tills/not-found',
      'Reporting | Till Not Found',
    ),
  },

  users: {
    root: createRouteUtil('/retail/users', 'Cashier Management'),
    settings: createRouteUtil(
      '/retail/users/settings',
      'Cashier Management | Settings',
    ),
    edit: createRouteUtil(
      '/retail/users/:userID/update',
      'Cashier Management | Update User',
    ),
    create: createRouteUtil(
      '/retail/users/new',
      'Cashier Management | Add New User',
    ),
    notFound: createRouteUtil(
      '/retail/users/not-found',
      'Cashier Management | User Not Found',
    ),
  },
}

export { routes }
