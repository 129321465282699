import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChartSquarePlusIconProps = IconProps

const BarChartSquarePlusIcon = forwardRef<
  SVGSVGElement,
  BarChartSquarePlusIconProps
>((props: BarChartSquarePlusIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 3h4.2c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C21 5.28 21 6.12 21 7.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 18.72 3 17.88 3 16.2V12m5 1v4m8-6v6M12 7v10M5 8V2M2 5h6"
      fill="none"
    ></path>
  </Icon>
))

BarChartSquarePlusIcon.displayName = 'BarChartSquarePlusIcon'
BarChartSquarePlusIcon.defaultProps = iconDefaultProps

export type { BarChartSquarePlusIconProps }
export { BarChartSquarePlusIcon }
