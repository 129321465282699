import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PieChart04IconProps = IconProps

const PieChart04Icon = forwardRef<SVGSVGElement, PieChart04IconProps>(
  (props: PieChart04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 12 2.332 9.446a10 10 0 0 0 5.922 11.826L12 12Zm0 0 .105-10A10 10 0 0 0 2.34 9.413L12 12Zm10 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

PieChart04Icon.displayName = 'PieChart04Icon'
PieChart04Icon.defaultProps = iconDefaultProps

export type { PieChart04IconProps }
export { PieChart04Icon }
