import * as Sentry from '@sentry/react'
import { ENV_PROD } from '@vori/dashboard-env'
import { UserType } from '@vori/gql-dashboard'
import { getFullStorySessionURL } from '@vori/dashboard-integrations/FullStory/getFullStorySessionURL'

import { getAuth } from 'firebase/auth'
import { getAuthTokenFromUrl } from '../getAuthTokenFromUrl'
import { getFirebaseApp } from '../getFirebaseApp'
import { getSessionStorageRecord } from '../sessionStorage'

type HTTPHeaders = {
  'X-Client-App'?: UserType | 'JWT'
  'X-Client-Platform'?: string
  authorization?: string
} & { [key in string]: string }

let numTimesCouldntFetchToken = 0

async function getHTTPHeaders(): Promise<HTTPHeaders | null> {
  async function logToSentry(failedToLoadApp = false) {
    if (ENV_PROD) {
      const messageContext = {
        contexts: {
          extraData: {
            num_attempts: numTimesCouldntFetchToken,
            full_story_session_url: getFullStorySessionURL(),
            username: getAuth(app ?? undefined).currentUser?.email || null,
          },
        },
      }
      if (failedToLoadApp) {
        Sentry.captureMessage(
          `Failed to retrieve Firebase app. Unable to authenticate users!`,
          messageContext,
        )
      } else {
        Sentry.captureMessage('Auth token not fetched', messageContext)
      }
    }
  }

  const app = getFirebaseApp()

  if (app === null) {
    logToSentry(true)
    return null
  }

  const JWT = getAuthTokenFromUrl()
  // NOTE: Firebase handles caching and renewing after expiration
  const auth = getAuth(app)
  const token = await auth.currentUser?.getIdToken()
  const otherHTTPHeaders = getSessionStorageRecord('http-headers') || {}

  if (token) {
    return {
      'X-Client-Platform': 'WEB',
      authorization: `Bearer ${token}`,
      ...otherHTTPHeaders,
    }
  } else if (JWT != null) {
    return {
      'X-Client-Platform': 'WEB',
      authorization: `Bearer ${JWT}`,
      ...otherHTTPHeaders,
    }
  }

  numTimesCouldntFetchToken++
  if (numTimesCouldntFetchToken > 1) {
    logToSentry()
  }
  return null
}

export { getHTTPHeaders }
export type { HTTPHeaders }
