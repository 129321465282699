import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PuzzlePiece02IconProps = IconProps

const PuzzlePiece02Icon = forwardRef<SVGSVGElement, PuzzlePiece02IconProps>(
  (props: PuzzlePiece02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m12 2 3.6 3.6c2.4-6.3 9.1.4 2.8 2.8L22 12l-3.6 3.6c-2.4-6.3-9.1.4-2.8 2.8L12 22l-3.6-3.6C6 24.7-.7 18 5.6 15.6L2 12l3.6-3.6C8 14.7 14.7 8 8.4 5.6L12 2Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

PuzzlePiece02Icon.displayName = 'PuzzlePiece02Icon'
PuzzlePiece02Icon.defaultProps = iconDefaultProps

export type { PuzzlePiece02IconProps }
export { PuzzlePiece02Icon }
