import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronDownIconProps = IconProps

const ChevronDownIcon = forwardRef<SVGSVGElement, ChevronDownIconProps>(
  (props: ChevronDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 9 6 6 6-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ChevronDownIcon.displayName = 'ChevronDownIcon'
ChevronDownIcon.defaultProps = iconDefaultProps

export type { ChevronDownIconProps }
export { ChevronDownIcon }
