import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FigmaIconProps = IconProps

const FigmaIcon = forwardRef<SVGSVGElement, FigmaIconProps>(
  (props: FigmaIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 1.5H8.5a3.5 3.5 0 1 0 0 7m3.5-7v7m0-7h3.5a3.5 3.5 0 1 1 0 7m-3.5 0H8.5m3.5 0v7m0-7h3.5m-7 0a3.5 3.5 0 1 0 0 7m3.5 0H8.5m3.5 0V19a3.5 3.5 0 1 1-3.5-3.5m7-7a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FigmaIcon.displayName = 'FigmaIcon'
FigmaIcon.defaultProps = iconDefaultProps

export type { FigmaIconProps }
export { FigmaIcon }
