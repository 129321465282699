import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Thermometer02IconProps = IconProps

const Thermometer02Icon = forwardRef<SVGSVGElement, Thermometer02IconProps>(
  (props: Thermometer02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.5 4.5a2.5 2.5 0 0 0-5 0v9.258a4.5 4.5 0 1 0 5 0V4.5Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Thermometer02Icon.displayName = 'Thermometer02Icon'
Thermometer02Icon.defaultProps = iconDefaultProps

export type { Thermometer02IconProps }
export { Thermometer02Icon }
