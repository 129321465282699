import React from 'react'

import { CurrencyInput } from '../CurrencyInput'
import { CurrencyRangeInputProps } from './types'
import { NumberRangeInput } from '../NumberRangeInput'
import { parseInputValue } from './utils'

/**
 * The `<CurrencyRangeInput>` component enables the user to enter a range currency value.
 *
 * @example
 * <FormField
 *   description="Please enter an amount range"
 *   label="Filter by amount"
 * >
 *   <CurrencyRangeInput currencyInputProps={{ withSymbol: true }} />
 * </FormField>
 */
const CurrencyRangeInput = React.forwardRef<
  HTMLInputElement,
  CurrencyRangeInputProps
>(function CurrencyRangeInput(
  { currencyInputProps, ...props }: CurrencyRangeInputProps,
  ref,
): JSX.Element {
  return (
    <NumberRangeInput
      {...props}
      parseInputValue={parseInputValue}
      ref={ref}
      renderMaxInput={(inputProps, inputRef) => {
        return (
          <CurrencyInput
            {...currencyInputProps}
            {...inputProps}
            ref={inputRef}
          />
        )
      }}
      renderMinInput={(inputProps, inputRef) => {
        return (
          <CurrencyInput
            {...currencyInputProps}
            {...inputProps}
            ref={inputRef}
          />
        )
      }}
    />
  )
})

CurrencyRangeInput.displayName = 'CurrencyRangeInput'
CurrencyRangeInput.defaultProps = {}

export { CurrencyRangeInput }
