import React from 'react'

import { DisclosureState, DisclosureContentProps } from './types'
import { useDisclosureContext } from './DisclosureContext'

/**
 * The `<DisclosureContent>` component will contain the contents of the
 * disclosure.
 *
 * @example
 * <DisclosureContent>
 *   {(props, isOpen) => (
 *     <Flex {...props} direction="column">
 *       <Spacer /> {isOpen ? 'Here I am!' : 'I am not here'}
 *     </Flex>
 *   )}
 * </DisclosureContent>
 */
function DisclosureContent({ children }: DisclosureContentProps): JSX.Element {
  const { id, isOpen } = useDisclosureContext()

  return (
    <>
      {children(
        {
          'data-gourmet-disclosure-content': '',
          'data-state': isOpen
            ? DisclosureState.OPEN
            : DisclosureState.COLLAPSED,
          hidden: !isOpen,
          id,
        },
        isOpen,
      )}
    </>
  )
}

DisclosureContent.displayName = 'DisclosureContent'
DisclosureContent.defaultProps = {}

export { DisclosureContent }
