import { NavLink, NavLinkProps } from 'react-router-dom'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { toRem } from '../utils'

import {
  buttonDefaultProps,
  ButtonCoreProps,
  buttonStyles,
} from '../ButtonNext'

function styles(): CSSObject {
  return {
    ...buttonStyles(),
    gap: toRem(8),
    textDecoration: 'underline',

    '&:hover, &:active': {
      textDecoration: 'underline',
    },
  }
}

const StyledLinkInternal = styled(NavLink)(styles)
const StyledLinkExternal = styled.a(styles)

type CoreProps = Omit<
  ButtonCoreProps,
  'asClickable' | 'asIconButton' | 'noFocusRing' | 'noPadding' | 'sizing'
>

type Props = CoreProps &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'href' | 'aria-label' | 'aria-labelledby'
  > &
  (
    | { href: string; to?: undefined }
    | ({ href?: undefined } & Omit<
        NavLinkProps,
        'href' | 'aria-label' | 'aria-labelledby'
      >)
  )

const defaultProps: Partial<CoreProps> = buttonDefaultProps

/**
 * A `<Link>` provides an interactive reference to a resource. The
 * target resource can be either external or local, i.e., either outside using
 * `href` or within the current application using the `to` prop.
 *
 * @example
 * <Link to="/login" leftIcon={<AtSignIcon />}>Login</Link>
 *
 * @see {@link https://w3c.github.io/aria/#link}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link}
 */
const Link = React.forwardRef<HTMLAnchorElement, Props>(function Link(
  {
    children,
    fullWidth,
    leftIcon,
    rightIcon,
    variant = 'default',
    ...props
  }: Props,
  ref: React.Ref<HTMLAnchorElement>,
): JSX.Element {
  const commonProps = {
    'data-gourmet-link': '',
    ...(leftIcon && { 'data-left-icon': '' }),
    ...(rightIcon && { 'data-right-icon': '' }),
    ...(fullWidth && { 'data-full-width': '' }),
    ...(variant && { 'data-variant': variant }),
    'data-clickable': '',
    'data-no-focus-ring': '',
    'data-no-padding': '',
  } as ButtonCoreProps

  if (props.href != null) {
    return (
      <StyledLinkExternal {...props} {...commonProps} ref={ref}>
        {leftIcon && (
          <span aria-hidden="true" data-gourmet-button-icon="">
            {leftIcon}
          </span>
        )}
        {children}
        {rightIcon && (
          <span aria-hidden="true" data-gourmet-button-icon="">
            {rightIcon}
          </span>
        )}
      </StyledLinkExternal>
    )
  } else {
    return (
      <StyledLinkInternal {...props} {...commonProps} ref={ref}>
        {leftIcon && (
          <span aria-hidden="true" data-gourmet-button-icon="">
            {leftIcon}
          </span>
        )}
        {children}
        {rightIcon && (
          <span aria-hidden="true" data-gourmet-button-icon="">
            {rightIcon}
          </span>
        )}
      </StyledLinkInternal>
    )
  }
})

Link.displayName = 'Link'
Link.defaultProps = defaultProps

export { Link, defaultProps as linkDefaultProps, styles as linkStyles }
export type { Props as LinkProps, CoreProps as LinkCoreProps }
