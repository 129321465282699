import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ParagraphWrapIconProps = IconProps

const ParagraphWrapIcon = forwardRef<SVGSVGElement, ParagraphWrapIconProps>(
  (props: ParagraphWrapIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 6h18M3 12h15a3 3 0 0 1 0 6h-4m0 0 2-2m-2 2 2 2M3 18h7"
        fill="none"
      ></path>
    </Icon>
  ),
)

ParagraphWrapIcon.displayName = 'ParagraphWrapIcon'
ParagraphWrapIcon.defaultProps = iconDefaultProps

export type { ParagraphWrapIconProps }
export { ParagraphWrapIcon }
