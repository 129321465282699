import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DownloadCloud01IconProps = IconProps

const DownloadCloud01Icon = forwardRef<SVGSVGElement, DownloadCloud01IconProps>(
  (props: DownloadCloud01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 16.242A4.5 4.5 0 0 1 6.08 8.02a6.002 6.002 0 0 1 11.84 0A4.5 4.5 0 0 1 20 16.242M8 17l4 4m0 0 4-4m-4 4v-9"
        fill="none"
      ></path>
    </Icon>
  ),
)

DownloadCloud01Icon.displayName = 'DownloadCloud01Icon'
DownloadCloud01Icon.defaultProps = iconDefaultProps

export type { DownloadCloud01IconProps }
export { DownloadCloud01Icon }
