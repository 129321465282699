import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type VolumePlusIconProps = IconProps

const VolumePlusIcon = forwardRef<SVGSVGElement, VolumePlusIconProps>(
  (props: VolumePlusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.5 15.5v-7M15 12h7M9.634 5.366 6.47 8.53c-.173.173-.26.26-.36.322a1 1 0 0 1-.29.12C5.704 9 5.582 9 5.337 9H3.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C2 9.76 2 10.04 2 10.6v2.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C2.76 15 3.04 15 3.6 15h1.737c.245 0 .367 0 .482.028a1 1 0 0 1 .29.12c.1.061.187.148.36.32l3.165 3.166c.429.429.643.643.827.657a.5.5 0 0 0 .42-.174c.119-.14.119-.443.119-1.048V5.93c0-.606 0-.908-.12-1.049a.5.5 0 0 0-.42-.173c-.183.014-.397.228-.826.657Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

VolumePlusIcon.displayName = 'VolumePlusIcon'
VolumePlusIcon.defaultProps = iconDefaultProps

export type { VolumePlusIconProps }
export { VolumePlusIcon }
