import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Database01IconProps = IconProps

const Database01Icon = forwardRef<SVGSVGElement, Database01IconProps>(
  (props: Database01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 5c0 1.657-4.03 3-9 3S3 6.657 3 5m18 0c0-1.657-4.03-3-9-3S3 3.343 3 5m18 0v14c0 1.66-4 3-9 3s-9-1.34-9-3V5m18 7c0 1.66-4 3-9 3s-9-1.34-9-3"
        fill="none"
      ></path>
    </Icon>
  ),
)

Database01Icon.displayName = 'Database01Icon'
Database01Icon.defaultProps = iconDefaultProps

export type { Database01IconProps }
export { Database01Icon }
