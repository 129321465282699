import React, { forwardRef } from 'react'
import styled from 'styled-components'
import VisuallyHidden from '@reach/visually-hidden'

import {
  DialogContent,
  DialogContentProps,
  DialogOverlay,
  DialogOverlayProps,
} from '@reach/dialog'

import { XCloseIcon } from '@vori/gourmet-icons'

import Card, { CardProps, CardDefaultProps } from '../Card'
import { Button, ButtonProps } from '../ButtonNext'
import Flex, { FlexProps } from '../Flex'
import Spacer from '../Spacer'
import Text from '../Text'

import { colors, sizing, spacing, typography } from '../tokens'

const ModalOverlay = styled(DialogOverlay)`
  backdrop-filter: blur(8px);
  background-color: ${colors.overlay};
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  padding: ${spacing.modal.overlay};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`

type ModalCloseButtonProps = ButtonProps & { fixed?: boolean }

const ModalCloseButton = styled(
  ({ fixed, ...props }: ModalCloseButtonProps) => (
    <>
      <VisuallyHidden>Close</VisuallyHidden>
      <Button
        {...props}
        aria-label="Close modal"
        asClickable
        asIconButton
        leftIcon={<XCloseIcon />}
        sizing="small"
      />
    </>
  ),
)<ModalCloseButtonProps>`
  ${({ fixed }): string => (fixed ? 'position: absolute;' : '')}
  ${({ fixed }): string =>
    fixed ? `right: ${spacing.modal.closeButton};` : ''}
  ${({ fixed }): string => (fixed ? `top: ${spacing.modal.closeButton};` : '')}
  ${({ fixed }): string => (fixed ? 'z-index: 1000;' : '')}
  color: ${colors.modal.closeButton.color};
  padding: 0;

  :hover,
  :active,
  :focus {
    color: ${colors.modal.closeButton.color};
  }
`

type ModalHeaderProps = FlexProps & {
  closeButtonProps?: ModalCloseButtonProps
  onClickClose?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick']
}

const ModalHeader = styled(
  ({
    children,
    closeButtonProps,
    onClickClose,
    ...props
  }: ModalHeaderProps) => (
    <Flex centerY justifyContent="space-between" {...props}>
      <Flex center fullWidth>
        {children}
      </Flex>
      <ModalCloseButton onClick={onClickClose} {...closeButtonProps} />
    </Flex>
  ),
)<ModalHeaderProps>`
  -webkit-background-clip: padding-box;
  background-color: ${colors.modal.header.backgroundColor};
  /** webkit is weird sometimes */
  border-top-left-radius: calc(${sizing.radius.large} - 2px);
  /** webkit is weird sometimes */
  border-top-right-radius: calc(${sizing.radius.large} - 2px);
  margin: -${spacing.card} -${spacing.card};
  margin-bottom: ${spacing.card};
  padding: ${spacing.modal.header};

  ${Text} {
    color: ${colors.modal.header.color};
    font-size: ${typography.text.h3.fontSize};
    font-weight: ${typography.text.h3.fontWeight};
    line-height: ${typography.text.h3.lineHeight};
    text-align: center;
  }
`

const ModalFooter = styled(Flex)<FlexProps>`
  margin: ${spacing.card} -${spacing.card} -${spacing.card};
  padding: ${spacing.modal.footer};
`

const ModalContent = styled(Card)`
  border: 1px solid ${colors.modal.borderColor};
  margin: 0 auto;
  position: relative;
  z-index: 1000;
`

type ModalProps = React.HTMLAttributes<HTMLDivElement> &
  DialogContentProps &
  CardProps & {
    overlayProps?: DialogOverlayProps
  } & {
    'aria-label': string
  }

const defaultProps: Partial<ModalProps> = {
  ...CardDefaultProps,
  className: '',
  overlayProps: {},
}

/**
 * @deprecated Use `<ModalNext>` instead.
 */
const StyledModal = styled(
  forwardRef<HTMLDivElement, ModalProps>(function Modal(
    { children, overlayProps, ...props }: ModalProps,
    ref,
  ) {
    return (
      <ModalOverlay {...overlayProps} isOpen>
        <DialogContent
          aria-label={props['aria-label'] || "Modal's content"}
          style={{ height: '100%', width: '100%' }}
        >
          <Spacer size="xLarge" />
          <ModalContent size="base" {...props} ref={ref}>
            {children}
          </ModalContent>
          <Spacer size="xLarge" />
        </DialogContent>
      </ModalOverlay>
    )
  }),
)``

StyledModal.displayName = 'Modal'
StyledModal.defaultProps = defaultProps

export type { ModalProps }

export {
  defaultProps as ModalDefaultProps,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
}

export default StyledModal
