import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Attachment02IconProps = IconProps

const Attachment02Icon = forwardRef<SVGSVGElement, Attachment02IconProps>(
  (props: Attachment02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.5 5.256V16.5a5.5 5.5 0 1 1-11 0V5.667a3.667 3.667 0 0 1 7.333 0v10.779a1.833 1.833 0 1 1-3.666 0V6.65"
        fill="none"
      ></path>
    </Icon>
  ),
)

Attachment02Icon.displayName = 'Attachment02Icon'
Attachment02Icon.defaultProps = iconDefaultProps

export type { Attachment02IconProps }
export { Attachment02Icon }
