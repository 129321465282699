import { CSSObject } from 'styled-components'

import {
  foundations,
  TokenFoundationTypographyLineHeight,
  TokenFoundationTypographySize,
  TokenFoundationTypographyWeight,
} from '@vori/gourmet-tokens'

import { colorVariantToColorToken, lineHeightFromSize } from './utils'
import { TextCoreProps } from './types'
import { PropsToStyledProps } from '../utils'

export function textStyles(
  props: PropsToStyledProps<TextCoreProps>,
): CSSObject {
  return {
    ...(props.$align && { textAlign: props.$align }),
    ...(props.$decoration && { textDecoration: props.$decoration }),
    ...(props.$fullWidth && { width: '100%' }),
    ...(props.$overflow && { textOverflow: props.$overflow }),
    ...(props.$overflowWrap && { overflowWrap: props.$overflowWrap }),
    ...(props.$transform && { textTransform: props.$transform }),
    ...(props.$whiteSpace && { whiteSpace: props.$whiteSpace }),
    ...(props.$wordBreak && { wordBreak: props.$wordBreak }),

    ...(props.$truncate && {
      maxWidth: '95%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    }),

    color:
      foundations.color[colorVariantToColorToken(props.$variant || 'default')],

    fontWeight:
      foundations.typography[
        props.$weight
          ? (`typography.font-weight-${props.$weight}` as TokenFoundationTypographyWeight)
          : 'typography.font-weight-regular'
      ],

    fontSize:
      foundations.typography[
        props.$size
          ? (`typography.${props.$size}-font-size` as TokenFoundationTypographySize)
          : 'typography.text-md-font-size'
      ],

    lineHeight:
      foundations.typography[
        props.$lineHeight
          ? (`typography.${props.$lineHeight}-line-height` as TokenFoundationTypographyLineHeight)
          : lineHeightFromSize(props.$size || 'text-md')
      ],

    fontStyle: 'normal',
    margin: 0,
    transition: 'all 350ms cubic-bezier(0.215, 0.61, 0.355, 1)',
  }
}
