import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SubscriptIconProps = IconProps

const SubscriptIcon = forwardRef<SVGSVGElement, SubscriptIconProps>(
  (props: SubscriptIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m3 5 10 10m0-10L3 15m18 4h-4c0-1.5.44-2 1.5-2.5S21 15.33 21 14c0-.47-.17-.93-.48-1.29a2.11 2.11 0 0 0-2.62-.44c-.42.24-.74.62-.9 1.07"
        fill="none"
      ></path>
    </Icon>
  ),
)

SubscriptIcon.displayName = 'SubscriptIcon'
SubscriptIcon.defaultProps = iconDefaultProps

export type { SubscriptIconProps }
export { SubscriptIcon }
