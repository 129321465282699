import { FormFieldType } from '@vori/react-form'

import { NotificationTemplateFormSchema } from '@vori/dashboard-forms/NotificationTemplate'
import { OfferFormSchema } from '@vori/dashboard-forms/Offer'

export type IncentiveFormSchema = {
  validForDays: Extract<FormFieldType, 'number'>
}

export type IncentiveActivationFormSchema = {
  active: Extract<FormFieldType, 'switch'>
  id: Extract<FormFieldType, 'text'>
}

export type BasketLiftIncentiveFormSchema = IncentiveFormSchema &
  NotificationTemplateFormSchema & {
    incentiveRate: Extract<FormFieldType, 'select'>
    increments: Extract<FormFieldType, 'number'>
    visitCount: Extract<FormFieldType, 'select'>
  }

export type VisitNudgeIncentiveFormSchema = IncentiveFormSchema &
  OfferFormSchema &
  NotificationTemplateFormSchema & {
    daysSinceLastVisit: Extract<FormFieldType, 'number'>
  }

export enum IncentiveType {
  BasketLift = 'basket_lift',
  StoreVisitNudge = 'store_visit_nudge',
}
