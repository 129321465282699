import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { toRem } from '../utils'

import { InlineFlex } from '../FlexNext'
import { Spacer } from '../SpacerNext'
import { Text } from '../TextNext'

function styles(): CSSObject {
  return {
    alignItems: 'flex-start',
    borderWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    paddingBlock: 0,
    margin: 0,
    maxHeight: 'fit-content',

    '&[data-full-width], &[data-full-width] legend': {
      width: '100%',
    },

    legend: {
      borderWidth: 0,
      padding: 0,
      paddingBlock: 0,
      margin: 0,
    },

    '&[data-as-section] [data-gourmet-form-field-group-label]': {
      fontSize: toRem(24),
      fontWeight: 500,
      lineHeight: toRem(32),
    },

    '&:not([data-as-section]) [data-gourmet-form-field-group-label]': {
      fontSize: toRem(16),
      fontWeight: 500,
      lineHeight: toRem(24),
    },

    '[data-gourmet-form-field-group-description]': {
      fontSize: toRem(14),
      fontWeight: 400,
      lineHeight: toRem(20),
    },
  }
}

const FormFieldGroupFieldset = styled.fieldset(styles)

type Props = React.InputHTMLAttributes<HTMLFieldSetElement> & {
  /**
   * Use this prop when you want to display this group as a larger section
   * of a page, rather than a field group within a form. I.e. this will make the
   * title larger by using a heading element (h3).
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend#technical_summary}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements}
   */
  asSection?: boolean
  /**
   * An optional description for field group, useful for giving users hints
   * or instruction on how to interact with it.
   */
  description?: React.ReactNode
  /**
   * If `true`, the field group will take all the available horizontal space.
   */
  fullWidth?: boolean
  /**
   * Denotes the purpose or intention of the field group.
   */
  label: React.ReactNode
}

/**
 * Provides a way to group common controls, like checkboxes and switches within
 * a form through the `<fieldset>` HTML element.
 *
 * @example
 * <FormFieldGroup label="Incentives" description="Encourage members to shop at your store">
 *   <SwitchInput label="Store visit nudge" name="nudge_incentive" />
 *   <SwitchInput label="Basket lift" name="basket_incentive" />
 * </FormFieldGroup>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset}
 */
const FormFieldGroup = React.forwardRef<
  HTMLFieldSetElement,
  React.PropsWithChildren<Props>
>(function FormFieldGroup(
  {
    asSection,
    children,
    description,
    fullWidth,
    label,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  return (
    <FormFieldGroupFieldset
      {...(asSection && { 'data-as-section': '' })}
      {...(fullWidth && { 'data-full-width': '' })}
      data-gourmet-form-field-group=""
      ref={ref}
      {...props}
    >
      <legend>
        <InlineFlex direction="column" fullWidth>
          <Text
            data-gourmet-form-field-group-label=""
            size={asSection ? 'heading-xs' : 'text-md'}
            weight={asSection ? 'medium' : 'regular'}
          >
            {label}
          </Text>
          {description && (
            <Text
              data-gourmet-form-field-group-description=""
              variant="secondary"
            >
              {description}
            </Text>
          )}
        </InlineFlex>
      </legend>
      {React.Children.count(children) > 0 && <Spacer />}
      {children}
    </FormFieldGroupFieldset>
  )
})

FormFieldGroup.displayName = 'FormFieldGroup'
FormFieldGroup.defaultProps = {}

export { FormFieldGroup, styles as FormFieldGroupStyles }
export type { Props as FormFieldGroupProps }
