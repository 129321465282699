import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GamingPad02IconProps = IconProps

const GamingPad02Icon = forwardRef<SVGSVGElement, GamingPad02IconProps>(
  (props: GamingPad02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 12h4m-2-2v4m7-1h.01M18 11h.01M5.2 18h13.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C22 16.48 22 15.92 22 14.8V9.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 6 19.92 6 18.8 6H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 7.52 2 8.08 2 9.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 18 4.08 18 5.2 18Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

GamingPad02Icon.displayName = 'GamingPad02Icon'
GamingPad02Icon.defaultProps = iconDefaultProps

export type { GamingPad02IconProps }
export { GamingPad02Icon }
