import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Download04IconProps = IconProps

const Download04Icon = forwardRef<SVGSVGElement, Download04IconProps>(
  (props: Download04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8 12 4 4m0 0 4-4m-4 4V6.8c0-1.39 0-2.086-.55-2.865-.366-.517-1.42-1.155-2.047-1.24-.945-.128-1.304.059-2.022.433A9.999 9.999 0 0 0 2 12c0 5.523 4.477 10 10 10s10-4.477 10-10a9.996 9.996 0 0 0-5-8.662"
        fill="none"
      ></path>
    </Icon>
  ),
)

Download04Icon.displayName = 'Download04Icon'
Download04Icon.defaultProps = iconDefaultProps

export type { Download04IconProps }
export { Download04Icon }
