import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChartSquareDownIconProps = IconProps

const BarChartSquareDownIcon = forwardRef<
  SVGSVGElement,
  BarChartSquareDownIconProps
>((props: BarChartSquareDownIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 3h4.2c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C21 5.28 21 6.12 21 7.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 18.72 3 17.88 3 16.2V12m5 1v4m8-6v6M12 7v10M2 5l3 3m0 0 3-3M5 8V2"
      fill="none"
    ></path>
  </Icon>
))

BarChartSquareDownIcon.displayName = 'BarChartSquareDownIcon'
BarChartSquareDownIcon.defaultProps = iconDefaultProps

export type { BarChartSquareDownIconProps }
export { BarChartSquareDownIcon }
