import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserMinus01IconProps = IconProps

const UserMinus01Icon = forwardRef<SVGSVGElement, UserMinus01IconProps>(
  (props: UserMinus01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 18h6m-10-2.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserMinus01Icon.displayName = 'UserMinus01Icon'
UserMinus01Icon.defaultProps = iconDefaultProps

export type { UserMinus01IconProps }
export { UserMinus01Icon }
