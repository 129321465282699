import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Globe02IconProps = IconProps

const Globe02Icon = forwardRef<SVGSVGElement, Globe02IconProps>(
  (props: Globe02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m2.661 18.34 4.594-4.595M18.218 2.783A9.5 9.5 0 1 1 4.783 16.218M17 22H7m5 0v-3m5.5-9.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Globe02Icon.displayName = 'Globe02Icon'
Globe02Icon.defaultProps = iconDefaultProps

export type { Globe02IconProps }
export { Globe02Icon }
