import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCcw04IconProps = IconProps

const RefreshCcw04Icon = forwardRef<SVGSVGElement, RefreshCcw04IconProps>(
  (props: RefreshCcw04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 18.875A8.5 8.5 0 0 0 12 3.5h-.5m.5 17A8.5 8.5 0 0 1 7 5.125M11 22.4l2-2-2-2m2-12.8-2-2 2-2"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCcw04Icon.displayName = 'RefreshCcw04Icon'
RefreshCcw04Icon.defaultProps = iconDefaultProps

export type { RefreshCcw04IconProps }
export { RefreshCcw04Icon }
