import React from 'react'
import styled from 'styled-components'

import { CardRowCoreProps, CardRowProps } from './types'
import { cardRowStyles } from './styles'
import { Card, cardDefaultProps } from '../CardNext'

const { variant, ...cardDefaultPropsWithoutVariant } = cardDefaultProps

const defaultProps: Partial<CardRowCoreProps> = cardDefaultPropsWithoutVariant

const StyledCardRow = styled(Card)(cardRowStyles)

/**
 * The `<CardRow>` component is used within `<Card>` components to provide
 * actionable rows. It extends the `<Card>` to provide specific styling when
 * form elements are focused and/or checked within it.
 *
 * @extends Card
 * @example
 * <Card asFlat fullWidth>
 *   <Flex direction="column" fullWidth rowGap="space.100">
 *     <CardRow>...</CardRow>
 *     <CardRow>...</CardRow>
 *   </Flex>
 * </Card>
 */
const CardRow = React.forwardRef<HTMLDivElement, CardRowProps>(function CardRow(
  { children, ...props }: CardRowProps,
  ref,
): JSX.Element {
  return (
    <StyledCardRow
      {...props}
      alignItems="center"
      asFlat
      data-gourmet-card-row=""
      direction="row"
      gap="space.150"
      justifyContent="space-between"
      ref={ref}
    >
      {children}
    </StyledCardRow>
  )
})

CardRow.displayName = 'CardRow'
CardRow.defaultProps = defaultProps

export { CardRow, defaultProps as cardRowDefaultProps }
