import lodashMerge from 'lodash/merge'

import { merge, relayStylePagination } from '../utils/apollo'

import {
  RetailUserKeySpecifier,
  StrictTypedTypePolicies,
} from './generated/apollo-helpers'

import mockTypePolicies from './mocks/typePolicies'

const typePolicies: StrictTypedTypePolicies = {
  Banner: {
    fields: {
      orders: {
        keyArgs: false,
        merge,
      },
    },
  },
  Store: {
    fields: {
      orders: {
        keyArgs: false,
        merge,
      },
    },
  },
  Query: {
    fields: {
      allStores: { keyArgs: ['input', ['search']], merge },
      getCSVUploadLogByID(_, { args, toReference }) {
        return args?.id
          ? toReference({
              __typename: 'CsvUploadLog',
              id: args.id,
            })
          : null
      },
      vendorByID(_, { args, toReference }) {
        return args?.id
          ? toReference({
              __typename: 'Vendor',
              id: args.id,
            })
          : null
      },
      vendorProductByID(_, { args, toReference }) {
        return args?.id
          ? toReference({
              __typename: 'VendorProduct',
              id: args.id,
            })
          : null
      },
      queryInvoiceModuleOrders: relayStylePagination(['input', ['filters']]),
      queryInvoiceModuleInvoices: relayStylePagination(['input', ['filters']]),
      queryInvoiceModuleOrderLineItems: relayStylePagination([
        'input',
        ['status', 'orderID'],
      ]),
      queryInvoiceModuleInvoiceLineItems: relayStylePagination([
        'input',
        ['status', 'orderID'],
      ]),
      storesForCompany: relayStylePagination(['input', ['storeIDs']]),
      storeDepartmentsForCompany: relayStylePagination(['input', ['storeIDs']]),
      vendorsForCompany: relayStylePagination(['input', ['storeIDs']]),
    },
  },
  RetailUser: {
    /**
     * @todo remove casting once support for nested keyFields lands:
     * https://github.com/dotansimha/graphql-code-generator/issues/5025
     */
    keyFields: ['user', ['id'] as unknown as RetailUserKeySpecifier],
  },
  VendorProduct: {
    fields: {
      orderGuides: {
        merge: false,
      },
      retailerDiscontinuedDates: {
        merge: false,
      },
    },
  },
}

const mergedTypePolicies = lodashMerge({}, typePolicies, mockTypePolicies)

export default mergedTypePolicies
