import { MenuItemProps as ReachMenuItemProps } from '@reach/menu-button'
import { renderToString } from 'react-dom/server'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { foundations } from '@vori/gourmet-tokens'
import { CheckIcon } from '@vori/gourmet-icons'

import { Flex, flexDefaultProps, FlexProps, InlineFlex } from '../FlexNext'
import { Text } from '../TextNext'
import Label from '../Label'

import { toRem } from '../utils'
import toTransitions from '../utils/toTransitions'

type MenuItemProps = Omit<ReachMenuItemProps, 'children' | 'onSelect'> &
  FlexProps & {
    disableActiveState?: boolean
    icon?: React.ReactElement | null
    isNested?: boolean
    onSelect?: ReachMenuItemProps['onSelect']
    withParentCategory?: string
    withSelectedCheckIcon?: boolean
  }

const defaultProps: Partial<MenuItemProps> = {
  ...flexDefaultProps,
  className: '',
  disableActiveState: false,
  icon: undefined,
  isNested: false,
  onSelect: () => void 0,
  withSelectedCheckIcon: false,
}

const StyledMenuItem = styled(
  forwardRef<HTMLDivElement, MenuItemProps>(function MenuItem(
    {
      children,
      disableActiveState,
      icon,
      isNested,
      withParentCategory,
      withSelectedCheckIcon,
      ...props
    }: MenuItemProps,
    ref,
  ) {
    return (
      <Flex
        {...props}
        data-gourmet-menu-item=""
        gap="space.075"
        centerY
        fullWidth
        justifyContent="space-between"
        ref={ref}
      >
        <InlineFlex centerY gap="space.075" fullWidth>
          {icon}
          <Text size="text-sm" fullWidth>
            {children}
          </Text>
        </InlineFlex>

        {withParentCategory && (
          <Text align="right" size="text-xs" variant="secondary">
            Sub-category of {withParentCategory}
          </Text>
        )}
      </Flex>
    )
  }),
)<MenuItemProps>`
  cursor: pointer;
  padding: ${foundations.space['space.075']} ${foundations.space['space.100']};
  transition: ${toTransitions(['background-color', 'color'], 'ease')};
  ${({ fullWidth }) => (fullWidth ? `${Text} { width: 100%; }` : '')}

  ${({ isNested }) =>
    isNested ? `padding-left: ${foundations.space['space.200']};` : ''}

  [data-gourmet-checkbox-input-label] {
    font-weight: ${foundations.typography['typography.font-weight-regular']};
  }

  [data-gourmet-icon] {
    height: auto;
  }

  ,
  &,
  [data-gourmet-text]:not(${Label}) {
    text-decoration: none;
    width: 100%;
  }

  :hover,
  :focus,
  :active,
  &[data-highlighted],
  &[data-selected],
  &[aria-selected='true'] {
    background-color: #fafafa;
    outline: none;

    &,
    [data-gourmet-text]:not(${Label}) {
      text-decoration: none;
    }
  }

  ${({ disableActiveState, withSelectedCheckIcon }) =>
    !disableActiveState
      ? `&[data-current],
  &[data-user-value] {
    background-color: #fafafa;
    background-position: calc(100% - ${toRem(16)}) center;
    background-repeat: no-repeat;
    background-image: ${
      withSelectedCheckIcon
        ? `url(data:image/svg+xml;utf8,${encodeURI(
            renderToString(<CheckIcon />),
          )
            .replace('currentColor', '#6038EF')
            .replace('#', '%23')})`
        : 'none'
    };

    &, [data-gourmet-text]:not(${Label}) {
      text-decoration: none;
    }
  }`
      : ''}
`

StyledMenuItem.displayName = 'MenuItem'
StyledMenuItem.defaultProps = defaultProps

export type { MenuItemProps }
export { defaultProps as MenuItemDefaultProps }
export default StyledMenuItem
