import { CSSObject } from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'

import { GridCoreProps, GridItemCoreProps } from './types'
import { PropsToStyledProps } from '../utils'
import { tokenOrValue } from './utils'

export function gridStyles(
  props: PropsToStyledProps<GridCoreProps>,
): CSSObject {
  return {
    display: 'grid',

    ...(props.$autoColumns && {
      gridAutoColumns: tokenOrValue(props.$autoColumns),
    }),

    ...(props.$autoFlow && { gridAutoFlow: props.$autoFlow }),
    ...(props.$alignItems && { alignItems: props.$alignItems }),
    ...(props.$alignSelf && { alignSelf: props.$alignSelf }),

    ...(props.$autoRows && {
      gridAutoRows: tokenOrValue(props.$autoRows),
    }),

    ...(props.$columnGap && {
      columnGap: tokenOrValue(props.$columnGap),
    }),

    ...(props.$fullHeight && { height: '100%' }),
    ...(props.$fullWidth && { width: '100%' }),

    ...(props.$gap != null && {
      gap: Array.isArray(props.$gap)
        ? props.$gap.map(tokenOrValue).join(' ')
        : tokenOrValue(props.$gap),
    }),

    ...(props.$grid && { grid: props.$grid }),
    ...(props.$justifyContent && { justifyContent: props.$justifyContent }),

    ...(props.$rowGap && {
      rowGap: tokenOrValue(props.$rowGap),
    }),

    ...(props.$templateAreas && { gridTemplateAreas: props.$templateAreas }),

    ...(props.$templateColumns && {
      gridTemplateColumns: tokenOrValue(props.$templateColumns),
    }),

    ...(props.$templateRows && {
      gridTemplateRows: tokenOrValue(props.$templateRows),
    }),

    ...(props.$template && { gridTemplate: props.$template }),

    ...(!props.$fullWidth &&
      props.$size != null && {
        maxWidth: foundations.size[`size.${props.$size}`],
        width: '100%',
      }),
  }
}

export function gridItemStyles(
  props: PropsToStyledProps<GridItemCoreProps>,
): CSSObject {
  const { display, ...otherStyles } = gridStyles(props)

  return {
    ...otherStyles,
    ...(props.$area && { gridArea: props.$area }),
    ...(props.$column && { gridColumn: props.$column }),
    ...(props.$columnEnd && { gridColumnEnd: props.$columnEnd }),
    ...(props.$columnStart && { gridColumnStart: props.$columnStart }),
    ...(props.$justifySelf && { justifySelf: props.$justifySelf }),
    ...(props.$placeSelf && { placeSelf: props.$placeSelf }),
    ...(props.$row && { gridRow: props.$row }),
    ...(props.$rowEnd && { gridRowEnd: props.$rowEnd }),
    ...(props.$rowStart && { gridRowStart: props.$rowStart }),
  }
}
