import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CheckVerified03IconProps = IconProps

const CheckVerified03Icon = forwardRef<SVGSVGElement, CheckVerified03IconProps>(
  (props: CheckVerified03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9 12 2 2 4.5-4.5M9.202 20.6a1.24 1.24 0 0 1 .92.247l1.203.924c.398.305.951.305 1.348 0l1.248-.959c.234-.179.528-.258.82-.219l1.561.206a1.11 1.11 0 0 0 1.168-.675l.601-1.453c.112-.272.328-.488.6-.6l1.453-.601c.464-.191.74-.671.675-1.168l-.198-1.505a1.24 1.24 0 0 1 .247-.92l.923-1.204a1.105 1.105 0 0 0 0-1.347l-.959-1.25a1.105 1.105 0 0 1-.219-.818l.206-1.562a1.108 1.108 0 0 0-.675-1.168l-1.453-.601a1.103 1.103 0 0 1-.6-.6l-.6-1.453a1.107 1.107 0 0 0-1.169-.675l-1.562.206a1.1 1.1 0 0 1-.817-.217l-1.25-.959a1.105 1.105 0 0 0-1.347 0l-1.249.959a1.115 1.115 0 0 1-.818.219L7.696 3.2a1.108 1.108 0 0 0-1.167.675l-.6 1.453a1.117 1.117 0 0 1-.6.6l-1.453.6c-.464.192-.74.671-.675 1.168l.206 1.562a1.11 1.11 0 0 1-.219.818l-.959 1.249a1.105 1.105 0 0 0 0 1.347l.959 1.25a1.1 1.1 0 0 1 .219.818L3.2 16.303c-.065.497.211.976.675 1.168l1.453.601c.272.112.488.328.6.6l.6 1.453c.192.464.672.74 1.169.675l1.504-.2Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CheckVerified03Icon.displayName = 'CheckVerified03Icon'
CheckVerified03Icon.defaultProps = iconDefaultProps

export type { CheckVerified03IconProps }
export { CheckVerified03Icon }
