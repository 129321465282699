import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GraduationHat01IconProps = IconProps

const GraduationHat01Icon = forwardRef<SVGSVGElement, GraduationHat01IconProps>(
  (props: GraduationHat01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 10v6.011c0 .36 0 .539.055.697a1 1 0 0 0 .23.374c.118.12.278.2.6.36l5.4 2.7c.262.131.393.197.53.223a1 1 0 0 0 .37 0c.137-.026.268-.091.53-.223l5.4-2.7c.322-.16.482-.24.6-.36a.999.999 0 0 0 .23-.374c.055-.158.055-.338.055-.697v-6.01M2 8.5l9.642-4.822c.131-.066.197-.098.266-.111a.5.5 0 0 1 .184 0c.069.013.135.045.266.11L22 8.5l-9.642 4.821a1.028 1.028 0 0 1-.266.111.501.501 0 0 1-.184 0c-.069-.012-.135-.045-.266-.11L2 8.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

GraduationHat01Icon.displayName = 'GraduationHat01Icon'
GraduationHat01Icon.defaultProps = iconDefaultProps

export type { GraduationHat01IconProps }
export { GraduationHat01Icon }
