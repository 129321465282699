import { Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'

const SentryRoute = Sentry.withSentryRouting(Route)

export { SentryRoute as SentryEnhancedRoute }

/**
 * @todo Migrate to the named export
 * @deprecated Used the named export instead
 */
export default SentryRoute
