import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Umbrella03IconProps = IconProps

const Umbrella03Icon = forwardRef<SVGSVGElement, Umbrella03IconProps>(
  (props: Umbrella03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.25 21.96 12 12m5-8.66C12.687.85 7.29 1.938 4.246 5.683c-.297.366-.446.548-.489.81-.034.209.017.485.123.667.134.23.371.366.845.64l14.55 8.4c.474.274.711.41.976.412a1.1 1.1 0 0 0 .64-.227c.205-.168.289-.388.457-.828C23.07 11.048 21.313 5.83 17 3.34Zm0 0C15.087 2.235 11.297 5.217 8.536 10M17 3.34c1.913 1.105 1.226 5.877-1.536 10.66M22 22H2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Umbrella03Icon.displayName = 'Umbrella03Icon'
Umbrella03Icon.defaultProps = iconDefaultProps

export type { Umbrella03IconProps }
export { Umbrella03Icon }
