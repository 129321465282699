import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BluetoothOnIconProps = IconProps

const BluetoothOnIcon = forwardRef<SVGSVGElement, BluetoothOnIconProps>(
  (props: BluetoothOnIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 7 12 10-6 5V2l6 5L6 17"
        fill="none"
      ></path>
    </Icon>
  ),
)

BluetoothOnIcon.displayName = 'BluetoothOnIcon'
BluetoothOnIcon.defaultProps = iconDefaultProps

export type { BluetoothOnIconProps }
export { BluetoothOnIcon }
