import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { sizing } from '../tokens'
import { toRem, toTransitions } from '../utils'
import { useControlledState } from '../hooks'

import { Flex } from '../FlexNext'
import { Text } from '../TextNext'
import { VisuallyHiddenInput } from '../VisuallyHiddenInput'

function styles(): CSSObject {
  return {
    '&': {
      position: 'relative',

      '&:hover [data-gourmet-switch-input-control]': {
        backgroundColor: '#E4E4E7',
      },

      '&:hover [data-gourmet-switch-input-input]:not(:disabled):checked + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]':
        {
          backgroundColor: '#5235D0',
        },

      '[data-gourmet-switch-input-control-container]': {
        cursor: 'pointer',
      },

      '[data-gourmet-switch-input-control]': {
        alignItems: 'center',
        backgroundColor: '#F4F4F5',
        borderRadius: sizing.radius.pill,
        display: 'inline-flex',
        flexShrink: 0,
        height: toRem(20),
        padding: toRem(2),
        position: 'relative',
        width: toRem(36),

        transition: toTransitions(
          ['background-color', 'border-color', 'box-shadow'],
          'ease',
        ),

        '&::after': {
          backgroundColor: '#FFFFFF',
          borderRadius: sizing.radius.rounded,
          boxShadow: `0 ${toRem(1)} ${toRem(
            3,
          )} rgba(16, 24, 40, 0.1), 0 ${toRem(1)} ${toRem(
            2,
          )} rgba(16, 24, 40, 0.06)`,
          content: '""',
          display: 'block',
          height: toRem(16),
          width: toRem(16),
          transform: 'translateX(0)',
          transition: toTransitions(
            ['background-color', 'border-color', 'box-shadow', 'transform'],
            'ease',
          ),
        },
      },

      '[data-gourmet-switch-input-input]': {
        '&:checked + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]':
          {
            backgroundColor: '#6038EF',

            '&::after': {
              transform: 'translateX(100%)',
            },
          },

        [[
          '&:focus + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]',
          '&:active:not(:disabled) + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]',
          '&:focus:checked + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]',
          '&:active:not(:disabled):checked + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]',
        ].join(',')]: {
          boxShadow: `0 0 0 ${toRem(2)} #F4F3FF, 0 0 0 ${toRem(4)} #6038EF`,
        },

        '&:disabled + [data-gourmet-switch-input-control-container]': {
          cursor: 'default',

          '& [data-gourmet-switch-input-control]': {
            backgroundColor: '#F4F4F5',
          },
        },

        '&:disabled + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-label]':
          {
            color: '#3F3F46',
          },

        [[
          '&:disabled + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]::after',
          '&:checked:disabled + [data-gourmet-switch-input-control-container] [data-gourmet-switch-input-control]::after',
        ].join(',')]: {
          backgroundColor: '#FAFAFA',
        },
      },
    },
  }
}

const SwitchInputLabel = styled.label(styles)

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  /**
   * An optional description for this switch input element, useful for giving users hints
   * or instruction on how to interact with it.
   */
  description?: React.ReactNode
  /**
   * The ID, if any, of the HTML element used to describe the checkbox.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
   */
  descriptionID?: string
  /**
   * The ID, if any, of the HTML element containing the error message related
   * to the checkbox.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-errormessage}
   */
  errorID?: string
  /**
   * If `true`, the element will take all the available horizontal space.
   */
  fullWidth?: boolean
  /**
   * Denotes the purpose or intention behind the input's value.
   */
  label: React.ReactNode
  /**
   * The ID, if any, of the HTML element used to label the checkbox.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
   */
  labelID?: string
  /**
   * If `true`, the input will aligned to the right, as opposed to the left of
   * the label and description.
   */
  withRightAlignedInput?: boolean
}

/**
 * Switch buttons are a common way to allow users to make a single selection
 * from a list of options. Since only one switch button can be selected at a
 * time (within the same group), each available choice must be its own
 * item and label.
 *
 * @example
 * <SwitchInput label="1" name="some_switch" value="1" />
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/switch/}
 */
const SwitchInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(function SwitchInput(
  {
    description,
    descriptionID: controlledDescriptionID,
    errorID,
    fullWidth,
    id: controlledID,
    label,
    labelID,
    onChange: controlledOnChange,
    withRightAlignedInput,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [defaultID] = useClientId('gourmet-switch-input')
  const [defaultDescriptionID] = useClientId('gourmet-switch-input-description')

  const [inputID] = useControlledState({
    componentName: 'SwitchInput',
    controlledValue: controlledID,
    defaultValue: defaultID,
  })

  const [descriptionID] = useControlledState({
    componentName: 'SwitchInput',
    controlledValue: controlledDescriptionID,
    defaultValue: defaultDescriptionID,
  })

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      /**
       * We explicitly focus the input due to browsers like Safari, which will only accept focus
       * on text-only inputs.
       *
       * @see {@link https://bugs.webkit.org/show_bug.cgi?id=22261}
       */
      event.currentTarget.focus()

      controlledOnChange?.(event)
    },
    [controlledOnChange],
  )

  return (
    <SwitchInputLabel data-gourmet-switch-input="" data-value={props.value}>
      <VisuallyHiddenInput
        {...props}
        {...(descriptionID && { 'aria-describedby': descriptionID })}
        {...(errorID && {
          'aria-errormessage': errorID,
          'aria-invalid': 'true',
        })}
        {...(labelID && { 'aria-labelledby': labelID })}
        data-gourmet-switch-input-input=""
        focusable
        id={inputID}
        onChange={onChange}
        ref={ref}
        role="switch"
        tabIndex={0}
        type="checkbox"
      />

      <Flex
        data-gourmet-switch-input-control-container=""
        fullWidth={fullWidth}
        direction={withRightAlignedInput ? 'row-reverse' : 'row'}
        columnGap="space.050"
      >
        <span aria-hidden="true" data-gourmet-switch-input-control="" />
        <Flex direction="column" fullWidth>
          <Text
            data-gourmet-switch-input-label=""
            size="text-sm"
            weight="medium"
          >
            {label}
          </Text>
          {description != null && (
            <Text
              data-gourmet-switch-input-description=""
              id={defaultDescriptionID}
              size="text-sm"
              variant="secondary"
            >
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    </SwitchInputLabel>
  )
})

SwitchInput.displayName = 'SwitchInput'
SwitchInput.defaultProps = {}

export { SwitchInput, styles as SwitchInputStyles }
export type { Props as SwitchInputProps }
