import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Dice2IconProps = IconProps

const Dice2Icon = forwardRef<SVGSVGElement, Dice2IconProps>(
  (props: Dice2IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.25 8.75h.005m-6.505 6.5h.005M7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 3 17.88 3 16.2 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21Zm7.7-12.25a.25.25 0 1 1-.5 0 .25.25 0 0 1 .5 0ZM9 15.25a.25.25 0 1 1-.5 0 .25.25 0 0 1 .5 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Dice2Icon.displayName = 'Dice2Icon'
Dice2Icon.defaultProps = iconDefaultProps

export type { Dice2IconProps }
export { Dice2Icon }
