import { init, isInitialized } from '@fullstory/browser'

import { ENV_FULLSTORY_ORGANIZATION_ID, ENV_LOCAL } from '@vori/dashboard-env'

export function initializeFullStory(): void {
  if (ENV_LOCAL || isInitialized()) {
    return
  }

  init(
    {
      orgId: ENV_FULLSTORY_ORGANIZATION_ID,
    },
    ({ sessionUrl }) => console.debug(`FullStory Session URL: ${sessionUrl}`),
  )
}
