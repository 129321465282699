import { InlineSpacer } from '@vori/gourmet-components'
import React from 'react'
import { ChevronRightIcon } from '@vori/gourmet-icons'

interface DataGridGroupingMenuTriggerTextProps {
  groupedColumnNames: Array<string>
  groupedColumnsCount: number
  hasGroupedColumns: boolean
}

function DataGridGroupingMenuTriggerText({
  hasGroupedColumns,
  groupedColumnsCount,
  groupedColumnNames,
}: DataGridGroupingMenuTriggerTextProps): JSX.Element {
  if (!hasGroupedColumns) {
    return <>Group By</>
  }

  if (groupedColumnsCount === 1) {
    return <>By {groupedColumnNames[0]}</>
  }

  return (
    <>
      {groupedColumnNames.map((groupedColumnName, index) => (
        <React.Fragment key={`grid-grouping-menu-name-${groupedColumnName}`}>
          {index !== 0 && (
            <>
              <InlineSpacer size="space.050" />
              <ChevronRightIcon size="sm" />
              <InlineSpacer size="space.050" />
            </>
          )}
          {groupedColumnName}
        </React.Fragment>
      ))}
    </>
  )
}

export { DataGridGroupingMenuTriggerText }
