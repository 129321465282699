import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { colors, Size, sizing } from '../tokens'
import toPx from '../utils/toPx'

type LogoColorVariant = keyof typeof colors.logo.fill

type LogoProps = React.SVGAttributes<SVGSVGElement> & {
  size?: Size
  variant?: LogoColorVariant
}

const defaultProps: Partial<LogoProps> = {
  className: '',
  size: 'base',
  variant: 'primary',
}

const StyledLogo = styled(
  forwardRef<SVGSVGElement, LogoProps>(function Logo(
    { className, fill, size, variant }: LogoProps,
    ref,
  ) {
    return (
      <svg
        className={className}
        fill="none"
        height={toPx(sizing.logo[size as Size])}
        ref={ref}
        viewBox="0 0 24 20"
        width={toPx(sizing.logo[size as Size])}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="m.730372 1.48104c-.174341-.28609.03019-.653892.363608-.653892h4.96839c.15128 0 .29123.080647.3678.211952l8.76473 15.0304c.0743.1274.0783.2842.0107.4153l-2.0971 4.0655c-.1529.2964-.5686.3113-.7421.0266zm17.948428-.16891c-.1251-.2147.0287-.484949.2759-.484949h3.9514c.3224 0 .5283.346139.376.632219l-1.8878 3.54622c-.118.22161-.4315.22772-.5579.01074z"
          fill={fill || colors.logo.fill[variant as LogoColorVariant]}
          fillRule="evenodd"
        />
      </svg>
    )
  }),
)``

StyledLogo.displayName = 'Logo'
StyledLogo.defaultProps = defaultProps

export type { LogoProps }
export { defaultProps as LogoDefaultProps }
export default StyledLogo
