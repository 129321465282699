import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserEditIconProps = IconProps

const UserEditIcon = forwardRef<SVGSVGElement, UserEditIconProps>(
  (props: UserEditIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM11 21l3.101-.886c.149-.043.223-.064.292-.096a.998.998 0 0 0 .175-.102c.061-.045.116-.1.225-.21l6.457-6.456a1.768 1.768 0 1 0-2.5-2.5l-6.457 6.457a2.12 2.12 0 0 0-.209.225.998.998 0 0 0-.102.175 2.07 2.07 0 0 0-.096.292L11 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserEditIcon.displayName = 'UserEditIcon'
UserEditIcon.defaultProps = iconDefaultProps

export type { UserEditIconProps }
export { UserEditIcon }
