import React from 'react'
import styled from 'styled-components'

import { GridCoreProps, InlineGridProps } from './types'
import { gridStyles } from './styles'
import { propsToStyledProps, PropsToStyledProps } from '../utils'

const StyledInlineGrid = styled.span<PropsToStyledProps<GridCoreProps>>(
  (props) => ({
    ...gridStyles(props),
    display: 'inline-grid',
  }),
)

const defaultProps: Partial<GridCoreProps> = {}

/**
 * The `<InlineGrid>` component extends the `<Grid>` component to render a
 * `<span>` element with `inline-grid` display.
 *
 * @example
 * <InlineGrid templateRows="1fr 1fr" centerY>
 *   <GridItem>Item 1</GridItem>
 *   <GridItem>Item 2</GridItem>
 * </InlineGrid>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_gridible_box_layout}
 */
const InlineGrid = React.forwardRef<HTMLSpanElement, InlineGridProps>(
  function InlineGrid(
    {
      alignItems,
      alignSelf,
      autoColumns,
      autoFlow,
      autoRows,
      children,
      columnGap,
      fullHeight,
      fullWidth,
      gap,
      grid,
      justifyContent,
      rowGap,
      size,
      template,
      templateAreas,
      templateColumns,
      templateRows,
      ...props
    }: InlineGridProps,
    ref,
  ): JSX.Element {
    return (
      <StyledInlineGrid
        {...propsToStyledProps({
          alignItems,
          alignSelf,
          autoColumns,
          autoFlow,
          autoRows,
          columnGap,
          fullHeight,
          fullWidth,
          gap,
          grid,
          justifyContent,
          rowGap,
          size,
          template,
          templateAreas,
          templateColumns,
          templateRows,
        })}
        {...props}
        data-gourmet-grid=""
        data-gourmet-inline-grid=""
        ref={ref}
      >
        {children}
      </StyledInlineGrid>
    )
  },
)

InlineGrid.displayName = 'InlineGrid'
InlineGrid.defaultProps = defaultProps

export { InlineGrid, defaultProps as inlineGridDefaultProps }
