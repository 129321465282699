import React from 'react'
import OpenTelemetry from '@opentelemetry/api'
import { useRouteMatch } from 'react-router-dom'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

/**
 * @todo Setup Sentry integration. Right now it's unclear how to do this
 * **after** initializing the SDK, given that OpenTelemetry support for web
 * is experimental and poorly documented. If this cannot be done then we would
 * have to move things around so that we ensure Sentry is initialized before
 * Honeycomb and then use the Sentry instance to define a span processor when
 * initializing the Honeycomb SDK.
 *
 * @see {@link https://opentelemetry.io/docs/languages/js/getting-started/browser/}
 * @see {@link https://github.com/honeycombio/honeycomb-opentelemetry-web/issues/80}
 * @see {@link https://github.com/getsentry/sentry-javascript/discussions/7364}
 */

/**
 * @todo Setup Fullstory integration. Right now it's unclear how to do this
 * **after** initializing the SDK, given that OpenTelemetry support for web
 * is experimental and poorly documented. If this cannot be done then we would
 * have to move things around so that we ensure Fullstory is initialized before
 * Honeycomb and then use the Fullstory instance to define a span processor when
 * initializing the Honeycomb SDK.
 *
 * @see {@link https://opentelemetry.io/docs/languages/js/getting-started/browser/}
 * @see {@link https://github.com/honeycombio/honeycomb-opentelemetry-web/issues/79}
 */

export function useOpenTelemetry() {
  const { user } = useCurrentUserState()
  const route = useRouteMatch()

  /**
   * Sets current user information for OpenTelemetry when available.
   *
   * Note that this implementation is somewhat incomplete given that there
   * doesn't seem to be a way to define a span processor **after** initializing
   * the Honeycomb SDK. Based on the available documentation, it seems like we
   * need to have the user authenticated before initializing the Honeycomb SDK,
   * but that seems counterintuitive because the docs also say to initialize
   * Honeycomb as early as possible. Waiting to have the user authenticated means
   * we miss observability before the user authenticates, i.e. during the login
   * process.
   *
   * @see {@link https://docs.honeycomb.io/send-data/javascript-browser/honeycomb-distribution/#example-adding-user-information-after-sdk-initialization}
   */
  React.useEffect(() => {
    if (!user.state.isLoggedIn) {
      return
    }

    const activeSpan = OpenTelemetry.trace.getActiveSpan()

    if (activeSpan) {
      activeSpan.setAttribute('user.id', user.data.id)
    }

    return
  }, [user.data.id, user.state.isLoggedIn])

  // Removes current user information from OpenTelemetry when the user logs out.
  React.useEffect(() => {
    if (user.state.isLoggedIn) {
      return
    }

    const activeSpan = OpenTelemetry.trace.getActiveSpan()

    if (activeSpan) {
      activeSpan.setAttribute('user.id', '')
    }
  }, [user.state, user.state.isLoggedIn])

  /**
   * Sets current page route.
   *
   * @todo - Implement as stated on the docs when we upgrade to React Router v6.
   * @see {@link https://docs.honeycomb.io/send-data/javascript-browser/honeycomb-distribution/#example-dynamic-page-routes-with-react-router}
   */
  React.useEffect(() => {
    const activeSpan = OpenTelemetry.trace.getActiveSpan()

    if (activeSpan) {
      activeSpan.setAttribute('page.route', route.path)
    }
  }, [route.path, user.state, user.state.isLoggedIn])
}
