import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LockUnlocked04IconProps = IconProps

const LockUnlocked04Icon = forwardRef<SVGSVGElement, LockUnlocked04IconProps>(
  (props: LockUnlocked04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 10V8a5 5 0 0 1 9-3m-4 9v2m7-1a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LockUnlocked04Icon.displayName = 'LockUnlocked04Icon'
LockUnlocked04Icon.defaultProps = iconDefaultProps

export type { LockUnlocked04IconProps }
export { LockUnlocked04Icon }
