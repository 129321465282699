import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LineHeightIconProps = IconProps

const LineHeightIcon = forwardRef<SVGSVGElement, LineHeightIconProps>(
  (props: LineHeightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 13h6m-8 4 4.272-9.398c.231-.509.347-.763.507-.842a.5.5 0 0 1 .442 0c.16.079.276.333.507.842L17 17m4 4H3M21 3H3"
        fill="none"
      ></path>
    </Icon>
  ),
)

LineHeightIcon.displayName = 'LineHeightIcon'
LineHeightIcon.defaultProps = iconDefaultProps

export type { LineHeightIconProps }
export { LineHeightIcon }
