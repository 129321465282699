import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Hurricane03IconProps = IconProps

const Hurricane03Icon = forwardRef<SVGSVGElement, Hurricane03IconProps>(
  (props: Hurricane03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 4H3m17 4H6m12 4H9m6 4H8m9 4h-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

Hurricane03Icon.displayName = 'Hurricane03Icon'
Hurricane03Icon.defaultProps = iconDefaultProps

export type { Hurricane03IconProps }
export { Hurricane03Icon }
