import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ParagraphSpacingIconProps = IconProps

const ParagraphSpacingIcon = forwardRef<
  SVGSVGElement,
  ParagraphSpacingIconProps
>((props: ParagraphSpacingIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 10h-8m8-4h-8m8 8h-8m8 4h-8m-7 2V4m0 16-3-3m3 3 3-3M6 4 3 7m3-3 3 3"
      fill="none"
    ></path>
  </Icon>
))

ParagraphSpacingIcon.displayName = 'ParagraphSpacingIcon'
ParagraphSpacingIcon.defaultProps = iconDefaultProps

export type { ParagraphSpacingIconProps }
export { ParagraphSpacingIcon }
