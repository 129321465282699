import { FirebaseApp, getApps, initializeApp } from 'firebase/app'

import {
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_DATABASE_URL,
  ENV_FIREBASE_MEASUREMENT_ID,
  ENV_FIREBASE_MESSAGING_SENDER_ID,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
} from '@vori/dashboard-env'

const config = {
  apiKey: ENV_FIREBASE_API_KEY,
  appId: ENV_FIREBASE_APP_ID,
  authDomain: ENV_FIREBASE_AUTH_DOMAIN,
  databaseURL: ENV_FIREBASE_DATABASE_URL,
  measurementId: ENV_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: ENV_FIREBASE_MESSAGING_SENDER_ID,
  projectId: ENV_FIREBASE_PROJECT_ID,
  storageBucket: ENV_FIREBASE_STORAGE_BUCKET,
}

let firebaseApp: FirebaseApp | null

function getFirebaseApp() {
  if (firebaseApp != null) {
    return firebaseApp
  }

  if (!getApps().length) {
    firebaseApp = initializeApp(config)
  } else {
    firebaseApp =
      getApps()?.find(({ options }) => options?.appId === config.appId) || null
  }

  return firebaseApp
}

export { getFirebaseApp }
