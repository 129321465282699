import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SunSetting01IconProps = IconProps

const SunSetting01Icon = forwardRef<SVGSVGElement, SunSetting01IconProps>(
  (props: SunSetting01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 3v2M5.314 7.314 3.9 5.9m14.786 1.414L20.1 5.9M6 15a6 6 0 0 1 12 0m4 0H2m17 4H5"
        fill="none"
      ></path>
    </Icon>
  ),
)

SunSetting01Icon.displayName = 'SunSetting01Icon'
SunSetting01Icon.defaultProps = iconDefaultProps

export type { SunSetting01IconProps }
export { SunSetting01Icon }
