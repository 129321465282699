import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ImageIndentLeftIconProps = IconProps

const ImageIndentLeftIcon = forwardRef<SVGSVGElement, ImageIndentLeftIconProps>(
  (props: ImageIndentLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 9.25h-6M21 4H3m18 10.75h-6M21 20H3m1.6-4h4.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C11 15.24 11 14.96 11 14.4V9.6c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C10.24 8 9.96 8 9.4 8H4.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C3 8.76 3 9.04 3 9.6v4.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C3.76 16 4.04 16 4.6 16Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ImageIndentLeftIcon.displayName = 'ImageIndentLeftIcon'
ImageIndentLeftIcon.defaultProps = iconDefaultProps

export type { ImageIndentLeftIconProps }
export { ImageIndentLeftIcon }
