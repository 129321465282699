import { foundations } from '@vori/gourmet-tokens'

import tokens from './base'
import toAlpha from '../utils/toAlpha'

export default {
  shadow: 'rgba(0, 0, 0, 0.0890512)',
  overlay: 'rgba(52, 64, 84, 0.7)',

  autocomplete: {
    item: {
      disabled: {
        backgroundColor: tokens.colors.light2,
        color: tokens.colors.dark2,
      },
    },
  },

  alert: {
    backgroundColor: `linear-gradient(89.9deg, ${toAlpha(
      tokens.colors.pureWhite,
      0.33,
    )} 19.83%, ${toAlpha(tokens.colors.lightPurple, 0.25)} 93.69%), ${
      tokens.colors.lightPurple
    }`,
    borderColor: tokens.colors.dark4,
  },

  avatar: {
    backgroundColor: tokens.colors.pureWhite,
    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    borderColor: '#939393',
    color: tokens.colors.purple,
  },

  breadcrumb: {
    color: tokens.colors.purple,

    hover: {
      color: tokens.colors.darkPurple,
    },

    active: {
      color: tokens.colors.darkPurple,
    },
  },

  button: {
    backgroundColor: {
      default: tokens.colors.pureWhite,
      negative: tokens.colors.accentRed,
      positive: tokens.colors.accentGreen,
      primary: tokens.colors.purple,
      secondary: tokens.colors.pureWhite,
      secondaryInverted: tokens.colors.light3,
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      tertiary: '#333333',
    },

    borderColor: {
      default: tokens.colors.dark4,
      negative: tokens.colors.accentRed,
      positive: tokens.colors.accentGreen,
      primary: tokens.colors.purple,
      secondary: tokens.colors.dark4,
      secondaryInverted: tokens.colors.light2,
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      tertiary: '#333333',
    },

    color: {
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      default: '#333333',
      negative: tokens.colors.pureWhite,
      positive: tokens.colors.pureWhite,
      primary: tokens.colors.pureWhite,
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      secondary: '#333333',
      secondaryInverted: tokens.colors.purple,
      tertiary: tokens.colors.pureWhite,
    },

    focusRing: {
      default: toAlpha(tokens.colors.dark3, 0.15),
      negative: toAlpha(tokens.colors.accentRed, 0.2),
      positive: toAlpha(tokens.colors.accentGreen, 0.2),
      primary: toAlpha(tokens.colors.lightPurple, 0.55),
      secondary: toAlpha(tokens.colors.dark3, 0.15),
      secondaryInverted: toAlpha(tokens.colors.light4, 0.2),
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      tertiary: toAlpha('#000000', 0.2),
    },

    hover: {
      backgroundColor: {
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        default: '#F6F6F6',
        negative: tokens.colors.red,
        positive: tokens.colors.green,
        primary: tokens.colors.darkPurple,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        secondary: '#F6F6F6',
        secondaryInverted: tokens.colors.light4,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        tertiary: '#000000',
      },

      borderColor: {
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        default: '#000000',
        negative: tokens.colors.red,
        positive: tokens.colors.green,
        primary: tokens.colors.darkPurple,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        secondary: '#000000',
        secondaryInverted: tokens.colors.light3,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        tertiary: '#000000',
      },

      color: {
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        default: '#333333',
        negative: tokens.colors.pureWhite,
        positive: tokens.colors.pureWhite,
        primary: tokens.colors.pureWhite,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        secondary: '#333333',
        secondaryInverted: tokens.colors.purple,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        tertiary: tokens.colors.pureWhite,
      },
    },

    disabled: {
      backgroundColor: {
        default: tokens.colors.pureWhite,
        negative: tokens.colors.lightRed,
        positive: tokens.colors.lightGreen,
        primary: tokens.colors.lightPurple,
        secondary: tokens.colors.pureWhite,
        secondaryInverted: tokens.colors.light1,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        tertiary: '#333333',
      },

      borderColor: {
        default: 'transparent',
      },
    },
  },

  calendar: {
    day: {
      color: tokens.colors.dark2,
    },

    date: {
      backgroundColor: 'transparent',

      hover: {
        backgroundColor: tokens.colors.lightPurple,
      },

      active: {
        backgroundColor: tokens.colors.purple,
      },
    },

    dateSelected: {
      color: tokens.colors.pureWhite,

      hover: {
        color: tokens.colors.pureWhite,
      },

      active: {
        color: tokens.colors.pureWhite,
      },
    },

    dateDisabled: {
      color: tokens.colors.dark3,

      hover: {
        color: tokens.colors.dark3,
      },

      active: {
        color: tokens.colors.dark3,
      },
    },

    dateFromCurrentMonth: {
      color: tokens.colors.dark1,

      hover: {
        color: tokens.colors.dark1,
      },

      active: {
        color: tokens.colors.pureWhite,
      },
    },

    dateFromOtherMonth: {
      color: tokens.colors.light4,

      hover: {
        color: tokens.colors.dark2,
      },

      active: {
        color: tokens.colors.pureWhite,
      },
    },

    dateToday: {
      color: tokens.colors.purple,

      hover: {
        color: tokens.colors.purple,
      },

      active: {
        color: tokens.colors.pureWhite,
      },
    },

    navigation: {
      backgroundColor: tokens.colors.dark1,
    },

    navigationButton: {
      color: tokens.colors.pureWhite,
    },

    navigationTitle: {
      color: tokens.colors.pureWhite,
    },
  },

  card: {
    backgroundColor: {
      default: tokens.colors.pureWhite,
      primary: tokens.colors.purple,
    },

    borderColor: {
      default: tokens.colors.light4,
      primary: tokens.colors.darkPurple,
    },

    color: {
      default: tokens.colors.dark1,
      primary: tokens.colors.light1,
    },
  },

  checkbox: {
    backgroundColor: tokens.colors.light1,
    borderColor: tokens.colors.dark4,
    color: tokens.colors.light1,

    hover: {
      borderColor: tokens.colors.purple,
    },

    active: {
      borderColor: tokens.colors.darkPurple,
      backgroundColor: tokens.colors.lightPurple,
    },

    focusRing: toAlpha(tokens.colors.lightPurple, 0.55),
  },

  clickable: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',

    color: {
      default: tokens.colors.dark1,
      negative: tokens.colors.accentRed,
      positive: tokens.colors.accentGreen,
      primary: tokens.colors.purple,
      secondary: tokens.colors.dark3,
      secondaryInverted: tokens.colors.pureWhite,
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      tertiary: '#333333',
    },

    hover: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',

      color: {
        default: tokens.colors.dark2,
        negative: tokens.colors.red,
        positive: tokens.colors.green,
        primary: tokens.colors.darkPurple,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        secondary: '#000000',
        secondaryInverted: tokens.colors.light1,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        tertiary: '#000000',
      },
    },

    active: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',

      color: {
        default: tokens.colors.dark1,
        negative: tokens.colors.red,
        positive: tokens.colors.green,
        primary: tokens.colors.darkPurple,
        secondary: tokens.colors.dark2,
        secondaryInverted: tokens.colors.light1,
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        tertiary: '#000000',
      },
    },
  },

  containedLogo: {
    backgroundColor: {
      primary: tokens.colors.purple,
      secondary: tokens.colors.dark1,
    },

    fill: tokens.colors.pureWhite,
  },

  datePicker: {
    borderColor: tokens.colors.dark4,
    color: tokens.colors.dark2,

    hover: {
      borderColor: tokens.colors.purple,
      color: tokens.colors.dark2,
    },

    active: {
      borderColor: tokens.colors.purple,
      color: tokens.colors.purple,
    },
  },

  divider: {
    borderColor: {
      default: tokens.colors.dark3,
      primary: tokens.colors.darkPurple,
      secondary: tokens.colors.dark4,
    },
  },

  editable: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',

    hover: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },

    active: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },

  icon: {
    default: tokens.colors.dark1,
    negative: tokens.colors.accentRed,
    positive: tokens.colors.accentGreen,
    primary: tokens.colors.purple,
    progress: tokens.colors.gold,
    secondary: tokens.colors.dark2,
  },

  iconContainer: {
    default: tokens.colors.light3,
    negative: tokens.colors.lightRed,
    positive: tokens.colors.lightGreen,
    primary: tokens.colors.lightPurple,
    progress: tokens.colors.lightGold,
    secondary: tokens.colors.light2,
  },

  input: {
    backgroundColor: tokens.colors.pureWhite,

    borderColor: {
      default: tokens.colors.dark4,
      negative: tokens.colors.accentRed,
      positive: tokens.colors.dark4,
    },

    color: tokens.colors.dark1,

    focus: {
      backgroundColor: tokens.colors.pureWhite,

      borderColor: {
        default: tokens.colors.darkPurple,
        negative: tokens.colors.accentRed,
        positive: tokens.colors.darkPurple,
      },

      color: tokens.colors.dark1,
    },

    readOnly: {
      backgroundColor: '#FAFAFA',

      borderColor: {
        default: '#D1D1D6',
        negative: '#D1D1D6',
        positive: '#D1D1D6',
      },

      color: '#70707B',
    },

    icon: {
      default: tokens.colors.dark2,
      negative: tokens.colors.accentRed,
      positive: tokens.colors.accentGreen,
    },

    focusRing: toAlpha(tokens.colors.lightPurple, 0.55),
    placeholder: tokens.colors.dark3,
  },

  label: {
    backgroundColor: {
      default: tokens.colors.light3,
      negative: tokens.colors.lightRed,
      positive: tokens.colors.lightGreen,
      primary: tokens.colors.lightPurple,
      progress: tokens.colors.lightGold,
      secondary: tokens.colors.light1,
      selected: tokens.colors.pureWhite,
      active: tokens.colors.lightBlue,
    },

    borderColor: {
      default: tokens.colors.light3,
      negative: tokens.colors.lightRed,
      positive: tokens.colors.lightGreen,
      primary: tokens.colors.lightPurple,
      progress: tokens.colors.lightGold,
      secondary: tokens.colors.light1,
      selected: tokens.colors.pureWhite,
      active: tokens.colors.lightBlue,
    },

    color: {
      default: tokens.colors.dark2,
      negative: tokens.colors.red,
      positive: tokens.colors.green,
      primary: tokens.colors.purple,
      progress: tokens.colors.gold,
      secondary: tokens.colors.dark2,
      selected: tokens.colors.purple,
      active: tokens.colors.blue,
    },

    outlined: {
      backgroundColor: {
        default: tokens.colors.light3,
        negative: tokens.colors.lightRed,
        positive: tokens.colors.lightGreen,
        primary: tokens.colors.lightPurple,
        progress: tokens.colors.lightGold,
        secondary: tokens.colors.light1,
        selected: tokens.colors.pureWhite,
        active: tokens.colors.lightBlue,
      },

      borderColor: {
        default: tokens.colors.dark2,
        negative: tokens.colors.red,
        positive: tokens.colors.green,
        primary: tokens.colors.purple,
        progress: tokens.colors.gold,
        secondary: tokens.colors.dark2,
        selected: tokens.colors.purple,
        active: tokens.colors.blue,
      },

      color: {
        default: tokens.colors.dark2,
        negative: tokens.colors.red,
        positive: tokens.colors.green,
        primary: tokens.colors.purple,
        progress: tokens.colors.gold,
        secondary: tokens.colors.dark2,
        selected: tokens.colors.purple,
        active: tokens.colors.blue,
      },
    },
  },

  listbox: {
    option: {
      disabled: {
        backgroundColor: tokens.colors.light2,
      },
    },
  },

  loader: {
    borderColor: {
      primary: tokens.colors.purple,
      secondary: tokens.colors.light1,
    },
  },

  logo: {
    fill: {
      primary: tokens.colors.purple,
      secondary: tokens.colors.light1,
      secondaryInverted: tokens.colors.dark4,
    },
  },

  menuItem: {
    active: {
      backgroundColor: tokens.colors.lightPurple,
      color: tokens.colors.purple,
    },

    hover: {
      backgroundColor: tokens.colors.darkPurple,
      color: tokens.colors.pureWhite,
    },
  },

  modal: {
    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    borderColor: '#474747',

    closeButton: {
      color: tokens.colors.dark4,
    },

    header: {
      color: tokens.colors.pureWhite,
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      backgroundColor: '#474747',
    },
  },

  radioButton: {
    backgroundColor: tokens.colors.pureWhite,
    borderColor: tokens.colors.dark3,
    color: tokens.colors.dark1,

    hover: {
      backgroundColor: tokens.colors.pureWhite,
      borderColor: tokens.colors.purple,
      color: tokens.colors.dark1,
    },

    active: {
      backgroundColor: toAlpha(tokens.colors.lightGold, 0.25),
      borderColor: tokens.colors.gold,
      color: tokens.colors.dark1,
    },

    activeRadioInput: {
      backgroundColor: 'transparent',
      borderColor: tokens.colors.green,
    },

    focusRing: toAlpha(tokens.colors.lightPurple, 0.55),
  },

  searchInput: {
    icon: {
      backgroundColor: tokens.colors.purple,
      color: tokens.colors.pureWhite,
    },
  },

  section: {
    backgroundColor: {
      danger: '#FFFCF5',
      default: '#FAFAFA',
      primary: toAlpha(tokens.colors.purple, 0.0396),
    },

    borderColor: {
      danger: '#FEC84B',
      default: '#E4E4E7',
      primary: tokens.colors.purple,
    },

    color: {
      danger: '#DC6803',
      default: tokens.colors.dark1,
      primary: tokens.colors.dark1,
    },
  },

  select: {
    trigger: {
      active: {
        backgroundColor: tokens.colors.light1,
        borderColor: tokens.colors.dark2,
      },
      error: {
        borderColor: tokens.colors.accentRed,
      },
    },

    item: {
      color: tokens.colors.dark1,
      backgroundColor: tokens.colors.pureWhite,

      hover: {
        color: tokens.colors.dark1,
        backgroundColor: tokens.colors.lightPurple,
      },

      active: {
        color: tokens.colors.pureWhite,
        backgroundColor: tokens.colors.purple,
      },
    },
  },

  splashScreen: {
    backgroundColor: tokens.colors.purple,
    color: tokens.colors.light1,
  },

  switch: {
    backgroundColor: tokens.colors.light4,
    borderColor: tokens.colors.light4,

    hover: {
      borderColor: tokens.colors.purple,
    },

    active: {
      backgroundColor: tokens.colors.purple,
      borderColor: tokens.colors.purple,
    },

    knob: {
      backgroundColor: tokens.colors.pureWhite,
    },

    focusRing: toAlpha(tokens.colors.lightPurple, 0.55),
  },

  table: {
    row: {
      backgroundColor: tokens.colors.pureWhite,
      borderColor: tokens.colors.dark4,

      hover: {
        backgroundColor: tokens.colors.light1,
      },
    },

    cell: {
      backgroundColor: tokens.colors.pureWhite,
      borderColor: tokens.colors.dark4,

      sticky: {
        borderColor: tokens.colors.dark4,
      },
    },

    headerCell: {
      backgroundColor: tokens.colors.light2,
      borderColor: tokens.colors.dark4,

      sticky: {
        borderColor: tokens.colors.dark4,
      },
    },
  },

  tabs: {
    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    borderColor: '#000000',
  },

  tab: {
    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    color: '#333333',
    borderColor: 'transparent',

    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    hover: {
      color: '#000000',
      borderColor: 'transparent',
    },

    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    active: {
      color: '#000000',
      borderColor: '#000000',
    },
  },

  text: {
    default: tokens.colors.dark1,
    negative: tokens.colors.accentRed,
    positive: tokens.colors.accentGreen,
    primary: tokens.colors.purple,
    progress: tokens.colors.gold,
    secondary: tokens.colors.dark2,
  },

  toggleButton: {
    backgroundColor: tokens.colors.pureWhite,
    borderColor: tokens.colors.dark2,
    color: tokens.colors.dark1,

    hover: {
      backgroundColor: tokens.colors.pureWhite,
      borderColor: tokens.colors.purple,
      color: tokens.colors.dark1,
    },

    active: {
      backgroundColor: tokens.colors.lightPurple,
      borderColor: tokens.colors.purple,
      color: tokens.colors.purple,
    },
  },

  toast: {
    backgroundColor: {
      default: '#FAFAFA',
      negative: '#FEF2F3',
      positive: '#ECFDF3',
      primary: '#F4F3FF',
      progress: '#FEFDE8',
      secondary: tokens.colors.light1,
      selected: tokens.colors.pureWhite,
      active: tokens.colors.lightBlue,
    },

    borderColor: {
      default: 'transparent',
      negative: 'transparent',
      positive: 'transparent',
      primary: 'transparent',
      progress: 'transparent',
      secondary: 'transparent',
      selected: 'transparent',
      active: 'transparent',
    },

    color: {
      default: '#3F3F46',
      negative: '#BB0C1D',
      positive: '#027A4C',
      primary: '#5235D0',
      progress: '#A17016',
      secondary: tokens.colors.dark2,
      selected: tokens.colors.purple,
      active: tokens.colors.blue,
    },
  },

  tooltip: {
    backgroundColor: tokens.colors.dark1,
    color: tokens.colors.light1,
  },

  panel: {
    closeButton: {
      borderColor: tokens.colors.dark3,
      color: tokens.colors.dark2,
    },
  },

  scrollbar: {
    track: tokens.colors.light1,
    thumb: tokens.colors.dark4,
  },

  progress: {
    step: {
      number: {
        backgroundColor: tokens.colors.lightPurple,
        color: tokens.colors.purple,

        active: {
          backgroundColor: tokens.colors.purple,
          color: tokens.colors.pureWhite,
        },
      },

      track: {
        backgroundColor: tokens.colors.dark4,
      },
    },
  },

  /** v2  */
  appHeader: {
    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    backgroundColor: '#292929',

    title: {
      color: tokens.colors.pureWhite,
    },

    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    divider: {
      borderColor: '#606060',
    },
  },

  appSidebar: {
    backgroundColor: tokens.colors.pureWhite,
  },

  appSidebarHeader: {
    borderColor: tokens.colors.dark4,
  },

  appSidebarLink: {
    active: {
      backgroundColor: toAlpha(tokens.colors.purple, 0.0396),
      color: tokens.colors.purple,
    },

    text: {
      color: tokens.colors.dark1,

      hover: {
        color: tokens.colors.purple,
      },

      active: {
        color: tokens.colors.purple,
      },
    },
  },

  appFrame: {
    /**
     * @todo - Make this a reusable, standard value imported from base tokens.
     */
    backgroundColor: {
      default: foundations.color['color.gray-50'],
      next: '#FFFFFF',
    },
  },

  logoWithText: {
    fill: {
      primary: tokens.colors.purple,
      secondary: tokens.colors.light1,
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      secondaryInverted: '#292929',
    },
  },

  timeline: {
    entry: {
      backgroundColor: tokens.colors.dark1,
    },
    track: {
      backgroundColor: tokens.colors.light2,
    },
  },

  fileModal: {
    backgroundColor: tokens.colors.pureWhite,

    header: {
      backgroundColor: tokens.colors.pureWhite,
      borderColor: tokens.colors.light4,
    },
    sidebar: {
      /**
       * @todo - Make this a reusable, standard value imported from base tokens.
       */
      backgroundColor: '#F6F6F6',

      thumbnail: {
        /**
         * @todo - Make this a reusable, standard value imported from base tokens.
         */
        borderColor: '#C9C9C9',

        active: {
          borderColor: tokens.colors.purple,
        },
      },
    },
  },

  radio: {
    backgroundColor: tokens.colors.light1,
    borderColor: tokens.colors.dark4,
    color: tokens.colors.light1,

    hover: {
      borderColor: tokens.colors.purple,
    },

    active: {
      borderColor: tokens.colors.purple,
      backgroundColor: tokens.colors.purple,
    },

    focusRing: toAlpha(tokens.colors.lightPurple, 0.55),
  },

  multiSelect: {
    footerActions: {
      borderColor: tokens.colors.dark4,
    },
  },
}
