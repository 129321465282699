import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { createGlobalModalSlice } from './GlobalModalStore'
import { GetState, GlobalStoreType, SetState } from './storeTypes'
import { createProductSlice } from './ProductStore'
import { createUserSlice } from './UserStore'

export const useGlobalStore = create<GlobalStoreType>(
  devtools(
    persist(
      (set: SetState<GlobalStoreType>, get: GetState<GlobalStoreType>) => ({
        ...createGlobalModalSlice(set, get),
        ...createProductSlice(set, get),
        ...createUserSlice(set),
      }),
      {
        name: 'product-slice-storage',
        whitelist: ['searchHistory'],
      },
    ),
  ),
)
