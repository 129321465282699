import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CompassIconProps = IconProps

const CompassIcon = forwardRef<SVGSVGElement, CompassIconProps>(
  (props: CompassIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 0V2m9 12.938A11.971 11.971 0 0 1 12 19a11.971 11.971 0 0 1-9-4.063m7.745-6.275L3 22M13.255 8.662 21 22"
        fill="none"
      ></path>
    </Icon>
  ),
)

CompassIcon.displayName = 'CompassIcon'
CompassIcon.defaultProps = iconDefaultProps

export type { CompassIconProps }
export { CompassIcon }
