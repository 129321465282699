import React, { cloneElement, forwardRef, Fragment } from 'react'
import styled, { css } from 'styled-components'

import Spacer from '../Spacer'

import { colors, sizing, spacing, typography } from '../tokens'
import toTransitions from '../utils/toTransitions'

type ButtonSize = keyof typeof sizing.button
type ButtonColorVariant = keyof typeof colors.button.backgroundColor

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  disableFocusRing?: boolean
  fullWidth?: boolean
  iconOnly?: boolean
  leftIcon?: React.ReactElement | null
  rightIcon?: React.ReactElement | null
  rounded?: boolean
  size?: ButtonSize
  variant?: ButtonColorVariant
}

const defaultProps: Partial<ButtonProps> = {
  className: '',
  disableFocusRing: false,
  fullWidth: false,
  iconOnly: false,
  leftIcon: undefined,
  rightIcon: undefined,
  rounded: false,
  size: 'base',
  type: 'button',
  variant: 'default',
}

const setPadding =
  () =>
  ({
    iconOnly,
    leftIcon,
    rightIcon,
    size = defaultProps.size,
  }: ButtonProps): string => {
    const hasIcon = leftIcon != null || rightIcon != null

    if (iconOnly) {
      return ''
    }

    if (size === 'small') {
      return `padding: ${
        hasIcon ? spacing.button.withIcon.small : spacing.button.small
      };`
    }

    if (size === 'large') {
      return `padding: ${
        hasIcon ? spacing.button.withIcon.large : spacing.button.large
      };`
    }

    return `padding: ${
      hasIcon ? spacing.button.withIcon.base : spacing.button.base
    };`
  }

const styles = css<ButtonProps>`
  ${setPadding()}
  ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  background-color: ${({ variant = defaultProps.variant }): string =>
    colors.button.backgroundColor[variant as ButtonColorVariant]};
  border-radius: ${({ rounded }) =>
    rounded ? sizing.radius.rounded : sizing.radius.base};
  border-color: ${({ variant = defaultProps.variant }): string =>
    colors.button.borderColor[variant as ButtonColorVariant]};
  border-style: solid;
  border-width: 1px;
  color: ${({ variant = defaultProps.variant }): string =>
    colors.button.color[variant as ButtonColorVariant]};
  cursor: pointer;
  font-size: ${({ size = defaultProps.size }): string =>
    typography.button[size as ButtonSize].fontSize};
  font-weight: ${({ size = defaultProps.size }): string =>
    typography.button[size as ButtonSize].fontWeight};
  line-height: ${({ size = defaultProps.size }): number =>
    typography.button[size as ButtonSize].lineHeight};
  text-align: center;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: ${({ iconOnly, size = defaultProps.size }) =>
    iconOnly ? sizing.button[size as ButtonSize] : 'none'};
  min-width: ${({ iconOnly, size = defaultProps.size }) =>
    iconOnly ? sizing.button[size as ButtonSize] : 'none'};
  transition: ${toTransitions(
    ['background-color', 'border-color', 'box-shadow', 'color', 'opacity'],
    'ease',
  )};

  :hover,
  :focus,
  :active,
  &[data-active],
  &[aria-expanded='true'] {
    background-color: ${({ variant = defaultProps.variant }): string =>
      colors.button.hover.backgroundColor[variant as ButtonColorVariant]};
    border-color: ${({ variant = defaultProps.variant }): string =>
      colors.button.hover.borderColor[variant as ButtonColorVariant]};
    color: ${({ variant = defaultProps.variant }): string =>
      colors.button.hover.color[variant as ButtonColorVariant]};
    text-decoration: none;
    outline: none;
  }

  :active,
  :focus,
  &[aria-expanded='true'] {
    box-shadow: ${({ disableFocusRing, variant = defaultProps.variant }) =>
      !disableFocusRing
        ? `0 0 0 ${sizing.focusRing} ${
            colors.button.focusRing[variant as ButtonColorVariant]
          }`
        : 'none'};
  }

  :disabled,
  &[data-disabled] {
    cursor: none;
    opacity: 0.9;
    pointer-events: none;
  }

  > svg {
    flex-shrink: 0;
  }
`

/**
 * @deprecated Use `<ButtonNext>` instead.
 */
const StyledButton = styled(
  forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
      children,
      disableFocusRing,
      fullWidth,
      iconOnly,
      leftIcon,
      rightIcon,
      rounded,
      size,
      variant,
      ...props
    }: ButtonProps,
    ref,
  ) {
    return (
      <button
        {...props}
        data-gourmet-button
        ref={ref}
        {...(iconOnly && { 'data-icon-only-button': '' })}
      >
        {iconOnly && <Fragment>&zwnj;</Fragment>}
        {leftIcon &&
          cloneElement(
            leftIcon,
            {
              'aria-hidden': true,
              size: 'medium',
            },
            null,
          )}
        {leftIcon && !iconOnly && <Spacer inline size="tiny" />}
        {children}
        {rightIcon && !iconOnly && <Spacer inline size="tiny" />}
        {rightIcon &&
          cloneElement(
            rightIcon,
            {
              'aria-hidden': true,
              size: 'medium',
            },
            null,
          )}
      </button>
    )
  }),
)<ButtonProps>`
  ${styles}
`

StyledButton.displayName = 'Button'
StyledButton.defaultProps = defaultProps

export type { ButtonColorVariant, ButtonProps }
export { defaultProps as ButtonDefaultProps, styles }
export default StyledButton
