import {
  getLabel,
  hasSelectedOption as hasSelectedOptionUtil,
} from './reducer-utils'

import { ReducerAction, ReducerState } from './types'

export function getDefaultReducerState({
  defaultLabel = '',
  selectedOption = null,
}: Partial<ReducerState> = {}): ReducerState {
  const hasSelectedOption = hasSelectedOptionUtil(selectedOption)

  return {
    defaultLabel: defaultLabel || '',
    hasSelectedOption,
    label: getLabel(selectedOption, defaultLabel),
    optionAdded: null,
    optionRemoved: null,
    selectedOption,
  }
}

export function reducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case 'ADD_OPTION': {
      return {
        ...state,
        optionAdded: action.option,
        optionRemoved: null,
        hasSelectedOption: hasSelectedOptionUtil(action.option),
        label: getLabel(action.option),
        selectedOption: action.option,
      }
    }

    case 'REMOVE_OPTION': {
      return {
        ...state,
        optionAdded: null,
        optionRemoved: action.option || state.selectedOption,
        hasSelectedOption: false,
        label: getLabel(null, state.defaultLabel),
        selectedOption: null,
      }
    }

    case 'RESET': {
      return getDefaultReducerState(
        action.nextState || { defaultLabel: state.defaultLabel },
      )
    }

    default: {
      return state
    }
  }
}
