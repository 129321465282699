import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Recording02IconProps = IconProps

const Recording02Icon = forwardRef<SVGSVGElement, Recording02IconProps>(
  (props: Recording02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 10v4m4.5-3v2M12 6v12m4.5-15v18M21 10v4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Recording02Icon.displayName = 'Recording02Icon'
Recording02Icon.defaultProps = iconDefaultProps

export type { Recording02IconProps }
export { Recording02Icon }
