import { noop } from './noop'

/**
 * Creates a React synthetic event based on the given DOM element and event.
 * This is useful for when you need to manually trigger event handlers like
 * `onChange`.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Event}
 * @see {@link https://react.dev/reference/react-dom/components/common#react-event-object}
 */
export function createSyntheticEvent<
  TElement extends Element,
  TEvent extends Event,
>(
  /**
   * The event to based this synthetic event on.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Event}
   */
  event: TEvent,
): React.SyntheticEvent<TElement, TEvent> {
  let isDefaultPrevented = false
  let isPropagationStopped = false

  const preventDefault = () => {
    isDefaultPrevented = true
    event.preventDefault()
  }

  const stopPropagation = () => {
    isPropagationStopped = true
    event.stopPropagation()
  }

  return {
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    currentTarget: event.currentTarget as EventTarget & TElement,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    isDefaultPrevented: () => isDefaultPrevented,
    isPropagationStopped: () => isPropagationStopped,
    isTrusted: event.isTrusted,
    nativeEvent: event,
    persist: noop,
    preventDefault,
    stopPropagation,
    target: event.target as EventTarget & TElement,
    timeStamp: event.timeStamp,
    type: event.type,
  }
}

/**
 * Creates a synthetic change event for the given HTML element.
 */
export function createOnChangeEvent<TElement extends Element>(
  element: TElement,
): React.ChangeEvent<TElement> {
  const event = new Event('change', { bubbles: true })

  Object.defineProperty(event, 'target', {
    writable: false,
    value: element,
  })

  Object.defineProperty(event, 'currentTarget', {
    writable: false,
    value: element,
  })

  return createSyntheticEvent(event) as React.ChangeEvent<TElement>
}
