import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudBlank02IconProps = IconProps

const CloudBlank02Icon = forwardRef<SVGSVGElement, CloudBlank02IconProps>(
  (props: CloudBlank02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 19a7.5 7.5 0 1 1 6.641-10.988A5.5 5.5 0 1 1 16.5 19h-7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudBlank02Icon.displayName = 'CloudBlank02Icon'
CloudBlank02Icon.defaultProps = iconDefaultProps

export type { CloudBlank02IconProps }
export { CloudBlank02Icon }
