import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Recording03IconProps = IconProps

const Recording03Icon = forwardRef<SVGSVGElement, Recording03IconProps>(
  (props: Recording03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 11v2m4-4v6m4-8v10m4-6v2m4-1c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Recording03Icon.displayName = 'Recording03Icon'
Recording03Icon.defaultProps = iconDefaultProps

export type { Recording03IconProps }
export { Recording03Icon }
