import * as CSS from 'csstype'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { Size, sizing } from '../tokens'

type FlexProps = React.HTMLAttributes<HTMLDivElement | HTMLSpanElement> & {
  alignItems?: CSS.Property.AlignItems
  basis?: CSS.Property.FlexBasis
  center?: boolean
  centerX?: boolean
  centerY?: boolean
  column?: boolean
  flex?: CSS.Property.Flex
  fullHeight?: boolean
  /**
   * If `true`, this component will take up all available horizontal space.
   */
  fullWidth?: boolean
  grow?: CSS.Property.FlexGrow
  inline?: boolean
  justifyContent?: CSS.Property.JustifyContent
  shrink?: CSS.Property.FlexShrink
  size?: Size
  wrap?: CSS.Property.FlexWrap | boolean
}

const defaultProps: Partial<FlexProps> = {
  center: false,
  centerX: false,
  centerY: false,
  className: '',
  column: false,
  fullHeight: false,
  fullWidth: false,
  inline: false,
  wrap: false,
}

const setAlignItems =
  () =>
  ({ alignItems, center, centerX, centerY, column }: FlexProps): string => {
    if (center || (centerY && !column) || (centerX && column)) {
      return 'align-items: center;'
    } else if (alignItems) {
      return `align-items: ${alignItems};`
    }

    return ''
  }

const setJustifyContent =
  () =>
  ({ justifyContent, center, centerX, centerY, column }: FlexProps): string => {
    if (center || (centerX && !column) || (centerY && column)) {
      return 'justify-content: center;'
    } else if (justifyContent) {
      return `justify-content: ${justifyContent};`
    }

    return ''
  }

const setWrap =
  () =>
  ({ wrap = defaultProps.wrap }: FlexProps): string => {
    if (wrap === true) {
      return 'flex-wrap: wrap;'
    } else if (wrap) {
      return `flex-wrap: ${wrap};`
    }

    return ''
  }

/**
 * @deprecated Use `<FlexNext>` and `<FlexInline>` instead
 */
const StyledFlex = styled(
  forwardRef<HTMLDivElement | HTMLSpanElement, FlexProps>(function Flex(
    {
      alignItems,
      basis,
      center,
      centerX,
      centerY,
      column,
      flex,
      fullHeight,
      fullWidth,
      grow,
      inline,
      justifyContent,
      shrink,
      size,
      wrap,
      ...props
    }: FlexProps,
    ref,
  ) {
    return inline ? (
      <span
        {...props}
        data-gourmet-flex=""
        ref={ref as React.Ref<HTMLSpanElement>}
      />
    ) : (
      <div
        {...props}
        data-gourmet-flex=""
        ref={ref as React.Ref<HTMLDivElement>}
      />
    )
  }),
)`
  ${setAlignItems()}
  ${setJustifyContent()}
  ${setWrap()}
  ${({ basis }): string => (basis != null ? `flex-basis: ${basis};` : '')}
  ${({ flex }): string => (flex != null ? `flex: ${flex};` : '')}
  ${({ fullHeight }): string => (fullHeight ? 'height: 100%;' : '')}
  ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  ${({ grow }): string => (grow != null ? `flex-grow: ${grow};` : '')}
  ${({ shrink }): string => (shrink != null ? `flex-shrink: ${shrink};` : '')}
  ${({ fullWidth, size = defaultProps.size }): string =>
    !fullWidth && size != null
      ? `max-width: ${sizing.container[size as Size]}; width: 100%;`
      : ''}
  display: ${({ inline }): string => (inline ? 'inline-flex' : 'flex')};
  flex-direction: ${({ column }): string => (column ? 'column' : 'row')};
`

StyledFlex.displayName = 'Flex'
StyledFlex.defaultProps = defaultProps

export type { FlexProps }
export { defaultProps as FlexDefaultProps }
export default StyledFlex
