import React from 'react'
import styled from 'styled-components'

import { TextCoreProps, TextProps } from './types'
import { textStyles } from './styles'
import { propsToStyledProps } from '../utils'

const StyledText = styled.span(textStyles)

const defaultProps: Partial<TextCoreProps> = {
  size: 'text-md',
  variant: 'default',
  weight: 'regular',
}

/**
 * The `<Text>` component is the core typography component used to display text
 * in different sizes and formats. This component doesn't carry any semantic
 * meaning, i.e. the text will be rendered within a `<span>` element.
 *
 * @example
 * <Text>Here is some text</Text>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Learn/CSS/Styling_text/Fundamentals}
 */
const Text = React.forwardRef<HTMLSpanElement, TextProps>(function Text(
  {
    align,
    children,
    decoration,
    fullWidth,
    lineHeight,
    overflow,
    overflowWrap,
    size,
    transform,
    truncate,
    variant,
    weight,
    whiteSpace,
    wordBreak,
    ...props
  }: TextProps,
  ref,
): JSX.Element {
  return (
    <StyledText
      {...propsToStyledProps({
        align,
        decoration,
        fullWidth,
        lineHeight,
        overflow,
        overflowWrap,
        size,
        transform,
        truncate,
        variant,
        weight,
        whiteSpace,
        wordBreak,
      })}
      {...props}
      data-gourmet-text=""
      ref={ref}
    >
      {children}
    </StyledText>
  )
})

Text.displayName = 'Text'
Text.defaultProps = defaultProps

export { Text, defaultProps as textDefaultProps }
