import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCcw01IconProps = IconProps

const RefreshCcw01Icon = forwardRef<SVGSVGElement, RefreshCcw01IconProps>(
  (props: RefreshCcw01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 10s2.005-2.732 3.634-4.362A9 9 0 1 1 12 21a9.004 9.004 0 0 1-8.648-6.5M2 10V4m0 6h6"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCcw01Icon.displayName = 'RefreshCcw01Icon'
RefreshCcw01Icon.defaultProps = iconDefaultProps

export type { RefreshCcw01IconProps }
export { RefreshCcw01Icon }
