import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyRupeeIconProps = IconProps

const CurrencyRupeeIcon = forwardRef<SVGSVGElement, CurrencyRupeeIconProps>(
  (props: CurrencyRupeeIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 3h12M6 8h12m-3.5 13L6 13h3c6.667 0 6.667-10 0-10"
        fill="none"
      ></path>
    </Icon>
  ),
)

CurrencyRupeeIcon.displayName = 'CurrencyRupeeIcon'
CurrencyRupeeIcon.defaultProps = iconDefaultProps

export type { CurrencyRupeeIconProps }
export { CurrencyRupeeIcon }
