import React from 'react'

export function MasterCardIcon(): JSX.Element {
  return (
    <g>
      <rect width="46" height="32" rx="4" fill="#FFFFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9053 22.4393C21.3266 23.77 19.2787 24.5733 17.0409 24.5733C12.0478 24.5733 8 20.5737 8 15.64C8 10.7063 12.0478 6.70667 17.0409 6.70667C19.2787 6.70667 21.3266 7.51 22.9053 8.84072C24.484 7.51 26.5319 6.70667 28.7697 6.70667C33.7628 6.70667 37.8106 10.7063 37.8106 15.64C37.8106 20.5737 33.7628 24.5733 28.7697 24.5733C26.5319 24.5733 24.484 23.77 22.9053 22.4393Z"
        fill="#484848"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9053 22.4393C24.8492 20.8007 26.0818 18.3626 26.0818 15.64C26.0818 12.9174 24.8492 10.4793 22.9053 8.84072C24.484 7.50999 26.5319 6.70667 28.7697 6.70667C33.7628 6.70667 37.8106 10.7063 37.8106 15.64C37.8106 20.5737 33.7628 24.5733 28.7697 24.5733C26.5319 24.5733 24.484 23.77 22.9053 22.4393Z"
        fill="#7e7e7e"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9053 22.4393C24.8492 20.8008 26.0818 18.3627 26.0818 15.6401C26.0818 12.9175 24.8492 10.4794 22.9053 8.84082C20.9614 10.4794 19.7288 12.9175 19.7288 15.6401C19.7288 18.3627 20.9614 20.8008 22.9053 22.4393Z"
        fill="#a7a7a7"
      />
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" stroke="#F4F4F5" />
    </g>
  )
}
