import * as CSS from 'csstype'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

type GridItemProps = React.HTMLAttributes<HTMLDivElement> & {
  alignSelf?: CSS.Property.AlignSelf
  area?: CSS.Property.GridArea
  column?: CSS.Property.GridColumn
  columnEnd?: CSS.Property.GridColumnEnd
  columnStart?: CSS.Property.GridColumnStart
  justifySelf?: CSS.Property.JustifySelf
  placeSelf?: CSS.Property.PlaceSelf
  row?: CSS.Property.GridRow
  rowEnd?: CSS.Property.GridRowEnd
  rowStart?: CSS.Property.GridRowStart
}

const defaultProps: Partial<GridItemProps> = {
  className: '',
}

const StyledGridItem = styled(
  forwardRef<HTMLDivElement, GridItemProps>(function GridItem(
    {
      alignSelf,
      area,
      column,
      columnEnd,
      columnStart,
      justifySelf,
      placeSelf,
      row,
      rowEnd,
      rowStart,
      ...props
    }: GridItemProps,
    ref,
  ) {
    return <div {...props} ref={ref} />
  }),
)<GridItemProps>`
  ${({ alignSelf }): string => (alignSelf ? `align-self: ${alignSelf};` : '')}
  ${({ area }): string => (area ? `grid-area: ${area};` : '')}
  ${({ column }): string => (column ? `grid-column: ${column};` : '')}
  ${({ columnStart }): string =>
    columnStart ? `grid-column-start: ${columnStart};` : ''}
  ${({ columnEnd }): string =>
    columnEnd ? `grid-column-end: ${columnEnd};` : ''}
  ${({ justifySelf }): string =>
    justifySelf ? `justify-self: ${justifySelf};` : ''}
  ${({ placeSelf }): string => (placeSelf ? `place-self: ${placeSelf};` : '')}
  ${({ row }): string => (row ? `grid-row: ${row};` : '')}
  ${({ rowStart }): string => (rowStart ? `grid-row-start: ${rowStart};` : '')}
  ${({ rowEnd }): string => (rowEnd ? `grid-row-end: ${rowEnd};` : '')}
`

StyledGridItem.displayName = 'GridItem'
StyledGridItem.defaultProps = defaultProps

export type { GridItemProps }
export { defaultProps as GridItemDefaultProps }
export default StyledGridItem
