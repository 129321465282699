import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowNarrowUpIconProps = IconProps

const ArrowNarrowUpIcon = forwardRef<SVGSVGElement, ArrowNarrowUpIconProps>(
  (props: ArrowNarrowUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20V4m0 0-6 6m6-6 6 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowNarrowUpIcon.displayName = 'ArrowNarrowUpIcon'
ArrowNarrowUpIcon.defaultProps = iconDefaultProps

export type { ArrowNarrowUpIconProps }
export { ArrowNarrowUpIcon }
