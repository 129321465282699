import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BluetoothIconProps = IconProps

const BluetoothIcon = forwardRef<SVGSVGElement, BluetoothIconProps>(
  (props: BluetoothIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 17 6-5v10l5.44-4.533M12 7V2l6 5-2.918 2.432M21 21 3 3"
        fill="none"
      ></path>
    </Icon>
  ),
)

BluetoothIcon.displayName = 'BluetoothIcon'
BluetoothIcon.defaultProps = iconDefaultProps

export type { BluetoothIconProps }
export { BluetoothIcon }
