import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TypeStrikethrough02IconProps = IconProps

const TypeStrikethrough02Icon = forwardRef<
  SVGSVGElement,
  TypeStrikethrough02IconProps
>((props: TypeStrikethrough02IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 20h8m-5.75-9.5V20m3.5-6v6M3 3l18 18M4 7V6c0-.541.215-1.032.564-1.392M9.5 4H17c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C20 5.602 20 6.068 20 7m-9.75-3v1m3.5-1v4"
      fill="none"
    ></path>
  </Icon>
))

TypeStrikethrough02Icon.displayName = 'TypeStrikethrough02Icon'
TypeStrikethrough02Icon.defaultProps = iconDefaultProps

export type { TypeStrikethrough02IconProps }
export { TypeStrikethrough02Icon }
