import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type InfoCircleIconProps = IconProps

const InfoCircleIcon = forwardRef<SVGSVGElement, InfoCircleIconProps>(
  (props: InfoCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 16v-4m0-4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

InfoCircleIcon.displayName = 'InfoCircleIcon'
InfoCircleIcon.defaultProps = iconDefaultProps

export type { InfoCircleIconProps }
export { InfoCircleIcon }
