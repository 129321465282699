import { hexToRgba } from '../utils'
import { TokensWithMetadataMap } from '../types'

export const tokens = {
  /**
   * Base white color style to quickly swap out if you need to.
   */
  'color.base-white': '#FFFFFF',
  /**
   * Base black to quickly swap out if you need to.
   */
  'color.base-black': '#000000',
  /**
   * Base black to quickly swap out if you need to.
   */
  'color.squid-ink': '#0D0C0F',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-25': '#FCFCFC',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-50': '#FAFAFA',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-100': '#F4F4F5',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-200': '#E4E4E7',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-300': '#D1D1D6',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-400': '#A0A0AB',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-500': '#70707B',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-600': '#51525C',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-700': '#3F3F46',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-800': '#26272B',
  /**
   * Gray is a neutral color and is the foundation of
   * the color system. Almost everything in UI design — text, form fields,
   * backgrounds, dividers — are usually gray.
   */
  'color.gray-900': '#15141B',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-25': '#FAFAFF',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-50': '#F4F3FF',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-100': '#EBE9FE',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-200': '#D9D6FE',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-300': '#BDB4FE',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-400': '#9B8AFB',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-500': '#7A5AF8',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-600': '#6038EF',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-700': '#5235D0',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-800': '#3B1EA6',
  /**
   * The primary color is the "brand" color, and is used across all interactive
   * elements such as buttons, links, inputs, etc. This color can define the
   * overall feel and can elicit emotion.
   */
  'color.primary-900': '#341887',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-25': '#FFFAFA',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-50': '#FEF2F3',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-100': '#FEE2E5',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-200': '#FFC9CF',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-300': '#FD9AA4',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-400': '#FA6675',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-500': '#F0374A',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-600': '#D62738',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-700': '#BB0C1D',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-800': '#91171D',
  /**
   * Error colors are used across error states and in "destructive" actions.
   * They communicate a destructive/negative action, such as removing a user from
   * your team.
   */
  'color.error-900': '#7A1A23',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-25': '#F6FEF9',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-50': '#ECFDF3',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-100': '#D1FAE1',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-200': '#A6F4C8',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-300': '#6CE9AA',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-400': '#32D589',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-500': '#1BC47D',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-600': '#14995E',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-700': '#027A4C',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-800': '#05603D',
  /**
   * Success colors communicate a positive action, positive trend, or a
   * successful confirmation. If you're using green as your primary color, it
   * can be helpful to introduce a different hue for your success green.
   */
  'color.success-900': '#054F34',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-25': '#FEFDF0',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-50': '#FEFDE8',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-100': '#FEFAC3',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-200': '#FEF495',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-300': '#FFE959',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-400': '#FAD415',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-500': '#EABD08',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-600': '#CA9E18',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-700': '#A17016',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-800': '#85570E',
  /**
   * Warning colors can communicate that an action is potentially destructive
   * or "on-hold". These colors are commonly used in confirmations to grab the
   * users' attention.
   */
  'color.warning-900': '#5E3D0F',
} as const

export const tokensWithMetadata: TokensWithMetadataMap<'color', typeof tokens> =
  {
    'color.base-white': {
      description: 'Base white color style to quickly swap out if you need to.',
      introducedIn: 'v0.0.1',
      name: 'color.base-white',
      type: 'color',
      value: tokens['color.base-white'],
      metadata: {
        conformanceLevel: 'AAA 21.1',
        hex: tokens['color.base-white'],
        rgba: hexToRgba(tokens['color.base-white']),
        swatchBase: 'White',
      },
    },
    'color.base-black': {
      description: 'Base black to quickly swap out if you need to.',
      introducedIn: 'v0.0.1',
      name: 'color.base-black',
      type: 'color',
      value: tokens['color.base-black'],
      metadata: {
        conformanceLevel: 'AAA 21.1',
        hex: tokens['color.base-black'],
        rgba: hexToRgba(tokens['color.base-black']),
        swatchBase: 'Black',
      },
    },
    'color.squid-ink': {
      description: 'Base black to quickly swap out if you need to.',
      introducedIn: 'v0.0.1',
      name: 'color.squid-ink',
      type: 'color',
      value: tokens['color.squid-ink'],
      metadata: {
        conformanceLevel: 'AAA 19.5',
        hex: tokens['color.squid-ink'],
        rgba: hexToRgba(tokens['color.squid-ink']),
        swatchBase: 'Squid Ink',
      },
    },
    'color.gray-25': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-25',
      type: 'color',
      value: tokens['color.gray-25'],
      metadata: {
        conformanceLevel: 'AA 4.84',
        hex: tokens['color.gray-25'],
        rgba: hexToRgba(tokens['color.gray-25']),
        swatchBase: '25',
      },
    },
    'color.gray-50': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-50',
      type: 'color',
      value: tokens['color.gray-50'],
      metadata: {
        conformanceLevel: 'AA 4.73',
        hex: tokens['color.gray-50'],
        rgba: hexToRgba(tokens['color.gray-50']),
        swatchBase: '50',
      },
    },
    'color.gray-100': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-100',
      type: 'color',
      value: tokens['color.gray-100'],
      metadata: {
        conformanceLevel: 'AA 4.49',
        hex: tokens['color.gray-100'],
        rgba: hexToRgba(tokens['color.gray-100']),
        swatchBase: '100',
      },
    },
    'color.gray-200': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-200',
      type: 'color',
      value: tokens['color.gray-200'],
      metadata: {
        conformanceLevel: '4.19',
        hex: tokens['color.gray-200'],
        rgba: hexToRgba(tokens['color.gray-200']),
        swatchBase: '200',
      },
    },
    'color.gray-300': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-300',
      type: 'color',
      value: tokens['color.gray-300'],
      metadata: {
        conformanceLevel: '1.48',
        hex: tokens['color.gray-300'],
        rgba: hexToRgba(tokens['color.gray-300']),
        swatchBase: '300',
      },
    },
    'color.gray-400': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-400',
      type: 'color',
      value: tokens['color.gray-400'],
      metadata: {
        conformanceLevel: '2.58',
        hex: tokens['color.gray-400'],
        rgba: hexToRgba(tokens['color.gray-400']),
        swatchBase: '400',
      },
    },
    'color.gray-500': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-500',
      type: 'color',
      value: tokens['color.gray-500'],
      metadata: {
        conformanceLevel: 'AA 4.95',
        hex: tokens['color.gray-500'],
        rgba: hexToRgba(tokens['color.gray-500']),
        swatchBase: '500',
      },
    },
    'color.gray-600': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-600',
      type: 'color',
      value: tokens['color.gray-600'],
      metadata: {
        conformanceLevel: 'AAA 7.65',
        hex: tokens['color.gray-600'],
        rgba: hexToRgba(tokens['color.gray-600']),
        swatchBase: '600',
      },
    },
    'color.gray-700': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-700',
      type: 'color',
      value: tokens['color.gray-700'],
      metadata: {
        conformanceLevel: 'AAA 10.41',
        hex: tokens['color.gray-700'],
        rgba: hexToRgba(tokens['color.gray-700']),
        swatchBase: '700',
      },
    },
    'color.gray-800': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-800',
      type: 'color',
      value: tokens['color.gray-800'],
      metadata: {
        conformanceLevel: 'AAA 14.74',
        hex: tokens['color.gray-800'],
        rgba: hexToRgba(tokens['color.gray-800']),
        swatchBase: '800',
      },
    },
    'color.gray-900': {
      description:
        'Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form fields, backgrounds, dividers — are usually gray.',
      introducedIn: 'v0.0.1',
      name: 'color.gray-900',
      type: 'color',
      value: tokens['color.gray-900'],
      metadata: {
        conformanceLevel: 'AAA 17.79',
        hex: tokens['color.gray-900'],
        rgba: hexToRgba(tokens['color.gray-900']),
        swatchBase: '900',
      },
    },
    'color.primary-25': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-25',
      type: 'color',
      value: tokens['color.primary-25'],
      metadata: {
        conformanceLevel: 'AA 6.39',
        hex: tokens['color.primary-25'],
        rgba: hexToRgba(tokens['color.primary-25']),
        swatchBase: '25',
      },
    },
    'color.primary-50': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-50',
      type: 'color',
      value: tokens['color.primary-50'],
      metadata: {
        conformanceLevel: 'AA 6.16',
        hex: tokens['color.primary-50'],
        rgba: hexToRgba(tokens['color.primary-50']),
        swatchBase: '50',
      },
    },
    'color.primary-100': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-100',
      type: 'color',
      value: tokens['color.primary-100'],
      metadata: {
        conformanceLevel: 'AA 5.74',
        hex: tokens['color.primary-100'],
        rgba: hexToRgba(tokens['color.primary-100']),
        swatchBase: '100',
      },
    },
    'color.primary-200': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-200',
      type: 'color',
      value: tokens['color.primary-200'],
      metadata: {
        conformanceLevel: 'AA 4.93',
        hex: tokens['color.primary-200'],
        rgba: hexToRgba(tokens['color.primary-200']),
        swatchBase: '200',
      },
    },
    'color.primary-300': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-300',
      type: 'color',
      value: tokens['color.primary-300'],
      metadata: {
        conformanceLevel: '1.70',
        hex: tokens['color.primary-300'],
        rgba: hexToRgba(tokens['color.primary-300']),
        swatchBase: '300',
      },
    },
    'color.primary-400': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-400',
      type: 'color',
      value: tokens['color.primary-400'],
      metadata: {
        conformanceLevel: '2.49',
        hex: tokens['color.primary-400'],
        rgba: hexToRgba(tokens['color.primary-400']),
        swatchBase: '400',
      },
    },
    'color.primary-500': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-500',
      type: 'color',
      value: tokens['color.primary-500'],
      metadata: {
        conformanceLevel: '3.33',
        hex: tokens['color.primary-500'],
        rgba: hexToRgba(tokens['color.primary-500']),
        swatchBase: '500',
      },
    },
    'color.primary-600': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-600',
      type: 'color',
      value: tokens['color.primary-600'],
      metadata: {
        conformanceLevel: 'AA 4.96',
        hex: tokens['color.primary-600'],
        rgba: hexToRgba(tokens['color.primary-600']),
        swatchBase: '600',
      },
    },
    'color.primary-700': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-700',
      type: 'color',
      value: tokens['color.primary-700'],
      metadata: {
        conformanceLevel: 'AA 6.63',
        hex: tokens['color.primary-700'],
        rgba: hexToRgba(tokens['color.primary-700']),
        swatchBase: '700',
      },
    },
    'color.primary-800': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-800',
      type: 'color',
      value: tokens['color.primary-800'],
      metadata: {
        conformanceLevel: 'AAA 8.67',
        hex: tokens['color.primary-800'],
        rgba: hexToRgba(tokens['color.primary-800']),
        swatchBase: '800',
      },
    },
    'color.primary-900': {
      description:
        'The primary color is your "brand" color, and is used across all interactive elements such as buttons, links, inputs, etc. This color can define the overall feel and can elicit emotion.',
      introducedIn: 'v0.0.1',
      name: 'color.primary-900',
      type: 'color',
      value: tokens['color.primary-900'],
      metadata: {
        conformanceLevel: 'AAA 10.78',
        hex: tokens['color.primary-900'],
        rgba: hexToRgba(tokens['color.primary-900']),
        swatchBase: '900',
      },
    },
    'color.error-25': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-25',
      type: 'color',
      value: tokens['color.error-25'],
      metadata: {
        conformanceLevel: 'AA 6.4',
        hex: tokens['color.error-25'],
        rgba: hexToRgba(tokens['color.error-25']),
        swatchBase: '25',
      },
    },
    'color.error-50': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-50',
      type: 'color',
      value: tokens['color.error-50'],
      metadata: {
        conformanceLevel: 'AA 6.06',
        hex: tokens['color.error-50'],
        rgba: hexToRgba(tokens['color.error-50']),
        swatchBase: '50',
      },
    },
    'color.error-100': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-100',
      type: 'color',
      value: tokens['color.error-100'],
      metadata: {
        conformanceLevel: 'AA 5.4',
        hex: tokens['color.error-100'],
        rgba: hexToRgba(tokens['color.error-100']),
        swatchBase: '100',
      },
    },
    'color.error-200': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-200',
      type: 'color',
      value: tokens['color.error-200'],
      metadata: {
        conformanceLevel: 'AA 4.56',
        hex: tokens['color.error-200'],
        rgba: hexToRgba(tokens['color.error-200']),
        swatchBase: '200',
      },
    },
    'color.error-300': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-300',
      type: 'color',
      value: tokens['color.error-300'],
      metadata: {
        conformanceLevel: '1.95',
        hex: tokens['color.error-300'],
        rgba: hexToRgba(tokens['color.error-300']),
        swatchBase: '300',
      },
    },
    'color.error-400': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-400',
      type: 'color',
      value: tokens['color.error-400'],
      metadata: {
        conformanceLevel: '2.78',
        hex: tokens['color.error-400'],
        rgba: hexToRgba(tokens['color.error-400']),
        swatchBase: '400',
      },
    },
    'color.error-500': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-500',
      type: 'color',
      value: tokens['color.error-500'],
      metadata: {
        conformanceLevel: '3.76',
        hex: tokens['color.error-500'],
        rgba: hexToRgba(tokens['color.error-500']),
        swatchBase: '500',
      },
    },
    'color.error-600': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-600',
      type: 'color',
      value: tokens['color.error-600'],
      metadata: {
        conformanceLevel: 'AA 4.82',
        hex: tokens['color.error-600'],
        rgba: hexToRgba(tokens['color.error-600']),
        swatchBase: '600',
      },
    },
    'color.error-700': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-700',
      type: 'color',
      value: tokens['color.error-700'],
      metadata: {
        conformanceLevel: 'AA 6.60',
        hex: tokens['color.error-700'],
        rgba: hexToRgba(tokens['color.error-700']),
        swatchBase: '700',
      },
    },
    'color.error-800': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-800',
      type: 'color',
      value: tokens['color.error-800'],
      metadata: {
        conformanceLevel: 'AAA 8.70',
        hex: tokens['color.error-800'],
        rgba: hexToRgba(tokens['color.error-800']),
        swatchBase: '800',
      },
    },
    'color.error-900': {
      description:
        'Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative action, such as removing a user from your team.',
      introducedIn: 'v0.0.1',
      name: 'color.error-900',
      type: 'color',
      value: tokens['color.error-900'],
      metadata: {
        conformanceLevel: 'AAA 9.86',
        hex: tokens['color.error-900'],
        rgba: hexToRgba(tokens['color.error-900']),
        swatchBase: '900',
      },
    },
    'color.success-25': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-25',
      type: 'color',
      value: tokens['color.success-25'],
      metadata: {
        conformanceLevel: 'AA 5.31',
        hex: tokens['color.success-25'],
        rgba: hexToRgba(tokens['color.success-25']),
        swatchBase: '25',
      },
    },
    'color.success-50': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-50',
      type: 'color',
      value: tokens['color.success-50'],
      metadata: {
        conformanceLevel: 'AA 5.17',
        hex: tokens['color.success-50'],
        rgba: hexToRgba(tokens['color.success-50']),
        swatchBase: '50',
      },
    },
    'color.success-100': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-100',
      type: 'color',
      value: tokens['color.success-100'],
      metadata: {
        conformanceLevel: 'AA 4.79',
        hex: tokens['color.success-100'],
        rgba: hexToRgba(tokens['color.success-100']),
        swatchBase: '100',
      },
    },
    'color.success-200': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-200',
      type: 'color',
      value: tokens['color.success-200'],
      metadata: {
        conformanceLevel: '4.24',
        hex: tokens['color.success-200'],
        rgba: hexToRgba(tokens['color.success-200']),
        swatchBase: '200',
      },
    },
    'color.success-300': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-300',
      type: 'color',
      value: tokens['color.success-300'],
      metadata: {
        conformanceLevel: '1.52',
        hex: tokens['color.success-300'],
        rgba: hexToRgba(tokens['color.success-300']),
        swatchBase: '300',
      },
    },
    'color.success-400': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-400',
      type: 'color',
      value: tokens['color.success-400'],
      metadata: {
        conformanceLevel: '1.91',
        hex: tokens['color.success-400'],
        rgba: hexToRgba(tokens['color.success-400']),
        swatchBase: '400',
      },
    },
    'color.success-500': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-500',
      type: 'color',
      value: tokens['color.success-500'],
      metadata: {
        conformanceLevel: '2.63',
        hex: tokens['color.success-500'],
        rgba: hexToRgba(tokens['color.success-500']),
        swatchBase: '500',
      },
    },
    'color.success-600': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-600',
      type: 'color',
      value: tokens['color.success-600'],
      metadata: {
        conformanceLevel: '3.74',
        hex: tokens['color.success-600'],
        rgba: hexToRgba(tokens['color.success-600']),
        swatchBase: '600',
      },
    },
    'color.success-700': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-700',
      type: 'color',
      value: tokens['color.success-700'],
      metadata: {
        conformanceLevel: 'AA 5.44',
        hex: tokens['color.success-700'],
        rgba: hexToRgba(tokens['color.success-700']),
        swatchBase: '700',
      },
    },
    'color.success-800': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-800',
      type: 'color',
      value: tokens['color.success-800'],
      metadata: {
        conformanceLevel: 'AAA 7.67',
        hex: tokens['color.success-800'],
        rgba: hexToRgba(tokens['color.success-800']),
        swatchBase: '800',
      },
    },
    'color.success-900': {
      description:
        "Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green as your primary color, it can be helpful to introduce a different hue for your success green.",
      introducedIn: 'v0.0.1',
      name: 'color.success-900',
      type: 'color',
      value: tokens['color.success-900'],
      metadata: {
        conformanceLevel: 'AAA 9.68',
        hex: tokens['color.success-900'],
        rgba: hexToRgba(tokens['color.success-900']),
        swatchBase: '900',
      },
    },
    'color.warning-25': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-25',
      type: 'color',
      value: tokens['color.warning-25'],
      metadata: {
        conformanceLevel: 'AA 5.28',
        hex: tokens['color.warning-25'],
        rgba: hexToRgba(tokens['color.warning-25']),
        swatchBase: '25',
      },
    },
    'color.warning-50': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-50',
      type: 'color',
      value: tokens['color.warning-50'],
      metadata: {
        conformanceLevel: 'AA 5.17',
        hex: tokens['color.warning-50'],
        rgba: hexToRgba(tokens['color.warning-50']),
        swatchBase: '50',
      },
    },
    'color.warning-100': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-100',
      type: 'color',
      value: tokens['color.warning-100'],
      metadata: {
        conformanceLevel: 'AA 4.75',
        hex: tokens['color.warning-100'],
        rgba: hexToRgba(tokens['color.warning-100']),
        swatchBase: '100',
      },
    },
    'color.warning-200': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-200',
      type: 'color',
      value: tokens['color.warning-200'],
      metadata: {
        conformanceLevel: '4.15',
        hex: tokens['color.warning-200'],
        rgba: hexToRgba(tokens['color.warning-200']),
        swatchBase: '200',
      },
    },
    'color.warning-300': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-300',
      type: 'color',
      value: tokens['color.warning-300'],
      metadata: {
        conformanceLevel: '1.54',
        hex: tokens['color.warning-300'],
        rgba: hexToRgba(tokens['color.warning-300']),
        swatchBase: '300',
      },
    },
    'color.warning-400': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-400',
      type: 'color',
      value: tokens['color.warning-400'],
      metadata: {
        conformanceLevel: '1.84',
        hex: tokens['color.warning-400'],
        rgba: hexToRgba(tokens['color.warning-400']),
        swatchBase: '400',
      },
    },
    'color.warning-500': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-500',
      type: 'color',
      value: tokens['color.warning-500'],
      metadata: {
        conformanceLevel: '2.34',
        hex: tokens['color.warning-500'],
        rgba: hexToRgba(tokens['color.warning-500']),
        swatchBase: '500',
      },
    },
    'color.warning-600': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-600',
      type: 'color',
      value: tokens['color.warning-600'],
      metadata: {
        conformanceLevel: '3.49',
        hex: tokens['color.warning-600'],
        rgba: hexToRgba(tokens['color.warning-600']),
        swatchBase: '600',
      },
    },
    'color.warning-700': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-700',
      type: 'color',
      value: tokens['color.warning-700'],
      metadata: {
        conformanceLevel: 'AA 5.40',
        hex: tokens['color.warning-700'],
        rgba: hexToRgba(tokens['color.warning-700']),
        swatchBase: '700',
      },
    },
    'color.warning-800': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-800',
      type: 'color',
      value: tokens['color.warning-800'],
      metadata: {
        conformanceLevel: 'AAA 7.49',
        hex: tokens['color.warning-800'],
        rgba: hexToRgba(tokens['color.warning-800']),
        swatchBase: '800',
      },
    },
    'color.warning-900': {
      description:
        'Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly used in confirmations to grab the users\' attention.',
      introducedIn: 'v0.0.1',
      name: 'color.warning-900',
      type: 'color',
      value: tokens['color.warning-900'],
      metadata: {
        conformanceLevel: 'AAA 9.48',
        hex: tokens['color.warning-900'],
        rgba: hexToRgba(tokens['color.warning-900']),
        swatchBase: '900',
      },
    },
  } as const

export type TokenFoundationColor = keyof typeof tokens
