import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Toggle01RightIconProps = IconProps

const Toggle01RightIcon = forwardRef<SVGSVGElement, Toggle01RightIconProps>(
  (props: Toggle01RightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 17H7A5 5 0 0 1 7 7h10m0 10a5 5 0 0 0 0-10m0 10a5 5 0 0 1 0-10"
        fill="none"
      ></path>
    </Icon>
  ),
)

Toggle01RightIcon.displayName = 'Toggle01RightIcon'
Toggle01RightIcon.defaultProps = iconDefaultProps

export type { Toggle01RightIconProps }
export { Toggle01RightIcon }
