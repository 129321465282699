import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Brush01IconProps = IconProps

const Brush01Icon = forwardRef<SVGSVGElement, Brush01IconProps>(
  (props: Brush01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 11.222 12.778 15m-4.803 5.975C6.608 22.342 4 22 2 22c1.025-2-.342-4.608 1.025-5.975a3.5 3.5 0 0 1 4.95 4.95Zm3.947-5.05 9.137-9.868a2.204 2.204 0 0 0-3.116-3.116L8.075 12.08c-.51.472-.765.708-.913.96a2 2 0 0 0-.038 1.967c.139.257.385.503.876.994.491.492.737.737.994.876a2 2 0 0 0 1.968-.038c.251-.148.487-.403.96-.913Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Brush01Icon.displayName = 'Brush01Icon'
Brush01Icon.defaultProps = iconDefaultProps

export type { Brush01IconProps }
export { Brush01Icon }
