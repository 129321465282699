import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { ButtonColorVariant } from '../Button'
import { colors } from '../tokens'

import NavButton, { NavButtonDefaultProps, NavButtonProps } from '../NavButton'

type ClickableNavProps = NavButtonProps & {
  noPadding?: boolean
}

const defaultProps: Partial<ClickableNavProps> = {
  ...NavButtonDefaultProps,
  noPadding: false,
}

/**
 * @deprecated Use `<NavButtonNext asClickable>` instead
 */
const StylesClickableNav = styled(
  forwardRef<HTMLAnchorElement, ClickableNavProps>(function ClickableNav(
    { noPadding, ...props }: ClickableNavProps,
    ref,
  ) {
    return <NavButton {...props} ref={ref} />
  }),
)<ClickableNavProps>`
  ${({ noPadding }): string => (noPadding ? 'padding: 0px;' : '')}
  color: ${({ variant = defaultProps.variant }): string =>
    colors.clickable.color[variant as ButtonColorVariant]};
  background-color: ${colors.clickable.backgroundColor};
  border-color: ${colors.clickable.borderColor};

  :hover,
  :focus,
  :active {
    color: ${({ variant = defaultProps.variant }): string =>
      colors.clickable.hover.color[variant as ButtonColorVariant]};
    background-color: ${colors.clickable.hover.backgroundColor};
    border-color: ${colors.clickable.hover.borderColor};
  }
`

StylesClickableNav.displayName = 'ClickableNav'
StylesClickableNav.defaultProps = defaultProps

export type { ClickableNavProps }
export { defaultProps as ClickableNavDefaultProps }
export default StylesClickableNav
