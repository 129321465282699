import { MenuButton } from '@reach/menu-button'
import React from 'react'

import { Button, ButtonProps } from '../ButtonNext'

type Props = ButtonProps & {
  /**
   * A boolean flag used to conditionally apply active styles to the popup's
   * trigger button.
   */
  isActive?: boolean
}

const PopupTrigger = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<Props>
>(function PopupTrigger(
  { children, isActive, ...props }: React.PropsWithChildren<Props>,
  ref,
) {
  return (
    <MenuButton
      {...props}
      {...(isActive && { 'data-active': '' })}
      as={Button}
      data-gourmet-popup-trigger=""
      ref={ref}
    >
      {children}
    </MenuButton>
  )
})

PopupTrigger.displayName = 'PopupTrigger'
PopupTrigger.defaultProps = {}

export type { Props as PopupTriggerProps }
export { PopupTrigger }
