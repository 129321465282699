import { useRef, useEffect } from 'react'

type HookOptions<T> = {
  /**
   * The value you want to store in between renders.
   */
  value: T
}

/**
 * The last value cached between renders.
 */
type HookReturn<T> = T | undefined

/**
 * Returns the previous value.
 */
function usePreviousValue<T>(value: HookOptions<T>['value']): HookReturn<T> {
  const valueRef = useRef<T>()

  /**
   * Store the current `value` in `valueRef` if `value` changes.
   * The `current` property on refs is mutable and shared between renders.
   */
  useEffect(() => {
    valueRef.current = value
  }, [value])

  /**
   * Return previous value.
   */
  return valueRef.current
}

export type { HookOptions, HookReturn }
export default usePreviousValue
