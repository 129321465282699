import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SwitchVertical02IconProps = IconProps

const SwitchVertical02Icon = forwardRef<
  SVGSVGElement,
  SwitchVertical02IconProps
>((props: SwitchVertical02IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 4v16m0 0-4-4m4 4 4-4m6 4V4m0 0-4 4m4-4 4 4"
      fill="none"
    ></path>
  </Icon>
))

SwitchVertical02Icon.displayName = 'SwitchVertical02Icon'
SwitchVertical02Icon.defaultProps = iconDefaultProps

export type { SwitchVertical02IconProps }
export { SwitchVertical02Icon }
