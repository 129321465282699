import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Settings03IconProps = IconProps

const Settings03Icon = forwardRef<SVGSVGElement, Settings03IconProps>(
  (props: Settings03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.05 9H5.5a2.5 2.5 0 0 1 0-5h9.55m-6.1 16h9.55a2.5 2.5 0 0 0 0-5H8.95M3 17.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 0 0-7 0Zm18-11a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Settings03Icon.displayName = 'Settings03Icon'
Settings03Icon.defaultProps = iconDefaultProps

export type { Settings03IconProps }
export { Settings03Icon }
