import React from 'react'

type FormFieldContextValue = {
  descriptionID: string
  errorID: string
  inputID: string
  labelID: string
}

const FormFieldContext = React.createContext<FormFieldContextValue>({
  descriptionID: '',
  errorID: '',
  inputID: '',
  labelID: '',
})

FormFieldContext.displayName = 'FormFieldContext'

export { FormFieldContext }
export type { FormFieldContextValue }
