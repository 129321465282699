import React from 'react'

export function addValuesToSet<T>(
  originalSet: Set<T>,
  values: T | Array<T>,
): Set<T> {
  const newSet = new Set(originalSet)
  const valuesArray = Array.isArray(values) ? values : [values]

  valuesArray.forEach((value) => {
    newSet.add(value)
  })

  return newSet
}

export function addValuesToSetInState<T>(
  values: T | Array<T>,
): React.SetStateAction<Set<T>> {
  return (previousState) => addValuesToSet(previousState, values)
}

export function removeValuesFromSet<T>(
  originalSet: Set<T>,
  values: T | Array<T>,
): Set<T> {
  const newSet = new Set(originalSet)
  const valuesArray = Array.isArray(values) ? values : [values]

  valuesArray.forEach((value) => {
    newSet.delete(value)
  })

  return newSet
}

export function removeValuesFromSetInState<T>(
  values: T | Array<T>,
): React.SetStateAction<Set<T>> {
  return (previousState) => removeValuesFromSet(previousState, values)
}

export function setToArray<T>(set: Set<T>): Array<T> {
  return Array.from(set.values())
}

export function arrayToSet<T>(array: Array<T>): Set<T> {
  return new Set(array)
}
