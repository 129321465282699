import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileCheck03IconProps = IconProps

const FileCheck03Icon = forwardRef<SVGSVGElement, FileCheck03IconProps>(
  (props: FileCheck03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9 16l2 2 4.5-4.5M14 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 19.72 20 18.88 20 17.2V8l-6-6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileCheck03Icon.displayName = 'FileCheck03Icon'
FileCheck03Icon.defaultProps = iconDefaultProps

export type { FileCheck03IconProps }
export { FileCheck03Icon }
