import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Lightbulb01IconProps = IconProps

const Lightbulb01Icon = forwardRef<SVGSVGElement, Lightbulb01IconProps>(
  (props: Lightbulb01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 16.5V19c0 .932 0 1.398-.152 1.765a2 2 0 0 1-1.083 1.083C13.398 22 12.932 22 12 22c-.932 0-1.398 0-1.765-.152a2 2 0 0 1-1.083-1.083C9 20.398 9 19.932 9 19v-2.5m6 0c2.649-1.157 4.5-3.925 4.5-7a7.5 7.5 0 0 0-15 0c0 3.075 1.851 5.843 4.5 7m6 0H9"
        fill="none"
      ></path>
    </Icon>
  ),
)

Lightbulb01Icon.displayName = 'Lightbulb01Icon'
Lightbulb01Icon.defaultProps = iconDefaultProps

export type { Lightbulb01IconProps }
export { Lightbulb01Icon }
