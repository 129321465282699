import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Loading01IconProps = IconProps

const Loading01Icon = forwardRef<SVGSVGElement, Loading01IconProps>(
  (props: Loading01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2.25v2.5M12 18v4M5.75 12h-3.5m19 0h-1.5m-1.293 6.457-.707-.707m.914-12.334L17.25 6.83M4.922 19.078 7.75 16.25M5.129 5.209 7.25 7.33"
        fill="none"
      ></path>
    </Icon>
  ),
)

Loading01Icon.displayName = 'Loading01Icon'
Loading01Icon.defaultProps = iconDefaultProps

export type { Loading01IconProps }
export { Loading01Icon }
