import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Coins03IconProps = IconProps

const Coins03Icon = forwardRef<SVGSVGElement, Coins03IconProps>(
  (props: Coins03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.101 4A7 7 0 0 1 20 13.899M16 15a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Coins03Icon.displayName = 'Coins03Icon'
Coins03Icon.defaultProps = iconDefaultProps

export type { Coins03IconProps }
export { Coins03Icon }
