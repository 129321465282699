export function updateFocus(
  tableElement: HTMLTableElement,
  focusedTableCellElement: HTMLTableCellElement | null,
  nextDateString: string,
): boolean {
  const nextTableCellElement = tableElement.querySelector<HTMLTableCellElement>(
    `[data-value="${nextDateString}"]`,
  )

  if (!nextTableCellElement) {
    return false
  }

  if (focusedTableCellElement) {
    focusedTableCellElement.removeAttribute('data-is-focusable')
    // We cast here instead of using a generic because we know for a fact that all
    // table data cell elements contain a button element.
    ;(focusedTableCellElement.firstElementChild as HTMLButtonElement).tabIndex =
      -1
  }

  // We cast here instead of using a generic because we know for a fact that all
  // table data cell elements contain a button element.
  const nextButtonElement =
    nextTableCellElement.firstElementChild as HTMLButtonElement

  nextTableCellElement.setAttribute('data-is-focusable', '')
  nextButtonElement.tabIndex = 0
  nextButtonElement.focus()

  return true
}

export function getTimeFromDate(date: Date): string {
  return `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
}

export function getMinimumTimeFromSelectedDates(
  selectedDates: Array<Date>,
): string | undefined {
  return selectedDates.length ? getTimeFromDate(selectedDates[0]) : undefined
}

export function getMaximumTimeFromSelectedDates(
  selectedDates: Array<Date>,
): string | undefined {
  if (!selectedDates.length) {
    return undefined
  }

  if (selectedDates.length === 1) {
    const firstDate = new Date(selectedDates[0])
    firstDate.setMinutes(selectedDates[0].getMinutes() + 1)
    return getTimeFromDate(firstDate)
  }

  if (
    selectedDates.length > 1 &&
    getTimeFromDate(selectedDates[0]) ===
      getTimeFromDate(selectedDates.slice(-1)[0])
  ) {
    const lastDate = selectedDates.slice(-1)[0]
    lastDate.setMinutes(selectedDates[0].getMinutes() + 1)
    return getTimeFromDate(lastDate)
  }

  return getTimeFromDate(selectedDates.slice(-1)[0])
}
