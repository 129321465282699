import React from 'react'
import styled from 'styled-components'

import {
  Flex,
  foundation,
  SearchInput,
  SearchInputProps,
  Spacer,
  toTransitions,
} from '@vori/gourmet-components'

const Container = styled(Flex)(
  ({
    $noPadding,
    $noPaddingY,
    $noPaddingX,
  }: {
    $noPadding?: boolean
    $noPaddingY?: boolean
    $noPaddingX?: boolean
  }) => ({
    backgroundColor: '#FFFFFF',
    minHeight: 44,
    padding: $noPadding
      ? 0
      : `${$noPaddingY ? 0 : foundation.spacing.small} ${
          $noPaddingX ? 0 : foundation.spacing.medium
        }`,
    transition: toTransitions(['background-color'], 'ease'),
  }),
)

interface Props {
  noPadding?: boolean
  noPaddingY?: boolean
  noPaddingX?: boolean
  renderFilters?: () => React.ReactNode
  searchInputProps?: SearchInputProps
  withSearch?: boolean
}

function DataGridFilters({
  noPadding,
  noPaddingY,
  noPaddingX,
  renderFilters,
  searchInputProps,
  withSearch,
}: Props): JSX.Element {
  return (
    <Container
      $noPadding={noPadding}
      $noPaddingY={noPaddingY}
      $noPaddingX={noPaddingX}
      centerY
      fullWidth
      justifyContent="space-between"
    >
      <Flex centerY fullWidth>
        {renderFilters && renderFilters()}
      </Flex>
      <Spacer />
      <Flex size="small" shrink={0}>
        {withSearch && <SearchInput fullWidth {...searchInputProps} />}
      </Flex>
    </Container>
  )
}

export { DataGridFilters }
export type { Props as DataGridFiltersProps }
