import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Edit01IconProps = IconProps

const Edit01Icon = forwardRef<SVGSVGElement, Edit01IconProps>(
  (props: Edit01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.876 18.116c.046-.414.069-.62.131-.814a2 2 0 0 1 .234-.485c.111-.17.259-.317.553-.61L17 3a2.828 2.828 0 1 1 4 4L7.794 20.206c-.294.294-.442.442-.611.553a2 2 0 0 1-.485.233c-.193.063-.4.086-.814.132L2.5 21.5l.376-3.384Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Edit01Icon.displayName = 'Edit01Icon'
Edit01Icon.defaultProps = iconDefaultProps

export type { Edit01IconProps }
export { Edit01Icon }
