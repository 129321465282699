import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Flex from '../Flex'

import { base, Space } from '../tokens'

type SpacerProps = React.HTMLAttributes<HTMLDivElement> & {
  inline?: boolean
  size?: Space
}

const defaultProps: Partial<SpacerProps> = {
  className: '',
  inline: false,
  size: 'base',
}

/**
 * @deprecated Use `<SpacerNext>` and `<SpacerInline>` instead
 */
const StyledSpacer = styled(
  forwardRef<HTMLDivElement, SpacerProps>(function Spacer(
    { inline, size, ...props }: SpacerProps,
    ref,
  ) {
    return (
      <Flex
        shrink={0}
        {...props}
        data-gourmet-spacer
        alignItems={inline ? 'stretch' : undefined}
        aria-hidden="true"
        inline={inline}
        ref={ref}
      />
    )
  }),
)<SpacerProps>`
  ${({ inline, size }): string =>
    inline ? '' : `height: ${base.spacing[size as Space]};`}
  pointer-events: none;
  width: ${({ size }): string => base.spacing[size as Space]};
`

StyledSpacer.displayName = 'Spacer'
StyledSpacer.defaultProps = defaultProps

export type { SpacerProps }
export { defaultProps as SpacerDefaultProps }
export default StyledSpacer
