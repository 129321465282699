import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SpacingWidth01IconProps = IconProps

const SpacingWidth01Icon = forwardRef<SVGSVGElement, SpacingWidth01IconProps>(
  (props: SpacingWidth01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 12h12M6 12l2-3m-2 3 2 3m10-3-2-3m2 3-2 3m5 6V3M3 21V3"
        fill="none"
      ></path>
    </Icon>
  ),
)

SpacingWidth01Icon.displayName = 'SpacingWidth01Icon'
SpacingWidth01Icon.defaultProps = iconDefaultProps

export type { SpacingWidth01IconProps }
export { SpacingWidth01Icon }
