import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Coins01IconProps = IconProps

const Coins01Icon = forwardRef<SVGSVGElement, Coins01IconProps>(
  (props: Coins01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.938 15.938A7.001 7.001 0 0 0 15 2a7.001 7.001 0 0 0-6.938 6.062M16 15a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Coins01Icon.displayName = 'Coins01Icon'
Coins01Icon.defaultProps = iconDefaultProps

export type { Coins01IconProps }
export { Coins01Icon }
