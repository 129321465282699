import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignJustifyIconProps = IconProps

const AlignJustifyIcon = forwardRef<SVGSVGElement, AlignJustifyIconProps>(
  (props: AlignJustifyIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 10H3m18 8H3M21 6H3m18 8H3"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignJustifyIcon.displayName = 'AlignJustifyIcon'
AlignJustifyIcon.defaultProps = iconDefaultProps

export type { AlignJustifyIconProps }
export { AlignJustifyIcon }
