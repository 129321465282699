import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Sliders04IconProps = IconProps

const Sliders04Icon = forwardRef<SVGSVGElement, Sliders04IconProps>(
  (props: Sliders04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm-6 8h12M9 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Sliders04Icon.displayName = 'Sliders04Icon'
Sliders04Icon.defaultProps = iconDefaultProps

export type { Sliders04IconProps }
export { Sliders04Icon }
