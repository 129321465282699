import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Upload03IconProps = IconProps

const Upload03Icon = forwardRef<SVGSVGElement, Upload03IconProps>(
  (props: Upload03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 12-4-4m0 0-4 4m4-4v8m10-4c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Upload03Icon.displayName = 'Upload03Icon'
Upload03Icon.defaultProps = iconDefaultProps

export type { Upload03IconProps }
export { Upload03Icon }
