import { setContext } from '@apollo/client/link/context'
import { buildSentryErrorLink } from 'apollo-sentry-helper'
import { createUploadLink, UploadLinkOptions } from 'apollo-upload-client'

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'

import { getHTTPHeaders } from '@vori/dashboard-api/getHTTPHeaders'

import {
  ENV_GRAPHQL_ENDPOINT,
  ENV_PROD,
  ENV_VERSION,
} from '@vori/dashboard-env'

import fragmentMatcher from './generated/fragment-matcher'
import typeDefs from './mocks/typeDefs'
import typePolicies from './typePolicies'

if (!ENV_GRAPHQL_ENDPOINT) {
  throw new Error('Missing GraphQL endpoint config')
}

export function getApolloClientLink(
  uploadLinkOptions?: UploadLinkOptions,
): ApolloLink {
  const authMiddleware = setContext(async (_, { headers }) => {
    const authHeader = await getHTTPHeaders()

    return {
      headers: {
        ...headers,
        ...(authHeader && { ...authHeader }),
        // NOTE: The preflight header is needed work with CSRF prevention on the backend.
        // See https://www.apollographql.com/docs/apollo-server/security/cors/#graphql-upload for more info.
        'Apollo-Require-Preflight': true,
      },
    }
  })

  const sentryErrorLink = buildSentryErrorLink() as unknown as ApolloLink

  const httpLink = createUploadLink({
    uri: ENV_GRAPHQL_ENDPOINT,
    ...uploadLinkOptions,
  }) as unknown as ApolloLink

  const namedLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      uri: `${ENV_GRAPHQL_ENDPOINT}?${operation.operationName}`,
    }))
    return forward ? forward(operation) : null
  })

  return ApolloLink.from(
    ENV_PROD
      ? [authMiddleware, sentryErrorLink, namedLink, httpLink]
      : [authMiddleware, namedLink, httpLink],
  )
}

export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache({
    ...fragmentMatcher,
    typePolicies,
  })

  const client = new ApolloClient({
    assumeImmutableResults: true,
    cache,
    link: getApolloClientLink(),
    name: 'dash-web',
    typeDefs,
    uri: ENV_GRAPHQL_ENDPOINT,
    version: ENV_VERSION,
  })

  return client
}
