import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowRightIconProps = IconProps

const ArrowRightIcon = forwardRef<SVGSVGElement, ArrowRightIconProps>(
  (props: ArrowRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12h14m0 0-7-7m7 7-7 7"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowRightIcon.displayName = 'ArrowRightIcon'
ArrowRightIcon.defaultProps = iconDefaultProps

export type { ArrowRightIconProps }
export { ArrowRightIcon }
