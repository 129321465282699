import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AnchorIconProps = IconProps

const AnchorIcon = forwardRef<SVGSVGElement, AnchorIconProps>(
  (props: AnchorIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 0v14m0 0A10 10 0 0 1 2 12h3m7 10a10 10 0 0 0 10-10h-3"
        fill="none"
      ></path>
    </Icon>
  ),
)

AnchorIcon.displayName = 'AnchorIcon'
AnchorIcon.defaultProps = iconDefaultProps

export type { AnchorIconProps }
export { AnchorIcon }
