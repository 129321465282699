import React, { forwardRef, useRef } from 'react'
import styled from 'styled-components'

import { assignRef, toTransitions } from '../utils'
import { colors, sizing, spacing, typography } from '../tokens'
import { InputColorVariant, InputDefaultProps, InputProps } from '../Input'

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  Pick<
    InputProps,
    'disableFocusRing' | 'fullWidth' | 'variant' | 'noPadding'
  > & {
    autoGrow?: boolean
  }

const defaultProps: Partial<TextareaProps> = {
  autoGrow: false,
  className: '',
  disableFocusRing: InputDefaultProps.disableFocusRing,
  fullWidth: InputDefaultProps.fullWidth,
  noPadding: InputDefaultProps.noPadding,
  variant: InputDefaultProps.variant,
}

/**
 * @deprecated Use `<TextareaNext>` instead
 */
const StyledTextarea = styled(
  forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
    { autoGrow, disableFocusRing, fullWidth, noPadding, variant, ...props },
    ref,
  ) {
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

    return (
      <textarea
        {...props}
        onChange={(event) => {
          if (props.onChange != null) {
            props.onChange(event)
          }

          if (textAreaRef.current != null && autoGrow) {
            textAreaRef.current.style.height = '1px'
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
          }
        }}
        ref={(element) => {
          if (element !== null) {
            assignRef(ref, element)
            assignRef(textAreaRef, element)
          }
        }}
      />
    )
  }),
)<TextareaProps>((props: TextareaProps) => ({
  ...(props.fullWidth && { width: '100%' }),
  backgroundColor: colors.input.backgroundColor,
  borderColor:
    colors.input.borderColor[
      (props.variant || defaultProps.variant) as InputColorVariant
    ],
  borderRadius: sizing.radius.base,
  borderStyle: 'solid',
  borderWidth: 1,
  color: colors.input.color,
  cursor: 'text',
  fontSize: typography.input.base.fontSize,
  fontWeight: typography.input.base.fontWeight,
  lineHeight: typography.input.base.lineHeight,
  margin: 0,
  minHeight: sizing.textarea,
  resize: 'none',
  padding: props.noPadding ? 0 : spacing.input.base,
  transition: toTransitions(['border-color', 'box-shadow', 'opacity'], 'ease'),

  ':active, :focus, :focus-within, &[data-state-active="true"], &[data-state-focus="true"]':
    {
      backgroundColor: colors.input.focus.backgroundColor,
      borderColor:
        colors.input.focus.borderColor[
          (props.variant || defaultProps.variant) as InputColorVariant
        ],
      color: colors.input.focus.color,
      outline: 'none',
      textDecoration: 'none',
    },

  ':focus, :focus-within, &[data-state-focus="true"]': {
    boxShadow: !props.disableFocusRing
      ? `0 0 0 ${sizing.focusRing} ${colors.input.focusRing}, 0 0 0 1px ${
          colors.input.focus.borderColor[
            (props.variant || defaultProps.variant) as InputColorVariant
          ]
        } inset`
      : 'none',
  },

  ':disabled, &[data-state-disabled="true"]': {
    opacity: 0.5,
    pointerEvents: 'none',
  },

  '::placeholder': {
    color: colors.input.placeholder,
  },
}))

StyledTextarea.displayName = 'Textarea'
StyledTextarea.defaultProps = defaultProps

export type { TextareaProps }
export { defaultProps as TextareaDefaultProps }
export default StyledTextarea
