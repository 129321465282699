import React from 'react'

import { TabValue } from './types'

type TabsContextValue = {
  focusedIndex: number
  id: string
  isSelectedValue: (value: TabValue) => boolean
  selectedValue: TabValue
  setFocusedIndex: (index: number) => void
  setSelectedValue: (value: TabValue) => void
  values: Array<TabValue>
}

const TabsContext = React.createContext<TabsContextValue | undefined>(undefined)

TabsContext.displayName = 'TabsContext'

export { TabsContext }
export type { TabsContextValue }
