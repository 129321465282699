import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Tv01IconProps = IconProps

const Tv01Icon = forwardRef<SVGSVGElement, Tv01IconProps>(
  (props: Tv01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 17v2.4c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C17.24 21 16.96 21 16.4 21H7.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C6 20.24 6 19.96 6 19.4V17m.8 0h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 14.72 22 13.88 22 12.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 3 18.88 3 17.2 3H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 5.28 2 6.12 2 7.8v4.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 17 5.12 17 6.8 17Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Tv01Icon.displayName = 'Tv01Icon'
Tv01Icon.defaultProps = iconDefaultProps

export type { Tv01IconProps }
export { Tv01Icon }
