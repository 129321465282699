import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Menu05IconProps = IconProps

const Menu05Icon = forwardRef<SVGSVGElement, Menu05IconProps>(
  (props: Menu05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 8.5h18m-18 7h18"
        fill="none"
      ></path>
    </Icon>
  ),
)

Menu05Icon.displayName = 'Menu05Icon'
Menu05Icon.defaultProps = iconDefaultProps

export type { Menu05IconProps }
export { Menu05Icon }
