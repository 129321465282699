import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Dotpoints02IconProps = IconProps

const Dotpoints02Icon = forwardRef<SVGSVGElement, Dotpoints02IconProps>(
  (props: Dotpoints02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 5H10m11 14H10m11-7H10M6 5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 14a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0-7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Dotpoints02Icon.displayName = 'Dotpoints02Icon'
Dotpoints02Icon.defaultProps = iconDefaultProps

export type { Dotpoints02IconProps }
export { Dotpoints02Icon }
