import React, { Children, cloneElement, forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { sizing, spacing } from '../tokens'

import Spacer from '../Spacer'
import TimelineEntry, { TimelineEntryProps } from './TimelineEntry'

type TimelineProps = React.HTMLAttributes<HTMLOListElement> & {
  fullWidth?: boolean
}

const defaultProps: Partial<TimelineProps> = {
  className: '',
  fullWidth: false,
}

function styles(props: TimelineProps): CSSObject {
  return {
    ...(props.fullWidth && { width: '100%' }),
    listStyle: 'none',
    margin: 0,
    padding: 0,
    paddingLeft: `calc(${sizing.timeline.track} + ${spacing.timeline})`,
    position: 'relative',
    zIndex: 1,
  }
}

const StyledTimeline = styled(
  forwardRef<HTMLOListElement, TimelineProps>(function Timeline(
    { children, fullWidth, ...props }: TimelineProps,
    ref,
  ) {
    const entriesCount =
      Children.map(
        children,
        (child) =>
          child != null &&
          React.isValidElement(child) &&
          child.type === TimelineEntry,
      )?.filter((isEntry) => isEntry).length || 0

    return (
      <ol {...props} ref={ref}>
        {Children.map(children, (child, index) =>
          child != null && React.isValidElement<TimelineEntryProps>(child) ? (
            <>
              {cloneElement<TimelineEntryProps>(child, {
                isFirstEntry: index === 0,
                isLastEntry: index + 1 === entriesCount,
                withTrack: entriesCount > 1,
              })}
              <Spacer size="large" />
            </>
          ) : null,
        )}
      </ol>
    )
  }),
)(styles)

StyledTimeline.displayName = 'Timeline'
StyledTimeline.defaultProps = defaultProps

export type { TimelineProps }
export { defaultProps as TimelineDefaultProps }
export default StyledTimeline
