import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowNarrowLeftIconProps = IconProps

const ArrowNarrowLeftIcon = forwardRef<SVGSVGElement, ArrowNarrowLeftIconProps>(
  (props: ArrowNarrowLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 12H4m0 0 6 6m-6-6 6-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowNarrowLeftIcon.displayName = 'ArrowNarrowLeftIcon'
ArrowNarrowLeftIcon.defaultProps = iconDefaultProps

export type { ArrowNarrowLeftIconProps }
export { ArrowNarrowLeftIcon }
