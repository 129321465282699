import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FaceIdIconProps = IconProps

const FaceIdIcon = forwardRef<SVGSVGElement, FaceIdIconProps>(
  (props: FaceIdIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 3h-.2c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8V8m5 13h-.2c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 18.72 3 17.88 3 16.2V16m18-8v-.2c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 3 17.88 3 16.2 3H16m5 13v.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H16M7.5 8v1.5m9-1.5v1.5M11 12.6c.8 0 1.5-.7 1.5-1.5V8m2.7 7.2c-1.8 1.8-4.7 1.8-6.5 0"
        fill="none"
      ></path>
    </Icon>
  ),
)

FaceIdIcon.displayName = 'FaceIdIcon'
FaceIdIcon.defaultProps = iconDefaultProps

export type { FaceIdIconProps }
export { FaceIdIcon }
