import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Monitor05IconProps = IconProps

const Monitor05Icon = forwardRef<SVGSVGElement, Monitor05IconProps>(
  (props: Monitor05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.5 20H8m9-11h.01M8 6H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 7.52 2 8.08 2 9.2v3.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 16 4.08 16 5.2 16H8m7.2 4h3.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C22 18.48 22 17.92 22 16.8V7.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 4 19.92 4 18.8 4h-3.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C12 5.52 12 6.08 12 7.2v9.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C13.52 20 14.08 20 15.2 20Zm2.8-5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Monitor05Icon.displayName = 'Monitor05Icon'
Monitor05Icon.defaultProps = iconDefaultProps

export type { Monitor05IconProps }
export { Monitor05Icon }
