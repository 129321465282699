const UNITS: Array<[Intl.RelativeTimeFormatUnit, number]> = [
  ['second', 1],
  ['minute', 60],
  ['hour', 60 * 60],
  ['day', 24 * 60 * 60],
  ['month', 30 * 24 * 60 * 60],
  ['year', 12 * 30 * 24 * 60 * 60],
]

function getDeltaAndUnit(from: Date): {
  delta: number
  unit: Intl.RelativeTimeFormatUnit
} {
  const delta = Math.round((from.getTime() - Date.now()) / 1000)

  for (const [index, unit] of UNITS.entries()) {
    if (Math.abs(delta) < unit[1] && UNITS[index - 1]) {
      const [unit, value] = UNITS[index - 1]
      return { delta: Math.round(delta / value), unit }
    }
  }

  return {
    delta: Math.round(delta / (12 * 30 * 24 * 60 * 60)),
    unit: 'year',
  }
}

/**
 * @todo Move this and other date-related functions to a new
 * package, e.g. `@vori/utils-date`.
 */
export function toRelativeTime(date: string | number | Date): string {
  const from = !(date instanceof Date) ? new Date(date) : date

  if (Number.isNaN(from.getTime())) {
    return ''
  }

  const formatter = new Intl.RelativeTimeFormat('en', { style: 'long' })
  const { delta, unit } = getDeltaAndUnit(from)

  return delta === 0 && unit === 'second'
    ? 'Now'
    : formatter.format(delta, unit)
}
