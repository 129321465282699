import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowBlockUpIconProps = IconProps

const ArrowBlockUpIcon = forwardRef<SVGSVGElement, ArrowBlockUpIconProps>(
  (props: ArrowBlockUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.8 21c-.28 0-.42 0-.527-.055a.5.5 0 0 1-.218-.218C9 20.62 9 20.48 9 20.2V10H5l7-7 7 7h-4v10.2c0 .28 0 .42-.055.527a.5.5 0 0 1-.218.218C14.62 21 14.48 21 14.2 21H9.8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowBlockUpIcon.displayName = 'ArrowBlockUpIcon'
ArrowBlockUpIcon.defaultProps = iconDefaultProps

export type { ArrowBlockUpIconProps }
export { ArrowBlockUpIcon }
