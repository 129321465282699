import { foundations } from '@vori/gourmet-tokens'
import { MenuItem as ReachMenuItem } from '@reach/menu-button'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { InlineFlex } from '../FlexNext'
import { Text } from '../TextNext'

type Props = {
  /**
   * A boolean flag that determines if the menu item is currently disabled.
   */
  disabled?: boolean
  /**
   * The icon, if any, to be display to the left of the menu item's `label`.
   */
  icon?: React.ReactNode | null
  /**
   * The text used to describe the action and/or intent of the menu item.
   */
  label: string
  /**
   * Callback that fires when the menu item is selected either via keyboard
   * or mouse event.
   */
  onSelect: () => void
  /**
   * The content, if any, to be displayed to the right if the item menu's `label`.
   */
  rightContent?: React.ReactNode | null
}

type ComponentProps = Props & React.ButtonHTMLAttributes<HTMLButtonElement>

function styles(): CSSObject {
  return {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: foundations.color['color.gray-700'],
    cursor: 'pointer',
    padding: `${foundations.space['space.075']} ${foundations.space['space.100']}`,
    transition: 'all 350ms cubic-bezier(0.215, 0.61, 0.355, 1)',
    width: '100%',

    '&:not(:disabled, [aria-disabled="true"])': {
      '&:active, &:hover, &:focus, &[data-selected]': {
        backgroundColor: foundations.color['color.gray-50'],
      },
    },

    '&:disabled, &[aria-disabled="true"]': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  }
}

const StyledMenuItem = styled.button(styles)

const defaultProps: Partial<Props> = {
  disabled: false,
  icon: null,
  rightContent: null,
}

function MenuItemContent({
  icon,
  label,
  rightContent,
}: Omit<Props, 'onSelect'>): JSX.Element {
  return (
    <InlineFlex
      alignItems="center"
      data-gourmet-menu-item-content=""
      fullWidth
      justifyContent="space-between"
      columnGap="space.075"
    >
      <InlineFlex alignItems="center" fullWidth columnGap="space.075">
        {icon}
        <Text size="text-sm">{label}</Text>
      </InlineFlex>
      {rightContent}
    </InlineFlex>
  )
}

/**
 * The `<MenuItem>` component is used to handle menu selection, and must be
 * rendered within the `<MenuList>` component.
 *
 * @example
 * <Menu>
 *   <MenuButton>Menu</MenuButton>
 *   <MenuList>
 *     <MenuItem onSelect={onSelectFn} icon={<AlarmClockCheckIcon />} label="Menu Item 1" />
 *   </MenuList>
 * </Menu>
 */
const MenuItem = React.forwardRef<HTMLButtonElement, ComponentProps>(
  function MenuItem(
    { icon, label, onSelect, rightContent, ...props }: ComponentProps,
    ref,
  ): JSX.Element {
    return (
      <ReachMenuItem
        {...props}
        as={StyledMenuItem}
        data-gourmet-menu-item=""
        disabled={props.disabled}
        onSelect={onSelect}
        ref={ref}
      >
        <MenuItemContent
          icon={icon}
          label={label}
          rightContent={rightContent}
        />
      </ReachMenuItem>
    )
  },
)

MenuItem.displayName = 'MenuItem'
MenuItem.defaultProps = defaultProps

export {
  defaultProps as menuItemDefaultProps,
  MenuItem,
  MenuItemContent,
  styles as menuItemStyles,
}

export type { Props as MenuItemProps }
