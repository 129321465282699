import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LockUnlocked01IconProps = IconProps

const LockUnlocked01Icon = forwardRef<SVGSVGElement, LockUnlocked01IconProps>(
  (props: LockUnlocked01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 10V8a5 5 0 0 1 9.584-2M12 14.5v2M8.8 21h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 18.72 20 17.88 20 16.2v-1.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 10 16.88 10 15.2 10H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 12.28 4 13.12 4 14.8v1.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 21 7.12 21 8.8 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LockUnlocked01Icon.displayName = 'LockUnlocked01Icon'
LockUnlocked01Icon.defaultProps = iconDefaultProps

export type { LockUnlocked01IconProps }
export { LockUnlocked01Icon }
