import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LineChartUp01IconProps = IconProps

const LineChartUp01Icon = forwardRef<SVGSVGElement, LineChartUp01IconProps>(
  (props: LineChartUp01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 20.24 3 19.96 3 19.4V3m17 5-3.919 4.183c-.148.158-.223.237-.312.278a.5.5 0 0 1-.253.044c-.098-.01-.194-.06-.387-.16l-3.258-1.69c-.193-.1-.289-.15-.387-.16a.5.5 0 0 0-.253.044c-.09.04-.164.12-.312.278L7 15"
        fill="none"
      ></path>
    </Icon>
  ),
)

LineChartUp01Icon.displayName = 'LineChartUp01Icon'
LineChartUp01Icon.defaultProps = iconDefaultProps

export type { LineChartUp01IconProps }
export { LineChartUp01Icon }
