import React from 'react'
import styled, { CSSObject } from 'styled-components'

import Flex from '../Flex'
import { SegmentedProgressBarSegment } from './SegmentedProgressBarSegment'

import { SegmentedProgressBarTokens } from './tokens'

function styles(props: { $value: NonNullable<Props['value']> }): CSSObject {
  return {
    [`[data-gourmet-segmented-progressbar-segment]:nth-child(-n+${props.$value})::before`]:
      {
        backgroundColor:
          SegmentedProgressBarTokens.colors.segmentedProgressBarSegment
            .completed.backgroundColor,
      },

    [`[data-gourmet-segmented-progressbar-segment]:nth-child(${props.$value}) [data-gourmet-segmented-progressbar-segment-label]`]:
      {
        ...SegmentedProgressBarTokens.typography
          .segmentedProgressBarSegmentLabel.current,
      },
  }
}

const FlexContainer = styled(Flex)(styles)

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Used to denote the current value in readable form for assistive
   * technologies.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-valuetext}
   *
   * @todo Make this optional and derive this label from the currently active
   * `<SegmentedProgressBarSegmentLabel>`, if any.
   */
  ariaLabel: string
  /**
   * Represents the current segment in progress, if any. Defaults to `0`.
   */
  value?: number
}

/**
 * A segmented progress bar used to update users on their progress through
 * a multi-step process.
 *
 * @example
 * <SegmentedProgressBar ariaLabel="This invoice is being processed" value={1}>
 *  <SegmentedProgressBarSegment>
 *    <SegmentedProgressBarSegmentLabel>
 *      Processing
 *    </SegmentedProgressBarSegmentLabel>
 *  </SegmentedProgressBarSegment>
 *  <SegmentedProgressBarSegment>
 *    <SegmentedProgressBarSegmentLabel>
 *      Ready for Receiving
 *    </SegmentedProgressBarSegmentLabel>
 *    <Text size="body" variant="negative">
 *      2 items to action
 *    </Text>
 *  </SegmentedProgressBarSegment>
 *  <SegmentedProgressBarSegment>
 *    <SegmentedProgressBarSegmentLabel>
 *      Received
 *    </SegmentedProgressBarSegmentLabel>
 *  </SegmentedProgressBarSegment>
 *  <SegmentedProgressBarSegment>
 *    <SegmentedProgressBarSegmentLabel>
 *      Approved
 *    </SegmentedProgressBarSegmentLabel>
 *  </SegmentedProgressBarSegment>
 *</SegmentedProgressBar>
 *
 * @see {@link https://w3c.github.io/aria/#progressbar}
 */
const SegmentedProgressBar = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function SegmentedProgressBar(
  { children, ariaLabel, value = 0, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  // Throws an error if the immediate `children` are not
  // `<SegmentedProgressBarSegment>` components.
  if (
    process.env.NODE_ENV !== 'production' &&
    React.Children.toArray(children).some(
      (child) =>
        !React.isValidElement(child) ||
        (React.isValidElement(child) &&
          child.type !== SegmentedProgressBarSegment),
    )
  ) {
    console.warn(
      'A <SegmentedProgressBar> component should only contain a <SegmentedProgressBarSegment> components.',
    )
  }

  const segmentCount = React.Children.count(children)
  const safeValue = value > segmentCount ? segmentCount : value

  return (
    <FlexContainer
      {...props}
      $value={safeValue}
      aria-label={ariaLabel}
      aria-valuemax={React.Children.count(children)}
      aria-valuemin={0}
      aria-valuenow={safeValue}
      aria-valuetext={ariaLabel}
      data-gourmet-segmented-progressbar
      fullWidth
      ref={ref}
      role="progressbar"
    >
      {children}
    </FlexContainer>
  )
})

SegmentedProgressBar.displayName = 'SegmentedProgressBar'
SegmentedProgressBar.defaultProps = {}

export { SegmentedProgressBar, styles as SegmentedProgressBarStyles }
export type { Props as SegmentedProgressBarProps }
