import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { colors } from '../tokens'
import Flex from '../Flex'
import Loader from '../Loader'
import Logo from '../Logo'
import Spacer from '../Spacer'

const Container = styled(Flex)`
  background-color: ${colors.splashScreen.backgroundColor};
  color: ${colors.splashScreen.color};
  height: 100vh;
  width: 100vw;
`

type SplashScreenProps = React.HTMLAttributes<HTMLDivElement>

const defaultProps: Partial<SplashScreenProps> = {
  className: '',
}

const StyledSplashScreen = styled(
  forwardRef(function SplashScreen(
    { className }: SplashScreenProps,
    ref: React.Ref<HTMLDivElement>,
  ) {
    return (
      <Container center className={className} column ref={ref}>
        <Logo size="base" variant="secondary" />
        <Spacer />
        <Loader size="medium" variant="secondary" />
      </Container>
    )
  }),
)``

StyledSplashScreen.displayName = 'SplashScreen'
StyledSplashScreen.defaultProps = defaultProps

export type { SplashScreenProps }
export { defaultProps as SplashScreenDefaultProps }
export default StyledSplashScreen
