import { sizing } from '../tokens'
import { toRem } from '../utils'

export const TimelineTokens = {
  colors: {
    timelineEntryIcon: {
      color: '#FFFFFF',
      backgroundColor: '#26272B',
    },

    timelineEntryTrack: {
      backgroundColor: '#E4E4E7',
    },

    timelineEntryTitle: {
      color: '#0D0C0F',
    },

    timelineEntryTitleDate: {
      color: '#51525C',
    },

    TimelineEntryText: {
      color: '#51525C',
    },

    TimelineEntryTextLink: {
      color: '#5235D0',
    },
  },

  typography: {
    timelineEntryTitle: {
      fontSize: toRem(14),
      fontWeight: 500,
      lineHeight: toRem(17),
    },

    timelineEntryTitleDate: {
      fontSize: toRem(12),
      fontWeight: 400,
      lineHeight: toRem(15),
    },

    TimelineEntryText: {
      fontSize: toRem(14),
      fontWeight: 400,
      lineHeight: toRem(17),
    },

    TimelineEntryTextLink: {
      fontSize: toRem(14),
      fontWeight: 500,
      lineHeight: toRem(17),
    },
  },

  spacing: {
    timelineEntryTrack: {
      top: toRem(4),
    },

    timelineEntryContent: {
      paddingTop: toRem(4),
    },

    TimelineEntryText: {
      marginTop: toRem(8),
    },

    timelineEntryCard: {
      paddingX: toRem(16),
      paddingY: toRem(8),
    },
  },

  sizing: {
    timelineEntryIcon: {
      borderRadius: sizing.radius.rounded,
      minHeight: toRem(32),
      minWidth: toRem(32),
    },

    timelineEntryTrack: {
      borderRadius: sizing.radius.pill,
      width: toRem(2),
    },

    timelineEntryContent: {
      minHeight: toRem(68),
    },

    timelineEntryCard: {
      borderRadius: toRem(8),
    },
  },
} as const
