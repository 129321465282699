import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Receipt01IconProps = IconProps

const Receipt01Icon = forwardRef<SVGSVGElement, Receipt01IconProps>(
  (props: Receipt01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 7.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C6.28 3 7.12 3 8.8 3h6.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C20 5.28 20 6.12 20 7.8V21l-2.75-2-2.5 2L12 19l-2.75 2-2.5-2L4 21V7.8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Receipt01Icon.displayName = 'Receipt01Icon'
Receipt01Icon.defaultProps = iconDefaultProps

export type { Receipt01IconProps }
export { Receipt01Icon }
