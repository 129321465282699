import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowBlockDownIconProps = IconProps

const ArrowBlockDownIcon = forwardRef<SVGSVGElement, ArrowBlockDownIconProps>(
  (props: ArrowBlockDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 3.8c0-.28 0-.42.055-.527a.5.5 0 0 1 .218-.219C9.38 3 9.52 3 9.8 3h4.4c.28 0 .42 0 .527.054a.5.5 0 0 1 .218.219C15 3.38 15 3.52 15 3.8V14h4l-7 7-7-7h4V3.8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowBlockDownIcon.displayName = 'ArrowBlockDownIcon'
ArrowBlockDownIcon.defaultProps = iconDefaultProps

export type { ArrowBlockDownIconProps }
export { ArrowBlockDownIcon }
