import { DotIndicatorProps } from '../DotIndicator'
import { FlexProps } from '../Flex'

export type TagSizing = 'small' | 'medium' | 'large'

export const sizing: ReadonlyArray<TagSizing> = [
  'small',
  'medium',
  'large',
] as const

export type TagCoreProps = {
  /**
   * Provides a method for prefacing the visible content of the `<Tag>`
   * component with text that is not displayed when a screen reader reads the
   * content.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/status_role#aria-label}
   *
   * @deprecated Use `aria-label` or `aria-labelledby` for interactive `<Tag>`
   * components and `aria-description` or `aria-describedby` for non-interactive `<Tag>`.
   */
  label?: string
  /**
   * Makes the `<Tag>` component **interactive** by adding a checkbox to the
   * left of the tag's content. This checkbox can be fully controlled via
   * the `isChecked` prop. You can also handle the checkbox `onChange` event
   * via the `onTagChecked` callback function prop.
   *
   * @see isChecked
   * @see onTagChecked
   */
  asCheckable?: boolean
  /**
   * Makes the `<Tag>` component **interactive** by enabling users to click on
   * the tag to initiate an action. This click event can be handled via
   * the `onTagClick` callback function prop.
   *
   * @see onTagClick
   */
  asClickable?: boolean
  /**
   * Makes the `<Tag>` component **interactive** by enabling users to click on
   * a remove button rendered to the right of the tag's content. This click
   * event can be handled via the `onTagRemove` callback function prop.
   *
   * @see onTagRemove
   */
  asRemovable?: boolean
  /**
   * Makes the `<Tag>` component **interactive** by enabling users to click on
   * the tag to select it. Once the tag is selected, a remove button will be
   * rendered to the right of the tag's content so users can unselect the tag.
   * These click events can be handled via the `onTagClick` and `onTagRemove`
   * callback function props.
   *
   * @see onTagClick
   * @see onTagRemove
   */
  asSelectable?: boolean
  /**
   * Used to render a count badge to the right of the tag's content.
   */
  counter?: number
  /**
   * Used to control the checkbox input rendered when using the `asCheckable`
   * prop.
   *
   * @see asCheckable
   */
  isChecked?: boolean
  /**
   * Used to control the checkbox input rendered when using the `asSelectable`
   * prop.
   *
   * @see asSelectable
   */
  isSelected?: boolean
  /**
   * Will render a `<DotIndicator>` component to the left of the tag's content.
   * Use a `boolean` to render the `<DotIndicator>` component with its default
   * props, or pass an object containing props specific to the `<DotIndicator>`
   * component.
   *
   * @see {@link https://vori-gourmet-1bdf0.web.app/?path=/docs/dotindicator--default}
   */
  leftDotIndicator?: boolean | DotIndicatorProps
  /**
   * Will render an icon component to the left of the tag's content.
   */
  leftIcon?: React.ReactElement | null
  /**
   * Callback function used to hook into the checkbox `onChange` event.
   *
   * @see asCheckable
   */
  onTagChecked?: React.ChangeEventHandler<HTMLInputElement>
  /**
   * Callback function used to hook into the `onClick` event fired when clicking
   * on a `<Tag>` component with the `asClickable` prop.
   *
   * @see asClickable
   */
  onTagClick?: React.MouseEventHandler<HTMLButtonElement>
  /**
   * Callback function used to hook into the remove button's `onClick` event.
   *
   * @see asRemovable
   */
  onTagRemove?: React.MouseEventHandler<HTMLButtonElement>
  /**
   * Callback function used to hook into the `onClick` event of both the tag and
   * the remove button, depending on the selected state of the tag.
   *
   * @see asSelectable
   */
  onTagSelect?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isSelected: boolean,
  ) => void
  /**
   * Will render a `<DotIndicator>` component to the right of the tag's content.
   * Use a `boolean` to render the `<DotIndicator>` component with its default
   * props, or pass an object containing props specific to the `<DotIndicator>`
   * component.
   *
   * @see {@link https://vori-gourmet-1bdf0.web.app/?path=/docs/dotindicator--default}
   */
  rightDotIndicator?: boolean | DotIndicatorProps
  /**
   * Will render an icon component to the right of the tag's content.
   */
  rightIcon?: React.ReactElement | null
  /**
   * Props to be passed to the inner `<Flex>` component. Be careful though,
   * as this might affect the layout of this component; Use it as a last resort.
   *
   * @see {@link https://vori-gourmet-1bdf0.web.app/?path=/docs/layout-flex--default}
   */
  flexProps?: FlexProps
  /**
   * Specifies the size of the `<Tag>` component, which is `'medium'` by
   * default.
   *
   * @default "medium"
   */
  sizing?: TagSizing
}

export type TagProps = TagCoreProps & React.HTMLAttributes<HTMLSpanElement>
