import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Fingerprint04IconProps = IconProps

const Fingerprint04Icon = forwardRef<SVGSVGElement, Fingerprint04IconProps>(
  (props: Fingerprint04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m5.807 18.53.054-.089A13.916 13.916 0 0 0 8 11a4 4 0 1 1 8 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0 0 15.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 0 0 8 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4M12 11c0 3.517-1.009 6.799-2.753 9.571"
        fill="none"
      ></path>
    </Icon>
  ),
)

Fingerprint04Icon.displayName = 'Fingerprint04Icon'
Fingerprint04Icon.defaultProps = iconDefaultProps

export type { Fingerprint04IconProps }
export { Fingerprint04Icon }
