import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FaceNeutralIconProps = IconProps

const FaceNeutralIcon = forwardRef<SVGSVGElement, FaceNeutralIconProps>(
  (props: FaceNeutralIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 15h8m-1-6h.01M9 9h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-6.5-3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-6 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FaceNeutralIcon.displayName = 'FaceNeutralIcon'
FaceNeutralIcon.defaultProps = iconDefaultProps

export type { FaceNeutralIconProps }
export { FaceNeutralIcon }
