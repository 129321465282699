import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CreditCardRefreshIconProps = IconProps

const CreditCardRefreshIcon = forwardRef<
  SVGSVGElement,
  CreditCardRefreshIconProps
>((props: CreditCardRefreshIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 10H2m9 9h7.8c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C22 17.48 22 16.92 22 15.8V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 5 19.92 5 18.8 5H17m-6 14 2 2m-2-2 2-2m-6 2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 17.48 2 16.92 2 15.8V8.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 5 4.08 5 5.2 5H13m0 0-2 2m2-2-2-2"
      fill="none"
    ></path>
  </Icon>
))

CreditCardRefreshIcon.displayName = 'CreditCardRefreshIcon'
CreditCardRefreshIcon.defaultProps = iconDefaultProps

export type { CreditCardRefreshIconProps }
export { CreditCardRefreshIcon }
