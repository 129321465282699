import React from 'react'
import { AlertCircleIcon } from '@vori/gourmet-icons'
import { CSSObject } from 'styled-components'
import { renderToString } from 'react-dom/server'

import { toRem } from '../utils'
import { TextAreaProps } from './TextArea'

export function svgIconToURL(
  icon: React.ReactElement | null,
  invalid?: boolean,
): string {
  return icon
    ? `url(data:image/svg+xml;utf8,${encodeURI(renderToString(icon))
        .replace('currentColor', invalid ? '#F0374A' : '#A0A0AB')
        .replace('#', '%23')})`
    : ''
}

export function getBackgroundCSSProperties(
  props: Partial<TextAreaProps>,
): CSSObject {
  const isInvalid = props['aria-invalid'] === 'true'
  const defaultPosition = !props.noPadding ? toRem(12) : 0

  return {
    backgroundImage: [
      props.leftIcon && svgIconToURL(props.leftIcon || null, isInvalid),
      (props.rightIcon || isInvalid) &&
        svgIconToURL(
          !isInvalid ? props.rightIcon || null : <AlertCircleIcon />,
          isInvalid,
        ),
    ]
      .filter(Boolean)
      .join(','),
    backgroundPosition: [
      props.leftIcon && `${defaultPosition} ${defaultPosition}`,
      (props.rightIcon || isInvalid) &&
        `${
          !props.noPadding
            ? `calc(100% - ${defaultPosition}) ${defaultPosition}`
            : '100% top'
        }`,
    ]
      .filter(Boolean)
      .join(','),
  }
}
