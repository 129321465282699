import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GridDotsHorizontalCenterIconProps = IconProps

const GridDotsHorizontalCenterIcon = forwardRef<
  SVGSVGElement,
  GridDotsHorizontalCenterIconProps
>((props: GridDotsHorizontalCenterIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 3h.01M3 12h.01M3 21h.01M3 16.5h.01M3 7.5h.01M7.5 3h.01m-.01 9h.01m-.01 9h.01M16.5 3h.01m-.01 9h.01m-.01 9h.01M21 3h.01M21 12h.01M21 21h.01M21 16.5h.01m-.01-9h.01M12 21V3"
      fill="none"
    ></path>
  </Icon>
))

GridDotsHorizontalCenterIcon.displayName = 'GridDotsHorizontalCenterIcon'
GridDotsHorizontalCenterIcon.defaultProps = iconDefaultProps

export type { GridDotsHorizontalCenterIconProps }
export { GridDotsHorizontalCenterIcon }
