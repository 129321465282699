import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type HorizontalBarChart02IconProps = IconProps

const HorizontalBarChart02Icon = forwardRef<
  SVGSVGElement,
  HorizontalBarChart02IconProps
>((props: HorizontalBarChart02IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 9.5v3.4c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437c-.214.109-.494.109-1.054.109H3m10 0v3.4c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437c-.214.109-.494.109-1.054.109H3M3 2v20M3 9.5h16.4c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C21 8.74 21 8.46 21 7.9V6.1c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C20.24 4.5 19.96 4.5 19.4 4.5H3v5Z"
      fill="none"
    ></path>
  </Icon>
))

HorizontalBarChart02Icon.displayName = 'HorizontalBarChart02Icon'
HorizontalBarChart02Icon.defaultProps = iconDefaultProps

export type { HorizontalBarChart02IconProps }
export { HorizontalBarChart02Icon }
