import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BellMinusIconProps = IconProps

const BellMinusIcon = forwardRef<SVGSVGElement, BellMinusIconProps>(
  (props: BellMinusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.354 21c.705.622 1.632 1 2.646 1s1.94-.378 2.646-1M15 5h6m-8-2.916A6 6 0 0 0 6 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.089 1.936.015.182.054.252.2.36.133.099.732.099 1.928.099H18.61c1.197 0 1.795 0 1.927-.098.147-.11.186-.179.2-.361.014-.165-.353-.755-1.087-1.935-.774-1.243-1.476-3.052-1.623-5.606"
        fill="none"
      ></path>
    </Icon>
  ),
)

BellMinusIcon.displayName = 'BellMinusIcon'
BellMinusIcon.defaultProps = iconDefaultProps

export type { BellMinusIconProps }
export { BellMinusIcon }
