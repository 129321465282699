import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCw04IconProps = IconProps

const RefreshCw04Icon = forwardRef<SVGSVGElement, RefreshCw04IconProps>(
  (props: RefreshCw04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 5.125A8.5 8.5 0 0 1 12 20.5h-.5M7 18.875A8.5 8.5 0 0 1 12 3.5h.5m.5 18.9-2-2 2-2M11 5.6l2-2-2-2"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCw04Icon.displayName = 'RefreshCw04Icon'
RefreshCw04Icon.defaultProps = iconDefaultProps

export type { RefreshCw04IconProps }
export { RefreshCw04Icon }
