import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserCheck01IconProps = IconProps

const UserCheck01Icon = forwardRef<SVGSVGElement, UserCheck01IconProps>(
  (props: UserCheck01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21m14-3 2 2 4-4m-7.5-8.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserCheck01Icon.displayName = 'UserCheck01Icon'
UserCheck01Icon.defaultProps = iconDefaultProps

export type { UserCheck01IconProps }
export { UserCheck01Icon }
