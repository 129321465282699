import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Crop01IconProps = IconProps

const Crop01Icon = forwardRef<SVGSVGElement, Crop01IconProps>(
  (props: Crop01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 6h12.8c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C18 7.52 18 8.08 18 9.2V22m4-4H9.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C6 16.48 6 15.92 6 14.8V2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Crop01Icon.displayName = 'Crop01Icon'
Crop01Icon.defaultProps = iconDefaultProps

export type { Crop01IconProps }
export { Crop01Icon }
