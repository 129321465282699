import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CoinsStacked04IconProps = IconProps

const CoinsStacked04Icon = forwardRef<SVGSVGElement, CoinsStacked04IconProps>(
  (props: CoinsStacked04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 5c0 1.657-3.582 3-8 3S4 6.657 4 5m16 0c0-1.657-3.582-3-8-3S4 3.343 4 5m16 0v14c0 1.657-3.582 3-8 3s-8-1.343-8-3V5m16 4.667c0 1.656-3.582 3-8 3s-8-1.344-8-3m16 4.663c0 1.657-3.582 3-8 3s-8-1.343-8-3"
        fill="none"
      ></path>
    </Icon>
  ),
)

CoinsStacked04Icon.displayName = 'CoinsStacked04Icon'
CoinsStacked04Icon.defaultProps = iconDefaultProps

export type { CoinsStacked04IconProps }
export { CoinsStacked04Icon }
