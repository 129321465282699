import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCcw02IconProps = IconProps

const RefreshCcw02Icon = forwardRef<SVGSVGElement, RefreshCcw02IconProps>(
  (props: RefreshCcw02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 10s.121-.85 3.636-4.364A9 9 0 0 1 20.776 10M2 10V4m0 6h6m14 4s-.121.85-3.636 4.364A9 9 0 0 1 3.224 14M22 14v6m0-6h-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCcw02Icon.displayName = 'RefreshCcw02Icon'
RefreshCcw02Icon.defaultProps = iconDefaultProps

export type { RefreshCcw02IconProps }
export { RefreshCcw02Icon }
