import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Sliders01IconProps = IconProps

const Sliders01Icon = forwardRef<SVGSVGElement, Sliders01IconProps>(
  (props: Sliders01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 21v-7m0-4V3m7 18v-9m0-4V3m7 18v-5m0-4V3M2 14h6m1-6h6m1 8h6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Sliders01Icon.displayName = 'Sliders01Icon'
Sliders01Icon.defaultProps = iconDefaultProps

export type { Sliders01IconProps }
export { Sliders01Icon }
