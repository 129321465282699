import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LogOut01IconProps = IconProps

const LogOut01Icon = forwardRef<SVGSVGElement, LogOut01IconProps>(
  (props: LogOut01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 17 5-5m0 0-5-5m5 5H9m0-9H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21H9"
        fill="none"
      ></path>
    </Icon>
  ),
)

LogOut01Icon.displayName = 'LogOut01Icon'
LogOut01Icon.defaultProps = iconDefaultProps

export type { LogOut01IconProps }
export { LogOut01Icon }
