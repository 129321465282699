import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TerminalCircleIconProps = IconProps

const TerminalCircleIcon = forwardRef<SVGSVGElement, TerminalCircleIconProps>(
  (props: TerminalCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m7 15 3-3-3-3m6 6h4m5-3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

TerminalCircleIcon.displayName = 'TerminalCircleIcon'
TerminalCircleIcon.defaultProps = iconDefaultProps

export type { TerminalCircleIconProps }
export { TerminalCircleIcon }
