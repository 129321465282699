import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Target03IconProps = IconProps

const Target03Icon = forwardRef<SVGSVGElement, Target03IconProps>(
  (props: Target03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12h-4M6 12H2m10-6V2m0 20v-4m8-6a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Target03Icon.displayName = 'Target03Icon'
Target03Icon.defaultProps = iconDefaultProps

export type { Target03IconProps }
export { Target03Icon }
