import React from 'react'
import styled from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'

import { Flex, FlexProps } from '../FlexNext'

const StyledFlex = styled(Flex)({
  padding: `${foundations.space['space.075']} ${foundations.space['space.100']}`,
})

/**
 * The `<MenuContent>` component is used to render content inside the `<MenuList>`
 * component. It is recommended that it's only used for rendering the `<MenuList>`
 * `header` and/or `footer` sections.
 *
 * @example
 * <Menu>
 *   <MenuButton
 *     aria-label="Menu"
 *     asClickable
 *     asIconButton
 *     leftIcon={<DotsVerticalIcon />}
 *   />
 *   <MenuList
 *     header={
 *       <MenuContent centerY fullWidth columnGap="space.075">
 *         <Avatar
 *           alt="Olivia Rhye's avatar image"
 *           dotIndicator={{ variant: 'positive' }}
 *           src="https://i.pravatar.cc"
 *         />
 *         <Flex direction="column">
 *           <Text>Olivia Rhye</Text>
 *           <Text variant="secondary">olivia@untitledui.com</Text>
 *         </Flex>
 *       </MenuContent>
 *     }
 *   >
 *     <MenuItem onSelect={onSelectFn} icon={<AlarmClockCheckIcon />} label="Menu Item 1" />
 *     <MenuItem onSelect={onSelectFn} icon={<Building02Icon />} label="Menu Item 2" />
 *     <MenuDivider />
 *     <MenuItem onSelect={onSelectFn} icon={<Dataflow04Icon />} label="Menu Item 3" />
 *     <MenuItem onSelect={onSelectFn} icon={<Shield02Icon />} label="Menu Item 4" />
 *     <MenuDivider />
 *     <MenuItem onSelect={onSelectFn} icon={<InfinityIcon />} label="Menu Item 5" />
 *     <MenuItem onSelect={onSelectFn} icon={<Webcam02Icon />} label="Menu Item 6" />
 *   </MenuList>
 * </Menu>
 */
const MenuContent = React.forwardRef<HTMLDivElement, FlexProps>(
  function MenuContent({ children, ...props }: FlexProps, ref): JSX.Element {
    return (
      <StyledFlex {...props} data-gourmet-menu-content="" ref={ref}>
        {children}
      </StyledFlex>
    )
  },
)

MenuContent.displayName = 'MenuContent'
MenuContent.defaultProps = { ...Flex.defaultProps }

export { MenuContent }
export type { FlexProps as MenuContentProps }
