import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CodeSnippet02IconProps = IconProps

const CodeSnippet02Icon = forwardRef<SVGSVGElement, CodeSnippet02IconProps>(
  (props: CodeSnippet02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m17 17 5-5-5-5M7 7l-5 5 5 5m7-14-4 18"
        fill="none"
      ></path>
    </Icon>
  ),
)

CodeSnippet02Icon.displayName = 'CodeSnippet02Icon'
CodeSnippet02Icon.defaultProps = iconDefaultProps

export type { CodeSnippet02IconProps }
export { CodeSnippet02Icon }
