import React, { forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { colors, spacing } from '../../tokens'

import Flex from '../../Flex'

type AppSidebarHeaderProps = React.HTMLAttributes<HTMLDivElement>

const defaultProps: Partial<AppSidebarHeaderProps> = {
  className: '',
}

const styles: CSSObject = {
  borderBottomColor: colors.appSidebarHeader.borderColor,
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  padding: spacing.appSidebarHeader,
}

const StyledAppSidebarHeader = styled(
  forwardRef<HTMLDivElement, AppSidebarHeaderProps>(function AppSidebarHeader(
    { children, ...props }: AppSidebarHeaderProps,
    ref,
  ) {
    return (
      <Flex {...props} center fullWidth ref={ref}>
        {children}
      </Flex>
    )
  }),
)<AppSidebarHeaderProps>(styles)

StyledAppSidebarHeader.displayName = 'AppSidebarHeader'
StyledAppSidebarHeader.defaultProps = defaultProps

export type { AppSidebarHeaderProps }

export {
  defaultProps as AppSidebarHeaderDefaultProps,
  styles as AppSidebarHeaderStyles,
}

export default StyledAppSidebarHeader
