import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CornerLeftUpIconProps = IconProps

const CornerLeftUpIcon = forwardRef<SVGSVGElement, CornerLeftUpIconProps>(
  (props: CornerLeftUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 20h-3.4c-3.36 0-5.04 0-6.324-.654a6 6 0 0 1-2.622-2.622C8 15.44 8 13.76 8 10.4V4m0 0 5 5M8 4 3 9"
        fill="none"
      ></path>
    </Icon>
  ),
)

CornerLeftUpIcon.displayName = 'CornerLeftUpIcon'
CornerLeftUpIcon.defaultProps = iconDefaultProps

export type { CornerLeftUpIconProps }
export { CornerLeftUpIcon }
