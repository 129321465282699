import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Sale01IconProps = IconProps

const Sale01Icon = forwardRef<SVGSVGElement, Sale01IconProps>(
  (props: Sale01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 9h.01M15 15h.01M16 8l-8 8m14-4c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM9.5 9a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm6 6a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Sale01Icon.displayName = 'Sale01Icon'
Sale01Icon.defaultProps = iconDefaultProps

export type { Sale01IconProps }
export { Sale01Icon }
