import { MenuItem as ReachMenuItem } from '@reach/menu-button'
import { NavLink, NavLinkProps } from 'react-router-dom'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { noop } from '../utils'

import {
  MenuItem,
  MenuItemContent,
  menuItemDefaultProps,
  MenuItemProps,
  menuItemStyles,
} from './MenuItem'

function styles(): CSSObject {
  return {
    ...menuItemStyles(),
    textDecoration: 'none',

    '&:hover, &:active': {
      textDecoration: 'none',
    },
  }
}

const StyledMenuLinkInternal = styled(NavLink)(styles)
const StyledMenuLinkExternal = styled.a(styles)

type Props = Omit<MenuItemProps, 'onSelect'> & {
  /**
   * Callback that fires when the menu item is selected either via keyboard
   * or mouse event.
   */
  onSelect?: MenuItemProps['onSelect']
}

type ComponentProps = Props &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'href' | 'aria-label' | 'aria-labelledby'
  > &
  (
    | { href: string; to?: undefined }
    | ({ href?: undefined } & Omit<
        NavLinkProps,
        'href' | 'aria-label' | 'aria-labelledby'
      >)
  )

const defaultProps: Partial<Props> = menuItemDefaultProps

/**
 * The `<MenuLink>` component is similar to the `<MenuItem>` component but it
 * renders an anchor element used to link to internal and external content.
 * It must also be rendered within the `<MenuList>` component.
 *
 * @example
 * <Menu>
 *   <MenuButton>Menu</MenuButton>
 *   <MenuList>
 *     <MenuLink
 *       href="https://help.vori.com/en/"
 *       icon={<LinkExternal01Icon />}
 *       label="Help Center (External)"
 *       target="_blank"
 *     />
 *     <MenuLink to="/1" icon={<Shield02Icon />} label="Internal Link" />
 *   </MenuList>
 * </Menu>
 */
const MenuLink = React.forwardRef<HTMLAnchorElement, ComponentProps>(
  function MenuLink(
    { disabled, icon, label, onSelect, rightContent, ...props }: ComponentProps,
    ref: React.Ref<HTMLAnchorElement>,
  ): JSX.Element {
    if (disabled) {
      return (
        <MenuItem
          data-gourmet-menu-link=""
          disabled={disabled}
          icon={icon}
          label={label}
          onSelect={noop}
          ref={ref as React.Ref<HTMLButtonElement>}
          rightContent={rightContent}
        />
      )
    }

    if (props.href != null || disabled) {
      return (
        <ReachMenuItem
          {...props}
          as={StyledMenuLinkExternal}
          data-gourmet-menu-link=""
          onSelect={onSelect || noop}
          ref={ref}
        >
          <MenuItemContent
            icon={icon}
            label={label}
            rightContent={rightContent}
          />
        </ReachMenuItem>
      )
    } else {
      return (
        <ReachMenuItem
          {...props}
          as={StyledMenuLinkInternal}
          data-gourmet-menu-link=""
          onSelect={onSelect || noop}
          ref={ref}
        >
          <MenuItemContent
            icon={icon}
            label={label}
            rightContent={rightContent}
          />
        </ReachMenuItem>
      )
    }
  },
)

MenuLink.displayName = 'MenuLink'
MenuLink.defaultProps = defaultProps

export { MenuLink, defaultProps as linkDefaultProps, styles as linkStyles }
export type { Props as MenuLinkProps }
