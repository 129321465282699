import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Film01IconProps = IconProps

const Film01Icon = forwardRef<SVGSVGElement, Film01IconProps>(
  (props: Film01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 12h20M2 7h5m10 0h5M2 17h5m10 0h5M7 22V2m10 20V2M6.8 22h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 19.72 22 18.88 22 17.2V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 2 18.88 2 17.2 2H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 4.28 2 5.12 2 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 22 5.12 22 6.8 22Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Film01Icon.displayName = 'Film01Icon'
Film01Icon.defaultProps = iconDefaultProps

export type { Film01IconProps }
export { Film01Icon }
