import { useCallback, useLayoutEffect, useRef, useState } from 'react'

const useAvailableHeight = (
  offset = 0,
): [
  React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  number,
  () => void,
] => {
  const prevOffset = useRef<number | null>(null)
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null)
  const [elementHeight, setElementHeight] = useState<number>(0)

  const calculateHeight = useCallback(() => {
    if (
      (elementHeight <= 0 ||
        (prevOffset.current !== null && prevOffset.current !== offset)) &&
      elementRef != null
    ) {
      prevOffset.current = offset

      setElementHeight(
        document.documentElement.clientHeight -
          elementRef.getBoundingClientRect().top -
          offset,
      )
    }
  }, [elementHeight, elementRef, offset])

  useLayoutEffect(() => {
    calculateHeight()
  }, [calculateHeight])

  return [setElementRef, elementHeight, calculateHeight]
}

export default useAvailableHeight
