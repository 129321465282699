import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { composeEventHandlers, composeRefs, toRem } from '../utils'
import { foundation } from '../tokens'
import { useControlledState } from '../hooks'

import { Flex, InlineFlex } from '../FlexNext'
import { Text } from '../TextNext'
import { Spacer } from '../SpacerNext'

import { VisuallyHiddenInput } from '../VisuallyHiddenInput'

import {
  RadioInputFieldContext,
  RadioInputFieldContextValue,
} from './RadioInputContext'

import { getNextElementToFocus } from './utils'
import { RADIO_INPUT_SELECTOR } from './constants'
import { RadioInputFieldValue } from './types'

function styles(props: { $fullWidth?: boolean; $inline?: boolean }): CSSObject {
  return {
    alignItems: 'flex-start',
    borderWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    margin: 0,

    legend: {
      borderWidth: 0,
      padding: 0,
    },

    ...(props.$fullWidth && { width: '100%' }),

    '&[data-as-section] [data-gourmet-radio-input-field-label]': {
      fontSize: toRem(24),
      fontWeight: 500,
      lineHeight: toRem(32),
    },

    '&:not([data-as-section]) [data-gourmet-radio-input-field-label]': {
      fontSize: toRem(14),
      fontWeight: 500,
      lineHeight: toRem(20),
    },

    '[data-gourmet-radio-input-field-description]': {
      fontSize: toRem(14),
      fontWeight: 400,
      lineHeight: toRem(20),
    },

    '[data-gourmet-radio-input]:not(:last-child)': {
      ...(props.$inline
        ? { marginRight: foundation.spacing.base }
        : { marginBottom: foundation.spacing.base }),
    },

    '[data-gourmet-radio-input-control-container]': {
      width: '100%',
    },

    ...(props.$inline && {
      '[data-gourmet-radio-input][data-variant="container"]': {
        flex: 1,
        width: '100%',
      },
    }),
  }
}

const RadioInputFieldContainer = styled.fieldset(styles)

type BaseProps = React.HTMLAttributes<HTMLFieldSetElement> & {
  /**
   * Use this prop when you want to display this field as a larger section
   * of a page, rather than a field within a form. I.e. this will make the title
   * larger by using a heading element (h3).
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend#technical_summary}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements}
   */
  asSection?: boolean
  /**
   * A value to treat as **currently** checked. Use this prop to define a
   * **controlled** <RadioInputField> component.
   *
   * @see {@link https://reactjs.org/docs/forms.html#controlled-components}
   */
  checkedValue?: string
  /**
   * Value to treat as **initially** checked in an **uncontrolled**
   * <RadioInputField> component.
   *
   * **Note:** Use this prop on `<RadioInputField>` instead of setting
   * `defaultChecked` on individual `<RadioInput>` components.
   */
  defaultCheckedValue?: string
  /**
   * An optional description for this form field, useful for giving users hints
   * or instruction on how to interact with it.
   *
   * When supplied, all `<RadioInput>` components contained within this
   * `<RadioInputField>` component will be updated to point to the HTML id
   * designated to the element used to render the description through the
   * `aria-describedby` attribute.
   *
   * **Note:** If an `error` prop is passed, it will override this `description`,
   * i.e. the error will be shown instead of the form field's provided
   * description.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
   */
  description?: React.ReactNode
  /**
   * An optional error message for this form field, useful for informing users
   * of errors related to this form field that might prevent them from
   * submitting a form or taking other actions.
   *
   * When supplied, all `<RadioInput>` components contained within this
   * `<RadioInputField>` component will be updated to point to the HTML id
   * designated to the element used to render the error through the
   * `aria-errormessage` attribute.
   *
   * **Note:** If you provide an error message through this `error` prop, the
   * associated `<RadioInput>` elements will be provided with a `aria-invalid`
   * attribute, which could trigger an error state if supported by the element.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-errormessage}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-invalid}
   */
  error?: React.ReactNode | null
  /**
   * If `true`, the element will take all the available horizontal space.
   */
  fullWidth?: boolean
  /**
   * If `true`, the `<RadioInput>` elements contained within this
   * `<RadioInputField>` component will be rendered inlined.
   */
  inline?: boolean
  /**
   * A callback function fired when the `<RadioInputField>` component's state
   * changes, i.e. a new radio input gets checked.
   */
  onChangeValue?: (value: RadioInputFieldValue) => void
}

type Props =
  | (BaseProps & { label: React.ReactNode; labelID?: string })
  | (BaseProps & { label?: React.ReactNode; labelID: string })

/**
 * A radio group is a set of checkable buttons, known as radio buttons,
 * where no more than one of the buttons can be checked at a time.
 *
 * @example
 * <RadioInputField label="Choose a user type">
 *  <RadioInput label="Buyer" name="user_type" value="buyer" />
 *  <RadioInput label="Department Manager" name="user_type" value="department_manager" />
 *  <RadioInput label="AP" name="user_type" value="ap" />
 *  <RadioInput label="Store Admin" name="user_type" value="store_admin" />
 * </RadioInputField>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/radio/}
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/radio/examples/radio-activedescendant/}
 */
const RadioInputField = React.forwardRef<
  HTMLFieldSetElement,
  React.PropsWithChildren<Props>
>(function RadioInputField(
  {
    asSection,
    children,
    defaultCheckedValue,
    description,
    error,
    fullWidth,
    inline,
    label,
    labelID: controlledLabelID,
    onChangeValue,
    checkedValue: controlledActiveValue,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [focusTriggerID] = useClientId(
    'gourmet-radio-input-field-focus-trigger',
  )

  const [defaultLabelID] = useClientId('gourmet-radio-input-field-label')
  const [descriptionID] = useClientId('gourmet-radio-input-field-description')
  const [errorID] = useClientId('gourmet-radio-input-field-error')

  const [labelID] = useControlledState({
    componentName: 'RadioInputField',
    controlledValue: controlledLabelID,
    defaultValue: defaultLabelID,
  })

  const [checkedValue, setCheckedValueState] = useControlledState<
    RadioInputFieldContextValue['checkedValue']
  >({
    componentName: 'RadioInputField',
    controlledValue: controlledActiveValue,
    defaultValue: defaultCheckedValue,
  })

  const setCheckedValue = React.useCallback<
    RadioInputFieldContextValue['setCheckedValue']
  >(
    (nextValue) => {
      if (nextValue === checkedValue) {
        return
      }

      setCheckedValueState(nextValue)
      onChangeValue?.(nextValue)
    },
    [onChangeValue, checkedValue, setCheckedValueState],
  )

  const isCheckedValue = React.useCallback<
    RadioInputFieldContextValue['isCheckedValue']
  >((value) => checkedValue === value, [checkedValue])

  const [elementRef, setElementRef] =
    React.useState<HTMLFieldSetElement | null>(null)

  const [focusedValue, setFocusedValueState] =
    React.useState<RadioInputFieldContextValue['focusedValue']>(checkedValue)

  const setFocusedValue = React.useCallback<
    RadioInputFieldContextValue['setFocusedValue']
  >(
    (nextValue) => {
      if (nextValue === focusedValue) {
        return
      }

      setFocusedValueState(nextValue)
    },
    [focusedValue, setFocusedValueState],
  )

  const isFocusedValue = React.useCallback<
    RadioInputFieldContextValue['isFocusedValue']
  >((value) => focusedValue === value, [focusedValue])

  const focusFirstAvailableInput = React.useCallback(() => {
    if (!elementRef) {
      return
    }

    const input =
      elementRef.querySelector<HTMLInputElement>(
        `[${RADIO_INPUT_SELECTOR}]:checked:not(:disabled)`,
      ) ||
      elementRef.querySelector<HTMLInputElement>(
        `[${RADIO_INPUT_SELECTOR}]:not(:disabled)`,
      ) ||
      null

    if (!input) {
      return
    }

    input.focus()
  }, [elementRef])

  const handleOnMouseDown = React.useCallback<
    React.MouseEventHandler<HTMLFieldSetElement>
  >(
    (event) => {
      event.preventDefault()
      focusFirstAvailableInput()
    },
    [focusFirstAvailableInput],
  )

  const handleOnFocusGlobal = React.useCallback<
    React.FocusEventHandler<HTMLFieldSetElement>
  >(
    (event) => {
      if (event.target instanceof HTMLInputElement) {
        setFocusedValue(event.target.value)
      }
    },
    [setFocusedValue],
  )

  const handleOnFocus = React.useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(() => {
    focusFirstAvailableInput()
  }, [focusFirstAvailableInput])

  const handleOnBlur = React.useCallback<
    React.FocusEventHandler<HTMLFieldSetElement>
  >(() => {
    setFocusedValue(undefined)
  }, [setFocusedValue])

  /**
   * Implements the "roving tabindex" pattern in order to focus radio input
   * elements when the user navigates through with the arrow keys on their keyboard.
   *
   * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/radio/examples/radio/}
   */
  const handleKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLFieldSetElement>
  >(
    (event) => {
      if (!elementRef) {
        return
      }

      const elements = Array.from<HTMLInputElement>(
        event.currentTarget.querySelectorAll(`[${RADIO_INPUT_SELECTOR}]`),
      )

      if (!elements.length) {
        return
      }

      const enabledElements = elements.filter(
        (tabElement) => !tabElement.disabled,
      )

      if (!enabledElements.length) {
        return
      }

      if (
        enabledElements.length === 1 &&
        document.activeElement === enabledElements[0]
      ) {
        return
      }

      switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowUp': {
          const nextElementToFocus = getNextElementToFocus(elements, 'left')

          if (
            nextElementToFocus &&
            document.activeElement !== nextElementToFocus
          ) {
            nextElementToFocus.focus()
          }

          break
        }

        case 'ArrowRight':
        case 'ArrowDown': {
          const nextElementToFocus = getNextElementToFocus(elements, 'right')

          if (
            nextElementToFocus &&
            document.activeElement !== nextElementToFocus
          ) {
            nextElementToFocus.focus()
          }

          break
        }

        case 'Home': {
          const nextElementToFocus = elements.find(
            (element) => !element.disabled,
          )

          if (
            nextElementToFocus &&
            document.activeElement !== nextElementToFocus
          ) {
            nextElementToFocus.focus()
          }

          break
        }

        case 'End': {
          const nextElementToFocus = elements
            .reverse()
            .find((element) => !element.disabled)

          if (
            nextElementToFocus &&
            document.activeElement !== nextElementToFocus
          ) {
            nextElementToFocus.focus()
          }

          break
        }

        default: {
          break
        }
      }
    },
    [elementRef],
  )

  const activeDescendant = React.useMemo<string>(() => {
    if (!elementRef || !checkedValue) {
      return ''
    }

    const input = elementRef.querySelector<HTMLInputElement>(
      `[${RADIO_INPUT_SELECTOR}][value="${checkedValue}"]`,
    )

    if (!input) {
      return ''
    }

    return input.id
  }, [elementRef, checkedValue])

  React.useEffect(() => {
    if (focusedValue) {
      setCheckedValue(focusedValue)
    }
  }, [focusedValue, setCheckedValue])

  return (
    <RadioInputFieldContainer
      {...props}
      {...(asSection && { 'data-as-section': '' })}
      {...(!label && { 'aria-labelledby': labelID })}
      $fullWidth={fullWidth}
      $inline={inline}
      aria-activedescendant={activeDescendant}
      data-gourmet-radio-input-field=""
      onFocus={composeEventHandlers(props.onFocus, handleOnFocusGlobal)}
      onBlur={composeEventHandlers(props.onBlur, handleOnBlur)}
      onKeyDown={composeEventHandlers(props.onKeyDown, handleKeyDown)}
      onMouseDown={handleOnMouseDown}
      ref={composeRefs([setElementRef, ref])}
      role="radiogroup"
    >
      {/**
       * We use this input to be able to accept focus when the user is using
       * the "Tab" key on browsers like Safari, which will only accept focus
       * on text-only inputs.
       *
       * @see {@link https://bugs.webkit.org/show_bug.cgi?id=22261}
       */}
      <VisuallyHiddenInput
        data-gourmet-radio-input-field-focus-trigger=""
        focusable
        id={focusTriggerID}
        onFocus={handleOnFocus}
      />

      {(label || description) && (
        <>
          <legend>
            <InlineFlex direction="column" fullWidth>
              {label != null && (
                <Text data-gourmet-radio-input-field-label="">{label}</Text>
              )}

              {description && (
                <Text
                  data-gourmet-radio-input-field-description=""
                  id={descriptionID}
                  size="text-sm"
                  variant="secondary"
                >
                  {description}
                </Text>
              )}
            </InlineFlex>
          </legend>

          <Spacer size={asSection ? 'space.150' : 'space.050'} />
        </>
      )}

      <Flex direction={!inline ? 'column' : 'row'} fullWidth>
        <RadioInputFieldContext.Provider
          value={{
            checkedValue,
            descriptionID: description ? descriptionID : null,
            errorID: error ? errorID : null,
            focusedValue,
            focusTriggerID,
            isCheckedValue,
            isFocusedValue,
            setCheckedValue,
            setFocusedValue,
          }}
        >
          {children}
        </RadioInputFieldContext.Provider>
      </Flex>

      {error && (
        <>
          <Spacer size="space.050" />
          <Text
            data-gourmet-radio-input-field-error=""
            id={errorID}
            size="text-sm"
            variant="negative"
          >
            {error}
          </Text>
        </>
      )}
    </RadioInputFieldContainer>
  )
})

RadioInputField.displayName = 'RadioInputField'
RadioInputField.defaultProps = {}

export { RadioInputField }
export type { Props as RadioInputFieldProps }
