import { useClientId } from '@vori/react-hooks'
import React from 'react'

import Flex, { FlexProps } from '../Flex'

import { Tab } from './Tab'
import { TabList } from './TabList'
import { TabPanel } from './TabPanel'
import { TabPanels } from './TabPanels'
import { TabsContext, TabsContextValue } from './TabsContext'

import { getComponentValues } from './utils'
import { isInReactChildren } from '../utils'
import { TabValue } from './types'
import { useControlledState } from '../hooks'

interface Props {
  /**
   * A value to treat as **currently** selected. Use this prop to define a
   * **controlled** <Tabs> component.
   *
   * @see {@link https://reactjs.org/docs/forms.html#controlled-components}
   */
  selectedValue?: TabValue
  /**
   * Value to treat as **initially** selected in an **uncontrolled** <Tabs>
   * component.
   */
  defaultSelectedValue?: TabValue
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: FlexProps
  /**
   * A callback function fired when the <Tabs> component's state changes,
   * i.e. a new tab gets selected.
   */
  onChange?: (value: TabValue) => void
}

/**
 * Tabs organize similar content together into individual sections
 * in the same page.
 *
 * @example
 * function MyTabs() {
 *   return (
 *     <Tabs>
 *       <TabList>
 *         <Tab>First Item</Tab>
 *         <Tab>Second Item</Tab>
 *         <Tab>Third Item</Tab>
 *       </TabList>
 *       <TabPanels>
 *         <TabPanel>First Panel</TabPanel>
 *         <TabPanel>Second Panel</TabPanel>
 *         <TabPanel>Third Panel</TabPanel>
 *       </TabPanels>
 *     </Tabs>
 *   )
 * }
 *
 * About accessibility and keyboard navigation:
 *
 * This <Tabs> component implements the "manual selection" keyboard navigation
 * pattern, which is recommended when displaying a new tabpanel causes a
 * network request, which is almost always true in our case.
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/tabpanel/}
 * @see {@link https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_selection_follows_focus}
 */
const Tabs = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  function Tabs(
    {
      selectedValue: controlledActiveValue,
      children,
      defaultSelectedValue,
      flexProps,
      onChange,
    }: React.PropsWithChildren<Props>,
    ref,
  ) {
    // Warns if no <TabList> or <TabPanels> is present in `children`.
    if (
      process.env.NODE_ENV !== 'production' &&
      !isInReactChildren(
        children,
        (child) =>
          React.isValidElement(child) &&
          (child.type === TabList || child.type === TabPanels),
      )
    ) {
      console.warn(
        'A <Tabs> component should contain a <TabList> and <TabPanels> component.',
      )
    }

    const [tabsID] = useClientId('tabs')

    const tabValues = React.useMemo<Array<TabValue>>(
      () => getComponentValues(children, TabList, Tab),
      [children],
    )

    const tabPanelValues = React.useMemo<Array<TabValue>>(
      () => getComponentValues(children, TabPanels, TabPanel),
      [children],
    )

    // Provides a warning when the values of the <Tab> components don't
    // match up with the values of the <TabPanel> components.
    if (
      process.env.NODE_ENV !== 'production' &&
      tabValues.some((tabValue) => tabPanelValues.indexOf(tabValue) === -1)
    ) {
      console.warn(
        'The values of your <Tab> components do not match the values of your <TabPanel> components. This might lead to unintended consequences.',
      )
    }

    const [selectedValue, setSelectedValueState] = useControlledState<
      TabsContextValue['selectedValue']
    >({
      componentName: 'Tabs',
      controlledValue: controlledActiveValue,
      defaultValue: defaultSelectedValue ?? tabValues[0],
    })

    const setSelectedValue = React.useCallback<
      TabsContextValue['setSelectedValue']
    >(
      (value) => {
        setSelectedValueState(value)
        onChange?.(value)
      },
      [onChange, setSelectedValueState],
    )

    const isSelectedValue = React.useCallback<
      TabsContextValue['isSelectedValue']
    >((value) => selectedValue === value, [selectedValue])

    const [focusedIndex, setFocusedIndex] = React.useState(
      tabValues.indexOf(selectedValue) ?? 0,
    )

    return (
      <Flex
        alignItems="flex-start"
        column
        fullWidth
        {...flexProps}
        data-gourmet-tabs={tabsID}
        ref={ref}
      >
        <TabsContext.Provider
          value={{
            selectedValue,
            focusedIndex,
            id: tabsID,
            isSelectedValue,
            setSelectedValue,
            setFocusedIndex,
            values: tabValues,
          }}
        >
          {children}
        </TabsContext.Provider>
      </Flex>
    )
  },
)

Tabs.displayName = 'Tabs'
Tabs.defaultProps = {}

export { Tabs }
export type { Props as TabsProps }
