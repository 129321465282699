import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowLeftIconProps = IconProps

const ArrowLeftIcon = forwardRef<SVGSVGElement, ArrowLeftIconProps>(
  (props: ArrowLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 12H5m0 0 7 7m-7-7 7-7"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowLeftIcon.displayName = 'ArrowLeftIcon'
ArrowLeftIcon.defaultProps = iconDefaultProps

export type { ArrowLeftIconProps }
export { ArrowLeftIcon }
