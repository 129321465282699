import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileDollarIconProps = IconProps

const FileDollarIcon = forwardRef<SVGSVGElement, FileDollarIconProps>(
  (props: FileDollarIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8.759 1c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C3 5.29 3 5.954 3 6.758v10.483c0 .805 0 1.47.044 2.01.046.563.145 1.08.392 1.565a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C7.29 23 7.954 23 8.758 23H14a1 1 0 1 0 0-2H8.8c-.857 0-1.439 0-1.889-.038-.438-.035-.663-.1-.819-.18a2 2 0 0 1-.874-.874c-.08-.156-.145-.38-.18-.819C5 18.639 5 18.057 5 17.2V6.8c0-.857 0-1.439.038-1.889.035-.438.1-.663.18-.819a2 2 0 0 1 .874-.874c.156-.08.38-.145.819-.18C7.361 3 7.943 3 8.8 3h6.4c.857 0 1.439 0 1.889.038.438.035.663.1.819.18a2 2 0 0 1 .874.874c.08.156.145.38.18.819C19 5.361 19 5.943 19 6.8v2.7a1 1 0 1 0 2 0V6.759c0-.805 0-1.47-.044-2.01-.046-.563-.145-1.08-.392-1.565a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C16.71 1 16.046 1 15.242 1H8.758Z"
        stroke="none"
      ></path>
      <path
        fill="currentColor"
        d="M8 6a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2H8Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H8Zm0 4a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H8Z"
        stroke="none"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.6 12.834a.6.6 0 1 0-1.2 0v.233h-.233a2.267 2.267 0 0 0 0 4.533h.233v2.133h-.233c-.59 0-1.067-.477-1.067-1.066a.6.6 0 0 0-1.2 0 2.267 2.267 0 0 0 2.267 2.266h.233v.234a.6.6 0 1 0 1.2 0v-.234h.233a2.267 2.267 0 1 0 0-4.533H19.6v-2.133h.233c.59 0 1.067.477 1.067 1.067a.6.6 0 1 0 1.2 0 2.267 2.267 0 0 0-2.267-2.267H19.6v-.233Zm-1.433 1.433h.233V16.4h-.233a1.067 1.067 0 0 1 0-2.133Zm1.433 5.466V17.6h.233a1.067 1.067 0 1 1 0 2.133H19.6Z"
        clipRule="evenodd"
        stroke="none"
      ></path>
    </Icon>
  ),
)

FileDollarIcon.displayName = 'FileDollarIcon'
FileDollarIcon.defaultProps = iconDefaultProps

export type { FileDollarIconProps }
export { FileDollarIcon }
