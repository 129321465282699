import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Speedometer04IconProps = IconProps

const Speedometer04Icon = forwardRef<SVGSVGElement, Speedometer04IconProps>(
  (props: Speedometer04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.745 16a7.026 7.026 0 0 0 1.094-5.5M6.255 16a7 7 0 0 1 6.982-10.891M16.5 7.5 12 12m10 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Speedometer04Icon.displayName = 'Speedometer04Icon'
Speedometer04Icon.defaultProps = iconDefaultProps

export type { Speedometer04IconProps }
export { Speedometer04Icon }
