import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Menu02IconProps = IconProps

const Menu02Icon = forwardRef<SVGSVGElement, Menu02IconProps>(
  (props: Menu02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12h12M3 6h18M3 18h18"
        fill="none"
      ></path>
    </Icon>
  ),
)

Menu02Icon.displayName = 'Menu02Icon'
Menu02Icon.defaultProps = iconDefaultProps

export type { Menu02IconProps }
export { Menu02Icon }
