import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCw05IconProps = IconProps

const RefreshCw05Icon = forwardRef<SVGSVGElement, RefreshCw05IconProps>(
  (props: RefreshCw05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.453 12.893A8.5 8.5 0 0 1 4.638 16.25l-.25-.433m-.842-4.71A8.5 8.5 0 0 1 19.361 7.75l.25.433M3.493 18.066l.732-2.732 2.732.732m10.085-8.132 2.732.732.732-2.732"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCw05Icon.displayName = 'RefreshCw05Icon'
RefreshCw05Icon.defaultProps = iconDefaultProps

export type { RefreshCw05IconProps }
export { RefreshCw05Icon }
