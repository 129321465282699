import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Camera02IconProps = IconProps

const Camera02Icon = forwardRef<SVGSVGElement, Camera02IconProps>(
  (props: Camera02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 7.702A2.702 2.702 0 0 1 4.702 5c.775 0 1.463-.496 1.709-1.232L6.5 3.5c.042-.127.063-.19.086-.246a2 2 0 0 1 1.735-1.25C8.38 2 8.448 2 8.58 2h6.838c.133 0 .2 0 .26.004a2 2 0 0 1 1.735 1.25c.023.056.044.12.086.246l.09.268A1.801 1.801 0 0 0 19.297 5 2.702 2.702 0 0 1 22 7.702V16.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.72 21 18.88 21 17.2 21H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C2 18.72 2 17.88 2 16.2V7.702Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Camera02Icon.displayName = 'Camera02Icon'
Camera02Icon.defaultProps = iconDefaultProps

export type { Camera02IconProps }
export { Camera02Icon }
