import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CreditCardDownIconProps = IconProps

const CreditCardDownIcon = forwardRef<SVGSVGElement, CreditCardDownIconProps>(
  (props: CreditCardDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 18 3 3m0 0 3-3m-3 3v-6m3-5H2m20 2V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 5 19.92 5 18.8 5H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 6.52 2 7.08 2 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 19 4.08 19 5.2 19H12"
        fill="none"
      ></path>
    </Icon>
  ),
)

CreditCardDownIcon.displayName = 'CreditCardDownIcon'
CreditCardDownIcon.defaultProps = iconDefaultProps

export type { CreditCardDownIconProps }
export { CreditCardDownIcon }
