import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type NavigationPointer01IconProps = IconProps

const NavigationPointer01Icon = forwardRef<
  SVGSVGElement,
  NavigationPointer01IconProps
>((props: NavigationPointer01IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3.413 10.745c-.595-.232-.893-.348-.98-.515a.5.5 0 0 1 0-.461c.087-.167.385-.283.98-.515L20.3 2.664c.537-.21.806-.315.978-.258a.5.5 0 0 1 .316.316c.057.172-.048.44-.258.977l-6.59 16.888c-.232.595-.348.893-.515.98a.5.5 0 0 1-.462 0c-.167-.088-.282-.385-.514-.98l-2.628-6.759a1.075 1.075 0 0 0-.107-.232.5.5 0 0 0-.116-.117 1.069 1.069 0 0 0-.232-.106l-6.759-2.628Z"
      fill="none"
    ></path>
  </Icon>
))

NavigationPointer01Icon.displayName = 'NavigationPointer01Icon'
NavigationPointer01Icon.defaultProps = iconDefaultProps

export type { NavigationPointer01IconProps }
export { NavigationPointer01Icon }
