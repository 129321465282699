import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignRight01IconProps = IconProps

const AlignRight01Icon = forwardRef<SVGSVGElement, AlignRight01IconProps>(
  (props: AlignRight01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21V3M3 12h14m0 0-7-7m7 7-7 7"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignRight01Icon.displayName = 'AlignRight01Icon'
AlignRight01Icon.defaultProps = iconDefaultProps

export type { AlignRight01IconProps }
export { AlignRight01Icon }
