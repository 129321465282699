import React, { forwardRef } from 'react'
import styled from 'styled-components'

import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
} from '@vori/gourmet-icons'

import Link from '../Link'
import Spacer from '../Spacer'

import Label, {
  LabelDefaultProps,
  LabelColorVariant,
  LabelProps,
} from '../Label'

import { colors, sizing, spacing } from '../tokens'

type ToastProps = LabelProps

const defaultProps: Partial<LabelProps> = LabelDefaultProps

const StyledToast = styled(
  forwardRef<HTMLSpanElement, ToastProps>(function Toast(
    { children, variant, ...props }: ToastProps,
    ref,
  ) {
    const Icon: React.ReactElement | null =
      variant === 'negative' ? (
        <AlertCircleIcon />
      ) : variant === 'positive' ? (
        <CheckCircleIcon />
      ) : variant === 'progress' ? (
        <AlertTriangleIcon />
      ) : null

    return (
      <Label {...props} ref={ref} pill variant={variant}>
        {Icon}
        {Icon != null && <Spacer size="tiny" />}
        {children}
      </Label>
    )
  }),
)<ToastProps>`
  background-color: ${({ variant = defaultProps.variant }) =>
    colors.toast.backgroundColor[variant as LabelColorVariant]};
  border: 1px solid
    ${({ variant = defaultProps.variant }) =>
      colors.toast.borderColor[variant as LabelColorVariant]};
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  border-radius: ${sizing.radius.base};
  color: ${({ variant = defaultProps.variant }) =>
    colors.toast.color[variant as LabelColorVariant]};
  text-align: center;
  justify-content: center;
  padding: ${({ noPadding }): string => (noPadding ? '0px' : spacing.toast)};

  ${Link} {
    color: ${({ variant = defaultProps.variant }) =>
      colors.toast.color[variant as LabelColorVariant]};
  }
`

StyledToast.displayName = 'Toast'
StyledToast.defaultProps = defaultProps

export type { ToastProps }
export { defaultProps as ToastDefaultProps }
export { StyledToast as Toast }
