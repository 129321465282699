import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlertTriangleFilledIconProps = IconProps

const AlertTriangleFilledIcon = forwardRef<
  SVGSVGElement,
  AlertTriangleFilledIconProps
>((props: AlertTriangleFilledIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.754 1.8a1.857 1.857 0 0 0-1.509 0c-.363.16-.604.448-.78.697a12.36 12.36 0 0 0-.573.933L1.63 17.702c-.212.366-.4.69-.527.964-.128.277-.257.63-.216 1.027.055.524.33 1 .755 1.31.323.234.693.299.997.327.3.027.675.027 1.098.027h16.525c.423 0 .798 0 1.098-.027.305-.028.675-.093.998-.328.425-.31.7-.785.755-1.31.04-.396-.088-.749-.217-1.026-.126-.274-.314-.598-.526-.964L14.107 3.43a12.994 12.994 0 0 0-.571-.933c-.177-.25-.418-.536-.782-.698ZM12 8.142c.473 0 .857.384.857.857v4a.857.857 0 0 1-1.714 0V9c0-.473.383-.857.857-.857ZM11.143 17c0-.473.383-.857.857-.857h.01a.857.857 0 0 1 0 1.714H12a.857.857 0 0 1-.857-.857Z"
      clipRule="evenodd"
      stroke="none"
    ></path>
  </Icon>
))

AlertTriangleFilledIcon.displayName = 'AlertTriangleFilledIcon'
AlertTriangleFilledIcon.defaultProps = iconDefaultProps

export type { AlertTriangleFilledIconProps }
export { AlertTriangleFilledIcon }
