import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DistributeSpacingHorizontalIconProps = IconProps

const DistributeSpacingHorizontalIcon = forwardRef<
  SVGSVGElement,
  DistributeSpacingHorizontalIconProps
>((props: DistributeSpacingHorizontalIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 21V3M3 21V3m6 5v8c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083C10.602 19 11.068 19 12 19c.932 0 1.398 0 1.765-.152a2 2 0 0 0 1.083-1.083C15 17.398 15 16.932 15 16V8c0-.932 0-1.398-.152-1.765a2 2 0 0 0-1.083-1.083C13.398 5 12.932 5 12 5c-.932 0-1.398 0-1.765.152a2 2 0 0 0-1.083 1.083C9 6.602 9 7.068 9 8Z"
      fill="none"
    ></path>
  </Icon>
))

DistributeSpacingHorizontalIcon.displayName = 'DistributeSpacingHorizontalIcon'
DistributeSpacingHorizontalIcon.defaultProps = iconDefaultProps

export type { DistributeSpacingHorizontalIconProps }
export { DistributeSpacingHorizontalIcon }
