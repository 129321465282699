import React from 'react'
import styled from 'styled-components'

import { InlineFlex } from '../FlexNext'
import { propsToStyledProps, PropsToStyledProps } from '../utils'
import { SpacerCoreProps, InlineSpacerProps } from './types'
import { spacerStyles } from './styles'

const StyledInlineSpacer = styled(InlineFlex)(
  (props: PropsToStyledProps<SpacerCoreProps>) => ({
    ...spacerStyles(props),
    alignSelf: 'stretch',
  }),
)

const defaultProps: Partial<SpacerCoreProps> = {
  size: 'space.100',
}

/**
 * The `<InlineSpacer>` component extends the `<Spacer>` component to render a
 * `<span>` element with `inline-flex` display.
 *
 * @example
 * <InlineFlex>Text. <InlineSpacer/> More Text.</InlineFlex>
 */
const InlineSpacer = React.forwardRef<HTMLSpanElement, InlineSpacerProps>(
  function Spacer(
    { size = defaultProps.size, ...props }: InlineSpacerProps,
    ref,
  ): JSX.Element {
    return (
      <StyledInlineSpacer
        {...propsToStyledProps({ size })}
        {...props}
        aria-hidden="true"
        data-gourmet-inline-spacer=""
        data-gourmet-spacer=""
        ref={ref}
        shrink={0}
        tabIndex={-1}
      />
    )
  },
)

InlineSpacer.displayName = 'InlineSpacer'
InlineSpacer.defaultProps = defaultProps

export { InlineSpacer, defaultProps as inlineSpacerDefaultProps }
