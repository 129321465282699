import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Power02IconProps = IconProps

const Power02Icon = forwardRef<SVGSVGElement, Power02IconProps>(
  (props: Power02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 7v5M8 9a5 5 0 1 0 8 0m6 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Power02Icon.displayName = 'Power02Icon'
Power02Icon.defaultProps = iconDefaultProps

export type { Power02IconProps }
export { Power02Icon }
