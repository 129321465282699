import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FlipForwardIconProps = IconProps

const FlipForwardIcon = forwardRef<SVGSVGElement, FlipForwardIconProps>(
  (props: FlipForwardIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 9H7.5a4.5 4.5 0 1 0 0 9H12m9-9-4-4m4 4-4 4"
        fill="none"
      ></path>
    </Icon>
  ),
)

FlipForwardIcon.displayName = 'FlipForwardIcon'
FlipForwardIcon.defaultProps = iconDefaultProps

export type { FlipForwardIconProps }
export { FlipForwardIcon }
