import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LayersThree01IconProps = IconProps

const LayersThree01Icon = forwardRef<SVGSVGElement, LayersThree01IconProps>(
  (props: LayersThree01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m2 12 9.642 4.821c.131.066.197.099.266.111.06.012.123.012.184 0 .069-.012.135-.045.266-.11L22 12M2 17l9.642 4.821c.131.066.197.099.266.111.06.012.123.012.184 0 .069-.012.135-.045.266-.11L22 17M2 7l9.642-4.821c.131-.066.197-.098.266-.111a.5.5 0 0 1 .184 0c.069.013.135.045.266.11L22 7l-9.642 4.821a1.028 1.028 0 0 1-.266.111.501.501 0 0 1-.184 0c-.069-.012-.135-.045-.266-.11L2 7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LayersThree01Icon.displayName = 'LayersThree01Icon'
LayersThree01Icon.defaultProps = iconDefaultProps

export type { LayersThree01IconProps }
export { LayersThree01Icon }
