import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Pencil02IconProps = IconProps

const Pencil02Icon = forwardRef<SVGSVGElement, Pencil02IconProps>(
  (props: Pencil02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18 2 4 4M2 22l1.276-4.68c.084-.305.125-.458.19-.6.056-.127.126-.247.207-.36.092-.125.204-.237.428-.46L14.434 5.565c.198-.198.297-.297.412-.334a.5.5 0 0 1 .309 0c.114.037.213.136.41.334l2.87 2.868c.197.198.296.297.333.411.033.1.033.21 0 .31-.037.114-.136.213-.334.41L8.101 19.9c-.224.224-.336.336-.462.428a2.003 2.003 0 0 1-.358.208c-.143.064-.296.105-.6.189L2 22Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Pencil02Icon.displayName = 'Pencil02Icon'
Pencil02Icon.defaultProps = iconDefaultProps

export type { Pencil02IconProps }
export { Pencil02Icon }
