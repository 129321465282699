import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DotsVerticalIconProps = IconProps

const DotsVerticalIcon = forwardRef<SVGSVGElement, DotsVerticalIconProps>(
  (props: DotsVerticalIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

DotsVerticalIcon.displayName = 'DotsVerticalIcon'
DotsVerticalIcon.defaultProps = iconDefaultProps

export type { DotsVerticalIconProps }
export { DotsVerticalIcon }
