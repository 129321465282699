import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Percent02IconProps = IconProps

const Percent02Icon = forwardRef<SVGSVGElement, Percent02IconProps>(
  (props: Percent02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 5 5 19M9 7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm10 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Percent02Icon.displayName = 'Percent02Icon'
Percent02Icon.defaultProps = iconDefaultProps

export type { Percent02IconProps }
export { Percent02Icon }
