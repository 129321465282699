// Try to keep this file roughly in sync with https://github.com/voriteam/ecommerce-web/blob/master/src/theme.js

// "Strict Mode" theme avoids React Strict mode warnings about nested `findDOMNode` usage on `Transition` / `Drawer`
// https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles'

import { foundation } from '@vori/gourmet-components'

const Colors = {
  BLACK: '#000',
  WHITE: '#fff',
}

// export const GRAY_4_BG =
//   // #D5D5D5 1x1px. Via http://www.patternify.com/
//   'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2O4evXqfwAIgQN/8XHAJAAAAABJRU5ErkJggg=='

const defaultTheme = createMuiTheme()

const MaterialUITheme = responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    // Define custom `colors` rather than overwriting `palette`,
    // since each has a different format
    // colors: Colors,
    palette: {
      primary: {
        main: foundation.colors.purple,
        // light?: string; Calculated if not provided
        // dark?: string; Calculated if not provided
        // contrastText?: string; Calculated if not provided
      },
      secondary: {
        main: foundation.colors.gold,
      },
      error: {
        main: foundation.colors.accentRed,
      },
      warning: {
        main: foundation.colors.lightRed,
      },
      // info: {},
      // success: {},
    },
    typography: {
      fontFamily:
        '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
      caption: {
        color: foundation.colors.dark2,
        fontWeight: 700,
        letterSpacing: 1,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'subpixel-antialiased',

            [defaultTheme.breakpoints.only('xs')]: {
              fontSize: '90%',
            },
          },
          body: {
            color: Colors.BLACK,
            backgroundColor: Colors.WHITE,
          },
        },
      },
      // Header
      MuiToolbar: {
        root: {
          backgroundColor: '#fff',
          color: foundation.colors.dark2,
        },
      },
      // Links
      MuiLink: {
        root: {
          cursor: 'pointer',
        },
      },
      // Buttons
      MuiButton: {
        root: {
          textTransform: 'none',
          '&+&': {
            marginLeft: 8,
          },
        },
        contained: {
          borderRadius: 100,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          color: Colors.WHITE,
          backgroundColor: foundation.colors.purple,
          '&:hover': {
            backgroundColor: foundation.colors.darkPurple,
          },
        },
        outlined: {
          borderRadius: defaultTheme.spacing(),
          border: `1px solid ${foundation.colors.dark2}`,
          color: foundation.colors.dark2,
        },
        outlinedPrimary: {
          color: foundation.colors.purple,
          backgroundColor: 'transparent',
          borderColor: foundation.colors.purple,
          '&:hover': {
            color: Colors.WHITE,
            borderColor: foundation.colors.darkPurple,
            backgroundColor: foundation.colors.darkPurple,
          },
        },
        outlinedSizeSmall: {
          fontSize: '0.75rem',
          fontWeight: 700,
          letterSpacing: 1,
          padding: '6px 12px',
        },
        outlinedSizeLarge: {
          fontWeight: 700,
          letterSpacing: 1,
        },
      },
      // Checkboxes
      MuiCheckbox: {
        root: {
          color: foundation.colors.dark2,
        },
        colorPrimary: {
          '&.Mui-checked': { color: foundation.colors.purple },
        },
      },
      // Chips
      MuiChip: {
        root: {
          height: 26,
          fontSize: '0.8rem',
          margin: '0 4px 8px 0',
          '& > svg.MuiSvgIcon-root': {
            width: 18,
            height: 18,
            color: foundation.colors.purple,
            '&:hover': {
              color: foundation.colors.darkPurple,
            },
          },
        },
        colorPrimary: {
          backgroundColor: foundation.colors.lightPurple,
          color: foundation.colors.purple,
          '&.MuiChip-deletableColorPrimary:focus': {
            backgroundColor: foundation.colors.lightPurple,
          },
        },
      },
      // CircularProgress
      MuiCircularProgress: {
        colorPrimary: {
          color: foundation.colors.purple,
        },
      },
      // LinearProgress
      MuiLinearProgress: {
        root: {
          borderRadius: 2,
        },
      },
      // Inputs
      MuiOutlinedInput: {
        notchedOutline: {
          borderRadius: 10,
          borderColor: foundation.colors.dark2,
          '.MuiOutlinedInput-root:hover &': {
            borderColor: foundation.colors.purple,
          },
          '.MuiOutlinedInput-root.Mui-error:hover &': {
            borderColor: foundation.colors.accentRed,
          },
        },

        input: {
          padding: '12px 16px', // default 18.5px 14px
          '&::placeholder': {
            color: Colors.BLACK,
          },
        },
      },
      MuiStepper: {
        root: {
          '&.MuiPaper-elevation0': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiTab: {
        root: {
          textTransform: 'inherit',
          fontWeight: 'bold',
        },
      },
      // Old product table implementation, TODO revisit
      MuiTableCell: {
        root: {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
          fontSize: '15px !important',
          color: 'rgba(0, 0, 0, 0.5) !important',
        },
        head: {
          color: 'rgba(0, 0, 0, 0.87) !important',
          textTransform: 'uppercase',
          fontSize: '14px !important',
        },
      },
      MuiTableSortLabel: {
        root: {
          fontSize: '14px !important',
        },
      },
      MuiTypography: {
        colorTextSecondary: {
          color: foundation.colors.dark2,
        },
      },
    },
  }),
  {
    breakpoints: ['xs', 'sm', 'md', 'lg'],
  },
)

export default MaterialUITheme
