import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LineChartDown02IconProps = IconProps

const LineChartDown02Icon = forwardRef<SVGSVGElement, LineChartDown02IconProps>(
  (props: LineChartDown02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 20.24 3 19.96 3 19.4V3m18 12-5.434-5.434c-.198-.198-.297-.297-.412-.334a.5.5 0 0 0-.309 0c-.114.037-.213.136-.41.334l-1.87 1.868c-.197.198-.296.297-.41.334a.499.499 0 0 1-.31 0c-.114-.037-.213-.136-.41-.334L7 7m14 8h-4m4 0v-4"
        fill="none"
      ></path>
    </Icon>
  ),
)

LineChartDown02Icon.displayName = 'LineChartDown02Icon'
LineChartDown02Icon.defaultProps = iconDefaultProps

export type { LineChartDown02IconProps }
export { LineChartDown02Icon }
