import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenUpIconProps = IconProps

const ArrowCircleBrokenUpIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenUpIconProps
>((props: ArrowCircleBrokenUpIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 20.662A9.996 9.996 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10a9.996 9.996 0 0 1-5 8.662M16 12l-4-4m0 0-4 4m4-4v14"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenUpIcon.displayName = 'ArrowCircleBrokenUpIcon'
ArrowCircleBrokenUpIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenUpIconProps }
export { ArrowCircleBrokenUpIcon }
