import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Moon01IconProps = IconProps

const Moon01Icon = forwardRef<SVGSVGElement, Moon01IconProps>(
  (props: Moon01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 15.844a10.424 10.424 0 0 1-4.306.925c-5.779 0-10.463-4.684-10.463-10.462 0-1.536.33-2.994.925-4.307A10.464 10.464 0 0 0 2 11.538C2 17.316 6.684 22 12.462 22c4.243 0 7.896-2.526 9.538-6.156Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Moon01Icon.displayName = 'Moon01Icon'
Moon01Icon.defaultProps = iconDefaultProps

export type { Moon01IconProps }
export { Moon01Icon }
