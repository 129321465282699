import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ReverseLeftIconProps = IconProps

const ReverseLeftIcon = forwardRef<SVGSVGElement, ReverseLeftIconProps>(
  (props: ReverseLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 7h10a6 6 0 0 1 0 12H4M4 7l4-4M4 7l4 4"
        fill="none"
      ></path>
    </Icon>
  ),
)

ReverseLeftIcon.displayName = 'ReverseLeftIcon'
ReverseLeftIcon.defaultProps = iconDefaultProps

export type { ReverseLeftIconProps }
export { ReverseLeftIcon }
