import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Rss01IconProps = IconProps

const Rss01Icon = forwardRef<SVGSVGElement, Rss01IconProps>(
  (props: Rss01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 11a9 9 0 0 1 9 9M4 4a16 16 0 0 1 16 16M6 19a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Rss01Icon.displayName = 'Rss01Icon'
Rss01Icon.defaultProps = iconDefaultProps

export type { Rss01IconProps }
export { Rss01Icon }
