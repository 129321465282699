import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowDownLeftIconProps = IconProps

const ArrowDownLeftIcon = forwardRef<SVGSVGElement, ArrowDownLeftIconProps>(
  (props: ArrowDownLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 7 7 17m0 0h10M7 17V7"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowDownLeftIcon.displayName = 'ArrowDownLeftIcon'
ArrowDownLeftIcon.defaultProps = iconDefaultProps

export type { ArrowDownLeftIconProps }
export { ArrowDownLeftIcon }
