import React, { Children, cloneElement, forwardRef } from 'react'
import styled from 'styled-components'

import Step, { StepProps } from './Step'
import Flex, { FlexProps, FlexDefaultProps } from '../Flex'

type ProgressProps = React.HTMLAttributes<HTMLDivElement> &
  Pick<FlexProps, 'fullHeight' | 'fullWidth'> & {
    vertical?: boolean
  }

const defaultProps: Partial<ProgressProps> = {
  className: '',
  fullHeight: FlexDefaultProps.fullHeight,
  fullWidth: FlexDefaultProps.fullWidth,
  vertical: false,
}

const StyledProgress = styled(
  forwardRef<HTMLDivElement, ProgressProps>(function Progress(
    { className = '', children, vertical, ...props }: ProgressProps,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const stepsCount =
      Children.map(
        children,
        (child) =>
          child != null && React.isValidElement(child) && child.type === Step,
      )?.filter((isEntry) => isEntry).length || 0

    return (
      <Flex
        {...props}
        column={vertical}
        alignItems="flex-start"
        className={className}
        ref={ref}
      >
        {Children.map(
          children as React.ReactElement<StepProps>[],
          (child, index) =>
            child != null
              ? cloneElement(child, {
                  children: index + 1,
                  vertical,
                  withTrack: index + 1 < stepsCount,
                })
              : null,
        )}
      </Flex>
    )
  }),
)`
  position: relative;
`

StyledProgress.displayName = 'Progress'
StyledProgress.defaultProps = defaultProps

export type { ProgressProps, StepProps }
export { defaultProps as ProgressDefaultProps }
export default StyledProgress
