export function getInputAriaProps(
  props: {
    [k in string]: unknown
  } & {
    descriptionID?: string
    errorID?: string
    label?: string
    labelID?: string
  },
): React.AriaAttributes {
  return {
    ...(props.descriptionID && { 'aria-describedby': props.descriptionID }),
    ...(props.errorID && {
      'aria-errormessage': props.errorID,
      'aria-invalid': 'true',
    }),
    ...(props.labelID && !props.label && { 'aria-labelledby': props.labelID }),
    ...(!props.labelID && props.label && { 'aria-label': props.label }),
  }
}
