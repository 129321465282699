import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Button, ButtonProps } from '../ButtonNext'
import { FormFieldLayout } from '../FormFieldLayout'

import { ChevronDownIcon, ChevronUpIcon, XCloseIcon } from '@vori/gourmet-icons'

import { Props as SelectProps } from './utils/types'

const LeftAlignedButton = styled(Button)`
  text-align: left;
  justify-content: flex-start;
`

interface Props<T> {
  internals: Parameters<NonNullable<SelectProps<T>['renderTrigger']>>[1] & {
    setNestedOptions: React.Dispatch<React.SetStateAction<T[]>>
  }
  isOpen: Parameters<NonNullable<SelectProps<T>['renderTrigger']>>[0]
  label?: string | null
  leftButtonProps?: ButtonProps
  rightButtonProps?: ButtonProps
}

function SelectRemoveValueTrigger<T>({
  internals,
  isOpen,
  label,
  leftButtonProps,
  rightButtonProps,
}: Props<T>): JSX.Element {
  const onRightButtonClick = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (event) => {
      if (rightButtonProps?.onClick != null) {
        rightButtonProps.onClick(event)
      }

      if (internals.state.hasSelectedOption) {
        internals.deselectOption()
      }
    },
    [internals, rightButtonProps],
  )

  const onRightButtonMouseDown = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (event) => {
      if (rightButtonProps?.onMouseDown != null) {
        rightButtonProps.onMouseDown(event)
      }

      if (internals.state.hasSelectedOption) {
        event.stopPropagation()
      }
    },
    [internals.state.hasSelectedOption, rightButtonProps],
  )

  return (
    <FormFieldLayout>
      <LeftAlignedButton fullWidth {...leftButtonProps}>
        {label || internals.state.label}
      </LeftAlignedButton>
      <Button
        {...rightButtonProps}
        aria-label={
          internals.state.hasSelectedOption
            ? 'Remove'
            : isOpen
              ? 'Close'
              : 'Open'
        }
        asIconButton
        leftIcon={
          internals.state.hasSelectedOption ? (
            <XCloseIcon />
          ) : isOpen ? (
            <ChevronUpIcon />
          ) : (
            <ChevronDownIcon />
          )
        }
        onClick={onRightButtonClick}
        onMouseDown={onRightButtonMouseDown}
      />
    </FormFieldLayout>
  )
}

export type { Props as SelectRemoveValueTriggerProps }
export default SelectRemoveValueTrigger
