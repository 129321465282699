import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Wind01IconProps = IconProps

const Wind01Icon = forwardRef<SVGSVGElement, Wind01IconProps>(
  (props: Wind01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 18s-1.19-.47-2-.698c-5.12-1.445-8.88 2.84-14 1.396C4.19 18.469 3 18 3 18m18-6s-1.19-.47-2-.698c-5.12-1.445-8.88 2.84-14 1.396C4.19 12.469 3 12 3 12m18-6s-1.19-.47-2-.698c-5.12-1.445-8.88 2.84-14 1.396C4.19 6.47 3 6 3 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Wind01Icon.displayName = 'Wind01Icon'
Wind01Icon.defaultProps = iconDefaultProps

export type { Wind01IconProps }
export { Wind01Icon }
