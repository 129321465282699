import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudSnowing02IconProps = IconProps

const CloudSnowing02Icon = forwardRef<SVGSVGElement, CloudSnowing02IconProps>(
  (props: CloudSnowing02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 18h.01M8 21h.01M12 18.5h.01m-.01 3h.01M16 18h.01M16 21h.01M7 15a5 5 0 1 1 .1-9.999 5.502 5.502 0 0 1 10.195 1.004A4.5 4.5 0 1 1 17.5 15H6.999Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudSnowing02Icon.displayName = 'CloudSnowing02Icon'
CloudSnowing02Icon.defaultProps = iconDefaultProps

export type { CloudSnowing02IconProps }
export { CloudSnowing02Icon }
