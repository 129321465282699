import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronUpDoubleIconProps = IconProps

const ChevronUpDoubleIcon = forwardRef<SVGSVGElement, ChevronUpDoubleIconProps>(
  (props: ChevronUpDoubleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m17 18-5-5-5 5m10-7-5-5-5 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

ChevronUpDoubleIcon.displayName = 'ChevronUpDoubleIcon'
ChevronUpDoubleIcon.defaultProps = iconDefaultProps

export type { ChevronUpDoubleIconProps }
export { ChevronUpDoubleIcon }
