import React from 'react'

import { Divider, DividerProps } from '../Divider'
import { Spacer } from '../SpacerNext'

/**
 * The `<MenuDivider>` component is used to visually separate menu items and
 * links through a `<Divider>` component.
 *
 * @example
 * <Menu>
 *   <MenuButton
 *     aria-label="Menu"
 *     asClickable
 *     asIconButton
 *     leftIcon={<DotsVerticalIcon />}
 *   />
 *   <MenuList>
 *     <MenuItem onSelect={onSelectFn} icon={<AlarmClockCheckIcon />} label="Menu Item 1" />
 *     <MenuItem onSelect={onSelectFn} icon={<Building02Icon />} label="Menu Item 2" />
 *     <MenuDivider />
 *     <MenuItem onSelect={onSelectFn} icon={<Dataflow04Icon />} label="Menu Item 3" />
 *     <MenuItem onSelect={onSelectFn} icon={<Shield02Icon />} label="Menu Item 4" />
 *     <MenuDivider />
 *     <MenuItem onSelect={onSelectFn} icon={<InfinityIcon />} label="Menu Item 5" />
 *     <MenuItem onSelect={onSelectFn} icon={<Webcam02Icon />} label="Menu Item 6" />
 *   </MenuList>
 * </Menu>
 *
 * @see {@link https://vori-gourmet-1bdf0.web.app/?path=/docs/divider--default}
 */
const MenuDivider = React.forwardRef<HTMLDivElement, DividerProps>(
  function MenuDivider(props: DividerProps, ref): JSX.Element {
    return (
      <>
        <Spacer data-gourmet-menu-divider-spacer="" size="space.025" />
        <Divider {...props} data-gourmet-menu-divider="" ref={ref} />
        <Spacer data-gourmet-menu-divider-spacer="" size="space.025" />
      </>
    )
  },
)

MenuDivider.displayName = 'MenuDivider'
MenuDivider.defaultProps = { ...Divider.defaultProps }

export { MenuDivider }
export type { DividerProps as MenuDividerProps }
