import React from 'react'
import { StoreProductTypeaheadSearchResultDto } from '@vori/dashboard-rest-next/schemas'
import { Badge, base, LinkNext } from '@vori/gourmet-components'
import { Button } from 'gourmet/web-components/src/ButtonNext'
import styled from 'styled-components'

type DataProp = StoreProductTypeaheadSearchResultDto | null

const DetailContainer = styled.div`
  padding: 5px;
  .header {
    h4 {
      margin-bottom: 5px;
      font-weight: bold;
    }
    .subhead {
      font-size: 0.8rem;
    }
  }

  .content-body {
    margin-top: 1.4rem;
  }

  .content-split-item {
    font-size: 0.9rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0.8rem;

    span:nth-child(2) {
      text-align: right; /* Align text in the second item to the right */
    }
  }
`

const ProductSeachDetailView = ({
  data,
  ctaHandler,
}: {
  data: DataProp
  ctaHandler?: () => void
}) => {
  if (data === null) {
    return data
  }
  const handleViewProductClicked = () => {
    if (ctaHandler) {
      ctaHandler()
    }
  }

  return (
    <DetailContainer>
      <div className="header">
        <h4>{data.name}</h4>
        <div className="subhead">
          {data.brand && <span>{data.brand} </span>}
          {data.barcode && <span>&#x2022; {data.barcode}</span>}
          {data.unit_volume && <span> &#x2022; {data.unit_volume}</span>}
        </div>
      </div>
      <div className="content-body">
        <div className="content-split-item">
          <span>Brand</span>
          <span>{data.brand || ''}</span>
        </div>
        <div className="content-split-item">
          <span>Department</span>
          <span>{data.department.name}</span>
        </div>
        <div className="content-split-item">
          <span>Price</span>
          <span>${parseFloat(data.base_retail_price || '').toFixed(2)}</span>
        </div>
        <div className="content-split-item">
          <span>Status</span>
          <span>
            <Badge
              variant={data.active ? 'positive' : 'negative'}
              label={data.active ? 'Active' : 'Inactive'}
            >
              {data.active ? 'Active' : 'Inactive'}
            </Badge>
          </span>
        </div>
        <LinkNext
          style={{
            textDecoration: 'none',
            width: '100%',
          }}
          onClick={handleViewProductClicked}
          to={`/retail/products/${data.id}/update`}
        >
          <Button
            size="xLarge"
            variant="default"
            style={{
              marginTop: '1rem',
              borderColor: base.colors.dark3,
              color: base.colors.dark1,
              width: '100%',
            }}
          >
            View Product
          </Button>
        </LinkNext>
      </div>
    </DetailContainer>
  )
}

export default ProductSeachDetailView
