import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LogIn04IconProps = IconProps

const LogIn04Icon = forwardRef<SVGSVGElement, LogIn04IconProps>(
  (props: LogIn04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m12 8 4 4m0 0-4 4m4-4H3m.338-5A9.996 9.996 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.996 9.996 0 0 1-8.662-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

LogIn04Icon.displayName = 'LogIn04Icon'
LogIn04Icon.defaultProps = iconDefaultProps

export type { LogIn04IconProps }
export { LogIn04Icon }
