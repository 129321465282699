import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Edit02IconProps = IconProps

const Edit02Icon = forwardRef<SVGSVGElement, Edit02IconProps>(
  (props: Edit02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18 10-4-4M2.5 21.5l3.384-.376c.414-.046.62-.069.814-.131a2 2 0 0 0 .485-.234c.17-.111.317-.259.61-.553L21 7a2.828 2.828 0 1 0-4-4L3.794 16.206c-.294.294-.442.442-.553.611a2 2 0 0 0-.234.485c-.062.193-.085.4-.131.814L2.5 21.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Edit02Icon.displayName = 'Edit02Icon'
Edit02Icon.defaultProps = iconDefaultProps

export type { Edit02IconProps }
export { Edit02Icon }
