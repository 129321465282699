import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowsDownIconProps = IconProps

const ArrowsDownIcon = forwardRef<SVGSVGElement, ArrowsDownIconProps>(
  (props: ArrowsDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 4v11m0 0-4-4m4 4 4-4M7 4v16m0 0-4-4m4 4 4-4"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowsDownIcon.displayName = 'ArrowsDownIcon'
ArrowsDownIcon.defaultProps = iconDefaultProps

export type { ArrowsDownIconProps }
export { ArrowsDownIcon }
