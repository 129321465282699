import React from 'react'

import { AppSidebarLink, AppSidebarLinks } from '@vori/gourmet-components'

import {
  Announcement02Icon,
  BarChart10Icon,
  BookOpen01Icon,
  Building02Icon,
  ClipboardIcon,
  Cube01Icon,
  File02Icon,
  FileDollarIcon,
  Gift01Icon,
  Grid01Icon,
  Grid02Icon,
  HomeLineIcon,
  IconProps,
  Percent02Icon,
  Settings03Icon,
  Tag01Icon,
  Truck02Icon,
  Users01Icon,
} from '@vori/gourmet-icons'

import { FeatureFlags } from '@vori/dashboard-constants'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import { routes } from '../routes'

type NavigationLink = {
  icon: React.ReactElement<IconProps>
  isActive: () => boolean
  label: string
  path: string
}

function RetailNavigation(): JSX.Element | null {
  const hasLoyaltyAccess = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_RETAIL_LOYALTY,
  )

  const hasCampaignsAccess = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_RETAIL_CAMPAIGNS,
  )

  const hasPromotionsAccess = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_RETAIL_PROMOTIONS,
  )

  const hasFrontOfficeAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_FRONT_OFFICE,
  )

  const hasBackOfficeAdminAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_BACK_OFFICE_ADMIN,
  )

  const hasBackOfficeProductsAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_BACK_OFFICE_PRODUCTS,
  )

  const hasInvoicesV2Access = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_INVOICES_NEXT,
  )

  const hasReportingAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_REPORTING,
  )

  const hasStoreFilesAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_STORE_FILES,
  )

  const hasLegacyAccess = useFeatureConfig(FeatureFlags.WEB_NAVIGATION_LEGACY)
  const hasUsersAccess = useFeatureConfig(FeatureFlags.WEB_FEATURE_USERS)

  const navigationLinks: Array<NavigationLink> = [
    {
      label: 'Home',
      path: routes.home.root.path,
      icon: <HomeLineIcon />,
      isActive: () => hasReportingAccess || hasLegacyAccess,
    },
    {
      label: 'Front Office',
      path: routes.frontOffice.root.path,
      icon: <Building02Icon />,
      isActive: () => hasFrontOfficeAccess,
    },
    {
      label: 'Reporting',
      path: routes.reporting.root.path,
      icon: <BarChart10Icon />,
      isActive: () => hasReportingAccess,
    },
    {
      label: 'Invoices',
      path: routes.invoices.root.path,
      icon: <FileDollarIcon />,
      isActive: () => hasBackOfficeProductsAccess && hasInvoicesV2Access,
    },
    {
      label: 'Products',
      path: routes.products.root.path,
      icon: <Cube01Icon />,
      isActive: () => hasBackOfficeProductsAccess,
    },
    {
      label: 'Promotions',
      path: routes.promotions.root.path,
      icon: <Tag01Icon />,
      isActive: () => hasPromotionsAccess,
    },
    {
      label: 'Loyalty',
      path: routes.loyalty.root.path,
      icon: <Gift01Icon />,
      isActive: () => hasLoyaltyAccess,
    },
    {
      label: 'Campaigns',
      path: routes.campaigns.root.path,
      icon: <Announcement02Icon />,
      isActive: () => hasCampaignsAccess,
    },
    {
      label: 'Vendors',
      path: routes.vendors.root.path,
      icon: <Truck02Icon />,
      isActive: () => hasBackOfficeAdminAccess,
    },
    {
      label: 'Taxes',
      path: routes.taxes.root.path,
      icon: <Percent02Icon />,
      isActive: () => hasBackOfficeAdminAccess,
    },
    {
      label: 'Departments',
      path: routes.departments.list.path,
      icon: <Grid01Icon />,
      isActive: () => hasBackOfficeAdminAccess,
    },
    {
      label: 'Item Modifiers',
      path: routes.itemModifiers.root.path,
      icon: <Grid02Icon />,
      isActive: () => hasBackOfficeAdminAccess,
    },
    {
      label: 'Cashiers',
      path: routes.users.root.path,
      icon: <Users01Icon />,
      isActive: () => hasUsersAccess,
    },
    {
      label: 'Order Setting',
      path: routes.stores.root.path,
      icon: <Settings03Icon />,
      isActive: () => hasLegacyAccess,
    },
    {
      label: 'Order Guides',
      path: routes.search.root.path,
      icon: <ClipboardIcon />,
      isActive: () => hasLegacyAccess,
    },
    {
      label: 'Vendor Catalogs',
      path: routes.catalogs.root.path,
      icon: <BookOpen01Icon />,
      isActive: () => hasLegacyAccess,
    },
    {
      label: 'Files',
      path: routes.files.root.path,
      icon: <File02Icon />,
      isActive: () => hasStoreFilesAccess,
    },
  ]

  return (
    <AppSidebarLinks>
      {navigationLinks
        .filter(({ isActive }) => isActive())
        .map((link) => (
          <AppSidebarLink
            href={link.path}
            icon={link.icon}
            key={link.path}
            label={link.label}
          />
        ))}
    </AppSidebarLinks>
  )
}

export { RetailNavigation }
