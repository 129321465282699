import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PauseCircleIconProps = IconProps

const PauseCircleIcon = forwardRef<SVGSVGElement, PauseCircleIconProps>(
  (props: PauseCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 15V9m5 6V9m7.5 3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

PauseCircleIcon.displayName = 'PauseCircleIcon'
PauseCircleIcon.defaultProps = iconDefaultProps

export type { PauseCircleIconProps }
export { PauseCircleIcon }
