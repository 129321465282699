import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DownloadCloud02IconProps = IconProps

const DownloadCloud02Icon = forwardRef<SVGSVGElement, DownloadCloud02IconProps>(
  (props: DownloadCloud02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8 17 4 4m0 0 4-4m-4 4v-9m8 4.743A5.5 5.5 0 0 0 16.5 7a.62.62 0 0 1-.534-.302 7.5 7.5 0 1 0-11.78 9.096"
        fill="none"
      ></path>
    </Icon>
  ),
)

DownloadCloud02Icon.displayName = 'DownloadCloud02Icon'
DownloadCloud02Icon.defaultProps = iconDefaultProps

export type { DownloadCloud02IconProps }
export { DownloadCloud02Icon }
