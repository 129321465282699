import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GridDotsBottomIconProps = IconProps

const GridDotsBottomIcon = forwardRef<SVGSVGElement, GridDotsBottomIconProps>(
  (props: GridDotsBottomIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 3h.01M3 12h.01M3 16.5h.01M3 7.5h.01M7.5 3h.01m-.01 9h.01m8.99-9h.01m-.01 9h.01M12 3h.01M12 12h.01M12 16.5h.01m-.01-9h.01M21 3h.01M21 12h.01M21 16.5h.01m-.01-9h.01M21 21H3"
        fill="none"
      ></path>
    </Icon>
  ),
)

GridDotsBottomIcon.displayName = 'GridDotsBottomIcon'
GridDotsBottomIcon.defaultProps = iconDefaultProps

export type { GridDotsBottomIconProps }
export { GridDotsBottomIcon }
