import React from 'react'
import styled from 'styled-components'

import { FlexCoreProps, InlineFlexProps } from './types'
import { flexStyles } from './styles'
import { propsToStyledProps, PropsToStyledProps } from '../utils'

const StyledInlineFlex = styled.span<PropsToStyledProps<FlexCoreProps>>(
  (props) => ({
    ...flexStyles(props),
    display: 'inline-flex',
  }),
)

const defaultProps: Partial<FlexCoreProps> = {}

/**
 * The `<InlineFlex>` component extends the `<Flex>` component to render a
 * `<span>` element with `inline-flex` display.
 *
 * @example
 * <InlineFlex centerY>Text</InlineFlex>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_flexible_box_layout}
 */
const InlineFlex = React.forwardRef<HTMLSpanElement, InlineFlexProps>(
  function InlineFlex(
    {
      alignItems,
      alignSelf,
      basis,
      center,
      centerX,
      centerY,
      children,
      columnGap,
      columnOffset,
      direction,
      flex,
      fullHeight,
      fullWidth,
      gap,
      grow,
      justifyContent,
      offset,
      rowGap,
      rowOffset,
      shrink,
      size,
      wrap,
      ...props
    }: InlineFlexProps,
    ref,
  ): JSX.Element {
    return (
      <StyledInlineFlex
        {...propsToStyledProps({
          alignItems,
          alignSelf,
          basis,
          center,
          centerX,
          centerY,
          columnGap,
          columnOffset,
          direction,
          flex,
          fullHeight,
          fullWidth,
          gap,
          grow,
          justifyContent,
          offset,
          rowGap,
          rowOffset,
          shrink,
          size,
          wrap,
        })}
        {...props}
        data-gourmet-flex=""
        data-gourmet-inline-flex=""
        ref={ref}
      >
        {children}
      </StyledInlineFlex>
    )
  },
)

InlineFlex.displayName = 'InlineFlex'
InlineFlex.defaultProps = defaultProps

export { InlineFlex, defaultProps as inlineFlexDefaultProps }
