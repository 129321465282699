import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignCenterIconProps = IconProps

const AlignCenterIcon = forwardRef<SVGSVGElement, AlignCenterIconProps>(
  (props: AlignCenterIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 10H6m15-4H3m18 8H3m15 4H6"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignCenterIcon.displayName = 'AlignCenterIcon'
AlignCenterIcon.defaultProps = iconDefaultProps

export type { AlignCenterIconProps }
export { AlignCenterIcon }
