import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TrendUp02IconProps = IconProps

const TrendUp02Icon = forwardRef<SVGSVGElement, TrendUp02IconProps>(
  (props: TrendUp02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 17 17 7m0 0H7m10 0v10"
        fill="none"
      ></path>
    </Icon>
  ),
)

TrendUp02Icon.displayName = 'TrendUp02Icon'
TrendUp02Icon.defaultProps = iconDefaultProps

export type { TrendUp02IconProps }
export { TrendUp02Icon }
