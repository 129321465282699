/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Arguments, Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  BackofficeProductDto,
  CountBackofficeProductsParams,
  CountDto,
  CreateItemModifierDto,
  CreateProductRangeDto,
  CreateStoreProduct400,
  CreateStoreProductWrapperDto,
  CreateStoreVendorProductDto,
  CreateVariableWeightDto,
  GetProductRangeProductsParams,
  GetStoreProductParams,
  InvalidStoresError,
  ItemModifierDto,
  ListBackOfficeProductsParams,
  ListBackofficeProductsResponseDto,
  ListItemModifierParams,
  ListStoreProductsParams,
  ListVariableWeightParams,
  ProductRangeDto,
  ProductSchemaDto,
  SearchStoreProductsParams,
  StoreProductDto,
  StoreProductPageDto,
  StoreProductTypeaheadSearchPageDto,
  UpdateItemModifierDto,
  UpdateProductRangeDto,
  UpdateStoreProduct400,
  UpdateStoreProductWrapperDto,
  UpdateStoreVendorProductDto,
  UpdateVariableWeightDto,
  VariableWeightDto,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const listBackOfficeProducts = (
  params: ListBackOfficeProductsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ListBackofficeProductsResponseDto>(
    { url: `/api/backoffice/products`, method: 'GET', params },
    options,
  )
}

export const getListBackOfficeProductsKey = (
  params: ListBackOfficeProductsParams,
) => [`/api/backoffice/products`, ...(params ? [params] : [])] as const

export type ListBackOfficeProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBackOfficeProducts>>
>
export type ListBackOfficeProductsQueryError = ErrorType<unknown>

export const useListBackOfficeProducts = <TError = ErrorType<unknown>>(
  params: ListBackOfficeProductsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listBackOfficeProducts>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListBackOfficeProductsKey(params) : null))
  const swrFn = () => listBackOfficeProducts(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getBackOfficeProductsSchema = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ProductSchemaDto>(
    { url: `/api/backoffice/products-schema`, method: 'GET' },
    options,
  )
}

export const getGetBackOfficeProductsSchemaKey = () =>
  [`/api/backoffice/products-schema`] as const

export type GetBackOfficeProductsSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBackOfficeProductsSchema>>
>
export type GetBackOfficeProductsSchemaQueryError = ErrorType<unknown>

export const useGetBackOfficeProductsSchema = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getBackOfficeProductsSchema>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetBackOfficeProductsSchemaKey() : null))
  const swrFn = () => getBackOfficeProductsSchema(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const countBackofficeProducts = (
  params: CountBackofficeProductsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CountDto>(
    { url: `/api/backoffice/products/count`, method: 'GET', params },
    options,
  )
}

export const getCountBackofficeProductsKey = (
  params: CountBackofficeProductsParams,
) => [`/api/backoffice/products/count`, ...(params ? [params] : [])] as const

export type CountBackofficeProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof countBackofficeProducts>>
>
export type CountBackofficeProductsQueryError = ErrorType<unknown>

export const useCountBackofficeProducts = <TError = ErrorType<unknown>>(
  params: CountBackofficeProductsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof countBackofficeProducts>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getCountBackofficeProductsKey(params) : null))
  const swrFn = () => countBackofficeProducts(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getBackOfficeProduct = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<BackofficeProductDto>(
    { url: `/api/backoffice/products/${id}`, method: 'GET' },
    options,
  )
}

export const getGetBackOfficeProductKey = (id: string) =>
  [`/api/backoffice/products/${id}`] as const

export type GetBackOfficeProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBackOfficeProduct>>
>
export type GetBackOfficeProductQueryError = ErrorType<void>

export const useGetBackOfficeProduct = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getBackOfficeProduct>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetBackOfficeProductKey(id) : null))
  const swrFn = () => getBackOfficeProduct(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createItemModifier = (
  createItemModifierDto: CreateItemModifierDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ItemModifierDto>(
    {
      url: `/v1/item-modifiers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createItemModifierDto,
    },
    options,
  )
}

export const getCreateItemModifierMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateItemModifierDto },
  ): Promise<ItemModifierDto> => {
    return createItemModifier(arg, options)
  }
}
export const getCreateItemModifierMutationKey = () =>
  [`/v1/item-modifiers`] as const

export type CreateItemModifierMutationResult = NonNullable<
  Awaited<ReturnType<typeof createItemModifier>>
>
export type CreateItemModifierMutationError = ErrorType<unknown>

export const useCreateItemModifier = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createItemModifier>>,
    TError,
    Key,
    CreateItemModifierDto,
    Awaited<ReturnType<typeof createItemModifier>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateItemModifierMutationKey()
  const swrFn = getCreateItemModifierMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listItemModifier = (
  params: ListItemModifierParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ItemModifierDto[]>(
    { url: `/v1/item-modifiers`, method: 'GET', params },
    options,
  )
}

export const getListItemModifierKey = (params: ListItemModifierParams) =>
  [`/v1/item-modifiers`, ...(params ? [params] : [])] as const

export type ListItemModifierQueryResult = NonNullable<
  Awaited<ReturnType<typeof listItemModifier>>
>
export type ListItemModifierQueryError = ErrorType<unknown>

export const useListItemModifier = <TError = ErrorType<unknown>>(
  params: ListItemModifierParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listItemModifier>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListItemModifierKey(params) : null))
  const swrFn = () => listItemModifier(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const findOneItemModifier = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ItemModifierDto>(
    { url: `/v1/item-modifiers/${id}`, method: 'GET' },
    options,
  )
}

export const getFindOneItemModifierKey = (id: string) =>
  [`/v1/item-modifiers/${id}`] as const

export type FindOneItemModifierQueryResult = NonNullable<
  Awaited<ReturnType<typeof findOneItemModifier>>
>
export type FindOneItemModifierQueryError = ErrorType<void>

export const useFindOneItemModifier = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof findOneItemModifier>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getFindOneItemModifierKey(id) : null))
  const swrFn = () => findOneItemModifier(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateItemModifier = (
  id: string,
  updateItemModifierDto: UpdateItemModifierDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ItemModifierDto>(
    {
      url: `/v1/item-modifiers/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateItemModifierDto,
    },
    options,
  )
}

export const getUpdateItemModifierMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateItemModifierDto },
  ): Promise<ItemModifierDto> => {
    return updateItemModifier(id, arg, options)
  }
}
export const getUpdateItemModifierMutationKey = (id: string) =>
  [`/v1/item-modifiers/${id}`] as const

export type UpdateItemModifierMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateItemModifier>>
>
export type UpdateItemModifierMutationError = ErrorType<void>

export const useUpdateItemModifier = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateItemModifier>>,
      TError,
      Key,
      UpdateItemModifierDto,
      Awaited<ReturnType<typeof updateItemModifier>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateItemModifierMutationKey(id)
  const swrFn = getUpdateItemModifierMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteItemModifier = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/item-modifiers/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteItemModifierMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteItemModifier(id, options)
  }
}
export const getDeleteItemModifierMutationKey = (id: string) =>
  [`/v1/item-modifiers/${id}`] as const

export type DeleteItemModifierMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteItemModifier>>
>
export type DeleteItemModifierMutationError = ErrorType<void>

export const useDeleteItemModifier = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteItemModifier>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteItemModifier>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteItemModifierMutationKey(id)
  const swrFn = getDeleteItemModifierMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createProductRange = (
  createProductRangeDto: CreateProductRangeDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ProductRangeDto>(
    {
      url: `/v1/product-ranges`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createProductRangeDto,
    },
    options,
  )
}

export const getCreateProductRangeMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateProductRangeDto },
  ): Promise<ProductRangeDto> => {
    return createProductRange(arg, options)
  }
}
export const getCreateProductRangeMutationKey = () =>
  [`/v1/product-ranges`] as const

export type CreateProductRangeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createProductRange>>
>
export type CreateProductRangeMutationError = ErrorType<void>

export const useCreateProductRange = <TError = ErrorType<void>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createProductRange>>,
    TError,
    Key,
    CreateProductRangeDto,
    Awaited<ReturnType<typeof createProductRange>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateProductRangeMutationKey()
  const swrFn = getCreateProductRangeMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listProductRanges = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ProductRangeDto[]>(
    { url: `/v1/product-ranges`, method: 'GET' },
    options,
  )
}

export const getListProductRangesKey = () => [`/v1/product-ranges`] as const

export type ListProductRangesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProductRanges>>
>
export type ListProductRangesQueryError = ErrorType<unknown>

export const useListProductRanges = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listProductRanges>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListProductRangesKey() : null))
  const swrFn = () => listProductRanges(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getProductRange = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ProductRangeDto>(
    { url: `/v1/product-ranges/${id}`, method: 'GET' },
    options,
  )
}

export const getGetProductRangeKey = (id: string) =>
  [`/v1/product-ranges/${id}`] as const

export type GetProductRangeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductRange>>
>
export type GetProductRangeQueryError = ErrorType<void>

export const useGetProductRange = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getProductRange>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetProductRangeKey(id) : null))
  const swrFn = () => getProductRange(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateProductRange = (
  id: string,
  updateProductRangeDto: UpdateProductRangeDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ProductRangeDto>(
    {
      url: `/v1/product-ranges/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateProductRangeDto,
    },
    options,
  )
}

export const getUpdateProductRangeMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateProductRangeDto },
  ): Promise<ProductRangeDto> => {
    return updateProductRange(id, arg, options)
  }
}
export const getUpdateProductRangeMutationKey = (id: string) =>
  [`/v1/product-ranges/${id}`] as const

export type UpdateProductRangeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateProductRange>>
>
export type UpdateProductRangeMutationError = ErrorType<void>

export const useUpdateProductRange = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateProductRange>>,
      TError,
      Key,
      UpdateProductRangeDto,
      Awaited<ReturnType<typeof updateProductRange>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateProductRangeMutationKey(id)
  const swrFn = getUpdateProductRangeMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getProductRangeProducts = (
  id: string,
  params?: GetProductRangeProductsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreProductPageDto>(
    { url: `/v1/product-ranges/${id}/products`, method: 'GET', params },
    options,
  )
}

export const getGetProductRangeProductsKey = (
  id: string,
  params?: GetProductRangeProductsParams,
) => [`/v1/product-ranges/${id}/products`, ...(params ? [params] : [])] as const

export type GetProductRangeProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductRangeProducts>>
>
export type GetProductRangeProductsQueryError = ErrorType<void>

export const useGetProductRangeProducts = <TError = ErrorType<void>>(
  id: string,
  params?: GetProductRangeProductsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getProductRangeProducts>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetProductRangeProductsKey(id, params) : null))
  const swrFn = () => getProductRangeProducts(id, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createStoreProduct = (
  createStoreProductWrapperDto: CreateStoreProductWrapperDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreProductDto>(
    {
      url: `/v1/store-products`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createStoreProductWrapperDto,
    },
    options,
  )
}

export const getCreateStoreProductMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateStoreProductWrapperDto },
  ): Promise<StoreProductDto> => {
    return createStoreProduct(arg, options)
  }
}
export const getCreateStoreProductMutationKey = () =>
  [`/v1/store-products`] as const

export type CreateStoreProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStoreProduct>>
>
export type CreateStoreProductMutationError = ErrorType<CreateStoreProduct400>

export const useCreateStoreProduct = <
  TError = ErrorType<CreateStoreProduct400>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createStoreProduct>>,
    TError,
    Key,
    CreateStoreProductWrapperDto,
    Awaited<ReturnType<typeof createStoreProduct>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateStoreProductMutationKey()
  const swrFn = getCreateStoreProductMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listStoreProducts = (
  params?: ListStoreProductsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreProductPageDto>(
    { url: `/v1/store-products`, method: 'GET', params },
    options,
  )
}

export const getListStoreProductsKey = (params?: ListStoreProductsParams) =>
  [`/v1/store-products`, ...(params ? [params] : [])] as const

export type ListStoreProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listStoreProducts>>
>
export type ListStoreProductsQueryError = ErrorType<InvalidStoresError>

export const useListStoreProducts = <TError = ErrorType<InvalidStoresError>>(
  params?: ListStoreProductsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listStoreProducts>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListStoreProductsKey(params) : null))
  const swrFn = () => listStoreProducts(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
/**
 * Endpoint for fast full-text product search. This returns only what is indexed in Algolia and does not hit the database.
 */
export const searchStoreProducts = (
  params: SearchStoreProductsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreProductTypeaheadSearchPageDto>(
    { url: `/v1/store-products/search`, method: 'GET', params },
    options,
  )
}

export const getSearchStoreProductsKey = (params: SearchStoreProductsParams) =>
  [`/v1/store-products/search`, ...(params ? [params] : [])] as const

export type SearchStoreProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchStoreProducts>>
>
export type SearchStoreProductsQueryError = ErrorType<unknown>

export const useSearchStoreProducts = <TError = ErrorType<unknown>>(
  params: SearchStoreProductsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof searchStoreProducts>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getSearchStoreProductsKey(params) : null))
  const swrFn = () => searchStoreProducts(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateStoreProduct = (
  id: string,
  updateStoreProductWrapperDto: UpdateStoreProductWrapperDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreProductDto>(
    {
      url: `/v1/store-products/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateStoreProductWrapperDto,
    },
    options,
  )
}

export const getUpdateStoreProductMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateStoreProductWrapperDto },
  ): Promise<StoreProductDto> => {
    return updateStoreProduct(id, arg, options)
  }
}
export const getUpdateStoreProductMutationKey = (id: string) =>
  [`/v1/store-products/${id}`] as const

export type UpdateStoreProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStoreProduct>>
>
export type UpdateStoreProductMutationError =
  ErrorType<UpdateStoreProduct400 | void>

export const useUpdateStoreProduct = <
  TError = ErrorType<UpdateStoreProduct400 | void>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateStoreProduct>>,
      TError,
      Key,
      UpdateStoreProductWrapperDto,
      Awaited<ReturnType<typeof updateStoreProduct>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateStoreProductMutationKey(id)
  const swrFn = getUpdateStoreProductMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getStoreProduct = (
  id: string,
  params?: GetStoreProductParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreProductDto>(
    { url: `/v1/store-products/${id}`, method: 'GET', params },
    options,
  )
}

export const getGetStoreProductKey = (
  id: string,
  params?: GetStoreProductParams,
) => [`/v1/store-products/${id}`, ...(params ? [params] : [])] as const

export type GetStoreProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreProduct>>
>
export type GetStoreProductQueryError = ErrorType<void>

export const useGetStoreProduct = <TError = ErrorType<void>>(
  id: string,
  params?: GetStoreProductParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getStoreProduct>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetStoreProductKey(id, params) : null))
  const swrFn = () => getStoreProduct(id, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createStoreVendorProduct = (
  createStoreVendorProductDto: CreateStoreVendorProductDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    {
      url: `/v1/store-vendor-products`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createStoreVendorProductDto,
    },
    options,
  )
}

export const getCreateStoreVendorProductMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateStoreVendorProductDto },
  ): Promise<void> => {
    return createStoreVendorProduct(arg, options)
  }
}
export const getCreateStoreVendorProductMutationKey = () =>
  [`/v1/store-vendor-products`] as const

export type CreateStoreVendorProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStoreVendorProduct>>
>
export type CreateStoreVendorProductMutationError = ErrorType<unknown>

export const useCreateStoreVendorProduct = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createStoreVendorProduct>>,
    TError,
    Key,
    CreateStoreVendorProductDto,
    Awaited<ReturnType<typeof createStoreVendorProduct>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateStoreVendorProductMutationKey()
  const swrFn = getCreateStoreVendorProductMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listStoreVendorProducts = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/store-vendor-products`, method: 'GET' },
    options,
  )
}

export const getListStoreVendorProductsKey = () =>
  [`/v1/store-vendor-products`] as const

export type ListStoreVendorProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listStoreVendorProducts>>
>
export type ListStoreVendorProductsQueryError = ErrorType<unknown>

export const useListStoreVendorProducts = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listStoreVendorProducts>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListStoreVendorProductsKey() : null))
  const swrFn = () => listStoreVendorProducts(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getStoreVendorProduct = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<unknown>(
    { url: `/v1/store-vendor-products/${id}`, method: 'GET' },
    options,
  )
}

export const getGetStoreVendorProductKey = (id: string) =>
  [`/v1/store-vendor-products/${id}`] as const

export type GetStoreVendorProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreVendorProduct>>
>
export type GetStoreVendorProductQueryError = ErrorType<void>

export const useGetStoreVendorProduct = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getStoreVendorProduct>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetStoreVendorProductKey(id) : null))
  const swrFn = () => getStoreVendorProduct(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateStoreVendorProduct = (
  id: string,
  updateStoreVendorProductDto: UpdateStoreVendorProductDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<unknown>(
    {
      url: `/v1/store-vendor-products/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateStoreVendorProductDto,
    },
    options,
  )
}

export const getUpdateStoreVendorProductMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateStoreVendorProductDto },
  ): Promise<unknown> => {
    return updateStoreVendorProduct(id, arg, options)
  }
}
export const getUpdateStoreVendorProductMutationKey = (id: string) =>
  [`/v1/store-vendor-products/${id}`] as const

export type UpdateStoreVendorProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStoreVendorProduct>>
>
export type UpdateStoreVendorProductMutationError = ErrorType<void>

export const useUpdateStoreVendorProduct = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateStoreVendorProduct>>,
      TError,
      Key,
      UpdateStoreVendorProductDto,
      Awaited<ReturnType<typeof updateStoreVendorProduct>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdateStoreVendorProductMutationKey(id)
  const swrFn = getUpdateStoreVendorProductMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteStoreVendorProduct = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<unknown>(
    { url: `/v1/store-vendor-products/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteStoreVendorProductMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<unknown> => {
    return deleteStoreVendorProduct(id, options)
  }
}
export const getDeleteStoreVendorProductMutationKey = (id: string) =>
  [`/v1/store-vendor-products/${id}`] as const

export type DeleteStoreVendorProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteStoreVendorProduct>>
>
export type DeleteStoreVendorProductMutationError = ErrorType<void>

export const useDeleteStoreVendorProduct = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteStoreVendorProduct>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteStoreVendorProduct>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeleteStoreVendorProductMutationKey(id)
  const swrFn = getDeleteStoreVendorProductMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createVariableWeight = (
  createVariableWeightDto: CreateVariableWeightDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<VariableWeightDto>(
    {
      url: `/v1/variable-weights`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createVariableWeightDto,
    },
    options,
  )
}

export const getCreateVariableWeightMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateVariableWeightDto },
  ): Promise<VariableWeightDto> => {
    return createVariableWeight(arg, options)
  }
}
export const getCreateVariableWeightMutationKey = () =>
  [`/v1/variable-weights`] as const

export type CreateVariableWeightMutationResult = NonNullable<
  Awaited<ReturnType<typeof createVariableWeight>>
>
export type CreateVariableWeightMutationError = ErrorType<unknown>

export const useCreateVariableWeight = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createVariableWeight>>,
    TError,
    Key,
    CreateVariableWeightDto,
    Awaited<ReturnType<typeof createVariableWeight>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateVariableWeightMutationKey()
  const swrFn = getCreateVariableWeightMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listVariableWeight = (
  params: ListVariableWeightParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<VariableWeightDto[]>(
    { url: `/v1/variable-weights`, method: 'GET', params },
    options,
  )
}

export const getListVariableWeightKey = (params: ListVariableWeightParams) =>
  [`/v1/variable-weights`, ...(params ? [params] : [])] as const

export type ListVariableWeightQueryResult = NonNullable<
  Awaited<ReturnType<typeof listVariableWeight>>
>
export type ListVariableWeightQueryError = ErrorType<unknown>

export const useListVariableWeight = <TError = ErrorType<unknown>>(
  params: ListVariableWeightParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listVariableWeight>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListVariableWeightKey(params) : null))
  const swrFn = () => listVariableWeight(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const findOneVariableWeight = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<VariableWeightDto>(
    { url: `/v1/variable-weights/${id}`, method: 'GET' },
    options,
  )
}

export const getFindOneVariableWeightKey = (id: string) =>
  [`/v1/variable-weights/${id}`] as const

export type FindOneVariableWeightQueryResult = NonNullable<
  Awaited<ReturnType<typeof findOneVariableWeight>>
>
export type FindOneVariableWeightQueryError = ErrorType<void>

export const useFindOneVariableWeight = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof findOneVariableWeight>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getFindOneVariableWeightKey(id) : null))
  const swrFn = () => findOneVariableWeight(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateVariableWeight = (
  id: string,
  updateVariableWeightDto: UpdateVariableWeightDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<VariableWeightDto>(
    {
      url: `/v1/variable-weights/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateVariableWeightDto,
    },
    options,
  )
}

export const getUpdateVariableWeightMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateVariableWeightDto },
  ): Promise<VariableWeightDto> => {
    return updateVariableWeight(id, arg, options)
  }
}
export const getUpdateVariableWeightMutationKey = (id: string) =>
  [`/v1/variable-weights/${id}`] as const

export type UpdateVariableWeightMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateVariableWeight>>
>
export type UpdateVariableWeightMutationError = ErrorType<void>

export const useUpdateVariableWeight = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateVariableWeight>>,
      TError,
      Key,
      UpdateVariableWeightDto,
      Awaited<ReturnType<typeof updateVariableWeight>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateVariableWeightMutationKey(id)
  const swrFn = getUpdateVariableWeightMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteVariableWeight = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/variable-weights/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteVariableWeightMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteVariableWeight(id, options)
  }
}
export const getDeleteVariableWeightMutationKey = (id: string) =>
  [`/v1/variable-weights/${id}`] as const

export type DeleteVariableWeightMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteVariableWeight>>
>
export type DeleteVariableWeightMutationError = ErrorType<void>

export const useDeleteVariableWeight = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteVariableWeight>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteVariableWeight>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteVariableWeightMutationKey(id)
  const swrFn = getDeleteVariableWeightMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
