import { blackA, mauve, violet } from '@radix-ui/colors'
import * as Menubar from '@radix-ui/react-menubar'
import type * as Stitches from '@stitches/react'
import { css, styled } from '@stitches/react'
import { FC } from 'react'

export const MenubarRoot: FC<Menubar.MenubarProps> = styled(Menubar.Root, {
  display: 'flex',
  backgroundColor: 'white',
  padding: 3,
  borderRadius: 6,
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
})

export const MenubarMenu = Menubar.Menu
export const MenubarPortal: FC<Menubar.MenuPortalProps> = Menubar.Portal
export const MenubarSub = Menubar.Sub

export const MenubarTrigger: FC<Menubar.MenubarTriggerProps> = styled(
  Menubar.Trigger,
  {
    all: 'unset',
    padding: '8px 12px',
    outline: 'none',
    userSelect: 'none',
    fontWeight: 500,
    lineHeight: 1,
    borderRadius: 4,
    fontSize: 13,
    color: violet.violet11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,

    '&[data-highlighted], &[data-state="open"]': {
      backgroundColor: violet.violet4,
    },
  },
)

const contentStyles = css({
  minWidth: 220,
  backgroundColor: 'white',
  borderRadius: 6,
  padding: 5,
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: 20000,
})

export const MenubarContent: FC<
  CSSItemStyleVariants & Menubar.MenubarContentProps
> = styled(Menubar.Content, contentStyles)
export const MenubarSubContent: FC<
  CSSItemStyleVariants & Menubar.MenubarSubContentProps
> = styled(Menubar.SubContent, contentStyles)

const itemStyles = css({
  all: 'unset',
  fontSize: 13,
  lineHeight: 1,
  color: violet.violet11,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 10px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    backgroundImage: `linear-gradient(135deg, ${violet.violet9} 0%, ${violet.violet10} 100%)`,
    color: violet.violet1,
  },

  variants: {
    variant: {
      inset: {
        paddingLeft: 20,
      },
    },
  },
})

type CSSItemStyleVariants = Stitches.VariantProps<typeof itemStyles>

export const MenubarItem: FC<CSSItemStyleVariants & Menubar.MenubarItemProps> =
  styled(Menubar.Item, itemStyles)

export const MenubarSubTrigger: FC<
  CSSItemStyleVariants & Menubar.MenubarSubTriggerProps
> = styled(Menubar.SubTrigger, {
  '&[data-state="open"]': {
    backgroundColor: violet.violet4,
    color: violet.violet11,
  },
  ...itemStyles,
})

export const MenubarCheckboxItem: FC<
  CSSItemStyleVariants & Menubar.MenubarCheckboxItemProps
> = styled(Menubar.CheckboxItem, itemStyles)
export const MenubarRadioItem: FC<
  CSSItemStyleVariants & Menubar.MenubarRadioItemProps
> = styled(Menubar.RadioItem, itemStyles)

export const MenubarRadioGroup: FC<
  CSSItemStyleVariants & Menubar.MenubarRadioGroupProps
> = styled(Menubar.RadioGroup, itemStyles)

export const MenubarItemIndicator: FC<Menubar.MenubarItemIndicatorProps> =
  styled(Menubar.ItemIndicator, {
    position: 'absolute',
    left: 0,
    width: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

export const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: mauve.mauve9,
  '[data-highlighted] > &': { color: 'white' },
  '[data-disabled] &': { color: mauve.mauve8 },
})

export const MenubarSeparator: FC<Menubar.MenubarSeparatorProps> = styled(
  Menubar.Separator,
  {
    height: 1,
    backgroundColor: violet.violet6,
    margin: 5,
  },
)
