import * as CSS from 'csstype'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import {
  colors,
  ColorVariant,
  FontSize,
  base,
  LineHeight,
  typography,
} from '../tokens'

import toTransitions from '../utils/toTransitions'

type TextProps = React.HTMLAttributes<HTMLSpanElement> & {
  align?: CSS.Property.TextAlign
  bold?: boolean
  decoration?: CSS.Property.TextDecoration
  /**
   * @deprecated Use `notSemantic` instead.
   */
  noSemantic?: boolean
  /**
   * Renders the `<Text>` component as a plain `<span>` element, as opposed to
   * rendering a semantic element like `<p>`, `<h1>`, etc.
   */
  notSemantic?: boolean
  size?: FontSize
  transform?: CSS.Property.TextTransform
  truncate?: boolean
  variant?: ColorVariant
  whiteSpace?: CSS.Property.WhiteSpace
  wordBreak?: CSS.Property.WordBreak
  /**
   * Escape hatchet for bypassing !important rule for color
   */
  enableVariantOverride?: boolean
}

const defaultProps: Partial<TextProps> = {
  align: 'left',
  className: '',
  decoration: 'none',
  enableVariantOverride: false,
  noSemantic: false,
  notSemantic: false,
  size: 'display',
  transform: 'none',
  variant: 'default',
}

/**
 * @deprecated Use `<TextNext>`, `<Paragraph>` or `<Heading>` instead.
 */
const StyledText = styled(
  forwardRef<HTMLSpanElement, TextProps>(function Text(
    {
      align,
      bold,
      children,
      decoration,
      enableVariantOverride,
      noSemantic,
      notSemantic,
      size,
      transform,
      truncate,
      variant,
      whiteSpace,
      wordBreak,
      ...props
    }: TextProps,
    ref,
  ) {
    return (
      <span
        data-gourmet-text=""
        title={truncate && typeof children === 'string' ? children : ''}
        {...props}
        ref={ref}
      >
        {children}
      </span>
    )
  }),
).attrs<TextProps>(({ size, noSemantic, notSemantic }) =>
  noSemantic || notSemantic
    ? {}
    : {
        ...(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(size as string)
          ? { as: size }
          : size === 'body'
            ? { as: 'p' }
            : {}),
      },
)<TextProps>`
  color: ${({ variant = defaultProps.variant }): string =>
    colors.text[variant as ColorVariant]};
  font-size: ${({ size = defaultProps.size }): string =>
    typography.text[size as FontSize].fontSize};
  font-style: normal;
  font-weight: ${({ bold, size = defaultProps.size }): string =>
    bold
      ? base.typography.fontWeights.bold
      : bold === false
        ? base.typography.fontWeights.normal
        : typography.text[size as FontSize].fontWeight};
  line-height: ${({ size = defaultProps.size }): string =>
    typography.text[size as LineHeight].lineHeight};
  text-transform: ${({ transform = defaultProps.transform }): string =>
    transform ? transform : 'none'};
  text-align: ${({ align = defaultProps.align }): CSS.Property.TextAlign =>
    align as CSS.Property.TextAlign};
  text-decoration: ${({
    decoration = defaultProps.decoration,
  }): CSS.Property.TextDecoration => decoration as CSS.Property.TextAlign};
  transition: ${toTransitions(['color'], 'ease')};
  margin: 0;

  ${({ wordBreak }): string => (wordBreak ? `word-break: ${wordBreak};` : '')}

  ${({ whiteSpace }): string =>
    whiteSpace ? `white-space: ${whiteSpace};` : ''}

  ${({ truncate }): string =>
    truncate
      ? `
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `
      : ''}

  ${({ enableVariantOverride, variant = defaultProps.variant }): string =>
    variant !== 'default' && !enableVariantOverride
      ? `&& { color: ${colors.text[variant as ColorVariant]} !important; }`
      : ''}
`

StyledText.displayName = 'Text'
StyledText.defaultProps = defaultProps

export type { TextProps }
export { defaultProps as TextDefaultProps }
export default StyledText
