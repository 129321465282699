import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FaceSadIconProps = IconProps

const FaceSadIcon = forwardRef<SVGSVGElement, FaceSadIconProps>(
  (props: FaceSadIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 16s-1.5-2-4-2-4 2-4 2m9-6.76c-.395.485-.935.76-1.5.76s-1.09-.275-1.5-.76m-4 0c-.395.485-.935.76-1.5.76S7.41 9.725 7 9.24M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

FaceSadIcon.displayName = 'FaceSadIcon'
FaceSadIcon.defaultProps = iconDefaultProps

export type { FaceSadIconProps }
export { FaceSadIcon }
