import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Flag01IconProps = IconProps

const Flag01Icon = forwardRef<SVGSVGElement, Flag01IconProps>(
  (props: Flag01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1v19"
        fill="none"
      ></path>
    </Icon>
  ),
)

Flag01Icon.displayName = 'Flag01Icon'
Flag01Icon.defaultProps = iconDefaultProps

export type { Flag01IconProps }
export { Flag01Icon }
