import React from 'react'

type HookOptions<T> = {
  /**
   * The value that will be returned with the given `delay`.
   */
  value: T
  /**
   * The number of milliseconds to `delay` the `value`.
   */
  delay?: number
}

/**
 * Debounced value.
 */
type HookReturn<T> = T

/**
 * Returns the given `value` after the given `delay`.
 */
function useDebouncedValue<T>(
  value: HookOptions<T>['value'],
  delay: HookOptions<T>['delay'] = 0,
): HookReturn<T> {
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const timer = timerRef.current

    if (timerRef.current !== null) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [delay, value])

  return debouncedValue
}

export type { HookOptions, HookReturn }
export default useDebouncedValue
