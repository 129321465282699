import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ZoomInIconProps = IconProps

const ZoomInIcon = forwardRef<SVGSVGElement, ZoomInIconProps>(
  (props: ZoomInIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m21 21-4.35-4.35M11 8v6m-3-3h6m5 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ZoomInIcon.displayName = 'ZoomInIcon'
ZoomInIcon.defaultProps = iconDefaultProps

export type { ZoomInIconProps }
export { ZoomInIcon }
