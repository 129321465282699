import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GitPullRequestIconProps = IconProps

const GitPullRequestIcon = forwardRef<SVGSVGElement, GitPullRequestIconProps>(
  (props: GitPullRequestIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 15a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 0V8a2 2 0 0 0-2-2h-3M6 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 0v12"
        fill="none"
      ></path>
    </Icon>
  ),
)

GitPullRequestIcon.displayName = 'GitPullRequestIcon'
GitPullRequestIcon.defaultProps = iconDefaultProps

export type { GitPullRequestIconProps }
export { GitPullRequestIcon }
