import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Contrast02IconProps = IconProps

const Contrast02Icon = forwardRef<SVGSVGElement, Contrast02IconProps>(
  (props: Contrast02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18.5a6.5 6.5 0 1 0 0-13v13Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Contrast02Icon.displayName = 'Contrast02Icon'
Contrast02Icon.defaultProps = iconDefaultProps

export type { Contrast02IconProps }
export { Contrast02Icon }
