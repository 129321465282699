import React from 'react'
import styled from 'styled-components'

import { Building02Icon } from '@vori/gourmet-icons'
import { foundations } from '@vori/gourmet-tokens'

import {
  FlexNext as Flex,
  Loader,
  Select,
  TextNext as Text,
} from '@vori/gourmet-components'

import {
  useCurrentUserDispatch,
  useCurrentUserState,
} from '@vori/dashboard-hooks/useCurrentUser'

import { useStores } from '@vori/dashboard-hooks/entities'

const SelectContainer = styled(Flex)({
  '&&&& [data-gourmet-button]': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: foundations.color['color.base-white'],
    padding: 0,
  },

  [`${Text}`]: {
    color: foundations.color['color.base-white'],
  },
})

export function StoreSelector(): JSX.Element | null {
  const { loading, stores } = useStores()
  const { user } = useCurrentUserState()
  const dispatchUserEvent = useCurrentUserDispatch()

  if (loading) {
    return (
      <SelectContainer centerY columnGap="space.025">
        <Loader size="tiny" variant="secondary" />
        <Text>Loading Stores...</Text>
      </SelectContainer>
    )
  }

  if (!stores.length) {
    return null
  }

  if (stores.length === 1) {
    return (
      <SelectContainer centerY columnGap="space.025">
        <Building02Icon />
        <Text>{stores[0].name}</Text>
      </SelectContainer>
    )
  }

  return (
    <SelectContainer centerY columnGap="space.025">
      <Flex shrink={0}>
        <Building02Icon />
      </Flex>
      <Select
        fullWidth
        label="Select a Store"
        getLabelForTrigger={(_, { originalOptionSelected }) =>
          originalOptionSelected?.name || 'Your store'
        }
        options={stores}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ serialID, id }) => serialID || id}
        getOriginalOption={(optionValue) =>
          stores.find(
            ({ serialID, id }) =>
              serialID === optionValue || id === optionValue,
          ) || null
        }
        selectedOption={user.metadata.selectedStoreID || null}
        onChange={({ originalOptionSelected }) => {
          if (!originalOptionSelected) {
            return
          }

          dispatchUserEvent({
            type: 'userV2/storeSelected',
            payload: {
              selectedStoreID:
                originalOptionSelected.serialID || originalOptionSelected.id,
              selectedStoreName: originalOptionSelected.name,
              selectedStoreTimeZone:
                originalOptionSelected.metadata?.local_iana_timezone || '',
            },
          })
        }}
        popupProps={{
          size: 'small',
        }}
      />
    </SelectContainer>
  )
}
