import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ThermometerColdIconProps = IconProps

const ThermometerColdIcon = forwardRef<SVGSVGElement, ThermometerColdIconProps>(
  (props: ThermometerColdIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 12h10M9 4v16M3 9l3 3-3 3m9-9L9 9 6 6m0 12 3-3 1.5 1.5m9.5-1.965V4a2 2 0 1 0-4 0v10.535a4 4 0 1 0 4 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ThermometerColdIcon.displayName = 'ThermometerColdIcon'
ThermometerColdIcon.defaultProps = iconDefaultProps

export type { ThermometerColdIconProps }
export { ThermometerColdIcon }
