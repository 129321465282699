import { toRem } from '../utils'

const DotIndicatorTokens = {
  color: {
    root: {
      default: {
        backgroundColor: '#70707B',
      },
      negative: {
        backgroundColor: '#F0374A',
      },
      positive: {
        backgroundColor: '#12B76A',
      },
      primary: {
        backgroundColor: '#8A70FF',
      },
      warning: {
        backgroundColor: '#EABD08',
      },
    },
  },

  sizing: {
    root: {
      borderRadius: '50%',
      height: toRem(6),
      width: toRem(6),
    },
  },
} as const

export { DotIndicatorTokens }
