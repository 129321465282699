import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type NotificationBoxIconProps = IconProps

const NotificationBoxIcon = forwardRef<SVGSVGElement, NotificationBoxIconProps>(
  (props: NotificationBoxIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 4H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 6.28 3 7.12 3 8.8v7.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21h7.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 18.72 20 17.88 20 16.2V13m.121-9.121A3 3 0 1 1 15.88 8.12a3 3 0 0 1 4.24-4.24Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

NotificationBoxIcon.displayName = 'NotificationBoxIcon'
NotificationBoxIcon.defaultProps = iconDefaultProps

export type { NotificationBoxIconProps }
export { NotificationBoxIcon }
