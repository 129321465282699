import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowNarrowDownRightIconProps = IconProps

const ArrowNarrowDownRightIcon = forwardRef<
  SVGSVGElement,
  ArrowNarrowDownRightIconProps
>((props: ArrowNarrowDownRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m6 6 12 12m0 0v-8m0 8h-8"
      fill="none"
    ></path>
  </Icon>
))

ArrowNarrowDownRightIcon.displayName = 'ArrowNarrowDownRightIcon'
ArrowNarrowDownRightIcon.defaultProps = iconDefaultProps

export type { ArrowNarrowDownRightIconProps }
export { ArrowNarrowDownRightIcon }
