import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TrendDown02IconProps = IconProps

const TrendDown02Icon = forwardRef<SVGSVGElement, TrendDown02IconProps>(
  (props: TrendDown02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m7 7 10 10m0 0V7m0 10H7"
        fill="none"
      ></path>
    </Icon>
  ),
)

TrendDown02Icon.displayName = 'TrendDown02Icon'
TrendDown02Icon.defaultProps = iconDefaultProps

export type { TrendDown02IconProps }
export { TrendDown02Icon }
