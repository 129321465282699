import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TrendDown01IconProps = IconProps

const TrendDown01Icon = forwardRef<SVGSVGElement, TrendDown01IconProps>(
  (props: TrendDown01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m22 17-7.869-7.869c-.396-.396-.594-.594-.822-.668a1 1 0 0 0-.618 0c-.228.074-.426.272-.822.668L9.13 11.87c-.396.396-.594.594-.822.668a1 1 0 0 1-.618 0c-.228-.074-.426-.272-.822-.668L2 7m20 10h-7m7 0v-7"
        fill="none"
      ></path>
    </Icon>
  ),
)

TrendDown01Icon.displayName = 'TrendDown01Icon'
TrendDown01Icon.defaultProps = iconDefaultProps

export type { TrendDown01IconProps }
export { TrendDown01Icon }
