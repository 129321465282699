import React from 'react'
import styled, { CSSObject } from 'styled-components'
import { IconProps, CheckCircleIcon } from '@vori/gourmet-icons'

import Flex from '../Flex'
import Spacer from '../Spacer'

import { TimelineTokens } from './tokens'

function styles(): CSSObject {
  return {
    display: 'flex',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',

    '[data-gourmet-timeline-entry-icon]': {
      backgroundColor: TimelineTokens.colors.timelineEntryIcon.backgroundColor,
      borderRadius: TimelineTokens.sizing.timelineEntryIcon.borderRadius,
      color: TimelineTokens.colors.timelineEntryIcon.color,
      minHeight: TimelineTokens.sizing.timelineEntryIcon.minHeight,
      minWidth: TimelineTokens.sizing.timelineEntryIcon.minWidth,
    },

    '[data-gourmet-timeline-entry-icon]::after': {
      backgroundColor: TimelineTokens.colors.timelineEntryTrack.backgroundColor,
      borderRadius: TimelineTokens.sizing.timelineEntryTrack.borderRadius,
      content: '""',
      height: `calc(100% - (${TimelineTokens.sizing.timelineEntryIcon.minHeight} + (${TimelineTokens.spacing.timelineEntryTrack.top} * 2)))`,
      left: `calc((${TimelineTokens.sizing.timelineEntryIcon.minWidth} - ${TimelineTokens.sizing.timelineEntryTrack.width}) / 2)`,
      position: 'absolute',
      top: `calc(${TimelineTokens.sizing.timelineEntryIcon.minHeight} + ${TimelineTokens.spacing.timelineEntryTrack.top})`,
      width: TimelineTokens.sizing.timelineEntryTrack.width,
    },

    '&:last-of-type [data-gourmet-timeline-entry-icon]::after': {
      display: 'none',
    },

    '[data-gourmet-card]': {
      borderRadius: TimelineTokens.sizing.timelineEntryCard.borderRadius,
      paddingBottom: TimelineTokens.spacing.timelineEntryCard.paddingY,
      paddingLeft: TimelineTokens.spacing.timelineEntryCard.paddingX,
      paddingRight: TimelineTokens.spacing.timelineEntryCard.paddingX,
      paddingTop: TimelineTokens.spacing.timelineEntryCard.paddingY,
    },
  }
}

const ListItem = styled.li(styles)

const TimelineEntryContent = styled(Flex)({
  paddingTop: TimelineTokens.spacing.timelineEntryContent.paddingTop,
  minHeight: TimelineTokens.sizing.timelineEntryContent.minHeight,
})

type Props = React.HTMLAttributes<HTMLLIElement> & {
  /**
   * An icon associated with the entry. When not specified, the `<CheckCircleIcon>`
   * component will be used as the default.
   */
  icon?: React.ReactElement
}

const TimelineEntry = React.forwardRef<
  HTMLLIElement,
  React.PropsWithChildren<Props>
>(function TimelineEntry(
  { children, icon, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  return (
    <ListItem {...props} data-gourmet-timeline-entry ref={ref}>
      <Flex column role="presentation">
        <Flex center data-gourmet-timeline-entry-icon shrink={0}>
          {icon ? (
            React.cloneElement<IconProps>(icon, { size: 'medium' }, null)
          ) : (
            <CheckCircleIcon />
          )}
        </Flex>
      </Flex>
      <Spacer inline size="medium" />
      <TimelineEntryContent column fullWidth>
        {children}
        <Spacer />
      </TimelineEntryContent>
    </ListItem>
  )
})

TimelineEntry.displayName = 'TimelineEntry'
TimelineEntry.defaultProps = {}

export { TimelineEntry, styles as TimelineEntryStyles }
export type { Props as TimelineEntryProps }
