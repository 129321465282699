import { useEffect, useRef } from 'react'

/**
 * A boolean flag to determine if a component is currentlty mounted.
 */
type HookReturn = boolean

/**
 * Returns a boolean flag to determine if a component is currentlty mounted.
 */
function useIsMounted(): HookReturn {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted.current
}

export type { HookReturn }
export default useIsMounted
