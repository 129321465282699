import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MarkerPin05IconProps = IconProps

const MarkerPin05Icon = forwardRef<SVGSVGElement, MarkerPin05IconProps>(
  (props: MarkerPin05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 13.374c3.532.695 6 2.28 6 4.126 0 2.485-4.477 4.5-10 4.5S2 19.985 2 17.5c0-1.845 2.468-3.431 6-4.126M12 17V3l5.318 3.272c.388.24.581.358.643.509a.5.5 0 0 1-.011.407c-.07.146-.271.254-.672.47L12 10.5"
        fill="none"
      ></path>
    </Icon>
  ),
)

MarkerPin05Icon.displayName = 'MarkerPin05Icon'
MarkerPin05Icon.defaultProps = iconDefaultProps

export type { MarkerPin05IconProps }
export { MarkerPin05Icon }
