import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LineChartUp05IconProps = IconProps

const LineChartUp05Icon = forwardRef<SVGSVGElement, LineChartUp05IconProps>(
  (props: LineChartUp05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18 10-3.434 3.434c-.198.198-.297.297-.412.334a.499.499 0 0 1-.309 0c-.114-.037-.213-.136-.41-.334l-2.87-2.868c-.197-.198-.296-.297-.41-.334a.499.499 0 0 0-.31 0c-.114.037-.213.136-.41.334L6 14m16-2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LineChartUp05Icon.displayName = 'LineChartUp05Icon'
LineChartUp05Icon.defaultProps = iconDefaultProps

export type { LineChartUp05IconProps }
export { LineChartUp05Icon }
