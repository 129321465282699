import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyRubleCircleIconProps = IconProps

const CurrencyRubleCircleIcon = forwardRef<
  SVGSVGElement,
  CurrencyRubleCircleIconProps
>((props: CurrencyRubleCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.5 6.5H14a2.5 2.5 0 0 1 0 5H9.5v-5Zm0 0v11m.25-6H8m5 3.25H8M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

CurrencyRubleCircleIcon.displayName = 'CurrencyRubleCircleIcon'
CurrencyRubleCircleIcon.defaultProps = iconDefaultProps

export type { CurrencyRubleCircleIconProps }
export { CurrencyRubleCircleIcon }
