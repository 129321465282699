import { remToEm, remToPx } from '../utils'
import { TokensWithMetadataMap } from '../types'

export const TOKEN_SPACE_BASE_UNIT = 16 as const

export const tokens = {
  /**
   * Space based on a base unit multiplier of 0 — Equivalent to 0px.
   */
  'space.0': '0rem',
  /**
   * Space based on a base unit multiplier of 0.25 — Equivalent to 4px.
   */
  'space.025': '0.25rem',
  /**
   * Space based on a base unit multiplier of 0.50 — Equivalent to 8px.
   */
  'space.050': '0.50rem',
  /**
   * Space based on a base unit multiplier of 0.75 — Equivalent to 12px.
   */
  'space.075': '0.75rem',
  /**
   * Space based on a base unit multiplier of 1 — Equivalent to 16px.
   */
  'space.100': '1rem',
  /**
   * Space based on a base unit multiplier of 1.25 — Equivalent to 20px.
   */
  'space.125': '1.25rem',
  /**
   * Space based on a base unit multiplier of 1.50 — Equivalent to 24px.
   */
  'space.150': '1.50rem',
  /**
   * Space based on a base unit multiplier of 2 — Equivalent to 32px.
   */
  'space.200': '2rem',
  /**
   * Space based on a base unit multiplier of 2.50 — Equivalent to 40px.
   */
  'space.250': '2.50rem',
  /**
   * Space based on a base unit multiplier of 3 — Equivalent to 48px.
   */
  'space.300': '3rem',
  /**
   * Space based on a base unit multiplier of 4 — Equivalent to 64px.
   */
  'space.400': '4rem',
  /**
   * Space based on a base unit multiplier of 5 — Equivalent to 80px.
   */
  'space.500': '5rem',
  /**
   * Space based on a base unit multiplier of 6 — Equivalent to 96px.
   */
  'space.600': '6rem',
  /**
   * Space based on a base unit multiplier of 8 — Equivalent to 128px.
   */
  'space.800': '8rem',
  /**
   * Space based on a base unit multiplier of 10 — Equivalent to 160px.
   */
  'space.1000': '10rem',
  /**
   * Space based on a base unit multiplier of 12 — Equivalent to 192px.
   */
  'space.1200': '12rem',
  /**
   * Space based on a base unit multiplier of 14 — Equivalent to 224px.
   */
  'space.1400': '14rem',
  /**
   * Space based on a base unit multiplier of 16 — Equivalent to 256px.
   */
  'space.1600': '16rem',
} as const

export const tokensWithMetadata: TokensWithMetadataMap<'space', typeof tokens> =
  {
    'space.0': {
      description:
        'Space based on a base unit multiplier of 0 — Equivalent to 0px.',
      introducedIn: 'v0.0.1',
      name: 'space.0',
      type: 'space',
      value: tokens['space.0'],
      metadata: {
        baseUnitMultiplier: 0,
        em: remToEm(tokens['space.0']),
        px: remToPx(tokens['space.0'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.0'],
      },
    },
    'space.025': {
      description:
        'Space based on a base unit multiplier of 0.25 — Equivalent to 4px.',
      introducedIn: 'v0.0.1',
      name: 'space.025',
      type: 'space',
      value: tokens['space.025'],
      metadata: {
        baseUnitMultiplier: 0.25,
        em: remToEm(tokens['space.025']),
        px: remToPx(tokens['space.025'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.025'],
      },
    },
    'space.050': {
      description:
        'Space based on a base unit multiplier of 0.50 — Equivalent to 8px.',
      introducedIn: 'v0.0.1',
      name: 'space.050',
      type: 'space',
      value: tokens['space.050'],
      metadata: {
        baseUnitMultiplier: 0.5,
        em: remToEm(tokens['space.050']),
        px: remToPx(tokens['space.050'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.050'],
      },
    },
    'space.075': {
      description:
        'Space based on a base unit multiplier of 0.75 — Equivalent to 12px.',
      introducedIn: 'v0.0.1',
      name: 'space.075',
      type: 'space',
      value: tokens['space.075'],
      metadata: {
        baseUnitMultiplier: 0.75,
        em: remToEm(tokens['space.075']),
        px: remToPx(tokens['space.075'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.075'],
      },
    },
    'space.100': {
      description:
        'Space based on a base unit multiplier of 1 — Equivalent to 16px.',
      introducedIn: 'v0.0.1',
      name: 'space.100',
      type: 'space',
      value: tokens['space.100'],
      metadata: {
        baseUnitMultiplier: 1,
        em: remToEm(tokens['space.100']),
        px: remToPx(tokens['space.100'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.100'],
      },
    },
    'space.125': {
      description:
        'Space based on a base unit multiplier of 1.25 — Equivalent to 20px.',
      introducedIn: 'v0.0.1',
      name: 'space.125',
      type: 'space',
      value: tokens['space.125'],
      metadata: {
        baseUnitMultiplier: 1.25,
        em: remToEm(tokens['space.125']),
        px: remToPx(tokens['space.125'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.125'],
      },
    },
    'space.150': {
      description:
        'Space based on a base unit multiplier of 1.50 — Equivalent to 24px.',
      introducedIn: 'v0.0.1',
      name: 'space.150',
      type: 'space',
      value: tokens['space.150'],
      metadata: {
        baseUnitMultiplier: 1.5,
        em: remToEm(tokens['space.150']),
        px: remToPx(tokens['space.150'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.150'],
      },
    },
    'space.200': {
      description:
        'Space based on a base unit multiplier of 2 — Equivalent to 32px.',
      introducedIn: 'v0.0.1',
      name: 'space.200',
      type: 'space',
      value: tokens['space.200'],
      metadata: {
        baseUnitMultiplier: 2,
        em: remToEm(tokens['space.200']),
        px: remToPx(tokens['space.200'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.200'],
      },
    },
    'space.250': {
      description:
        'Space based on a base unit multiplier of 2.50 — Equivalent to 40px.',
      introducedIn: 'v0.0.1',
      name: 'space.250',
      type: 'space',
      value: tokens['space.250'],
      metadata: {
        baseUnitMultiplier: 2.5,
        em: remToEm(tokens['space.250']),
        px: remToPx(tokens['space.250'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.250'],
      },
    },
    'space.300': {
      description:
        'Space based on a base unit multiplier of 3 — Equivalent to 48px.',
      introducedIn: 'v0.0.1',
      name: 'space.300',
      type: 'space',
      value: tokens['space.300'],
      metadata: {
        baseUnitMultiplier: 3,
        em: remToEm(tokens['space.300']),
        px: remToPx(tokens['space.300'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.300'],
      },
    },
    'space.400': {
      description:
        'Space based on a base unit multiplier of 4 — Equivalent to 64px.',
      introducedIn: 'v0.0.1',
      name: 'space.400',
      type: 'space',
      value: tokens['space.400'],
      metadata: {
        baseUnitMultiplier: 4,
        em: remToEm(tokens['space.400']),
        px: remToPx(tokens['space.400'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.400'],
      },
    },
    'space.500': {
      description:
        'Space based on a base unit multiplier of 5 — Equivalent to 80px.',
      introducedIn: 'v0.0.1',
      name: 'space.500',
      type: 'space',
      value: tokens['space.500'],
      metadata: {
        baseUnitMultiplier: 5,
        em: remToEm(tokens['space.500']),
        px: remToPx(tokens['space.500'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.500'],
      },
    },
    'space.600': {
      description:
        'Space based on a base unit multiplier of 6 — Equivalent to 96px.',
      introducedIn: 'v0.0.1',
      name: 'space.600',
      type: 'space',
      value: tokens['space.600'],
      metadata: {
        baseUnitMultiplier: 6,
        em: remToEm(tokens['space.600']),
        px: remToPx(tokens['space.600'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.600'],
      },
    },
    'space.800': {
      description:
        'Space based on a base unit multiplier of 8 — Equivalent to 128px.',
      introducedIn: 'v0.0.1',
      name: 'space.800',
      type: 'space',
      value: tokens['space.800'],
      metadata: {
        baseUnitMultiplier: 8,
        em: remToEm(tokens['space.800']),
        px: remToPx(tokens['space.800'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.800'],
      },
    },
    'space.1000': {
      description:
        'Space based on a base unit multiplier of 10 — Equivalent to 160px.',
      introducedIn: 'v0.0.1',
      name: 'space.1000',
      type: 'space',
      value: tokens['space.1000'],
      metadata: {
        baseUnitMultiplier: 10,
        em: remToEm(tokens['space.1000']),
        px: remToPx(tokens['space.1000'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.1000'],
      },
    },
    'space.1200': {
      description:
        'Space based on a base unit multiplier of 12 — Equivalent to 192px.',
      introducedIn: 'v0.0.1',
      name: 'space.1200',
      type: 'space',
      value: tokens['space.1200'],
      metadata: {
        baseUnitMultiplier: 12,
        em: remToEm(tokens['space.1200']),
        px: remToPx(tokens['space.1200'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.1200'],
      },
    },
    'space.1400': {
      description:
        'Space based on a base unit multiplier of 14 — Equivalent to 224px.',
      introducedIn: 'v0.0.1',
      name: 'space.1400',
      type: 'space',
      value: tokens['space.1400'],
      metadata: {
        baseUnitMultiplier: 14,
        em: remToEm(tokens['space.1400']),
        px: remToPx(tokens['space.1400'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.1400'],
      },
    },
    'space.1600': {
      description:
        'Space based on a base unit multiplier of 16 — Equivalent to 256px.',
      introducedIn: 'v0.0.1',
      name: 'space.1600',
      type: 'space',
      value: tokens['space.1600'],
      metadata: {
        baseUnitMultiplier: 16,
        em: remToEm(tokens['space.1600']),
        px: remToPx(tokens['space.1600'], TOKEN_SPACE_BASE_UNIT),
        rem: tokens['space.1600'],
      },
    },
  } as const

export type TokenFoundationSpace = keyof typeof tokens
