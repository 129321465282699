/**
 * Used to get the next radio input element to be focused based on the direction
 * of the keyboard-based navigation and the currently selected and focused
 * radio input.
 */
export function getNextElementToFocus(
  elements: Array<HTMLInputElement>,
  direction: 'left' | 'right',
): HTMLInputElement {
  let nextElement: HTMLInputElement

  const index = elements.findIndex((element) => element.checked) || 0

  const getNextIndex = (currentIndex: number): number =>
    direction === 'left' ? currentIndex - 1 : currentIndex + 1

  if (index !== -1) {
    nextElement = elements[getNextIndex(index)]
  } else {
    nextElement = elements.find((element) => element.checked) || elements[0]
  }

  if (!nextElement) {
    nextElement = direction === 'left' ? elements.slice(-1)[0] : elements[0]
  }

  return nextElement.disabled && elements.length > 1
    ? getNextElementToFocus(elements, direction)
    : nextElement
}
