import React from 'react'

import { ModalFooter, ModalFooterProps } from '../ModalNext'

type Props = ModalFooterProps

/**
 * Provides basic styling for rendering the footer of a `<AlertModal>` component.
 *
 * @example
 * function ExampleAlertModal(): React.ReactElement {
 *   const { ref, ...alertModal } = useAlertModal()
 *
 *   return (
 *      <AlertModal ref={ref} {...alertModal}>
 *        <AlertModalContent>
 *          <Text>This is the modal content</Text>
 *          <AlertModalFooter>
 *            <AlertModalCancelButton>
 *              Cancel
 *            </AlertModalCancelButton>
 *            <AlertModalConfirmButton>
 *              Save
 *            </AlertModalConfirmButton>
 *          </AlertModalFooter>
 *        </AlertModalContent>
 *      </AlertModal>
 *   )
 * }
 */
const AlertModalFooter = React.forwardRef<HTMLDivElement, Props>(
  function AlertModalFooter(props: Props, ref): JSX.Element {
    return (
      <ModalFooter {...props} ref={ref} data-gourmet-alert-modal-footer="" />
    )
  },
)

AlertModalFooter.displayName = 'AlertModalFooter'
AlertModalFooter.defaultProps = {}

export { AlertModalFooter }
export type { Props as AlertModalFooterProps }
