import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ActivityIconProps = IconProps

const ActivityIcon = forwardRef<SVGSVGElement, ActivityIconProps>(
  (props: ActivityIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12h-4l-3 9L9 3l-3 9H2"
        fill="none"
      ></path>
    </Icon>
  ),
)

ActivityIcon.displayName = 'ActivityIcon'
ActivityIcon.defaultProps = iconDefaultProps

export type { ActivityIconProps }
export { ActivityIcon }
