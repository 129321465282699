import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Target01IconProps = IconProps

const Target01Icon = forwardRef<SVGSVGElement, Target01IconProps>(
  (props: Target01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12c0 5.523-4.477 10-10 10m10-10c0-5.523-4.477-10-10-10m10 10h-4m-6 10C6.477 22 2 17.523 2 12m10 10v-4M2 12C2 6.477 6.477 2 12 2M2 12h4m6-10v4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Target01Icon.displayName = 'Target01Icon'
Target01Icon.defaultProps = iconDefaultProps

export type { Target01IconProps }
export { Target01Icon }
