import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CodeCircle03IconProps = IconProps

const CodeCircle03Icon = forwardRef<SVGSVGElement, CodeCircle03IconProps>(
  (props: CodeCircle03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m15.5 15 3-3-3-3m-7 0-3 3 3 3M13 7l-2 10m11-5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CodeCircle03Icon.displayName = 'CodeCircle03Icon'
CodeCircle03Icon.defaultProps = iconDefaultProps

export type { CodeCircle03IconProps }
export { CodeCircle03Icon }
