import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyEthereumIconProps = IconProps

const CurrencyEthereumIcon = forwardRef<
  SVGSVGElement,
  CurrencyEthereumIconProps
>((props: CurrencyEthereumIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m4 11 8 2 8-2M4 11l8-9m-8 9 8-2m8 2-8-9m8 9-8-2m0-7v7m-6.5 6 6.5 7 6.5-7-6.5 1.5L5.5 15Z"
      fill="none"
    ></path>
  </Icon>
))

CurrencyEthereumIcon.displayName = 'CurrencyEthereumIcon'
CurrencyEthereumIcon.defaultProps = iconDefaultProps

export type { CurrencyEthereumIconProps }
export { CurrencyEthereumIcon }
