import React from 'react'
import styled from 'styled-components'

import { GridCoreProps, GridProps } from './types'
import { gridStyles } from './styles'
import { propsToStyledProps } from '../utils'

const StyledGrid = styled.div(gridStyles)
const defaultProps: Partial<GridCoreProps> = {}

/**
 * The `<Grid>` component exposes CSS properties of the grid layout module,
 * e.g. `grid-template`, `column-gap`, etc., as React props. Use this component
 * to build two-dimensional (horizontal and/or vertical) layouts.
 *
 * @example
 * <Grid templateColumns="1fr 1fr" fullWidth>
 *   <GridItem>Item 1</GridItem>
 *   <GridItem>Item 2</GridItem>
 * </Grid>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout}
 */
const Grid = React.forwardRef<HTMLDivElement, GridProps>(function Grid(
  {
    alignItems,
    alignSelf,
    autoColumns,
    autoFlow,
    autoRows,
    children,
    columnGap,
    fullHeight,
    fullWidth,
    gap,
    grid,
    justifyContent,
    rowGap,
    size,
    template,
    templateAreas,
    templateColumns,
    templateRows,
    ...props
  }: GridProps,
  ref,
): JSX.Element {
  return (
    <StyledGrid
      {...propsToStyledProps({
        alignItems,
        alignSelf,
        autoColumns,
        autoFlow,
        autoRows,
        columnGap,
        fullHeight,
        fullWidth,
        gap,
        grid,
        justifyContent,
        rowGap,
        size,
        template,
        templateAreas,
        templateColumns,
        templateRows,
      })}
      {...props}
      data-gourmet-grid=""
      ref={ref}
    >
      {children}
    </StyledGrid>
  )
})

Grid.displayName = 'Grid'
Grid.defaultProps = defaultProps

export { Grid, defaultProps as gridDefaultProps }
