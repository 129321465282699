import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SunIconProps = IconProps

const SunIcon = forwardRef<SVGSVGElement, SunIconProps>(
  (props: SunIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2v2m0 16v2M4 12H2m4.314-5.686L4.9 4.9m12.786 1.414L19.1 4.9M6.314 17.69 4.9 19.104m12.786-1.414 1.414 1.414M22 12h-2m-3 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

SunIcon.displayName = 'SunIcon'
SunIcon.defaultProps = iconDefaultProps

export type { SunIconProps }
export { SunIcon }
