import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Headphones01IconProps = IconProps

const Headphones01Icon = forwardRef<SVGSVGElement, Headphones01IconProps>(
  (props: Headphones01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 18v-6a9 9 0 1 0-18 0v6m2.5 3A2.5 2.5 0 0 1 3 18.5v-2a2.5 2.5 0 0 1 5 0v2A2.5 2.5 0 0 1 5.5 21Zm13 0a2.5 2.5 0 0 1-2.5-2.5v-2a2.5 2.5 0 0 1 5 0v2a2.5 2.5 0 0 1-2.5 2.5Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Headphones01Icon.displayName = 'Headphones01Icon'
Headphones01Icon.defaultProps = iconDefaultProps

export type { Headphones01IconProps }
export { Headphones01Icon }
