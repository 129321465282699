import { createRouteUtil } from '@vori/dashboard-utils'

const routes = {
  metrics: {
    root: createRouteUtil('/dev/metrics', 'Metrics'),
  },

  users: {
    /**
     * Lists all users.
     */
    root: createRouteUtil('/dev/users', 'Users'),
    /**
     * Displays the create user form.
     */
    create: createRouteUtil('/dev/users/create', 'Create User'),
    /**
     * Displays all information about a specific user to edit.
     */
    detail: createRouteUtil('/dev/users/:userID', 'Users'),
  },

  stores: {
    root: createRouteUtil('/dev/stores', 'Stores'),
    detail: createRouteUtil('/dev/stores/:storeID', 'Stores'),
    create: createRouteUtil('/dev/stores/create', 'Create Store'),
    buyers: createRouteUtil('/dev/stores/:storeID/buyers', 'Store Buyers'),
    createBuyer: createRouteUtil(
      '/dev/stores/:storeID/buyers/create',
      'Add Store Buyer',
    ),
    vendors: createRouteUtil('/dev/stores/:storeID/vendors', 'Store Vendors'),
    departments: createRouteUtil(
      '/dev/stores/:storeID/departments',
      'Store Departments',
    ),
    createDepartment: createRouteUtil(
      '/dev/stores/:storeID/departments/create',
      'Create Store Department',
    ),
    departmentDetail: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID',
      'Store Department',
    ),
    /**
     * Lists all vendors for a specific department.
     */
    departmentVendors: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID/vendors',
      'Store Department Vendors',
    ),
    /**
     * Form to create a new vendor.
     */
    createDepartmentVendor: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID/vendors/create',
      'Create Store Department Vendor',
    ),
    editDepartmentVendor: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID/vendors/edit/:vendorID',
      'Store Department Vendors',
    ),
    /**
     * Lists all vendors for a specific department.
     */
    departmentBuyers: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID/buyers',
      'Store Department Buyers',
    ),
    /**
     * Form to create a new vendor.
     */
    createDepartmentBuyer: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID/buyers/create',
      'Create Store Department Buyer',
    ),
    /**
     * Lists all vendors for a specific department.
     */
    departmentOrders: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID/orders',
      'Store Department Orders',
    ),
    /**
     * Shows specific order for a department.
     */
    departmentOrderDetail: createRouteUtil(
      '/dev/stores/:storeID/departments/:departmentID/orders/:orderID',
      'Store Department Orders',
    ),
  },

  vendors: {
    root: createRouteUtil('/dev/vendors', 'Vendors'),
    vendorInvitation: createRouteUtil(
      '/dev/vendor-invitation/:inviteCode',
      'Vendor Invitation',
    ),
    /**
     * Displays all information about a specific vendor to edit.
     */
    detail: createRouteUtil('/dev/vendors/:vendorID', 'Vendors'),
    /**
     * Displays the create vendor form.
     */
    create: createRouteUtil('/dev/vendors/create', 'Create Vendor'),
  },

  adminVendors: {
    root: createRouteUtil('/dev/admin-vendors', 'Vendors (new)'),
  },

  products: {
    /**
     * Lists all vendor products.
     */
    root: createRouteUtil('/dev/product-catalog', 'All Products'),
    /**
     * Displays the create product form.
     */
    create: createRouteUtil('/dev/product-catalog/create', 'Create Product'),
    /**
     * Displays all information about a specific product.
     */
    detail: createRouteUtil(
      '/dev/product-catalog/:vendorID/:productID',
      'Product Details',
    ),
    /**
     * Displays the update form for a specific product.
     */
    updateDetails: createRouteUtil(
      '/dev/product-catalog/:vendorID/:productID/update-details',
      'Update Product Details',
    ),
    /**
     * Displays the update pricing form for a specific product.
     */
    updatePricing: createRouteUtil(
      '/dev/product-catalog/:vendorID/:productID/update-pricing',
      'Update Product Pricing',
    ),
    /**
     * Displays a product's custom pricing for vendor admins.
     */
    pricing: createRouteUtil(
      '/dev/product-catalog/:vendorID/:productID/pricing',
      'Product Pricing',
    ),
    /**
     * Displays the confirmation modal to delete a product.
     */
    delete: createRouteUtil(
      '/dev/product-catalog/:vendorID/:productID/delete',
      'Delete Product',
    ),
    /**
     * Lists all product CSV file uploads made.
     */
    history: createRouteUtil(
      '/dev/catalog-uploader',
      'Product Catalog Uploader',
    ),
    /**
     * Displays form to upload product CSV files.
     */
    upload: createRouteUtil(
      '/dev/catalog-uploader',
      'Product Catalog Uploader',
    ),
    /**
     * @description Displays form to upload products for order guide CSV files.
     */
    uploadOrderGuide: createRouteUtil(
      '/dev/catalog-uploader?uploader-type=order-guide',
      'Order Guide Uploader',
    ),
    /**
     * @description Displays form to upload product catalog.
     */
    uploadCatalog: createRouteUtil(
      '/dev/catalog-uploader?uploader-type=catalog',
      'Catalog Uploader',
    ),
    /**
     * @description Displays form to upload product catalog.
     */
    uploadInvitationOrderGuide: createRouteUtil(
      '/dev/catalog-uploader?uploader-type=invitation-order-guide',
      'Invitation Order Guide Uploader',
    ),
    /**
     * Lists all vendor products.
     */
  },

  /**
   * Command Center routes
   */
  commandCenter: {
    root: createRouteUtil('/dev/command/:type/:storeID', 'Command Center'),
    departments: createRouteUtil(
      '/dev/command/departments/:storeID',
      'Admin Departments',
    ),
    updateDepartment: createRouteUtil(
      '/dev/command/departments/:storeID/update/:departmentID',
      'Update Department',
    ),
    products: createRouteUtil(
      '/dev/command/products/:storeID',
      'Admin Products',
    ),
    product: createRouteUtil(
      '/dev/command/products/:storeID/product/:productID',
      'Admin Product',
    ),
    bulkUpdateProducts: createRouteUtil(
      '/dev/command/products/:storeID/bulk-update',
      'Admin Product Update Bulk',
    ),
  },
  storeVendorCatalog: {
    root: createRouteUtil('/dev/store-vendor-catalog', 'Store Vendor Catalog'),
    product: createRouteUtil(
      '/dev/store-vendor-catalog/:productID',
      'Store Vendor Catalog Product',
    ),
  },
  banners: {
    root: createRouteUtil('/dev/banners', 'Banners'),
    create: createRouteUtil('/dev/banners/create', 'Create Banner'),
    edit: createRouteUtil('/dev/banners/edit/:bannerID', 'Update Banner'),
  },

  invitations: {
    adminVendorInvitations: createRouteUtil(
      '/dev/admin-vendor-invitations',
      'Vendor Invitations',
    ),
    adminVendorInvitationsCreate: createRouteUtil(
      '/dev/admin-vendor-invitations/create',
      'Create Vendor Invitations',
    ),
    adminStoreInvitations: createRouteUtil(
      '/dev/admin-store-invitations',
      'Store Invitations',
    ),
  },

  storeFiles: {
    root: createRouteUtil('/dev/store-files', 'Store Files'),
  },

  gql: {
    root: createRouteUtil('/dev/graphql', 'GraphQL Playground'),
  },

  aplRevisions: {
    root: createRouteUtil(
      '/dev/apl-revisions/:revisionID',
      'Revision Composer',
    ),
  },

  retool: {
    root: createRouteUtil('/dev/retool', 'Retool Apps'),
  },

  autoInvoice: {
    root: createRouteUtil('/dev/auto-invoice', 'Auto Invoice'),
    fileUploads: createRouteUtil(
      '/dev/auto-invoice/file-uploads',
      'Auto Invoice File Uploads',
    ),
    unmappedInvoices: createRouteUtil(
      '/dev/auto-invoice/unmapped-invoices',
      'Unmapped Invoices',
    ),
    mappingTemplates: createRouteUtil(
      '/dev/auto-invoice/mapping-templates',
      'Mapping Templates',
    ),
    metadataMapping: createRouteUtil(
      '/dev/auto-invoice/metadata-mapping/:id',
      'Metadata Mapping',
    ),
  },

  internalAutoInvoice: {
    root: createRouteUtil('/dev/internal-auto-invoice', 'Auto Invoice'),
    lineItemMapping: createRouteUtil(
      '/dev/auto-invoice/line-item-mapping/:invoiceID/:vendorID/:storeID?',
      'Auto Invoice | Line Item Mapping',
    ),
    fileUploads: createRouteUtil(
      '/dev/auto-invoice/file-uploads',
      'Auto Invoice File Uploads',
    ),
    unmappedInvoices: createRouteUtil(
      '/dev/auto-invoice/unmapped-invoices',
      'Unmapped Invoices',
    ),
    mappingTemplates: createRouteUtil(
      '/dev/auto-invoice/mapping-templates',
      'Mapping Templates',
    ),
    metadataMapping: createRouteUtil(
      '/dev/auto-invoice/metadata-mapping/:id',
      'Metadata Mapping',
    ),
  },

  eslUpload: {
    root: createRouteUtil('/dev/esl-upload', 'ESL Upload'),
  },

  productsV2: {
    root: createRouteUtil('/dev/products-v2', 'Products V2'),
  },
}

export { routes }
