import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Expand06IconProps = IconProps

const Expand06Icon = forwardRef<SVGSVGElement, Expand06IconProps>(
  (props: Expand06IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 8 5-5m0 0h-5m5 0v5M8 8 3 3m0 0v5m0-5h5m0 13-5 5m0 0h5m-5 0v-5m13 0 5 5m0 0v-5m0 5h-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

Expand06Icon.displayName = 'Expand06Icon'
Expand06Icon.defaultProps = iconDefaultProps

export type { Expand06IconProps }
export { Expand06Icon }
