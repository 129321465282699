import React from 'react'
import { CurrencyDollarIcon } from '@vori/gourmet-icons'

import { composeEventHandlers } from '../utils'
import { CurrencyInputProps } from './types'
import { DEFAULT_DECIMAL_PLACES } from './constants'
import { cleanInitialValue, formatValueToCurrency } from './utils'
import { InputProps } from '../InputNext'
import { useControlledState } from '../hooks'

export function useCurrencyInput(props?: CurrencyInputProps): InputProps {
  const decimalPlaces = React.useMemo(
    () => props?.decimalPlaces || DEFAULT_DECIMAL_PLACES,
    [props?.decimalPlaces],
  )

  const [value, setValue] = useControlledState({
    componentName: 'CurrencyInput',
    controlledValue: props?.value
      ? formatValueToCurrency(
          cleanInitialValue(props.value, decimalPlaces),
          decimalPlaces,
          props.withSymbol,
        )
      : props?.value,
    defaultValue: props?.defaultValue
      ? formatValueToCurrency(
          cleanInitialValue(props.defaultValue, decimalPlaces),
          decimalPlaces,
          props.withSymbol,
        )
      : '',
  })

  return React.useMemo(
    () => ({
      ...props,
      ...(value && { 'data-raw-value': String(value).replace('$', '') }),
      inputMode: 'decimal',
      leftIcon: props?.withIcon ? <CurrencyDollarIcon /> : props?.leftIcon,
      placeholder:
        props?.placeholder ||
        `${props?.withSymbol ? '$' : ''}0.${'0'.repeat(decimalPlaces)}`,
      type: 'text',
      value,
      onChange: (event) => {
        const formattedValue = formatValueToCurrency(
          event.target.value,
          decimalPlaces,
          props?.withSymbol,
        )

        setValue(formattedValue)

        // We need to "bubble up" the change event with the formatted value.
        event.target.value = formattedValue
        event.target.dataset.rawValue = formattedValue.replace('$', '')
        props?.onChange?.(event)
      },
      onFocus: composeEventHandlers(props?.onFocus, (event) => {
        if (props?.highlightOnFocus) {
          event.target.select()
        }
      }),
    }),
    [decimalPlaces, props, setValue, value],
  )
}
