import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Building08IconProps = IconProps

const Building08Icon = forwardRef<SVGSVGElement, Building08IconProps>(
  (props: Building08IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 21h18M6 18v-8m4 8v-8m4 8v-8m4 8v-8m2-3-7.576-4.735c-.154-.096-.23-.144-.313-.163a.5.5 0 0 0-.222 0c-.082.019-.16.067-.313.163L4 7h16Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Building08Icon.displayName = 'Building08Icon'
Building08Icon.defaultProps = iconDefaultProps

export type { Building08IconProps }
export { Building08Icon }
