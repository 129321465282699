import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowDownIconProps = IconProps

const ArrowDownIcon = forwardRef<SVGSVGElement, ArrowDownIconProps>(
  (props: ArrowDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 5v14m0 0 7-7m-7 7-7-7"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowDownIcon.displayName = 'ArrowDownIcon'
ArrowDownIcon.defaultProps = iconDefaultProps

export type { ArrowDownIconProps }
export { ArrowDownIcon }
