import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Coins04IconProps = IconProps

const Coins04Icon = forwardRef<SVGSVGElement, Coins04IconProps>(
  (props: Coins04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.101 4A7 7 0 0 1 20 13.899M7.5 13 9 12v5.5m-1.5 0h3M16 15a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Coins04Icon.displayName = 'Coins04Icon'
Coins04Icon.defaultProps = iconDefaultProps

export type { Coins04IconProps }
export { Coins04Icon }
