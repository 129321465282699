import { IconProps } from '@vori/gourmet-icons'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Flex from '../Flex'
import Info, { InfoDefaultProps, InfoProps } from '../Info'
import Input, { InputDefaultProps, InputProps } from '../Input'

import { base, Size, sizing, spacing } from '../tokens'

const InfoContainer = styled(Flex)`
  right: ${spacing.infoInput.iconContainer};
  position: absolute;
  z-index: 1;
`

const StyledInput = styled(Input)<{ $iconSize?: IconProps['size'] }>`
  padding-right: ${({ $iconSize }) => `calc(
    ${
      sizing.icon[$iconSize || (InfoDefaultProps.iconProps?.size as Size)]
    } + (${base.spacing.tiny} * 2)
  )`};
`

const Container = styled(Flex)`
  position: relative;
`

type InfoInputProps = InputProps & {
  infoProps?: InfoProps
}

const defaultProps: Partial<InfoInputProps> = {
  ...InputDefaultProps,
  fullWidth: false,
  infoProps: InfoDefaultProps,
}

const StyledInfoInput = styled(
  forwardRef<HTMLInputElement, InfoInputProps>(function InfoInput(
    { className, fullWidth, infoProps, ...props }: InfoInputProps,
    ref,
  ) {
    return (
      <Container centerY className={className} fullWidth={fullWidth}>
        <StyledInput {...props} fullWidth={fullWidth} type="search" ref={ref} />
        <InfoContainer center shrink={0}>
          <Info {...infoProps} />
        </InfoContainer>
      </Container>
    )
  }),
)``

StyledInfoInput.displayName = 'InfoInput'
StyledInfoInput.defaultProps = defaultProps

export type { InfoInputProps }
export { defaultProps as InfoInputDefaultProps }
export default StyledInfoInput
