import * as CSS from 'csstype'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { base, Size, sizing, Space } from '../tokens'

type GridProps = React.HTMLAttributes<HTMLDivElement> & {
  alignItems?: CSS.Property.AlignItems
  areas?: CSS.Property.GridTemplateAreas
  autoColumns?: CSS.Property.GridAutoColumns
  autoFlow?: CSS.Property.GridAutoFlow
  autoRows?: CSS.Property.GridAutoRows
  columnGap?: Space
  columns?: CSS.Property.GridTemplateColumns
  fullWidth?: boolean
  gap?: Space[]
  grid?: CSS.Property.Grid
  inline?: boolean
  justifyItems?: CSS.Property.JustifyItems
  placeItems?: CSS.Property.PlaceItems
  rowGap?: Space
  rows?: CSS.Property.GridTemplateRows
  size?: Size
  template?: CSS.Property.GridTemplate
}

const defaultProps: Partial<GridProps> = {
  className: '',
  columnGap: 'base',
  fullWidth: false,
  gap: ['base'],
  inline: false,
  rowGap: 'base',
}

const StyledGrid = styled(
  forwardRef<HTMLDivElement, GridProps>(function Grid(
    {
      alignItems,
      areas,
      autoColumns,
      autoFlow,
      autoRows,
      columnGap,
      columns,
      fullWidth,
      gap,
      grid,
      inline,
      justifyItems,
      placeItems,
      rowGap,
      rows,
      size,
      template,
      ...props
    }: GridProps,
    ref,
  ) {
    return <div {...props} ref={ref} />
  }),
)<GridProps>`
  ${({ alignItems }): string =>
    alignItems ? `align-items: ${alignItems};` : ''}
  ${({ areas }): string => (areas ? `grid-template-areas: ${areas};` : '')}
  ${({ autoColumns }): string =>
    autoColumns ? `grid-auto-columns: ${autoColumns};` : ''}
  ${({ autoFlow }): string => (autoFlow ? `grid-auto-flow: ${autoFlow};` : '')}
  ${({ autoRows }): string => (autoRows ? `grid-auto-rows: ${autoRows};` : '')}
  ${({ gap = defaultProps.gap }): string =>
    gap && gap.length
      ? `grid-gap: ${gap
          .map((space) => base.spacing[space as Space])
          .join(' ')};`
      : ''}
  ${({ columnGap = defaultProps.columnGap }): string =>
    columnGap ? `column-gap: ${base.spacing[columnGap as Space]};` : ''}
  ${({ columns }): string =>
    columns ? `grid-template-columns: ${columns};` : ''}
  ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  ${({ grid }): string => (grid ? `grid: ${grid};` : '')}
  ${({ justifyItems }): string =>
    justifyItems ? `justify-items: ${justifyItems};` : ''}
  ${({ placeItems }): string =>
    placeItems ? `place-items: ${placeItems};` : ''}
  ${({ rowGap = defaultProps.rowGap }): string =>
    rowGap ? `row-gap: ${base.spacing[rowGap as Space]};` : ''}
  ${({ rows }): string => (rows ? `grid-template-rows: ${rows};` : '')}
  ${({ fullWidth, size }): string =>
    !fullWidth ? `max-width: ${sizing.popup[size as Size]}; width: 100%;` : ''}
  ${({ template }): string => (template ? `grid-template: ${template};` : '')}
  display: ${({ inline }): string => (inline ? 'inline-grid' : 'grid')};
`

StyledGrid.displayName = 'Grid'
StyledGrid.defaultProps = defaultProps

export type { GridProps }
export { defaultProps as GridDefaultProps }
export default StyledGrid
