import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowNarrowRightIconProps = IconProps

const ArrowNarrowRightIcon = forwardRef<
  SVGSVGElement,
  ArrowNarrowRightIconProps
>((props: ArrowNarrowRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 12h16m0 0-6-6m6 6-6 6"
      fill="none"
    ></path>
  </Icon>
))

ArrowNarrowRightIcon.displayName = 'ArrowNarrowRightIcon'
ArrowNarrowRightIcon.defaultProps = iconDefaultProps

export type { ArrowNarrowRightIconProps }
export { ArrowNarrowRightIcon }
