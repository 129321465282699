import { assignRef } from './assignRef'

export function composeRefs<RefType>(
  targetRefs: Array<React.Ref<RefType>>,
): (ref: RefType) => unknown {
  return (ref) => {
    targetRefs.forEach((targetRef) => {
      assignRef(targetRef, ref)
    })
  }
}
