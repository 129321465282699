export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CartReviewItem: [
      'CartReviewItemUnusualQuantity',
      'CartReviewItemUnusualUOM',
    ],
    Company: ['Store', 'Vendor'],
    Deal: ['BuyXGetYFreeDeal', 'FixedDeal', 'PercentageDeal'],
    GQLLineItemIssueBase: ['ReceivingLineItemIssue'],
    GQLOrderHistoryEventBase: [
      'OrderHistoryEventInvoiceApproved',
      'OrderHistoryEventInvoicePaid',
      'OrderHistoryEventOrdered',
      'OrderHistoryEventReceived',
    ],
    Misscan: ['MisscanResolution', 'MisscanSubmission'],
    MissingCartItem: ['MissingCartItemStockedNotScanned'],
    OrderReceivingLineItemListItem: ['OrderLineItem', 'OrderMispick'],
  },
}
export default result
