import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Speaker01IconProps = IconProps

const Speaker01Icon = forwardRef<SVGSVGElement, Speaker01IconProps>(
  (props: Speaker01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6h.01M8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 19.72 20 18.88 20 17.2V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22Zm3.7-16a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm3.5 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Speaker01Icon.displayName = 'Speaker01Icon'
Speaker01Icon.defaultProps = iconDefaultProps

export type { Speaker01IconProps }
export { Speaker01Icon }
