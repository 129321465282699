import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LeftIndent01IconProps = IconProps

const LeftIndent01Icon = forwardRef<SVGSVGElement, LeftIndent01IconProps>(
  (props: LeftIndent01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 9.25h-9M21 4H3m18 10.75h-9M21 20H3M4.28 8.56l3.867 2.9c.29.217.434.326.486.459a.5.5 0 0 1 0 .362c-.052.133-.197.242-.486.459l-3.867 2.9c-.412.309-.618.463-.79.46a.5.5 0 0 1-.384-.192C3 15.773 3 15.515 3 15V9.2c0-.515 0-.772.106-.908A.5.5 0 0 1 3.49 8.1c.172-.003.378.151.79.46Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LeftIndent01Icon.displayName = 'LeftIndent01Icon'
LeftIndent01Icon.defaultProps = iconDefaultProps

export type { LeftIndent01IconProps }
export { LeftIndent01Icon }
