import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CoinsSwap02IconProps = IconProps

const CoinsSwap02Icon = forwardRef<SVGSVGElement, CoinsSwap02IconProps>(
  (props: CoinsSwap02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 6 2-2m0 0L6 2m2 2H6a4 4 0 0 0-4 4m16 10-2 2m0 0 2 2m-2-2h2a4 4 0 0 0 4-4M10.189 6.5a6 6 0 1 1 7.311 7.311M14 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CoinsSwap02Icon.displayName = 'CoinsSwap02Icon'
CoinsSwap02Icon.defaultProps = iconDefaultProps

export type { CoinsSwap02IconProps }
export { CoinsSwap02Icon }
