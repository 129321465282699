import { CSSObject } from 'styled-components'
import { IconProps } from '@vori/gourmet-icons'
import React from 'react'
import styled from 'styled-components'

import { toRem, toTransitions } from '../utils'
import { cssObjectFromDimensions, dimensions } from './utils'
import { ButtonCoreProps, ButtonProps } from './types'

function styles(): CSSObject {
  return {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderColor: '#D1D1D6',
    borderRadius: toRem(8),
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#3F3F46',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: toRem(14),
    fontStyle: 'normal',
    fontWeight: 500,
    justifyContent: 'center',
    lineHeight: toRem(20),
    minHeight: toRem(dimensions.default.minHeight),
    paddingBottom: dimensions.default.verticalPadding,
    paddingLeft: dimensions.default.horizontalPadding,
    paddingRight: dimensions.default.horizontalPadding,
    paddingTop: dimensions.default.verticalPadding,
    width: 'fit-content',

    transition: toTransitions(
      ['background-color', 'border-color', 'box-shadow', 'color', 'zIndex'],
      'ease',
    ),

    ['&[data-left-align]']: {
      textAlign: 'left',
      justifyContent: 'flex-start',
    },

    ...cssObjectFromDimensions((buttonSizing, buttonDimensions) => ({
      [`&[data-sizing="${buttonSizing}"]`]: {
        gap: buttonSizing === 'xxLarge' ? toRem(12) : toRem(8),
        minHeight: toRem(buttonDimensions.minHeight),
        paddingBottom: buttonDimensions.verticalPadding,
        paddingLeft: buttonDimensions.horizontalPadding,
        paddingRight: buttonDimensions.horizontalPadding,
        paddingTop: buttonDimensions.verticalPadding,
      },
    })),

    '&[data-icon-button]': {
      ...cssObjectFromDimensions((buttonSizing, buttonDimensions) => ({
        [`&[data-sizing="${buttonSizing}"]:not([data-no-padding])`]: {
          minWidth: toRem(buttonDimensions.minHeight),
          paddingLeft: buttonDimensions.verticalPadding,
          paddingRight: buttonDimensions.verticalPadding,
        },
      })),
    },

    '&[data-variant="primary"]': {
      backgroundColor: '#5235D0',
      borderColor: '#5235D0',
      color: '#FFFFFF',
    },

    '&[data-variant="secondary"]': {
      backgroundColor: '#26272B',
      borderColor: '#26272B',
      color: '#FFFFFF',
    },

    '&[data-variant="destructive"]': {
      backgroundColor: '#D62738',
      borderColor: '#D62738',
      color: '#FFFFFF',
    },

    '&:hover, &[data-hovered], &:active, &[data-active], &[aria-expanded=true]':
      {
        backgroundColor: '#FAFAFA',
        borderColor: '#26272B',

        '&[data-variant="primary"]': {
          backgroundColor: '#3B1EA6',
          borderColor: '#3B1EA6',
        },

        '&[data-variant="secondary"]': {
          backgroundColor: '#15141B',
          borderColor: '#15141B',
        },

        '&[data-variant="destructive"]': {
          backgroundColor: '#BB0C1D',
          borderColor: '#BB0C1D',
        },
      },

    '&:focus, &:focus-within, &[data-focused]': {
      backgroundColor: '#FFFFFF',
      borderColor: '#D1D1D6',
      boxShadow: `0 0 0 ${toRem(2)} #FFFFFF, 0 0 0 ${toRem(4)} #6038EF`,
      outline: 'none',

      '&[data-variant="primary"]': {
        backgroundColor: '#5235D0',
        borderColor: '#5235D0',
      },

      '&[data-variant="secondary"]': {
        backgroundColor: '#26272B',
        borderColor: '#26272B',
      },

      '&[data-variant="destructive"]': {
        backgroundColor: '#D62738',
        borderColor: '#D62738',
        boxShadow: `0 0 0 ${toRem(2)} #FFFFFF, 0 0 0 ${toRem(4)} #D62738`,
      },
    },

    '&:disabled': {
      color: '#D1D1D6',
      cursor: 'not-allowed',
      borderColor: '#E4E4E7',
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',

      '&[data-variant="primary"]': {
        backgroundColor: '#D9D6FE',
        borderColor: '#D9D6FE',
        color: '#FFFFFF',
      },

      '&[data-variant="secondary"]': {
        backgroundColor: '#70707B',
        borderColor: '#70707B',
        color: '#F4F4F5',
      },

      '&[data-variant="destructive"]': {
        backgroundColor: '#FFC9CF',
        borderColor: '#FFC9CF',
        color: '#FFFFFF',
      },
    },

    '&[data-full-width]': {
      width: '100%',
    },

    '&[data-no-padding]': {
      minHeight: 'auto',
      minWidth: 'auto',
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },

    '&[data-clickable][data-variant="default"]': {
      color: '#70707B',

      '&:hover, &[data-hovered]': {
        backgroundColor: '#FAFAFA',
        color: '#51525C',
      },

      '&:disabled': {
        color: '#D1D1D6',
      },
    },

    '&[data-clickable][data-variant="primary"]': {
      color: '#5235D0',

      '&:hover, &[data-hovered]': {
        backgroundColor: '#F4F3FF',
        color: '#3B1EA6',
      },

      '&:disabled': {
        color: '#BDB4FE',
      },
    },

    '&[data-clickable][data-variant="secondary"]': {
      color: '#70707B',

      '&:hover, &[data-hovered]': {
        backgroundColor: '#D1D1D6',
        color: '#26272B',
      },

      '&:disabled': {
        color: '#D1D1D6',
      },
    },

    '&[data-clickable][data-variant="destructive"]': {
      color: '#BB0C1D',

      '&:hover, &[data-hovered]': {
        backgroundColor: '#FEF2F3',
        color: '#91171D',
      },

      '&:disabled': {
        color: '#FD9AA4',
      },
    },

    '&[data-clickable][data-variant]': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',

      '&:hover, &[data-hovered]': {
        borderColor: 'transparent',

        '&[data-no-padding]': {
          backgroundColor: 'transparent',
        },
      },

      '&:disabled': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },

    '&[data-no-focus-ring], &[data-no-focus-ring]:active, &[data-no-focus-ring]:focus':
      {
        boxShadow: 'none',
      },

    '[data-gourmet-button-icon]': {
      alignItems: 'center',
      display: 'inline-flex',
      justifyContent: 'center',
    },

    '::-webkit-search-cancel-button': {
      cursor: 'pointer',
    },
  }
}

const StyledButton = styled.button(styles)

const defaultProps: Partial<ButtonCoreProps> = {
  size: 'default',
  variant: 'default',
}

/**
 * A `<Button>` is a widget that enables users to trigger an action or event,
 * such as submitting a form, opening a dialog, canceling an action, or
 * performing a delete operation.
 *
 * @example
 * <Button leftIcon={<AtSignIcon />}>Some button</Button>
 *
 * @see {@link https://html.spec.whatwg.org/multipage/form-elements.html#the-button-element}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button}
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    asClickable,
    asIconButton,
    children,
    fullWidth,
    leftIcon,
    noFocusRing,
    noPadding,
    rightIcon,
    size = 'default',
    sizing,
    variant = 'default',
    withLeftAlignedText,
    ...props
  }: ButtonProps,
  ref,
): JSX.Element {
  return (
    <StyledButton
      type="button"
      {...props}
      data-gourmet-button=""
      {...(!asIconButton && leftIcon && { 'data-left-icon': '' })}
      {...(!asIconButton && rightIcon && { 'data-right-icon': '' })}
      {...(asClickable && { 'data-clickable': '' })}
      {...(asIconButton && { 'data-icon-button': '' })}
      {...(fullWidth && { 'data-full-width': '' })}
      {...(noFocusRing && { 'data-no-focus-ring': '' })}
      {...(noPadding && { 'data-no-padding': '' })}
      {...((sizing || size) && { 'data-sizing': sizing || size })}
      {...(variant && { 'data-variant': variant })}
      {...(withLeftAlignedText && { 'data-left-align': '' })}
      ref={ref}
    >
      {leftIcon &&
        React.isValidElement(leftIcon) &&
        React.cloneElement<IconProps>(
          leftIcon,
          { 'data-gourmet-button-icon': '', size: 'md' },
          null,
        )}

      {!asIconButton && (
        <>
          {children}
          {rightIcon &&
            React.isValidElement(rightIcon) &&
            React.cloneElement<IconProps>(
              rightIcon,
              { 'data-gourmet-button-icon': '', size: 'md' },
              null,
            )}
        </>
      )}
    </StyledButton>
  )
})

Button.displayName = 'Button'
Button.defaultProps = defaultProps

export { Button, styles as buttonStyles, defaultProps as buttonDefaultProps }
