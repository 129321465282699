import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type VoicemailIconProps = IconProps

const VoicemailIcon = forwardRef<SVGSVGElement, VoicemailIconProps>(
  (props: VoicemailIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 16h12M6 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm12 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

VoicemailIcon.displayName = 'VoicemailIcon'
VoicemailIcon.defaultProps = iconDefaultProps

export type { VoicemailIconProps }
export { VoicemailIcon }
