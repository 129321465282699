/**
 * Returns the JWT from the current url if it contains a ?auth=:JWT query
 * parameter, e.g., https://dash.vori.com/payments?auth=123abc.cba321.321abc
 */
function getAuthTokenFromUrl(): string | null {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get('auth') || null
}

export { getAuthTokenFromUrl }
