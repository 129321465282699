import { Divider, DividerProps } from '@vori/gourmet-components'
import React from 'react'
import styled from 'styled-components'

const StyledDivider = styled(Divider)({
  borderColor: '#EAECF0',
})

function DataGridDivider(props: DividerProps): JSX.Element {
  return <StyledDivider {...props} />
}

export { DataGridDivider }
export type { DividerProps as DataGridDividerProps }
