import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Disc01IconProps = IconProps

const Disc01Icon = forwardRef<SVGSVGElement, Disc01IconProps>(
  (props: Disc01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Disc01Icon.displayName = 'Disc01Icon'
Disc01Icon.defaultProps = iconDefaultProps

export type { Disc01IconProps }
export { Disc01Icon }
