import React from 'react'

/**
 * Defines an effect that will be evaluated once, when the component is mounted
 * and the provided condition is truthy
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Glossary/Truthy}
 */
export function useEffectOnceConditionally<
  T extends (...args: never[]) => unknown,
>(
  /**
   * Imperative function that will be executed once when the component mounts.
   */
  effect: T,
  /**
   * The condition used to conditionally execute the effect.
   */
  condition: boolean,
): void {
  const isFirstRenderRef = React.useRef(true)

  React.useEffect(() => {
    if (!condition) {
      return
    }

    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false
      effect()
    }
  }, [condition, effect])
}
