export enum RestApiErrorCode {
  LOYALTY_PROGRAM_ALREADY_EXISTS = 'loyalty_program_already_exists',
  MULTIPLE_ACTIVE_PROMOTIONS_FOR_PRODUCT = 'multiple_active_promotions_for_product',
  MULTIPLE_OFFERS_FOR_PRODUCT = 'multiple_offers_for_product',
  PIN_ALREADY_ASSIGNED = 'pin_already_assigned',
  PROMOTION_MISSING_OFFERS = 'promotion_missing_offers',
  PROMOTION_OFFERS_MISSING_PRODUCTS = 'promotion_offers_missing_products',
}

export type RestApiErrorResponse<TErrorDetails> = {
  error: string
  message: string
  statusCode: number
  error_code: RestApiErrorCode
  error_details: TErrorDetails
}

export function isRestApiErrorResponse<TErrorDetails>(
  value: unknown,
): value is RestApiErrorResponse<TErrorDetails> {
  return (
    value != null &&
    typeof value === 'object' &&
    Object.keys(value).every((key) =>
      [
        'error',
        'message',
        'statusCode',
        'error_code',
        'error_details',
      ].includes(key),
    )
  )
}

export function isError<TErrorDetails>(
  value: unknown,
): value is Error | RestApiErrorResponse<TErrorDetails> {
  return isRestApiErrorResponse(value) || value instanceof Error
}

export class RestApiError<TErrorDetails = unknown> extends Error {
  readonly response: RestApiErrorResponse<TErrorDetails> | undefined

  constructor(
    response: Error | RestApiErrorResponse<TErrorDetails> | string | unknown,
  ) {
    const errorMessage = isError(response)
      ? response.message
      : typeof response === 'string'
        ? response
        : 'There was an error with this request'

    super(errorMessage)

    this.response = isRestApiErrorResponse<TErrorDetails>(response)
      ? response
      : undefined

    this.cause = response instanceof Error ? response : undefined
    this.message = errorMessage
    this.name = 'RestApiError'

    Error.captureStackTrace(this, RestApiError)
  }
}
