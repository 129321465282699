import React from 'react'
import styled, { CSSObject } from 'styled-components'

import Flex, { FlexProps } from '../Flex'

function styles(): CSSObject {
  return {
    width: '100%',
  }
}

const StyledForm = styled.form(styles)

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  /**
   * Props to be passed directly to the <Flex> component wrapping the inner
   * <form> element.
   */
  flexProps?: FlexProps
}

const Form = React.forwardRef<HTMLFormElement, React.PropsWithChildren<Props>>(
  function Form(
    { children, flexProps, ...props }: React.PropsWithChildren<Props>,
    ref,
  ) {
    return (
      <Flex
        column={flexProps != null}
        fullWidth={flexProps != null}
        {...flexProps}
      >
        <StyledForm {...props} data-gourmet-form="" ref={ref}>
          {children}
        </StyledForm>
      </Flex>
    )
  },
)

Form.displayName = 'Form'

export { Form, styles as FormStyles }
export type { Props as FormProps }
