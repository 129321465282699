import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenUpRightIconProps = IconProps

const ArrowCircleBrokenUpRightIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenUpRightIconProps
>((props: ArrowCircleBrokenUpRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.34 14.59a9.996 9.996 0 0 1 2.589-9.661c3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142a9.996 9.996 0 0 1-9.66 2.59M15 15V9m0 0H9m6 0L5 19"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenUpRightIcon.displayName = 'ArrowCircleBrokenUpRightIcon'
ArrowCircleBrokenUpRightIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenUpRightIconProps }
export { ArrowCircleBrokenUpRightIcon }
