import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowNarrowDownLeftIconProps = IconProps

const ArrowNarrowDownLeftIcon = forwardRef<
  SVGSVGElement,
  ArrowNarrowDownLeftIconProps
>((props: ArrowNarrowDownLeftIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18 6 6 18m0 0h8m-8 0v-8"
      fill="none"
    ></path>
  </Icon>
))

ArrowNarrowDownLeftIcon.displayName = 'ArrowNarrowDownLeftIcon'
ArrowNarrowDownLeftIcon.defaultProps = iconDefaultProps

export type { ArrowNarrowDownLeftIconProps }
export { ArrowNarrowDownLeftIcon }
