import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudLightningIconProps = IconProps

const CloudLightningIcon = forwardRef<SVGSVGElement, CloudLightningIconProps>(
  (props: CloudLightningIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 15.744a4.502 4.502 0 0 0-1.08-8.725 6.002 6.002 0 0 0-11.84 0A4.5 4.5 0 0 0 5 15.744M13 10l-4 6h6l-4 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudLightningIcon.displayName = 'CloudLightningIcon'
CloudLightningIcon.defaultProps = iconDefaultProps

export type { CloudLightningIconProps }
export { CloudLightningIcon }
