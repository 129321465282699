import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileX01IconProps = IconProps

const FileX01Icon = forwardRef<SVGSVGElement, FileX01IconProps>(
  (props: FileX01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 12V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22H12m4-6 5 5m0-5-5 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileX01Icon.displayName = 'FileX01Icon'
FileX01Icon.defaultProps = iconDefaultProps

export type { FileX01IconProps }
export { FileX01Icon }
