import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Maximize01IconProps = IconProps

const Maximize01Icon = forwardRef<SVGSVGElement, Maximize01IconProps>(
  (props: Maximize01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m14 10 7-7m0 0h-6m6 0v6m-11 5-7 7m0 0h6m-6 0v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Maximize01Icon.displayName = 'Maximize01Icon'
Maximize01Icon.defaultProps = iconDefaultProps

export type { Maximize01IconProps }
export { Maximize01Icon }
