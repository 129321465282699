import { CSSObject } from 'styled-components'
import { foundations, TokenFoundationSpace } from '@vori/gourmet-tokens'

import { FlexCoreProps } from './types'
import { gap, offset } from './utils'
import { PropsToStyledProps } from '../utils'

export function flexStyles(
  props: PropsToStyledProps<FlexCoreProps>,
): CSSObject {
  const offsetArray: [TokenFoundationSpace, TokenFoundationSpace] | null =
    props.$offset != null
      ? Array.isArray(props.$offset)
        ? props.$offset
        : [props.$offset, props.$offset]
      : props.$rowOffset != null
        ? [props.$rowOffset, '' as TokenFoundationSpace]
        : props.$columnOffset != null
          ? ['' as TokenFoundationSpace, props.$columnOffset]
          : null

  return {
    display: 'flex',

    ...(props.$alignItems && { alignItems: props.$alignItems }),
    ...(props.$alignSelf && { alignSelf: props.$alignSelf }),
    ...(props.$direction && { flexDirection: props.$direction }),
    ...(props.$justifyContent && { justifyContent: props.$justifyContent }),

    ...((props.$center ||
      (props.$centerY && !props.$direction) ||
      (props.$centerX &&
        (props.$direction === 'column' ||
          props.$direction === 'column-reverse'))) && {
      alignItems: 'center',
    }),

    ...((props.$center ||
      (props.$centerX && !props.$direction) ||
      (props.$centerY &&
        (props.$direction === 'column' ||
          props.$direction === 'column-reverse'))) && {
      justifyContent: 'center',
    }),

    ...(props.$wrap && {
      flexWrap: props.$wrap === true ? 'wrap' : props.$wrap,
    }),

    ...(props.$basis != null && { flexBasis: props.$basis }),
    ...(props.$flex != null && { flex: props.$flex }),
    ...(props.$fullHeight && { height: '100%' }),
    ...(props.$fullWidth && { width: '100%' }),
    ...(props.$grow != null && { flexGrow: props.$grow }),
    ...(props.$shrink != null && { flexShrink: props.$shrink }),

    ...(!props.$fullWidth &&
      props.$size != null && {
        maxWidth: foundations.size[`size.${props.$size}`],
        width: '100%',
      }),

    ...(props.$rowGap && {
      rowGap: gap(props.$rowGap),
    }),

    ...(props.$columnGap && {
      columnGap: gap(props.$columnGap),
    }),

    ...(props.$gap != null && {
      gap: Array.isArray(props.$gap)
        ? props.$gap.map(gap).join(' ')
        : gap(props.$gap),
    }),

    ...(offsetArray != null && offset(offsetArray)),
  }
}
