import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Webcam02IconProps = IconProps

const Webcam02Icon = forwardRef<SVGSVGElement, Webcam02IconProps>(
  (props: Webcam02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 22h8m4.5-11.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Zm-5.313 0a3.188 3.188 0 1 1-6.375 0 3.188 3.188 0 0 1 6.376 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Webcam02Icon.displayName = 'Webcam02Icon'
Webcam02Icon.defaultProps = iconDefaultProps

export type { Webcam02IconProps }
export { Webcam02Icon }
