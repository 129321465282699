import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BookmarkIconProps = IconProps

const BookmarkIcon = forwardRef<SVGSVGElement, BookmarkIconProps>(
  (props: BookmarkIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 7.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C7.28 3 8.12 3 9.8 3h4.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C19 5.28 19 6.12 19 7.8V21l-7-4-7 4V7.8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

BookmarkIcon.displayName = 'BookmarkIcon'
BookmarkIcon.defaultProps = iconDefaultProps

export type { BookmarkIconProps }
export { BookmarkIcon }
