import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCw03IconProps = IconProps

const RefreshCw03Icon = forwardRef<SVGSVGElement, RefreshCw03IconProps>(
  (props: RefreshCw03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 22s.85-.121 4.364-3.636A9 9 0 0 0 14 3.224M14 22h6m-6 0v-6M10 2s-.85.122-4.364 3.636A9 9 0 0 0 10 20.776M10 2H4m6 0v6"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCw03Icon.displayName = 'RefreshCw03Icon'
RefreshCw03Icon.defaultProps = iconDefaultProps

export type { RefreshCw03IconProps }
export { RefreshCw03Icon }
