import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyBitcoinCircleIconProps = IconProps

const CurrencyBitcoinCircleIcon = forwardRef<
  SVGSVGElement,
  CurrencyBitcoinCircleIconProps
>((props: CurrencyBitcoinCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.5 7.5h4.25a2.25 2.25 0 0 1 0 4.5H9.5h4.75a2.25 2.25 0 0 1 0 4.5H9.5m0-9H8m1.5 0v9m0 0H8M10 6v1.5m0 9V18m3-12v1.5m0 9V18m9-6c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

CurrencyBitcoinCircleIcon.displayName = 'CurrencyBitcoinCircleIcon'
CurrencyBitcoinCircleIcon.defaultProps = iconDefaultProps

export type { CurrencyBitcoinCircleIconProps }
export { CurrencyBitcoinCircleIcon }
