import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleBrokenDownIconProps = IconProps

const ArrowCircleBrokenDownIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleBrokenDownIconProps
>((props: ArrowCircleBrokenDownIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 3.338A9.996 9.996 0 0 1 22 12c0 5.523-4.477 10-10 10S2 17.523 2 12a9.996 9.996 0 0 1 5-8.662M8 12l4 4m0 0 4-4m-4 4V2"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleBrokenDownIcon.displayName = 'ArrowCircleBrokenDownIcon'
ArrowCircleBrokenDownIcon.defaultProps = iconDefaultProps

export type { ArrowCircleBrokenDownIconProps }
export { ArrowCircleBrokenDownIcon }
