import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Contrast01IconProps = IconProps

const Contrast01Icon = forwardRef<SVGSVGElement, Contrast01IconProps>(
  (props: Contrast01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2c.592 0 1.171.051 1.735.15M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10m0-20v20m5.738-18.191c.954.67 1.786 1.502 2.455 2.456m1.657 4a10.064 10.064 0 0 1 0 3.47m-1.66 4.006c-.67.952-1.5 1.782-2.453 2.45m-4.004 1.66A10.21 10.21 0 0 1 12 22"
        fill="none"
      ></path>
    </Icon>
  ),
)

Contrast01Icon.displayName = 'Contrast01Icon'
Contrast01Icon.defaultProps = iconDefaultProps

export type { Contrast01IconProps }
export { Contrast01Icon }
