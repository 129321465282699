import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Upload02IconProps = IconProps

const Upload02Icon = forwardRef<SVGSVGElement, Upload02IconProps>(
  (props: Upload02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 3H3m15 10-6-6m0 0-6 6m6-6v14"
        fill="none"
      ></path>
    </Icon>
  ),
)

Upload02Icon.displayName = 'Upload02Icon'
Upload02Icon.defaultProps = iconDefaultProps

export type { Upload02IconProps }
export { Upload02Icon }
