import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Sliders02IconProps = IconProps

const Sliders02Icon = forwardRef<SVGSVGElement, Sliders02IconProps>(
  (props: Sliders02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 21v-6m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-8V3m7 18v-6m0-8V3m0 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm7 14v-4m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0-8V3"
        fill="none"
      ></path>
    </Icon>
  ),
)

Sliders02Icon.displayName = 'Sliders02Icon'
Sliders02Icon.defaultProps = iconDefaultProps

export type { Sliders02IconProps }
export { Sliders02Icon }
