import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { toRem } from '../utils'
import Flex, { FlexProps } from '../Flex'

function styles(): CSSObject {
  return {
    '[data-gourmet-input], [data-gourmet-button], [data-gourmet-text]': {
      position: 'relative',
      zIndex: 1,

      '&:hover, &:focus': {
        zIndex: 2,
      },
    },

    [[
      '[data-gourmet-autocomplete]:not(:first-child):not(:last-child) [data-gourmet-autocomplete-input]',
      '[data-gourmet-button]:not(:first-child):not(:last-child)',
      '[data-gourmet-flex]:not([data-gourmet-input-group]):not(:first-child):not(:last-child)',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input]):not(:first-child):not(:last-child)',
      '[data-gourmet-listbox]:not(:first-child):not(:last-child) [data-gourmet-listbox-button]',
      '[data-gourmet-text]:not(:first-child):not(:last-child)',
    ].join(',')]: {
      borderRadius: 0,
    },

    [[
      '[data-gourmet-input-group]:not(:last-child) [data-gourmet-button]',
      '[data-gourmet-input-group]:not(:last-child) [data-gourmet-input]',
      '[data-gourmet-input-group]:not(:last-child) [data-gourmet-text]',
    ].join(',')]: {
      borderRadius: 0,
    },

    [[
      '[data-gourmet-autocomplete]',
      '[data-gourmet-button]',
      '[data-gourmet-flex]:not([data-gourmet-input-group])',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input])',
      '[data-gourmet-listbox]',
      '[data-gourmet-text]',
    ]
      .map(
        (selector) =>
          `&:not([data-stacked])[data-with-gaps] > ${selector}:not(:last-child)`,
      )
      .join(',')]: {
      marginRight: toRem(1),
    },

    [[
      '[data-gourmet-autocomplete]',
      '[data-gourmet-button]',
      '[data-gourmet-flex]:not([data-gourmet-input-group])',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input])',
      '[data-gourmet-listbox]',
      '[data-gourmet-text]',
    ]
      .map(
        (selector) =>
          `&:not([data-stacked]):not([data-with-gaps]) > ${selector}:not(:last-child)`,
      )
      .join(',')]: {
      marginRight: toRem(-1),
    },

    [[
      '&:not([data-stacked]) > [data-gourmet-autocomplete]:first-child [data-gourmet-autocomplete-input]',
      '&:not([data-stacked]) > [data-gourmet-button]:first-child',
      '&:not([data-stacked]) > [data-gourmet-flex]:not([data-gourmet-input-group]):first-child',
      '&:not([data-stacked]) > [data-gourmet-input]:not([data-gourmet-autocomplete-input]):first-child',
      '&:not([data-stacked]) > [data-gourmet-listbox]:first-child [data-gourmet-listbox-button]',
      '&:not([data-stacked]) > [data-gourmet-text]:first-child',
    ].join(',')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    [[
      '&:not([data-stacked]) > [data-gourmet-autocomplete]:last-child [data-gourmet-autocomplete-input]',
      '&:not([data-stacked]) > [data-gourmet-button]:last-child',
      '&:not([data-stacked]) > [data-gourmet-flex]:not([data-gourmet-input-group]):last-child',
      '&:not([data-stacked]) > [data-gourmet-input]:not([data-gourmet-autocomplete-input]):last-child',
      '&:not([data-stacked]) > [data-gourmet-listbox]:last-child [data-gourmet-listbox-button]',
      '&:not([data-stacked]) > [data-gourmet-text]:last-child',
    ].join(',')]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    [[
      '[data-gourmet-autocomplete]',
      '[data-gourmet-button]',
      '[data-gourmet-flex]:not([data-gourmet-input-group])',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input])',
      '[data-gourmet-listbox]',
      '[data-gourmet-text]',
    ]
      .map(
        (selector) =>
          `&[data-stacked][data-with-gaps] > ${selector}:not(:last-child), &[data-stacked][data-with-gaps] > [data-gourmet-input-group]:not(:last-child) ${selector}`,
      )
      .join(',')]: {
      marginBottom: toRem(1),
    },

    [[
      '[data-gourmet-autocomplete]',
      '[data-gourmet-button]',
      '[data-gourmet-flex]:not([data-gourmet-input-group])',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input])',
      '[data-gourmet-listbox]',
      '[data-gourmet-text]',
    ]
      .map(
        (selector) =>
          `&[data-stacked]:not([data-with-gaps]) > ${selector}:not(:last-child), &[data-stacked]:not([data-with-gaps]) > [data-gourmet-input-group]:not(:last-child) ${selector}`,
      )
      .join(',')]: {
      marginBottom: toRem(-1),
    },

    [[
      '[data-gourmet-autocomplete]',
      '[data-gourmet-button]',
      '[data-gourmet-flex]:not([data-gourmet-input-group])',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input])',
      '[data-gourmet-listbox]',
      '[data-gourmet-text]',
    ]
      .map((selector) => `&[data-stacked] > ${selector}:first-child`)
      .join(',')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    [[
      '[data-gourmet-autocomplete]',
      '[data-gourmet-button]',
      '[data-gourmet-flex]:not([data-gourmet-input-group])',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input])',
      '[data-gourmet-listbox]',
      '[data-gourmet-text]',
    ]
      .map((selector) => `&[data-stacked] > ${selector}:last-child`)
      .join(',')]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },

    [[
      '[data-gourmet-autocomplete]',
      '[data-gourmet-button]',
      '[data-gourmet-flex]:not([data-gourmet-input-group])',
      '[data-gourmet-input]:not([data-gourmet-autocomplete-input])',
      '[data-gourmet-listbox]',
      '[data-gourmet-text]',
    ]
      .map(
        (selector) =>
          `&[data-stacked] > [data-gourmet-input-group]:not([data-stacked]) > ${selector}`,
      )
      .join(',')]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }
}

const StyledFormFieldLayout = styled(Flex)(styles)

type Props = React.HTMLAttributes<HTMLDivElement> &
  Pick<FlexProps, 'fullWidth'> & {
    /**
     * If `true`, the form field elements will be stacked, akin to
     * using `<Flex column>...</Flex>`.
     */
    stacked?: boolean
    /**
     * Adds gaps/spacing between form field elements within the group.
     */
    withGaps?: boolean
  }

/**
 * Provides a way to layout form-related components together like
 * `<Input>`, `<Button>`, `<Select>` etc. to create inline, stacked, and/or
 * combined user input layouts for forms.
 *
 * @example
 * <FormFieldLayout stacked fullWidth>
 *   <Input
 *     leftIcon={<CreditCard01Icon />}
 *     fullWidth
 *     placeholder="1234 1234 1234 1234"
 *   />
 *   <FormFieldLayout fullWidth>
 *     <Input leftIcon={<CalendarDateIcon />} fullWidth placeholder="MM / YY" />
 *     <Input leftIcon={<Lock03Icon />} fullWidth placeholder="CVC" />
 *   </FormFieldLayout>
 * </FormFieldLayout>
 */
const FormFieldLayout = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function FormFieldLayout(
  {
    children,
    fullWidth,
    stacked,
    withGaps,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  return (
    <StyledFormFieldLayout
      {...(fullWidth && { 'data-full-width': '' })}
      {...(stacked && { 'data-stacked': '' })}
      {...(withGaps && { 'data-with-gaps': '' })}
      {...props}
      alignItems="stretch"
      column={stacked}
      data-gourmet-input-group=""
      fullWidth={fullWidth}
      ref={ref}
    >
      {children}
    </StyledFormFieldLayout>
  )
})

FormFieldLayout.displayName = 'FormFieldLayout'

FormFieldLayout.defaultProps = {
  fullWidth: false,
  stacked: false,
  withGaps: false,
}

export { FormFieldLayout }
export type { Props as FormFieldLayoutProps }
