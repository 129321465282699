import React, { useState } from 'react'
import { ProductsSlice } from '../../../state-management/ProductStore'
import { useGlobalStore } from '../../../state-management/store'
import { StoreProductTypeaheadSearchResultDto } from '@vori/dashboard-rest-next/schemas'
import { SearchSmIcon } from '@vori/gourmet-icons'
import { Command } from 'cmdk'
import ProductSearchDetailView from './ProductSearchDetailView'

import { UserSlice } from 'web/dashboard/src/state-management/UserStore'
import { Loader } from '@vori/gourmet-components'
import {
  ProductSearchContainer,
  ProductSearchFooter,
  ProductSearchFooterIcons,
  ProductSearchHeader,
  ProductSearchResult,
} from './ProductSearchStyledComponents'
import { GlobalModalSlice } from 'web/dashboard/src/state-management/GlobalModalStore'
import RecentProductSearches from './RecentProductSearches'

const QUERY_DEBOUNCE_MS = 400 as const
const SPLIT_DELIMITER = '#-#' as const

let inputTimeoutId: NodeJS.Timeout | null = null

const ProductSearch = () => {
  const searchProduct = useGlobalStore(
    (state: ProductsSlice) => state.searchProduct,
  )
  const loading = useGlobalStore((state: ProductsSlice) => state.loading)
  const selectedStoreID = useGlobalStore(
    (state: UserSlice) => state.metadata?.selectedStoreID,
  )

  const canShowRecentSearches = useGlobalStore(
    (state: ProductsSlice) => state.canShowRecentSearches,
  )

  const productSearchResults: StoreProductTypeaheadSearchResultDto[] =
    useGlobalStore((state: ProductsSlice) => state.productSearchResults)

  const toggleDisplay = useGlobalStore(
    (state: GlobalModalSlice) => state.toggleDisplay,
  )

  const [value, setValue] = useState('')
  const [isChanging, setIsChanging] = useState(false)
  const [isPristine, setIsPristine] = useState(true)
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1)

  const handleInputChange = (value: string) => {
    setIsPristine(false)
    setValue(value)
    setIsChanging(true)
    if (inputTimeoutId) {
      clearTimeout(inputTimeoutId)
    }

    if (selectedStoreID) {
      inputTimeoutId = setTimeout(() => {
        searchProduct(selectedStoreID, value)
        setIsChanging(false)
      }, QUERY_DEBOUNCE_MS)
    } else {
      throw new Error('Encountered a missing store id for the current user')
    }
  }

  const renderNoResults = () => {
    if (!isPristine && !loading && !isChanging) {
      return (
        <Command.Empty className="product-search-empty">
          No results found.
        </Command.Empty>
      )
    } else {
      return null
    }
  }

  const handleItemDelegateClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const trueTarget = event.target as HTMLElement
    const { itemIndex = null } = trueTarget.dataset

    if (itemIndex) {
      setSelectedProductIndex(+itemIndex)
    }
  }

  const handleItemSelected = (value: string) => {
    setSelectedProductIndex(+value.split(SPLIT_DELIMITER)[0])
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
    const trueTarget = event.target as HTMLElement
    const { itemIndex = null } = trueTarget.dataset

    if (itemIndex !== null) {
      setSelectedProductIndex(+itemIndex)
    }
  }

  const handleViewProduct = () => {
    setValue('')
    toggleDisplay(false)
  }

  return (
    <ProductSearchContainer>
      <Command loop onMouseOver={handleMouseOver}>
        <ProductSearchHeader>
          {loading || isChanging ? <Loader /> : <SearchSmIcon />}
          <Command.Input
            placeholder="Search Products"
            onValueChange={handleInputChange}
            value={value}
          />
        </ProductSearchHeader>
        <ProductSearchResult>
          {!canShowRecentSearches ? (
            <>
              <Command.List>
                <div className="product-search-results-left">
                  <Command.Group forceMount onClick={handleItemDelegateClick}>
                    {isChanging === false && productSearchResults.length
                      ? productSearchResults.map((item, idx) => (
                          <Command.Item
                            className={
                              selectedProductIndex === idx
                                ? 'product-search-result-item product-search-selected-item'
                                : 'product-search-result-item'
                            }
                            key={item.id}
                            data-item-index={idx}
                            value={`${idx}${SPLIT_DELIMITER}${item.name}`}
                            onSelect={handleItemSelected}
                          >
                            <h6>{item.name}</h6>
                            {item.brand && <span>{item.brand}</span>}
                            {item.barcode && (
                              <span> &#x2022; {item.barcode}</span>
                            )}
                            {item.unit_volume && (
                              <span> &#x2022; {item.unit_volume}</span>
                            )}
                          </Command.Item>
                        ))
                      : renderNoResults()}
                  </Command.Group>
                </div>
              </Command.List>
              <div className="product-search-results-right">
                <ProductSearchDetailView
                  data={productSearchResults[selectedProductIndex] || null}
                  ctaHandler={handleViewProduct}
                />
              </div>
            </>
          ) : (
            <RecentProductSearches redoPastSearch={handleInputChange} />
          )}
        </ProductSearchResult>
        {!canShowRecentSearches ? (
          <ProductSearchFooter>
            <div>
              <ProductSearchFooterIcons>&#8593;</ProductSearchFooterIcons>
              <ProductSearchFooterIcons>&#8595;</ProductSearchFooterIcons>
              <span> to navigate</span>
            </div>
            <div>
              <ProductSearchFooterIcons>enter</ProductSearchFooterIcons>
              <span> to select</span>
            </div>
            <div>
              <ProductSearchFooterIcons>esc</ProductSearchFooterIcons>
              <span> to close</span>
            </div>
          </ProductSearchFooter>
        ) : null}
      </Command>
    </ProductSearchContainer>
  )
}

export default ProductSearch
