import { foundations, TokenFoundationSizeIcon } from '@vori/gourmet-tokens'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

type IconSize<T extends string> = T extends `size.icon-${infer Size}`
  ? Size
  : never

type IconProps = React.SVGAttributes<SVGSVGElement> &
  Partial<Record<`data-${string}`, string>> & {
    size?:
      | /** @deprecated - use `lg` instead */ 'base'
      | /** @deprecated - use `md` instead */ 'medium'
      | /** @deprecated - use `sm` instead */ 'small'
      | /** @deprecated - use `xl` instead */ 'large'
      | /** @deprecated - use `xs` instead */ 'tiny'
      | /** @deprecated */ 'xLarge'
      | IconSize<TokenFoundationSizeIcon>
    variant?:
      | 'default'
      | 'negative'
      | 'positive'
      | 'primary'
      | 'secondary'
      | 'warning'
      | /** @deprecated - use `warning` instead */ 'progress'
    withContainer?: boolean
  }

const defaultProps: Partial<IconProps> = {
  className: '',
  size: 'md',
  withContainer: false,
}

const iconSize = {
  tiny: `${8 / 16}rem`,
  small: `${12 / 16}rem`,
  medium: `${16 / 16}rem`,
  base: `${24 / 16}rem`,
  large: `${48 / 16}rem`,
  xLarge: `${64 / 16}rem`,
  xs: foundations.size['size.icon-xs'],
  sm: foundations.size['size.icon-sm'],
  md: foundations.size['size.icon-md'],
  lg: foundations.size['size.icon-lg'],
  xl: foundations.size['size.icon-xl'],
  xxl: foundations.size['size.icon-xxl'],
}

const IconContainer = styled.div(() => ({
  alignItems: 'center',
  borderRadius: '50%',
  display: 'inline-flex',
  flexShrink: 0,
  justifyContent: 'center',
  transition: 'all 350ms cubic-bezier(0.215, 0.61, 0.355, 1)',

  '&[data-variant="default"]': {
    backgroundColor: foundations.color['color.gray-100'],
  },

  '&[data-variant="negative"]': {
    backgroundColor: foundations.color['color.error-200'],
  },

  '&[data-variant="positive"]': {
    backgroundColor: foundations.color['color.success-200'],
  },

  '&[data-variant="primary"]': {
    backgroundColor: foundations.color['color.primary-200'],
  },

  '&[data-variant="progress"], &[data-variant="warning"]': {
    backgroundColor: foundations.color['color.warning-200'],
  },

  '&[data-variant="secondary"]': {
    backgroundColor: foundations.color['color.gray-200'],
  },

  ...Object.fromEntries(
    Array.from(Object.entries(iconSize)).map(([key, value]) => [
      `&[data-size="${key}"]`,
      { height: `calc(${value} * 1.65)`, width: `calc(${value} * 1.65)` },
    ]),
  ),
}))

const Icon = styled(
  forwardRef<SVGSVGElement, IconProps>(function Icon(
    { children, size = 'md', variant, withContainer, ...props }: IconProps,
    ref,
  ) {
    const icon = (
      <svg
        aria-hidden="true"
        data-gourmet-icon=""
        data-variant={variant}
        height={Number.parseFloat(iconSize[size].replace('rem', '')) * 16}
        preserveAspectRatio="xMinYMin meet"
        ref={ref}
        viewBox="0 0 24 24"
        width={Number.parseFloat(iconSize[size].replace('rem', '')) * 16}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        {children}
      </svg>
    )

    return withContainer ? (
      <IconContainer
        aria-hidden="true"
        data-gourmet-icon-container=""
        data-size={size}
        data-variant={variant || 'default'}
      >
        {icon}
      </IconContainer>
    ) : (
      icon
    )
  }),
)<IconProps>(() => ({
  '&[data-variant="default"]': {
    color: foundations.color['color.gray-900'],
  },

  '&[data-variant="negative"]': {
    color: foundations.color['color.error-600'],
  },

  '&[data-variant="positive"]': {
    color: foundations.color['color.success-600'],
  },

  '&[data-variant="primary"]': {
    color: foundations.color['color.primary-500'],
  },

  '&[data-variant="progress"], &[data-variant="warning"]': {
    color: foundations.color['color.warning-600'],
  },

  '&[data-variant="secondary"]': {
    color: foundations.color['color.gray-500'],
  },

  '> *': {
    transition: 'all 350ms cubic-bezier(0.215, 0.61, 0.355, 1)',
  },
}))

Icon.displayName = 'Icon'
Icon.defaultProps = defaultProps

export { Icon, defaultProps as iconDefaultProps }
export type { IconProps }
