import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LineChartDown04IconProps = IconProps

const LineChartDown04Icon = forwardRef<SVGSVGElement, LineChartDown04IconProps>(
  (props: LineChartDown04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m3 9 6.655 4.754a.5.5 0 0 0 .645-.053l3.4-3.402a.5.5 0 0 1 .645-.053L21 15M6 21h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LineChartDown04Icon.displayName = 'LineChartDown04Icon'
LineChartDown04Icon.defaultProps = iconDefaultProps

export type { LineChartDown04IconProps }
export { LineChartDown04Icon }
