/**
 * Used to format dates into `Mmm D, YYY`.
 *
 * @todo This date formatting utility is also used on the `web-dashboard`
 * package, so moving forward, it would be better to move this and other
 * date-related utilities to their own package.
 */
export function formatDate(
  /**
   * A valid date to be formatted.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
   */
  date: Date,
): string {
  return new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date)
}

/**
 * Used to format dates into `Mmm D, YYY hh:mm:ss A`.
 *
 * @todo This date formatting utility is also used on the `web-dashboard`
 * package, so moving forward, it would be better to move this and other
 * date-related utilities to their own package.
 */
export function formatDateTime(
  /**
   * A valid date to be formatted.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
   */
  date: Date,
): string {
  return new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(date)
}
