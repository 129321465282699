import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyPoundIconProps = IconProps

const CurrencyPoundIcon = forwardRef<SVGSVGElement, CurrencyPoundIconProps>(
  (props: CurrencyPoundIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.5 20.5h-11s3.5-2.759 3.5-7c0-2.828-2.086-3.839-2.116-6.195.002-4.664 5.617-4.416 7.568-2.562M6.5 13.5H15"
        fill="none"
      ></path>
    </Icon>
  ),
)

CurrencyPoundIcon.displayName = 'CurrencyPoundIcon'
CurrencyPoundIcon.defaultProps = iconDefaultProps

export type { CurrencyPoundIconProps }
export { CurrencyPoundIcon }
