import React from 'react'

import { Card, CardProps } from '../CardNext'

type Props = CardProps

/**
 * Provides basic styling for rendering the contents of a `<Modal>` component.
 *
 * @example
 * function ExampleModal(): React.ReactElement {
 *   const { ref, ...modal } = useModal()
 *
 *   return (
 *     <Modal ref={ref} {...modal}>
 *       <ModalContent>
 *         <Text>This is the modal content</Text>
 *       </ModalContent>
 *     </Modal>
 *   )
 * }
 */
const ModalContent = React.forwardRef<HTMLDivElement, Props>(
  function ModalContent({ children, ...props }: Props, ref): JSX.Element {
    return (
      <Card
        direction="column"
        rowGap="space.100"
        size="container-40"
        {...props}
        data-gourmet-modal-content=""
        ref={ref}
      >
        {children}
      </Card>
    )
  },
)

ModalContent.displayName = 'ModalContent'
ModalContent.defaultProps = {}

export { ModalContent }
export type { Props as ModalContentProps }
