import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GridDotsVerticalCenterIconProps = IconProps

const GridDotsVerticalCenterIcon = forwardRef<
  SVGSVGElement,
  GridDotsVerticalCenterIconProps
>((props: GridDotsVerticalCenterIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 3h.01M3 21h.01M3 16.5h.01M3 7.5h.01M7.5 3h.01M7.5 21h.01M16.5 3h.01m-.01 18h.01M12 3h.01M12 21h.01M12 16.5h.01m-.01-9h.01M21 3h.01M21 21h.01M21 16.5h.01m-.01-9h.01M21 12H3"
      fill="none"
    ></path>
  </Icon>
))

GridDotsVerticalCenterIcon.displayName = 'GridDotsVerticalCenterIcon'
GridDotsVerticalCenterIcon.defaultProps = iconDefaultProps

export type { GridDotsVerticalCenterIconProps }
export { GridDotsVerticalCenterIcon }
