import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DropIconProps = IconProps

const DropIcon = forwardRef<SVGSVGElement, DropIconProps>(
  (props: DropIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 14a8 8 0 1 1-15.418-3C5.768 8.068 12 2 12 2s6.232 6.068 7.419 9A7.98 7.98 0 0 1 20 14Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

DropIcon.displayName = 'DropIcon'
DropIcon.defaultProps = iconDefaultProps

export type { DropIconProps }
export { DropIcon }
