import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MagicWand02IconProps = IconProps

const MagicWand02Icon = forwardRef<SVGSVGElement, MagicWand02IconProps>(
  (props: MagicWand02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 4V2m0 14v-2M8 9h2m10 0h2m-4.2 2.8L19 13m-1.2-6.8L19 5M3 21l9-9m.2-5.8L11 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

MagicWand02Icon.displayName = 'MagicWand02Icon'
MagicWand02Icon.defaultProps = iconDefaultProps

export type { MagicWand02IconProps }
export { MagicWand02Icon }
