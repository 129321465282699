import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BracketsXIconProps = IconProps

const BracketsXIcon = forwardRef<SVGSVGElement, BracketsXIconProps>(
  (props: BracketsXIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.57 20a2.286 2.286 0 0 0 2.287-2.286v-4.571L22 12l-1.143-1.143V6.286A2.285 2.285 0 0 0 18.57 4M5.429 4a2.285 2.285 0 0 0-2.286 2.286v4.571L2 12l1.143 1.143v4.571A2.285 2.285 0 0 0 5.429 20M15 9l-6 6m0-6 6 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

BracketsXIcon.displayName = 'BracketsXIcon'
BracketsXIcon.defaultProps = iconDefaultProps

export type { BracketsXIconProps }
export { BracketsXIcon }
