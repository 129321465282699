import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CornerUpLeftIconProps = IconProps

const CornerUpLeftIcon = forwardRef<SVGSVGElement, CornerUpLeftIconProps>(
  (props: CornerUpLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 14 4 9m0 0 5-5M4 9h6.4c3.36 0 5.04 0 6.324.654a6 6 0 0 1 2.622 2.622C20 13.56 20 15.24 20 18.6V20"
        fill="none"
      ></path>
    </Icon>
  ),
)

CornerUpLeftIcon.displayName = 'CornerUpLeftIcon'
CornerUpLeftIcon.defaultProps = iconDefaultProps

export type { CornerUpLeftIconProps }
export { CornerUpLeftIcon }
