import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LinkBroken02IconProps = IconProps

const LinkBroken02Icon = forwardRef<SVGSVGElement, LinkBroken02IconProps>(
  (props: LinkBroken02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8.5 15.5 7-7M9 4V2m6 18v2M4 9H2m18 6h2M4.914 4.914 3.5 3.5m15.586 15.586L20.5 20.5M12 17.657l-2.121 2.121a4 4 0 1 1-5.657-5.657L6.343 12m11.314 0 2.121-2.121a4 4 0 0 0-5.657-5.657L12 6.343"
        fill="none"
      ></path>
    </Icon>
  ),
)

LinkBroken02Icon.displayName = 'LinkBroken02Icon'
LinkBroken02Icon.defaultProps = iconDefaultProps

export type { LinkBroken02IconProps }
export { LinkBroken02Icon }
