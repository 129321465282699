import React from 'react'
import styled from 'styled-components'

import { PlusCircleIcon } from '@vori/gourmet-icons'

import {
  assignRef,
  Autocomplete,
  AutocompleteProps,
  Clickable,
  composeEventHandlers,
  Flex,
  foundation,
  Input,
  InputProps,
  InputNextProps,
  sizing,
  Tag,
  useControlledState,
} from '@vori/gourmet-components'

import { usePreviousValue } from '@vori/react-hooks'

const Container = styled(Input)({
  backgroundColor: foundation.colors.pureWhite,
  flexWrap: 'wrap',
  maxHeight: 'unset',
  minHeight: sizing.input.base,
  paddingBottom: 0,
  paddingTop: 0,

  '[data-gourmet-input]': {
    borderRadius: 0,
  },

  '[data-gourmet-tag]': {
    marginBottom: `calc(${foundation.spacing.tiny} / 1.15)`,
    marginRight: `${foundation.spacing.tiny}`,
    marginTop: `calc(${foundation.spacing.tiny} / 1.15)`,
  },

  '[data-gourmet-tag] [data-gourmet-button]': {
    lineHeight: 1,
    minHeight: 'unset',
    minWidth: 'unset',
  },

  '[data-gourmet-tag-content-right] [data-gourmet-icon]': {
    height: 8,
    width: 8,
  },

  '[data-gourmet-tag] [data-gourmet-avatar]': {
    height: 14,
    width: 14,
  },

  '[data-reach-combobox]': {
    width: '100%',
  },

  '[data-reach-combobox-popover]': {
    left: 0,
    position: 'absolute',
    top: `calc(100% + ${foundation.spacing.tiny})`,
    width: '100%',
  },

  '[data-reach-combobox-popover] > [data-gourmet-spacer]': {
    display: 'none',
  },
})

const CreateValueButton = styled(Clickable)({
  justifyContent: 'flex-start',
  paddingLeft: foundation.spacing.tiny,
  paddingRight: foundation.spacing.tiny,
  textAlign: 'left',
})

type Props<T = void> = InputProps &
  Pick<
    AutocompleteProps<T>,
    | 'getOptionMenuItemProps'
    | 'openOnFocus'
    | 'options'
    | 'renderOption'
    | 'showEmpty'
    | 'showLoader'
  > & {
    /**
     * Will add the option to create new values when `getExactMatch` fails to
     * find a match based on the current user input.
     */
    allowCreationOfValues?: boolean
    /**
     * Persists user input on blur, if the user did not selected or created an
     * option.
     */
    allowUserInputAsValue?: boolean
    /**
     * Used internally to create options based on user input, so that it matches
     * the structure of the data used as options.
     */
    createOption?: (value: string) => T
    /**
     * Array of options to be selected by default.
     */
    defaultSelectedOptions?: Array<T>
    /**
     * Prevents user input from being cleaned when creating new options.
     */
    disableValueCleanup?: boolean
    /**
     * Used to determine what `key` from the given options (when using objects)
     * to use as the value to display when making selections.
     */
    getDisplayValue?: (option: T | null, value: string) => string
    /**
     * Function used to match the user input with the given options. This is
     * used when `allowCreationOfValues` is true.
     */
    getExactMatch?: (value: string) => T | null
    /**
     * Used to specify what `key` from the given options should be used as
     * the value.
     */
    getOptionValue?: AutocompleteProps<T>['getOptionValue']
    /**
     * Used to determine how to match an option using a value.
     *
     * @see `getOptionValue`
     */
    getOriginalOption?: (value: string) => T | null
    /**
     * Determines the maximum amount of values that can be selected/created.
     */
    maxValues?: number
    /**
     * Callback function for when a value gets created.
     */
    onAddValue?: (value: string, option: T) => void
    /**
     * Callback function for when the value changes (add or remove).
     */
    onChangeValue?: (value: string, values: Array<T>) => void
    /**
     * Callback function for when a value gets removed.
     */
    onRemoveValue?: (value: string, option: T) => void
    /**
     * Used to define how tags should be used for selected values.
     */
    renderTag?: (option: T | null, value: string) => React.ReactNode
    /**
     * Selected values to be passed making it a controlled component.
     */
    selectedValues?: Array<T>
  }

/**
 * An autocomplete input with the ability to select multiple values.
 */
const TaggedAutocompleteInput = React.forwardRef<HTMLInputElement, Props>(
  function TaggedAutocompleteInput<T>(
    {
      allowCreationOfValues,
      allowUserInputAsValue,
      createOption: externalCreateOption,
      defaultSelectedOptions,
      defaultValue,
      disabled,
      disableValueCleanup,
      form,
      getDisplayValue: externalGetDisplayValue,
      getExactMatch: externalGetExactMatch,
      getOptionMenuItemProps,
      id,
      name,
      getOptionValue: externalGetOptionValue,
      getOriginalOption: externalGetOriginalOption,
      maxValues = Infinity,
      onAddValue,
      onBlur,
      onChange,
      onChangeValue,
      onKeyDown,
      onRemoveValue,
      openOnFocus,
      options,
      readOnly,
      renderOption,
      renderTag: externalRenderTag,
      selectedValues: externalSelectedValues,
      showEmpty,
      showLoader,
      ...props
    }: Props<T>,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) {
    const containerRef = React.useRef<HTMLDivElement | null>(null)
    const inputRef = React.useRef<HTMLInputElement | null>(null)

    const [value, setValue] = useControlledState<string>({
      componentName: 'TaggedAutocompleteInput',
      controlledValue: props.value?.toString(),
      defaultValue: defaultValue?.toString() ?? '',
    })

    const [inputVisibleValue, setInputVisibleValue] = React.useState<string>('')
    const prevValue = usePreviousValue(value)

    const getOptionValue = React.useCallback<
      NonNullable<Props<T>['getOptionValue']>
    >(
      (option) => externalGetOptionValue?.(option) || `${option}`,
      [externalGetOptionValue],
    )

    const createOption = React.useCallback<
      NonNullable<Props<T>['createOption']>
    >(
      (value) => externalCreateOption?.(value) || (value as unknown as T),
      [externalCreateOption],
    )

    const [selectedOptions, setSelectedOptions] = useControlledState({
      componentName: 'TaggedAutocompleteInput',
      controlledValue: externalSelectedValues,
      defaultValue:
        defaultSelectedOptions ||
        (defaultValue ? [createOption(defaultValue.toString())] : []),
    })

    const getOriginalOption = React.useCallback<
      NonNullable<Props<T>['getOriginalOption']>
    >(
      (value) => {
        return (
          externalGetOriginalOption?.(value) ||
          [...options, ...selectedOptions].find(
            (option) => `${option}` === value,
          ) ||
          null
        )
      },
      [externalGetOriginalOption, options, selectedOptions],
    )

    const getExactMatch = React.useCallback<
      NonNullable<Props<T>['getExactMatch']>
    >(() => {
      if (externalGetExactMatch) {
        return externalGetExactMatch(inputVisibleValue)
      }

      const allOptions = [...options, ...selectedOptions]

      if (getOptionValue) {
        return (
          allOptions.find(
            (option) => inputVisibleValue === getOptionValue(option),
          ) || null
        )
      }

      return (
        allOptions.find((option) => inputVisibleValue === `${option}`) || null
      )
    }, [
      externalGetExactMatch,
      getOptionValue,
      options,
      inputVisibleValue,
      selectedOptions,
    ])

    const getDisplayValue = React.useCallback<
      NonNullable<Props<T>['getDisplayValue']>
    >(
      (option, value) => {
        return (
          externalGetDisplayValue?.(option, value) ||
          (option ? `${option}` : value)
        )
      },
      [externalGetDisplayValue],
    )

    const renderTag = React.useCallback<NonNullable<Props<T>['renderTag']>>(
      (option, value) => {
        return (
          externalRenderTag?.(option, value) ||
          (option ? getDisplayValue(option, value) : value)
        )
      },
      [externalRenderTag, getDisplayValue],
    )

    const addValue = React.useCallback(
      (valueToAdd: string) => {
        const cleanValueToAdd = disableValueCleanup
          ? valueToAdd
          : valueToAdd.trim()

        const hasMaxValues = selectedOptions.length === maxValues

        const valueAsOption =
          getOriginalOption(cleanValueToAdd) || createOption(cleanValueToAdd)

        setValue((prevValues) =>
          hasMaxValues
            ? getOptionValue(valueAsOption)
            : `${prevValues ? `${prevValues},` : ''}${getOptionValue(
                valueAsOption,
              )}`,
        )

        setSelectedOptions((prevSelectedOptions) => [
          ...(hasMaxValues ? [] : prevSelectedOptions),
          valueAsOption,
        ])

        setInputVisibleValue('')
        onAddValue?.(cleanValueToAdd, valueAsOption)
      },
      [
        createOption,
        disableValueCleanup,
        getOptionValue,
        getOriginalOption,
        maxValues,
        onAddValue,
        selectedOptions.length,
        setSelectedOptions,
        setValue,
      ],
    )

    const removeValue = React.useCallback(
      (valueToRemove: string) => {
        const cleanValueToRemove = disableValueCleanup
          ? valueToRemove
          : valueToRemove.trim()

        setValue((prevValues) =>
          prevValues
            .split(',')
            .filter((prevValue) => prevValue !== cleanValueToRemove)
            .join(','),
        )

        setSelectedOptions((prevSelectedOptions) =>
          prevSelectedOptions.filter(
            (option) => getOptionValue(option) !== cleanValueToRemove,
          ),
        )

        onRemoveValue?.(
          cleanValueToRemove,
          getOriginalOption(cleanValueToRemove) as T,
        )
      },
      [
        disableValueCleanup,
        getOptionValue,
        getOriginalOption,
        onRemoveValue,
        setSelectedOptions,
        setValue,
      ],
    )

    const removeLastValue = React.useCallback(() => {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.slice(0, -1),
      )

      setValue((prevValues) => prevValues.split(',').slice(0, -1).join(','))
    }, [setSelectedOptions, setValue])

    React.useEffect(() => {
      if (prevValue !== undefined && prevValue !== value) {
        onChangeValue?.(value, selectedOptions)
      }
    }, [onChangeValue, prevValue, selectedOptions, value])

    return (
      <Container
        {...props}
        disabled={disabled}
        fullWidth
        readOnly={readOnly}
        tabIndex={-1}
        onFocus={() => {
          if (inputRef.current) {
            inputRef.current.focus()
          }
        }}
        variant="default"
      >
        {selectedOptions.map((option) => {
          const valueFromOption = getOptionValue(option)

          return (
            <Tag
              key={valueFromOption}
              label={valueFromOption}
              sizing="small"
              asRemovable={!disabled && !readOnly}
              onTagRemove={() => {
                removeValue(valueFromOption)
              }}
            >
              {renderTag(option, valueFromOption)}
            </Tag>
          )
        })}
        <Flex centerY inline flex={1}>
          <Autocomplete
            options={options}
            onSelect={(value) => {
              addValue(value)
            }}
            getOptionValue={getOptionValue}
            openOnFocus={openOnFocus}
            ref={containerRef}
            renderOption={renderOption}
            renderPopupInline
            showEmpty={showEmpty}
            showLoader={showLoader}
            useEditable
            getOptionMenuItemProps={(option) => {
              const controlledOptions = getOptionMenuItemProps?.(option)

              return {
                ...controlledOptions,
                onMouseDown: composeEventHandlers(
                  controlledOptions?.onMouseDown,
                  (event) => {
                    // Prevents the input's `blur` event from firing so we can
                    // select a value from the list on click.
                    event.preventDefault()
                  },
                ),
              }
            }}
            popupProps={{
              fullWidth: true,
            }}
            renderFooter={
              allowCreationOfValues &&
              inputVisibleValue &&
              !getExactMatch(inputVisibleValue) &&
              !showLoader
                ? () => (
                    <CreateValueButton
                      fullWidth
                      leftIcon={<PlusCircleIcon />}
                      onMouseDown={(event) => {
                        event.stopPropagation()
                        addValue(inputVisibleValue)
                      }}
                      variant="primary"
                    >
                      Create &quot;{inputVisibleValue}&quot;
                    </CreateValueButton>
                  )
                : undefined
            }
            inputProps={{
              ...(props as InputNextProps),
              autoComplete: 'off',
              autocomplete: false,
              disabled,
              form,
              fullWidth: true,
              id,
              name,
              noPadding: true,
              readOnly,
              onBlur: composeEventHandlers(onBlur, (event) => {
                const value = event.target.value

                if (allowUserInputAsValue && value) {
                  addValue(value)
                } else {
                  setInputVisibleValue('')
                }
              }),
              onChange: composeEventHandlers(onChange, (event) => {
                const value = event.target.value

                setInputVisibleValue(
                  getDisplayValue(getOriginalOption(value), value),
                )
              }),
              onKeyDown: composeEventHandlers(onKeyDown, (event) => {
                const value = (event.target as HTMLInputElement).value

                switch (event.nativeEvent.code) {
                  case 'Comma':
                  case 'Enter': {
                    event.preventDefault()

                    if (allowUserInputAsValue && value) {
                      addValue(value)
                    }

                    break
                  }

                  case 'Backspace': {
                    if (!value) {
                      event.preventDefault()
                      removeLastValue()
                    }

                    break
                  }

                  default: {
                    break
                  }
                }
              }),
              ref: (input) => {
                assignRef(ref, input)
                assignRef(inputRef, input)
              },
              value: getDisplayValue(
                getOriginalOption(inputVisibleValue),
                inputVisibleValue,
              ),
            }}
          />
        </Flex>
      </Container>
    )
  },
)

export default TaggedAutocompleteInput as <T>(
  props: Props<T> & { ref?: React.Ref<HTMLInputElement> },
) => React.ReactElement
