import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudRaining06IconProps = IconProps

const CloudRaining06Icon = forwardRef<SVGSVGElement, CloudRaining06IconProps>(
  (props: CloudRaining06IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 21H6m13-3h-9m-3 0H5m13 3h-2m-9-6a5 5 0 1 1 .1-9.999 5.502 5.502 0 0 1 10.195 1.004A4.5 4.5 0 1 1 17.5 15H6.999Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudRaining06Icon.displayName = 'CloudRaining06Icon'
CloudRaining06Icon.defaultProps = iconDefaultProps

export type { CloudRaining06IconProps }
export { CloudRaining06Icon }
