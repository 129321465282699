import React from 'react'
import ReactDOM from 'react-dom/server'
import styled from 'styled-components'

import { foundations } from '@vori/gourmet-tokens'

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MinusIcon,
} from '@vori/gourmet-icons'

import {
  ClickableNav,
  colors,
  fauxCheckboxStyles,
  FlexNext as Flex,
  FlexNextProps as FlexProps,
  foundation,
  sizing,
  Text,
} from '@vori/gourmet-components'

import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'

import { DATAGRID_HEADER_HEIGHT } from './constants'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-061015}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Vori}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Vori}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Vori}_need_to_be_licensed___{Vori}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{30_June_2025}____[v3]_[01]_MTc1MTIzODAwMDAwMA==bc5699f32087006f1614a9cda8529f42',
)

type Props = FlexProps & {
  /**
   * Used to conditionally apply visual treatments when the grid has headers.
   */
  hasHeaders?: boolean
  /**
   * Used to conditionally display border around the data grid.
   */
  noBorders?: boolean
  /**
   * Used on data grids that do not take all the available viewport
   * height (i.e are full height) to provide different visual treatments.
   */
  withFixedHeight?: boolean
}

const StyledContainer = styled(Flex)<{
  $hasHeaders?: Props['hasHeaders']
  $noBorders?: Props['noBorders']
  $withFixedHeight?: Props['withFixedHeight']
}>((props) => ({
  ...(props.$withFixedHeight
    ? { borderRadius: sizing.radius.base }
    : {
        borderTopLeftRadius: sizing.radius.base,
        borderTopRightRadius: sizing.radius.base,
      }),
  borderBottomWidth: 0,
  borderColor: '#EAECF0',
  borderStyle: 'solid',
  borderWidth: props.$noBorders ? 0 : 1,
  overflow: 'hidden',

  // Root
  '--ag-border-color': 'transparent',
  '--ag-border-radius': sizing.radius.base,
  '--ag-font-family': '',
  '--ag-secondary-border-color': '#EAECF0',

  '.ag-root-wrapper': {
    ...(props.$withFixedHeight
      ? {
          borderRadius:
            props.$hasHeaders || props.$noBorders ? 0 : sizing.radius.base,
        }
      : {
          borderTopLeftRadius:
            props.$hasHeaders || props.$noBorders ? 0 : sizing.radius.base,
          borderTopRightRadius:
            props.$hasHeaders || props.$noBorders ? 0 : sizing.radius.base,
        }),
    borderWidth: 0,
  },

  // Header
  '--ag-header-background-color': '#3F3F46',
  '--ag-header-foreground-color': '#FFFFFF',
  '--ag-header-height': `${DATAGRID_HEADER_HEIGHT}px`,

  '.ag-header': {
    borderBottomColor: '#EAECF0',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },

  '.ag-header-container': {
    minWidth: '100%',
  },

  '.ag-pinned-left-header': {
    borderRightColor: '#E4E4E7',
    borderRightStyle: 'solid',
    borderRightWidth: 2,
  },

  '.ag-pinned-right-header': {
    borderLeftColor: '#E4E4E7',
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
  },

  '.ag-pinned-left-header .ag-header-cell:last-child': {
    borderRightColor: '#EAECF0',
    borderRightStyle: 'solid',
    borderRightWidth: 2,
  },

  '.ag-header-cell': {
    color: foundations.color['color.base-white'],
    fontSize: foundations.typography['typography.text-sm-font-size'],
    lineHeight: foundations.typography['typography.text-sm-line-height'],
    fontWeight: foundations.typography['typography.font-weight-medium'],
  },

  '.ag-header-cell.row-group-hide-border': {
    borderRight: 'none',
  },

  '.ag-header-icon': {
    color: '#FFFFFF',

    ':hover, :active, :focus': {
      color: '#FFFFFF',
    },
  },

  // Rows
  '--ag-borders-row': 'solid 1px',
  '--ag-odd-row-background-color': '#FFFFFF',
  '--ag-row-border-color': '#EAECF0',
  '--ag-row-height': '56px',
  '--ag-row-hover-color': 'rgba(0, 0, 0, 0.010)',
  '--ag-selected-row-background-color': '#FFFFFF',

  '.ag-row-last': {
    borderBottomWidth: 0,
  },

  [[
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper .ag-row:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper .ag-row:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)::before',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper .ag-row:not(.ag-row-group, .row-attention-required):not(.ag-row-loading):hover',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper .ag-row-hover:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper .ag-row-focus:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper + .ag-pinned-right-cols-container .ag-row:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper + .ag-pinned-right-cols-container .ag-row:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)::before',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper + .ag-pinned-right-cols-container .ag-row:not(.ag-row-group, .row-attention-required):not(.ag-row-loading):hover',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper + .ag-pinned-right-cols-container .ag-row-hover:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)',
    '.ag-pinned-left-cols-container:not(.ag-hidden) + .ag-center-cols-clipper + .ag-pinned-right-cols-container .ag-row-focus:not(.ag-row-group, .row-attention-required):not(.ag-row-loading)',
  ].join(',')]: {
    backgroundColor: '#FDFDFD !important',
  },

  '.row-attention-required, .row-attention-required.row-hover, .row-attention-required.row-hover::before, .row-attention-required:hover, .row-attention-required:active, .row-attention-required:focus':
    {
      backgroundColor: '#FEF2F3 !important',
    },

  '[data-clickable-rows] .ag-row': {
    cursor: 'pointer',
    transition: 'all 350ms cubic-bezier(0.215, 0.61, 0.355, 1)',

    '&:hover, &.is-active-row': {
      backgroundColor: foundations.color['color.primary-50'],
    },
  },

  '.ag-row.row-clicked': {
    backgroundColor: foundations.color['color.primary-50'],
  },

  // Column
  '.ag-pinned-left-cols-container': {
    borderRightColor: '#E4E4E7',
    borderRightStyle: 'solid',
    borderRightWidth: 2,
  },

  '.ag-pinned-right-cols-container': {
    borderLeftColor: '#E4E4E7',
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
  },

  '.ag-center-cols-clipper': {
    position: 'relative',
    zIndex: 2,
    minHeight: 'unset !important',
  },

  '.ag-center-cols-container': {
    minWidth: '100%',
  },

  '.ag-pinned-left-header .ag-header-cell[col-id="ag-Grid-AutoColumn"]': {
    borderRightWidth: 1,
    marginLeft: -1,
  },

  // Cells
  '--ag-cell-horizontal-padding': foundation.spacing.medium,
  '--ag-cell-vertical-padding': foundation.spacing.small,
  '--ag-range-selection-border-color': '#EAECF0',

  '.ag-cell': {
    alignItems: 'center',
    display: 'flex',
    lineHeight: foundation.typography.lineHeights.body,
  },

  '.cell-with-error': {
    border: '1.5px solid red',
  },

  '.cell-editable, .ag-cell[col-id="ag-Grid-AutoColumn"]': {
    backgroundColor: '#FFFFFF',
  },

  '.cell-editable.ag-cell-inline-editing': {
    border: '0 none !important',
    borderRadius: 0,
    boxShadow: `0 0 0 1px ${foundation.colors.purple}`,
    height: 'calc(100% - 2px)',
    margin: '1px -1px 0',
  },

  '.ag-row-group .cell-editable': {
    backgroundColor: '#FDFDFD',
  },

  '.ag-cell[col-id="row_selection"]': {
    padding: 0,
  },

  '.ag-row-group-expanded + .ag-row:not(.ag-row-level-0, .row-attention-required) [col-id="row_selection"]':
    {
      backgroundColor: '#FDFDFD !important',
    },

  '.ag-cell-left-pinned:not(.ag-cell-focus):not([col-id="row_selection"]), .ag-cell-first-left-pinned:not(.ag-cell-focus):not([col-id="row_selection"]), .ag-cell-last-left-pinned:not(.ag-cell-focus):not([col-id="row_selection"]), [col-id="ag-Grid-AutoColumn"]':
    {
      borderRightColor: '#EAECF0 !important',
      borderRightStyle: 'solid',
      borderRightWidth: 1,
    },

  [`.ag-cell ${Text}`]: {
    color: '#51525C',
  },

  [`.ag-row-group ${Text}`]: {
    color: '#101828',
  },

  '.ag-cell-edit-wrapper': {
    flexShrink: 0,
  },

  '.ag-cell-wrap-text': {
    wordBreak: 'break-word',
  },

  '.ag-cell.row-group-hide-border': {
    borderRight: 'none',
  },

  '.ag-cell.row-group-no-padding': {
    padding: 0,
  },

  // Pagination
  '.ag-paging-panel': {
    borderTop: '1px solid #EAECF0',
  },

  // Sidebar
  '.ag-side-bar': {
    borderLeft: '1px solid #EAECF0',
  },

  // Icons
  '.ag-filter-icon': {
    display: 'none',
  },

  // Grouping
  '.ag-row-group > .ag-group-value': {
    alignItems: 'center',
    display: 'flex',
    lineHeight: 1.25,
    width: '100%',
  },

  '.ag-row-group-leaf-indent': {
    marginLeft: 0,
  },

  '.ag-cell-wrapper.ag-row-group': {
    alignItems: 'center',
  },

  '.ag-icon-tree-closed, .ag-icon-tree-open': {
    height: sizing.icon.small,
    width: sizing.icon.small,
  },

  '.ag-icon-tree-closed::before': {
    backgroundImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
      ReactDOM.renderToString(<ChevronRightIcon />),
    )})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    content: '""',
    display: 'block',
    height: sizing.icon.small,
    width: sizing.icon.small,
  },

  '.ag-cell-expandable:hover ag-icon-tree-closed::before': {
    backgroundImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
      ReactDOM.renderToString(<ChevronRightIcon />).replace(
        'currentColor',
        foundation.colors.purple,
      ),
    )})`,
  },

  '.ag-icon-tree-open::before': {
    backgroundImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
      ReactDOM.renderToString(<ChevronDownIcon />),
    )})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    content: '""',
    display: 'block',
    height: sizing.icon.small,
    width: sizing.icon.small,
  },

  '.ag-cell-expandable:hover ag-icon-tree-open::before': {
    backgroundImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
      ReactDOM.renderToString(<ChevronDownIcon />).replace(
        'currentColor',
        foundation.colors.purple,
      ),
    )})`,
  },

  // Checkbox
  '[col-id="row_selection"]': {
    borderColor: 'transparent !important',
    boxShadow: 'none !important',
  },

  '.ag-checkbox-input-wrapper': {
    cursor: 'pointer',
    ...fauxCheckboxStyles({ $size: 'small' }),
  },

  '.ag-checkbox-input-wrapper.ag-checked, .ag-checkbox-input-wrapper.ag-indeterminate':
    {
      backgroundColor: colors.checkbox.active.backgroundColor,
      borderColor: colors.checkbox.active.borderColor,
    },

  '.ag-checkbox-input-wrapper:focus-within, .ag-checkbox-input-wrapper:active':
    {
      boxShadow: `0 0 0 ${sizing.focusRing} ${colors.checkbox.focusRing}`,
    },

  '.ag-checkbox-input-wrapper:hover': {
    borderColor: colors.checkbox.hover.borderColor,
  },

  '.ag-checkbox-input-wrapper::after': {
    content: '""',
    height: '100%',
    width: '100%',
  },

  '.ag-checkbox-input-wrapper.ag-checked::after': {
    backgroundImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
      ReactDOM.renderToString(<CheckIcon />).replace(
        'currentColor',
        foundation.colors.purple,
      ),
    )})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '65% 65%',
    content: '""',
  },

  '.ag-checkbox-input-wrapper.ag-indeterminate::after': {
    backgroundImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
      ReactDOM.renderToString(<MinusIcon />).replace(
        'currentColor',
        foundation.colors.purple,
      ),
    )})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '65% 65%',
    content: '""',
  },

  '.ag-checkbox-input-wrapper input': {
    cursor: 'pointer',
  },

  '.ag-cell-inline-editing .ag-selection-checkbox': {
    display: 'none',
  },

  // Input
  '--ag-input-focus-box-shadow': 'none',
  '--ag-input-focus-border-color': 'transparent',

  '.ag-text-field-input': {
    border: '0 none !important',
    borderRadius: '0px !important',
    height: '100%',
    paddingLeft: `${foundation.spacing.medium} !important`,
    paddingRight: `${foundation.spacing.medium} !important`,
  },

  '.ag-text-field-input:focus': {
    border: '0 none !important',
  },

  // Overlays
  '.ag-overlay': {
    backgroundColor: '#FFFFFF',
  },

  // Overrides
  '.gourmet-data-grid-checkbox-header-cell': {
    padding: 0,
  },

  '.gourmet-data-grid-details-action-cell.ag-cell-focus:not(.ag-cell-range-selected):focus-within':
    {
      borderColor: 'transparent',
    },

  [`.gourmet-data-grid-details-action-cell ${ClickableNav}`]: {
    height: '100%',
  },

  '.ag-watermark': {
    display: 'none !important',
  },
}))

function DataGridContainer({
  children,
  hasHeaders,
  noBorders,
  withFixedHeight,
  ...props
}: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <StyledContainer
      {...props}
      data-grid-container
      $hasHeaders={hasHeaders}
      $noBorders={noBorders}
      $withFixedHeight={withFixedHeight}
      className={`${props.className} ag-theme-alpine`}
      direction="column"
      flex={1}
      fullWidth
    >
      {children}
    </StyledContainer>
  )
}

export { DataGridContainer }
export type { Props as DataGridContainerProps }
