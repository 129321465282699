import { useRef, useEffect, useReducer } from 'react'

type State = {
  /**
   * A boolean flag to determine if the image has been loaded.
   */
  isLoaded: boolean
  /**
   * A boolean flag to determine if the image is being loaded.
   */
  isLoading: boolean
  /**
   * A boolean flag to determine if the image has failed loading.
   */
  hasFailed: boolean
}

type Action = { type: 'LOADING' } | { type: 'LOADED' } | { type: 'FAILED' }

function getInitialState(src: string | null): State {
  return {
    isLoaded: false,
    isLoading: false,
    hasFailed: !src,
  }
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'LOADING': {
      return {
        isLoaded: false,
        isLoading: true,
        hasFailed: false,
      }
    }

    case 'LOADED': {
      return {
        isLoaded: true,
        isLoading: false,
        hasFailed: false,
      }
    }

    case 'FAILED': {
      return {
        isLoaded: false,
        isLoading: false,
        hasFailed: true,
      }
    }

    default: {
      return state
    }
  }
}

type HookOptions = {
  /**
   * Image url used to load image.
   */
  src: string | null
}

type HookReturn = State

/**
 * Returns the loading state of an image based on the given a `src` by
 * dynamically loading it through the `Image` constructor.
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/Image}
 */
function useImage(src: HookOptions['src']): HookReturn {
  const imageRef = useRef(new Image())
  const [state, dispatch] = useReducer(reducer, getInitialState(src))

  const onLoad = () => {
    dispatch({ type: 'LOADED' })
  }

  const onError = () => {
    dispatch({ type: 'FAILED' })
  }

  useEffect(() => {
    const currentRef = imageRef.current

    imageRef.current.src = src || ''

    if (imageRef.current.complete) {
      onLoad()
    } else {
      dispatch({ type: 'LOADING' })
      imageRef.current.onload = onLoad
      imageRef.current.onerror = onError
    }

    return () => {
      if (currentRef) {
        currentRef.onload = null
        currentRef.onerror = null
      }
    }
  }, [src])

  return state
}

export type { HookOptions, HookReturn }
export default useImage
