import React from 'react'
import styled from 'styled-components'

import { ParagraphCoreProps, ParagraphProps } from './types'
import { textStyles } from './styles'
import { propsToStyledProps } from '../utils'

const StyledParagraph = styled.p(textStyles)

const defaultProps: Partial<ParagraphCoreProps> = {
  size: 'text-md',
  variant: 'default',
  weight: 'regular',
}

/**
 * The `<Paragraph>` component extends the `<Text>` component to render a
 * `<p>` element.
 *
 * @example
 * <Paragraph>This is a paragraph.</Paragraph>
 *
 * @see {@link https://vori-gourmet-1bdf0.web.app/?path=/docs/typography-text--default}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/p}
 */
const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  function Paragraph(
    {
      align,
      children,
      decoration,
      fullWidth,
      lineHeight,
      overflow,
      overflowWrap,
      size,
      transform,
      truncate,
      variant,
      weight,
      whiteSpace,
      wordBreak,
      ...props
    }: ParagraphProps,
    ref,
  ): JSX.Element {
    return (
      <StyledParagraph
        {...propsToStyledProps({
          align,
          decoration,
          fullWidth,
          lineHeight,
          overflow,
          overflowWrap,
          size,
          transform,
          truncate,
          variant,
          weight,
          whiteSpace,
          wordBreak,
        })}
        {...props}
        data-gourmet-text=""
        data-gourmet-paragraph=""
        ref={ref}
      >
        {children}
      </StyledParagraph>
    )
  },
)

Paragraph.displayName = 'Paragraph'
Paragraph.defaultProps = defaultProps

export { Paragraph, defaultProps as paragraphDefaultProps }
