export type PropsToStyledProps<TProps extends Record<string, unknown>> = {
  [key in keyof TProps as `$${Extract<key, string>}`]: TProps[key]
}

export function propsToStyledProps<TProps extends Record<string, unknown>>(
  props: TProps,
): PropsToStyledProps<TProps> {
  return Object.fromEntries(
    Object.entries(props).map(([key, value]) => [`$${key}`, value]),
  ) as PropsToStyledProps<TProps>
}
