import React, { forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { colors, spacing, sizing } from '../../tokens'

type TimelineEntryProps = React.HTMLAttributes<HTMLLIElement> & {
  isFirstEntry?: boolean
  isLastEntry?: boolean
  withTrack?: boolean
}

const defaultProps: Partial<TimelineEntryProps> = {
  className: '',
}

function styles(props: TimelineEntryProps): CSSObject {
  return {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    zIndex: 1,

    '&:before': {
      backgroundColor: colors.timeline.track.backgroundColor,
      borderTopLeftRadius: props.isFirstEntry ? sizing.radius.pill : 0,
      borderTopRightRadius: props.isFirstEntry ? sizing.radius.pill : 0,
      borderBottomLeftRadius: props.isLastEntry ? sizing.radius.pill : 0,
      borderBottomRightRadius: props.isLastEntry ? sizing.radius.pill : 0,
      content: '""',
      height: props.isLastEntry
        ? `calc(${sizing.timeline.track} - ${sizing.timeline.offset})`
        : `calc(100% + ${spacing.timeline})`,
      left: `calc(((${sizing.timeline.track} - ${sizing.timeline.offset}) + ${spacing.timeline}) * -1)`,
      position: 'absolute',
      top: 0,
      visibility: props.withTrack ? 'visible' : 'hidden',
      width: sizing.timeline.track,
      zIndex: 1,
    },

    '&:after': {
      backgroundColor: colors.timeline.entry.backgroundColor,
      borderRadius: sizing.radius.rounded,
      content: '""',
      height: sizing.timeline.entry,
      left: `calc((${sizing.timeline.entry} + ${spacing.timeline}) * -1)`,
      position: 'absolute',
      top: props.isLastEntry ? 0 : sizing.timeline.offset,
      width: sizing.timeline.entry,
      zIndex: 2,
    },
  }
}

const StyledTimelineEntry = styled(
  forwardRef<HTMLLIElement, TimelineEntryProps>(function TimelineEntry(
    {
      children,
      isFirstEntry,
      isLastEntry,
      withTrack,
      ...props
    }: TimelineEntryProps,
    ref,
  ) {
    return (
      <li {...props} ref={ref}>
        {children}
      </li>
    )
  }),
)(styles)

StyledTimelineEntry.displayName = 'TimelineEntry'
StyledTimelineEntry.defaultProps = defaultProps

export type { TimelineEntryProps }
export { defaultProps as TimelineEntryDefaultProps }
export default StyledTimelineEntry
