import React from 'react'
import styled from 'styled-components'

import {
  Flex,
  TextNext as Text,
  TextNextProps as TextProps,
  Tooltip,
  toTransitions,
} from '@vori/gourmet-components'

import {
  AlertCircleIcon,
  MagicWand01Icon,
  MessageAlertSquareIcon,
} from '@vori/gourmet-icons'

import { DataGridCellRendererProps, DataGridRowData } from '../types'

enum State {
  MISSING_VALUE,
  SUGGESTED_VALUE,
  UNSAVED_CHANGE,
  CUSTOM_VALUE,
}

type Value = string | number | null

type Props<TData extends DataGridRowData> = DataGridCellRendererProps<TData> & {
  missingValueTooltipText?:
    | React.ReactNode
    | ((prevValue: Value, nextValue: Value) => React.ReactNode)
  suggestionTooltipText?:
    | React.ReactNode
    | ((prevValue: Value, nextValue: Value) => React.ReactNode)
  placeholderText?: string
  originalSuggestedValueColumn: keyof TData
  textProps?: TextProps
  unsavedChangeTooltipText?:
    | React.ReactNode
    | ((prevValue: Value, nextValue: Value) => React.ReactNode)
}

const SuggestedIconContainer = styled(Flex)({
  opacity: 0,
  transition: toTransitions(['opacity'], 'ease'),
})

const CellContainer = styled(Flex)({
  '&:hover': {
    [`${SuggestedIconContainer}`]: {
      opacity: 1,
    },
  },
})

const IconContainer = styled(Flex)({
  '&:hover': {
    [`${SuggestedIconContainer}`]: {
      opacity: 1,
    },
  },
})

function DataGridSuggestedValueCellRenderer<TData extends DataGridRowData>({
  data,
  missingValueTooltipText,
  originalSuggestedValueColumn,
  placeholderText,
  suggestionTooltipText,
  textProps,
  unsavedChangeTooltipText,
  ...props
}: Props<TData>): JSX.Element {
  const value = React.useMemo<Value>(
    () => (props.value ?? null) as Value,
    [props.value],
  )

  const previousValue = React.useMemo<Value>(
    () => (data?.[originalSuggestedValueColumn] ?? null) as Value,
    [data, originalSuggestedValueColumn],
  )

  const formattedValue = props.formatValue
    ? props.formatValue(value as TData[keyof TData])
    : props.valueFormatted || value || null

  const formattedPreviousValue = props.formatValue
    ? props.formatValue(data?.[originalSuggestedValueColumn])
    : data?.[originalSuggestedValueColumn] || null

  const [state, setState] = React.useState(
    Boolean(previousValue) && Boolean(value) && value !== previousValue
      ? State.CUSTOM_VALUE
      : value
        ? State.SUGGESTED_VALUE
        : State.MISSING_VALUE,
  )

  React.useEffect(() => {
    setState(
      Boolean(previousValue) && Boolean(value) && value !== previousValue
        ? State.CUSTOM_VALUE
        : value
          ? State.SUGGESTED_VALUE
          : State.MISSING_VALUE,
    )
  }, [previousValue, value])

  return (
    <CellContainer
      centerY
      flex={1}
      fullHeight
      fullWidth
      justifyContent="space-between"
    >
      <Flex centerY flex={1} fullHeight fullWidth>
        {typeof value === 'string' || !value ? (
          <Text
            size="text-sm"
            variant={!value && placeholderText ? 'secondary' : 'default'}
            {...textProps}
          >
            {value ? formattedValue : placeholderText}
          </Text>
        ) : (
          <>{value ? formattedValue : placeholderText}</>
        )}
      </Flex>
      <IconContainer centerY fullHeight>
        {state === State.SUGGESTED_VALUE && (
          <SuggestedIconContainer>
            <Tooltip
              label={
                suggestionTooltipText
                  ? typeof suggestionTooltipText === 'function'
                    ? suggestionTooltipText(previousValue, value)
                    : suggestionTooltipText
                  : `Suggested Value`
              }
            >
              <MagicWand01Icon variant="primary" />
            </Tooltip>
          </SuggestedIconContainer>
        )}
        {state === State.MISSING_VALUE && (
          <Tooltip
            label={
              missingValueTooltipText
                ? typeof missingValueTooltipText === 'function'
                  ? missingValueTooltipText(previousValue, value)
                  : missingValueTooltipText
                : 'Missing Value'
            }
          >
            <AlertCircleIcon variant="negative" />
          </Tooltip>
        )}
        {(state === State.UNSAVED_CHANGE || state === State.CUSTOM_VALUE) && (
          <Tooltip
            label={
              unsavedChangeTooltipText
                ? typeof unsavedChangeTooltipText === 'function'
                  ? unsavedChangeTooltipText(previousValue, value)
                  : unsavedChangeTooltipText
                : previousValue
                  ? `Updated from ${formattedPreviousValue}`
                  : 'Unsaved change'
            }
          >
            <MessageAlertSquareIcon variant="progress" />
          </Tooltip>
        )}
      </IconContainer>
    </CellContainer>
  )
}

export { DataGridSuggestedValueCellRenderer }
export type { Props as DataGridSuggestedValueCellRendererProps }
