import { NavLink, NavLinkProps } from 'react-router-dom'
import React, { cloneElement, forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { toTransitions } from '../../utils'

import { resolveStringProp } from '../utils'
import { colors, spacing } from '../../tokens'

import Spacer from '../../Spacer'
import Text from '../../Text'

type AppSidebarLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  href: string | (() => string)
  icon: React.ReactElement
  label: string | (() => string)
  navLinkProps?: Partial<NavLinkProps>
}

const defaultProps: Partial<AppSidebarLinkProps> = {
  className: '',
}

const styles: CSSObject = {
  alignItems: 'center',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'flex-start',
  paddingBottom: spacing.appSidebarLink,
  paddingTop: spacing.appSidebarLink,
  transition: toTransitions(['background-color', 'color'], 'ease'),
  width: '100%',

  '&, .gourmet-appsidebar__text': {
    color: colors.appSidebarLink.text.color,
    textDecoration: 'none',
    textTransform: 'capitalize',
  },

  [[
    '&:hover, &:hover .gourmet-appsidebar__text',
    '&:focus, &:focus .gourmet-appsidebar__text',
    '&:active, &:active .gourmet-appsidebar__text',
  ].join(', ')]: {
    color: colors.appSidebarLink.text.hover.color,
    textDecoration: 'none',
  },

  '&.active': {
    backgroundColor: colors.appSidebarLink.active.backgroundColor,
  },

  [[
    '&.active, &.active .gourmet-appsidebar__text',
    '&.active, &.active .gourmet-appsidebar__text',
    '&.active, &.active .gourmet-appsidebar__text',
  ].join(', ')]: {
    color: colors.appSidebarLink.active.color,
  },
}

const StyledAppSidebarLink = styled(
  forwardRef<HTMLAnchorElement, AppSidebarLinkProps>(function AppSidebarLink(
    {
      className,
      href,
      icon,
      label,
      navLinkProps,
      ...props
    }: AppSidebarLinkProps,
    ref,
  ) {
    return (
      <NavLink
        {...props}
        {...navLinkProps}
        className={`${className} gourmet-appsidebar__link`}
        ref={ref}
        to={resolveStringProp(href)}
      >
        <Spacer inline />
        {cloneElement(
          icon,
          {
            'aria-hidden': true,
            className: 'gourmet-appsidebar__icon',
            ...(icon.props?.size && {
              size: 'base',
            }),
          },
          null,
        )}
        <div className="gourmet-appsidebar__toggle">
          <Spacer inline />
          <Text className="gourmet-appsidebar__text">
            {resolveStringProp(label).toLocaleLowerCase()}
          </Text>
        </div>
        <Spacer inline />
      </NavLink>
    )
  }),
)<AppSidebarLinkProps>(styles)

StyledAppSidebarLink.displayName = 'AppSidebarLink'
StyledAppSidebarLink.defaultProps = defaultProps

export type { AppSidebarLinkProps }

export {
  defaultProps as AppSidebarLinkDefaultProps,
  styles as AppSidebarLinkStyles,
}

export default StyledAppSidebarLink
