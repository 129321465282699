import React from 'react'
import styled from 'styled-components'
import { useClientId } from '@vori/react-hooks'

import { Flex, FlexProps, InlineFlex, InlineFlexProps } from '../FlexNext'
import { Text } from '../TextNext'

import { FormFieldContext } from './FormFieldContext'
import { FormFieldInput } from './FormFieldInput'
import { FormFieldLabel } from './FormFieldLabel'

import { isInReactChildren } from '../utils'
import { useControlledState } from '../hooks'

interface Props {
  /**
   * An optional description for this form field, useful for giving users hints
   * or instruction on how to interact with it.
   *
   * **Note:** If an `error` prop is passed, it will override this description,
   * i.e. the error will be shown instead of the form field's provided
   * description.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
   */
  description?: string
  /**
   * An optional error message for this form field, useful for informing users
   * of errors related to this form field that might prevent them from
   * submitting a form or taking other actions.
   *
   * **Note:** If you provide an error message through this `error` prop, the
   * associated `<FormFieldInput>` element will be provided with a `aria-invalid`
   * attribute, which could trigger an error state if supported by the element.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-errormessage}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-invalid}
   */
  error?: string | null
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: FlexProps
  /**
   * If `true` the form field will take up all available horizontal space.
   */
  fullWidth?: boolean
  /**
   * A specific id to be used on the form element inside the `<FormFieldInput>`
   * component. When providing this `id` all accessibility description and error
   * associations will automatically be made using this `id`.
   */
  id?: string
  /**
   * Used to display the `<FormFieldLabel>` component alongside the `<FormFieldInput>`
   * component.
   */
  inline?: boolean
  /**
   * Props to be passed to the inner `<InlineFlex>` component containing the
   * `<FormFieldLabel>` and `<FormFieldInput>` components when using the
   * `inline` prop.
   */
  inlineFlexProps?: InlineFlexProps
  /**
   * The caption used for the `<FormFieldInput>` component.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label}
   */
  label: string
  /**
   * Props to be passed to the inner `<FormFieldLabel>` component.
   *
   * **Note:** The `for` (`htmlFor` in React) attribute/prop will always be
   * overridden internally to ensure proper association with the `<FormFieldInput>`
   * component.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#attributes}
   */
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  /**
   * If `true`, a red asterisk will be display next to the label's text.
   */
  required?: boolean
  /**
   * If `true`, the input will aligned to the right, as opposed to the left of
   * the label and description.
   */
  withRightAlignedInput?: boolean
}

const StyledFlex = styled(Flex)<Partial<Props>>(({ flexProps, fullWidth }) => ({
  width: fullWidth || flexProps?.fullWidth ? '100%' : 'max-content',
}))

const StyledInlineFlex = styled(InlineFlex)<Partial<Props>>(
  ({ inlineFlexProps, fullWidth }) => ({
    width: fullWidth || inlineFlexProps?.fullWidth ? '100%' : 'max-content',
  }),
)

/**
 * Used to enhance form elements with a caption and an optional description or
 * error message.
 *
 * @example
 * function FullNameField() {
 *   return (
 *     <FormField label="Name" description="Please provide us with your full name">
 *       <Input placeholder="Jane Doe" />
 *     </FormField>
 *   )
 * }
 */
const FormField = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function FormField(
  {
    children,
    description,
    error,
    flexProps,
    fullWidth,
    id: controlledInputID,
    inline,
    inlineFlexProps,
    label,
    labelProps,
    required,
    withRightAlignedInput,
  }: React.PropsWithChildren<Props>,
  ref,
) {
  const [defaultInputID] = useClientId('gourmet-formfield-input')
  const [defaultLabelID] = useClientId('gourmet-formfield-label')
  const [descriptionID] = useClientId('gourmet-formfield-description')
  const [errorID] = useClientId('gourmet-formfield-error')

  const [inputID] = useControlledState({
    componentName: 'FormField',
    controlledValue: controlledInputID,
    defaultValue: defaultInputID,
  })

  const [labelID] = useControlledState({
    componentName: 'FormField',
    controlledValue: labelProps?.id,
    defaultValue: defaultLabelID,
  })

  const Label = React.useMemo(
    () => (
      <FormFieldLabel {...labelProps} id={labelID} htmlFor={inputID}>
        {label}
        {required && <Text variant="negative">*</Text>}
      </FormFieldLabel>
    ),
    [inputID, label, labelID, labelProps, required],
  )

  const Input = React.useMemo(
    () =>
      isInReactChildren(
        children,
        (child) => React.isValidElement(child) && child.type === FormFieldInput,
      ) ? (
        children
      ) : (
        <FormFieldInput>{children}</FormFieldInput>
      ),
    [children],
  )

  const Description = React.useMemo(
    () =>
      !error && description ? (
        <Text
          data-gourmet-formfield-description=""
          fullWidth
          id={descriptionID}
          size="text-sm"
          variant="secondary"
        >
          {description}
        </Text>
      ) : null,
    [description, descriptionID, error],
  )

  const Error = React.useMemo(
    () =>
      error ? (
        <Text
          data-gourmet-formfield-error=""
          fullWidth
          id={errorID}
          size="text-sm"
          variant="negative"
        >
          {error}
        </Text>
      ) : null,
    [error, errorID],
  )

  return (
    <FormFieldContext.Provider
      value={{
        descriptionID: description ? descriptionID : '',
        errorID: error ? errorID : '',
        inputID,
        labelID,
      }}
    >
      {inline ? (
        <StyledInlineFlex
          {...inlineFlexProps}
          alignItems="flex-start"
          gap="space.125"
          centerY
          fullWidth={fullWidth || flexProps?.fullWidth || false}
          justifyContent={
            withRightAlignedInput
              ? 'space-between'
              : inlineFlexProps?.justifyContent || undefined
          }
        >
          <InlineFlex direction="column" fullWidth={withRightAlignedInput}>
            {Label}
            {Description}
            {Error}
          </InlineFlex>
          {withRightAlignedInput ? (
            <InlineFlex
              fullWidth
              justifyContent="flex-end"
              style={{ maxWidth: '75%' }}
            >
              {Input}
            </InlineFlex>
          ) : (
            Input
          )}
        </StyledInlineFlex>
      ) : (
        <StyledFlex
          {...flexProps}
          alignItems="flex-start"
          data-gourmet-formfield=""
          direction="column"
          fullWidth={fullWidth || flexProps?.fullWidth || false}
          gap="space.050"
          ref={ref}
        >
          {Label}
          {Input}
          {Description}
          {Error}
        </StyledFlex>
      )}
    </FormFieldContext.Provider>
  )
})

FormField.displayName = 'FormField'
FormField.defaultProps = {}

export { FormField }
export type { Props as FormFieldProps }
