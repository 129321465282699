import { DialogContent, DialogOverlay, DialogOverlayProps } from '@reach/dialog'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { XCloseIcon } from '@vori/gourmet-icons'

import { ButtonDefaultProps, ButtonProps } from '../Button'
import Card, { CardDefaultProps, CardProps } from '../Card'
import Flex, { FlexDefaultProps, FlexProps } from '../Flex'

import { Clickable } from '..'
import { colors, foundation, Size, sizing, spacing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type Position = 'left' | 'right'

type SidePanelProps = React.HTMLAttributes<HTMLDivElement> &
  FlexProps & {
    closeButtonProps?: ButtonProps
    contentProps?: FlexProps
    /**
     * Used as the `aria-label` attribute for the <DialogContent /> used to
     * render the panel.
     * {@link https://reach.tech/dialog#labeling}
     */
    dialogLabel: string
    dialogProps?: DialogOverlayProps
    isOpen?: boolean
    noContentPadding?: boolean
    overlay?: boolean
    panelProps?: CardProps
    position?: Position
    renderPanel?: () => React.ReactNode
    size?: Size
    withCloseButton?: boolean
  }

const defaultProps: Partial<SidePanelProps> = {
  className: '',
  closeButtonProps: ButtonDefaultProps,
  contentProps: FlexDefaultProps,
  dialogProps: {},
  isOpen: false,
  noContentPadding: false,
  overlay: false,
  panelProps: CardDefaultProps,
  position: 'right',
  size: 'base',
  withCloseButton: false,
}

type SidePanelCloseButtonProps = ButtonProps & {
  $position?: SidePanelProps['position']
}

const StyledSidePanelCloseButton = styled(
  forwardRef<HTMLButtonElement, SidePanelCloseButtonProps>(
    function SidePanelCloseButton(
      { $position, ...props }: SidePanelCloseButtonProps,
      ref,
    ) {
      return (
        <Clickable
          {...props}
          ref={ref}
          iconOnly
          leftIcon={<XCloseIcon aria-hidden size="sm" />}
          size="small"
        />
      )
    },
  ),
)<SidePanelCloseButtonProps>`
  border-radius: ${sizing.radius.rounded};
  background-color: ${foundation.colors.pureWhite};
  color: ${colors.panel.closeButton.color};
  height: ${sizing.panelCloseButton};
  min-height: ${sizing.panelCloseButton};
  min-width: ${sizing.panelCloseButton};
  position: absolute;
  padding: 4px;
  top: 10px;
  right: 10px;
  width: ${sizing.panelCloseButton};
  z-index: 1000;
`

const SidePanelOverlay = styled(DialogOverlay)`
  background-color: ${colors.overlay};
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`

const Container = styled(Flex)<Omit<SidePanelProps, 'dialogLabel'>>`
  position: relative;
`

const Content = styled(Flex)<{
  $isOpen: SidePanelProps['isOpen']
  $noContentPadding: SidePanelProps['noContentPadding']
  $overlay: SidePanelProps['overlay']
  $position: SidePanelProps['position']
  $size: SidePanelProps['size']
}>`
  padding-left: ${({
    $isOpen,
    $noContentPadding,
    $overlay,
    $position = defaultProps.position,
  }) =>
    !$noContentPadding && $isOpen && $position === 'left' && !$overlay
      ? `calc(${spacing.panel.content})`
      : '0px'};
  padding-right: ${({
    $isOpen,
    $noContentPadding,
    $overlay,
    $position = defaultProps.position,
  }) =>
    !$noContentPadding && $isOpen && $position === 'right' && !$overlay
      ? `calc(${spacing.panel.content})`
      : '0px'};
  margin-left: ${({
    $isOpen,
    $position = defaultProps.position,
    $size = defaultProps.size,
  }) =>
    !$isOpen && $position === 'left' ? `-${sizing.panel[$size as Size]}` : '0'};
  transition: ${toTransitions(['padding', 'margin', 'width'], 'ease')};
  width: ${({
    $isOpen,
    $overlay = defaultProps.position,
    $size = defaultProps.size,
  }) =>
    $isOpen && !$overlay
      ? `calc(100% - ${sizing.panel[$size as Size]})`
      : '100%'};
`

const PanelContainer = styled.div<{
  $isOpen: SidePanelProps['isOpen']
  $overlay: SidePanelProps['overlay']
  $position: SidePanelProps['position']
  $size: SidePanelProps['size']
  $fullWidth?: boolean
}>`
  ${({ $position = defaultProps.position }) =>
    $position === 'left' ? 'left: 0;' : 'right: 0;'}
  height: 100%;
  max-width: ${({ $size = defaultProps.size, $fullWidth = false }) =>
    $fullWidth ? '100%' : sizing.panel[$size as Size]};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  position: ${({ $overlay }) => ($overlay ? 'fixed' : 'relative')};
  top: 0;
  transform: ${({
    $isOpen,
    $position = defaultProps.position,
    $size = defaultProps.size,
  }) =>
    `translate3d(${
      $isOpen
        ? '0'
        : `${$position === 'left' ? '-' : ''}${sizing.panel[$size as Size]}`
    }, 0, 0)`};
  transition: ${toTransitions(['opacity', 'transform'], 'ease')};
  width: 100%;
  will-change: transform;
  z-index: 1000;
`

const Panel = styled(Card)`
  border-bottom: 0 none;
  border-radius: 0;
  border-right: 0 none;
  border-top: 0 none;
  height: 100%;
`

const renderWithContainer = (
  content: React.ReactNode,
  {
    dialogLabel,
    dialogProps,
    isOpen,
    overlay,
  }: {
    dialogLabel: string
    dialogProps: SidePanelProps['dialogProps']
    isOpen: SidePanelProps['isOpen']
    overlay: SidePanelProps['overlay']
  },
) =>
  overlay ? (
    <SidePanelOverlay {...dialogProps} isOpen={isOpen || false}>
      <DialogContent aria-label={dialogLabel}>{content}</DialogContent>
    </SidePanelOverlay>
  ) : (
    <>{content}</>
  )

/**
 * @deprecated Use `<SidePanelNext>` instead.
 */
const StyledSidePanel = styled(
  forwardRef<HTMLDivElement, SidePanelProps>(function SidePanel(
    {
      children,
      className = '',
      closeButtonProps,
      contentProps,
      dialogProps,
      dialogLabel,
      isOpen,
      noContentPadding,
      overlay,
      panelProps,
      position,
      renderPanel,
      size,
      withCloseButton,
      fullWidth,
      ...props
    }: SidePanelProps,
    ref,
  ) {
    const PanelComponent = (
      <PanelContainer
        $isOpen={isOpen}
        $overlay={overlay}
        $position={position}
        $size={size}
        $fullWidth={fullWidth}
        className={className}
      >
        {withCloseButton && (
          <StyledSidePanelCloseButton
            {...closeButtonProps}
            $position={position}
          />
        )}
        <Panel {...panelProps} fullWidth>
          {renderPanel != null && renderPanel()}
        </Panel>
      </PanelContainer>
    )

    return (
      <Container {...props} className={className} ref={ref}>
        {renderPanel != null &&
          position === 'left' &&
          renderWithContainer(PanelComponent, {
            dialogLabel,
            dialogProps,
            isOpen,
            overlay,
          })}

        <Content
          {...contentProps}
          $isOpen={isOpen}
          $noContentPadding={noContentPadding || false}
          $overlay={overlay}
          $position={position}
          $size={size}
          shrink={0}
        >
          {children}
        </Content>

        {renderPanel != null &&
          position === 'right' &&
          renderWithContainer(PanelComponent, {
            dialogProps,
            dialogLabel,
            isOpen,
            overlay,
          })}
      </Container>
    )
  }),
)``

StyledSidePanel.displayName = 'SidePanel'
StyledSidePanel.defaultProps = defaultProps

export type { SidePanelProps }
export { defaultProps as SidePanelDefaultProps }
export default StyledSidePanel
