import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MicrophoneOff01IconProps = IconProps

const MicrophoneOff01Icon = forwardRef<SVGSVGElement, MicrophoneOff01IconProps>(
  (props: MicrophoneOff01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 9.4V5a3 3 0 0 0-5.688-1.334M12 19v3m0-3a7 7 0 0 1-7-7v-2m7 9a7 7 0 0 0 7-7v-2M8 22h8M2 2l20 20m-10-7a3 3 0 0 1-3-3V9l5.123 5.12A2.99 2.99 0 0 1 12 15Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MicrophoneOff01Icon.displayName = 'MicrophoneOff01Icon'
MicrophoneOff01Icon.defaultProps = iconDefaultProps

export type { MicrophoneOff01IconProps }
export { MicrophoneOff01Icon }
