import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleDownIconProps = IconProps

const ArrowCircleDownIcon = forwardRef<SVGSVGElement, ArrowCircleDownIconProps>(
  (props: ArrowCircleDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8 12 4 4m0 0 4-4m-4 4V8m10 4c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowCircleDownIcon.displayName = 'ArrowCircleDownIcon'
ArrowCircleDownIcon.defaultProps = iconDefaultProps

export type { ArrowCircleDownIconProps }
export { ArrowCircleDownIcon }
