import { endOfDay, isSameDay, startOfDay } from 'date-fns'
import { merge } from 'lodash'

import {
  createCalendarDatesForMonthAndYear,
  formatMonth,
  formatYear,
  getRangeFromSelectedDates,
} from './utils'

import {
  CalendarMonth,
  HookOptions,
  ReducerAction,
  ReducerState,
} from './types'

export function getDefaultState(options: Partial<HookOptions>): ReducerState {
  const mergedOptions = merge(
    {
      dates: [],
      disabledDates: [],
      disableFutureDates: false,
      disablePastDates: false,
      maxSelected: Infinity,
      month: new Date().getMonth() as CalendarMonth,
      rangeSelection: false,
      selectedDates: [],
      selectedTimes: [],
      weekStartsOn: 0,
      year: new Date().getFullYear(),
    },
    options,
    { ...(options.rangeSelection && { maxSelected: 2 }) },
  ) as Required<HookOptions>

  const month = mergedOptions.selectedDates.length
    ? (mergedOptions.selectedDates[0].getMonth() as CalendarMonth)
    : mergedOptions.month

  const year = mergedOptions.selectedDates.length
    ? mergedOptions.selectedDates[0].getFullYear()
    : mergedOptions.year

  const dateWithMonthAndYear = new Date(year, month)

  return {
    ...mergedOptions,
    dates: createCalendarDatesForMonthAndYear({
      ...mergedOptions,
      month,
      year,
    }),
    datesWithinRangeOfSelectedDates: getRangeFromSelectedDates(
      mergedOptions.selectedDates || [],
    ),
    formattedMonth: formatMonth(dateWithMonthAndYear) || '',
    formattedYear: formatYear(dateWithMonthAndYear) || '',
    month,
    selectedDaysAreVisible: mergedOptions.selectedDates.some(
      (selectedDate) => selectedDate.getMonth() === month,
    ),
    todayIsVisible: new Date().getMonth() === month,
    year,
  }
}

function setTimeAction(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  if (action.type !== 'SET_TIME') {
    return state
  }

  const { times } = action.payload

  return {
    ...state,
    selectedTimes: times,
    selectedDates: state.selectedDates.map((selectedDate, index) => {
      const timeToSet = times.length === 1 ? times[0] : times[index] || times[0]

      if (!timeToSet) {
        return state.selectedDates.length > 1 &&
          state.selectedDates.length - 1 === index
          ? endOfDay(selectedDate)
          : startOfDay(selectedDate)
      }

      if (typeof timeToSet === 'string') {
        return new Date(
          `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${selectedDate
            .getDate()
            .toString()
            .padStart(2, '0')}T${timeToSet}`,
        )
      } else {
        const timeToSetAsDate = new Date(timeToSet)
        const updatedSelectedDate = new Date(selectedDate)

        updatedSelectedDate.setHours(timeToSetAsDate.getHours())
        updatedSelectedDate.setMinutes(timeToSetAsDate.getMinutes())
        updatedSelectedDate.setSeconds(timeToSetAsDate.getSeconds())

        return updatedSelectedDate
      }
    }),
  }
}

export function reducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case 'INIT': {
      return getDefaultState({ ...state, ...action.payload.options })
    }

    case 'SET_MONTH': {
      const { dates, ...options } = state
      const nextYear = state.month >= 12 ? state.year + 1 : state.year
      const nextDateWithMonthAndYear = new Date(nextYear, action.payload.month)

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          month: action.payload.month,
          year: nextYear,
        }),
        formattedMonth: formatMonth(nextDateWithMonthAndYear) || '',
        formattedYear: formatYear(nextDateWithMonthAndYear) || '',
        month: action.payload.month,
        selectedDaysAreVisible: options.selectedDates.some(
          (selectedDate) =>
            selectedDate.getMonth() === action.payload.month &&
            selectedDate.getFullYear() === nextYear,
        ),
        todayIsVisible:
          new Date().getMonth() === action.payload.month &&
          new Date().getFullYear() === nextYear,
        year: nextYear,
      }
    }

    case 'SET_NEXT_MONTH': {
      const { dates, ...options } = state

      const nextMonth = (
        state.month >= 12 ? 1 : state.month + 1
      ) as CalendarMonth

      const nextYear = state.month >= 12 ? state.year + 1 : state.year
      const nextDateWithMonthAndYear = new Date(nextYear, nextMonth)

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          month: nextMonth,
          year: nextYear,
        }),
        formattedMonth: formatMonth(nextDateWithMonthAndYear) || '',
        formattedYear: formatYear(nextDateWithMonthAndYear) || '',
        month: nextMonth,
        selectedDaysAreVisible: options.selectedDates.some(
          (selectedDate) =>
            selectedDate.getMonth() === nextMonth &&
            selectedDate.getFullYear() === nextYear,
        ),
        todayIsVisible:
          new Date().getMonth() === nextMonth &&
          new Date().getFullYear() === nextYear,
        year: nextYear,
      }
    }

    case 'SET_YEAR': {
      const { dates, ...options } = state

      const nextDateWithMonthAndYear = new Date(
        action.payload.year,
        options.month,
      )

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          year: action.payload.year,
        }),
        formattedMonth: formatMonth(nextDateWithMonthAndYear) || '',
        formattedYear: formatYear(nextDateWithMonthAndYear) || '',
        selectedDaysAreVisible: options.selectedDates.some(
          (selectedDate) =>
            selectedDate.getMonth() === options.month &&
            selectedDate.getFullYear() === action.payload.year,
        ),
        todayIsVisible:
          new Date().getMonth() === options.month &&
          new Date().getFullYear() === action.payload.year,
        year: action.payload.year,
      }
    }

    case 'SET_NEXT_YEAR': {
      const { dates, ...options } = state

      const nextYear = state.year + 1
      const nextDateWithMonthAndYear = new Date(nextYear, options.month)

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          year: nextYear,
        }),
        formattedMonth: formatMonth(nextDateWithMonthAndYear) || '',
        formattedYear: formatYear(nextDateWithMonthAndYear) || '',
        selectedDaysAreVisible: options.selectedDates.some(
          (selectedDate) =>
            selectedDate.getMonth() === options.month &&
            selectedDate.getFullYear() === nextYear,
        ),
        todayIsVisible:
          new Date().getMonth() === options.month &&
          new Date().getFullYear() === nextYear,
        year: nextYear,
      }
    }

    case 'SET_PREV_MONTH': {
      const { dates, ...options } = state

      const nextMonth = (
        state.month <= 1 ? 12 : state.month - 1
      ) as CalendarMonth

      const nextYear = state.month <= 1 ? state.year - 1 : state.year
      const nextDateWithMonthAndYear = new Date(nextYear, nextMonth)

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          month: nextMonth,
          year: nextYear,
        }),
        formattedMonth: formatMonth(nextDateWithMonthAndYear) || '',
        formattedYear: formatYear(nextDateWithMonthAndYear) || '',
        month: nextMonth,
        selectedDaysAreVisible: options.selectedDates.some(
          (selectedDate) => selectedDate.getMonth() === nextMonth,
        ),
        todayIsVisible:
          new Date().getMonth() === nextMonth &&
          new Date().getFullYear() === nextYear,
        year: nextYear,
      }
    }

    case 'SET_PREV_YEAR': {
      const { dates, ...options } = state

      const nextYear = state.year - 1
      const nextDateWithMonthAndYear = new Date(nextYear, options.month)

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          year: nextYear,
        }),
        formattedMonth: formatMonth(nextDateWithMonthAndYear) || '',
        formattedYear: formatYear(nextDateWithMonthAndYear) || '',
        selectedDaysAreVisible: options.selectedDates.some(
          (selectedDate) =>
            selectedDate.getMonth() === options.month &&
            selectedDate.getFullYear() === nextYear,
        ),
        todayIsVisible:
          new Date().getMonth() === options.month &&
          new Date().getFullYear() === nextYear,
        year: nextYear,
      }
    }

    case 'DESELECT_ALL': {
      const { dates, ...options } = state

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          selectedDates: [],
        }),
        datesWithinRangeOfSelectedDates: [],
        selectedDates: [],
        selectedDaysAreVisible: false,
      }
    }

    case 'DESELECT': {
      const { dates, ...options } = state

      const selectedDates =
        state.selectedDates.find((selectedDate) =>
          isSameDay(selectedDate, action.payload.date),
        ) === undefined
          ? state.selectedDates.filter(
              (date) => !isSameDay(date, action.payload.date),
            )
          : state.selectedDates

      return {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          selectedDates,
        }),
        datesWithinRangeOfSelectedDates:
          getRangeFromSelectedDates(selectedDates),
        selectedDates,
        selectedDaysAreVisible: selectedDates.some(
          (selectedDate) => selectedDate.getMonth() === options.month,
        ),
      }
    }

    case 'SELECT': {
      const { dates, ...options } = state

      const selectedDates =
        state.selectedDates.find((selectedDate) =>
          isSameDay(selectedDate, action.payload.date),
        ) === undefined
          ? state.rangeSelection && state.selectedDates.length === 2
            ? [state.selectedDates[0], action.payload.date]
            : [
                ...state.selectedDates.slice(
                  state.selectedDates.length >= state.maxSelected ? 1 : 0,
                ),
                action.payload.date,
              ]
          : state.selectedDates

      const nextState = {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          selectedDates,
        }),
        datesWithinRangeOfSelectedDates:
          getRangeFromSelectedDates(selectedDates),
        selectedDates,
        selectedDaysAreVisible: selectedDates.some(
          (selectedDate) => selectedDate.getMonth() === options.month,
        ),
      }

      return setTimeAction(nextState, {
        type: 'SET_TIME',
        payload: { times: nextState.selectedTimes },
      })
    }

    case 'TOGGLE': {
      const { dates, ...options } = state

      const selectedDates =
        state.selectedDates.find((selectedDate) =>
          isSameDay(selectedDate, action.payload.date),
        ) === undefined
          ? state.rangeSelection && state.selectedDates.length === 2
            ? [action.payload.date]
            : [
                ...state.selectedDates.slice(
                  state.selectedDates.length >= state.maxSelected ? 1 : 0,
                ),
                action.payload.date,
              ]
          : state.selectedDates.filter(
              (date) => !isSameDay(date, action.payload.date),
            )

      const nextState = {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          selectedDates,
        }),
        datesWithinRangeOfSelectedDates:
          getRangeFromSelectedDates(selectedDates),
        selectedDates,
        selectedDaysAreVisible: selectedDates.some(
          (selectedDate) => selectedDate.getMonth() === options.month,
        ),
      }

      return setTimeAction(nextState, {
        type: 'SET_TIME',
        payload: { times: nextState.selectedTimes },
      })
    }

    case 'SET_DATES': {
      const { dates, ...options } = state

      const nextState = {
        ...state,
        dates: createCalendarDatesForMonthAndYear({
          ...options,
          selectedDates: action.payload.dates,
        }),
        datesWithinRangeOfSelectedDates: getRangeFromSelectedDates(
          action.payload.dates,
        ),
        selectedDates: action.payload.dates,
        selectedDaysAreVisible: action.payload.dates.some(
          (selectedDate) => selectedDate.getMonth() === options.month,
        ),
      }

      return setTimeAction(nextState, {
        type: 'SET_TIME',
        payload: { times: nextState.selectedTimes },
      })
    }

    case 'SET_TIME': {
      return setTimeAction(state, action)
    }

    default: {
      return state
    }
  }
}
