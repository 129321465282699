import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SpacingWidth02IconProps = IconProps

const SpacingWidth02Icon = forwardRef<SVGSVGElement, SpacingWidth02IconProps>(
  (props: SpacingWidth02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21V3M3 21V3m3.5 9h11m0 3V9m-11 6V9"
        fill="none"
      ></path>
    </Icon>
  ),
)

SpacingWidth02Icon.displayName = 'SpacingWidth02Icon'
SpacingWidth02Icon.defaultProps = iconDefaultProps

export type { SpacingWidth02IconProps }
export { SpacingWidth02Icon }
