import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Card, { CardProps, CardDefaultProps } from '../Card'
import Label from '../Label'
import Text from '../Text'

import { colors, sizing, spacing } from '../tokens'

type SectionProps = Omit<CardProps, 'variant'> & {
  variant?: CardProps['variant'] | 'danger'
}

const defaultProps: Partial<CardProps> = CardDefaultProps

const StyledSection = styled(
  forwardRef<HTMLDivElement, SectionProps>(function Section(
    { variant, ...props }: SectionProps,
    ref,
  ) {
    return <Card {...props} ref={ref} />
  }),
)<SectionProps>(({ variant = 'default' }) => ({
  backgroundColor: colors.section.backgroundColor[variant],
  borderColor: colors.section.borderColor[variant],
  borderRadius: sizing.radius.base,
  boxShadow: 'none',
  color: colors.section.color[variant],
  padding: spacing.section,

  [Card]: {
    borderRadius: sizing.radius.base,
  },

  [`&, && ${Text}:not(${Label})`]: {
    color: colors.section.color[variant],
  },
}))

StyledSection.displayName = 'Section'
StyledSection.defaultProps = defaultProps

export type { SectionProps }
export { defaultProps as SectionDefaultProps }
export default StyledSection
