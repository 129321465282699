import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Minimize01IconProps = IconProps

const Minimize01Icon = forwardRef<SVGSVGElement, Minimize01IconProps>(
  (props: Minimize01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 14h6m0 0v6m0-6-7 7m17-11h-6m0 0V4m0 6 7-7"
        fill="none"
      ></path>
    </Icon>
  ),
)

Minimize01Icon.displayName = 'Minimize01Icon'
Minimize01Icon.defaultProps = iconDefaultProps

export type { Minimize01IconProps }
export { Minimize01Icon }
