import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Expand03IconProps = IconProps

const Expand03Icon = forwardRef<SVGSVGElement, Expand03IconProps>(
  (props: Expand03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 14v2.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H14M10 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8V10m12-1 6-6m0 0h-6m6 0v6M9 15l-6 6m0 0h6m-6 0v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Expand03Icon.displayName = 'Expand03Icon'
Expand03Icon.defaultProps = iconDefaultProps

export type { Expand03IconProps }
export { Expand03Icon }
