import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Upload04IconProps = IconProps

const Upload04Icon = forwardRef<SVGSVGElement, Upload04IconProps>(
  (props: Upload04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 12-4-4m0 0-4 4m4-4v9.2c0 1.39 0 2.086.55 2.865.366.517 1.42 1.155 2.047 1.24.945.128 1.304-.059 2.022-.433A9.999 9.999 0 0 0 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12a9.996 9.996 0 0 0 5 8.662"
        fill="none"
      ></path>
    </Icon>
  ),
)

Upload04Icon.displayName = 'Upload04Icon'
Upload04Icon.defaultProps = iconDefaultProps

export type { Upload04IconProps }
export { Upload04Icon }
