import { XCloseIcon } from '@vori/gourmet-icons'
import React from 'react'

import { composeEventHandlers } from '../utils'
import { useSidePanelContext } from './SidePanelContext'
import { Button, ButtonProps } from '../ButtonNext'

type Props = ButtonProps

/**
 * Used within a `<SidePanel>`, primarily inside `<SidePanelHeader>`, to close
 * it.
 *
 * @example
 * function ExampleSidePanel(): React.ReactElement {
 *   const { ref, ...sidePanel } = useSidePanel()
 *
 *   return (
 *     <SidePanel ref={ref} {...sidePanel}>
 *       <SidePanelHeader>
 *         {({ descriptionID, titleID }) => (
 *           <Flex
 *             alignItems="flex-start"
 *             fullWidth
 *             justifyContent="space-between"
 *           >
 *             <Flex column fullWidth>
 *               <Text id={titleID}>Some title</Text>
 *               <Text id={descriptionID}>Some description</Text>
 *             </Flex>
 *             <Spacer inline />
 *             <SidePanelCloseButton />
 *           </Flex>
 *         )}
 *       </SidePanelHeader>
 *       <SidePanelContent>
 *         <Text>This is a side panel</Text>
 *       </SidePanelContent>
 *       <SidePanelFooter justifyContent="flex-end">
 *         <Button
 *           onClick={() => {
 *             sidePanel.close()
 *           }}
 *         >
 *           Cancel
 *         </Button>
 *         <Spacer inline size="small" />
 *         <Button variant="tertiary">Save</Button>
 *       </SidePanelFooter>
 *     </SidePanel>
 *   )
 * }
 */
const SidePanelCloseButton = React.forwardRef<HTMLButtonElement, Props>(
  function SidePanelHeader(props: Props, ref): JSX.Element {
    const sidePanelContext = useSidePanelContext()

    return (
      <Button
        {...props}
        aria-label="Close panel"
        asIconButton
        asClickable
        leftIcon={<XCloseIcon />}
        onClick={composeEventHandlers(props.onClick, () => {
          sidePanelContext.close()
        })}
        ref={ref}
        sizing="small"
      />
    )
  },
)

SidePanelCloseButton.displayName = 'SidePanelCloseButton'
SidePanelCloseButton.defaultProps = {}

export { SidePanelCloseButton }
export type { Props as SidePanelCloseButtonProps }
