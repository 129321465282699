import React, { Children, cloneElement, Fragment, forwardRef } from 'react'
import styled, { CSSObject } from 'styled-components'

import { Flex } from '../../FlexNext'
import { Spacer } from '../../SpacerNext'
import AppSidebarLink, { AppSidebarLinkProps } from '../AppSidebarLink'

type AppSidebarLinksProps = React.HTMLAttributes<HTMLDivElement>

const defaultProps: Partial<AppSidebarLinksProps> = {
  className: '',
}

const styles: CSSObject = {
  overflowY: 'auto',
}

const StyledAppSidebarLinks = styled(
  forwardRef<HTMLDivElement, AppSidebarLinksProps>(function AppSidebarLinks(
    { children, ...props }: AppSidebarLinksProps,
    ref,
  ) {
    const linksCount =
      Children.map(
        children,
        (child) =>
          child != null &&
          React.isValidElement(child) &&
          child.type === AppSidebarLink,
      )?.filter((isEntry) => isEntry).length || 0

    return (
      <Flex
        {...props}
        alignItems="flex-start"
        direction="column"
        flex={1}
        fullWidth
        justifyContent="flex-start"
        ref={ref}
      >
        <Spacer />
        {Children.map(children, (child, index) =>
          child != null && React.isValidElement<AppSidebarLinkProps>(child) ? (
            <Fragment key={`app-sidebar-link-${index}`}>
              {cloneElement<AppSidebarLinkProps>(child)}
            </Fragment>
          ) : null,
        )}
        {linksCount === 0 && <Spacer />}
      </Flex>
    )
  }),
)<AppSidebarLinksProps>(styles)

StyledAppSidebarLinks.displayName = 'AppSidebarLinks'
StyledAppSidebarLinks.defaultProps = defaultProps

export type { AppSidebarLinksProps }
export { defaultProps as AppSidebarLinksDefaultProps }
export default StyledAppSidebarLinks
