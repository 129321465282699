import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Link04IconProps = IconProps

const Link04Icon = forwardRef<SVGSVGElement, Link04IconProps>(
  (props: Link04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 7H7a5 5 0 0 0 0 10h2a5 5 0 0 0 5-5m2.5 5h.5a5 5 0 0 0 0-10h-2a5 5 0 0 0-5 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

Link04Icon.displayName = 'Link04Icon'
Link04Icon.defaultProps = iconDefaultProps

export type { Link04IconProps }
export { Link04Icon }
