import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ImageUserPlusIconProps = IconProps

const ImageUserPlusIcon = forwardRef<SVGSVGElement, ImageUserPlusIconProps>(
  (props: ImageUserPlusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 8V2m-3 3h6m0 7v5.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.72 22 18.88 22 17.2 22H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C2 19.72 2 18.88 2 17.2V6.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C4.28 2 5.12 2 6.8 2H12M2.146 19.926A4.002 4.002 0 0 1 6 17h7c.93 0 1.394 0 1.78.077a4 4 0 0 1 3.143 3.143c.077.386.077.85.077 1.78M14 9.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ImageUserPlusIcon.displayName = 'ImageUserPlusIcon'
ImageUserPlusIcon.defaultProps = iconDefaultProps

export type { ImageUserPlusIconProps }
export { ImageUserPlusIcon }
