import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ShieldDollarIconProps = IconProps

const ShieldDollarIcon = forwardRef<SVGSVGElement, ShieldDollarIconProps>(
  (props: ShieldDollarIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.213 14.5c.312.594.936 1 1.654 1H13a2 2 0 0 0 0-4h-2a2 2 0 0 1 0-4h2.133c.718 0 1.342.405 1.654 1M12 6v1.5m0 8V17m8-5c0 4.908-5.354 8.478-7.302 9.615-.221.129-.332.194-.488.227a1.137 1.137 0 0 1-.42 0c-.156-.034-.267-.098-.488-.227C9.354 20.478 4 16.908 4 12V7.217c0-.799 0-1.199.13-1.542a2 2 0 0 1 .548-.79c.276-.243.65-.383 1.398-.664l5.362-2.01c.208-.078.312-.117.419-.133a1 1 0 0 1 .286 0c.107.016.21.055.419.133l5.362 2.01c.748.28 1.123.421 1.398.664a2 2 0 0 1 .547.79c.131.343.131.743.131 1.542V12Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ShieldDollarIcon.displayName = 'ShieldDollarIcon'
ShieldDollarIcon.defaultProps = iconDefaultProps

export type { ShieldDollarIconProps }
export { ShieldDollarIcon }
