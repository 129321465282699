import React from 'react'

import { ModalHeader, ModalHeaderProps } from '../ModalNext'

type Props = ModalHeaderProps

/**
 * Provides basic styling for rendering the header of a `<AlertModal>` component.
 * It also provides functionality to provide an accessible name and description
 * for a dialog, through the `aria-label` or `aria-labelledby` attributes.
 *
 * @see {@link https://w3c.github.io/aria/#aria-label}
 * @see {@link https://w3c.github.io/aria/#aria-labelledby}
 *
 * @example
 * function Basic(): React.ReactElement {
 *   const { ref, ...alertModal } = useAlertModal()
 *
 *   return (
 *     <AlertModal ref={ref} {...alertModal}>
 *       <AlertModalHeader title="Some title" description="Some description" />
 *     </AlertModal>
 *   )
 * }
 *
 * function CustomHeader(): React.ReactElement {
 *   const { ref, ...alertModal } = useAlertModal()
 *
 *   return (
 *     <AlertModal ref={ref} {...alertModal}>
 *       <AlertModalHeader>
 *         {({ descriptionID, titleID }) => (
 *           <Flex column fullWidth>
 *             <Text id={titleID}>Some title</Text>
 *             <Text id={descriptionID}>Some description</Text>
 *           </Flex>
 *         )}
 *       </AlertModalHeader>
 *     </AlertModal>
 *   )
 * }
 */
const AlertModalHeader = React.forwardRef<HTMLDivElement, Props>(
  function AlertModalHeader(props: Props, ref): JSX.Element {
    return (
      <ModalHeader
        {...props}
        data-gourmet-alert-modal-header=""
        ref={ref}
        withoutCloseButton
      />
    )
  },
)

AlertModalHeader.displayName = 'AlertModalHeader'
AlertModalHeader.defaultProps = {}

export { AlertModalHeader }
export type { Props as AlertModalHeaderProps }
