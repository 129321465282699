import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Save03IconProps = IconProps

const Save03Icon = forwardRef<SVGSVGElement, Save03IconProps>(
  (props: Save03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 3h7.675c.489 0 .733 0 .963.055.204.05.4.13.579.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963V17m-8.5-7H8.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C7 9.24 7 8.96 7 8.4V6.5M13.5 21v-4.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C12.74 15 12.46 15 11.9 15H8.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7 15.76 7 16.04 7 16.6V21m10.5-10.837V17.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C15.98 21 15.42 21 14.3 21H6.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3 19.48 3 18.92 3 17.8V9.7c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C4.52 6.5 5.08 6.5 6.2 6.5h7.637c.245 0 .367 0 .482.028a1 1 0 0 1 .29.12c.1.061.187.148.36.32l2.062 2.063c.173.173.26.26.322.36.054.09.095.188.12.29.027.115.027.237.027.482Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Save03Icon.displayName = 'Save03Icon'
Save03Icon.defaultProps = iconDefaultProps

export type { Save03IconProps }
export { Save03Icon }
