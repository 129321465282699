import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudBlank01IconProps = IconProps

const CloudBlank01Icon = forwardRef<SVGSVGElement, CloudBlank01IconProps>(
  (props: CloudBlank01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.5 19a4.5 4.5 0 0 1-.42-8.98 6.002 6.002 0 0 1 11.84 0A4.5 4.5 0 0 1 17.5 19h-11Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudBlank01Icon.displayName = 'CloudBlank01Icon'
CloudBlank01Icon.defaultProps = iconDefaultProps

export type { CloudBlank01IconProps }
export { CloudBlank01Icon }
