import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudSun03IconProps = IconProps

const CloudSun03Icon = forwardRef<SVGSVGElement, CloudSun03IconProps>(
  (props: CloudSun03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.15 11a7.5 7.5 0 1 1 14.784-2.5M6 22a4 4 0 1 1 .337-7.986 6.003 6.003 0 0 1 10.866-1.004A4.5 4.5 0 1 1 17.5 22H6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudSun03Icon.displayName = 'CloudSun03Icon'
CloudSun03Icon.defaultProps = iconDefaultProps

export type { CloudSun03IconProps }
export { CloudSun03Icon }
