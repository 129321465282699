import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Expand02IconProps = IconProps

const Expand02Icon = forwardRef<SVGSVGElement, Expand02IconProps>(
  (props: Expand02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 21 21 3M3 21h6m-6 0v-6M21 3h-6m6 0v6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Expand02Icon.displayName = 'Expand02Icon'
Expand02Icon.defaultProps = iconDefaultProps

export type { Expand02IconProps }
export { Expand02Icon }
