import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Pilcrow01IconProps = IconProps

const Pilcrow01Icon = forwardRef<SVGSVGElement, Pilcrow01IconProps>(
  (props: Pilcrow01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 4v16m0-16h2m-2 0h-5.5a4.5 4.5 0 0 0 0 9H16V4Zm-2 16h4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Pilcrow01Icon.displayName = 'Pilcrow01Icon'
Pilcrow01Icon.defaultProps = iconDefaultProps

export type { Pilcrow01IconProps }
export { Pilcrow01Icon }
