import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyEthereumCircleIconProps = IconProps

const CurrencyEthereumCircleIcon = forwardRef<
  SVGSVGElement,
  CurrencyEthereumCircleIconProps
>((props: CurrencyEthereumCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6.5 15.5 12 18l5.5-2.5M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-15.5-.5L12 14l5.5-2.5L12 5l-5.5 6.5Z"
      fill="none"
    ></path>
  </Icon>
))

CurrencyEthereumCircleIcon.displayName = 'CurrencyEthereumCircleIcon'
CurrencyEthereumCircleIcon.defaultProps = iconDefaultProps

export type { CurrencyEthereumCircleIconProps }
export { CurrencyEthereumCircleIcon }
