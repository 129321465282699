import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type GridDotsBlankIconProps = IconProps

const GridDotsBlankIcon = forwardRef<SVGSVGElement, GridDotsBlankIconProps>(
  (props: GridDotsBlankIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 3h.01M3 12h.01M3 21h.01M3 16.5h.01M3 7.5h.01M7.5 3h.01m-.01 9h.01m-.01 9h.01M16.5 3h.01m-.01 9h.01m-.01 9h.01M12 3h.01M12 12h.01M12 21h.01M12 16.5h.01m-.01-9h.01M21 3h.01M21 12h.01M21 21h.01M21 16.5h.01m-.01-9h.01"
        fill="none"
      ></path>
    </Icon>
  ),
)

GridDotsBlankIcon.displayName = 'GridDotsBlankIcon'
GridDotsBlankIcon.defaultProps = iconDefaultProps

export type { GridDotsBlankIconProps }
export { GridDotsBlankIcon }
