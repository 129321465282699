import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UploadCloud01IconProps = IconProps

const UploadCloud01Icon = forwardRef<SVGSVGElement, UploadCloud01IconProps>(
  (props: UploadCloud01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 16.242A4.5 4.5 0 0 1 6.08 8.02a6.002 6.002 0 0 1 11.84 0A4.5 4.5 0 0 1 20 16.242M8 16l4-4m0 0 4 4m-4-4v9"
        fill="none"
      ></path>
    </Icon>
  ),
)

UploadCloud01Icon.displayName = 'UploadCloud01Icon'
UploadCloud01Icon.defaultProps = iconDefaultProps

export type { UploadCloud01IconProps }
export { UploadCloud01Icon }
