import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCcw03IconProps = IconProps

const RefreshCcw03Icon = forwardRef<SVGSVGElement, RefreshCcw03IconProps>(
  (props: RefreshCcw03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 2s.85.121 4.364 3.636A9 9 0 0 1 14 20.776M14 2h6m-6 0v6m-4 14s-.85-.122-4.364-3.636A9 9 0 0 1 10 3.224M10 22H4m6 0v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCcw03Icon.displayName = 'RefreshCcw03Icon'
RefreshCcw03Icon.defaultProps = iconDefaultProps

export type { RefreshCcw03IconProps }
export { RefreshCcw03Icon }
