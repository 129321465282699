import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Cube02IconProps = IconProps

const Cube02Icon = forwardRef<SVGSVGElement, Cube02IconProps>(
  (props: Cube02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2.5V12m0 0 8.5-4.722M12 12 3.5 7.278M12 12v9.5m8.5-4.778-7.723-4.29c-.284-.158-.425-.237-.575-.268a1.001 1.001 0 0 0-.403 0c-.15.031-.292.11-.576.268L3.5 16.722M21 16.06V7.942c0-.343 0-.514-.05-.667a1 1 0 0 0-.215-.364c-.109-.119-.258-.202-.558-.368l-7.4-4.111c-.284-.158-.425-.237-.575-.267a1 1 0 0 0-.403 0c-.15.03-.292.11-.576.267l-7.4 4.11c-.3.167-.45.25-.558.369a1 1 0 0 0-.215.364C3 7.428 3 7.599 3 7.942v8.117c0 .342 0 .514.05.666a1 1 0 0 0 .215.364c.109.119.258.202.558.368l7.4 4.111c.284.158.425.237.576.268.133.027.27.027.402 0 .15-.031.292-.11.576-.268l7.4-4.11c.3-.167.45-.25.558-.369a.999.999 0 0 0 .215-.364c.05-.152.05-.324.05-.666Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Cube02Icon.displayName = 'Cube02Icon'
Cube02Icon.defaultProps = iconDefaultProps

export type { Cube02IconProps }
export { Cube02Icon }
