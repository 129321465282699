import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { foundations } from '@vori/gourmet-tokens'

import Flex, { FlexProps } from '../Flex'
import { Tab, TabProps } from './Tab'

import { composeEventHandlers } from '../utils'
import { getNextTabElementToFocus } from './utils'
import { useTabsContext } from './hooks'

import { TabsTokens } from './tokens'

function styles(): CSSObject {
  return {
    position: 'relative',

    '&::after': {
      borderBottomColor: foundations.color['color.gray-300'],
      borderBottomStyle: 'solid',
      borderBottomWidth: TabsTokens.sizing.tabList.borderWidth,
      bottom: 0,
      content: '""',
      height: TabsTokens.sizing.tabList.borderWidth,
      left: 0,
      position: 'absolute',
      width: '100%',
      zIndex: 1,
    },
  }
}

const StyledTabList = styled(Flex)(styles)

type Props = FlexProps

/**
 * A list of <Tab> components, which are references to <TabPanel> components.
 *
 * @see {@link https://w3c.github.io/aria/#tablist}
 */
const TabList = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function TabList(
  { children, ...props }: React.PropsWithChildren<Props>,
  ref,
) {
  const { selectedValue, focusedIndex, setFocusedIndex, values } =
    useTabsContext()

  // Provides a warning when trying to render a component within <TabList> that
  // is not a <Tab> component.
  if (
    process.env.NODE_ENV !== 'production' &&
    React.Children.toArray(children).find(
      (child) => !React.isValidElement(child) || child.type !== Tab,
    ) !== undefined
  ) {
    console.warn(
      'You are trying to render non <Tab> components inside a <TabList> component. This might lead to unintended consequences.',
    )
  }

  // Implements the "manual activation" pattern in order to focus tab elements
  // when the user navigates through the arrow keys on their keyboard.
  // https://www.w3.org/WAI/ARIA/apg/example-index/tabs/tabs-manual.html
  const handleKeyDown = composeEventHandlers(props.onKeyDown, (event) => {
    const tabElements = Array.from<HTMLButtonElement>(
      event.currentTarget.querySelectorAll('[data-gourmet-tab]'),
    )

    if (!tabElements.length) {
      return
    }

    const enabledTabElements = tabElements.filter(
      (tabElement) => !tabElement.disabled,
    )

    if (!enabledTabElements.length) {
      return
    }

    if (
      enabledTabElements.length === 1 &&
      document.activeElement === enabledTabElements[0]
    ) {
      return
    }

    switch (event.key) {
      case 'ArrowLeft': {
        const nextElementToFocus = getNextTabElementToFocus(
          tabElements,
          values,
          focusedIndex,
          selectedValue,
          'left',
        )

        if (
          nextElementToFocus &&
          document.activeElement !== nextElementToFocus
        ) {
          nextElementToFocus.focus()
        }

        break
      }

      case 'ArrowRight': {
        const nextElementToFocus = getNextTabElementToFocus(
          tabElements,
          values,
          focusedIndex,
          selectedValue,
          'right',
        )

        if (
          nextElementToFocus &&
          document.activeElement !== nextElementToFocus
        ) {
          nextElementToFocus.focus()
        }

        break
      }

      case 'Home': {
        tabElements[0].focus()
        break
      }

      case 'End': {
        tabElements.slice(-1)[0].focus()
        break
      }

      default: {
        break
      }
    }
  })

  return (
    <StyledTabList
      {...props}
      alignItems="center"
      fullWidth
      data-gourmet-tablist
      ref={ref}
      role="tablist"
      onKeyDown={handleKeyDown}
    >
      {React.Children.map(children, (child, index) =>
        child != null &&
        React.isValidElement<TabProps>(child) &&
        child.type === Tab
          ? React.cloneElement<TabProps>(child, {
              onBlur: composeEventHandlers(child.props.onBlur, () => {
                setFocusedIndex(-1)
              }),
              onFocus: composeEventHandlers(child.props.onFocus, () => {
                setFocusedIndex(index)
              }),
              value: child.props.value ?? index,
            })
          : null,
      )}
    </StyledTabList>
  )
})

TabList.displayName = 'TabList'
TabList.defaultProps = {}

export { TabList, styles as TabListStyles }
export type { Props as TabListProps }
