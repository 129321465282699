import React from 'react'

import Flex, { FlexProps } from '../Flex'
import Spacer from '../Spacer'

import { AccordionItemContext } from './AccordionContext'
import { AccordionState } from './types'

interface Props {
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: FlexProps
  /**
   * If `true`, no `<Spacer>` component will be added **after** rendering the
   * component's `children`.
   */
  withoutBottomSpacing?: boolean
  /**
   * If `true`, no `<Spacer>` components will be added **before and after**
   * rendering the component's `children`.
   */
  withoutSpacing?: boolean
  /**
   * If `true`, no `<Spacer>` component will be added **before** rendering the
   * component's `children`.
   */
  withoutTopSpacing?: boolean
}

const AccordionItemContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function AccordionItemContent(
  {
    children,
    flexProps,
    withoutBottomSpacing,
    withoutSpacing,
    withoutTopSpacing,
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const { disabled, isActive, itemIndex, panelID, triggerID } =
    React.useContext(AccordionItemContext)

  return (
    <Flex
      fullWidth
      role="region"
      column
      {...flexProps}
      {...(disabled && { 'data-disabled': '' })}
      aria-labelledby={triggerID}
      data-gourmet-accordion-item-content={itemIndex}
      data-state={isActive ? AccordionState.OPEN : AccordionState.COLLAPSED}
      id={panelID}
      hidden={!isActive}
      ref={ref}
    >
      {!withoutSpacing && !withoutTopSpacing && <Spacer />}
      {children}
      {!withoutSpacing && !withoutBottomSpacing && <Spacer />}
    </Flex>
  )
})

AccordionItemContent.displayName = 'AccordionItemContent'
AccordionItemContent.defaultProps = {}

export { AccordionItemContent }
export type { Props as AccordionItemContentProps }
