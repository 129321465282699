import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart05IconProps = IconProps

const BarChart05Icon = forwardRef<SVGSVGElement, BarChart05IconProps>(
  (props: BarChart05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 17v4M15 8v13m-6-8v8M21 3v18"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart05Icon.displayName = 'BarChart05Icon'
BarChart05Icon.defaultProps = iconDefaultProps

export type { BarChart05IconProps }
export { BarChart05Icon }
