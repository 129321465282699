import React from 'react'

import { Modal, ModalProps } from '../ModalNext'

type Props = ModalProps

/**
 * The `<AlertModal>` interrupts the user's workflow to communicate an important
 * message and acquire a response, e.g. action confirmation prompts and error message confirmations.
 *
 * It uses the `alertdialog` role to enable assistive technologies and browsers to
 * distinguish alert dialogs from other dialogs so they have the option of giving
 * alert dialogs special treatment, such as playing a system alert sound.
 *
 * @example
 * const { ref, triggerProps, ...alertModal } = useAlertModal()
 *
 * <Button {...triggerProps} onClick={alertModal.open}>Open</Button>
 *
 * <AlertModal ref={ref} {...alertModal}>
 *   <AlertModalContent>
 *     <AlertModalHeader
 *       title="This is a alert modal"
 *       description="Here is the description for this alert modal"
 *     />
 *     <Text>Do you want to continue?</Text>
 *     <AlertModalFooter cancelButtonText="Discard" confirmButtonText="Save" />
 *   </AlertModalContent>
 * </AlertModal>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/alertdialog/}
 */
const AlertModal = React.forwardRef<
  HTMLDialogElement,
  React.PropsWithChildren<Props>
>(function AlertModal(props: React.PropsWithChildren<Props>, ref): JSX.Element {
  return (
    <Modal
      {...props}
      role="alertdialog"
      data-gourmet-alert-modal=""
      ref={ref}
    />
  )
})

AlertModal.displayName = 'AlertModal'
AlertModal.defaultProps = {}

export { AlertModal }
export type { Props as AlertModalProps }
