import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Share05IconProps = IconProps

const Share05Icon = forwardRef<SVGSVGElement, Share05IconProps>(
  (props: Share05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 6h-3.2c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C13 8.28 13 9.12 13 10.8V12m8-6-3-3m3 3-3 3m-8-6H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V14"
        fill="none"
      ></path>
    </Icon>
  ),
)

Share05Icon.displayName = 'Share05Icon'
Share05Icon.defaultProps = iconDefaultProps

export type { Share05IconProps }
export { Share05Icon }
