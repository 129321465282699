import { CSSObject } from 'styled-components'

import { sizing, TagSizing } from './types'

export function cssSelectorForEachSize(
  callback: (size: TagSizing) => CSSObject,
): CSSObject {
  return Object.fromEntries(
    sizing.map((sizing) => [`&[data-sizing="${sizing}"]`, callback(sizing)]),
  )
}
