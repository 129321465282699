import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClapperboardIconProps = IconProps

const ClapperboardIcon = forwardRef<SVGSVGElement, ClapperboardIconProps>(
  (props: ClapperboardIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 3 8 8m8-5-1 5m7 0H2m4.8 13h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 18.72 22 17.88 22 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 3 18.88 3 17.2 3H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 5.28 2 6.12 2 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 21 5.12 21 6.8 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClapperboardIcon.displayName = 'ClapperboardIcon'
ClapperboardIcon.defaultProps = iconDefaultProps

export type { ClapperboardIconProps }
export { ClapperboardIcon }
