import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MessageSmileCircleIconProps = IconProps

const MessageSmileCircleIcon = forwardRef<
  SVGSVGElement,
  MessageSmileCircleIconProps
>((props: MessageSmileCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 14s1.312 1.5 3.5 1.5c2.187 0 3.5-1.5 3.5-1.5m-.75-5h.01M9.75 9h.01m2.74 11a8.5 8.5 0 1 0-8.057-5.783c.108.32.162.481.172.604a.899.899 0 0 1-.028.326c-.03.12-.098.245-.232.494l-1.636 3.027c-.233.432-.35.648-.324.815a.5.5 0 0 0 .234.35c.144.087.388.062.876.011l5.121-.529c.155-.016.233-.024.303-.021.07.002.12.009.187.024.069.016.155.05.329.116A8.478 8.478 0 0 0 12.5 20Zm3.25-11a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-5.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      fill="none"
    ></path>
  </Icon>
))

MessageSmileCircleIcon.displayName = 'MessageSmileCircleIcon'
MessageSmileCircleIcon.defaultProps = iconDefaultProps

export type { MessageSmileCircleIconProps }
export { MessageSmileCircleIcon }
