import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import React from 'react'

import { ENV_LOCAL } from '@vori/dashboard-env'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

let browserTracingIntegrationAdded = false

export function useSentry(): void {
  const history = useHistory()
  const { user } = useCurrentUserState()

  // Setup browser tracing based on the current router history.
  React.useEffect(() => {
    if (ENV_LOCAL || browserTracingIntegrationAdded) {
      return
    }

    browserTracingIntegrationAdded = true

    Sentry.addIntegration(
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    )
  }, [history])

  // Sets current user information for Sentry when available.
  React.useEffect(() => {
    if (Sentry.getCurrentScope().getUser()?.id != null) {
      return
    }

    if (ENV_LOCAL || !user.state.isLoggedIn) {
      Sentry.setUser(null)
      return
    }

    Sentry.getCurrentScope().setUser({
      companyID: user.data.companyID,
      companyName: user.data.companyName,
      email: user.data.email || '',
      id: user.data.id,
      storeID: user.metadata.selectedStoreID,
      storeName: user.metadata.selectedStoreName,
      username: user.data.displayName || '',
      userType: user.data.userType,
    })
  }, [
    user.data.companyID,
    user.data.companyName,
    user.data.displayName,
    user.data.email,
    user.data.id,
    user.data.userType,
    user.metadata.selectedStoreID,
    user.metadata.selectedStoreName,
    user.state.isLoggedIn,
  ])

  // Removes current user information from Sentry when the user logs out.
  React.useEffect(() => {
    if (
      ENV_LOCAL ||
      !Sentry.getCurrentScope().getUser()?.id ||
      user.state.isLoggedIn
    ) {
      return
    }

    Sentry.getCurrentScope().setUser(null)
  }, [user.state, user.state.isLoggedIn])
}
