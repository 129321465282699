import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileDownload01IconProps = IconProps

const FileDownload01Icon = forwardRef<SVGSVGElement, FileDownload01IconProps>(
  (props: FileDownload01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 12.5V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h3.7m2.5-3 3 3m0 0 3-3m-3 3v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

FileDownload01Icon.displayName = 'FileDownload01Icon'
FileDownload01Icon.defaultProps = iconDefaultProps

export type { FileDownload01IconProps }
export { FileDownload01Icon }
