import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Contrast03IconProps = IconProps

const Contrast03Icon = forwardRef<SVGSVGElement, Contrast03IconProps>(
  (props: Contrast03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 8.5a7.5 7.5 0 0 1-9.284 7.287 6.5 6.5 0 1 0 9.07-9.07c.14.571.214 1.168.214 1.783Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Contrast03Icon.displayName = 'Contrast03Icon'
Contrast03Icon.defaultProps = iconDefaultProps

export type { Contrast03IconProps }
export { Contrast03Icon }
