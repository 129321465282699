export function composeEventHandlers<
  EventType extends React.SyntheticEvent | Event,
>(
  externalHandler: ((event: EventType) => unknown) | undefined,
  internalHandler: ((event: EventType) => unknown) | undefined,
): (event: EventType) => unknown {
  return (event) => {
    externalHandler?.(event)

    if (!event.defaultPrevented) {
      return internalHandler?.(event)
    }
  }
}
