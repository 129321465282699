import { CSSObject } from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'

import { SpacerCoreProps } from './types'
import { PropsToStyledProps } from '../utils'

export function spacerStyles(
  props: PropsToStyledProps<SpacerCoreProps>,
): CSSObject {
  return {
    pointerEvents: 'none',
    width: foundations.space[props.$size || 'space.100'],
  }
}
