import React from 'react'
import styled, { CSSObject } from 'styled-components'

import Flex from '../Flex'
// import Spacer from '../Spacer'

import { SegmentedProgressBarTokens } from './tokens'
import { toTransitions } from '../utils'

function styles(): CSSObject {
  return {
    ':not(:last-child)': {
      marginRight:
        SegmentedProgressBarTokens.spacing.segmentedProgressBarSegment
          .marginRight,
    },

    '::before': {
      backgroundColor:
        SegmentedProgressBarTokens.colors.segmentedProgressBarSegment
          .backgroundColor,
      borderRadius:
        SegmentedProgressBarTokens.sizing.segmentedProgressBarSegment
          .borderRadius,
      content: '""',
      display: 'block',
      minHeight:
        SegmentedProgressBarTokens.sizing.segmentedProgressBarSegment.minHeight,
      transition: toTransitions(['background-color'], 'ease'),
    },

    '> [data-gourmet-segmented-progressbar-segment-label]': {
      marginTop:
        SegmentedProgressBarTokens.spacing.segmentedProgressBarSegmentLabel
          .marginTop,
    },
  }
}

const FlexContainer = styled(Flex)(styles)

type Props = React.HTMLAttributes<HTMLDivElement>

const SegmentedProgressBarSegment = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function SegmentedProgressBarSegment(
  { children, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  return (
    <FlexContainer
      {...props}
      data-gourmet-segmented-progressbar-segment
      column
      fullWidth
      ref={ref}
      role="presentation"
    >
      {children}
    </FlexContainer>
  )
})

SegmentedProgressBarSegment.displayName = 'SegmentedProgressBarSegment'
SegmentedProgressBarSegment.defaultProps = {}

export {
  SegmentedProgressBarSegment,
  styles as SegmentedProgressBarSegmentStyles,
}
export type { Props as SegmentedProgressBarSegmentProps }
