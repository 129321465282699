import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MessageCircle02IconProps = IconProps

const MessageCircle02Icon = forwardRef<SVGSVGElement, MessageCircle02IconProps>(
  (props: MessageCircle02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 12a9 9 0 0 1-12.385 8.342c-.2-.081-.3-.122-.38-.14a.909.909 0 0 0-.219-.024 2.31 2.31 0 0 0-.353.045l-3.558.593c-.373.062-.56.093-.694.035a.5.5 0 0 1-.262-.262c-.058-.135-.027-.321.035-.694l.593-3.558c.03-.18.045-.27.045-.353a.907.907 0 0 0-.024-.219c-.018-.08-.059-.18-.14-.38A9 9 0 1 1 21 12Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MessageCircle02Icon.displayName = 'MessageCircle02Icon'
MessageCircle02Icon.defaultProps = iconDefaultProps

export type { MessageCircle02IconProps }
export { MessageCircle02Icon }
