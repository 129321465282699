import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Umbrella02IconProps = IconProps

const Umbrella02Icon = forwardRef<SVGSVGElement, Umbrella02IconProps>(
  (props: Umbrella02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 19.4C7 20.836 8.12 22 9.5 22s2.5-1.164 2.5-2.6V11m0 0c-1.61 0-4 1-4 1s-1.39-1-3-1-3 1-3 1C2 6.477 6.477 2 12 2s10 4.477 10 10c0 0-1.39-1-3-1s-3 1-3 1-2.39-1-4-1Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Umbrella02Icon.displayName = 'Umbrella02Icon'
Umbrella02Icon.defaultProps = iconDefaultProps

export type { Umbrella02IconProps }
export { Umbrella02Icon }
