import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Divide01IconProps = IconProps

const Divide01Icon = forwardRef<SVGSVGElement, Divide01IconProps>(
  (props: Divide01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 12h16m-7-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Divide01Icon.displayName = 'Divide01Icon'
Divide01Icon.defaultProps = iconDefaultProps

export type { Divide01IconProps }
export { Divide01Icon }
