import base from './base'

export default {
  avatar: {
    tiny: {
      fontSize: base.typography.fontSizes.caption,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: 1.15,
    },
    small: {
      fontSize: base.typography.fontSizes.caption,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: 1.15,
    },
    medium: {
      fontSize: base.typography.fontSizes.display,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: 1.15,
    },
    base: {
      fontSize: base.typography.fontSizes.h4,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: 1.15,
    },
    large: {
      fontSize: base.typography.fontSizes.h3,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: 1.15,
    },
    xLarge: {
      fontSize: base.typography.fontSizes.h3,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: 1.15,
    },
  },
  button: {
    small: {
      fontSize: base.typography.fontSizes.caption,
      fontWeight: base.typography.fontWeights.medium,
      lineHeight: 1,
    },
    base: {
      fontSize: base.typography.fontSizes.display,
      fontWeight: base.typography.fontWeights.medium,
      lineHeight: 1,
    },
    large: {
      fontSize: base.typography.fontSizes.h5,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: 1.15,
    },
  },

  breadcrumb: {
    fontSize: base.typography.fontSizes.label,
    fontWeight: base.typography.fontWeights.bold,
    lineHeight: 1.15,
  },

  calendar: {
    date: {
      fontSize: base.typography.fontSizes.body,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: base.typography.lineHeights.body,
    },
  },

  input: {
    small: {
      fontSize: base.typography.fontSizes.display,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: 1.35,
    },
    base: {
      fontSize: base.typography.fontSizes.display,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: 1,
    },
    large: {
      fontSize: base.typography.fontSizes.display,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: 1,
    },
  },

  label: {
    fontSize: base.typography.fontSizes.h6,
    fontWeight: base.typography.fontWeights.bold,
    lineHeight: 1.15,
  },

  text: {
    label: {
      fontSize: base.typography.fontSizes.body,
      fontWeight: base.typography.fontWeights.medium,
      lineHeight: base.typography.lineHeights.label,
    },
    caption: {
      fontSize: base.typography.fontSizes.caption,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: base.typography.lineHeights.caption,
    },
    body: {
      fontSize: base.typography.fontSizes.body,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: base.typography.lineHeights.body,
    },
    display: {
      fontSize: base.typography.fontSizes.display,
      fontWeight: base.typography.fontWeights.medium,
      lineHeight: base.typography.lineHeights.display,
    },
    h6: {
      fontSize: base.typography.fontSizes.h6,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: base.typography.lineHeights.h6,
    },
    h5: {
      fontSize: base.typography.fontSizes.h5,
      fontWeight: base.typography.fontWeights.normal,
      lineHeight: base.typography.lineHeights.h5,
    },
    h4: {
      fontSize: base.typography.fontSizes.h4,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: base.typography.lineHeights.h4,
    },
    h3: {
      fontSize: base.typography.fontSizes.h3,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: base.typography.lineHeights.h3,
    },
    h2: {
      fontSize: base.typography.fontSizes.h2,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: base.typography.lineHeights.h2,
    },
    h1: {
      fontSize: base.typography.fontSizes.h1,
      fontWeight: base.typography.fontWeights.bold,
      lineHeight: base.typography.lineHeights.h1,
    },
  },
  tooltip: {
    fontSize: base.typography.fontSizes.caption,
    fontWeight: base.typography.fontWeights.normal,
    lineHeight: base.typography.lineHeights.caption,
  },
}
