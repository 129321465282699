import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { foundations } from '@vori/gourmet-tokens'

function styles(props: {
  $orientation?: Props['orientation']
  $withVerticalHeight?: Props['withVerticalHeight']
}): CSSObject {
  return {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 0,
    flexShrink: 0,

    ...(props.$orientation === 'horizontal' && {
      borderTopColor: foundations.color['color.gray-300'],
      borderTopWidth: 1,
      height: 1,
      width: '100%',
    }),

    ...(props.$orientation === 'vertical' && {
      alignSelf: 'stretch',
      borderLeftColor: foundations.color['color.gray-300'],
      borderLeftWidth: 1,
      display: 'inline-flex',
      width: 1,

      ...(props.$withVerticalHeight && { height: '100%' }),
    }),
  }
}

const StyledDivider = styled.div(styles)

type DividerOrientation = 'horizontal' | 'vertical'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * The orientation for the divider:
   * - `'horizontal' (default)`: Useful for block-style division of content.
   * - `'vertical'`: Useful for inline-style division of content.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-orientation}
   */
  orientation?: DividerOrientation
  /**
   * When using the vertical orientation, you're likely rendering the
   * `<Divider>` component(s) within a `<Flex>` component. In this case, using
   * `alignItems="stretch"` will make the `<Divider>` component take all the
   * available vertical height, as using an explicit `height` value can break
   * the flex layout.
   *
   * @example
   * <Flex alignItems="stretch" fullWidth>
   *   <Button>Button</Button>
   *   <Spacer inline size="tiny" />
   *   <Divider orientation="vertical" />
   *   <Spacer inline size="tiny" />
   *   <Button>Button</Button>
   * </Flex>
   *
   * In the case where you are not rendering within a `<Flex>` component, this
   * prop will assign an explicit `height` of `100%` to take up all the vertical
   * space available.
   *
   * @example
   * <div>
   *   <Text>Inline divider</Text>
   *   <Divider orientation="vertical" withVerticalHeight />
   *   <Text>Inline divider</Text>
   * </div>
   */
  withVerticalHeight?: boolean
  /**
   * @deprecated
   */
  variant?: string
}

/**
 * Provides a visual division between block (horizontal) and inline (vertical)
 * elements.
 *
 * @example
 * <Flex column fullWidth>
 *   <Text>This is a divider</Text>
 *   <Spacer size="tiny" />
 *   <Divider />
 *   <Spacer size="tiny" />
 *   <Text>This is a divider</Text>
 * </Flex>
 *
 * <Flex alignItems="stretch" fullWidth>
 *   <Button>Button</Button>
 *   <Spacer inline size="tiny" />
 *   <Divider orientation="vertical" />
 *   <Spacer inline size="tiny" />
 *   <Button>Button</Button>
 *   <Spacer inline size="tiny" />
 *   <Divider orientation="vertical" />
 *   <Spacer inline size="tiny" />
 *   <Button>Button</Button>
 * </Flex>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/separator_role}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-orientation}
 */
const Divider = React.forwardRef<HTMLDivElement, Props>(function Divider(
  { orientation = 'horizontal', withVerticalHeight, ...props }: Props,
  ref,
) {
  return (
    <StyledDivider
      {...props}
      $orientation={orientation}
      $withVerticalHeight={withVerticalHeight}
      aria-orientation={orientation}
      data-gourmet-divider=""
      ref={ref}
      role="separator"
      tabIndex={-1}
    />
  )
})

Divider.displayName = 'Divider'
Divider.defaultProps = { orientation: 'horizontal' }

export type { Props as DividerProps }
export { Divider, styles as DividerStyles }
