import ReachAlert, { AlertProps as ReachAlertProps } from '@reach/alert'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Card, { CardProps } from '../Card'
import { colors, sizing } from '../tokens'

type AlertProps = React.HTMLAttributes<HTMLDivElement> &
  ReachAlertProps &
  CardProps

const defaultProps: Partial<AlertProps> = {
  className: '',
}

/**
 * @deprecated Use `<AlertNext>` instead
 */
const StyledAlert = styled(
  forwardRef<HTMLDivElement, AlertProps>(function Alert(
    { centerY, type, children, ...rest }: AlertProps,
    ref,
  ) {
    return (
      <ReachAlert type={type}>
        <Card {...rest} centerY={centerY} noShadow ref={ref}>
          {children}
        </Card>
      </ReachAlert>
    )
  }),
)<AlertProps>`
  background: ${colors.alert.backgroundColor};
  border-radius: ${sizing.radius.base};
  border: 1px solid ${colors.alert.borderColor};
`

StyledAlert.displayName = 'Alert'
StyledAlert.defaultProps = defaultProps

export type { AlertProps }
export { defaultProps as AlertDefaultProps }
export default StyledAlert
