import React from 'react'

function isInReactChildren(
  children: Parameters<typeof React.Children.toArray>['0'],
  findFunction: (
    child: ReturnType<typeof React.Children.toArray>[number],
  ) => boolean,
): boolean {
  const found = React.Children.toArray(children).find(findFunction)

  if (found) {
    return true
  } else if (
    children &&
    React.isValidElement(children) &&
    children.props?.children
  ) {
    return isInReactChildren(children.props.children, findFunction)
  }

  return false
}

export { isInReactChildren }
