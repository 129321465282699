import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Signal03IconProps = IconProps

const Signal03Icon = forwardRef<SVGSVGElement, Signal03IconProps>(
  (props: Signal03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.586 13.414a2 2 0 1 1 2.828 0m-5.657 2.829a6 6 0 1 1 8.486 0M4.929 19.07c-3.905-3.905-3.905-10.237 0-14.142 3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142"
        fill="none"
      ></path>
    </Icon>
  ),
)

Signal03Icon.displayName = 'Signal03Icon'
Signal03Icon.defaultProps = iconDefaultProps

export type { Signal03IconProps }
export { Signal03Icon }
