import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Scales02IconProps = IconProps

const Scales02Icon = forwardRef<SVGSVGElement, Scales02IconProps>(
  (props: Scales02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.5 13h6m7 0h6M12 7v14m0-14a2.5 2.5 0 0 0 2.5-2.5M12 7a2.5 2.5 0 0 1-2.5-2.5M4 21h16M4 4.5h5.5m0 0a2.5 2.5 0 0 1 5 0m0 0H20M8.88 14.336C8.48 15.871 7.12 17 5.5 17c-1.618 0-2.98-1.13-3.38-2.664a1.383 1.383 0 0 1-.05-.438 2.882 2.882 0 0 1 .105-.653c.08-.237.167-.371.34-.64L5.5 8l2.986 4.606c.173.268.26.402.34.639.05.145.107.5.106.653-.002.25-.018.313-.051.438Zm13 0C21.48 15.871 20.12 17 18.5 17c-1.618 0-2.98-1.13-3.38-2.664-.033-.125-.05-.188-.05-.438a2.773 2.773 0 0 1 .105-.653c.08-.237.167-.371.34-.64L18.5 8l2.986 4.606c.173.268.26.402.34.639.05.145.107.5.106.653-.002.25-.018.313-.051.438Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Scales02Icon.displayName = 'Scales02Icon'
Scales02Icon.defaultProps = iconDefaultProps

export type { Scales02IconProps }
export { Scales02Icon }
