import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Button, { ButtonColorVariant, ButtonProps } from '../Button'

import { colors } from '../tokens'

type ClickableProps = ButtonProps & {
  noPadding?: boolean
}

const defaultProps: Partial<ClickableProps> = {
  ...Button.defaultProps,
  noPadding: false,
  variant: 'default',
}

/**
 * @deprecated use `<ButtonNext asClickable>...</ButtonNext>`
 */
const StyledClickable = styled(
  forwardRef<HTMLButtonElement, ClickableProps>(function Clickable(
    { noPadding, ...props }: ClickableProps,
    ref,
  ) {
    return <Button {...props} ref={ref} />
  }),
)<ClickableProps>`
  ${({ noPadding }): string => (noPadding ? 'padding: 0px !important;' : '')}
  color: ${({ variant }): string =>
    colors.clickable.color[variant as ButtonColorVariant]};
  background-color: ${colors.clickable.backgroundColor};
  border-color: ${colors.clickable.borderColor};

  :hover,
  :focus,
  :active,
  :disabled,
  &[aria-expanded='true'] {
    color: ${({ variant }): string =>
      colors.clickable.hover.color[variant as ButtonColorVariant]};
    background-color: ${colors.clickable.hover.backgroundColor};
    border-color: ${colors.clickable.hover.borderColor};
  }
`

StyledClickable.displayName = 'Clickable'
StyledClickable.defaultProps = defaultProps

export type { ClickableProps }
export { defaultProps as ClickableDefaultProps }
export default StyledClickable
