import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowUpLeftIconProps = IconProps

const ArrowUpLeftIcon = forwardRef<SVGSVGElement, ArrowUpLeftIconProps>(
  (props: ArrowUpLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 17 7 7m0 0v10M7 7h10"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowUpLeftIcon.displayName = 'ArrowUpLeftIcon'
ArrowUpLeftIcon.defaultProps = iconDefaultProps

export type { ArrowUpLeftIconProps }
export { ArrowUpLeftIcon }
