import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type KeyboardDownIconProps = IconProps

const KeyboardDownIcon = forwardRef<SVGSVGElement, KeyboardDownIconProps>(
  (props: KeyboardDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M6.545 6.636a.91.91 0 0 0 0 1.818h.01a.91.91 0 1 0 0-1.818h-.01Zm3.637 0a.91.91 0 0 0 0 1.818h.009a.91.91 0 1 0 0-1.818h-.01Zm3.636 0a.91.91 0 1 0 0 1.818h.01a.91.91 0 0 0 0-1.818h-.01Zm3.637 0a.91.91 0 1 0 0 1.818h.009a.91.91 0 1 0 0-1.818h-.01Zm-10 4.091a.91.91 0 0 0 0 1.818h9.09a.91.91 0 0 0 0-1.818h-9.09Z"
        stroke="none"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.783 3c-.48 0-.893 0-1.233.028-.36.03-.717.094-1.06.27a2.72 2.72 0 0 0-1.193 1.191c-.175.344-.24.702-.27 1.06C2 5.89 2 6.305 2 6.784v5.16c0 .48 0 .893.028 1.234.03.36.094.717.27 1.06.26.514.678.931 1.191 1.193.344.175.702.24 1.061.27.34.027.754.027 1.233.027h12.434c.48 0 .893 0 1.233-.028.36-.03.717-.094 1.06-.27a2.72 2.72 0 0 0 1.193-1.191c.175-.344.24-.702.27-1.061.027-.34.027-.754.027-1.233V6.783c0-.48 0-.893-.028-1.233-.03-.36-.094-.717-.27-1.06a2.727 2.727 0 0 0-1.191-1.193c-.344-.175-.702-.24-1.061-.27C19.11 3 18.696 3 18.217 3H5.783ZM4.315 4.917c.044-.023.136-.057.383-.077.258-.021.596-.022 1.12-.022h12.364c.524 0 .862 0 1.12.022.247.02.338.054.384.077a.91.91 0 0 1 .397.398c.023.044.057.136.077.383.021.258.022.596.022 1.12v5.09c0 .525 0 .863-.022 1.121-.02.247-.054.338-.077.383a.91.91 0 0 1-.397.398c-.046.023-.137.057-.384.077a15.42 15.42 0 0 1-1.12.022H5.818c-.524 0-.862 0-1.12-.022-.247-.02-.339-.054-.383-.077a.909.909 0 0 1-.398-.398c-.023-.045-.057-.136-.077-.383a15.457 15.457 0 0 1-.022-1.12v-5.09c0-.525 0-.863.022-1.121.02-.247.054-.339.077-.383a.909.909 0 0 1 .398-.398Z"
        clipRule="evenodd"
        stroke="none"
      ></path>
      <path
        fill="currentColor"
        d="M10.479 17.37a.849.849 0 0 0-1.2 1.2l2.12 2.121a.849.849 0 0 0 1.2 0l2.122-2.121a.849.849 0 0 0-1.2-1.2L12 18.89l-1.521-1.521Z"
        stroke="none"
      ></path>
    </Icon>
  ),
)

KeyboardDownIcon.displayName = 'KeyboardDownIcon'
KeyboardDownIcon.defaultProps = iconDefaultProps

export type { KeyboardDownIconProps }
export { KeyboardDownIcon }
