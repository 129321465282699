import { foundations } from '@vori/gourmet-tokens'
import { toRem } from '@vori/gourmet-components'
import React from 'react'
import styled from 'styled-components'

import {
  FlexNext as Flex,
  FlexNextProps as FlexProps,
  InlineFlex,
  TextNext as Text,
} from '@vori/gourmet-components'

const SurplusCounter = styled(InlineFlex)({
  borderColor: foundations.color['color.gray-100'],
  borderRadius: toRem(4),
  borderStyle: 'solid',
  borderWidth: 1,
  height: toRem(32),
  minWidth: toRem(32),
  paddingLeft: foundations.space['space.050'],
  paddingRight: foundations.space['space.050'],
})

type CoreProps = {
  limit?: number
  total?: number
}

type Props = FlexProps & CoreProps

const PaymentGroup = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function PaymentGroup(
  {
    children,
    limit = Infinity,
    total: controlledTotal,
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  const total =
    controlledTotal != null ? controlledTotal : React.Children.count(children)

  return (
    <Flex centerY gap="space.025" ref={ref} wrap>
      {React.Children.toArray(children)
        .slice(0, limit)
        .map((child, index) => (
          <React.Fragment key={`payment-group-${index}`}>
            {child}
          </React.Fragment>
        ))}
      {total > limit && (
        <SurplusCounter center>
          <Text size="text-xs" weight="bold">
            +{total - limit}
          </Text>
        </SurplusCounter>
      )}
    </Flex>
  )
})

PaymentGroup.displayName = 'PaymentGroup'
PaymentGroup.defaultProps = {}

export { PaymentGroup }
export type { CoreProps as PaymentGroupCoreProps, Props as PaymentGroupProps }
