import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SlashDividerIconProps = IconProps

const SlashDividerIcon = forwardRef<SVGSVGElement, SlashDividerIconProps>(
  (props: SlashDividerIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 22 17 2"
        fill="none"
      ></path>
    </Icon>
  ),
)

SlashDividerIcon.displayName = 'SlashDividerIcon'
SlashDividerIcon.defaultProps = iconDefaultProps

export type { SlashDividerIconProps }
export { SlashDividerIcon }
