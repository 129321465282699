import React from 'react'
import styled, { CSSObject } from 'styled-components'
import { getPlatform, platformIsApple } from '@vori/web-utils'

import { KeyboardShortcutTokens } from './tokens'

function styles(): CSSObject {
  return {
    alignItems: 'center',
    backgroundColor: KeyboardShortcutTokens.color.root.backgroundColor,
    borderColor: KeyboardShortcutTokens.color.root.borderColor,
    borderRadius: KeyboardShortcutTokens.sizing.root.borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    color: KeyboardShortcutTokens.color.root.color,
    display: 'inline-flex',
    flexShrink: 0,
    fontSize: KeyboardShortcutTokens.typography.root.fontSize,
    fontWeight: KeyboardShortcutTokens.typography.root.fontWeight,
    justifyContent: 'center',
    lineHeight: KeyboardShortcutTokens.typography.root.lineHeight,
    maxHeight: KeyboardShortcutTokens.sizing.root.maxHeight,
    paddingBottom: KeyboardShortcutTokens.spacing.root.paddingY,
    paddingLeft: KeyboardShortcutTokens.spacing.root.paddingX,
    paddingRight: KeyboardShortcutTokens.spacing.root.paddingX,
    paddingTop: KeyboardShortcutTokens.spacing.root.paddingY,
    textAlign: 'center',
    textTransform: 'capitalize',
    whiteSpace: 'pre',

    '> kbd': {
      textTransform: 'capitalize',
    },
  }
}

const StyledKeyboardShortcut = styled.kbd(styles)

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  /**
   * List of keys to nest into a single keyboard shortcut, e.g.
   * `['command', 'enter']` will result in `<kbd><kbd>⌘<kbd>+<kbd>enter</kbd></kbd>`.
   *
   * - Using the word "command" or "control", will print out `⌘` on Apple devices and `Ctrl` on Windows.
   * - Keys will be separated by a `+` character.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/kbd#usage_notes}
   */
  keys: Array<string>
}

/**
 * Represents inline text denoting textual user input from a keyboard,
 * voice input, or any other text entry device.
 *
 * @example
 * <KeyboardShortcut keys={['command', 'p']} />
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/kbd}
 */
const KeyboardShortcut = React.forwardRef<HTMLSpanElement, Props>(
  function KeyboardShortcut({ keys, ...props }: Props, ref) {
    const platform = getPlatform()

    const parsedKeys = keys.map((key) =>
      key === 'command' && platform === 'macos'
        ? '⌘'
        : key === 'command' && !platformIsApple()
          ? 'ctrl'
          : key,
    )

    return (
      <StyledKeyboardShortcut
        {...props}
        data-gourmet-keyboardshortcut=""
        ref={ref}
      >
        {parsedKeys.length === 1
          ? parsedKeys[0]
          : parsedKeys.map((key, index) => (
              <React.Fragment key={`${key}-${index}`}>
                {index !== 0 ? ' + ' : ''}
                <kbd key={`${key}-${index}`}>{key}</kbd>
              </React.Fragment>
            ))}
      </StyledKeyboardShortcut>
    )
  },
)

KeyboardShortcut.displayName = 'KeyboardShortcut'
KeyboardShortcut.defaultProps = {}

export type { Props as KeyboardShortcutProps }
export { KeyboardShortcut, styles as KeyboardShortcutStyles }
