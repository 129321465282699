import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronRightIconProps = IconProps

const ChevronRightIcon = forwardRef<SVGSVGElement, ChevronRightIconProps>(
  (props: ChevronRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9 18 6-6-6-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ChevronRightIcon.displayName = 'ChevronRightIcon'
ChevronRightIcon.defaultProps = iconDefaultProps

export type { ChevronRightIconProps }
export { ChevronRightIcon }
