import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowDownRightIconProps = IconProps

const ArrowDownRightIcon = forwardRef<SVGSVGElement, ArrowDownRightIconProps>(
  (props: ArrowDownRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m7 7 10 10m0 0V7m0 10H7"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowDownRightIcon.displayName = 'ArrowDownRightIcon'
ArrowDownRightIcon.defaultProps = iconDefaultProps

export type { ArrowDownRightIconProps }
export { ArrowDownRightIcon }
