import { CSSObject } from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'

import { toRem } from '../utils'

export function cardStyles(): CSSObject {
  return {
    backgroundColor: foundations.color['color.base-white'],
    borderColor: foundations.color['color.gray-50'],
    borderRadius: toRem(8),
    borderStyle: 'solid',
    borderWidth: toRem(1),
    boxShadow: foundations.elevation['elevation.shadow-sm'],
    padding: `${foundations.space['space.100']} ${foundations.space['space.125']}`,

    '&:has([data-gourmet-card-banner])': {
      paddingTop: 0,
    },

    '&[data-variant="secondary"]': {
      backgroundColor: foundations.color['color.gray-50'],
    },

    '&[data-as-flat]': {
      borderColor: foundations.color['color.gray-200'],
      boxShadow: 'none',
    },

    '[data-gourmet-card], [data-gourmet-side-panel] &': {
      borderColor: foundations.color['color.gray-200'],
      boxShadow: 'none',
      padding: `${foundations.space['space.075']} ${foundations.space['space.100']}`,

      '&[data-variant="secondary"]': {
        borderColor: 'transparent',
      },
    },
  }
}
