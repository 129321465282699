import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Signal01IconProps = IconProps

const Signal01Icon = forwardRef<SVGSVGElement, Signal01IconProps>(
  (props: Signal01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.243 7.757a6 6 0 0 1 0 8.486m-8.486 0a6 6 0 0 1 0-8.486M4.93 19.071c-3.905-3.905-3.905-10.237 0-14.142m14.142 0c3.905 3.905 3.905 10.237 0 14.142M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Signal01Icon.displayName = 'Signal01Icon'
Signal01Icon.defaultProps = iconDefaultProps

export type { Signal01IconProps }
export { Signal01Icon }
