import React, { memo } from 'react'

import { Flex } from '../../FlexNext'
import { Text } from '../../TextNext'
import Logo from '../../Logo'
import { ListItemProps } from '../../List'
import { ListBoxOption } from '../../ListBox'
import Loader from '../../Loader'

import { optionIsSelected } from './utils/reducer-utils'
import { OptionData } from './utils/types'

const VirtualSelectOption = memo(function VirtualOption<T>({
  data,
  index,
  style,
}: ListItemProps<T, OptionData<T>>) {
  if (data.itemsData == null) {
    return null
  }

  if (!data.isItemLoaded(index)) {
    return (
      <Flex
        center
        data-last-row
        fullWidth
        key="select-option-loader"
        style={style}
      >
        <Loader size="base" {...data.itemsData.loaderProps} />
      </Flex>
    )
  }

  if (index === data.itemCount - 1 && !data.itemsData.disableEndIndicator) {
    return (
      <Flex
        center
        data-last-row
        fullWidth
        key="select-option-logo"
        style={style}
      >
        <Logo
          size="tiny"
          variant="secondaryInverted"
          {...data.itemsData?.endIndicatorProps}
        />
      </Flex>
    )
  }

  const option = data.items[index]
  const optionValue = data.itemsData.getOptionValue?.(option) || String(option)

  return (
    <ListBoxOption
      disabled={Boolean(data.itemsData.getOptionDisabled?.(option))}
      label={data.itemsData.getOptionLabel?.(option) || String(option)}
      menuItemProps={
        data.itemsData.getOptionMenuItemProps != null
          ? {
              fullWidth: true,
              ...data.itemsData.getOptionMenuItemProps(option),
            }
          : { fullWidth: true }
      }
      key={data.itemsData.getOptionKey?.(option) || index}
      style={style}
      value={optionValue}
    >
      <Flex centerY fullWidth>
        <Text size="text-sm">
          {data.itemsData.renderOption?.(
            option,
            optionIsSelected(data.itemsData.internals.state, optionValue),
            data.itemsData.internals,
          ) || String(option)}
        </Text>
      </Flex>
    </ListBoxOption>
  )
})

export default VirtualSelectOption
