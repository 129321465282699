import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { TimelineTokens } from './tokens'

function styles(): CSSObject {
  return {
    ...TimelineTokens.typography.TimelineEntryText,
    color: TimelineTokens.colors.TimelineEntryText.color,
    marginTop: TimelineTokens.spacing.TimelineEntryText.marginTop,
    whiteSpace: 'pre-wrap',

    'a, a:hover, a:active, [data-gourmet-link], [data-gourmet-link]:hover, [data-gourmet-link]:active':
      {
        ...TimelineTokens.typography.TimelineEntryTextLink,
        borderWidth: 0,
        color: TimelineTokens.colors.TimelineEntryTextLink.color,
        display: 'inline',
        height: 'fit-content',
        padding: 0,
        textDecoration: 'none',
      },
  }
}

const Container = styled.div(styles)

type Props = React.HTMLAttributes<HTMLDivElement>

const TimelineEntryText = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function TimelineEntryText(
  { children, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  return (
    <Container {...props} data-gourmet-timeline-entry-content ref={ref}>
      {children}
    </Container>
  )
})

TimelineEntryText.displayName = 'TimelineEntryText'
TimelineEntryText.defaultProps = {}

export { TimelineEntryText, styles as TimelineEntryTextStyles }
export type { Props as TimelineEntryTextProps }
