import React from 'react'

import { DialogPropsFromHook } from '../Dialog'
import { Flex, FlexProps } from '../FlexNext'
import { ModalCloseButton } from './ModalCloseButton'
import { Text } from '../TextNext'
import { useModalContext } from './ModalContext'

type ChildrenFn = (
  modalHeaderProps: DialogPropsFromHook & {
    descriptionID: string
    titleID: string
  },
) => React.ReactNode

type Props = Omit<FlexProps, 'children'> & {
  /**
   * Boolean flag to determine if the close button will not be displayed.
   *
   * **Note: If `true`, make sure you provide an alternative way to close
   * the modal when active.**
   */
  withoutCloseButton?: boolean
} & (
    | {
        /**
         * Use the function-as-children approach when you want to render a custom
         * header for the `<Modal>`. The callback function will provide the
         * IDs for the title and description of the modal to attach to HTML
         * elements.
         *
         * @example
         * function CustomHeader(): React.ReactElement {
         *   const { ref, ...modal } = useModal()
         *
         *   return (
         *     <Modal ref={ref} {...modal}>
         *       <ModalHeader>
         *         {({ descriptionID, titleID }) => (
         *           <Flex
         *             alignItems="flex-start"
         *             fullWidth
         *             justifyContent="space-between"
         *           >
         *             <Flex column fullWidth>
         *               <Text id={titleID}>Some title</Text>
         *               <Text id={descriptionID}>Some description</Text>
         *             </Flex>
         *             <InlineSpacer />
         *             <ModalCloseButton />
         *           </Flex>
         *         )}
         *       </ModalHeader>
         *     </Modal>
         *   )
         * }
         */
        children: ChildrenFn
        /**
         * Provides a description for the modal.
         */
        description?: string
        /**
         * The ID, if any, of the HTML element used to described the modal.
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
         */
        descriptionID?: string
        /**
         * Provides a title for the modal.
         */
        title?: string
        /**
         * The ID, if any, of the HTML element used to label the modal.
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
         */
        titleID?: string
      }
    | ({
        /**
         * Use the function-as-children approach when you want to render a custom
         * header for the `<Modal>`. The callback function will provide the
         * IDs for the title and description of the modal to attach to HTML
         * elements.
         *
         * @example
         * function CustomHeader(): React.ReactElement {
         *   const { ref, ...modal } = useModal()
         *
         *   return (
         *     <Modal ref={ref} {...modal}>
         *       <ModalHeader>
         *         {({ descriptionID, titleID }) => (
         *           <Flex
         *             alignItems="flex-start"
         *             fullWidth
         *             justifyContent="space-between"
         *           >
         *             <Flex column fullWidth>
         *               <Text id={titleID}>Some title</Text>
         *               <Text id={descriptionID}>Some description</Text>
         *             </Flex>
         *             <InlineSpacer />
         *             <ModalCloseButton />
         *           </Flex>
         *         )}
         *       </ModalHeader>
         *     </Modal>
         *   )
         * }
         */
        children?: ChildrenFn
      } & ((
        | {
            /**
             * Provides a title for the modal.
             */
            title: string
            /**
             * The ID, if any, of the HTML element used to label the modal.
             *
             * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
             */
            titleID?: string
          }
        | {
            /**
             * Provides a title for the modal.
             */
            title?: string
            /**
             * The ID of the HTML element used to label the modal.
             *
             * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
             */
            titleID: string
          }
      ) & {
        /**
         * Provides a description for the modal.
         */
        description?: string
        /**
         * The ID, if any, of the HTML element used to described the modal.
         *
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
         */
        descriptionID?: string
      }))
  )

/**
 * Provides basic styling for rendering the header of a `<Modal>` component.
 * It also provides functionality to provide an accessible name and description
 * for a dialog, through the `aria-label` or `aria-labelledby` attributes.
 *
 * @see {@link https://w3c.github.io/aria/#aria-label}
 * @see {@link https://w3c.github.io/aria/#aria-labelledby}
 *
 * @example
 * function Basic(): React.ReactElement {
 *   const { ref, ...modal } = useModal()
 *
 *   return (
 *     <Modal ref={ref} {...modal}>
 *       <ModalHeader title="Some title" description="Some description" />
 *     </Modal>
 *   )
 * }
 *
 * function CustomHeader(): React.ReactElement {
 *   const { ref, ...modal } = useModal()
 *
 *   return (
 *     <Modal ref={ref} {...modal}>
 *       <ModalHeader>
 *         {({ descriptionID, titleID }) => (
 *           <Flex
 *             alignItems="flex-start"
 *             fullWidth
 *             justifyContent="space-between"
 *           >
 *             <Flex column fullWidth>
 *               <Text id={titleID}>Some title</Text>
 *               <Text id={descriptionID}>Some description</Text>
 *             </Flex>
 *             <Spacer inline />
 *             <ModalCloseButton />
 *           </Flex>
 *         )}
 *       </ModalHeader>
 *     </Modal>
 *   )
 * }
 */
const ModalHeader = React.forwardRef<HTMLDivElement, Props>(
  function ModalHeader(
    {
      children,
      description,
      descriptionID,
      title,
      titleID,
      withoutCloseButton,
      ...props
    }: Props,
    ref,
  ): JSX.Element {
    const modalContext = useModalContext()

    return children ? (
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        {...props}
        data-gourmet-modal-header=""
        fullWidth
        ref={ref}
      >
        {children(modalContext)}
      </Flex>
    ) : (
      <Flex
        alignItems="flex-start"
        columnGap="space.050"
        justifyContent="space-between"
        {...props}
        data-gourmet-modal-header=""
        fullWidth
        ref={ref}
      >
        <Flex direction="column" fullWidth>
          <Text
            id={titleID || modalContext.titleID}
            size="text-lg"
            weight="medium"
          >
            {title}
          </Text>
          <Text
            id={descriptionID || modalContext.descriptionID}
            size="text-sm"
            variant="secondary"
          >
            {description}
          </Text>
        </Flex>
        {!withoutCloseButton && <ModalCloseButton />}
      </Flex>
    )
  },
)

ModalHeader.displayName = 'ModalHeader'
ModalHeader.defaultProps = {}

export { ModalHeader }
export type { Props as ModalHeaderProps }
