import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CoinsSwap01IconProps = IconProps

const CoinsSwap01Icon = forwardRef<SVGSVGElement, CoinsSwap01IconProps>(
  (props: CoinsSwap01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 6 2-2m0 0L6 2m2 2H6a4 4 0 0 0-4 4m16 10-2 2m0 0 2 2m-2-2h2a4 4 0 0 0 4-4m-8.583-2.583a6 6 0 1 0-2.834-2.834M14 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CoinsSwap01Icon.displayName = 'CoinsSwap01Icon'
CoinsSwap01Icon.defaultProps = iconDefaultProps

export type { CoinsSwap01IconProps }
export { CoinsSwap01Icon }
