import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserCircleIconProps = IconProps

const UserCircleIcon = forwardRef<SVGSVGElement, UserCircleIconProps>(
  (props: UserCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.316 19.438A4.001 4.001 0 0 1 9 17h6a4.001 4.001 0 0 1 3.684 2.438M16 9.5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm6 2.5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserCircleIcon.displayName = 'UserCircleIcon'
UserCircleIcon.defaultProps = iconDefaultProps

export type { UserCircleIconProps }
export { UserCircleIcon }
