export function triggerInputOnChange(
  input: HTMLInputElement | null,
  value: React.InputHTMLAttributes<HTMLInputElement>['value'],
): void {
  if (!input) {
    return
  }

  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value',
  )?.set

  if (!nativeInputValueSetter) {
    return
  }

  nativeInputValueSetter.call(input, value)
  input.dispatchEvent(new Event('input', { bubbles: true }))
}
