import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SpacingHeight01IconProps = IconProps

const SpacingHeight01Icon = forwardRef<SVGSVGElement, SpacingHeight01IconProps>(
  (props: SpacingHeight01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18V6m0 12-3-2m3 2 3-2M12 6 9 8m3-2 3 2m6-5H3m18 18H3"
        fill="none"
      ></path>
    </Icon>
  ),
)

SpacingHeight01Icon.displayName = 'SpacingHeight01Icon'
SpacingHeight01Icon.defaultProps = iconDefaultProps

export type { SpacingHeight01IconProps }
export { SpacingHeight01Icon }
