import React from 'react'

import { DialogContextValue } from '../Dialog'

type SidePanelContextValue = DialogContextValue & {
  descriptionID: string
  titleID: string
}

const SidePanelContext = React.createContext<SidePanelContextValue | undefined>(
  undefined,
)

SidePanelContext.displayName = 'SidePanelContext'

/**
 * Returns the current `value` for the parent `<SidePanelContext>`, if any.
 */
function useSidePanelContext(): SidePanelContextValue {
  const value = React.useContext(SidePanelContext)

  if (value === undefined) {
    throw new Error(
      'The `useSidePanelContext` hook must be used within `<SidePanelContext.Provider>`',
    )
  }

  return value
}

export { SidePanelContext, useSidePanelContext }
export type { SidePanelContextValue }
