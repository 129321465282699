import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FileAttachment05IconProps = IconProps

const FileAttachment05Icon = forwardRef<
  SVGSVGElement,
  FileAttachment05IconProps
>((props: FileAttachment05IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 14V8.5a1.5 1.5 0 0 1 3 0V14a3 3 0 1 1-6 0v-4m-.2 12h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 19.72 20 18.88 20 17.2V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22Z"
      fill="none"
    ></path>
  </Icon>
))

FileAttachment05Icon.displayName = 'FileAttachment05Icon'
FileAttachment05Icon.defaultProps = iconDefaultProps

export type { FileAttachment05IconProps }
export { FileAttachment05Icon }
