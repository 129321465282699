import { ButtonProps } from '../ButtonNext'
import { DisclosureContextValue } from './DisclosureContext'

export enum DisclosureState {
  OPEN = 'open',
  COLLAPSED = 'collapsed',
}

export type DisclosureProps = {
  /**
   * Render content under the `<Disclosure>` component, which will have access
   * to its context.
   *
   * @example
   * <Disclosure isOpenByDefault>
   *   <DisclosureTrigger>
   *     {(props) => (
   *       <Button {...props}>
   *         Click to reveal
   *       </Button>
   *     )}
   *   </DisclosureTrigger>
   *   <DisclosureContent>
   *     Here I am!
   *   </DisclosureContent>
   * </Disclosure>
   *
   * Use the function-as-children approach when you want access to the disclosure's
   * context value.
   *
   * @example
   * <Disclosure>
   *   {({ isOpen }) => (
   *     <Flex direction="column" fullWidth>
   *       <DisclosureTrigger>
   *         {(props) => (
   *           <Button
   *             {...props}
   *             asClickable
   *             rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
   *             noPadding
   *           >
   *             Click to reveal
   *           </Button>
   *         )}
   *       </DisclosureTrigger>
   *       <DisclosureContent>
   *         {(props) => (
   *           <Flex {...props} direction="column">
   *             <Spacer /> Here I am!
   *           </Flex>
   *         )}
   *       </DisclosureContent>
   *     </Flex>
   *   )}
   * </Disclosure>
   */
  children:
    | React.ReactNode
    | ((contextValue: DisclosureContextValue) => React.ReactNode)
  /**
   * By default, an id will be automatically generated for the inner
   * `<DisclosureContent>` component, but you can optionally pass your own id.
   */
  id?: string
  /**
   * Denotes if the disclosure content is currently opened. Use this prop to
   * define a controlled `<Disclosure>` component.
   *
   * @see {@link https://reactjs.org/docs/forms.html#controlled-components}
   */
  isOpen?: boolean
  /**
   * Will render `<DisclosureContent>` as opened by default in an uncontrolled
   * `<Disclosure>` component.
   */
  isOpenByDefault?: boolean
  /**
   * Callback function executed when the `<DisclosureContent>` is opened.
   */
  onClose?: () => void
  /**
   * Callback function executed when the `<DisclosureContent>` is closed.
   */
  onOpen?: () => void
}

export type DisclosureTriggerProps = {
  /**
   * The function-as-children approach gives you access to the props that need to
   * passed down to the component used as the trigger for opening/closing
   * the `<DisclosureContent>` component.
   *
   * @example
   * <DisclosureTrigger>
   *  {(props, isOpen) => (
   *   <Button
   *     {...props}
   *     asClickable
   *     rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
   *     noPadding
   *   >
   *     Click to reveal
   *   </Button>
   *  )}
   * </DisclosureTrigger>
   */
  children: (
    /**
     * Props to be passed directly to the component used as the trigger for
     * opening/closing the `<DisclosureContent>` component.
     */
    props: ButtonProps & { [dataAttribute: `data-${string}`]: string },
    /**
     * Boolean flag indicating if the `<DisclosureContent>` is currently opened.
     */
    isOpen: boolean,
  ) => React.ReactNode
}

export type DisclosureContentProps = {
  /**
   * The function-as-children approach gives you access to the props that need to
   * passed down to the component used to contain the contents of
   * the `<DisclosureContent>` component.
   *
   * @example
   * <DisclosureContent>
   *   {(props, isOpen) => (
   *     <Flex {...props} direction="column">
   *       <Spacer /> {isOpen ? 'Here I am!' : 'I am not here'}
   *     </Flex>
   *   )}
   * </DisclosureContent>
   */
  children: (
    /**
     * Props to be passed directly to the component used to contain the contents
     * of the `<DisclosureContent>` component.
     */
    props: {
      [dataAttribute: `data-${string}`]: string
      hidden: boolean
      id: string
    },
    /**
     * Boolean flag indicating if the `<DisclosureContent>` is currently opened.
     */
    isOpen: boolean,
  ) => React.ReactNode
}
