import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Map01IconProps = IconProps

const Map01Icon = forwardRef<SVGSVGElement, Map01IconProps>(
  (props: Map01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9 18-7 4V6l7-4m0 16 7 4m-7-4V2m7 20 6-4V2l-6 4m0 16V6m0 0L9 2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Map01Icon.displayName = 'Map01Icon'
Map01Icon.defaultProps = iconDefaultProps

export type { Map01IconProps }
export { Map01Icon }
