import { useClientId } from '@vori/react-hooks'
import React from 'react'

import {
  addValuesToSetInState,
  arrayToSet,
  removeValuesFromSetInState,
} from '@vori/utils-set'

import Flex, { FlexProps } from '../Flex'
import { useControlledState } from '../hooks'

import { AccordionContext } from './AccordionContext'
import { AccordionItem, AccordionItemProps } from './AccordionItem'

interface Props {
  /**
   * Indexes to treat as **currently** active. Use this prop to define a
   * controlled <Accordion> component.
   *
   * @see {@link https://reactjs.org/docs/forms.html#controlled-components}
   */
  activeIndexes?: Set<number> | Array<number>
  /**
   * If `true`, only one `<AccordionItemContent>` component of an
   * **uncontrolled** <Accordion> component can be open at a time.
   */
  collapsible?: boolean
  /**
   * Indexes to treat as **initially** active in an uncontrolled <Accordion>
   * component.
   */
  defaultActiveIndexes?: Set<number> | Array<number>
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: FlexProps
  /**
   * A callback function fired when the <Accordion> component's state changes,
   * i.e. an index gets set or unset.
   */
  onChange?: (index: number) => void
  /**
   * By default, the `<Accordion>` component will make its first
   * `<AccordionItem>` component active, i.e. the first `<AccordionItem>` will
   * always be open (unless using a controlled `<Accordion>` through the
   * `activeIndexes` prop).
   *
   * Use this prop to override this behavior and make all `<AccordionItem>`
   * collapsed by default.
   *
   * *Note: This prop will be ignored when using the `activeIndexes` prop.*
   */
  startCollapsed?: boolean
}

/**
 * An accordion is a vertically stacked set of interactive headings that each
 * contain a title, content snippet, or thumbnail representing a section of
 * content. The headings function as controls that enable users to reveal or hide
 * their associated sections of content. Accordions are commonly used to reduce
 * the need to scroll when presenting multiple sections of content on a single page.
 *
 * If you are familiar with [the disclosure pattern](https://vori-gourmet-1bdf0.web.app/?path=/story/disclosure--default), an accordion will feel very similar.
 * The key distinction is that a disclosure is a standalone component that
 * consists of a single trigger-content-group. Because of this, you cannot navigate
 * between different disclosures with a keyboard the same way you can with an accordion.
 * To provide users with a predictable behavior between components, it is
 * important to keep disclosures and accordions visually distinct across the product.
 *
 * @example
 * function MyAccordion() {
 *   return (
 *     <Accordion>
 *       <AccordionItem>
 *         <AccordionItemTrigger>First Item</AccordionItemTrigger>
 *         <AccordionItemContent>First Content</AccordionItemContent>
 *       </AccordionItem>
 *       <AccordionItem>
 *         <AccordionItemTrigger>Second Item</AccordionItemTrigger>
 *         <AccordionItemContent>Second Content</AccordionItemContent>
 *       </AccordionItem>
 *     </Accordion>
 *   )
 * }
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/accordion}
 */
const Accordion = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function Accordion(
  {
    activeIndexes: controlledActiveIndexes,
    children,
    collapsible,
    defaultActiveIndexes,
    flexProps,
    onChange,
    startCollapsed,
  }: React.PropsWithChildren<Props>,
  ref,
) {
  const [accordionID] = useClientId()

  const [activeIndexes, setActiveIndexesState] = useControlledState<
    Set<number>
  >({
    componentName: 'Accordion',
    controlledValue: Array.isArray(controlledActiveIndexes)
      ? arrayToSet(controlledActiveIndexes)
      : controlledActiveIndexes,
    defaultValue: startCollapsed
      ? new Set()
      : defaultActiveIndexes
        ? Array.isArray(defaultActiveIndexes)
          ? new Set(defaultActiveIndexes)
          : defaultActiveIndexes
        : new Set([0]),
  })

  const setActiveIndex = React.useCallback<(index: number) => void>(
    (index) => {
      setActiveIndexesState(
        collapsible ? new Set([index]) : addValuesToSetInState(index),
      )

      onChange?.(index)
    },
    [collapsible, onChange, setActiveIndexesState],
  )

  const unsetActiveIndex = React.useCallback<(index: number) => void>(
    (index) => {
      setActiveIndexesState(removeValuesFromSetInState(index))
      onChange?.(index)
    },
    [onChange, setActiveIndexesState],
  )

  return (
    <Flex
      alignItems="flex-start"
      column
      fullWidth
      {...flexProps}
      data-gourmet-accordion={accordionID}
      ref={ref}
    >
      <AccordionContext.Provider
        value={{
          activeIndexes,
          setActiveIndex,
          unsetActiveIndex,
        }}
      >
        {React.Children.map(children, (child, index) =>
          child != null &&
          React.isValidElement<AccordionItemProps>(child) &&
          child.type === AccordionItem
            ? React.cloneElement<AccordionItemProps>(child, {
                itemIndex: index,
              })
            : null,
        )}
      </AccordionContext.Provider>
    </Flex>
  )
})

Accordion.displayName = 'Accordion'
Accordion.defaultProps = {}

export { Accordion }
export type { Props as AccordionProps }
