import { foundation } from '../tokens'
import { toRem } from '../utils'

const KeyboardShortcutTokens = {
  color: {
    root: {
      backgroundColor: '#FAFAFA',
      borderColor: '#F4F4F5',
      color: '#70707B',
    },
  },

  typography: {
    root: {
      fontSize: foundation.typography.fontSizes.body,
      fontWeight: foundation.typography.fontWeights.normal,
      lineHeight: foundation.typography.lineHeights.body,
    },
  },

  spacing: {
    root: {
      paddingX: toRem(4),
      paddingY: toRem(2),
    },
  },

  sizing: {
    root: {
      borderRadius: toRem(4),
      maxHeight: toRem(24),
    },
  },
} as const

export { KeyboardShortcutTokens }
