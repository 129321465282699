import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LifeBuoy01IconProps = IconProps

const LifeBuoy01Icon = forwardRef<SVGSVGElement, LifeBuoy01IconProps>(
  (props: LifeBuoy01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.136 9.136 4.93 4.93m0 14.142 4.239-4.239m5.693.032 4.207 4.207m0-14.142-4.24 4.24M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-6 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LifeBuoy01Icon.displayName = 'LifeBuoy01Icon'
LifeBuoy01Icon.defaultProps = iconDefaultProps

export type { LifeBuoy01IconProps }
export { LifeBuoy01Icon }
