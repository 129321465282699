import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LineChartDown05IconProps = IconProps

const LineChartDown05Icon = forwardRef<SVGSVGElement, LineChartDown05IconProps>(
  (props: LineChartDown05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 10 3.434 3.434c.198.198.297.297.411.334.1.033.21.033.31 0 .114-.037.213-.136.41-.334l2.87-2.868c.197-.198.296-.297.41-.334a.499.499 0 0 1 .31 0c.114.037.213.136.41.334L18 14m4-2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LineChartDown05Icon.displayName = 'LineChartDown05Icon'
LineChartDown05Icon.defaultProps = iconDefaultProps

export type { LineChartDown05IconProps }
export { LineChartDown05Icon }
