import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Cloud01IconProps = IconProps

const Cloud01Icon = forwardRef<SVGSVGElement, Cloud01IconProps>(
  (props: Cloud01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.5 19a4.5 4.5 0 0 1-.42-8.98 6.002 6.002 0 0 1 11.84 0A4.5 4.5 0 0 1 17.5 19h-11Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Cloud01Icon.displayName = 'Cloud01Icon'
Cloud01Icon.defaultProps = iconDefaultProps

export type { Cloud01IconProps }
export { Cloud01Icon }
