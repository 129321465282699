import React from 'react'
import styled, { CSSObject } from 'styled-components'

import Flex from '../Flex'
import Spacer from '../Spacer'

import { TimelineTokens } from './tokens'
import { toRelativeTime } from '../utils'

function styles(): CSSObject {
  return {
    ...TimelineTokens.typography.timelineEntryTitle,
    color: TimelineTokens.colors.timelineEntryTitle.color,

    '[data-gourmet-timeline-entry-date]': {
      ...TimelineTokens.typography.timelineEntryTitleDate,
      color: TimelineTokens.colors.timelineEntryTitleDate.color,
      textTransform: 'capitalize',
    },
  }
}

const FlexContainer = styled(Flex)(styles)

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * The date associated with the timeline entry. This date will be
   * automatically formatted as a relative date, e.g. "3 minutes ago".
   */
  date: string | number | Date
}

const TimelineEntryTitle = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function TimelineEntryTitle(
  { children, date, ...props }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element | null {
  return (
    <FlexContainer
      {...props}
      alignItems="baseline"
      data-gourmet-timeline-entry-title
      fullWidth
      ref={ref}
    >
      {children}
      <Spacer inline size="tiny" />
      <time
        dateTime={new Date(date).toISOString()}
        data-gourmet-timeline-entry-date
      >
        {toRelativeTime(date)}
      </time>
    </FlexContainer>
  )
})

TimelineEntryTitle.displayName = 'TimelineEntryTitle'
TimelineEntryTitle.defaultProps = {}

export { TimelineEntryTitle, styles as TimelineEntryTitleStyles }
export type { Props as TimelineEntryTitleProps }
