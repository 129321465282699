import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyEuroCircleIconProps = IconProps

const CurrencyEuroCircleIcon = forwardRef<
  SVGSVGElement,
  CurrencyEuroCircleIconProps
>((props: CurrencyEuroCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15.333 8.273a5 5 0 1 0 0 7.454M6 13.5h5m-5-3h5M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

CurrencyEuroCircleIcon.displayName = 'CurrencyEuroCircleIcon'
CurrencyEuroCircleIcon.defaultProps = iconDefaultProps

export type { CurrencyEuroCircleIconProps }
export { CurrencyEuroCircleIcon }
