import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Share01IconProps = IconProps

const Share01Icon = forwardRef<SVGSVGElement, Share01IconProps>(
  (props: Share01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 12v4.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 18.72 3 17.88 3 16.2V12m13-5-4-4m0 0L8 7m4-4v12"
        fill="none"
      ></path>
    </Icon>
  ),
)

Share01Icon.displayName = 'Share01Icon'
Share01Icon.defaultProps = iconDefaultProps

export type { Share01IconProps }
export { Share01Icon }
