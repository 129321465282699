import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowNarrowUpRightIconProps = IconProps

const ArrowNarrowUpRightIcon = forwardRef<
  SVGSVGElement,
  ArrowNarrowUpRightIconProps
>((props: ArrowNarrowUpRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18 18 6m0 0h-8m8 0v8"
      fill="none"
    ></path>
  </Icon>
))

ArrowNarrowUpRightIcon.displayName = 'ArrowNarrowUpRightIcon'
ArrowNarrowUpRightIcon.defaultProps = iconDefaultProps

export type { ArrowNarrowUpRightIconProps }
export { ArrowNarrowUpRightIcon }
