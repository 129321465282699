import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type User03IconProps = IconProps

const User03Icon = forwardRef<SVGSVGElement, User03IconProps>(
  (props: User03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 20c2.336-2.477 5.507-4 9-4 3.493 0 6.664 1.523 9 4M16.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

User03Icon.displayName = 'User03Icon'
User03Icon.defaultProps = iconDefaultProps

export type { User03IconProps }
export { User03Icon }
