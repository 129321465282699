import { CSSObject } from 'styled-components'
import React from 'react'
import styled from 'styled-components'
import { IconProps } from '@vori/gourmet-icons'

import { InlineFlex, InlineFlexProps } from '../FlexNext'
import { BadgeSize, BadgeVariant } from './types'
import { BadgeTokens } from './tokens'
import { BADGE_DEFAULT_SIZE, BADGE_DEFAULT_VARIANT } from './constants'

function styles(props: {
  $size?: Props['size']
  $variant?: Props['variant']
}): CSSObject {
  return {
    backgroundColor:
      BadgeTokens.color.root[props.$variant || BADGE_DEFAULT_VARIANT]
        .backgroundColor,
    borderRadius: BadgeTokens.sizing.root.borderRadius,
    color:
      BadgeTokens.color.root[props.$variant || BADGE_DEFAULT_VARIANT].color,
    fontSize:
      BadgeTokens.typography.root[props.$size || BADGE_DEFAULT_SIZE].fontSize,
    fontWeight:
      BadgeTokens.typography.root[props.$size || BADGE_DEFAULT_SIZE].fontWeight,
    lineHeight:
      BadgeTokens.typography.root[props.$size || BADGE_DEFAULT_SIZE].lineHeight,
    minHeight:
      BadgeTokens.sizing.root[props.$size || BADGE_DEFAULT_SIZE].minHeight,
    paddingLeft:
      BadgeTokens.spacing.root[props.$size || BADGE_DEFAULT_SIZE].paddingLeft,
    paddingRight:
      BadgeTokens.spacing.root[props.$size || BADGE_DEFAULT_SIZE].paddingRight,

    ['& [data-gourmet-badge-icon]']: {
      flexShrink: 0,
    },
  }
}

const BadgeFlexContainer = styled(InlineFlex)(styles)

type Props = {
  className?: string
  /**
   * Props to be passed to the inner `<Flex>` component.
   */
  flexProps?: InlineFlexProps
  /**
   * Provides a method for prefacing the visible content of the `<Badge>`
   * component with text that is not displayed when a screen reader reads the
   * content.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/status_role#aria-label}
   */
  label: string
  /**
   * An icon to be displayed on the left side of the `<Badge>` component.
   *
   * @example
   * <Badge label="Your profit margin has increased by 32%" leftIcon={<PointArrowUpIcon />}>32%</Badge>
   */
  leftIcon?: React.ReactElement<IconProps> | null
  /**
   * An icon to be displayed on the right side of the `<Badge>` component.
   *
   * @example
   * <Badge label="We have 4 new updates for you" rightIcon={<PointArrowRightIcon />}>4</Badge>
   */
  rightIcon?: React.ReactElement<IconProps> | null
  /**
   * Specifies the size of the `<Badge>` component, which is `'small'` by
   * default.
   */
  size?: BadgeSize
  /**
   * Specifies the variant or color of the `<Badge>` component, which
   * is `'default'` by default.
   */
  variant?: BadgeVariant
}

/**
 * Badges help highlight important information, such as notifications or new
 * and unread messages. They're primarily used for communicating secondary or
 * additional information to text.
 *
 * @example
 * <Badge label="We have 4 new updates for you" rightIcon={<PointArrowRightIcon />} variant="primary">4</Badge>'
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/status_role}
 * @see {@link https://www.w3.org/WAI/WCAG22/Techniques/aria/ARIA22#examples}
 */
const Badge = React.forwardRef<HTMLSpanElement, React.PropsWithChildren<Props>>(
  function Badge(
    {
      children,
      label,
      size = BADGE_DEFAULT_SIZE,
      variant = BADGE_DEFAULT_VARIANT,
      ...props
    }: React.PropsWithChildren<Props>,
    ref,
  ): JSX.Element {
    return (
      <BadgeFlexContainer
        $size={size}
        $variant={variant}
        aria-label={label}
        centerY
        className={props.className}
        data-gourmet-badge=""
        data-size={size}
        data-variant={variant}
        gap="space.025"
        ref={ref}
        role="status"
        title={label}
        {...props.flexProps}
      >
        {props.leftIcon &&
          React.isValidElement(props.leftIcon) &&
          React.cloneElement<IconProps>(
            props.leftIcon,
            {
              'aria-hidden': true,
              'data-gourmet-badge-icon-left': '',
              size: 'sm',
            },
            null,
          )}
        {children}
        {props.rightIcon &&
          React.isValidElement(props.rightIcon) &&
          React.cloneElement<IconProps>(
            props.rightIcon,
            {
              'aria-hidden': true,
              'data-gourmet-badge-icon-right': '',
              size: 'sm',
            },
            null,
          )}
      </BadgeFlexContainer>
    )
  },
)

Badge.displayName = 'Badge'
Badge.defaultProps = {}

export { Badge, styles as BadgeStyles }
export type { Props as BadgeProps }
