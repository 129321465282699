import { toRem } from '../utils'

const BadgeTokens = {
  color: {
    root: {
      active: {
        backgroundColor: '#EFF8FF',
        color: '#175CD3',
      },
      complete: {
        backgroundColor: '#26272B',
        color: '#FFFFFF',
      },
      default: {
        backgroundColor: '#F4F4F5',
        color: '#3F3F46',
      },
      negative: {
        backgroundColor: '#FEF2F3',
        color: '#BB0C1D',
      },
      positive: {
        backgroundColor: '#ECFDF3',
        color: '#027A4C',
      },
      primary: {
        backgroundColor: '#F4F3FF',
        color: '#5235D0',
      },
      warning: {
        backgroundColor: '#FEFDE8',
        color: '#A17016',
      },
    },
  },

  typography: {
    root: {
      small: {
        fontSize: toRem(12),
        fontWeight: 500,
        lineHeight: 1.15,
      },
      medium: {
        fontSize: toRem(14),
        fontWeight: 500,
        lineHeight: 1.15,
      },
      large: {
        fontSize: toRem(14),
        fontWeight: 500,
        lineHeight: 1.15,
      },
    },
  },

  spacing: {
    root: {
      small: {
        paddingLeft: toRem(8),
        paddingRight: toRem(8),
      },
      medium: {
        paddingLeft: toRem(10),
        paddingRight: toRem(10),
      },
      large: {
        paddingLeft: toRem(12),
        paddingRight: toRem(12),
      },
    },

    iconLeft: {
      marginRight: toRem(4),
    },

    iconRight: {
      marginLeft: toRem(4),
    },
  },

  sizing: {
    root: {
      borderRadius: toRem(9999),

      small: {
        minHeight: toRem(22),
      },
      medium: {
        minHeight: toRem(24),
      },
      large: {
        minHeight: toRem(28),
      },
    },
  },
} as const

export { BadgeTokens }
