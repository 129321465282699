import { CSSObject } from 'styled-components'
import React from 'react'
import styled from 'styled-components'

import { InputStyles } from '../InputNext'
import { getBackgroundCSSProperties } from './utils'
import { composeEventHandlers, composeRefs, toRem } from '../utils'

const styles = function styles(): CSSObject {
  return {
    ...InputStyles(),
    minHeight: toRem(80),
  }
}

const StyledTextArea = styled.textarea(styles)

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  /**
   * When `true`, the height of the textarea element will automatically expand or
   * retract according to its contents.
   */
  autoGrow?: boolean
  /**
   * When `true`, the textarea will be rendered without background and border
   * colors. This is useful when you want to introduce user textarea as part of a
   * more complex component, e.g. data grid cell, tagged textarea, etc.
   */
  asEditable?: boolean
  /**
   * When `true`, the textarea will take up all available horizontal space.
   */
  fullWidth?: boolean
  /**
   * Provides the ability to display an icon to the left of the user textarea.
   */
  leftIcon?: React.ReactElement | null
  /**
   * Disables the focus ring that appears when the textarea is focused. This should
   * never be `true`due to accessibility concerns unless you intend to also use
   * the `asEditable` prop and provide custom focus styling using `:focus-within`.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/:focus#focus_outline_none}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within}
   */
  noFocusRing?: boolean
  /**
   * Removes padding from the textarea element. This is really only useful in
   * conjunction with the `asEditable` prop, to make it easier to blend the
   * textarea into a more complex component that requires user textarea.
   */
  noPadding?: boolean
  /**
   * Provides the ability to display an icon to the right of the user textarea.
   * Note that this icon will be overridden if the textarea has the
   * `aria-invalid="true"` attribute(s), as we render an error icon to the
   * right by default.
   */
  rightIcon?: React.ReactElement | null
}

/**
 * The `<TextArea>` component represents a multi-line plain-text editing control,
 * useful when you want to allow users to enter a sizeable amount of free-form
 * text, for example a comment on a review or feedback form.
 *
 * @example
 * <TextArea
 *   fullWidth
 *   leftIcon={<AtSignIcon />}
 *   placeholder="email@example.com"
 *   type="email"
 * />
 *
 * @see {@link https://html.spec.whatwg.org/multipage/form-elements.html#the-textarea-element}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea}
 */
const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(function TextArea(
  {
    asEditable,
    autoGrow,
    fullWidth,
    leftIcon,
    noFocusRing,
    noPadding,
    rightIcon,
    ...props
  }: Props,
  ref,
): JSX.Element {
  const [internalRef, setInternalRef] =
    React.useState<HTMLTextAreaElement | null>(null)

  const hasSetInitialAutoHeight = React.useRef(false)

  const setAutoHeight = React.useCallback(() => {
    if (!internalRef || !autoGrow) {
      return
    }

    hasSetInitialAutoHeight.current = true
    internalRef.style.height = '1px'
    internalRef.style.height = `${internalRef.scrollHeight}px`
  }, [autoGrow, internalRef])

  React.useLayoutEffect(() => {
    const raf = requestAnimationFrame(() => {
      if (hasSetInitialAutoHeight.current) {
        return
      }

      if (props.defaultValue || props.value) {
        setAutoHeight()
      }
    })

    return () => {
      cancelAnimationFrame(raf)
    }
  }, [props.defaultValue, props.value, setAutoHeight])

  return (
    <StyledTextArea
      {...props}
      data-gourmet-textarea=""
      {...(asEditable && { 'data-editable': '' })}
      {...(autoGrow && { 'data-auto-grow': '' })}
      {...(fullWidth && { 'data-full-width': '' })}
      {...(leftIcon && { 'data-left-icon': '' })}
      {...(noFocusRing && { 'data-no-focus-ring': '' })}
      {...(noPadding && { 'data-no-padding': '' })}
      {...(rightIcon && { 'data-right-icon': '' })}
      onChange={composeEventHandlers(props.onChange, () => {
        setAutoHeight()
      })}
      ref={composeRefs([ref, setInternalRef])}
      style={{
        ...getBackgroundCSSProperties({
          leftIcon,
          noPadding,
          rightIcon,
          ...props,
        }),
      }}
    />
  )
})

TextArea.displayName = 'TextArea'
TextArea.defaultProps = {}

export { TextArea, styles as TextAreaStyles }
export type { Props as TextAreaProps }
