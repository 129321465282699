import { nanoid } from 'nanoid'
import React, { forwardRef, useRef } from 'react'
import styled from 'styled-components'

type HiddenInputProps = React.InputHTMLAttributes<HTMLInputElement>

const StyledHiddenInput = styled(
  forwardRef<HTMLInputElement, HiddenInputProps>(function HiddenInput(
    { value, ...props }: HiddenInputProps,
    ref,
  ) {
    const inputId = useRef(nanoid())

    return (
      <input
        {...props}
        aria-hidden="true"
        defaultValue={value}
        key={`hidden-input-${inputId.current}-${value}`}
        ref={ref}
        tabIndex={-1}
        type="hidden"
      />
    )
  }),
)<HiddenInputProps>`
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`

StyledHiddenInput.displayName = 'HiddenInput'

export type { HiddenInputProps }
export default StyledHiddenInput
