import { useClientId, usePreviousValue } from '@vori/react-hooks'
import React from 'react'

import { DisclosureContext, DisclosureContextValue } from './DisclosureContext'
import { DisclosureProps } from './types'
import { useControlledState } from '../hooks'

const defaultProps: Partial<DisclosureProps> = {
  isOpenByDefault: false,
}

/**
 * A disclosure is a component that enables content to be either collapsed (hidden)
 * or expanded (visible). It has two elements: a disclosure trigger and content
 * whose visibility is controlled by the trigger. When the controlled content is
 * hidden, the button is often styled as a typical push button with a right-pointing
 * arrow or triangle to hint that activating the button will display additional
 * content. When the content is visible, the arrow or triangle typically points down.
 *
 * If you have a group of disclosures that stack vertically and exist within the
 * same logical context, you may want to [use `<Accordion>` instead](https://vori-gourmet-1bdf0.web.app/?path=/story/accordion--default).
 *
 * @example
 * <Disclosure>
 *   <Flex direction="column" fullWidth>
 *     <DisclosureTrigger>
 *       {(props) => <Button {...props}>Click to reveal</Button>}
 *     </DisclosureTrigger>
 *     <DisclosureContent>
 *       {(props) => (
 *         <Flex {...props} direction="column">
 *           <Spacer /> Here I am!
 *         </Flex>
 *       )}
 *     </DisclosureContent>
 *   </Flex>
 * </Disclosure>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/}
 */
function Disclosure({
  id,
  children,
  isOpen: controlledIsOpen,
  isOpenByDefault,
  onClose,
  onOpen,
}: DisclosureProps): JSX.Element {
  const [disclosureID] = useClientId('gourmet-disclosure')

  const [isOpen, setState] = useControlledState<boolean>({
    componentName: 'Disclosure',
    controlledValue: controlledIsOpen,
    defaultValue: Boolean(isOpenByDefault),
  })

  const prevIsOpen = usePreviousValue(isOpen)

  const contextValue = React.useMemo<DisclosureContextValue>(
    () => ({ id: id || disclosureID, isOpen, setState }),
    [disclosureID, id, isOpen, setState],
  )

  React.useEffect(() => {
    if (prevIsOpen === undefined || prevIsOpen === isOpen) {
      return
    }

    if (isOpen && onOpen != null) {
      onOpen()
    } else if (onClose != null) {
      onClose()
    }
  }, [isOpen, onClose, onOpen, prevIsOpen])

  return (
    <DisclosureContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
    </DisclosureContext.Provider>
  )
}

Disclosure.displayName = 'Disclosure'
Disclosure.defaultProps = defaultProps

export { Disclosure }
