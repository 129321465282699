import { useId } from '@reach/auto-id'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Text from '../Text'
import Spacer from '../Spacer'

type FormFieldProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  description?: React.ReactNode
  error?: React.ReactNode
  fullWidth?: boolean
  label?: React.ReactNode
  required?: boolean
  /**
   * When using this prop, the wrapper element for this `FormField` will be
   * a `span` element as opposed to a `label` element. This prevents inner
   * input elements from automatically focusing when clicking on parts of the
   * `FormField` component, e.g. `label`, `error` or `description`.
   *
   * Using the `preventFocusCapture` prop will still allow you to create
   * accessible form fields when specifying a `label` or `description` because
   * `aria-labelledby` and `aria-describedby` are still properly assigned
   * under the hood.
   */
  preventFocusCapture?: boolean
}

const defaultProps: Partial<FormFieldProps> = {
  className: '',
  description: null,
  error: null,
  fullWidth: false,
  label: null,
  preventFocusCapture: false,
  required: false,
}

function renderWithContainer(
  type: 'label' | 'span',
  content: React.ReactNode,
  props: FormFieldProps,
  ref: React.ForwardedRef<HTMLLabelElement | HTMLSpanElement>,
): JSX.Element {
  if (type === 'label') {
    return (
      <label {...props} ref={ref as React.ForwardedRef<HTMLLabelElement>}>
        {content}
      </label>
    )
  } else if (type === 'span') {
    return (
      <span {...props} ref={ref as React.ForwardedRef<HTMLSpanElement>}>
        {content}
      </span>
    )
  } else {
    return <>{content}</>
  }
}

/**
 * @deprecated Use `<FormFieldNext>` instead
 */
const StyledFormField = styled(
  forwardRef<HTMLLabelElement | HTMLSpanElement, FormFieldProps>(
    function FormField(
      {
        children,
        description,
        error,
        fullWidth,
        label,
        preventFocusCapture,
        required,
        ...props
      }: FormFieldProps,
      ref,
    ) {
      const labelId = `form-field--label--${useId()}`
      const descriptionId = `form-field--description--${useId()}`

      const ariaAttributes = {
        ...(label != null && { 'aria-labelledby': labelId }),
        ...(description != null && { 'aria-describedby': descriptionId }),
      }

      return (
        <>
          {renderWithContainer(
            preventFocusCapture ? 'span' : 'label',
            <>
              {label && (
                <>
                  <Text id={labelId} size="label">
                    {label}{' '}
                    {required && (
                      <Text variant="negative" size="label">
                        *
                      </Text>
                    )}
                  </Text>
                  <Spacer size="tiny" />
                </>
              )}
              <span data-form-controls>{children}</span>
              {error && (
                <>
                  <Spacer size="tiny" />
                  <Text size="caption" variant="negative">
                    {error}
                  </Text>
                </>
              )}
              {description && (
                <>
                  <Spacer size="tiny" />
                  <Text id={descriptionId} size="caption" variant="secondary">
                    {description}
                  </Text>
                </>
              )}
            </>,
            { ...props, ...ariaAttributes },
            ref,
          )}
        </>
      )
    },
  ),
)<FormFieldProps>`
  ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  align-items: flex-start;
  cursor: default;
  display: flex;
  flex-direction: column;
  position: relative;

  > [data-form-controls],
  > [data-form-controls] > * {
    ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  }
`

StyledFormField.displayName = 'FormField'
StyledFormField.defaultProps = defaultProps

export type { FormFieldProps }
export { defaultProps as FormFieldDefaultProps }
export default StyledFormField
