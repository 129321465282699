import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LogIn01IconProps = IconProps

const LogIn01Icon = forwardRef<SVGSVGElement, LogIn01IconProps>(
  (props: LogIn01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 3h1.2c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C21 5.28 21 6.12 21 7.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 21 17.88 21 16.2 21H15M10 7l5 5m0 0-5 5m5-5H3"
        fill="none"
      ></path>
    </Icon>
  ),
)

LogIn01Icon.displayName = 'LogIn01Icon'
LogIn01Icon.defaultProps = iconDefaultProps

export type { LogIn01IconProps }
export { LogIn01Icon }
