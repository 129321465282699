import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Target04IconProps = IconProps

const Target04Icon = forwardRef<SVGSVGElement, Target04IconProps>(
  (props: Target04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 8V5l3-3 1 2 2 1-3 3h-3Zm0 0-4 4m10 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2m5 10a5 5 0 1 1-5-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

Target04Icon.displayName = 'Target04Icon'
Target04Icon.defaultProps = iconDefaultProps

export type { Target04IconProps }
export { Target04Icon }
