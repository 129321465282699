import React from 'react'

import { RadioInputFieldValue } from './types'

type RadioInputFieldContextValue = {
  checkedValue: RadioInputFieldValue
  descriptionID: string | null
  errorID: string | null
  focusedValue: RadioInputFieldValue
  focusTriggerID: string
  isCheckedValue: (value: RadioInputFieldValue) => boolean
  isFocusedValue: (value: RadioInputFieldValue) => boolean
  setCheckedValue: (value: RadioInputFieldValue) => void
  setFocusedValue: (value: RadioInputFieldValue) => void
}

const RadioInputFieldContext = React.createContext<
  RadioInputFieldContextValue | undefined
>(undefined)

RadioInputFieldContext.displayName = 'RadioInputFieldContext'

/**
 * Returns the current `value` for the parent `<RadioInputFieldContext>`, if any.
 */
export function useRadioInputFieldContext():
  | RadioInputFieldContextValue
  | undefined {
  return React.useContext(RadioInputFieldContext)
}

export { RadioInputFieldContext }
export type { RadioInputFieldContextValue }
