import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleLeftIconProps = IconProps

const ArrowCircleLeftIcon = forwardRef<SVGSVGElement, ArrowCircleLeftIconProps>(
  (props: ArrowCircleLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m12 8-4 4m0 0 4 4m-4-4h8m6 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowCircleLeftIcon.displayName = 'ArrowCircleLeftIcon'
ArrowCircleLeftIcon.defaultProps = iconDefaultProps

export type { ArrowCircleLeftIconProps }
export { ArrowCircleLeftIcon }
