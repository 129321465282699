import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarLineChartIconProps = IconProps

const BarLineChartIcon = forwardRef<SVGSVGElement, BarLineChartIconProps>(
  (props: BarLineChartIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 20v-7m-8 7V10M4 20v-4m9.407-10.973 5.168 1.939M10.8 5.4 5.2 9.6m15.86-3.16a1.5 1.5 0 1 1-2.121 2.12 1.5 1.5 0 0 1 2.122-2.12Zm-16 3a1.5 1.5 0 1 1-2.12 2.12 1.5 1.5 0 0 1 2.12-2.12Zm8-6a1.5 1.5 0 1 1-2.121 2.12 1.5 1.5 0 0 1 2.122-2.12Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarLineChartIcon.displayName = 'BarLineChartIcon'
BarLineChartIcon.defaultProps = iconDefaultProps

export type { BarLineChartIconProps }
export { BarLineChartIcon }
