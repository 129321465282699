import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Link05IconProps = IconProps

const Link05Icon = forwardRef<SVGSVGElement, Link05IconProps>(
  (props: Link05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 17H7A5 5 0 0 1 7 7h2m-1 5h10m-2.222 5H17a5 5 0 0 0 0-10h-1.222a.778.778 0 0 0-.778.778v8.444c0 .43.348.778.778.778Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Link05Icon.displayName = 'Link05Icon'
Link05Icon.defaultProps = iconDefaultProps

export type { Link05IconProps }
export { Link05Icon }
