import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockStopwatchIconProps = IconProps

const ClockStopwatchIcon = forwardRef<SVGSVGElement, ClockStopwatchIconProps>(
  (props: ClockStopwatchIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 9.5v4l2.5 1.5M12 5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm0 0V2m-2 0h4m6.329 3.592-1.5-1.5.75.75m-15.908.75 1.5-1.5-.75.75"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClockStopwatchIcon.displayName = 'ClockStopwatchIcon'
ClockStopwatchIcon.defaultProps = iconDefaultProps

export type { ClockStopwatchIconProps }
export { ClockStopwatchIcon }
