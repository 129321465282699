import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type RefreshCw02IconProps = IconProps

const RefreshCw02Icon = forwardRef<SVGSVGElement, RefreshCw02IconProps>(
  (props: RefreshCw02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 14s.121.85 3.636 4.364A9 9 0 0 0 20.776 14M2 14v6m0-6h6m14-4s-.121-.85-3.636-4.364A9 9 0 0 0 3.224 10M22 10V4m0 6h-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

RefreshCw02Icon.displayName = 'RefreshCw02Icon'
RefreshCw02Icon.defaultProps = iconDefaultProps

export type { RefreshCw02IconProps }
export { RefreshCw02Icon }
