import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { Dialog, DialogProps, useDialogContext } from '../Dialog'
import { Flex } from '../FlexNext'
import { ModalContext, ModalContextValue } from './ModalContext'
import { useControlledState } from '../hooks'

function styles(): CSSObject {
  return {
    bottom: 0,
    height: '100%',
    left: 0,
    overflow: 'auto',
    right: 0,
    top: 0,
    width: '100%',

    '&[open]': {
      alignItems: 'stretch',
      display: 'flex',
    },
  }
}

const ModalDialog = styled(Dialog)(styles)

type Props = Omit<DialogProps, 'aria-label' | 'aria-labelledby'> &
  Pick<
    React.DialogHTMLAttributes<HTMLDialogElement>,
    'aria-label' | 'aria-labelledby'
  > & {
    /**
     * The ID, if any, of the HTML element used to described the modal.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
     */
    descriptionID?: string
    /**
     * The ID, if any, of the HTML element used to label the modal.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
     */
    titleID?: string
  }

function ModalCore({
  children,
  descriptionID,
  titleID,
}: React.PropsWithChildren<
  Required<Pick<Props, 'descriptionID' | 'titleID'>>
>): JSX.Element {
  const dialogContextValue = useDialogContext()

  const contextValue = React.useMemo<ModalContextValue>(
    () => ({ ...dialogContextValue, descriptionID, titleID }),
    [descriptionID, dialogContextValue, titleID],
  )

  return (
    <ModalContext.Provider value={contextValue}>
      <Flex
        ref={(dialogElement) => {
          if (!dialogElement) {
            return
          }

          if (
            dialogElement.scrollHeight > dialogElement.clientHeight &&
            dialogElement.style['alignItems'] !== 'flex-start'
          ) {
            dialogElement.style['alignItems'] = 'flex-start'
          } else if (dialogElement.style['alignItems'] !== 'center') {
            dialogElement.style['alignItems'] = 'center'
          }
        }}
        data-gourmet-modal-container=""
        center
        flex={1}
        fullWidth
      >
        {children}
      </Flex>
    </ModalContext.Provider>
  )
}

/**
 * A dialog is a window overlaid on either the primary window or another dialog
 * window. Windows under a modal dialog are inert. That is, users cannot interact
 * with content outside an active dialog window. Inert content outside an active
 * dialog is typically visually obscured or dimmed so it is difficult to discern,
 * and in some implementations, attempts to interact with the inert content cause
 * the dialog to close.
 *
 * Modals contain their tab sequence. That is, Tab and Shift + Tab do not move
 * focus outside the dialog. However, unlike most non-modal dialogs, modal dialogs
 * do not provide means for moving keyboard focus outside the dialog window
 * without closing the dialog.
 *
 * @example
 * const { ref, triggerProps, ...modal } = useModal()
 *
 * <Button {...triggerProps} onClick={modal.open}>Open</Button>
 *
 * <Modal ref={ref} {...modal}>
 *   <ModalContent>
 *     <ModalHeader
 *       title="This is a modal"
 *       description="Here is the description for this modal"
 *     />
 *     <Text>This is a modal</Text>
 *     <ModalFooter>
 *       <Button
 *         onClick={() => {
 *           modal.close()
 *         }}
 *       >
 *         Cancel
 *       </Button>
 *       <Button variant="tertiary">Save</Button>
 *     </ModalFooter>
 *   </ModalContent>
 * </Modal>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/examples/dialog/}
 */
const Modal = React.forwardRef<
  HTMLDialogElement,
  React.PropsWithChildren<Props>
>(function Modal(
  {
    children,
    descriptionID: controlledDescriptionID,
    titleID: controlledTitleID,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [defaultDescriptionID] = useClientId('gourmet-modal-description')
  const [defaultTitleID] = useClientId('gourmet-modal-title')

  const [descriptionID] = useControlledState({
    componentName: 'Modal',
    controlledValue: controlledDescriptionID,
    defaultValue: defaultDescriptionID,
  })

  const [titleID] = useControlledState({
    componentName: 'Modal',
    controlledValue: controlledTitleID,
    defaultValue: defaultTitleID,
  })

  return (
    <ModalDialog
      {...props}
      {...(!props.isModal && { 'data-as-dialog': '' })}
      aria-describedby={descriptionID}
      aria-labelledby={titleID}
      data-gourmet-modal=""
      ref={ref}
    >
      <ModalCore descriptionID={descriptionID} titleID={titleID}>
        {children}
      </ModalCore>
    </ModalDialog>
  )
})

Modal.displayName = 'Modal'
Modal.defaultProps = {}

export { Modal }
export type { Props as ModalProps }
