import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ScissorsCut01IconProps = IconProps

const ScissorsCut01Icon = forwardRef<SVGSVGElement, ScissorsCut01IconProps>(
  (props: ScissorsCut01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 4 8.5 15.5m0-7L20 20m-2.5-8h.01M22 12h.01M6 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 12a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ScissorsCut01Icon.displayName = 'ScissorsCut01Icon'
ScissorsCut01Icon.defaultProps = iconDefaultProps

export type { ScissorsCut01IconProps }
export { ScissorsCut01Icon }
