import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignLeftIconProps = IconProps

const AlignLeftIcon = forwardRef<SVGSVGElement, AlignLeftIconProps>(
  (props: AlignLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 10H3m17-4H3m17 8H3m13 4H3"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignLeftIcon.displayName = 'AlignLeftIcon'
AlignLeftIcon.defaultProps = iconDefaultProps

export type { AlignLeftIconProps }
export { AlignLeftIcon }
