import React from 'react'

export function GiftCardIcon(): JSX.Element {
  return (
    <g>
      <rect width="46" height="32" rx="4" fill="#FFFFFF" />
      <path
        d="M14.7262 15.1534C14.6893 15.0028 14.631 14.8707 14.5515 14.7571C14.4719 14.6406 14.3725 14.5426 14.2532 14.4631C14.1367 14.3807 14.0004 14.3196 13.8441 14.2798C13.6907 14.2372 13.5217 14.2159 13.337 14.2159C12.9393 14.2159 12.5998 14.3111 12.3185 14.5014C12.0401 14.6918 11.8271 14.9659 11.6793 15.3239C11.5344 15.6818 11.462 16.1136 11.462 16.6193C11.462 17.1307 11.5316 17.5682 11.6708 17.9318C11.81 18.2955 12.0174 18.5739 12.293 18.767C12.5685 18.9602 12.9109 19.0568 13.32 19.0568C13.6808 19.0568 13.9805 19.0043 14.2191 18.8991C14.4606 18.794 14.641 18.6449 14.7603 18.4517C14.8796 18.2585 14.9393 18.0312 14.9393 17.7699L15.3484 17.8125H13.354V16.125H17.2234V17.3352C17.2234 18.1307 17.0543 18.8111 16.7163 19.3764C16.381 19.9389 15.918 20.3707 15.3271 20.6719C14.739 20.9702 14.0643 21.1193 13.3029 21.1193C12.4535 21.1193 11.7077 20.9389 11.0657 20.5781C10.4237 20.2173 9.92223 19.7031 9.56143 19.0355C9.20348 18.3679 9.0245 17.5739 9.0245 16.6534C9.0245 15.9318 9.13388 15.2926 9.35263 14.7358C9.57422 14.179 9.88104 13.7088 10.2731 13.3253C10.6651 12.9389 11.1183 12.6477 11.6325 12.4517C12.1467 12.2528 12.6978 12.1534 13.2859 12.1534C13.8029 12.1534 14.283 12.2273 14.7262 12.375C15.1722 12.5199 15.5657 12.7273 15.9066 12.9972C16.2504 13.2642 16.5273 13.581 16.7376 13.9474C16.9478 14.3139 17.0756 14.7159 17.1211 15.1534H14.7262ZM20.6974 12.2727V21H18.3281V12.2727H20.6974ZM21.8203 21V12.2727H27.9567V14.1818H24.1896V15.6818H27.5817V17.5909H24.1896V21H21.8203ZM28.5895 14.1818V12.2727H36.1747V14.1818H33.5497V21H31.2145V14.1818H28.5895Z"
        fill="#3F3F46"
      />
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" stroke="#F4F4F5" />
    </g>
  )
}
