import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronSelectorVerticalIconProps = IconProps

const ChevronSelectorVerticalIcon = forwardRef<
  SVGSVGElement,
  ChevronSelectorVerticalIconProps
>((props: ChevronSelectorVerticalIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m7 15 5 5 5-5M7 9l5-5 5 5"
      fill="none"
    ></path>
  </Icon>
))

ChevronSelectorVerticalIcon.displayName = 'ChevronSelectorVerticalIcon'
ChevronSelectorVerticalIcon.defaultProps = iconDefaultProps

export type { ChevronSelectorVerticalIconProps }
export { ChevronSelectorVerticalIcon }
