import { addValuesToSet, removeValuesFromSet } from '@vori/utils-set'

import {
  getLabel,
  hasSelectedOptions as hasSelectedOptionsUtil,
} from './reducer-utils'

import { ReducerAction, ReducerState } from './types'

export function getDefaultReducerState({
  defaultLabel = '',
  selectedOptions = new Set(),
}: Partial<ReducerState> = {}): ReducerState {
  const hasSelectedOptions = hasSelectedOptionsUtil(selectedOptions)

  return {
    defaultLabel: defaultLabel || '',
    hasSelectedOptions,
    label: getLabel(selectedOptions, defaultLabel),
    optionAdded: null,
    optionRemoved: null,
    selectedOptions,
  }
}

export function reducer(
  state: ReducerState,
  action: ReducerAction,
): ReducerState {
  switch (action.type) {
    case 'ADD_OPTION': {
      const selectedOptions = addValuesToSet(
        state.selectedOptions,
        action.option,
      )

      const hasSelectedOptions = hasSelectedOptionsUtil(selectedOptions)

      return {
        ...state,
        optionAdded: action.option,
        optionRemoved: null,
        hasSelectedOptions,
        label: getLabel(selectedOptions),
        selectedOptions,
      }
    }

    case 'REMOVE_OPTION': {
      const selectedOptions = removeValuesFromSet(
        state.selectedOptions,
        action.option,
      )

      const hasSelectedOptions = hasSelectedOptionsUtil(selectedOptions)

      return {
        ...state,
        optionAdded: null,
        optionRemoved: action.option,
        hasSelectedOptions,
        label: getLabel(selectedOptions),
        selectedOptions,
      }
    }

    case 'RESET': {
      return getDefaultReducerState({
        ...state,
        ...(action.nextState || { defaultLabel: state.defaultLabel }),
      })
    }

    default: {
      return state
    }
  }
}
