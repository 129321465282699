import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { Divider } from '../Divider'
import { toRem } from '../utils'
import Flex, { FlexProps } from '../Flex'

function styles(): CSSObject {
  return {
    paddingBottom: toRem(16),
    paddingLeft: toRem(24),
    paddingRight: toRem(24),
    paddingTop: toRem(16),
  }
}

const StyledSidePanelFooter = styled(Flex)(styles)

type Props = FlexProps

/**
 * Provides basic styling for rendering the footer of a `<SidePanel>` component.
 *
 * @example
 * function ExampleSidePanel(): React.ReactElement {
 *   const { ref, ...sidePanel } = useSidePanel()
 *
 *   return (
 *     <SidePanel ref={ref} {...sidePanel}>
 *       <SidePanelContent>
 *         <Text>This is the side panel content</Text>
 *       </SidePanelContent>
 *       <SidePanelFooter justifyContent="flex-end">
 *         <Button
 *           onClick={() => {
 *             sidePanel.close()
 *           }}
 *         >
 *           Cancel
 *         </Button>
 *         <Spacer inline size="small" />
 *         <Button variant="tertiary">Save</Button>
 *       </SidePanelFooter>
 *     </SidePanel>
 *   )
 * }
 */
const SidePanelFooter = React.forwardRef<HTMLDivElement, Props>(
  function SidePanelFooter({ children, ...props }: Props, ref): JSX.Element {
    return (
      <>
        <Divider />
        <StyledSidePanelFooter
          {...props}
          data-gourmet-side-panel-content=""
          fullWidth
          ref={ref}
        >
          {children}
        </StyledSidePanelFooter>
      </>
    )
  },
)

SidePanelFooter.displayName = 'SidePanelFooter'
SidePanelFooter.defaultProps = {}

export { SidePanelFooter }
export type { Props as SidePanelFooterProps }
