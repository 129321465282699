import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Luggage01IconProps = IconProps

const Luggage01Icon = forwardRef<SVGSVGElement, Luggage01IconProps>(
  (props: Luggage01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 22v-2m1.5-5V7M16 22v-2m-1.5-5V7M8.8 20h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 17.72 20 16.88 20 15.2V6.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 2 16.88 2 15.2 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 20 7.12 20 8.8 20Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Luggage01Icon.displayName = 'Luggage01Icon'
Luggage01Icon.defaultProps = iconDefaultProps

export type { Luggage01IconProps }
export { Luggage01Icon }
