import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronRightDoubleIconProps = IconProps

const ChevronRightDoubleIcon = forwardRef<
  SVGSVGElement,
  ChevronRightDoubleIconProps
>((props: ChevronRightDoubleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m6 17 5-5-5-5m7 10 5-5-5-5"
      fill="none"
    ></path>
  </Icon>
))

ChevronRightDoubleIcon.displayName = 'ChevronRightDoubleIcon'
ChevronRightDoubleIcon.defaultProps = iconDefaultProps

export type { ChevronRightDoubleIconProps }
export { ChevronRightDoubleIcon }
