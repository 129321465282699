import { useId } from '@reach/auto-id'

import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Text from '../Text'

import { colors, sizing, spacing } from '../tokens'
import toTransitions from '../utils/toTransitions'

const FauxButton = styled.span`
  background-color: ${colors.toggleButton.backgroundColor};
  border: 1px solid ${colors.toggleButton.borderColor};
  border-radius: ${sizing.radius.pill};
  color: ${colors.toggleButton.color};
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: ${spacing.toggle};
  transition: ${toTransitions(
    ['background-color', 'border-color', 'box-shadow', 'color', 'opacity'],
    'ease',
  )};

  ${Text} {
    color: ${colors.toggleButton.color};
  }
`

const Input = styled.input`
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const Label = styled.label<{ $disableFocusRing: boolean; disabled: boolean }>`
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  position: relative;

  :hover ${FauxButton}, :focus ${FauxButton} {
    background-color: ${colors.toggleButton.hover.backgroundColor};
    border-color: ${colors.toggleButton.hover.borderColor};
    color: ${colors.toggleButton.hover.color};
  }

  ${Input} {
    cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  }

  ${Input}:checked + ${FauxButton} {
    background-color: ${colors.radioButton.active.backgroundColor};
    border-color: ${colors.radioButton.active.borderColor};
    color: ${colors.radioButton.active.color};

    ${Text} {
      color: ${colors.radioButton.active.color};
    }
  }

  ${Input}:focus + ${FauxButton} {
    box-shadow: ${({ $disableFocusRing }) =>
      !$disableFocusRing
        ? `0 0 0 ${sizing.focusRing} ${colors.radioButton.focusRing}`
        : 'none'};
  }

  ${Input}:disabled + ${FauxButton} {
    opacity: 0.5;
    pointer-events: none;
  }
`

type ToggleButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
  disableFocusRing?: boolean
}

const defaultProps: Partial<ToggleButtonProps> = {
  className: '',
  disableFocusRing: false,
}

const StyledToggleButton = styled(
  forwardRef<HTMLLabelElement, ToggleButtonProps>(function ToggleButton(
    { children, className, disableFocusRing, ...props }: ToggleButtonProps,
    ref,
  ) {
    const labelId = `toggleButton--${useId()}`
    const inputId = `toggleButton--input--${useId()}`

    return (
      <Label
        $disableFocusRing={disableFocusRing || false}
        className={className}
        disabled={props.disabled || false}
        htmlFor={inputId}
        id={labelId}
        ref={ref}
      >
        <Input {...props} id={inputId} type="checkbox" />
        <FauxButton aria-labelledby={labelId}>{children}</FauxButton>
      </Label>
    )
  }),
)``

StyledToggleButton.displayName = 'ToggleButton'
StyledToggleButton.defaultProps = defaultProps

export type { ToggleButtonProps }
export { defaultProps as ToggleButtonDefaultProps }
export default StyledToggleButton
