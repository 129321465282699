import React from 'react'
import styled from 'styled-components'

import { Text } from '../TextNext'

const StyledText = styled(Text)``

const FormFieldLabel = React.forwardRef<
  HTMLLabelElement,
  React.PropsWithChildren<React.LabelHTMLAttributes<HTMLLabelElement>>
>(function FormFieldLabel(
  {
    children,
    ...props
  }: React.PropsWithChildren<React.LabelHTMLAttributes<HTMLLabelElement>>,
  ref,
) {
  return (
    <StyledText
      fullWidth
      whiteSpace="nowrap"
      {...props}
      data-gourmet-formfield-label=""
      forwardedAs="label"
      ref={ref}
      size="text-sm"
      weight="medium"
    >
      {children}
    </StyledText>
  )
})

export { FormFieldLabel }
