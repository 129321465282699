import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChartCircle03IconProps = IconProps

const BarChartCircle03Icon = forwardRef<
  SVGSVGElement,
  BarChartCircle03IconProps
>((props: BarChartCircle03IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 7v10m4-6v6m4-2v2m6-5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

BarChartCircle03Icon.displayName = 'BarChartCircle03Icon'
BarChartCircle03Icon.defaultProps = iconDefaultProps

export type { BarChartCircle03IconProps }
export { BarChartCircle03Icon }
