import React from 'react'

import { composeEventHandlers } from '../utils'
import { useAlertModalContext } from './AlertModalContext'
import { Button, ButtonProps } from '../ButtonNext'

type Props = ButtonProps

/**
 * Used within a `<AlertModal>`, primarily inside `<AlertModalFooter>`, to close
 * it.
 *
 * @example
 * function ExampleAlertModal(): React.ReactElement {
 *   const { ref, ...modal } = useModal()
 *
 *   return (
 *    <Modal ref={ref} {...modal}>
 *      <ModalContent>
 *        <ModalHeader>
 *          {({ descriptionID, titleID }) => (
 *            <Flex column fullWidth>
 *              <Text id={titleID}>Some title</Text>
 *              <Text id={descriptionID}>Some description</Text>
 *            </Flex>
 *          )}
 *        </ModalHeader>
 *        <Text>This is a modal</Text>
 *        <ModalFooter>
 *          <AlertModalCancelButton>
 *            Cancel
 *          </AlertModalCancelButton>
 *          <AlertModalConfirmButton>
 *            Save
 *          </AlertModalConfirmButton>
 *        </ModalFooter>
 *      </ModalContent>
 *    </Modal>
 *   )
 * }
 */
const AlertModalCancelButton = React.forwardRef<HTMLButtonElement, Props>(
  function AlertModalCancelButton(props: Props, ref): JSX.Element {
    const alertModalContext = useAlertModalContext()

    return (
      <Button
        {...props}
        data-gourmet-alert-modal-cancel-button=""
        ref={ref}
        onClick={composeEventHandlers(props.onClick, () => {
          alertModalContext.close()
        })}
        variant="default"
      />
    )
  },
)

AlertModalCancelButton.displayName = 'AlertModalCancelButton'
AlertModalCancelButton.defaultProps = {}

export { AlertModalCancelButton }
export type { Props as AlertModalCancelButtonProps }
