import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserCheck02IconProps = IconProps

const UserCheck02Icon = forwardRef<SVGSVGElement, UserCheck02IconProps>(
  (props: UserCheck02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 21v-1.2c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C13.72 15 12.88 15 11.2 15H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 17.28 2 18.12 2 19.8V21M16 6l2 2 4-4m-9.5 3.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserCheck02Icon.displayName = 'UserCheck02Icon'
UserCheck02Icon.defaultProps = iconDefaultProps

export type { UserCheck02IconProps }
export { UserCheck02Icon }
