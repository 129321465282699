import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PackageXIconProps = IconProps

const PackageXIcon = forwardRef<SVGSVGElement, PackageXIconProps>(
  (props: PackageXIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.5 7.278 12 12m0 0L3.5 7.278M12 12v9.5m9-9V7.942c0-.343 0-.514-.05-.667a1 1 0 0 0-.215-.364c-.109-.119-.258-.202-.558-.368l-7.4-4.111c-.284-.158-.425-.237-.575-.267a1 1 0 0 0-.403 0c-.15.03-.292.11-.576.267l-7.4 4.11c-.3.167-.45.25-.558.369a1 1 0 0 0-.215.364C3 7.428 3 7.599 3 7.942v8.117c0 .342 0 .514.05.666a1 1 0 0 0 .215.364c.109.119.258.202.558.368l7.4 4.111c.284.158.425.237.576.268.133.027.27.027.402 0 .15-.031.292-.11.576-.268l.223-.123M7.5 4.5l9 5m0 6.5 5 5m0-5-5 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

PackageXIcon.displayName = 'PackageXIcon'
PackageXIcon.defaultProps = iconDefaultProps

export type { PackageXIconProps }
export { PackageXIcon }
