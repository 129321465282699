import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BookmarkAddIconProps = IconProps

const BookmarkAddIcon = forwardRef<SVGSVGElement, BookmarkAddIconProps>(
  (props: BookmarkAddIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13V7m-3 3h6m4 11V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C16.72 3 15.88 3 14.2 3H9.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C5 5.28 5 6.12 5 7.8V21l7-4 7 4Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

BookmarkAddIcon.displayName = 'BookmarkAddIcon'
BookmarkAddIcon.defaultProps = iconDefaultProps

export type { BookmarkAddIconProps }
export { BookmarkAddIcon }
