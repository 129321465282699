import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChart08IconProps = IconProps

const BarChart08Icon = forwardRef<SVGSVGElement, BarChart08IconProps>(
  (props: BarChart08IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21H6.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3 19.48 3 18.92 3 17.8V3m4 11.5v3m4.5-6v6m4.5-9v9m4.5-12v12"
        fill="none"
      ></path>
    </Icon>
  ),
)

BarChart08Icon.displayName = 'BarChart08Icon'
BarChart08Icon.defaultProps = iconDefaultProps

export type { BarChart08IconProps }
export { BarChart08Icon }
