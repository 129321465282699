import * as Sentry from '@sentry/browser'

export function getSessionStorageRecord<T extends Record<string, unknown>>(
  key: string,
): T | null {
  const value = sessionStorage.getItem(key) || null

  try {
    return value ? JSON.parse(value) : null
  } catch (err) {
    Sentry.captureException(err, { extra: { key } })
  }

  return null
}

export function setSessionStorageRecord<T extends Record<string, unknown>>(
  key: string,
  value: T,
): void {
  try {
    sessionStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    Sentry.captureException(err, { extra: { key, value } })
  }
}

export function updateSessionStorageRecord<T extends Record<string, unknown>>(
  key: string,
  value: T,
): void {
  const prevValue = getSessionStorageRecord<T>(key)
  setSessionStorageRecord(key, prevValue ? { ...prevValue, ...value } : value)
}
