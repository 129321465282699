import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Snowflake02IconProps = IconProps

const Snowflake02Icon = forwardRef<SVGSVGElement, Snowflake02IconProps>(
  (props: Snowflake02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8v8m0-8V2m0 6L7 3m5 5 5-5m-5 13v6m0-6-5 5m5-5 5 5m-1-9H8m8 0h6m-6 0 5-5m-5 5 5 5M8 12H2m6 0L3 7m5 5-5 5"
        fill="none"
      ></path>
    </Icon>
  ),
)

Snowflake02Icon.displayName = 'Snowflake02Icon'
Snowflake02Icon.defaultProps = iconDefaultProps

export type { Snowflake02IconProps }
export { Snowflake02Icon }
