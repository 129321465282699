import { useStableCallback } from '@vori/react-hooks'
import React from 'react'

type Options = {
  onClose?: () => void
  onOpen?: () => void
  onToggle?: () => void
  openByDefault?: boolean
}

type HookReturn = {
  close: () => void
  isOpen: boolean
  open: () => void
  toggle: () => void
}

/**
 * Provides state management and helpers for the visibility of components.
 */
const useOpenState = (options?: Options): HookReturn => {
  const [isOpen, setOpenState] = React.useState<boolean>(
    options?.openByDefault || false,
  )

  const stableOnClose = useStableCallback(options?.onClose)
  const stableOnOpen = useStableCallback(options?.onOpen)
  const stableOnToggle = useStableCallback(options?.onToggle)

  const open = React.useCallback(() => {
    setOpenState(true)
    stableOnOpen()
  }, [stableOnOpen])

  const close = React.useCallback(() => {
    setOpenState(false)
    stableOnClose()
  }, [stableOnClose])

  const toggle = React.useCallback(() => {
    const wasOpen = isOpen

    setOpenState((prevState) => !prevState)
    stableOnToggle()

    if (wasOpen) {
      stableOnClose()
    } else {
      stableOnOpen()
    }
  }, [isOpen, stableOnClose, stableOnOpen, stableOnToggle])

  return React.useMemo(
    () => ({ close, isOpen, open, toggle }),
    [close, isOpen, open, toggle],
  )
}

export type {
  Options as OpenStateHookOptions,
  HookReturn as OpenStateHookReturn,
}

export default useOpenState
