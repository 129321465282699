import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Webcam01IconProps = IconProps

const Webcam01Icon = forwardRef<SVGSVGElement, Webcam01IconProps>(
  (props: Webcam01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 0v4m0 0H7m5 0h5m-2-12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Webcam01Icon.displayName = 'Webcam01Icon'
Webcam01Icon.defaultProps = iconDefaultProps

export type { Webcam01IconProps }
export { Webcam01Icon }
