import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SearchSmIconProps = IconProps

const SearchSmIcon = forwardRef<SVGSVGElement, SearchSmIconProps>(
  (props: SearchSmIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m21 21-6-6m2-5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

SearchSmIcon.displayName = 'SearchSmIcon'
SearchSmIcon.defaultProps = iconDefaultProps

export type { SearchSmIconProps }
export { SearchSmIcon }
