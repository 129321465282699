import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type XCloseIconProps = IconProps

const XCloseIcon = forwardRef<SVGSVGElement, XCloseIconProps>(
  (props: XCloseIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 6 6 18M6 6l12 12"
        fill="none"
      ></path>
    </Icon>
  ),
)

XCloseIcon.displayName = 'XCloseIcon'
XCloseIcon.defaultProps = iconDefaultProps

export type { XCloseIconProps }
export { XCloseIcon }
