import { remToEm, remToPx } from '../utils'
import { TokensWithMetadataMap } from '../types'

export const TOKEN_SIZE_CONTAINER_BASE_UNIT = 16 as const

export const tokens = {
  /**
   * The size for a container element based on a base unit multiplier of 25 —
   * Equivalent to 400px.
   */
  'size.container-25': '25rem',
  /**
   * The size for a container element based on a base unit multiplier of 30 —
   * Equivalent to 480px.
   */
  'size.container-30': '30rem',
  /**
   * The size for a container element based on a base unit multiplier of 35 —
   * Equivalent to 560px.
   */
  'size.container-35': '35rem',
  /**
   * The size for a container element based on a base unit multiplier of 40 —
   * Equivalent to 640px.
   */
  'size.container-40': '40rem',
  /**
   * The size for a container element based on a base unit multiplier of 48 —
   * Equivalent to 768px.
   */
  'size.container-48': '48rem',
  /**
   * The size for a container element based on a base unit multiplier of 64 —
   * Equivalent to 1024px.
   */
  'size.container-64': '64rem',
  /**
   * The size for a container element based on a base unit multiplier of 80 —
   * Equivalent to 1280px.
   */
  'size.container-80': '80rem',
  /**
   * The size for an icon based on a base unit multiplier of 0.75 —
   * Equivalent to 12px.
   */
  'size.icon-xs': '0.75rem',
  /**
   * The size for an icon based on a base unit multiplier of 1 —
   * Equivalent to 16px.
   */
  'size.icon-sm': '1rem',
  /**
   * The size for an icon based on a base unit multiplier of 1.25 —
   * Equivalent to 20px.
   */
  'size.icon-md': '1.25rem',
  /**
   * The size for an icon based on a base unit multiplier of 1.5 —
   * Equivalent to 24px.
   */
  'size.icon-lg': '1.5rem',
  /**
   * The size for an icon based on a base unit multiplier of 1.75 —
   * Equivalent to 28px.
   */
  'size.icon-xl': '1.75rem',
  /**
   * The size for an icon based on a base unit multiplier of 2 —
   * Equivalent to 32px.
   */
  'size.icon-xxl': '2rem',
  /**
   * The size for an avatar based on a base unit multiplier of 1.5 —
   * Equivalent to 24px.
   */
  'size.avatar-xs': '1.5rem',
  /**
   * The size for an avatar based on a base unit multiplier of 2 —
   * Equivalent to 32px.
   */
  'size.avatar-sm': '2rem',
  /**
   * The size for an avatar based on a base unit multiplier of 2.5 —
   * Equivalent to 40px.
   */
  'size.avatar-md': '2.5rem',
  /**
   * The size for an avatar based on a base unit multiplier of 3 —
   * Equivalent to 48px.
   */
  'size.avatar-lg': '3rem',
  /**
   * The size for an avatar based on a base unit multiplier of 3.5 —
   * Equivalent to 56px.
   */
  'size.avatar-xl': '3.5rem',
  /**
   * The size for an avatar based on a base unit multiplier of 4 —
   * Equivalent to 64px.
   */
  'size.avatar-xxl': '4rem',
} as const

export const tokensWithMetadata: TokensWithMetadataMap<'size', typeof tokens> =
  {
    'size.container-25': {
      description:
        'The size for a container element based on a base unit multiplier of 25 — Equivalent to 400px.',
      introducedIn: 'v0.4.0',
      name: 'size.container-25',
      type: 'size',
      value: tokens['size.container-25'],
      metadata: {
        baseUnitMultiplier: 25,
        em: remToEm(tokens['size.container-25']),
        px: remToPx(
          tokens['size.container-25'],
          TOKEN_SIZE_CONTAINER_BASE_UNIT,
        ),
        rem: tokens['size.container-25'],
      },
    },
    'size.container-30': {
      description:
        'The size for a container element based on a base unit multiplier of 30 — Equivalent to 480px.',
      introducedIn: 'v0.4.3',
      name: 'size.container-30',
      type: 'size',
      value: tokens['size.container-30'],
      metadata: {
        baseUnitMultiplier: 30,
        em: remToEm(tokens['size.container-30']),
        px: remToPx(
          tokens['size.container-30'],
          TOKEN_SIZE_CONTAINER_BASE_UNIT,
        ),
        rem: tokens['size.container-30'],
      },
    },
    'size.container-35': {
      description:
        'The size for a container element based on a base unit multiplier of 35 — Equivalent to 560px.',
      introducedIn: 'v0.4.3',
      name: 'size.container-35',
      type: 'size',
      value: tokens['size.container-35'],
      metadata: {
        baseUnitMultiplier: 35,
        em: remToEm(tokens['size.container-35']),
        px: remToPx(
          tokens['size.container-35'],
          TOKEN_SIZE_CONTAINER_BASE_UNIT,
        ),
        rem: tokens['size.container-35'],
      },
    },
    'size.container-40': {
      description:
        'The size for a container element based on a base unit multiplier of 40 — Equivalent to 640px.',
      introducedIn: 'v0.0.1',
      name: 'size.container-40',
      type: 'size',
      value: tokens['size.container-40'],
      metadata: {
        baseUnitMultiplier: 40,
        em: remToEm(tokens['size.container-40']),
        px: remToPx(
          tokens['size.container-40'],
          TOKEN_SIZE_CONTAINER_BASE_UNIT,
        ),
        rem: tokens['size.container-40'],
      },
    },
    'size.container-48': {
      description:
        'The size for a container element based on a base unit multiplier of 48 — Equivalent to 768px.',
      introducedIn: 'v0.0.1',
      name: 'size.container-48',
      type: 'size',
      value: tokens['size.container-48'],
      metadata: {
        baseUnitMultiplier: 48,
        em: remToEm(tokens['size.container-48']),
        px: remToPx(
          tokens['size.container-48'],
          TOKEN_SIZE_CONTAINER_BASE_UNIT,
        ),
        rem: tokens['size.container-48'],
      },
    },
    'size.container-64': {
      description:
        'The size for a container element based on a base unit multiplier of 64 — Equivalent to 1024px.',
      introducedIn: 'v0.0.1',
      name: 'size.container-64',
      type: 'size',
      value: tokens['size.container-64'],
      metadata: {
        baseUnitMultiplier: 64,
        em: remToEm(tokens['size.container-64']),
        px: remToPx(
          tokens['size.container-64'],
          TOKEN_SIZE_CONTAINER_BASE_UNIT,
        ),
        rem: tokens['size.container-64'],
      },
    },
    'size.container-80': {
      description:
        'The size for a container element based on a base unit multiplier of 80 — Equivalent to 1280px.',
      introducedIn: 'v0.0.1',
      name: 'size.container-80',
      type: 'size',
      value: tokens['size.container-80'],
      metadata: {
        baseUnitMultiplier: 80,
        em: remToEm(tokens['size.container-80']),
        px: remToPx(
          tokens['size.container-80'],
          TOKEN_SIZE_CONTAINER_BASE_UNIT,
        ),
        rem: tokens['size.container-80'],
      },
    },
    'size.icon-xs': {
      description:
        'The size for an icon based on a base unit multiplier of 0.75 — Equivalent to 12px.',
      introducedIn: 'v0.0.1',
      name: 'size.icon-xs',
      type: 'size',
      value: tokens['size.icon-xs'],
      metadata: {
        baseUnitMultiplier: 0.75,
        em: remToEm(tokens['size.icon-xs']),
        px: remToPx(tokens['size.icon-xs'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.icon-xs'],
      },
    },
    'size.icon-sm': {
      description:
        'The size for an icon based on a base unit multiplier of 1 — Equivalent to 16px.',
      introducedIn: 'v0.0.1',
      name: 'size.icon-sm',
      type: 'size',
      value: tokens['size.icon-sm'],
      metadata: {
        baseUnitMultiplier: 1,
        em: remToEm(tokens['size.icon-sm']),
        px: remToPx(tokens['size.icon-sm'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.icon-sm'],
      },
    },
    'size.icon-md': {
      description:
        'The size for an icon based on a base unit multiplier of 1.25 — Equivalent to 20px.',
      introducedIn: 'v0.0.1',
      name: 'size.icon-md',
      type: 'size',
      value: tokens['size.icon-md'],
      metadata: {
        baseUnitMultiplier: 1.25,
        em: remToEm(tokens['size.icon-md']),
        px: remToPx(tokens['size.icon-md'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.icon-md'],
      },
    },
    'size.icon-lg': {
      description:
        'The size for an icon based on a base unit multiplier of 1.5 — Equivalent to 24px.',
      introducedIn: 'v0.0.1',
      name: 'size.icon-lg',
      type: 'size',
      value: tokens['size.icon-lg'],
      metadata: {
        baseUnitMultiplier: 1.5,
        em: remToEm(tokens['size.icon-lg']),
        px: remToPx(tokens['size.icon-lg'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.icon-lg'],
      },
    },
    'size.icon-xl': {
      description:
        'The size for an icon based on a base unit multiplier of 1.75 — Equivalent to 28px.',
      introducedIn: 'v0.0.1',
      name: 'size.icon-xl',
      type: 'size',
      value: tokens['size.icon-xl'],
      metadata: {
        baseUnitMultiplier: 1.75,
        em: remToEm(tokens['size.icon-xl']),
        px: remToPx(tokens['size.icon-xl'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.icon-xl'],
      },
    },
    'size.icon-xxl': {
      description:
        'The size for an icon based on a base unit multiplier of 2 — Equivalent to 32px.',
      introducedIn: 'v0.0.1',
      name: 'size.icon-xxl',
      type: 'size',
      value: tokens['size.icon-xxl'],
      metadata: {
        baseUnitMultiplier: 2,
        em: remToEm(tokens['size.icon-xxl']),
        px: remToPx(tokens['size.icon-xxl'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.icon-xxl'],
      },
    },
    'size.avatar-xs': {
      description:
        'The size for an avatar based on a base unit multiplier of 1.5 — Equivalent to 24px.',
      introducedIn: 'v0.3.0',
      name: 'size.avatar-xs',
      type: 'size',
      value: tokens['size.avatar-xs'],
      metadata: {
        baseUnitMultiplier: 1.5,
        em: remToEm(tokens['size.avatar-xs']),
        px: remToPx(tokens['size.avatar-xs'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.avatar-xs'],
      },
    },
    'size.avatar-sm': {
      description:
        'The size for an avatar based on a base unit multiplier of 2 — Equivalent to 32px.',
      introducedIn: 'v0.3.0',
      name: 'size.avatar-sm',
      type: 'size',
      value: tokens['size.avatar-sm'],
      metadata: {
        baseUnitMultiplier: 2,
        em: remToEm(tokens['size.avatar-sm']),
        px: remToPx(tokens['size.avatar-sm'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.avatar-sm'],
      },
    },
    'size.avatar-md': {
      description:
        'The size for an avatar based on a base unit multiplier of 2.5 — Equivalent to 40px.',
      introducedIn: 'v0.3.0',
      name: 'size.avatar-md',
      type: 'size',
      value: tokens['size.avatar-md'],
      metadata: {
        baseUnitMultiplier: 2.5,
        em: remToEm(tokens['size.avatar-md']),
        px: remToPx(tokens['size.avatar-md'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.avatar-md'],
      },
    },
    'size.avatar-lg': {
      description:
        'The size for an avatar based on a base unit multiplier of 3 — Equivalent to 48px.',
      introducedIn: 'v0.3.0',
      name: 'size.avatar-lg',
      type: 'size',
      value: tokens['size.avatar-lg'],
      metadata: {
        baseUnitMultiplier: 3,
        em: remToEm(tokens['size.avatar-lg']),
        px: remToPx(tokens['size.avatar-lg'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.avatar-lg'],
      },
    },
    'size.avatar-xl': {
      description:
        'The size for an avatar based on a base unit multiplier of 3.5 — Equivalent to 56px.',
      introducedIn: 'v0.3.0',
      name: 'size.avatar-xl',
      type: 'size',
      value: tokens['size.avatar-xl'],
      metadata: {
        baseUnitMultiplier: 3.5,
        em: remToEm(tokens['size.avatar-xl']),
        px: remToPx(tokens['size.avatar-xl'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.avatar-xl'],
      },
    },
    'size.avatar-xxl': {
      description:
        'The size for an avatar based on a base unit multiplier of 4 — Equivalent to 64px.',
      introducedIn: 'v0.3.0',
      name: 'size.avatar-xxl',
      type: 'size',
      value: tokens['size.avatar-xxl'],
      metadata: {
        baseUnitMultiplier: 4,
        em: remToEm(tokens['size.avatar-xxl']),
        px: remToPx(tokens['size.avatar-xxl'], TOKEN_SIZE_CONTAINER_BASE_UNIT),
        rem: tokens['size.avatar-xxl'],
      },
    },
  } as const

export type TokenFoundationSize = keyof typeof tokens

export type TokenFoundationSizeContainer = Extract<
  TokenFoundationSize,
  | 'size.container-25'
  | 'size.container-30'
  | 'size.container-35'
  | 'size.container-40'
  | 'size.container-48'
  | 'size.container-64'
  | 'size.container-80'
>

export type TokenFoundationSizeIcon = Extract<
  TokenFoundationSize,
  | 'size.icon-xs'
  | 'size.icon-sm'
  | 'size.icon-md'
  | 'size.icon-lg'
  | 'size.icon-xl'
  | 'size.icon-xxl'
>

export type TokenFoundationSizeAvatar = Extract<
  TokenFoundationSize,
  | 'size.avatar-xs'
  | 'size.avatar-sm'
  | 'size.avatar-md'
  | 'size.avatar-lg'
  | 'size.avatar-xl'
  | 'size.avatar-xxl'
>
