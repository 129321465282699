import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PieChart01IconProps = IconProps

const PieChart01Icon = forwardRef<SVGSVGElement, PieChart01IconProps>(
  (props: PieChart01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.21 15.89A10 10 0 1 1 8 2.83m13.24 5.343a10 10 0 0 1 .728 3.028c.021.257.031.385-.02.5a.525.525 0 0 1-.22.239c-.11.06-.25.06-.528.06h-8.4c-.28 0-.42 0-.527-.055a.5.5 0 0 1-.218-.218C12 11.62 12 11.48 12 11.2V2.8c0-.278 0-.417.06-.528a.525.525 0 0 1 .239-.22c.115-.05.244-.04.5-.02a10 10 0 0 1 8.44 6.141Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

PieChart01Icon.displayName = 'PieChart01Icon'
PieChart01Icon.defaultProps = iconDefaultProps

export type { PieChart01IconProps }
export { PieChart01Icon }
