import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ChevronLeftIconProps = IconProps

const ChevronLeftIcon = forwardRef<SVGSVGElement, ChevronLeftIconProps>(
  (props: ChevronLeftIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m15 18-6-6 6-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

ChevronLeftIcon.displayName = 'ChevronLeftIcon'
ChevronLeftIcon.defaultProps = iconDefaultProps

export type { ChevronLeftIconProps }
export { ChevronLeftIcon }
