import { useClientId } from '@vori/react-hooks'

import { useDialog, DialogHookOptions, DialogHookReturn } from '../Dialog'

export type ModalHookOptions = DialogHookOptions

export function useModal(options?: ModalHookOptions): DialogHookReturn {
  const [modalID] = useClientId('gourmet-modal')

  return useDialog({
    ...options,
    asModal: true,
    id: options?.id || modalID,
  })
}
