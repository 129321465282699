import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CalendarPlus02IconProps = IconProps

const CalendarPlus02Icon = forwardRef<SVGSVGElement, CalendarPlus02IconProps>(
  (props: CalendarPlus02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 11.5V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 4 17.88 4 16.2 4H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 6.28 3 7.12 3 8.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 22 6.12 22 7.8 22h4.7M21 10H3m13-8v4M8 2v4m10 15v-6m-3 3h6"
        fill="none"
      ></path>
    </Icon>
  ),
)

CalendarPlus02Icon.displayName = 'CalendarPlus02Icon'
CalendarPlus02Icon.defaultProps = iconDefaultProps

export type { CalendarPlus02IconProps }
export { CalendarPlus02Icon }
