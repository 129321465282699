import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyYenIconProps = IconProps

const CurrencyYenIcon = forwardRef<SVGSVGElement, CurrencyYenIconProps>(
  (props: CurrencyYenIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20.5v-9m0 0 6.5-8m-6.5 8-6.5-8m12.5 8H6m11 4H7"
        fill="none"
      ></path>
    </Icon>
  ),
)

CurrencyYenIcon.displayName = 'CurrencyYenIcon'
CurrencyYenIcon.defaultProps = iconDefaultProps

export type { CurrencyYenIconProps }
export { CurrencyYenIcon }
