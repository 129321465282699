import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MinusCircleIconProps = IconProps

const MinusCircleIcon = forwardRef<SVGSVGElement, MinusCircleIconProps>(
  (props: MinusCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 12h8m6 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MinusCircleIcon.displayName = 'MinusCircleIcon'
MinusCircleIcon.defaultProps = iconDefaultProps

export type { MinusCircleIconProps }
export { MinusCircleIcon }
