import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type HelpCircleIconProps = IconProps

const HelpCircleIcon = forwardRef<SVGSVGElement, HelpCircleIconProps>(
  (props: HelpCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3m.08 4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

HelpCircleIcon.displayName = 'HelpCircleIcon'
HelpCircleIcon.defaultProps = iconDefaultProps

export type { HelpCircleIconProps }
export { HelpCircleIcon }
