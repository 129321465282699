import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Bold02IconProps = IconProps

const Bold02Icon = forwardRef<SVGSVGElement, Bold02IconProps>(
  (props: Bold02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 4v16M9.5 4h6a4 4 0 0 1 0 8h-6 7a4 4 0 0 1 0 8h-7m0-16v16m0-16H4m5.5 16H4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Bold02Icon.displayName = 'Bold02Icon'
Bold02Icon.defaultProps = iconDefaultProps

export type { Bold02IconProps }
export { Bold02Icon }
