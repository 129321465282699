import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Cryptocurrency01IconProps = IconProps

const Cryptocurrency01Icon = forwardRef<
  SVGSVGElement,
  Cryptocurrency01IconProps
>((props: Cryptocurrency01IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.878 20.09a10 10 0 0 1-11.756 0M16.384 3.012a10 10 0 0 1 5.519 10.38m-19.806 0a10 10 0 0 1 5.52-10.38M17.5 12a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
      fill="none"
    ></path>
  </Icon>
))

Cryptocurrency01Icon.displayName = 'Cryptocurrency01Icon'
Cryptocurrency01Icon.defaultProps = iconDefaultProps

export type { Cryptocurrency01IconProps }
export { Cryptocurrency01Icon }
