import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type IntersectCircleIconProps = IconProps

const IntersectCircleIcon = forwardRef<SVGSVGElement, IntersectCircleIconProps>(
  (props: IntersectCircleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 16A7 7 0 1 0 9 2a7 7 0 0 0 0 14Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 22a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

IntersectCircleIcon.displayName = 'IntersectCircleIcon'
IntersectCircleIcon.defaultProps = iconDefaultProps

export type { IntersectCircleIconProps }
export { IntersectCircleIcon }
