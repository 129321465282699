import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CheckCircleBrokenIconProps = IconProps

const CheckCircleBrokenIcon = forwardRef<
  SVGSVGElement,
  CheckCircleBrokenIconProps
>((props: CheckCircleBrokenIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 11.086v.92a10 10 0 1 1-5.93-9.14M22 4 12 14.01l-3-3"
      fill="none"
    ></path>
  </Icon>
))

CheckCircleBrokenIcon.displayName = 'CheckCircleBrokenIcon'
CheckCircleBrokenIcon.defaultProps = iconDefaultProps

export type { CheckCircleBrokenIconProps }
export { CheckCircleBrokenIcon }
