import React from 'react'

import { CheckboxInput } from '../../CheckboxInput'
import { Flex } from '../../FlexNext'

import { MultiSelectOptionContentProps } from './utils/types'

function MultiSelectOptionContent({
  icon,
  isDisabled,
  isSelected,
  labelContent,
}: MultiSelectOptionContentProps): JSX.Element {
  return (
    <Flex centerY fullWidth justifyContent="space-between">
      <Flex centerY fullWidth>
        <CheckboxInput
          label={
            icon ? (
              <Flex centerY fullWidth gap="space.025">
                <Flex shrink={0}>{icon}</Flex>
                {labelContent}
              </Flex>
            ) : (
              labelContent
            )
          }
          checked={isSelected}
          disabled={isDisabled}
          readOnly
          fullWidth
        />
      </Flex>
    </Flex>
  )
}

export default MultiSelectOptionContent
