import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import React from 'react'

function ToastProvider({
  children,
  ...props
}: SnackbarProviderProps): JSX.Element {
  return <SnackbarProvider {...props}>{children}</SnackbarProvider>
}

export type { SnackbarProviderProps as ToastProviderProps }
export default ToastProvider
