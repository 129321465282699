import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Text from '../Text'

import { base, colors, Size, sizing, spacing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type LabelSize = Extract<Size, 'small' | 'base'>
type LabelColorVariant = keyof typeof colors.label.backgroundColor

type LabelProps = Omit<React.HTMLAttributes<HTMLSpanElement>, 'size'> & {
  inverted?: boolean
  noBackground?: boolean
  noPadding?: boolean
  outlined?: boolean
  pill?: boolean
  size?: LabelSize
  /**
   * @deprecated This is now the default style so there is no need to use this
   * prop moving forward.
   */
  subtle?: boolean
  variant?: LabelColorVariant
}

const defaultProps: Partial<LabelProps> = {
  className: '',
  inverted: false,
  noBackground: false,
  noPadding: false,
  outlined: false,
  pill: false,
  size: 'base',
  subtle: false,
  variant: 'default',
}

const StyledLabel = styled(
  forwardRef<HTMLSpanElement, LabelProps>(function Label(
    {
      inverted,
      noBackground,
      noPadding,
      outlined,
      pill,
      size,
      subtle,
      variant,
      ...props
    }: LabelProps,
    ref,
  ) {
    return (
      <Text
        {...props}
        size={size === 'small' ? 'caption' : 'display'}
        ref={ref}
      />
    )
  }),
)<LabelProps>`
  ${({ size = defaultProps.size }) =>
    size === 'small' ? 'line-height: 1.2;' : ''}
  background-color: ${({
    inverted,
    noBackground,
    outlined,
    variant = defaultProps.variant,
  }): string =>
    noBackground
      ? 'transparent'
      : outlined
        ? colors.label.outlined[inverted ? 'color' : 'backgroundColor'][
            variant as LabelColorVariant
          ]
        : colors.label[inverted ? 'color' : 'backgroundColor'][
            variant as LabelColorVariant
          ]};
  border-color: ${({
    inverted,
    noBackground,
    outlined,
    variant = defaultProps.variant,
  }): string =>
    noBackground
      ? 'transparent'
      : outlined
        ? colors.label.outlined[inverted ? 'color' : 'borderColor'][
            variant as LabelColorVariant
          ]
        : colors.label[inverted ? 'color' : 'borderColor'][
            variant as LabelColorVariant
          ]};
  border-style: solid;
  border-width: 1px;
  border-radius: ${({ subtle, pill }) =>
    pill
      ? sizing.radius.pill
      : subtle
        ? sizing.radius.small
        : sizing.radius.small};
  color: ${({ inverted, outlined, variant = defaultProps.variant }): string =>
    outlined
      ? colors.label.outlined[inverted ? 'backgroundColor' : 'color'][
          variant as LabelColorVariant
        ]
      : colors.label[inverted ? 'backgroundColor' : 'color'][
          variant as LabelColorVariant
        ]};
  align-items: center;
  display: inline-flex;
  height: ${({ size = defaultProps.size }) => sizing.label[size as LabelSize]};
  padding: ${({ noPadding, pill }): string =>
    noPadding ? '0px' : pill ? base.spacing.tiny : `0px ${spacing.label}`};
  transition: ${toTransitions(['background-color'], 'ease')};
  width: max-content;
`

StyledLabel.displayName = 'Label'
StyledLabel.defaultProps = defaultProps

export type { LabelProps, LabelColorVariant, LabelSize }
export { defaultProps as LabelDefaultProps }
export default StyledLabel
