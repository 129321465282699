import React from 'react'
import { Menu } from '@reach/menu-button'

import { PopupContent } from './PopupContent'
import { PopupTrigger } from './PopupTrigger'

interface Props {
  children: React.ReactNode
}

/**
 * A generic popup component based on Reach UI's `<Menu>` component.
 *
 * @example
 * <Popup>
 *   <PopupTrigger>Trigger</PopupTrigger>
 *   <PopupContent column>
 *     <Text>Hello</Text>
 *     <Spacer />
 *     <PopupClose>
 *       <Button>Close</Button>
 *     </PopupClose>
 *   </PopupContent>
 * </Popup>
 *
 * @see {@link https://reach.tech/menu-button}
 */
function Popup({ children }: Props): JSX.Element {
  if (
    children == null ||
    React.Children.count(children) !== 2 ||
    React.Children.toArray(children)
      .map(
        (child) =>
          React.isValidElement(child) &&
          (child.type === PopupContent || child.type === PopupTrigger),
      )
      .every((condition) => !condition)
  ) {
    throw new Error(
      'The <Popup> component requires its children to be composed of <PopupTrigger> and <PopupContent> components.',
    )
  }

  return <Menu>{children}</Menu>
}

Popup.displayName = 'Popup'
Popup.defaultProps = {}

export { Popup }
export type { Props as PopupProps }
