/* tslint:disable */
/* eslint-disable */
/**
 * Vori API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PromotionStatus = {
    Draft: 'Draft',
    Pending: 'Pending',
    Active: 'Active',
    Completed: 'Completed',
    Canceled: 'Canceled',
    Deleted: 'Deleted'
} as const;
export type PromotionStatus = typeof PromotionStatus[keyof typeof PromotionStatus];


export function PromotionStatusFromJSON(json: any): PromotionStatus {
    return PromotionStatusFromJSONTyped(json, false);
}

export function PromotionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromotionStatus {
    return json as PromotionStatus;
}

export function PromotionStatusToJSON(value?: PromotionStatus | null): any {
    return value as any;
}

