import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleRightIconProps = IconProps

const ArrowCircleRightIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleRightIconProps
>((props: ArrowCircleRightIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m12 16 4-4m0 0-4-4m4 4H8m14 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleRightIcon.displayName = 'ArrowCircleRightIcon'
ArrowCircleRightIcon.defaultProps = iconDefaultProps

export type { ArrowCircleRightIconProps }
export { ArrowCircleRightIcon }
