import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Scissors02IconProps = IconProps

const Scissors02Icon = forwardRef<SVGSVGElement, Scissors02IconProps>(
  (props: Scissors02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.5 8.6 21 17m0-10L4.5 15.4M6 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 12a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Scissors02Icon.displayName = 'Scissors02Icon'
Scissors02Icon.defaultProps = iconDefaultProps

export type { Scissors02IconProps }
export { Scissors02Icon }
