import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { XCloseIcon } from '@vori/gourmet-icons'

import {
  Clickable,
  Editable,
  EditableProps,
  Flex,
  Input,
  Label,
  sizing,
  Spacer,
  foundation,
} from '@vori/gourmet-components'

import { addValuesToSetInState, removeValuesFromSetInState } from '../utils/set'

import { usePreviousValue } from '@vori/dashboard-hooks/usePreviousValue'

const Container = styled(Input)`
  flex-wrap: wrap;
  max-height: unset;
  min-height: ${sizing.input.base};
  padding-bottom: 0;
  padding-top: 0;

  ${Editable} {
    border-radius: 0;
  }
`

const Value = styled(Label)`
  margin-bottom: calc(${foundation.spacing.tiny} / 1.15);
  margin-right: ${foundation.spacing.tiny};
  margin-top: calc(${foundation.spacing.tiny} / 1.15);
`

const RemoveButton = styled(Clickable)`
  line-height: 1;
  min-height: unset;
  min-width: unset;
`

type Props = EditableProps & {
  invalidEmails?: Set<string>
  onChangeValue?: (values: Set<string>) => void
}

function EmailsInput({
  defaultValue,
  fullWidth,
  invalidEmails,
  leftIcon,
  onChange,
  onChangeValue,
  onKeyDown,
  rightIcon,
  variant,
  ...props
}: Props): JSX.Element {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [values, setValues] = useState<Set<string>>(
    new Set(
      defaultValue
        ? Array.isArray(defaultValue)
          ? defaultValue
          : defaultValue.toString().split(',')
        : [],
    ),
  )

  const prevValues = usePreviousValue(values)
  const [inputValue, setInputValue] = useState<string>('')

  const addValue = useCallback((value: string) => {
    setValues(addValuesToSetInState(value))
    setInputValue('')
  }, [])

  const removeValue = useCallback((value: string) => {
    setValues(removeValuesFromSetInState(value))
  }, [])

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value.trim())

      if (onChange != null) {
        onChange(event)
      }
    },
    [onChange],
  )

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.trim()

      if (value) {
        addValue(value)
      }
    },
    [addValue],
  )

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.trim()

      switch (event.nativeEvent.code) {
        case 'Comma':
        case 'Enter': {
          event.preventDefault()

          if (value) {
            addValue(value)
          }

          break
        }

        case 'Backspace': {
          if (!value) {
            event.preventDefault()

            setValues(
              (previousValues) =>
                new Set(Array.from(previousValues).slice(0, -1)),
            )
          }

          break
        }

        default: {
          break
        }
      }

      if (onKeyDown != null) {
        onKeyDown(event)
      }
    },
    [addValue, onKeyDown],
  )

  useEffect(() => {
    if (onChangeValue != null && prevValues != null && prevValues !== values) {
      onChangeValue(values)
    }
  }, [onChangeValue, prevValues, values])

  return (
    <Container
      fullWidth={fullWidth}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      tabIndex={-1}
      variant={variant}
      onFocus={() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }}
    >
      {Array.from(values.values()).map((value) => (
        <Value
          key={`email-value-${value}`}
          size="small"
          pill
          variant={invalidEmails?.has(value) ? 'negative' : 'default'}
        >
          <Flex centerY fullWidth>
            {value}
            <Spacer inline size="tiny" />
            <RemoveButton
              iconOnly
              leftIcon={<XCloseIcon />}
              noPadding
              onMouseDown={() => {
                removeValue(value)
              }}
              rounded
              size="small"
              variant={invalidEmails?.has(value) ? 'negative' : undefined}
            />
          </Flex>
        </Value>
      ))}
      <Flex centerY inline flex={1}>
        <Editable
          {...props}
          disableFocusRing
          fullWidth
          noPadding
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          ref={inputRef}
          value={inputValue}
        />
      </Flex>
    </Container>
  )
}

export { EmailsInput }
