import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CodeSnippet01IconProps = IconProps

const CodeSnippet01Icon = forwardRef<SVGSVGElement, CodeSnippet01IconProps>(
  (props: CodeSnippet01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16 18 6-6-6-6M8 6l-6 6 6 6"
        fill="none"
      ></path>
    </Icon>
  ),
)

CodeSnippet01Icon.displayName = 'CodeSnippet01Icon'
CodeSnippet01Icon.defaultProps = iconDefaultProps

export type { CodeSnippet01IconProps }
export { CodeSnippet01Icon }
