import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type PieChart03IconProps = IconProps

const PieChart03Icon = forwardRef<SVGSVGElement, PieChart03IconProps>(
  (props: PieChart03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2a10 10 0 0 1 10 10M12 2v10m0-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10M12 2c5.523 0 10 4.477 10 10m0 0H12m10 0a10 10 0 0 1-4.122 8.09L12 12"
        fill="none"
      ></path>
    </Icon>
  ),
)

PieChart03Icon.displayName = 'PieChart03Icon'
PieChart03Icon.defaultProps = iconDefaultProps

export type { PieChart03IconProps }
export { PieChart03Icon }
