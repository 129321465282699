import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Flag05IconProps = IconProps

const Flag05Icon = forwardRef<SVGSVGElement, Flag05IconProps>(
  (props: Flag05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.091 6.722h5.954c.472 0 .708 0 .846.1.12.086.2.22.217.367.02.169-.095.375-.325.788l-1.42 2.557c-.084.15-.125.225-.142.304a.529.529 0 0 0 0 .213c.017.079.058.154.141.303l1.421 2.558c.23.413.344.62.325.788a.528.528 0 0 1-.217.367c-.138.1-.374.1-.846.1h-7.431c-.592 0-.887 0-1.113-.115a1.056 1.056 0 0 1-.461-.462c-.115-.226-.115-.521-.115-1.112v-2.534M7.23 21.5 3.008 4.611m1.583 6.333h7.811c.592 0 .887 0 1.113-.115.199-.1.36-.262.461-.46.115-.227.115-.522.115-1.113V4.189c0-.591 0-.887-.115-1.113a1.056 1.056 0 0 0-.461-.46c-.226-.116-.521-.116-1.113-.116H4.643c-.737 0-1.106 0-1.358.153a1.056 1.056 0 0 0-.461.59c-.087.283.002.64.18 1.356l1.587 6.345Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Flag05Icon.displayName = 'Flag05Icon'
Flag05Icon.defaultProps = iconDefaultProps

export type { Flag05IconProps }
export { Flag05Icon }
