import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BarChartCircle02IconProps = IconProps

const BarChartCircle02Icon = forwardRef<
  SVGSVGElement,
  BarChartCircle02IconProps
>((props: BarChartCircle02IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 15v2m4-6v6m4-10v10m6-5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

BarChartCircle02Icon.displayName = 'BarChartCircle02Icon'
BarChartCircle02Icon.defaultProps = iconDefaultProps

export type { BarChartCircle02IconProps }
export { BarChartCircle02Icon }
