import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ReverseRightIconProps = IconProps

const ReverseRightIcon = forwardRef<SVGSVGElement, ReverseRightIconProps>(
  (props: ReverseRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 7H10a6 6 0 1 0 0 12h10m0-12-4-4m4 4-4 4"
        fill="none"
      ></path>
    </Icon>
  ),
)

ReverseRightIcon.displayName = 'ReverseRightIcon'
ReverseRightIcon.defaultProps = iconDefaultProps

export type { ReverseRightIconProps }
export { ReverseRightIcon }
