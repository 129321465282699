import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Scissors01IconProps = IconProps

const Scissors01Icon = forwardRef<SVGSVGElement, Scissors01IconProps>(
  (props: Scissors01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 4 8.5 15.5m0-7L20 20M6 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 12a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Scissors01Icon.displayName = 'Scissors01Icon'
Scissors01Icon.defaultProps = iconDefaultProps

export type { Scissors01IconProps }
export { Scissors01Icon }
