import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CheckHeartIconProps = IconProps

const CheckHeartIcon = forwardRef<SVGSVGElement, CheckHeartIconProps>(
  (props: CheckHeartIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9 11 2 2 4.5-4.5m-3.507-3.364c-2-2.338-5.333-2.966-7.838-.826s-2.858 5.719-.89 8.25c1.485 1.912 5.706 5.751 7.683 7.515.363.324.545.486.758.55.184.055.39.055.575 0 .212-.064.394-.226.757-.55 1.977-1.764 6.198-5.603 7.684-7.515 1.967-2.531 1.658-6.132-.89-8.25-2.549-2.118-5.84-1.512-7.839.826Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CheckHeartIcon.displayName = 'CheckHeartIcon'
CheckHeartIcon.defaultProps = iconDefaultProps

export type { CheckHeartIconProps }
export { CheckHeartIcon }
