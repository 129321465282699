import React from 'react'
import { IconProps } from '@vori/gourmet-icons'

import { AvatarDefaultIconProps } from './types'

function getIconProps(
  avatarSize: AvatarDefaultIconProps['avatarSize'],
): NonNullable<Pick<IconProps, 'size'>> {
  switch (avatarSize) {
    case 'xs':
    case 'sm': {
      return { size: 'sm' }
    }

    case 'md': {
      return { size: 'md' }
    }

    case 'lg': {
      return { size: 'lg' }
    }

    case 'xxl': {
      return { size: 'xxl' }
    }

    default: {
      return { size: 'xl' }
    }
  }
}

function AvatarDefaultIcon({
  avatarSize,
  icon,
  ...props
}: AvatarDefaultIconProps): JSX.Element {
  return React.cloneElement(icon, {
    ...props,
    ...getIconProps(avatarSize),
    'data-gourmet-avatar-default-icon': '',
    variant: 'secondary',
  })
}

AvatarDefaultIcon.displayName = 'AvatarDefaultIcon'

export { AvatarDefaultIcon }
