import { useIsMounted } from '@vori/react-hooks'
import styled from 'styled-components'

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import {
  positionDefault,
  positionMatchWidth,
  positionRight,
} from '@reach/popover'

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button'

import { CalendarIcon } from '@vori/gourmet-icons'
import { CardProps } from '../Card'
import Calendar, { CalendarDate, CalendarProps } from '../Calendar'
import Button, { ButtonProps } from '../Button'
import Flex from '../Flex'
import HiddenInput from '../HiddenInput'
import Spacer from '../Spacer'
import Text from '../Text'

import { colors, Size, sizing, spacing, typography } from '../tokens'

import useCalendar, {
  CalendarHookReturn,
  CalendarOptions,
} from '../Calendar/useCalendar'

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})

type DateRangePosition = 'default' | 'right' | 'matchWidth'
type DateRangePickerSize = Extract<Size, 'small' | 'base'>

type DateRangePickerProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> & {
  calendarOptions?: CalendarOptions
  calendarProps?: CalendarProps
  disableDateToggle?: boolean
  fullWidth?: boolean
  label?: string
  noLabel?: boolean
  onClear?: () => void
  onDateRangeChange?: (dates: Array<Date>) => void
  placeholder?: string
  popupProps?: CardProps
  position?: DateRangePosition
  renderCalendarFooter?: (
    calendarHookProps: CalendarHookReturn & { close: () => void },
  ) => React.ReactNode
  size?: DateRangePickerSize
  triggerProps?: Omit<ButtonProps, 'as' | 'size'>
}

type DateRangePickerContainerProps =
  React.LabelHTMLAttributes<HTMLLabelElement> & {
    fullWidth?: boolean
  }

const defaultProps: Partial<DateRangePickerProps> = {
  calendarOptions: {},
  calendarProps: {
    dates: [],
  },
  className: '',
  disableDateToggle: false,
  fullWidth: false,
  label: 'Dates',
  noLabel: false,
  placeholder: 'Choose Dates',
  popupProps: {
    size: Calendar.defaultProps?.size,
  },
  position: 'default',
  size: 'base',
}

const StyledMenuPopover = styled(MenuPopover)<{
  $size: CalendarProps['size']
}>`
  max-width: ${({ $size = defaultProps.size }) =>
    $size != null ? sizing.card[$size as Size] : 'auto'};
  width: ${({ $size }) => ($size != null ? '100%' : 'auto')};
  z-index: 1000;
`

const DateRangePickerTrigger = styled(Button)<
  ButtonProps & { $isExpanded?: boolean }
>(({ $isExpanded, disableFocusRing }) => ({
  borderColor: colors.input.borderColor.default,
  justifyContent: 'space-between',
  padding: spacing.input.base,

  ':active, :focus, :hover': {
    borderColor: colors.input.borderColor.default,
  },

  '&, &:hover, &:active': {
    ...($isExpanded && {
      backgroundColor: colors.button.hover.backgroundColor.secondary,
      borderColor: colors.input.borderColor.default,
      color: colors.button.color.secondary,
    }),
  },

  ':focus, :active, :focus-within': {
    borderColor: colors.input.focus.borderColor.default,
  },

  ...($isExpanded && {
    boxShadow: !disableFocusRing
      ? `0 0 0 ${sizing.focusRing} ${colors.button.focusRing.secondary}`
      : 'none',
  }),

  ':focus, :focus-within': {
    boxShadow: !disableFocusRing
      ? `0 0 0 ${sizing.focusRing} ${colors.input.focusRing}`
      : 'none',
  },
}))

const StyledDateRangePickerContainer = styled(
  forwardRef<HTMLLabelElement, DateRangePickerContainerProps>(
    function DateRangePickerContainer(
      { fullWidth, ...props }: DateRangePickerContainerProps,
      ref,
    ) {
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      return <label {...props} ref={ref} />
    },
  ),
)<DateRangePickerContainerProps>`
  display: inline-block;
  position: relative;
  ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  ${({ fullWidth }): string =>
    fullWidth ? `${DateRangePickerTrigger} { width: 100%; }` : ''}
`

const ValueText = styled(Text)({
  fontSize: typography.button.base.fontSize,
  fontWeight: typography.button.base.fontWeight,
  lineHeight: typography.button.base.lineHeight,
})

/**
 * @deprecated use `<DateRangeInput>` instead
 */
const StyledDateRangePicker = styled(
  forwardRef<HTMLLabelElement, DateRangePickerProps>(function DateRangePicker(
    {
      calendarOptions,
      calendarProps,
      className,
      disableDateToggle,
      fullWidth,
      label,
      noLabel,
      onClear,
      onDateRangeChange,
      placeholder,
      popupProps,
      position,
      renderCalendarFooter,
      size,
      triggerProps,
      ...props
    }: DateRangePickerProps,
    ref,
  ) {
    const isMounted = useIsMounted()
    const menuButtonRef = useRef<HTMLButtonElement | null>(null)

    const calendarPropsFromHook = useCalendar({
      ...calendarOptions,
      rangeSelection: true,
    })

    const selectedDates = useMemo<Array<Date>>(
      () => calendarPropsFromHook.selectedDates,
      [calendarPropsFromHook.selectedDates],
    )

    const previousSelectedDates = useRef<Array<Date>>(selectedDates)

    useEffect(() => {
      const prevDates = previousSelectedDates.current

      if (
        onClear != null &&
        prevDates !== selectedDates &&
        selectedDates.length === 0
      ) {
        previousSelectedDates.current = selectedDates
        onClear()
      }

      if (onDateRangeChange != null && prevDates !== selectedDates) {
        previousSelectedDates.current = selectedDates
        onDateRangeChange(selectedDates)
      }
    }, [onClear, onDateRangeChange, selectedDates])

    const triggerMenuButtonClick = useCallback(() => {
      if (isMounted && menuButtonRef.current !== null) {
        menuButtonRef.current.dispatchEvent(
          new Event('mousedown', {
            bubbles: true,
            cancelable: true,
            composed: true,
          }),
        )
      }
    }, [isMounted])

    return (
      <StyledDateRangePickerContainer
        className={className}
        fullWidth={fullWidth as boolean}
        ref={ref}
      >
        <Menu>
          {({ isExpanded }) => (
            <>
              <MenuButton
                $isExpanded={isExpanded}
                disabled={props.disabled}
                leftIcon={<CalendarIcon />}
                {...triggerProps}
                as={DateRangePickerTrigger}
                ref={menuButtonRef}
                size={size}
              >
                <Flex column>
                  {!noLabel && (
                    <Text size="label" variant="primary">
                      {label}
                    </Text>
                  )}
                  <ValueText size={size === 'small' ? 'caption' : 'display'}>
                    {calendarPropsFromHook.selectedDates.length
                      ? `${dateFormatter.format(
                          calendarPropsFromHook.selectedDates[0],
                        )}${
                          calendarPropsFromHook.selectedDates[1]
                            ? ` - ${dateFormatter.format(
                                calendarPropsFromHook.selectedDates[1],
                              )}`
                            : ''
                        }`.trim()
                      : placeholder}
                  </ValueText>
                </Flex>
              </MenuButton>

              <HiddenInput
                {...props}
                value={
                  calendarPropsFromHook.selectedDates
                    .map((selectedDate) => selectedDate.toISOString())
                    .join(',') || ''
                }
              />

              <StyledMenuPopover
                $size={popupProps?.size}
                position={
                  position === 'default'
                    ? positionDefault
                    : position === 'right'
                      ? positionRight
                      : position === 'matchWidth'
                        ? positionMatchWidth
                        : positionDefault
                }
              >
                <Spacer size="small" />
                <MenuItems>
                  <Calendar
                    {...calendarProps}
                    dates={calendarPropsFromHook.dates}
                    fullWidth
                    month={calendarPropsFromHook.formattedMonth}
                    onClickNext={calendarPropsFromHook.getNextMonth}
                    onClickPrev={calendarPropsFromHook.getPrevMonth}
                    showDateRange
                    year={calendarPropsFromHook.formattedYear}
                    renderDate={({
                      date,
                      isDisabled,
                      isFromCurrentMonth,
                      isInSelectedRange,
                      isSelected,
                      isToday,
                      selectDate,
                      toggleDate,
                    }): React.ReactNode => {
                      const currentDate = date.getDate()

                      return (
                        <MenuItem
                          as={CalendarDate}
                          disabled={isDisabled}
                          isDisabled={isDisabled}
                          isFromCurrentMonth={isFromCurrentMonth}
                          isSelected={isSelected}
                          isToday={isToday}
                          isWithinRange={isInSelectedRange}
                          key={`${label}-day-${currentDate}`}
                          onSelect={() => {
                            if (disableDateToggle) {
                              selectDate()
                            } else {
                              toggleDate()
                            }

                            if (menuButtonRef.current !== null) {
                              requestAnimationFrame(triggerMenuButtonClick)
                            }
                          }}
                        >
                          {currentDate}
                        </MenuItem>
                      )
                    }}
                  >
                    {renderCalendarFooter != null
                      ? renderCalendarFooter({
                          ...calendarPropsFromHook,
                          close: triggerMenuButtonClick,
                        })
                      : null}
                  </Calendar>
                </MenuItems>
                <Spacer size="small" />
              </StyledMenuPopover>
            </>
          )}
        </Menu>
      </StyledDateRangePickerContainer>
    )
  }),
)``

StyledDateRangePicker.displayName = 'DateRangePicker'
StyledDateRangePicker.defaultProps = defaultProps

export type { DateRangePickerProps }
export { defaultProps as DateRangePickerDefaultProps }
export default StyledDateRangePicker
