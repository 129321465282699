import React from 'react'

import { Flex, FlexProps } from '../FlexNext'

type Props = FlexProps

/**
 * Provides basic styling for rendering the footer of a `<Modal>` component.
 *
 * @example
 * function ExampleModal(): React.ReactElement {
 *   const { ref, ...modal } = useModal()
 *
 *   return (
 *      <Modal ref={ref} {...modal}>
 *        <ModalContent>
 *          <Text>This is the modal content</Text>
 *          <ModalFooter>
 *            <Button
 *              onClick={() => {
 *                modal.close()
 *              }}
 *              >
 *              Cancel
 *            </Button>
 *            <Button variant="tertiary">Save</Button>
 *          </ModalFooter>
 *        </ModalContent>
 *      </Modal>
 *   )
 * }
 */
const ModalFooter = React.forwardRef<HTMLDivElement, Props>(
  function ModalFooter({ children, ...props }: Props, ref): JSX.Element {
    return (
      <Flex
        centerY
        columnGap="space.075"
        fullWidth
        justifyContent="flex-end"
        {...props}
        data-gourmet-modal-footer=""
        ref={ref}
      >
        {children}
      </Flex>
    )
  },
)

ModalFooter.displayName = 'ModalFooter'
ModalFooter.defaultProps = {}

export { ModalFooter }
export type { Props as ModalFooterProps }
