import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Moon02IconProps = IconProps

const Moon02Icon = forwardRef<SVGSVGElement, Moon02IconProps>(
  (props: Moon02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.955 12.956a8 8 0 1 1-10.91-10.911C5.97 2.526 2 6.799 2 12c0 5.523 4.477 10 10 10 5.2 0 9.473-3.97 9.955-9.044Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Moon02Icon.displayName = 'Moon02Icon'
Moon02Icon.defaultProps = iconDefaultProps

export type { Moon02IconProps }
export { Moon02Icon }
