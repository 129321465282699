import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Cloud03IconProps = IconProps

const Cloud03Icon = forwardRef<SVGSVGElement, Cloud03IconProps>(
  (props: Cloud03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 19a7.5 7.5 0 1 1 6.641-10.988A5.5 5.5 0 1 1 16.5 19h-7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Cloud03Icon.displayName = 'Cloud03Icon'
Cloud03Icon.defaultProps = iconDefaultProps

export type { Cloud03IconProps }
export { Cloud03Icon }
