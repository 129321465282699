import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Monitor02IconProps = IconProps

const Monitor02Icon = forwardRef<SVGSVGElement, Monitor02IconProps>(
  (props: Monitor02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.572 21c1.335-.64 2.838-1 4.428-1 1.59 0 3.093.36 4.428 1M6.8 17h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 14.72 22 13.88 22 12.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 3 18.88 3 17.2 3H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 5.28 2 6.12 2 7.8v4.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 17 5.12 17 6.8 17Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Monitor02Icon.displayName = 'Monitor02Icon'
Monitor02Icon.defaultProps = iconDefaultProps

export type { Monitor02IconProps }
export { Monitor02Icon }
