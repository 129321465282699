import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignLeft01IconProps = IconProps

const AlignLeft01Icon = forwardRef<SVGSVGElement, AlignLeft01IconProps>(
  (props: AlignLeft01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 3v18m18-9H7m0 0 7 7m-7-7 7-7"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlignLeft01Icon.displayName = 'AlignLeft01Icon'
AlignLeft01Icon.defaultProps = iconDefaultProps

export type { AlignLeft01IconProps }
export { AlignLeft01Icon }
