import React from 'react'

import {
  useClientId,
  useEffectOnceConditionally,
  useEventListener,
  useOnClickOutside,
} from '@vori/react-hooks'

import { HookOptions, HookReturn } from './types'

export function useDialog(options?: HookOptions): HookReturn {
  const [dialogID] = useClientId('gourmet-dialog')
  const [ref, setRef] = React.useState<HTMLDialogElement | null>(null)
  const [isOpen, setIsOpen] = React.useState(Boolean(ref?.open))

  const show = React.useCallback<HookReturn['show']>(() => {
    if (!ref) {
      return
    }

    if (options?.asModal) {
      ref.showModal()
    } else {
      ref.show()
    }

    setIsOpen(true)

    options?.onShow?.()
  }, [options, ref])

  const close = React.useCallback<HookReturn['close']>(
    (returnValue) => {
      if (!ref) {
        return
      }

      ref.close(returnValue)
    },
    [ref],
  )

  React.useEffect(() => {
    if (!ref || options?.open === undefined) {
      return
    }

    if (options.open && !ref.open) {
      show()
    } else if (!options.open && ref.open) {
      close()
    }
  }, [close, options?.open, ref, show])

  useEffectOnceConditionally(() => {
    if (options?.openByDefault) {
      show()
    }
  }, ref != null)

  // We use `useOnClickOutside` for the non-modal dialogs, but we need custom
  // logic for dialogs using `asModal` because we can't detect clicks on the
  // shadow DOM generated by the dialog element for the back drop (`::backdrop`),
  // so we use `useEventListener` for that.
  useOnClickOutside(
    [ref],
    () => {
      close()
    },
    Boolean(options?.asModal) || !options?.closeOnClickOutside,
  )

  useEventListener(
    'click',
    (event) => {
      if (!options?.asModal) {
        return
      }

      if (
        options?.closeOnClickOutside &&
        event.currentTarget instanceof HTMLDialogElement &&
        event.target === event.currentTarget
      ) {
        event.currentTarget.close()
      }
    },
    { element: ref },
  )

  useEventListener(
    'cancel' as keyof GlobalEventHandlersEventMap,
    (event) => {
      setIsOpen(false)
      options?.onCancel?.(event)
    },
    { element: ref },
  )

  useEventListener(
    'close',
    (event) => {
      setIsOpen(false)
      options?.onClose?.(event)
    },
    { element: ref },
  )

  return React.useMemo<HookReturn>(
    () => ({
      close,
      id: options?.id || dialogID,
      isModal: Boolean(options?.asModal),
      isOpen,
      ref: setRef,
      show,
      triggerProps: {
        'aria-controls': options?.id || dialogID,
        'aria-expanded': isOpen,
        'aria-haspopup': 'dialog',
      },
    }),
    [close, dialogID, isOpen, options?.asModal, options?.id, show],
  )
}
