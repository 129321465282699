import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Asterisk01IconProps = IconProps

const Asterisk01Icon = forwardRef<SVGSVGElement, Asterisk01IconProps>(
  (props: Asterisk01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2v20m7.071-17.071L4.93 19.07M22 12H2m17.071 7.071L4.93 4.93"
        fill="none"
      ></path>
    </Icon>
  ),
)

Asterisk01Icon.displayName = 'Asterisk01Icon'
Asterisk01Icon.defaultProps = iconDefaultProps

export type { Asterisk01IconProps }
export { Asterisk01Icon }
