import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ScissorsCut02IconProps = IconProps

const ScissorsCut02Icon = forwardRef<SVGSVGElement, ScissorsCut02IconProps>(
  (props: ScissorsCut02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.5 8.6 21 17m0-10L4.5 15.4m13-3.4h.01M22 12h.01M6 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 12a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ScissorsCut02Icon.displayName = 'ScissorsCut02Icon'
ScissorsCut02Icon.defaultProps = iconDefaultProps

export type { ScissorsCut02IconProps }
export { ScissorsCut02Icon }
