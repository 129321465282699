import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type HomeSmileIconProps = IconProps

const HomeSmileIcon = forwardRef<SVGSVGElement, HomeSmileIconProps>(
  (props: HomeSmileIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.126 14a4.002 4.002 0 0 0 7.748 0M11.018 2.764 4.235 8.039c-.453.353-.68.53-.843.75a2 2 0 0 0-.318.65C3 9.704 3 9.991 3 10.565V17.8c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 21 5.08 21 6.2 21h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C21 19.48 21 18.92 21 17.8v-7.235c0-.574 0-.861-.074-1.126a2.002 2.002 0 0 0-.318-.65c-.163-.22-.39-.397-.843-.75l-6.783-5.275c-.351-.273-.527-.41-.72-.462a1 1 0 0 0-.523 0c-.194.052-.37.189-.721.462Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

HomeSmileIcon.displayName = 'HomeSmileIcon'
HomeSmileIcon.defaultProps = iconDefaultProps

export type { HomeSmileIconProps }
export { HomeSmileIcon }
