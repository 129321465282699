import React from 'react'

import { AlertCircleIcon } from '@vori/gourmet-icons'
import { useClientId } from '@vori/react-hooks'

import { InlineFlex } from '../FlexNext'
import { VisuallyHidden } from '../VisuallyHidden'
import Tooltip from '../Tooltip'

import { HintCoreProps, HintProps } from './types'

const defaultProps: Partial<HintCoreProps> = {
  icon: <AlertCircleIcon />,
}

/**
 * The `<Hint>` component is based on the "tooltip" pattern, i.e a popup that
 * displays information related to an element when the element receives keyboard
 * focus or the mouse hovers over it. It's used to provide additional and/or
 * contextual information about something.
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/}
 *
 * @example
 * <Hint>I just wanted to say hi :)</Hint>
 */
const Hint = React.forwardRef<HTMLButtonElement, HintProps>(function Hint(
  { children, icon, tooltipProps, ...props }: HintProps,
  ref,
): JSX.Element {
  const [describedByID] = useClientId('gourmet-hint')

  return (
    <InlineFlex ref={ref} {...props} aria-describedby={describedByID}>
      <Tooltip {...tooltipProps} label={children}>
        {icon}
      </Tooltip>
      <VisuallyHidden id={describedByID}>{children}</VisuallyHidden>
    </InlineFlex>
  )
})

Hint.displayName = 'Hint'
Hint.defaultProps = defaultProps

export { Hint, defaultProps as hintDefaultProps }
