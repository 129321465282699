export function getInitialsFromDisplayName(displayName?: string): string {
  const displayNameSplit = (displayName || '').split(' ')
  return `${displayNameSplit[0]?.[0]?.toUpperCase()}${(
    displayNameSplit[1]?.[0] || ''
  )?.toUpperCase()}`.trim()
}

export function resolveStringProp(
  propValue?: string | (() => string) | null,
): string {
  return typeof propValue === 'function' ? propValue() : propValue || ''
}
