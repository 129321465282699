import React from 'react'

import { ModalFooter, ModalFooterProps } from '../ModalNext'
import { AlertModalCancelButton } from './AlertModalCancelButton'
import { AlertModalConfirmButton } from './AlertModalConfirmButton'

type Props = ModalFooterProps & {
  /**
   * Use to denote the text displayed on the cancel button.
   */
  cancelButtonLabel?: string
  /**
   * Use to denote the text displayed on the confirm button.
   */
  confirmButtonLabel?: string
  /**
   * Callback function executed when clicking on the cancel button.
   */
  onCancel?: React.HTMLAttributes<HTMLButtonElement>['onClick']
  /**
   * Callback function executed when clicking on the confirm button.
   */
  onConfirm?: React.HTMLAttributes<HTMLButtonElement>['onClick']
}

/**
 * Provides basic styling for rendering the footer of a `<AlertModal>` component
 * with cancel and confirm buttons.
 *
 * @example
 * function ExampleAlertModal(): React.ReactElement {
 *   const { ref, ...alertModal } = useAlertModal()
 *
 *   return (
 *      <AlertModal ref={ref} {...alertModal}>
 *        <AlertModalContent>
 *          <Text>This is the modal content</Text>
 *          <AlertModalFooterWithControls />
 *        </AlertModalContent>
 *      </AlertModal>
 *   )
 * }
 */
const AlertModalFooterWithControls = React.forwardRef<HTMLDivElement, Props>(
  function AlertModalFooterWithControls(
    {
      cancelButtonLabel,
      confirmButtonLabel,
      onCancel,
      onConfirm,
      ...props
    }: Props,
    ref,
  ): JSX.Element {
    return (
      <ModalFooter
        {...props}
        ref={ref}
        data-gourmet-alert-modal-footer-with-controls=""
      >
        <AlertModalCancelButton onClick={onCancel}>
          {cancelButtonLabel || 'Cancel'}
        </AlertModalCancelButton>
        <AlertModalConfirmButton onClick={onConfirm}>
          {confirmButtonLabel || 'Confirm'}
        </AlertModalConfirmButton>
      </ModalFooter>
    )
  },
)

AlertModalFooterWithControls.displayName = 'AlertModalFooterWithControls'
AlertModalFooterWithControls.defaultProps = {}

export { AlertModalFooterWithControls }
export type { Props as AlertModalFooterWithControlsProps }
