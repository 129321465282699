import React from 'react'

import { DialogContextValue } from '../Dialog'

type ModalContextValue = DialogContextValue & {
  descriptionID: string
  titleID: string
}

const ModalContext = React.createContext<ModalContextValue | undefined>(
  undefined,
)

ModalContext.displayName = 'ModalContext'

/**
 * Returns the current `value` for the parent `<ModalContext>`, if any.
 */
function useModalContext(): ModalContextValue {
  const value = React.useContext(ModalContext)

  if (value === undefined) {
    throw new Error(
      'The `useModalContext` hook must be used within `<ModalContext.Provider>`',
    )
  }

  return value
}

export { ModalContext, useModalContext }
export type { ModalContextValue }
