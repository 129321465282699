import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Wind02IconProps = IconProps

const Wind02Icon = forwardRef<SVGSVGElement, Wind02IconProps>(
  (props: Wind02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.51 4.667A2 2 0 1 1 11 8H2m10.51 11.333A2 2 0 1 0 14 16H2m14.764-9A3 3 0 1 1 19 12H2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Wind02Icon.displayName = 'Wind02Icon'
Wind02Icon.defaultProps = iconDefaultProps

export type { Wind02IconProps }
export { Wind02Icon }
