import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlarmClockCheckIconProps = IconProps

const AlarmClockCheckIcon = forwardRef<SVGSVGElement, AlarmClockCheckIconProps>(
  (props: AlarmClockCheckIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 3 2 6m20 0-3-3M6 19l-2 2m14-2 2 2M9 13.5l2 2 4.5-4.5M12 21a8 8 0 1 0 0-16.001A8 8 0 0 0 12 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlarmClockCheckIcon.displayName = 'AlarmClockCheckIcon'
AlarmClockCheckIcon.defaultProps = iconDefaultProps

export type { AlarmClockCheckIconProps }
export { AlarmClockCheckIcon }
