import React from 'react'

import { CardRowsCoreProps, CardRowsProps } from './types'
import { Flex, flexDefaultProps } from '../FlexNext'

const { direction, ...flexDefaultPropsWithoutDirection } = flexDefaultProps

const defaultProps: Partial<CardRowsCoreProps> =
  flexDefaultPropsWithoutDirection

/**
 * The `<CardRows>` component is used within `<Card>` components to layout a
 * list of `<CardRow>` components.
 *
 * @extends Card
 * @example
 * <Card asFlat fullWidth>
 *   <CardRows>
 *     <CardRow>...</CardRow>
 *     <CardRow>...</CardRow>
 *   </CardRows>
 * </Card>
 */
const CardRows = React.forwardRef<HTMLDivElement, CardRowsProps>(
  function CardRows({ children, ...props }: CardRowsProps, ref): JSX.Element {
    return (
      <Flex
        {...props}
        data-gourmet-card-rows=""
        direction="column"
        fullWidth
        ref={ref}
        rowGap="space.100"
      >
        {children}
      </Flex>
    )
  },
)

CardRows.displayName = 'CardRows'
CardRows.defaultProps = defaultProps

export { CardRows, defaultProps as cardRowsDefaultProps }
