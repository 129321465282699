import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserX01IconProps = IconProps

const UserX01Icon = forwardRef<SVGSVGElement, UserX01IconProps>(
  (props: UserX01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16.5 16 5 5m0-5-5 5M12 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserX01Icon.displayName = 'UserX01Icon'
UserX01Icon.defaultProps = iconDefaultProps

export type { UserX01IconProps }
export { UserX01Icon }
