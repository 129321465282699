import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SpacingHeight02IconProps = IconProps

const SpacingHeight02Icon = forwardRef<SVGSVGElement, SpacingHeight02IconProps>(
  (props: SpacingHeight02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 3H3m18 18H3m9-3.5v-11m3 0H9m6 11H9"
        fill="none"
      ></path>
    </Icon>
  ),
)

SpacingHeight02Icon.displayName = 'SpacingHeight02Icon'
SpacingHeight02Icon.defaultProps = iconDefaultProps

export type { SpacingHeight02IconProps }
export { SpacingHeight02Icon }
