import React from 'react'

import {
  MenuButton as ReachMenuButton,
  useMenuButtonContext,
} from '@reach/menu-button'

import { Button, buttonDefaultProps, ButtonProps } from '../ButtonNext'

type MenuButtonProps = Omit<ButtonProps, 'children'> & {
  children?: React.ReactNode | ((isExpanded: boolean) => React.ReactNode)
}

const defaultProps: Partial<ButtonProps> = buttonDefaultProps

/**
 * The `<MenuButton>` component is a button that opens a menu as described in
 * the Menu Pattern via the `<Menu>` component.
 *
 * @example
 * <Menu>
 *   <MenuButton>Actions</MenuButton>
 *   <MenuList>
 *     <MenuItem onSelect={() => alert("Export")}>Export</MenuItem>
 *   </MenuList>
 * </Menu>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/}
 */
const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  function MenuButton(
    { children, ...props }: MenuButtonProps,
    ref,
  ): JSX.Element {
    const { isExpanded } = useMenuButtonContext()

    return (
      <ReachMenuButton
        {...props}
        as={Button}
        data-gourmet-menu-button=""
        ref={ref}
      >
        {typeof children === 'function' ? children(isExpanded) : children}
      </ReachMenuButton>
    )
  },
)

MenuButton.displayName = 'MenuButton'
MenuButton.defaultProps = defaultProps

export { MenuButton, defaultProps as paragraphDefaultProps }
export type { MenuButtonProps }
