import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MessageNotificationCircleIconProps = IconProps

const MessageNotificationCircleIcon = forwardRef<
  SVGSVGElement,
  MessageNotificationCircleIconProps
>((props: MessageNotificationCircleIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11.707 3.036a8.5 8.5 0 0 0-7.264 11.18c.108.322.162.482.172.605a.899.899 0 0 1-.028.326c-.03.12-.098.245-.232.494l-1.636 3.027c-.233.432-.35.648-.324.815a.5.5 0 0 0 .234.35c.144.087.388.062.876.011l5.121-.529c.155-.016.233-.024.303-.021.07.002.12.009.187.024.069.016.155.05.329.116a8.5 8.5 0 0 0 11.52-7.153M20.12 3.88a3 3 0 1 1-4.243 4.242A3 3 0 0 1 20.12 3.88Z"
      fill="none"
    ></path>
  </Icon>
))

MessageNotificationCircleIcon.displayName = 'MessageNotificationCircleIcon'
MessageNotificationCircleIcon.defaultProps = iconDefaultProps

export type { MessageNotificationCircleIconProps }
export { MessageNotificationCircleIcon }
