import React from 'react'

export function CashIcon(): JSX.Element {
  return (
    <g>
      <rect width="46" height="32" rx="4" fill="#FFFFFF" />
      <path
        d="M10.4616 20.144C7.46156 20.144 5.98556 18.392 5.98556 15.764V15.668C5.98556 13.028 7.85756 11.288 10.3656 11.288C12.5976 11.288 14.1456 12.32 14.3496 14.588H11.8176C11.7216 13.712 11.2416 13.232 10.3776 13.232C9.27356 13.232 8.57756 14.096 8.57756 15.656V15.752C8.57756 17.3 9.23756 18.176 10.4136 18.176C11.2776 18.176 11.8896 17.72 12.0096 16.772H14.4576C14.2896 18.992 12.6816 20.144 10.4616 20.144ZM14.6873 20L17.4353 11.42H20.6153L23.3393 20H20.7353L20.2793 18.404H17.4353L16.9793 20H14.6873ZM18.8633 13.412L17.9153 16.712H19.7993L18.8633 13.412ZM27.2923 20.144C24.8923 20.144 23.6683 19.136 23.5963 17.276H25.9363C25.9963 17.888 26.2723 18.392 27.2923 18.392C28.0003 18.392 28.3963 18.044 28.3963 17.54C28.3963 17.012 28.0723 16.868 27.0403 16.712C24.6163 16.424 23.8003 15.704 23.8003 13.976C23.8003 12.428 25.0723 11.3 27.1243 11.3C29.1883 11.3 30.3523 12.164 30.5083 13.976H28.2283C28.1443 13.364 27.7963 13.052 27.1243 13.052C26.4643 13.052 26.1283 13.352 26.1283 13.784C26.1283 14.264 26.3923 14.48 27.4603 14.624C29.6323 14.864 30.7483 15.428 30.7483 17.288C30.7483 18.86 29.5003 20.144 27.2923 20.144ZM31.9178 20V11.42H34.3898V14.696H37.2098V11.42H39.6698V20H37.2098V16.616H34.3898V20H31.9178Z"
        fill="#3F3F46"
      />
      <rect x="0.5" y="0.5" width="45" height="31" rx="3.5" stroke="#F4F4F5" />
    </g>
  )
}
