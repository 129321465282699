import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type NotDeliveredIconProps = IconProps

const NotDeliveredIcon = forwardRef<SVGSVGElement, NotDeliveredIconProps>(
  (props: NotDeliveredIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.143 8.647a8.11 8.11 0 0 1-1.164 4.199l-5.734 9.933a1.443 1.443 0 0 1-2.5 0l-5.747-9.955a8.147 8.147 0 1 1 15.144-4.177Zm-4.073 3.084a.987.987 0 0 0-.288-.697l-2.388-2.387 2.388-2.386a.987.987 0 0 0-1.397-1.397L11.997 7.25 9.61 4.864a.988.988 0 1 0-1.397 1.397L10.6 8.647l-2.387 2.386A.988.988 0 1 0 9.61 12.43l2.387-2.386 2.388 2.386a.989.989 0 0 0 1.685-.699Z"
        clipRule="evenodd"
        stroke="none"
      ></path>
    </Icon>
  ),
)

NotDeliveredIcon.displayName = 'NotDeliveredIcon'
NotDeliveredIcon.defaultProps = iconDefaultProps

export type { NotDeliveredIconProps }
export { NotDeliveredIcon }
