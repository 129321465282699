import dayjs from 'dayjs'
import React from 'react'

import { useGetJWT } from '@vori/dashboard-rest-next/courier/courier'

import { ENV_USE_MOCKS } from '@vori/dashboard-env'
import { FeatureFlags } from '@vori/dashboard-constants'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import { decodeToken, isTokenExpired } from './utils'

/**
 * Fetches a JWT granting access to the current user's notifications,
 * via Courier.
 *
 * @see {@link https://www.courier.com/}
 */
export function useCourierToken(): string | null {
  const { user } = useCurrentUserState()
  const tokenFromSessionStorage = sessionStorage.getItem('courier-jwt')

  const [courierToken, setCourierToken] = React.useState<string | null>(
    !isTokenExpired(tokenFromSessionStorage) ? tokenFromSessionStorage : null,
  )

  const refreshIntervalRef = React.useRef<null | NodeJS.Timeout>(null)

  const canSeeCourierInbox = useFeatureConfig(
    FeatureFlags.WEB_NOTIFICATIONS_INBOX,
  )

  const { data, mutate } = useGetJWT({
    swr: {
      enabled:
        user.state.isLoggedIn &&
        canSeeCourierInbox &&
        isTokenExpired(courierToken) &&
        !ENV_USE_MOCKS,
    },
  })

  React.useEffect(() => {
    if (
      data != null &&
      (courierToken !== data.token || !refreshIntervalRef.current)
    ) {
      setCourierToken(data.token)
      sessionStorage.setItem('courier-jwt', data.token)

      const intervalLengthInSeconds =
        decodeToken(data.token).exp - dayjs(new Date()).unix()

      refreshIntervalRef.current = setInterval(() => {
        mutate()
      }, intervalLengthInSeconds * 1000)
    }

    const refreshInterval = refreshIntervalRef.current

    return () => {
      if (refreshInterval) {
        refreshIntervalRef.current = null
        clearInterval(refreshInterval)
      }
    }
  }, [canSeeCourierInbox, courierToken, data, mutate, user.state.isLoggedIn])

  React.useEffect(() => {
    if (!user.state.isLoggedIn) {
      sessionStorage.removeItem('courier-jwt')
    }
  }, [user.state.isLoggedIn])

  return courierToken
}
