import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Flex, { FlexDefaultProps, FlexProps } from '../Flex'
import Label from '../Label'
import Text from '../Text'

import { colors, foundation, sizing, spacing } from '../tokens'
import toTransitions from '../utils/toTransitions'

type CardColorVariant = keyof typeof colors.card.backgroundColor

type CardProps = React.HTMLAttributes<HTMLDivElement> &
  FlexProps & {
    noBorder?: boolean
    noPadding?: boolean
    noShadow?: boolean
    variant?: CardColorVariant
  }

const defaultProps: Partial<CardProps> = {
  ...FlexDefaultProps,
  className: '',
  noBorder: false,
  noPadding: false,
  noShadow: false,
  variant: 'default',
}

/**
 * @deprecated Use `<CardNext>` instead.
 */
const StyledCard = styled(
  forwardRef<HTMLDivElement, CardProps>(function Card(
    { noBorder, noPadding, noShadow, variant, ...props }: CardProps,
    ref,
  ) {
    return <Flex {...props} data-gourmet-card="" ref={ref} />
  }),
)<CardProps>`
  background: ${({ variant = defaultProps.variant }): string =>
    colors.card.backgroundColor[variant as CardColorVariant]};
  border: ${({ noBorder }) => (noBorder ? '0px' : '1px')} solid
    ${({ variant = defaultProps.variant }): string =>
      colors.card.borderColor[variant as CardColorVariant]};
  border-radius: ${sizing.radius.base};
  color: ${({ variant = defaultProps.variant }): string =>
    variant === 'default'
      ? colors.card.color.default
      : colors.card.color.primary};
  transition: ${toTransitions(['background-color', 'color'], 'ease')};

  ${Text}:not(${Label}) {
    ${({ variant = defaultProps.variant }): string =>
      variant === 'primary' ? `color: ${colors.card.color.primary};` : ''}
  }

  ${({ noPadding }): string =>
    noPadding ? '&& { padding: 0px; }' : `& { padding: ${spacing.card}; }`}

  & [data-gourmet-card] {
    padding: ${foundation.spacing.medium};
  }
`

StyledCard.displayName = 'Card'
StyledCard.defaultProps = defaultProps

export type { CardProps }
export { defaultProps as CardDefaultProps }
export default StyledCard
