import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Lightbulb02IconProps = IconProps

const Lightbulb02Icon = forwardRef<SVGSVGElement, Lightbulb02IconProps>(
  (props: Lightbulb02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 17.659V20a2 2 0 1 0 4 0v-2.341M12 2v1m-9 9H2m3.5-6.5-.6-.6m13.6.6.6-.6M22 12h-1m-3 0a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Lightbulb02Icon.displayName = 'Lightbulb02Icon'
Lightbulb02Icon.defaultProps = iconDefaultProps

export type { Lightbulb02IconProps }
export { Lightbulb02Icon }
