import toRem from '../utils/toRem'

const colors = {
  /**
   * - Category: Colors | Brand
   * - Name: Purple
   * - Value: #5235D0
   */
  purple: '#5235D0',
  /**
   * - Category: Colors | Brand
   * - Name: Dark Purple
   * - Value: #452CB1
   */
  darkPurple: '#452CB1',
  /**
   * - Category: Colors | Brand
   * - Name: Light Purple
   * - Value: #E6E0FF
   */
  lightPurple: '#E6E0FF',
  /**
   * - Category: Colors | Secondary
   * - Name: Gold
   * - Value: #D07F35
   */
  gold: '#D07F35',
  /**
   * - Category: Colors | Secondary
   * - Name: Light Gold
   * - Value: #FFECD1
   */
  lightGold: '#FFECD1',
  /**
   * - Category: Colors | Secondary
   * - Name: Pink
   * - Value: #BB6BD9
   */
  pink: '#BB6BD9',
  /**
   * - Category: Colors | Secondary
   * - Name: Light Pink
   * - Value: #EDD9F3
   */
  lightPink: '#EDD9F3',
  /**
   * - Category: Colors | Secondary
   * - Name: Blue
   * - Value: #1C81F9
   */
  blue: '#1C81F9',
  /**
   * - Category: Colors | Secondary
   * - Name: Light Blue
   * - Value: #E1EFFF
   */
  lightBlue: '#E1EFFF',
  /**
   * - Category: Colors | Neutrals
   * - Name: Pure White
   * - Value: #FFFFFF
   */
  pureWhite: '#FFFFFF',
  /**
   * - Category: Colors | Neutrals
   * - Name: Light 1
   * - Value: #FBFBFB
   */
  light1: '#FBFBFB',
  /**
   * - Category: Colors | Neutrals
   * - Name: Light 2
   * - Value: #F2F2F2
   */
  light2: '#F2F2F2',
  /**
   * - Category: Colors | Neutrals
   * - Name: Light 3
   * - Value: #EDEDED
   */
  light3: '#EDEDED',
  /**
   * - Category: Colors | Neutrals
   * - Name: Light 4
   * - Value: #E0E0E0
   */
  light4: '#E0E0E0',
  /**
   * - Category: Colors | Neutrals
   * - Name: Dark 1
   * - Value: #2E3238
   */
  dark1: '#2E3238',
  /**
   * - Category: Colors | Neutrals
   * - Name: Dark 2
   * - Value: #6F6F6F
   */
  dark2: '#6F6F6F',
  /**
   * - Category: Colors | Neutrals
   * - Name: Dark 3
   * - Value: #ABABAB
   */
  dark3: '#ABABAB',
  /**
   * - Category: Colors | Neutrals
   * - Name: Dark 4
   * - Value: #DADADA
   */
  dark4: '#DADADA',
  /**
   * - Category: Colors | Status
   * - Name: Green
   * - Value: #16A364
   */
  green: '#16A364',
  /**
   * - Category: Colors | Status
   * - Name: Accent Green
   * - Value: #1BC47D
   */
  accentGreen: '#1BC47D',
  /**
   * - Category: Colors | Status
   * - Name: Light Green
   * - Value: #DFF2E9
   */
  lightGreen: '#DFF2E9',
  /**
   * - Category: Colors | Status
   * - Name: Red
   * - Value: #D6273D
   */
  red: '#D6273D',
  /**
   * - Category: Colors | Status
   * - Name: Accent Red
   * - Value: #DE4758
   */
  accentRed: '#DE4758',
  /**
   * - Category: Colors | Status
   * - Name: Light Red
   * - Value: #F9DFE2
   */
  lightRed: '#F9DFE2',
}

type Color = keyof typeof colors

type ColorVariant =
  | 'default'
  | 'negative'
  | 'positive'
  | 'primary'
  | 'progress'
  | 'secondary'

const fontSizes = {
  /**
   * - Category: Typography | Size
   * - Name: label
   * - Value: 12px
   */
  label: toRem(12),
  /**
   * - Category: Typography | Size
   * - Name: caption
   * - Value: 12px
   */
  caption: toRem(12),
  /**
   * - Category: Typography | Size
   * - Name: body
   * - Value: 14px
   */
  body: toRem(14),
  /**
   * - Category: Typography | Size
   * - Name: display
   * - Value: 14px
   */
  display: toRem(14),
  /**
   * - Category: Typography | Size
   * - Name: h6
   * - Value: 14px
   */
  h6: toRem(14),
  /**
   * - Category: Typography | Size
   * - Name: h5
   * - Value: 16px
   */
  h5: toRem(16),
  /**
   * - Category: Typography | Size
   * - Name: h4
   * - Value: 16px
   */
  h4: toRem(16),
  /**
   * - Category: Typography | Size
   * - Name: h3
   * - Value: 24px
   */
  h3: toRem(24),
  /**
   * - Category: Typography | Size
   * - Name: h2
   * - Value: 32px
   */
  h2: toRem(32),
  /**
   * - Category: Typography | Size
   * - Name: h1
   * - Value: 40px
   */
  h1: toRem(40),
}

type FontSize = keyof typeof fontSizes

const fontWeights = {
  /**
   * - Category: Typography | Weight
   * - Name: normal
   * - Value: 400
   */
  normal: '400',
  /**
   * - Category: Typography | Weight
   * - Name: medium
   * - Value: 500
   */
  medium: '500',
  /**
   * - Category: Typography | Weight
   * - Name: bold
   * - Value: 600
   */
  bold: '600',
}

type FontWeight = keyof typeof fontWeights

const lineHeights = {
  /**
   * - Category: Typography | LineHeight
   * - Name: label
   * - Value: 20px
   */
  label: toRem(20),
  /**
   * - Category: Typography | LineHeight
   * - Name: caption
   * - Value: 20px
   */
  caption: toRem(20),
  /**
   * - Category: Typography | LineHeight
   * - Name: body
   * - Value: 22px
   */
  body: toRem(22),
  /**
   * - Category: Typography | LineHeight
   * - Name: display
   * - Value: 20px
   */
  display: toRem(20),
  /**
   * - Category: Typography | LineHeight
   * - Name: h6
   * - Value: 20px
   */
  h6: toRem(20),
  /**
   * - Category: Typography | LineHeight
   * - Name: h5
   * - Value: 24px
   */
  h5: toRem(24),
  /**
   * - Category: Typography | LineHeight
   * - Name: h4
   * - Value: 24px
   */
  h4: toRem(24),
  /**
   * - Category: Typography | LineHeight
   * - Name: h3
   * - Value: 32px
   */
  h3: toRem(32),
  /**
   * - Category: Typography | LineHeight
   * - Name: h2
   * - Value: 42px
   */
  h2: toRem(42),
  /**
   * - Category: Typography | LineHeight
   * - Name: h1
   * - Value: 48px
   */
  h1: toRem(48),
}

type LineHeight = keyof typeof lineHeights

const spacing = {
  /**
   * - Category: Spacing
   * - Name: tiny
   * - Value: 8px
   */
  tiny: toRem(8),
  /**
   * - Category: Spacing
   * - Name: small
   * - Value: 12px
   */
  small: toRem(12),
  /**
   * - Category: Spacing
   * - Name: medium
   * - Value: 16px
   */
  medium: toRem(16),
  /**
   * - Category: Spacing
   * - Name: base
   * - Value: 24px
   */
  base: toRem(24),
  /**
   * - Category: Spacing
   * - Name: large
   * - Value: 48px
   */
  large: toRem(48),
  /**
   * - Category: Spacing
   * - Name: xLarge
   * - Value: 64px
   */
  xLarge: toRem(64),
}

type Space = keyof typeof spacing
type Size = Space

export type {
  Color,
  ColorVariant,
  FontSize,
  FontWeight,
  LineHeight,
  Size,
  Space,
}

export default {
  colors,
  spacing,
  typography: {
    fontSizes,
    fontWeights,
    lineHeights,
  },
}
