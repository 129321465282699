import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlarmClockPlusIconProps = IconProps

const AlarmClockPlusIcon = forwardRef<SVGSVGElement, AlarmClockPlusIconProps>(
  (props: AlarmClockPlusIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 3 2 6m20 0-3-3M6 19l-2 2m14-2 2 2m-8-5v-6m-3 3h6m-3 8a8 8 0 1 0 0-16.001A8 8 0 0 0 12 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

AlarmClockPlusIcon.displayName = 'AlarmClockPlusIcon'
AlarmClockPlusIcon.defaultProps = iconDefaultProps

export type { AlarmClockPlusIconProps }
export { AlarmClockPlusIcon }
