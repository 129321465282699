import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Flag02IconProps = IconProps

const Flag02Icon = forwardRef<SVGSVGElement, Flag02IconProps>(
  (props: Flag02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V4s-1 1-4 1-5-2-8-2-4 1-4 1m0 18V2"
        fill="none"
      ></path>
    </Icon>
  ),
)

Flag02Icon.displayName = 'Flag02Icon'
Flag02Icon.defaultProps = iconDefaultProps

export type { Flag02IconProps }
export { Flag02Icon }
