import { CSSObject } from 'styled-components'
import React from 'react'
import styled from 'styled-components'

import { getBackgroundCSSProperties } from './utils'
import { toRem, toTransitions } from '../utils'

function styles(): CSSObject {
  return {
    backgroundColor: '#FFFFFF',
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${toRem(16)} auto`,
    borderColor: '#D1D1D6',
    borderRadius: toRem(8),
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#15141B',
    fontSize: toRem(16),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: toRem(24),
    minHeight: toRem(40),
    paddingBottom: toRem(8),
    paddingLeft: toRem(12),
    paddingRight: toRem(12),
    paddingTop: toRem(8),

    transition: toTransitions(
      ['background-color', 'border-color', 'box-shadow', 'color', 'zIndex'],
      'ease',
    ),

    '&:disabled, &:read-only, ::placeholder': {
      color: '#70707B',
    },

    '&[aria-invalid="true"]': {
      borderColor: '#FD9AA4',
    },

    '&:hover': {
      borderColor: '#6038EF',

      '&[aria-invalid="true"]': {
        borderColor: '#FD9AA4',
      },
    },

    '&:focus, &:focus-within': {
      borderColor: '#6038EF',
      boxShadow: `0 0 0 ${toRem(4)} #EBE9FE`,
      outline: 'none',

      '&[aria-invalid="true"]': {
        borderColor: '#FD9AA4',
        boxShadow: `0 0 0 ${toRem(4)} #FEE2E5`,
      },
    },

    '&:disabled, &:read-only': {
      borderColor: '#D1D1D6',
      backgroundColor: '#FAFAFA',
      boxShadow: 'none',
      cursor: 'default',
    },

    '&:disabled': {
      cursor: 'not-allowed',
    },

    '&[data-left-icon]': {
      paddingLeft: `calc(${toRem(12)} + ${toRem(16)} + ${toRem(8)})`,
    },

    '&[data-right-icon], &[aria-invalid="true"]': {
      paddingRight: `calc(${toRem(12)} + ${toRem(16)} + ${toRem(8)})`,
    },

    '&[data-full-width]': {
      width: '100%',
    },

    '&[data-no-padding]': {
      minHeight: 'auto',
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,

      '&[data-left-icon]': {
        paddingLeft: `calc(${toRem(16)} + ${toRem(8)})`,
      },

      '&[data-right-icon], &[aria-invalid="true"]': {
        paddingRight: `calc(${toRem(16)} + ${toRem(8)})`,
      },
    },

    '&[data-editable], &[data-editable]:hover, &[data-editable]:focus': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },

    '&[data-no-focus-ring], &[data-no-focus-ring]:focus': {
      boxShadow: 'none',
    },

    '::-webkit-search-cancel-button': {
      cursor: 'pointer',
    },
  }
}

const StyledInput = styled.input(styles)

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  /**
   * When `true`, the input will be rendered without background and border
   * colors. This is useful when you want to introduce user input as part of a
   * more complex component, e.g. data grid cell, tagged input, etc.
   */
  asEditable?: boolean
  /**
   * When `true`, the input will take up all available horizontal space.
   */
  fullWidth?: boolean
  /**
   * Provides the ability to display an icon to the left of the user input.
   */
  leftIcon?: React.ReactElement | null
  /**
   * Disables the focus ring that appears when the input is focused. This should
   * never be `true` due to accessibility concerns, unless you intend to use the
   * input with the `asEditable` prop within another component that is providing
   * proper styling through `:focus-within`.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/:focus#focus_outline_none}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within}
   */
  noFocusRing?: boolean
  /**
   * Removes padding from the input element. This is really only useful in
   * conjunction with the `asEditable` prop, to make it easier to blend the
   * input into a more complex component that requires user input.
   */
  noPadding?: boolean
  /**
   * Provides the ability to display an icon to the right of the user input.
   * Note that this icon will be overridden if the input has the
   * `aria-invalid="true"` attribute(s), as we render an error icon to the
   * right by default.
   */
  rightIcon?: React.ReactElement | null
}

/**
 * The `<Input>` component represents a typed data field, usually within a form
 * control that allows the user to enter and/or edit data.
 *
 * @example
 * <Input
 *   fullWidth
 *   leftIcon={<AtSignIcon />}
 *   placeholder="email@example.com"
 *   type="email"
 * />
 *
 * @see {@link https://html.spec.whatwg.org/multipage/input.html}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input}
 */
const Input = React.forwardRef<HTMLInputElement, Props>(function Input(
  {
    asEditable,
    fullWidth,
    leftIcon,
    noFocusRing,
    noPadding,
    rightIcon,
    ...props
  }: Props,
  ref,
): JSX.Element {
  return (
    <StyledInput
      {...props}
      data-gourmet-input=""
      {...(asEditable && { 'data-editable': '' })}
      {...(fullWidth && { 'data-full-width': '' })}
      {...(leftIcon && { 'data-left-icon': '' })}
      {...(noFocusRing && { 'data-no-focus-ring': '' })}
      {...(noPadding && { 'data-no-padding': '' })}
      {...(rightIcon && { 'data-right-icon': '' })}
      ref={ref}
      style={{
        ...getBackgroundCSSProperties({
          leftIcon,
          noPadding,
          rightIcon,
          ...props,
        }),
        ...props.style,
      }}
    />
  )
})

Input.displayName = 'Input'
Input.defaultProps = {}

export { Input, styles as InputStyles }
export type { Props as InputProps }
