import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Dataflow02IconProps = IconProps

const Dataflow02Icon = forwardRef<SVGSVGElement, Dataflow02IconProps>(
  (props: Dataflow02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4v11.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C14.28 20 15.12 20 16.8 20h.2m0 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM7 4h10M7 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm10 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm-5 8h5m0 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Dataflow02Icon.displayName = 'Dataflow02Icon'
Dataflow02Icon.defaultProps = iconDefaultProps

export type { Dataflow02IconProps }
export { Dataflow02Icon }
