import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Signal02IconProps = IconProps

const Signal02Icon = forwardRef<SVGSVGElement, Signal02IconProps>(
  (props: Signal02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.243 5.757a6 6 0 0 1 0 8.486m-8.486 0a6 6 0 0 1 0-8.486M4.93 17.071c-3.906-3.905-3.906-10.237 0-14.142m14.142 0c3.905 3.905 3.905 10.237 0 14.142M12 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 0v9"
        fill="none"
      ></path>
    </Icon>
  ),
)

Signal02Icon.displayName = 'Signal02Icon'
Signal02Icon.defaultProps = iconDefaultProps

export type { Signal02IconProps }
export { Signal02Icon }
