import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type AlignHorizontalCentre01IconProps = IconProps

const AlignHorizontalCentre01Icon = forwardRef<
  SVGSVGElement,
  AlignHorizontalCentre01IconProps
>((props: AlignHorizontalCentre01IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 3v18m10-9h-6.5m0 0 4 4m-4-4 4-4M2 12h6.5m0 0-4 4m4-4-4-4"
      fill="none"
    ></path>
  </Icon>
))

AlignHorizontalCentre01Icon.displayName = 'AlignHorizontalCentre01Icon'
AlignHorizontalCentre01Icon.defaultProps = iconDefaultProps

export type { AlignHorizontalCentre01IconProps }
export { AlignHorizontalCentre01Icon }
