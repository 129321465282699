import React from 'react'
import styled from 'styled-components'

import { GridItemCoreProps, InlineGridItemProps } from './types'
import { gridItemStyles } from './styles'
import { propsToStyledProps, PropsToStyledProps } from '../utils'

const StyledInlineGridItem = styled.span<PropsToStyledProps<GridItemCoreProps>>(
  (props) => ({
    ...gridItemStyles(props),
    display: 'inline',
  }),
)

const defaultProps: Partial<GridItemCoreProps> = {}

/**
 * The `<InlineGridItem>` component extends the `<GridItem>` component to render a
 * `<span>` element with `inline` display.
 *
 * @example
 * <InlineGrid templateRows="1fr 1fr" centerY>
 *   <GridItem>Item 1</GridItem>
 *   <GridItem>Item 2</GridItem>
 * </InlineGrid>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_gridible_box_layout}
 */
const InlineGridItem = React.forwardRef<HTMLSpanElement, InlineGridItemProps>(
  function InlineGridItem(
    {
      alignSelf,
      area,
      children,
      column,
      columnEnd,
      columnStart,
      justifySelf,
      placeSelf,
      row,
      rowEnd,
      rowStart,
      ...props
    }: InlineGridItemProps,
    ref,
  ): JSX.Element {
    return (
      <StyledInlineGridItem
        {...propsToStyledProps({
          alignSelf,
          area,
          column,
          columnEnd,
          columnStart,
          justifySelf,
          placeSelf,
          row,
          rowEnd,
          rowStart,
        })}
        {...props}
        data-gourmet-grid-item=""
        data-gourmet-inline-grid-item=""
        ref={ref}
      >
        {children}
      </StyledInlineGridItem>
    )
  },
)

InlineGridItem.displayName = 'InlineGridItem'
InlineGridItem.defaultProps = defaultProps

export { InlineGridItem, defaultProps as inlineGridItemDefaultProps }
