import React from 'react'

import { Copy05Icon } from '@vori/gourmet-icons'

import Tooltip from '../Tooltip'
import { Button } from '../ButtonNext'
import { composeEventHandlers } from '../utils'

import {
  CopyToClipboardButtonCoreProps,
  CopyToClipboardButtonProps,
} from './types'

import {
  DEFAULT_ARIA_LABEL,
  DEFAULT_COPIED_ARIA_LABEL,
  DEFAULT_COPIED_TOOLTIP_LABEL,
  DEFAULT_RESET_TIMEOUT,
  DEFAULT_TOOLTIP_LABEL,
} from './constants'

const defaultProps: Partial<CopyToClipboardButtonCoreProps> = {
  'aria-label': DEFAULT_ARIA_LABEL,
  asIconButton: true,
  copiedAriaLabel: DEFAULT_COPIED_ARIA_LABEL,
  copiedTooltipLabel: DEFAULT_COPIED_TOOLTIP_LABEL,
  defaultAriaLabel: DEFAULT_ARIA_LABEL,
  defaultTooltipLabel: DEFAULT_TOOLTIP_LABEL,
  leftIcon: <Copy05Icon />,
  withoutTooltip: false,
}

/**
 * The `<CopyToClipboardButton>` component provides the user with the option to
 * copy a specific piece of text onto their clipboards.
 *
 * @example
 * <CopyToClipboardButton
 *   clipboardText={product.id}
 *   defaultTooltipLabel="Copy product's ID"
 *   copiedTooltipLabel="Product ID copied!"
 * />
 */
const CopyToClipboardButton = React.forwardRef<
  HTMLButtonElement,
  CopyToClipboardButtonProps
>(function CopyToClipboardButton(
  {
    children,
    clipboardText,
    copiedAriaLabel = DEFAULT_COPIED_ARIA_LABEL,
    copiedTooltipLabel = DEFAULT_COPIED_TOOLTIP_LABEL,
    defaultAriaLabel = DEFAULT_ARIA_LABEL,
    defaultTooltipLabel = DEFAULT_COPIED_TOOLTIP_LABEL,
    onClipboardWriteFail,
    onClipboardWriteSuccess,
    resetTimeout = DEFAULT_RESET_TIMEOUT,
    withoutTooltip,
    ...props
  }: CopyToClipboardButtonProps,
  ref,
): JSX.Element {
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  const [wasCopied, setWasCopied] = React.useState(false)

  React.useEffect(() => {
    const timeout = timeoutRef.current

    if (wasCopied && timeoutRef.current === null) {
      timeoutRef.current = setTimeout(() => {
        setWasCopied(false)
      }, resetTimeout)
    }

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout)
        timeoutRef.current = null
      }
    }
  }, [resetTimeout, wasCopied])

  const ButtonComponent = React.useMemo(
    () => (
      <Button
        {...props}
        aria-label={wasCopied ? copiedAriaLabel : defaultAriaLabel}
        asIconButton={children == null || typeof children !== 'function'}
        leftIcon={props.leftIcon || <Copy05Icon />}
        ref={ref}
        onClick={composeEventHandlers(props.onClick, () => {
          navigator.clipboard.writeText(clipboardText).then(() => {
            if (timeoutRef.current !== null) {
              clearTimeout(timeoutRef.current)
              timeoutRef.current = null
            }

            setWasCopied(true)
            onClipboardWriteSuccess?.()
          }, onClipboardWriteFail)
        })}
      >
        {children != null && typeof children === 'function'
          ? children(wasCopied)
          : null}
      </Button>
    ),
    [
      children,
      clipboardText,
      copiedAriaLabel,
      defaultAriaLabel,
      onClipboardWriteFail,
      onClipboardWriteSuccess,
      props,
      ref,
      wasCopied,
    ],
  )

  return withoutTooltip ? (
    ButtonComponent
  ) : (
    <Tooltip
      label={wasCopied ? copiedTooltipLabel : defaultTooltipLabel}
      isVisible={wasCopied}
    >
      {ButtonComponent}
    </Tooltip>
  )
})

CopyToClipboardButton.displayName = 'CopyToClipboardButton'
CopyToClipboardButton.defaultProps = defaultProps

export {
  CopyToClipboardButton,
  defaultProps as copyToClipboardButtonDefaultProps,
}
