import React from 'react'
import styled, { CSSObject } from 'styled-components'

function styles(): CSSObject {
  return {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    pointerEvents: 'none',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1,
    wordWrap: 'normal',
  }
}

const StyledVisuallyHidden = styled.span(styles)

type Props = React.HTMLAttributes<HTMLSpanElement>

/**
 * Provides a way to visually hide content, which is useful for 2 main reasons:
 *
 * 1. Make content, specifically text, only accessible to screen
 * readers; Basically the opposite of the `aria-hidden` attribute.
 * 2. Hide form inputs without using the `type="hidden"` attribute, given that
 * `<input type="hidden" />` elements are not bound by the Constraint Validation API.
 *
 * @example
 * <Flex column fullWidth>
 *   <VisuallyHidden>This is a description for screen readers</VisuallyHidden>
 *   <Button iconOnly leftIcon={<AddIcon />} />
 * </Flex>
 *
 * <FormField label="Name" description="Please provide us with your full name">
 *   <VisuallyHidden>
 *     <input onChange={setValue} value={controlledValue} />
 *   </VisuallyHidden>
 *   <SomeCustomFormComponent onSelection={setSelection} />
 * </FormField>
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-hidden}
 * @see {@link https://www.a11yproject.com/posts/how-to-hide-content/}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/hidden#validation}
 * @see {@link https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe}
 */
const VisuallyHidden = React.forwardRef<
  HTMLSpanElement,
  React.PropsWithChildren<Props>
>(function Divider(
  { children, ...props }: React.PropsWithChildren<Props>,
  ref,
) {
  return (
    <StyledVisuallyHidden {...props} data-gourmet-visually-hidden="" ref={ref}>
      {children}
    </StyledVisuallyHidden>
  )
})

VisuallyHidden.displayName = 'VisuallyHidden'
VisuallyHidden.defaultProps = {}

export type { Props as VisuallyHiddenProps }
export { VisuallyHidden, styles as VisuallyHiddenStyles }
