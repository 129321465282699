import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type XIconProps = IconProps

const XIcon = forwardRef<SVGSVGElement, XIconProps>(
  (props: XIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 7 7 17M7 7l10 10"
        fill="none"
      ></path>
    </Icon>
  ),
)

XIcon.displayName = 'XIcon'
XIcon.defaultProps = iconDefaultProps

export type { XIconProps }
export { XIcon }
