import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Coins02IconProps = IconProps

const Coins02Icon = forwardRef<SVGSVGElement, Coins02IconProps>(
  (props: Coins02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.938 15.938A7.001 7.001 0 0 0 15 2a7.001 7.001 0 0 0-6.938 6.062M7.5 13 9 12v5.5m-1.5 0h3M16 15a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Coins02Icon.displayName = 'Coins02Icon'
Coins02Icon.defaultProps = iconDefaultProps

export type { Coins02IconProps }
export { Coins02Icon }
