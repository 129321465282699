import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import styled, { CSSObject } from 'styled-components'

import { Toast, ToastProps } from '../Toast'
import { toRem } from '../utils'

function styles(): CSSObject {
  return {
    backgroundColor: 'transparent',
    borderWidth: 0,
    bottom: toRem(20),
    padding: 0,
    top: 'auto',

    '&::backdrop': {
      backgroundColor: 'transparent',
      pointerEvents: 'none',
    },
  }
}

const StyledDialog = styled.dialog(styles)

const TOAST_TIMEOUT = 2500 as const

interface Options {
  /**
   * Used when displaying toast notifications while a dialog is opened as a
   * modal. This will ensure that the toast notification is rendered on the top
   * layer, thus appearing on top of the modal.
   *
   * @see {@link https://developer.chrome.com/blog/what-is-the-top-layer/}
   */
  asModal?: boolean
  message: React.ReactNode
  notistackOptions?: Omit<OptionsObject, 'variant'>
  variant?: ToastProps['variant']
}

interface HookReturn {
  hide: (key?: SnackbarKey) => void
  show: (message?: React.ReactNode) => SnackbarKey
}

function useToast(options: Options): HookReturn {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  const hide = useCallback<HookReturn['hide']>(
    (key?: SnackbarKey) => {
      closeSnackbar(key)
    },
    [closeSnackbar],
  )

  const show = useCallback<HookReturn['show']>(
    (message) =>
      enqueueSnackbar(options.message, {
        autoHideDuration:
          options.notistackOptions?.autoHideDuration || TOAST_TIMEOUT,
        content: function ToastContainer(key, defaultMessage) {
          return options.asModal ? (
            <StyledDialog
              ref={(dialog) => {
                if (dialog && !dialog.open) {
                  dialog.showModal()
                }
              }}
            >
              <Toast key={key} variant={options.variant || 'default'}>
                {message || defaultMessage}
              </Toast>
            </StyledDialog>
          ) : (
            <Toast key={key} variant={options.variant || 'default'}>
              {message || defaultMessage}
            </Toast>
          )
        },
        preventDuplicate: true,
      }),
    [
      enqueueSnackbar,
      options.asModal,
      options.message,
      options.notistackOptions?.autoHideDuration,
      options.variant,
    ],
  )

  return { hide, show }
}

export default useToast
