import React from 'react'
import styled from 'styled-components'

import { CardCoreProps, CardProps } from './types'
import { cardStyles } from './styles'
import { Flex, flexDefaultProps } from '../FlexNext'

const StyledCard = styled(Flex)(cardStyles)

const defaultProps: Partial<CardCoreProps> = {
  ...flexDefaultProps,
  asFlat: false,
  variant: 'default',
}

/**
 * The `<Card>` component is used to group similar concepts and tasks together
 * for users to scan, read, and get things done. It displays content in a
 * familiar and recognizable style.
 *
 * Cards extend the `<Flex>` layout component, so all props available to `<Flex>`
 * are also available to `<Card>`. Note that by default, the `direction` of cards
 * is `column`.
 *
 * @extends Flex
 * @example
 * <Card fullWidth>
 *   <Text>Row 1</Text>
 *   <Text>Row 2</Text>
 * </Card>
 */
const Card = React.forwardRef<HTMLDivElement, CardProps>(function Card(
  { asFlat, children, variant = 'default', ...props }: CardProps,
  ref,
): JSX.Element {
  return (
    <StyledCard
      direction="column"
      {...props}
      {...(asFlat && { 'data-as-flat': '' })}
      data-gourmet-card=""
      data-variant={variant}
      ref={ref}
    >
      {children}
    </StyledCard>
  )
})

Card.displayName = 'Card'
Card.defaultProps = defaultProps

export { Card, defaultProps as cardDefaultProps }
