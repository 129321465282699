import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Tool01IconProps = IconProps

const Tool01Icon = forwardRef<SVGSVGElement, Tool01IconProps>(
  (props: Tool01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.631 7.631c-.396-.396-.594-.594-.668-.822a1 1 0 0 1 0-.618c.074-.228.272-.426.668-.822L18.47 2.53a6 6 0 0 0-8.3 6.895c.12.49.179.734.168.888a.849.849 0 0 1-.11.392c-.07.138-.207.274-.48.547L3.5 17.5a2.121 2.121 0 0 0 3 3l6.248-6.248c.273-.273.41-.41.547-.48a.849.849 0 0 1 .392-.11c.154-.011.399.049.888.168a6 6 0 0 0 6.895-8.3l-2.84 2.84c-.396.396-.594.594-.822.668a1 1 0 0 1-.618 0c-.228-.074-.426-.272-.822-.668l-.738-.738Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Tool01Icon.displayName = 'Tool01Icon'
Tool01Icon.defaultProps = iconDefaultProps

export type { Tool01IconProps }
export { Tool01Icon }
