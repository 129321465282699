import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type EqualNotIconProps = IconProps

const EqualNotIcon = forwardRef<SVGSVGElement, EqualNotIconProps>(
  (props: EqualNotIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 9h14M5 15h14m0-10L5 19"
        fill="none"
      ></path>
    </Icon>
  ),
)

EqualNotIcon.displayName = 'EqualNotIcon'
EqualNotIcon.defaultProps = iconDefaultProps

export type { EqualNotIconProps }
export { EqualNotIcon }
