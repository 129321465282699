import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MicrophoneOff02IconProps = IconProps

const MicrophoneOff02Icon = forwardRef<SVGSVGElement, MicrophoneOff02IconProps>(
  (props: MicrophoneOff02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 12v1a8 8 0 0 0 14.138 5.132M2 2l20 20m-6-11.6V7a4 4 0 0 0-6.53-3.1M12 17a4 4 0 0 1-4-4V8l7.281 7.288A3.995 3.995 0 0 1 12 17Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MicrophoneOff02Icon.displayName = 'MicrophoneOff02Icon'
MicrophoneOff02Icon.defaultProps = iconDefaultProps

export type { MicrophoneOff02IconProps }
export { MicrophoneOff02Icon }
