import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ClockIconProps = IconProps

const ClockIcon = forwardRef<SVGSVGElement, ClockIconProps>(
  (props: ClockIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6v6l4 2m6-2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

ClockIcon.displayName = 'ClockIcon'
ClockIcon.defaultProps = iconDefaultProps

export type { ClockIconProps }
export { ClockIcon }
