import { ClockRewindIcon, Trash01Icon } from '@vori/gourmet-icons'
import React from 'react'
import { ProductsSlice } from 'web/dashboard/src/state-management/ProductStore'
import { useGlobalStore } from 'web/dashboard/src/state-management/store'
import styled from 'styled-components'
import { base } from '@vori/gourmet-components'

const RecentSerachContainer = styled.div({
  color: base.colors.dark2,
  'max-height': 'calc(var(--containerHeight) - var(--headerHeight))',
  '> :first-child': {
    padding: '5px',
  },
})

const SearchItem = styled.div({
  display: 'grid',
  'grid-template-columns': '20px 1fr',
  gap: '5px',
  padding: '0.5rem',
  color: base.colors.dark1,
  cursor: 'pointer',
  'line-height': '16px',
  'border-radius': '0.5rem',
  ':hover': {
    'background-color': base.colors.light2,
  },
  svg: {
    color: base.colors.dark3,
  },
})

const ScrollArea = styled.div({
  'overflow-y': 'scroll',
  'max-height': 'calc(var(--containerHeight) - 80px)',
})

type IncomingProps = {
  redoPastSearch: null | ((value: string) => void)
}
const RecentProductSearches = ({ redoPastSearch }: IncomingProps) => {
  const searchHistory = useGlobalStore(
    (state: ProductsSlice) => state.searchHistory,
  )

  const clearSearchHistory = useGlobalStore(
    (state: ProductsSlice) => state.clearSearchHistory,
  )

  const handleRedoSearch = (event: React.MouseEvent<HTMLDivElement>) => {
    const trueTarget = event.target as HTMLElement
    if (redoPastSearch && trueTarget.dataset.value) {
      redoPastSearch(trueTarget.dataset.value)
    }
  }

  if (!searchHistory.length) {
    return (
      <RecentSerachContainer>
        <span>No recent searches</span>
      </RecentSerachContainer>
    )
  }
  return (
    <RecentSerachContainer>
      <div>Recent</div>
      <ScrollArea>
        {[...searchHistory].map((searchTerm, idx) => (
          <SearchItem
            key={`${searchTerm}-${idx}`}
            data-value={searchTerm}
            onClick={handleRedoSearch}
          >
            <ClockRewindIcon /> {searchTerm}
          </SearchItem>
        ))}
        <SearchItem onClick={clearSearchHistory}>
          <Trash01Icon /> Clear search history
        </SearchItem>
      </ScrollArea>
    </RecentSerachContainer>
  )
}

export default RecentProductSearches
