import React from 'react'

import { composeEventHandlers } from '../utils'
import { NumberRangeInputProps } from './types'
import { RangeInput } from '../RangeInput'
import { validateNumberRangeValue } from './utils'

/**
/**
 * The `<NumberRangeInput>` component enables the user to enter a range number value.
 *
 * @example
 * <FormField
 *   description="Please enter an percentage range"
 *   label="Filter by percentage"
 * >
 *   <NumberRangeInput />
 * </FormField>
 */
const NumberRangeInput = React.forwardRef<
  HTMLInputElement,
  NumberRangeInputProps
>(function NumberRangeInput(
  { skipDefaultValidation, parseInputValue, ...props }: NumberRangeInputProps,
  ref,
): JSX.Element {
  const [[, inputValidationProps], setValidation] = React.useState(() =>
    props.value
      ? validateNumberRangeValue(props.value, parseInputValue)
      : [null, { min: {}, max: {} }],
  )

  const maxValueInputProps = React.useMemo(
    () => ({
      ...props.maxValueInputProps,
      ...(!skipDefaultValidation ? inputValidationProps.max : {}),
      type: 'number',
    }),
    [inputValidationProps.max, props.maxValueInputProps, skipDefaultValidation],
  )

  const minValueInputProps = React.useMemo(
    () => ({
      ...props.minValueInputProps,
      ...(!skipDefaultValidation ? inputValidationProps.min : {}),
      type: 'number',
    }),
    [inputValidationProps.min, props.minValueInputProps, skipDefaultValidation],
  )

  return (
    <RangeInput
      {...props}
      onChange={composeEventHandlers(props.onChange, (event) => {
        if (!skipDefaultValidation) {
          setValidation(
            validateNumberRangeValue(event.target.value, parseInputValue),
          )
        }
      })}
      maxValueInputProps={maxValueInputProps}
      minValueInputProps={minValueInputProps}
      renderMaxInput={
        props.renderMaxInput != null
          ? (inputProps) =>
              props.renderMaxInput?.({
                ...inputProps,
                ...maxValueInputProps,
                onChange: composeEventHandlers(
                  inputProps.onChange,
                  maxValueInputProps.onChange,
                ),
              })
          : undefined
      }
      renderMinInput={
        props.renderMinInput != null
          ? (inputProps) =>
              props.renderMinInput?.({
                ...inputProps,
                ...minValueInputProps,
                onChange: composeEventHandlers(
                  inputProps.onChange,
                  minValueInputProps.onChange,
                ),
              })
          : undefined
      }
      ref={ref}
    />
  )
})

NumberRangeInput.displayName = 'NumberRangeInput'
NumberRangeInput.defaultProps = {}

export { NumberRangeInput }
