import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Download03IconProps = IconProps

const Download03Icon = forwardRef<SVGSVGElement, Download03IconProps>(
  (props: Download03IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8 12 4 4m0 0 4-4m-4 4V8m10 4c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Download03Icon.displayName = 'Download03Icon'
Download03Icon.defaultProps = iconDefaultProps

export type { Download03IconProps }
export { Download03Icon }
