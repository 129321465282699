import { useCallback, useMemo, useState } from 'react'
import Fuse from 'fuse.js'

import useDebouncedValue from './useDebouncedValue'

interface HookOptions<TData> {
  /**
   * Array of data used to perform fuzzy search over.
   */
  data: TData[]
  /**
   * Fuse.js options to pass to the search index.
   */
  options: Fuse.IFuseOptions<TData>
  /**
   * An optional delay to perform the search.
   */
  debounce: number
}

interface HookReturn<TData> {
  /**
   * The keyword used to search the given `data` array.
   */
  keyword: string
  /**
   * Function used to reset the search results.
   */
  reset: () => void
  /**
   * Resulsts retuned by Fuse based on the given `keyword`.
   */
  results: Fuse.FuseResult<TData>[]
  /**
   * Function used to execute the search on the `data` array using the given `keyword`.
   */
  search: (keyword: string) => void
}

/**
 * Performs fuzzy search on the given `data` array using the `fuse.js` library.
 *
 * {@link https://fusejs.io}
 */
function useFuzzySearch<TData>(
  data: HookOptions<TData>['data'],
  options: HookOptions<TData>['options'] = {},
  debounce: HookOptions<TData>['debounce'] = 0,
): HookReturn<TData> {
  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useDebouncedValue(keyword, debounce)

  const reset = useCallback(() => {
    setKeyword('')
  }, [setKeyword])

  const fuseIndex = useMemo(
    () => new Fuse(data, { threshold: 0.25, ...options }),
    [data, options],
  )

  return {
    keyword: debouncedKeyword,
    reset,
    results: debouncedKeyword
      ? fuseIndex.search(debouncedKeyword)
      : data.map((item, refIndex) => ({
          item,
          refIndex,
          score: 1,
        })),
    search: setKeyword,
  }
}

export type { HookOptions, HookReturn }
export default useFuzzySearch
