import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Target05IconProps = IconProps

const Target05Icon = forwardRef<SVGSVGElement, Target05IconProps>(
  (props: Target05IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
        fill="none"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Target05Icon.displayName = 'Target05Icon'
Target05Icon.defaultProps = iconDefaultProps

export type { Target05IconProps }
export { Target05Icon }
