import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Hash01IconProps = IconProps

const Hash01Icon = forwardRef<SVGSVGElement, Hash01IconProps>(
  (props: Hash01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 8h16M4 16h16M8 3v18m8-18v18"
        fill="none"
      ></path>
    </Icon>
  ),
)

Hash01Icon.displayName = 'Hash01Icon'
Hash01Icon.defaultProps = iconDefaultProps

export type { Hash01IconProps }
export { Hash01Icon }
