import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LogOut04IconProps = IconProps

const LogOut04Icon = forwardRef<SVGSVGElement, LogOut04IconProps>(
  (props: LogOut04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18 8 4 4m0 0-4 4m4-4H9m6-7.796A8.383 8.383 0 0 0 10.667 3C5.88 3 2 7.03 2 12s3.88 9 8.667 9A8.384 8.384 0 0 0 15 19.796"
        fill="none"
      ></path>
    </Icon>
  ),
)

LogOut04Icon.displayName = 'LogOut04Icon'
LogOut04Icon.defaultProps = iconDefaultProps

export type { LogOut04IconProps }
export { LogOut04Icon }
