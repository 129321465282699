import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UserDown01IconProps = IconProps

const UserDown01Icon = forwardRef<SVGSVGElement, UserDown01IconProps>(
  (props: UserDown01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21m14-3 3 3m0 0 3-3m-3 3v-6m-4.5-7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

UserDown01Icon.displayName = 'UserDown01Icon'
UserDown01Icon.defaultProps = iconDefaultProps

export type { UserDown01IconProps }
export { UserDown01Icon }
