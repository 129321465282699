import { UserType } from '@vori/gql-dashboard'
import { createRouteUtil } from '@vori/dashboard-utils'

import { routes as devRoutes } from './dev/routes'
import { routes as vendorRoutes } from './vendor/routes'

const paymentRoutes = {
  payments: {
    /**
     * Displays the current user's payments information.
     */
    root: createRouteUtil('/payments', 'Payments'),

    /**
     * Displays the current user's payments profile.
     */
    profile: createRouteUtil('/payments/profile', 'Payments Profile'),

    /**
     * Displays the payments profile form for authenticated users.
     */
    createProfile: createRouteUtil(
      '/payments/profile/new',
      'Create Payments Profile',
    ),
    /**
     * Displays the payments profile form for new unauthenticated users.
     */
    createProfileFromJWT: createRouteUtil(
      '/payments/profile/new',
      'Create Payments Profile',
    ),
  },
}

type SharedRoutes<T extends UserType> = {
  products: T extends UserType.VendorAdmin
    ? (typeof vendorRoutes)['products']
    : T extends UserType.VoriAdmin
      ? (typeof devRoutes)['products']
      : null
  payments: (typeof paymentRoutes)['payments']
}

/**
 * These routes are shared through different user roles even though the URLs
 * might be different.
 */
function sharedRoutes<T extends UserType>(
  userRole?: T | null,
): SharedRoutes<T> {
  return {
    ...paymentRoutes,
    products:
      userRole === UserType.VendorAdmin
        ? vendorRoutes.products
        : userRole === UserType.VoriAdmin
          ? devRoutes.products
          : null,
  } as SharedRoutes<T>
}

export { sharedRoutes }
