import truncate from 'lodash/truncate'

import {
  isSameMonth,
  isSameYear,
  isThisYear,
  isToday,
  isTomorrow,
} from 'date-fns'

const SELECT_CHAR_LIMIT = 16 as const

export function getRangeInputLabel(value: string | null): string {
  if (!value) {
    return ''
  }

  const valueSplit = value.split(',')

  if (valueSplit[0] && !valueSplit[1]) {
    return `< ${valueSplit[0]}`
  } else if (valueSplit[1] && !valueSplit[0]) {
    return `> ${valueSplit[1]}`
  } else if (valueSplit[0] && valueSplit[1]) {
    return `${valueSplit[0]} - ${valueSplit[1]}`
  }

  return ''
}

export function getSelectLabel(value: string | null): string {
  if (!value) {
    return ''
  }

  return truncate(value, { length: SELECT_CHAR_LIMIT })
}

export function getMultiSelectLabel(value: string | null): string {
  if (!value) {
    return ''
  }

  return truncate(value, { length: SELECT_CHAR_LIMIT })
}

export function getDateLabel(value: Date, withTime?: boolean): string {
  if (isToday(value)) {
    return 'Today'
  } else if (isTomorrow(value)) {
    return 'Tomorrow'
  } else {
    return new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      ...(isThisYear(value) && { year: 'numeric' }),
      ...(withTime && { minute: '2-digit', hour: '2-digit' }),
    }).format(value)
  }
}

export function getDateRangeLabel(
  values: Array<Date>,
  withTime?: boolean,
): string {
  if (values.length === 1) {
    return getDateLabel(values[0], withTime)
  }

  const getTime = (dateTimeA: Date, dateTimeB: Date) =>
    Array.from(
      new Set([
        new Intl.DateTimeFormat('en-US', {
          minute: '2-digit',
          hour: '2-digit',
        }).format(dateTimeA),
        new Intl.DateTimeFormat('en-US', {
          minute: '2-digit',
          hour: '2-digit',
        }).format(dateTimeB),
      ]),
    ).join(' - ')

  if (isSameMonth(values[0], values[1]) && isSameYear(values[0], values[1])) {
    return [
      `${new Intl.DateTimeFormat('en-US', {
        month: 'short',
      }).format(values[0])} ${new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
      }).format(values[0])} - ${new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
      }).format(values[1])}`,
      withTime ? getTime(values[0], values[1]) : null,
    ]
      .filter(Boolean)
      .join(', ')
  } else if (isSameYear(values[0], values[1])) {
    return [
      `${new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
      }).format(values[0])} - ${new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
      }).format(values[1])}`,
      withTime ? getTime(values[0], values[1]) : null,
    ]
      .filter(Boolean)
      .join(', ')
  } else {
    return [
      `${new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).format(values[0])} - ${new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).format(values[1])}`,
      withTime ? getTime(values[0], values[1]) : null,
    ]
      .filter(Boolean)
      .join(', ')
  }
}
