import React from 'react'

import { FormFieldContext } from './FormFieldContext'

type InputProps = {
  [key in string]: unknown
} & React.HTMLAttributes<HTMLInputElement>

interface Props {
  children:
    | React.ReactNode
    | ((inputProps: InputProps, labelID: string) => React.ReactNode)
}

/**
 * This component dynamically assigns an `id` attribute to its `children`.
 * This `id` will be automatically associated to the parent's (`<FormField>`)
 * `<FormFieldLabel>` component through the `for` attribute.
 *
 * You only need to use this component directly when rendering multiple
 * components inside the `<FormField>` to correctly pass the `id` and other
 * accessability attributes (`aria-labelledby`, `aria-errormessage`,
 * `aria-invalid`) to the right element.
 *
 * @example
 * // No need to use it here
 * function Field() {
 *   return (
 *     <FormField label="Full name">
 *       <Input />
 *     </FormField>
 *   )
 * }
 *
 * // You need to use it here to properly pass accessibility attributes to the
 * // `<Input />` component
 * function Field() {
 *   return (
 *     <FormField label="Full name">
 *       <FormFieldInput>
 *         <Input />
 *       </FormFieldInput>
 *       <Button>Reset</Button>
 *     </FormField>
 *   )
 * }
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#attr-for}
 */
function FormFieldInput({ children }: Props): React.ReactElement | null {
  const { descriptionID, errorID, inputID, labelID } =
    React.useContext(FormFieldContext)

  const inputProps: InputProps = {
    ...(!errorID && descriptionID && { 'aria-describedby': descriptionID }),
    ...(errorID && { 'aria-errormessage': errorID, 'aria-invalid': 'true' }),
    'aria-labelledby': labelID,
    'data-gourmet-formfield-input': '',
    id: inputID,
  }

  if (!React.isValidElement(children) && typeof children === 'function') {
    return children(inputProps, labelID)
  }

  const child = React.Children.only(children)

  const element = React.isValidElement(child)
    ? React.cloneElement(child, inputProps)
    : null

  return element
}

FormFieldInput.displayName = 'FormFieldInput'
FormFieldInput.defaultProps = {}

export { FormFieldInput }
