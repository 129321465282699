import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Strikethrough02IconProps = IconProps

const Strikethrough02Icon = forwardRef<SVGSVGElement, Strikethrough02IconProps>(
  (props: Strikethrough02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 16a4 4 0 0 0 4 4h4a4 4 0 0 0 0-8m-3.5 8a4 4 0 0 0 0-8M18 8a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4m7.5-4a4 4 0 0 0-4 4M3 12h18"
        fill="none"
      ></path>
    </Icon>
  ),
)

Strikethrough02Icon.displayName = 'Strikethrough02Icon'
Strikethrough02Icon.defaultProps = iconDefaultProps

export type { Strikethrough02IconProps }
export { Strikethrough02Icon }
