import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Disc02IconProps = IconProps

const Disc02Icon = forwardRef<SVGSVGElement, Disc02IconProps>(
  (props: Disc02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 5.674A7 7 0 0 1 19 12M8.392 18A6.996 6.996 0 0 1 5 12m17 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-7 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Disc02Icon.displayName = 'Disc02Icon'
Disc02Icon.defaultProps = iconDefaultProps

export type { Disc02IconProps }
export { Disc02Icon }
