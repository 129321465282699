import { useFullStory } from './FullStory/useFullStory'
import { useIntercom } from './Intercom/useIntercom'
import { useOpenTelemetry } from './OpenTelemetry/useOpenTelemetry'
import { useSegment } from './Segment'
import { useSentry } from './Sentry/useSentry'

export function useIntegrations(): void {
  useSentry()
  useSegment()
  useFullStory()
  useIntercom()
  useOpenTelemetry()
}
