import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MessageTextSquare01IconProps = IconProps

const MessageTextSquare01Icon = forwardRef<
  SVGSVGElement,
  MessageTextSquare01IconProps
>((props: MessageTextSquare01IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 8.5h5M7 12h8m-5.316 6H16.2c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 15.72 21 14.88 21 13.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 3 17.88 3 16.2 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.28 3 6.12 3 7.8v12.535c0 .533 0 .8.11.937a.5.5 0 0 0 .39.188c.176 0 .384-.167.8-.5l2.385-1.908c.488-.39.731-.585 1.002-.724.241-.122.497-.212.762-.267C8.748 18 9.06 18 9.684 18Z"
      fill="none"
    ></path>
  </Icon>
))

MessageTextSquare01Icon.displayName = 'MessageTextSquare01Icon'
MessageTextSquare01Icon.defaultProps = iconDefaultProps

export type { MessageTextSquare01IconProps }
export { MessageTextSquare01Icon }
