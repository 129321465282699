import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Loading02IconProps = IconProps

const Loading02Icon = forwardRef<SVGSVGElement, Loading02IconProps>(
  (props: Loading02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2v4m0 12v4M6 12H2m20 0h-4m1.078 7.078L16.25 16.25M19.078 5 16.25 7.828M4.922 19.078 7.75 16.25M4.922 5 7.75 7.828"
        fill="none"
      ></path>
    </Icon>
  ),
)

Loading02Icon.displayName = 'Loading02Icon'
Loading02Icon.defaultProps = iconDefaultProps

export type { Loading02IconProps }
export { Loading02Icon }
