import React from 'react'

import { CardBannerTextCoreProps, CardBannerTextProps } from './types'
import { Text, textDefaultProps } from '../TextNext'

const { variant, ...textDefaultPropsWithoutVariant } = textDefaultProps

const defaultProps: Partial<CardBannerTextCoreProps> =
  textDefaultPropsWithoutVariant

/**
 * The `<CardBannerText>` component extends the `<Text>` component to
 * provide specific styling when used within the `<CardBanner>` component.
 *
 * @extends Text
 * @example
 * <Card asFlat fullWidth>
 *   <CardBanner>
 *     <CardBannerText>Review changes</CardBannerText>
 *   </CardBanner>
 * </Card>
 */
const CardBannerText = React.forwardRef<HTMLSpanElement, CardBannerTextProps>(
  function CardBannerText(
    { children, variant = 'default', ...props }: CardBannerTextProps,
    ref,
  ): JSX.Element {
    return (
      <Text
        {...props}
        data-gourmet-card-banner-text=""
        data-variant={variant}
        ref={ref}
        size="text-sm"
        weight="medium"
      >
        {children}
      </Text>
    )
  },
)

CardBannerText.displayName = 'CardBannerText'
CardBannerText.defaultProps = defaultProps

export { CardBannerText, defaultProps as cardBannerTextDefaultProps }
