import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { getInitialsFromDisplayName } from '../utils'

import Avatar from '../../Avatar'
import Flex from '../../Flex'
import Spacer from '../../Spacer'
import Text from '../../Text'

type AppSidebarUserProps = React.HTMLAttributes<HTMLDivElement> & {
  companyName?: string
  displayName?: string
  imgURL?: string
}

const defaultProps: Partial<AppSidebarUserProps> = {
  className: '',
}

const StyledAppSidebarUser = styled(
  forwardRef<HTMLDivElement, AppSidebarUserProps>(function AppSidebarUser(
    { companyName, displayName, imgURL, ...props }: AppSidebarUserProps,
    ref,
  ) {
    return (
      <Flex {...props} centerY fullWidth ref={ref}>
        <Avatar
          alt="Your avatar image"
          src={imgURL}
          withInitials={
            !imgURL && displayName
              ? getInitialsFromDisplayName(displayName)
              : null
          }
        />
        <Flex className="gourmet-appsidebar__toggle">
          <Spacer inline size="medium" />
          <Flex column>
            <Text size="h4">{displayName || 'You'}</Text>
            {companyName != null && companyName !== '' && (
              <Text size="body" variant="secondary">
                {companyName}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    )
  }),
)<AppSidebarUserProps>``

StyledAppSidebarUser.displayName = 'AppSidebarUser'
StyledAppSidebarUser.defaultProps = defaultProps

export type { AppSidebarUserProps }
export { defaultProps as AppSidebarUserDefaultProps }
export default StyledAppSidebarUser
