import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Snowflake01IconProps = IconProps

const Snowflake01Icon = forwardRef<SVGSVGElement, Snowflake01IconProps>(
  (props: Snowflake01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18.062 8.5-12.124 7m12.124-7 1.098-4.098M18.062 8.5l4.098 1.098M5.938 15.5 1.84 14.402M5.938 15.5 4.84 19.598M18.062 15.5l-12.124-7m12.124 7 4.098-1.098M18.062 15.5l1.098 4.098M5.938 8.5 4.84 4.402M5.938 8.5 1.84 9.598M12 5v14m0-14L9 2m3 3 3-3m-3 17-3 3m3-3 3 3"
        fill="none"
      ></path>
    </Icon>
  ),
)

Snowflake01Icon.displayName = 'Snowflake01Icon'
Snowflake01Icon.defaultProps = iconDefaultProps

export type { Snowflake01IconProps }
export { Snowflake01Icon }
