import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Link01IconProps = IconProps

const Link01Icon = forwardRef<SVGSVGElement, Link01IconProps>(
  (props: Link01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m12.708 18.364-1.415 1.414a5 5 0 1 1-7.07-7.07l1.413-1.415m12.728 1.414 1.415-1.414a5 5 0 0 0-7.071-7.071l-1.415 1.414M8.5 15.5l7-7"
        fill="none"
      ></path>
    </Icon>
  ),
)

Link01Icon.displayName = 'Link01Icon'
Link01Icon.defaultProps = iconDefaultProps

export type { Link01IconProps }
export { Link01Icon }
