import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SlashCircle02IconProps = IconProps

const SlashCircle02Icon = forwardRef<SVGSVGElement, SlashCircle02IconProps>(
  (props: SlashCircle02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m4.93 4.93 14.14 14.14m0-14.14L4.93 19.07M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

SlashCircle02Icon.displayName = 'SlashCircle02Icon'
SlashCircle02Icon.defaultProps = iconDefaultProps

export type { SlashCircle02IconProps }
export { SlashCircle02Icon }
