import React from 'react'

import { Menu as ReachMenu, MenuProps } from '@reach/menu-button'

/**
 * The `<Menu>` component offers a list of choices to the user, such as a set of
 * actions or functions. Menu widgets behave like native operating system menus,
 * such as the menus that pull down from the menubars commonly found at the top
 * of many desktop application windows.
 *
 * @example
 * <Menu>
 *   <MenuButton>Actions</MenuButton>
 *   <MenuList>
 *     <MenuItem onSelect={() => alert("Export")}>Export</MenuItem>
 *     <MenuLink to={routes.home.path}>Home</MenuLink>
 *   </MenuList>
 * </Menu>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/menubar/}
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/}
 */
function Menu({ children }: MenuProps): JSX.Element {
  return <ReachMenu data-gourmet-menu="">{children}</ReachMenu>
}

Menu.displayName = 'Menu'

export { Menu }
export type { MenuProps }
