import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SearchMdIconProps = IconProps

const SearchMdIcon = forwardRef<SVGSVGElement, SearchMdIconProps>(
  (props: SearchMdIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m21 21-4.35-4.35M19 11a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

SearchMdIcon.displayName = 'SearchMdIcon'
SearchMdIcon.defaultProps = iconDefaultProps

export type { SearchMdIconProps }
export { SearchMdIcon }
