import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Underline02IconProps = IconProps

const Underline02Icon = forwardRef<SVGSVGElement, Underline02IconProps>(
  (props: Underline02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 4v6a7 7 0 1 1-14 0V4m3.5 0v6a7.003 7.003 0 0 0 5.14 6.75M4 21h16M3 4h7.5M17 4h4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Underline02Icon.displayName = 'Underline02Icon'
Underline02Icon.defaultProps = iconDefaultProps

export type { Underline02IconProps }
export { Underline02Icon }
