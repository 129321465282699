import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Toggle02RightIconProps = IconProps

const Toggle02RightIcon = forwardRef<SVGSVGElement, Toggle02RightIconProps>(
  (props: Toggle02RightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 16H6a4 4 0 0 1 0-8h8m8 4a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Toggle02RightIcon.displayName = 'Toggle02RightIcon'
Toggle02RightIcon.defaultProps = iconDefaultProps

export type { Toggle02RightIconProps }
export { Toggle02RightIcon }
