import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TerminalIconProps = IconProps

const TerminalIcon = forwardRef<SVGSVGElement, TerminalIconProps>(
  (props: TerminalIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m4 17 6-6-6-6m8 14h8"
        fill="none"
      ></path>
    </Icon>
  ),
)

TerminalIcon.displayName = 'TerminalIcon'
TerminalIcon.defaultProps = iconDefaultProps

export type { TerminalIconProps }
export { TerminalIcon }
