/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Key, SWRConfiguration } from 'swr'
import type { GetJwtDTO } from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getJWT = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<GetJwtDTO>(
    { url: `/courier/jwt`, method: 'GET' },
    options,
  )
}

export const getGetJWTKey = () => [`/courier/jwt`] as const

export type GetJWTQueryResult = NonNullable<Awaited<ReturnType<typeof getJWT>>>
export type GetJWTQueryError = ErrorType<unknown>

export const useGetJWT = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof getJWT>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetJWTKey() : null))
  const swrFn = () => getJWT(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
