import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type SunSetting02IconProps = IconProps

const SunSetting02Icon = forwardRef<SVGSVGElement, SunSetting02IconProps>(
  (props: SunSetting02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 16.5H2M20 20H4m8-17v2m-8 8H2m4.314-5.686L4.9 5.9m12.786 1.414L19.1 5.9M22 13h-2M7 13a5 5 0 0 1 10 0"
        fill="none"
      ></path>
    </Icon>
  ),
)

SunSetting02Icon.displayName = 'SunSetting02Icon'
SunSetting02Icon.defaultProps = iconDefaultProps

export type { SunSetting02IconProps }
export { SunSetting02Icon }
