import React from 'react'
import styled from 'styled-components'
import { MenuItems, MenuPopover, MenuPopoverProps } from '@reach/menu-button'
import {
  positionDefault,
  positionMatchWidth,
  positionRight,
} from '@reach/popover'

import { Card } from '../CardNext'
import { MenuDivider } from './MenuDivider'
import { Spacer } from '../SpacerNext'
import { toRem } from '../utils'

const StyledCard = styled(Card)({
  minWidth: toRem(240),
  padding: 0,

  '& > [role="menu"]': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },

  '&:not([data-has-footer], [data-has-header]) [data-gourmet-menu-item]:first-child':
    {
      borderTopLeftRadius: toRem(8),
      borderTopRightRadius: toRem(8),
    },

  '&:not([data-has-footer], [data-has-header]) [data-gourmet-menu-item]:last-child':
    {
      borderBottomLeftRadius: toRem(8),
      borderBottomRightRadius: toRem(8),
    },
})

type MenuListProps = Omit<MenuPopoverProps, 'position'> &
  React.HTMLAttributes<HTMLDivElement> & {
    /**
     * The content, if any, to be displayed at the bottom of the list.
     */
    footer?: React.ReactNode | null
    /**
     * The content, if any, to be displayed at the top of the list.
     */
    header?: React.ReactNode | null
    /**
     * Used to control the positioning of the popup displaying the list.
     */
    position?: 'default' | 'matchWidth' | 'right'
  }

/**
 * The `<MenuList>` component contains all of the menu items and links.
 *
 * @example
 * <Menu>
 *   <MenuButton>Menu</MenuButton>
 *   <MenuList>
 *     <MenuItem onSelect={onSelectFn} icon={<AlarmClockCheckIcon />} label="Menu Item 1" />
 *     <MenuLink href="https://dash.vori.com" label="Menu Item 2" />
 *   </MenuList>
 * </Menu>
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/}
 */
const MenuList = React.forwardRef<HTMLDivElement, MenuListProps>(
  function MenuList(
    { children, footer, header, position, ...props }: MenuListProps,
    ref,
  ): JSX.Element {
    return (
      <MenuPopover
        {...props}
        data-gourmet-menu-list=""
        position={
          position === 'default'
            ? positionDefault
            : position === 'right'
              ? positionRight
              : position === 'matchWidth'
                ? positionMatchWidth
                : positionDefault
        }
        ref={ref}
      >
        <Spacer size="space.050" />
        <StyledCard
          {...(footer != null && { 'data-has-footer': '' })}
          {...(header != null && { 'data-has-header': '' })}
          alignItems="flex-start"
          direction="column"
          justifyContent="flex-start"
        >
          {header != null && (
            <>
              {header}
              <MenuDivider />
            </>
          )}
          <MenuItems>{children}</MenuItems>
          {footer != null && (
            <>
              <MenuDivider />
              {footer}
            </>
          )}
        </StyledCard>
      </MenuPopover>
    )
  },
)

MenuList.displayName = 'MenuList'

export { MenuList }
export type { MenuListProps }
