import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowsUpIconProps = IconProps

const ArrowsUpIcon = forwardRef<SVGSVGElement, ArrowsUpIconProps>(
  (props: ArrowsUpIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 20V4m0 0L3 8m4-4 4 4m6 12V9m0 0-4 4m4-4 4 4"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowsUpIcon.displayName = 'ArrowsUpIcon'
ArrowsUpIcon.defaultProps = iconDefaultProps

export type { ArrowsUpIconProps }
export { ArrowsUpIcon }
