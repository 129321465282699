import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type UploadCloud02IconProps = IconProps

const UploadCloud02Icon = forwardRef<SVGSVGElement, UploadCloud02IconProps>(
  (props: UploadCloud02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8 16 4-4m0 0 4 4m-4-4v9m8-4.257A5.5 5.5 0 0 0 16.5 7a.62.62 0 0 1-.534-.302 7.5 7.5 0 1 0-11.78 9.096"
        fill="none"
      ></path>
    </Icon>
  ),
)

UploadCloud02Icon.displayName = 'UploadCloud02Icon'
UploadCloud02Icon.defaultProps = iconDefaultProps

export type { UploadCloud02IconProps }
export { UploadCloud02Icon }
