import React, { cloneElement, forwardRef } from 'react'
import styled from 'styled-components'

import {
  AlertCircleIcon,
  AlertCircleIconProps,
  iconDefaultProps,
} from '@vori/gourmet-icons'

import Tooltip, { TooltipDefaultProps, TooltipProps } from '../Tooltip'

type InfoProps = React.HTMLAttributes<HTMLDivElement> & {
  icon?: React.ReactElement | null
  iconProps?: AlertCircleIconProps
  tooltipProps?: TooltipProps
}

const defaultProps: Partial<InfoProps> = {
  ...TooltipDefaultProps,
  icon: <AlertCircleIcon />,
  iconProps: iconDefaultProps,
  className: '',
}

/**
 * @deprecated Use `<Hint>` instead.
 */
const StyledInfo = styled(
  forwardRef<HTMLDivElement, InfoProps>(function Info(
    { icon, iconProps, tooltipProps, ...props }: InfoProps,
    ref,
  ) {
    return (
      <Tooltip {...tooltipProps} {...props} ref={ref}>
        {icon ? (
          cloneElement(
            icon,
            {
              'aria-hidden': true,
              ...iconProps,
            },
            null,
          )
        ) : (
          <AlertCircleIcon {...iconProps} />
        )}
      </Tooltip>
    )
  }),
)<InfoProps>`
  cursor: help;
`

StyledInfo.displayName = 'Info'
StyledInfo.defaultProps = defaultProps

export type { InfoProps }
export { defaultProps as InfoDefaultProps }
export default StyledInfo
