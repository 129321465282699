import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type BluetoothSignalIconProps = IconProps

const BluetoothSignalIcon = forwardRef<SVGSVGElement, BluetoothSignalIconProps>(
  (props: BluetoothSignalIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m2 7 12 10-6 5V2l6 5L2 17M20.145 6.5a9.386 9.386 0 0 1 1.769 5.5 9.386 9.386 0 0 1-1.77 5.5M17 8.857A5.48 5.48 0 0 1 17.986 12 5.475 5.475 0 0 1 17 15.143"
        fill="none"
      ></path>
    </Icon>
  ),
)

BluetoothSignalIcon.displayName = 'BluetoothSignalIcon'
BluetoothSignalIcon.defaultProps = iconDefaultProps

export type { BluetoothSignalIconProps }
export { BluetoothSignalIcon }
