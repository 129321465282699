function pxToEm(value: number, baseUnit: number): number
function pxToEm(value: string, baseUnit: number): string
function pxToEm(value: number | string, baseUnit: number): number | string {
  if (typeof value === 'number') {
    return value / baseUnit
  }

  return `${Number(value.replace('px', '')) / baseUnit}em`
}

function pxToRem(value: number, baseUnit: number): number
function pxToRem(value: string, baseUnit: number): string
function pxToRem(value: number | string, baseUnit: number): number | string {
  if (typeof value === 'number') {
    return value / baseUnit
  }

  return `${Number(value.replace('px', '')) / baseUnit}rem`
}

function remToPx(value: number, baseUnit: number): number
function remToPx(value: string, baseUnit: number): string
function remToPx(value: number | string, baseUnit: number): number | string {
  if (typeof value === 'number') {
    return value * baseUnit
  }

  return `${Number(value.replace('rem', '')) * baseUnit}px`
}

function emToPx(value: number, baseUnit: number): number
function emToPx(value: string, baseUnit: number): string
function emToPx(value: number | string, baseUnit: number): number | string {
  if (typeof value === 'number') {
    return value * baseUnit
  }

  return `${Number(value.replace('em', '')) * baseUnit}px`
}

function remToEm(value: number): number
function remToEm(value: string): string
function remToEm(value: number | string): number | string {
  if (typeof value === 'number') {
    return value
  }

  return `${Number(value.replace('rem', ''))}em`
}

function emToRem(value: number): number
function emToRem(value: string): string
function emToRem(value: number | string): number | string {
  if (typeof value === 'number') {
    return value
  }

  return `${Number(value.replace('em', ''))}rem`
}

function hexToRgba(hex: string, alpha = 1): string {
  const [r, g, b] = (hex.match(/\w\w/g) || []).map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export { emToPx, emToRem, hexToRgba, pxToEm, pxToRem, remToEm, remToPx }
