import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Building04IconProps = IconProps

const Building04Icon = forwardRef<SVGSVGElement, Building04IconProps>(
  (props: Building04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 7h5m-5 4h5m-5 4h5m3.5 6V6.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C16.48 3 15.92 3 14.8 3H9.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C6 4.52 6 5.08 6 6.2V21m14 0H4"
        fill="none"
      ></path>
    </Icon>
  ),
)

Building04Icon.displayName = 'Building04Icon'
Building04Icon.defaultProps = iconDefaultProps

export type { Building04IconProps }
export { Building04Icon }
