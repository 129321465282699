import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudRaining02IconProps = IconProps

const CloudRaining02Icon = forwardRef<SVGSVGElement, CloudRaining02IconProps>(
  (props: CloudRaining02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 12v8m-8-8v8m4-6v8m8-6.758a4.5 4.5 0 0 0-2.08-8.223 6.002 6.002 0 0 0-11.84 0A4.5 4.5 0 0 0 4 15.242"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudRaining02Icon.displayName = 'CloudRaining02Icon'
CloudRaining02Icon.defaultProps = iconDefaultProps

export type { CloudRaining02IconProps }
export { CloudRaining02Icon }
