import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

export type CourierToken = {
  exp: number
  iat: number
  jti: string
  scope: string
  tenant_id: string
  tenant_scope: string
}

export function decodeToken(token: string): CourierToken {
  return jwtDecode<CourierToken>(token)
}

export function isTokenExpired(token: string | null): boolean {
  return token ? decodeToken(token).exp <= dayjs(new Date()).unix() : true
}
