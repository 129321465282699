import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Target02IconProps = IconProps

const Target02Icon = forwardRef<SVGSVGElement, Target02IconProps>(
  (props: Target02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12h-4M6 12H2m10-6V2m0 20v-4m8-6a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Target02Icon.displayName = 'Target02Icon'
Target02Icon.defaultProps = iconDefaultProps

export type { Target02IconProps }
export { Target02Icon }
