import {
  addDays,
  differenceInCalendarWeeks,
  eachDayOfInterval,
  endOfMonth,
  isAfter,
  isBefore,
  isFuture,
  isLastDayOfMonth,
  isPast,
  isSameDay,
  isThisMonth,
  isToday,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
} from 'date-fns'

import { CalendarDate, CalendarDateOptions } from './types'
import { DAYS_IN_WEEK, TODAY } from './constants'

const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  year: 'numeric',
})

export function formatMonth(date: Date): string | null {
  return (
    dateFormat.formatToParts(date).find(({ type }) => type === 'month')
      ?.value || null
  )
}

export function formatYear(date: Date): string | null {
  return (
    dateFormat.formatToParts(date).find(({ type }) => type === 'year')?.value ||
    null
  )
}

export function sortDates(dates: Array<Date>): Array<Date> {
  return dates.sort((dateA, dateB) => dateA.getTime() - dateB.getTime())
}

export function getRangeFromSelectedDates(
  selectedDates: Array<Date>,
): Array<Date> {
  const sortedSelectedDates = sortDates(selectedDates)

  return sortedSelectedDates.length > 0
    ? eachDayOfInterval({
        start: sortedSelectedDates[0],
        end: sortedSelectedDates.slice(-1)[0],
      })
    : []
}

export function isWithinRangeOfSelectedDates(
  date: Date,
  selectedDates: Array<Date>,
): boolean {
  if (
    selectedDates.length <= 1 ||
    !selectedDates.some((date) => date instanceof Date)
  ) {
    return false
  }

  const sortedSelectedDates = sortDates(selectedDates)

  return sortedSelectedDates.length > 0
    ? isWithinInterval(date, {
        start: sortedSelectedDates[0],
        end: sortedSelectedDates.slice(-1)[0],
      })
    : false
}

export function createCalendarDateFromDateObject(
  date: Date,
  options: CalendarDateOptions,
): Omit<CalendarDate, ''> {
  return {
    date: date,
    isAfterToday: isFuture(date),
    isBeforeToday: isPast(date),
    isDisabled:
      options.disabledDates.find((disabledDate) =>
        isSameDay(disabledDate, date),
      ) !== undefined ||
      (options.disablePastDates && !isToday(date) && isBefore(date, TODAY)) ||
      (options.disableFutureDates && !isToday(date) && isAfter(date, TODAY)) ||
      (options.disableDatesAfter !== undefined &&
        isAfter(date, options.disableDatesAfter)) ||
      (options.disableDatesBefore !== undefined &&
        isBefore(date, options.disableDatesBefore)),
    isFirstDateSelected:
      options.selectedDates[0]?.toLocaleDateString() ===
      date.toLocaleDateString(),
    isFirstDateSelectedFromCurrentMonth:
      options.selectedDates
        .filter((selectedDate) => selectedDate.getMonth() === options.month)[0]
        ?.toLocaleDateString() === date.toLocaleDateString(),
    isFirstDayOfMonth: date.getDate() === 1,
    isFromCurrentMonth: isThisMonth(date),
    isInSelectedRange:
      options.rangeSelection &&
      isWithinRangeOfSelectedDates(date, options.selectedDates),
    isLastDateSelected:
      options.selectedDates.slice(-1)[0]?.toLocaleDateString() ===
      date.toLocaleDateString(),
    isLastDateSelectedFromCurrentMonth:
      options.selectedDates
        .filter((selectedDate) => selectedDate.getMonth() === options.month)
        .slice(-1)[0]
        ?.toLocaleDateString() === date.toLocaleDateString(),
    isLastDayOfMonth: isLastDayOfMonth(date),
    isSelected:
      options.selectedDates.find((selectedDate) =>
        isSameDay(selectedDate, date),
      ) !== undefined,
    isToday: isToday(date),
  }
}

export function createCalendarDatesForMonthAndYear(
  options: CalendarDateOptions,
): CalendarDate[][] {
  const date = new Date(options.year, options.month)
  const startDay = startOfMonth(date)
  const lastDay = endOfMonth(date)

  const startDate = startOfWeek(startDay, {
    weekStartsOn: options.weekStartsOn,
  })

  const rows =
    differenceInCalendarWeeks(lastDay, startDay, {
      weekStartsOn: options.weekStartsOn,
    }) + 1

  return Array.from<undefined>({ length: rows * DAYS_IN_WEEK })
    .map<Date>((_, index) => addDays(startDate, index))
    .reduce<CalendarDate[][]>((dates, _, index, days) => {
      if (index % DAYS_IN_WEEK !== 0) {
        return dates
      }

      return [
        ...dates,
        days
          .slice(index, index + DAYS_IN_WEEK)
          .map((day) => createCalendarDateFromDateObject(day, options)),
      ]
    }, [])
}
