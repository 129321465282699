import { createRouteUtil } from '@vori/dashboard-utils'

const routes = {
  home: {
    root: createRouteUtil('/vendor/home', 'Home'),
  },

  profile: {
    /**
     * Lists all vendor-related account settings.
     */
    root: createRouteUtil('/vendor/profile', 'Profile'),
  },

  products: {
    /**
     * Lists all vendor products.
     */
    root: createRouteUtil('/vendor/product-catalog', 'All Products'),
    /**
     * Displays the create product form.
     */
    create: createRouteUtil('/vendor/product-catalog/create', 'Create Product'),
    /**
     * Displays all information about a specific product.
     */
    detail: createRouteUtil(
      '/vendor/product-catalog/:vendorID/:productID',
      'Product Details',
    ),
    /**
     * Displays the update form for a specific product.
     */
    updateDetails: createRouteUtil(
      '/vendor/product-catalog/:vendorID/:productID/update-details',
      'Update Product Details',
    ),
    /**
     * Displays the update pricing form for a specific product.
     */
    updatePricing: createRouteUtil(
      '/vendor/product-catalog/:vendorID/:productID/update-pricing',
      'Update Product Pricing',
    ),
    /**
     * Displays a product's custom pricing for vendor admins.
     */
    pricing: createRouteUtil(
      '/vendor/product-catalog/:vendorID/:productID/pricing',
      'Product Pricing',
    ),
    /**
     * Displays the confirmation modal to delete a product.
     */
    delete: createRouteUtil(
      '/vendor/product-catalog/:vendorID/:productID/delete',
      'Delete Product',
    ),
    /**
     * Lists all product CSV file uploads made.
     */
    history: createRouteUtil(
      '/vendor/catalog-uploader',
      'Product Catalog Uploader',
    ),
    /**
     * Displays form to upload product CSV files.
     */
    upload: createRouteUtil(
      '/vendor/catalog-uploader',
      'Product Catalog Uploader',
    ),
    /**
     * @description Displays form to upload products for order guide CSV files.
     */
    uploadOrderGuide: createRouteUtil(
      '/vendor/catalog-uploader?uploader-type=order-guide',
      'Order Guide Uploader',
    ),
    /**
     * @description Displays form to upload product catalog.
     */
    uploadCatalog: createRouteUtil(
      '/vendor/catalog-uploader?uploader-type=catalog',
      'Catalog Uploader',
    ),
    /**
     * @description Displays form to upload product catalog.
     */
    uploadInvitationOrderGuide: createRouteUtil(
      '/vendor/catalog-uploader?uploader-type=invitation-order-guide',
      'Invitation Order Guide Uploader',
    ),
  },

  crm: {
    /**
     * Lists all customers.
     */
    root: createRouteUtil('/vendor/customers', 'Customers'),
    all: createRouteUtil('/vendor/customers/all', 'Customers | All'),
    neverInvited: createRouteUtil(
      '/vendor/customers/never-invited',
      'Customers | Never Invited',
    ),
    invitedByNotSignedUp: createRouteUtil(
      '/vendor/customers/not-signed-up',
      'Customers | Not Signed Up',
    ),
    active: createRouteUtil('/vendor/customers/active', 'Customers | Active'),
    noRecentOrders: createRouteUtil(
      '/vendor/customers/no-recent-orders',
      'Customers | No Recent Orders',
    ),
    detail: createRouteUtil(
      '/vendor/customers/detail/:storeID',
      'Customer Detail',
    ),
    individualAdd: createRouteUtil(
      '/vendor/customers/add/single',
      'Add Customer',
    ),
    bulkUpload: createRouteUtil(
      '/vendor/customers/add/bulk',
      'Bulk Add Customers',
    ),
    inviteCustomers: createRouteUtil(
      '/vendor/customers/invite',
      'Invite Customers',
    ),
  },

  promotions: {
    /**
     * Lists all promotions.
     */
    root: createRouteUtil('/vendor/promotions', 'Promotions'),
    /**
     * Lists all active promotions.
     */
    active: createRouteUtil('/vendor/promotions/active', 'Active Promotions'),
    /**
     * Lists all drafted promotions.
     */
    drafts: createRouteUtil('/vendor/promotions/drafts', 'Drafted Promotions'),
    /**
     * Lists all expired promotions.
     */
    history: createRouteUtil(
      '/vendor/promotions/history',
      'Expired Promotions',
    ),
    /**
     * Displays a specific promotion.
     */
    detail: createRouteUtil('/vendor/promotions/:id/view', 'Promotion'),
    /**
     * Displays a form to create a new promotion.
     */
    create: createRouteUtil('/vendor/promotions/create', 'Create Promotion'),
    /**
     * Displays a form to edit a specific promotion.
     */
    edit: createRouteUtil('/vendor/promotions/:id/edit', 'Edit Promotion'),
    /**
     * Displays a form to duplicate a specific promotion.
     */
    duplicate: createRouteUtil(
      '/vendor/promotions/:id/duplicate',
      'Duplicate Promotion',
    ),
    /**
     * Displays products for a specific deal
     */
    deals: createRouteUtil(
      '/vendor/promotions/:promotionID/deals/:dealID',
      'Promotion Deals',
    ),
  },

  orders: {
    /**
     * Lists all orders.
     */
    root: createRouteUtil('/vendor/orders/:filter?', 'Orders'),
    /**
     * Lists all orders that require an action.
     */
    needsAction: createRouteUtil('/vendor/orders/needs-action', 'Orders'),
    /**
     * Lists all received orders.
     */
    received: createRouteUtil('/vendor/orders/received', 'Orders'),
    /**
     * Lists all confirmed orders.
     */
    confirmed: createRouteUtil('/vendor/orders/confirmed', 'Orders'),
    /**
     * Lists all orders from a specific store.
     */
    store: createRouteUtil(
      '/vendor/stores/:storeID/orders/:filter',
      'Store Orders',
    ),
    /**
     * Lists all orders from a specific store that require an action.
     */
    storeNeedsAction: createRouteUtil(
      '/vendor/stores/:storeID/orders/needs-action',
      'Store Orders',
    ),
    /**
     * Lists all received orders from a specific store.
     */
    storeReceived: createRouteUtil(
      '/vendor/stores/:storeID/orders/received',
      'Store Orders',
    ),
    /**
     * Lists all confirmed orders from a specific store.
     */
    storeConfirmed: createRouteUtil(
      '/vendors/stores/:storeID/orders/confirmed',
      'Store Orders',
    ),
  },
}

export { routes }
