import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Droplets01IconProps = IconProps

const Droplets01Icon = forwardRef<SVGSVGElement, Droplets01IconProps>(
  (props: Droplets01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 16a6 6 0 0 1-12 0c0-4.314 6-14 6-14s6 9.686 6 14ZM8 9a3 3 0 1 1-6 0c0-2.157 3-7 3-7s3 4.843 3 7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Droplets01Icon.displayName = 'Droplets01Icon'
Droplets01Icon.defaultProps = iconDefaultProps

export type { Droplets01IconProps }
export { Droplets01Icon }
