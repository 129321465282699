import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Lock04IconProps = IconProps

const Lock04Icon = forwardRef<SVGSVGElement, Lock04IconProps>(
  (props: Lock04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.101 10H7V8a5 5 0 0 1 10 0v2h-.101M12 14v2m7-1a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Lock04Icon.displayName = 'Lock04Icon'
Lock04Icon.defaultProps = iconDefaultProps

export type { Lock04IconProps }
export { Lock04Icon }
