import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MessageSquare01IconProps = IconProps

const MessageSquare01Icon = forwardRef<SVGSVGElement, MessageSquare01IconProps>(
  (props: MessageSquare01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 7.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.28 3 6.12 3 7.8 3h8.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C21 5.28 21 6.12 21 7.8v5.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 18 17.88 18 16.2 18H9.684c-.624 0-.936 0-1.235.061a2.997 2.997 0 0 0-.762.267c-.27.14-.514.334-1.002.724L4.3 20.96c-.416.333-.624.5-.8.5a.5.5 0 0 1-.39-.188C3 21.135 3 20.868 3 20.336V7.8Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MessageSquare01Icon.displayName = 'MessageSquare01Icon'
MessageSquare01Icon.defaultProps = iconDefaultProps

export type { MessageSquare01IconProps }
export { MessageSquare01Icon }
