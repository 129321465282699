import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MusicNote02IconProps = IconProps

const MusicNote02Icon = forwardRef<SVGSVGElement, MusicNote02IconProps>(
  (props: MusicNote02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18V5.589c0-.857 0-1.286.18-1.544a1 1 0 0 1 .674-.416c.312-.046.695.145 1.462.529L18 6m-6 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MusicNote02Icon.displayName = 'MusicNote02Icon'
MusicNote02Icon.defaultProps = iconDefaultProps

export type { MusicNote02IconProps }
export { MusicNote02Icon }
