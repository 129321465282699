import { Flex, Loader, Spacer, Text } from '@vori/gourmet-components'
import React from 'react'

function DataGridLoadingCellRenderer(): JSX.Element {
  return (
    <Flex centerY fullWidth>
      <Spacer inline />
      <Loader size="medium" />
      <Spacer size="tiny" inline />
      <Text variant="secondary">Loading Row</Text>
      <Spacer inline />
    </Flex>
  )
}

export { DataGridLoadingCellRenderer }
