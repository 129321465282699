import { isSameDay } from 'date-fns'
import { CalendarTime } from '../CalendarNext'

export function stringValueToArrayOfDates(
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'],
): Array<Date> {
  return value ? `${value}`.split(',').map((date) => new Date(date)) : []
}

export function getSelectedDaysFromValue(
  value: React.InputHTMLAttributes<HTMLInputElement>['value'],
): Array<Date> {
  return stringValueToArrayOfDates(value).filter(
    (date, index, dates) =>
      dates.findIndex((dateToCompare) => isSameDay(dateToCompare, date)) ===
      index,
  )
}

export function getSelectedTimesFromValue(
  value: React.InputHTMLAttributes<HTMLInputElement>['value'],
): Array<CalendarTime> {
  return stringValueToArrayOfDates(value).map((date) => date.getTime())
}
