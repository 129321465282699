import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Monitor04IconProps = IconProps

const Monitor04Icon = forwardRef<SVGSVGElement, Monitor04IconProps>(
  (props: Monitor04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 17v4H9v-4m-3.8 0h13.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C22 15.48 22 14.92 22 13.8V6.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 3 19.92 3 18.8 3H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 4.52 2 5.08 2 6.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 17 4.08 17 5.2 17Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Monitor04Icon.displayName = 'Monitor04Icon'
Monitor04Icon.defaultProps = iconDefaultProps

export type { Monitor04IconProps }
export { Monitor04Icon }
