import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CurrencyRubleIconProps = IconProps

const CurrencyRubleIcon = forwardRef<SVGSVGElement, CurrencyRubleIconProps>(
  (props: CurrencyRubleIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.5 11.5h6a4 4 0 0 0 0-8h-6v8Zm0 0h-2m7 4h-7M8.5 4v16.5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CurrencyRubleIcon.displayName = 'CurrencyRubleIcon'
CurrencyRubleIcon.defaultProps = iconDefaultProps

export type { CurrencyRubleIconProps }
export { CurrencyRubleIcon }
