import React from 'react'
import styled, { CSSObject } from 'styled-components'

import Flex, { FlexProps } from '../Flex'
import { toRem } from '../utils'

function styles(): CSSObject {
  return {
    padding: toRem(24),
    overflow: 'scroll',
  }
}

const StyledSidePanelContent = styled(Flex)(styles)

type Props = FlexProps

/**
 * Provides basic styling for rendering the contents of a `<SidePanel>` component.
 *
 * @example
 * function ExampleSidePanel(): React.ReactElement {
 *   const { ref, ...sidePanel } = useSidePanel()
 *
 *   return (
 *     <SidePanel ref={ref} {...sidePanel}>
 *       <SidePanelContent>
 *         <Text>This is the side panel content</Text>
 *       </SidePanelContent>
 *     </SidePanel>
 *   )
 * }
 */
const SidePanelContent = React.forwardRef<HTMLDivElement, Props>(
  function SidePanelContent({ children, ...props }: Props, ref): JSX.Element {
    return (
      <StyledSidePanelContent
        column
        {...props}
        data-gourmet-side-panel-content=""
        flex={1}
        fullWidth
        ref={ref}
      >
        {children}
      </StyledSidePanelContent>
    )
  },
)

SidePanelContent.displayName = 'SidePanelContent'
SidePanelContent.defaultProps = {}

export { SidePanelContent }
export type { Props as SidePanelContentProps }
