import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TransformIconProps = IconProps

const TransformIcon = forwardRef<SVGSVGElement, TransformIconProps>(
  (props: TransformIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 7v10M5 7v10M17 5H7m10 14H7M4.6 7h.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C7 6.24 7 5.96 7 5.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C6.24 3 5.96 3 5.4 3h-.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C3 3.76 3 4.04 3 4.6v.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C3.76 7 4.04 7 4.6 7Zm0 14h.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C7 20.24 7 19.96 7 19.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C6.24 17 5.96 17 5.4 17h-.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C3 17.76 3 18.04 3 18.6v.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C3.76 21 4.04 21 4.6 21Zm14-14h.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C21 6.24 21 5.96 21 5.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C20.24 3 19.96 3 19.4 3h-.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C17 3.76 17 4.04 17 4.6v.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C17.76 7 18.04 7 18.6 7Zm0 14h.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C21 20.24 21 19.96 21 19.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C20.24 17 19.96 17 19.4 17h-.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C17 17.76 17 18.04 17 18.6v.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C17.76 21 18.04 21 18.6 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

TransformIcon.displayName = 'TransformIcon'
TransformIcon.defaultProps = iconDefaultProps

export type { TransformIconProps }
export { TransformIcon }
