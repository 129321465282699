import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Expand01IconProps = IconProps

const Expand01Icon = forwardRef<SVGSVGElement, Expand01IconProps>(
  (props: Expand01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m14 10 7-7m0 0h-6m6 0v6m-11 5-7 7m0 0h6m-6 0v-6"
        fill="none"
      ></path>
    </Icon>
  ),
)

Expand01Icon.displayName = 'Expand01Icon'
Expand01Icon.defaultProps = iconDefaultProps

export type { Expand01IconProps }
export { Expand01Icon }
