import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type DistributeSpacingVerticalIconProps = IconProps

const DistributeSpacingVerticalIcon = forwardRef<
  SVGSVGElement,
  DistributeSpacingVerticalIconProps
>((props: DistributeSpacingVerticalIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 3H3m18 18H3m2-9c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C6.602 9 7.068 9 8 9h8c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C19 10.602 19 11.068 19 12c0 .932 0 1.398-.152 1.765a2 2 0 0 1-1.083 1.083C17.398 15 16.932 15 16 15H8c-.932 0-1.398 0-1.765-.152a2 2 0 0 1-1.083-1.083C5 13.398 5 12.932 5 12Z"
      fill="none"
    ></path>
  </Icon>
))

DistributeSpacingVerticalIcon.displayName = 'DistributeSpacingVerticalIcon'
DistributeSpacingVerticalIcon.defaultProps = iconDefaultProps

export type { DistributeSpacingVerticalIconProps }
export { DistributeSpacingVerticalIcon }
