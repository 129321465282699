import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowCircleUpLeftIconProps = IconProps

const ArrowCircleUpLeftIcon = forwardRef<
  SVGSVGElement,
  ArrowCircleUpLeftIconProps
>((props: ArrowCircleUpLeftIconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 15V9m0 0h6M9 9l6 6m7-3c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="none"
    ></path>
  </Icon>
))

ArrowCircleUpLeftIcon.displayName = 'ArrowCircleUpLeftIcon'
ArrowCircleUpLeftIcon.defaultProps = iconDefaultProps

export type { ArrowCircleUpLeftIconProps }
export { ArrowCircleUpLeftIcon }
