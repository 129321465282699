import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CornerLeftDownIconProps = IconProps

const CornerLeftDownIcon = forwardRef<SVGSVGElement, CornerLeftDownIconProps>(
  (props: CornerLeftDownIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 4h-3.4c-3.36 0-5.04 0-6.324.654a6 6 0 0 0-2.622 2.622C8 8.56 8 10.24 8 13.6V20m0 0 5-5m-5 5-5-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

CornerLeftDownIcon.displayName = 'CornerLeftDownIcon'
CornerLeftDownIcon.defaultProps = iconDefaultProps

export type { CornerLeftDownIconProps }
export { CornerLeftDownIcon }
