import React from 'react'

import { CurrencyInputProps } from './types'
import { Input } from '../InputNext'
import { useCurrencyInput } from './hook'

/**
 * The `<CurrencyInput />` provides the ability to enter values formatted as
 * US currency, e.g. "$1.99".
 *
 * @example
 * <CurrencyInput withSymbol />
 */
export const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  CurrencyInputProps
>(function CurrencyInput(props: CurrencyInputProps, ref): JSX.Element {
  const inputProps = useCurrencyInput(props)
  return <Input {...inputProps} ref={ref} />
})
