import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CloudRaining01IconProps = IconProps

const CloudRaining01Icon = forwardRef<SVGSVGElement, CloudRaining01IconProps>(
  (props: CloudRaining01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 15.242a4.5 4.5 0 0 0-2.08-8.223 6.002 6.002 0 0 0-11.84 0A4.5 4.5 0 0 0 4 15.242M8 18v2m0-8v2m8 4v2m0-8v2m-4 6v2m0-8v2"
        fill="none"
      ></path>
    </Icon>
  ),
)

CloudRaining01Icon.displayName = 'CloudRaining01Icon'
CloudRaining01Icon.defaultProps = iconDefaultProps

export type { CloudRaining01IconProps }
export { CloudRaining01Icon }
