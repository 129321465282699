import { AddressComponents, SubmissionMethod } from '@vori/gql-dashboard'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'

import { getTimeZone } from './date'
import Decimal from 'decimal.js'

/**
 * Formats the given `value` to a valid currency number.
 */
export const toCurrency = (value: number): string => {
  if (value == null || isNaN(value)) {
    return ''
  }

  let formattedValue = ''

  try {
    formattedValue = new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'currency',
    }).format(value)
  } catch (err) {
    console.error(err)
    return ''
  }

  return formattedValue
}

export const toFractional = (value?: number | string | null): number =>
  value != null
    ? Math.round(
        (typeof value === 'string' ? Number.parseFloat(value) : value) * 100,
      )
    : 0

export const fractionalToFloat = (value?: number | string | null): number =>
  value != null
    ? (typeof value === 'string' ? Number.parseFloat(value) : value) / 100
    : 0

export const toCurrencyFromFractional = (
  value?: number | string | null,
): string => toCurrency(fractionalToFloat(value))

export const toShortDate = (
  value: string | Date,
  options?: { timezone?: string },
): string | null => {
  if (!value) {
    return null
  }

  let date = null

  try {
    date = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: options?.timezone || getTimeZone(),
    }).format(new Date(value))
  } catch (err) {
    console.error(err)
  }

  return date
}

export const toShortDateWithoutYear = (
  value: string | Date,
  options?: { timezone?: string },
): string | null => {
  if (!value) {
    return null
  }

  let date = null

  try {
    date = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      timeZone: options?.timezone || getTimeZone(),
    }).format(new Date(value))
  } catch (err) {
    console.error(err)
  }

  return date
}

export const toNumericDate = (
  value: string | Date,
  options?: { timezone?: string },
): string | null => {
  if (!value) {
    return null
  }

  let date = null

  try {
    date = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      timeZone: options?.timezone || getTimeZone(),
    }).format(new Date(value))
  } catch (err) {
    console.error(err)
  }

  return date
}

export const toTime = (
  value: string | Date,
  options?: { timezone?: string; includeMinutes?: boolean },
): string | null => {
  let date = null

  try {
    date = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: options?.includeMinutes === false ? undefined : 'numeric',
      timeZone: options?.timezone || getTimeZone(),
    }).format(new Date(value))

    if (options?.includeMinutes === false) {
      date = date.split(' ').join('')
    }
  } catch (err) {
    console.error(err)
  }

  return date
}

export const toShortDateWithTime = (
  value: string | Date,
  options?: { timezone?: string },
): string | null => {
  return `${toShortDate(value, options)}, ${toTime(value, options)}`
}

export const toSafeQuotes = (value?: string | null): string =>
  value?.replace(/"([^"]+)"/g, '“$1”').replace(/"([^']+)"/g, '‘$1’') || ''

export function toPrice(value?: string | number | null): string {
  return value != null ? toCurrencyFromFractional(value) : 'N/A'
}

export function toJoinedWithDot(
  values: Array<string | null | undefined>,
): string {
  return compact(values).join(' • ')
}

export function displayOrderSubmissionMethod(method: SubmissionMethod): string {
  switch (method) {
    case SubmissionMethod.Email:
      return 'Email'
    case SubmissionMethod.Qbc:
      return 'QuickBooks Connect'
    case SubmissionMethod.Qbe:
      return 'QuickBooks Enterprise'
    case SubmissionMethod.Sps:
      return 'SPS'
    case SubmissionMethod.TrueCommerce:
      return 'True Commerce'
    default:
      break
  }
  return 'N/A'
}

export const convert24hrTo12hr = (
  time: string,
  options?: { timezone?: string },
): string =>
  new Date('1970-01-01T' + time + 'Z').toLocaleTimeString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
    timeZone: options?.timezone || getTimeZone(),
  })

export function toReadableEnum(enumValue?: string): string {
  return startCase(enumValue?.replaceAll('_', ' ').toLocaleLowerCase() || '')
}

export function toAddressLines(
  addressComponents?: AddressComponents | null,
): Array<string> {
  return addressComponents
    ? compact([addressComponents.addressLine1, addressComponents.addressLine2])
    : []
}

/**
 * @see {@link https://www.ag-grid.com/angular-data-grid/filter-provided-simple/#reference-DateFilterModel-dateFrom}
 */
export function toAgGridDate(
  value: Date | string,
  options?: { timezone?: string },
): string {
  const dateValue = typeof value === 'string' ? new Date(value) : value

  const [date, time] = new Date(
    dateValue.toLocaleString('en-US', {
      timeZone: options?.timezone || getTimeZone(),
    }),
  )
    .toISOString()
    .split('T')

  return `${date} ${time.split('.')[0].split(':').slice(0, 2).join(':')}`
}

export function toFormattedCurrencyAsString(
  value?: string | number | null,
  options?: { negativeFormat?: 'hyphen' | 'parenthesis' | 'none' },
): string {
  const asNumber = Number.parseFloat(`${value}`.replace('$', '') || '0')
  const negativeFormat = options?.negativeFormat || 'parenthesis'

  return asNumber < 0 && negativeFormat !== 'none'
    ? negativeFormat === 'hyphen'
      ? `-${toCurrency(Math.abs(asNumber))}`
      : `(${toCurrency(Math.abs(asNumber))})`
    : toCurrency(Math.abs(asNumber))
}

export function toFormattedCurrencyAsNumber(
  value?: string | number | null,
): number {
  return Number.parseFloat(`${value || '0'}`.replace(/[^\d.-]/g, ''))
}

export function toDateWithTimeZone(
  date: string | Date,
  timeZone: string,
): Date {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone,
    }),
  )
}

export function toObfuscatedPhoneNumber(value: string): string {
  if (value.startsWith('+1')) {
    value = value.slice(2) // Remove the '+1' prefix
  }

  if (value.length === 10) {
    const firstPart = value.substring(0, 3)
    const middlePart = '***'
    const lastPart = value.substring(6, 10)
    return `(${firstPart}) ${middlePart} ${lastPart}`
  }

  return value
}

export function stringToNumber(value: string): number {
  return new Decimal(value).toNumber()
}

export function toPhone(
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'],
  withPrefix?: boolean,
): string {
  if (!value) {
    return ''
  }

  const sanitizedValue = String(value)
    .replace('+1', '')
    .replace(/\D/g, '')
    .padStart(10, '#')

  const lineNumber = sanitizedValue.slice(6, 10).replaceAll('#', '')
  const exchangeCode = sanitizedValue.slice(3, 6).replaceAll('#', '')
  const areaCode = sanitizedValue.slice(0, 3).replaceAll('#', '')

  return areaCode && exchangeCode && lineNumber
    ? `${withPrefix ? '+1 ' : ''}(${areaCode}) ${exchangeCode}-${lineNumber}`
    : exchangeCode && lineNumber
      ? `${exchangeCode}-${lineNumber}`
      : lineNumber
}
