import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type FlipBackwardIconProps = IconProps

const FlipBackwardIcon = forwardRef<SVGSVGElement, FlipBackwardIconProps>(
  (props: FlipBackwardIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 9h13.5a4.5 4.5 0 1 1 0 9H12M3 9l4-4M3 9l4 4"
        fill="none"
      ></path>
    </Icon>
  ),
)

FlipBackwardIcon.displayName = 'FlipBackwardIcon'
FlipBackwardIcon.defaultProps = iconDefaultProps

export type { FlipBackwardIconProps }
export { FlipBackwardIcon }
