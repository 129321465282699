import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TextInputIconProps = IconProps

const TextInputIcon = forwardRef<SVGSVGElement, TextInputIconProps>(
  (props: TextInputIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 7H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 8.52 2 9.08 2 10.2v3.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 17 4.08 17 5.2 17H13m4-10h1.8c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C22 8.52 22 9.08 22 10.2v3.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C20.48 17 19.92 17 18.8 17H17m0 4V3m2.5 0h-5m5 18h-5"
        fill="none"
      ></path>
    </Icon>
  ),
)

TextInputIcon.displayName = 'TextInputIcon'
TextInputIcon.defaultProps = iconDefaultProps

export type { TextInputIconProps }
export { TextInputIcon }
