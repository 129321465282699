import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type CodeCircle01IconProps = IconProps

const CodeCircle01Icon = forwardRef<SVGSVGElement, CodeCircle01IconProps>(
  (props: CodeCircle01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m14.5 15 3-3-3-3m-5 0-3 3 3 3M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

CodeCircle01Icon.displayName = 'CodeCircle01Icon'
CodeCircle01Icon.defaultProps = iconDefaultProps

export type { CodeCircle01IconProps }
export { CodeCircle01Icon }
