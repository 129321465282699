import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MouseIconProps = IconProps

const MouseIcon = forwardRef<SVGSVGElement, MouseIconProps>(
  (props: MouseIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 9V6m0 16a7 7 0 0 1-7-7V9a7 7 0 0 1 14 0v6a7 7 0 0 1-7 7Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MouseIcon.displayName = 'MouseIcon'
MouseIcon.defaultProps = iconDefaultProps

export type { MouseIconProps }
export { MouseIcon }
