import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LetterSpacing02IconProps = IconProps

const LetterSpacing02Icon = forwardRef<SVGSVGElement, LetterSpacing02IconProps>(
  (props: LetterSpacing02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 18h20M2 18l3-3m-3 3 3 3m17-3-3-3m3 3-3 3M7 3h10m-5 0v11"
        fill="none"
      ></path>
    </Icon>
  ),
)

LetterSpacing02Icon.displayName = 'LetterSpacing02Icon'
LetterSpacing02Icon.defaultProps = iconDefaultProps

export type { LetterSpacing02IconProps }
export { LetterSpacing02Icon }
