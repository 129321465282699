import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Underline01IconProps = IconProps

const Underline01Icon = forwardRef<SVGSVGElement, Underline01IconProps>(
  (props: Underline01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 4v7a6 6 0 0 1-12 0V4M4 21h16"
        fill="none"
      ></path>
    </Icon>
  ),
)

Underline01Icon.displayName = 'Underline01Icon'
Underline01Icon.defaultProps = iconDefaultProps

export type { Underline01IconProps }
export { Underline01Icon }
