import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type MarkerPin04IconProps = IconProps

const MarkerPin04Icon = forwardRef<SVGSVGElement, MarkerPin04IconProps>(
  (props: MarkerPin04IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 14.286c-1.851.817-3 1.955-3 3.214C2 19.985 6.477 22 12 22s10-2.015 10-4.5c0-1.259-1.149-2.397-3-3.214M18 8c0 4.064-4.5 6-6 9-1.5-3-6-4.936-6-9a6 6 0 1 1 12 0Zm-5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

MarkerPin04Icon.displayName = 'MarkerPin04Icon'
MarkerPin04Icon.defaultProps = iconDefaultProps

export type { MarkerPin04IconProps }
export { MarkerPin04Icon }
