import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type TypeStrikethrough01IconProps = IconProps

const TypeStrikethrough01Icon = forwardRef<
  SVGSVGElement,
  TypeStrikethrough01IconProps
>((props: TypeStrikethrough01IconProps, ref) => (
  <Icon {...props} ref={ref}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 7V6c0-.541.215-1.032.564-1.392M9 20h6m-3-8v8M3 3l18 18M9.5 4H17c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C20 5.602 20 6.068 20 7m-8-3v2.5"
      fill="none"
    ></path>
  </Icon>
))

TypeStrikethrough01Icon.displayName = 'TypeStrikethrough01Icon'
TypeStrikethrough01Icon.defaultProps = iconDefaultProps

export type { TypeStrikethrough01IconProps }
export { TypeStrikethrough01Icon }
