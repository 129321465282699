import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Server02IconProps = IconProps

const Server02Icon = forwardRef<SVGSVGElement, Server02IconProps>(
  (props: Server02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 8h.01M6 16h.01M6 12h12M6 12a4 4 0 0 1 0-8h12a4 4 0 0 1 0 8M6 12a4 4 0 0 0 0 8h12a4 4 0 0 0 0-8"
        fill="none"
      ></path>
    </Icon>
  ),
)

Server02Icon.displayName = 'Server02Icon'
Server02Icon.defaultProps = iconDefaultProps

export type { Server02IconProps }
export { Server02Icon }
