import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type Dotpoints01IconProps = IconProps

const Dotpoints01Icon = forwardRef<SVGSVGElement, Dotpoints01IconProps>(
  (props: Dotpoints01IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 12H9m12-6H9m12 12H9m-4-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

Dotpoints01Icon.displayName = 'Dotpoints01Icon'
Dotpoints01Icon.defaultProps = iconDefaultProps

export type { Dotpoints01IconProps }
export { Dotpoints01Icon }
