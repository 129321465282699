import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type LockUnlocked02IconProps = IconProps

const LockUnlocked02Icon = forwardRef<SVGSVGElement, LockUnlocked02IconProps>(
  (props: LockUnlocked02IconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 11V8a5 5 0 0 1 9.9-1M7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2v-.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C18.72 11 17.88 11 16.2 11H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 13.28 3 14.12 3 15.8v.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21Z"
        fill="none"
      ></path>
    </Icon>
  ),
)

LockUnlocked02Icon.displayName = 'LockUnlocked02Icon'
LockUnlocked02Icon.defaultProps = iconDefaultProps

export type { LockUnlocked02IconProps }
export { LockUnlocked02Icon }
