import { MenuItem } from '@reach/menu-button'
import React from 'react'

function noop(): void {
  void 0
}

interface Props {
  /**
   * Disables the ability to close the popup when clicking on `<PopupClose>`
   */
  disabled?: boolean
}

const PopupClose = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function PopupClose(
  { children, ...props }: React.PropsWithChildren<Props>,
  ref,
) {
  return (
    <MenuItem {...props} data-gourmet-popup-close="" onSelect={noop} ref={ref}>
      {children}
    </MenuItem>
  )
})

PopupClose.displayName = 'PopupClose'
PopupClose.defaultProps = {}

export type { Props as PopupCloseProps }
export { PopupClose }
