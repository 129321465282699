import React from 'react'

import { DisclosureState, DisclosureTriggerProps } from './types'
import { useDisclosureContext } from './DisclosureContext'

/**
 * The `<DisclosureTrigger>` will contain the trigger for opening/closing
 * the `<DisclosureContent>` component.
 *
 * @example
 * <DisclosureTrigger>
 *  {(props, isOpen) => (
 *   <Button
 *     {...props}
 *     asClickable
 *     rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
 *     noPadding
 *   >
 *     Click to reveal
 *   </Button>
 *  )}
 * </DisclosureTrigger>
 */
function DisclosureTrigger({ children }: DisclosureTriggerProps): JSX.Element {
  const { id, isOpen, setState } = useDisclosureContext()

  return (
    <>
      {children(
        {
          'aria-controls': id,
          'aria-expanded': isOpen,
          'data-gourmet-disclosure-trigger': '',
          'data-state': isOpen
            ? DisclosureState.OPEN
            : DisclosureState.COLLAPSED,
          onClick: () => {
            setState(!isOpen)
          },
        },
        isOpen,
      )}
    </>
  )
}

DisclosureTrigger.displayName = 'DisclosureTrigger'
DisclosureTrigger.defaultProps = {}

export { DisclosureTrigger }
