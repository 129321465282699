import { CSSObject } from 'styled-components'
import { foundations } from '@vori/gourmet-tokens'

import { AvatarCoreProps } from './types'
import { PropsToStyledProps, toRem } from '../utils'

export function avatarStyles(
  props: PropsToStyledProps<AvatarCoreProps>,
): CSSObject {
  const height = props.$size
    ? foundations.size[`size.avatar-${props.$size}`]
    : foundations.size['size.avatar-sm']

  const width = props.$size
    ? foundations.size[`size.avatar-${props.$size}`]
    : foundations.size['size.avatar-sm']

  return {
    backgroundColor: foundations.color['color.gray-50'],
    borderRadius: '50%',
    height,
    position: 'relative',
    width,

    img: {
      borderRadius: '50%',
      height: 'auto',
      maxHeight: height,
      maxWidth: width,
      objectFit: 'cover',
      width: 'auto',
    },

    '[data-gourmet-avatar-dot-indicator]': {
      borderColor: foundations.color['color.base-white'],
      borderStyle: 'solid',
      borderWidth: 1.5,
      bottom: 0,
      position: 'absolute',
      right: 0,
    },

    '&[data-size="xs"] [data-gourmet-avatar-dot-indicator]': {
      height: toRem(6),
      width: toRem(6),
    },

    '&[data-size="sm"] [data-gourmet-avatar-dot-indicator]': {
      height: toRem(8),
      width: toRem(8),
    },

    '&[data-size="md"] [data-gourmet-avatar-dot-indicator]': {
      height: toRem(10),
      width: toRem(10),
    },

    '&[data-size="lg"] [data-gourmet-avatar-dot-indicator]': {
      height: toRem(12),
      width: toRem(12),
    },

    '&[data-size="xl"] [data-gourmet-avatar-dot-indicator]': {
      height: toRem(14),
      width: toRem(14),
    },

    '&[data-size="xxl"] [data-gourmet-avatar-dot-indicator]': {
      height: toRem(16),
      width: toRem(16),
    },
  }
}
