import { renderToString } from 'react-dom/server'
import { useClientId } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { CheckIcon, MinusIcon } from '@vori/gourmet-icons'

import { composeRefs, toRem, toTransitions } from '../utils'
import { useControlledState } from '../hooks'

import { Flex } from '../FlexNext'
import { Text } from '../TextNext'
import { VisuallyHiddenInput } from '../VisuallyHiddenInput'

function styles(): CSSObject {
  const CheckIconSVG = encodeURI(
    renderToString(<CheckIcon />).replace(
      'stroke-width="2"',
      'stroke-width="3"',
    ),
  )

  const MinusIconSVG = encodeURI(
    renderToString(<MinusIcon />).replace(
      'stroke-width="2"',
      'stroke-width="3"',
    ),
  )

  return {
    '&': {
      position: 'relative',

      '&[data-full-width]': {
        width: '100%',
      },

      '&:hover [data-gourmet-checkbox-input-control]': {
        backgroundColor: '#EBE9FE',
        borderColor: '#6038EF',
      },

      [[
        '&:hover [data-gourmet-checkbox-input-input]:disabled:checked + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
        '&:hover [data-gourmet-checkbox-input-input]:disabled:indeterminate + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
      ].join(',')]: {
        backgroundColor: '#F4F4F5',
        borderColor: '#D1D1D6',
      },

      '[data-gourmet-checkbox-input-control-container]': {
        cursor: 'pointer',
      },

      '[data-gourmet-checkbox-input-control]': {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderColor: '#D1D1D6',
        borderRadius: toRem(4),
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'inline-flex',
        flexShrink: 0,
        height: toRem(20),
        justifyContent: 'center',
        position: 'relative',
        width: toRem(20),

        transition: toTransitions(
          ['background-color', 'border-color', 'box-shadow'],
          'ease',
        ),

        '&::after': {
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          content: '""',
          display: 'block',
          height: toRem(14),
          width: toRem(14),

          transition: toTransitions(
            ['background-color', 'border-color'],
            'ease',
          ),
        },
      },

      '[data-gourmet-checkbox-input-input]': {
        [[
          '&:checked + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
          '&:indeterminate + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
        ].join(',')]: {
          backgroundColor: '#F4F3FF',
          borderColor: '#6038EF',
        },

        '&:checked + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]::after':
          {
            backgroundImage: `url(data:image/svg+xml;utf8,${CheckIconSVG.replace(
              'currentColor',
              '%236038EF',
            )})`,
          },

        '&:indeterminate + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]::after':
          {
            backgroundImage: `url(data:image/svg+xml;utf8,${MinusIconSVG.replace(
              'currentColor',
              '%236038EF',
            )})`,
          },

        [[
          '&:focus + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
          '&:active:not(:disabled) + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
          '&:focus:checked + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
          '&:active:not(:disabled):checked + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
          '&:focus:indeterminate + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
          '&:active:indeterminate + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
        ].join(',')]: {
          boxShadow: `0 0 0 ${toRem(2)} #F4F3FF, 0 0 0 ${toRem(4)} #6038EF`,
        },

        '&:disabled + [data-gourmet-checkbox-input-control-container]': {
          cursor: 'default',

          '& [data-gourmet-checkbox-input-control]': {
            backgroundColor: '#F4F4F5',
            borderColor: '#D1D1D6',
          },
        },

        '&:disabled + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-label]':
          {
            color: '#3F3F46',
          },

        '&:checked:disabled + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]::after':
          {
            backgroundImage: `url(data:image/svg+xml;utf8,${CheckIconSVG.replace(
              'currentColor',
              '%23D1D1D6',
            )})`,
          },

        '&:indeterminate:disabled + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]::after':
          {
            backgroundImage: `url(data:image/svg+xml;utf8,${MinusIconSVG.replace(
              'currentColor',
              '%23D1D1D6',
            )})`,
          },
      },

      [[
        '&[data-no-focus-ring] [data-gourmet-checkbox-input-control]',
        '&[data-no-focus-ring] [data-gourmet-checkbox-input-input]:active + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
        '&[data-no-focus-ring] [data-gourmet-checkbox-input-input]:active:checked + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
        '&[data-no-focus-ring] [data-gourmet-checkbox-input-input]:focus + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
        '&[data-no-focus-ring] [data-gourmet-checkbox-input-input]:focus:checked + [data-gourmet-checkbox-input-control-container] [data-gourmet-checkbox-input-control]',
        '&[data-no-focus-ring]:focus-within [data-gourmet-checkbox-input-control]',
      ].join(',')]: {
        boxShadow: 'none',
      },
    },
  }
}

const CheckboxInputLabel = styled.label(styles)

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  /**
   * An optional description for this checkbox input element, useful for giving
   * users hints or instruction on how to interact with it.
   */
  description?: React.ReactNode
  /**
   * The ID, if any, of the HTML element used to describe the checkbox.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
   */
  descriptionID?: string
  /**
   * The ID, if any, of the HTML element containing the error message related
   * to the checkbox.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-errormessage}
   */
  errorID?: string
  /**
   * If `true`, the element will take all the available horizontal space.
   */
  fullWidth?: boolean
  /**
   * In addition to the checked and unchecked states, there is a third state a
   * checkbox can be in: indeterminate. This is a state in which it's impossible
   * to say whether the item is toggled on or off.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes}
   */
  indeterminate?: boolean
  /**
   * Denotes the purpose or intention behind the input's value.
   */
  label: React.ReactNode
  /**
   * The ID, if any, of the HTML element used to label the checkbox.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
   */
  labelID?: string
  /**
   * Disables the focus ring that appears when the input is focused. This should
   * never be `true` due to accessibility concerns, unless you intend to use the
   * checkbox within another component that is providing proper styling through
   * `:focus-within`.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/:focus#focus_outline_none}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within}
   */
  noFocusRing?: boolean
  /**
   * If `true`, the input will aligned to the right, as opposed to the left of
   * the label and description.
   */
  withRightAlignedInput?: boolean
}

/**
 * Switch buttons are a common way to allow users to make a single selection
 * from a list of options. Since only one checkbox button can be selected at a
 * time (within the same group), each available choice must be its own
 * item and label.
 *
 * @example
 * <CheckboxInput label="1" name="some_checkbox" value="1" />
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/switch/}
 */
const CheckboxInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(function CheckboxInput(
  {
    className,
    description,
    descriptionID: controlledDescriptionID,
    errorID,
    fullWidth,
    id: controlledID,
    indeterminate,
    label,
    labelID,
    noFocusRing,
    onChange: controlledOnChange,
    withRightAlignedInput,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [defaultID] = useClientId('gourmet-switch-input')
  const [defaultDescriptionID] = useClientId('gourmet-switch-input-description')

  const [inputID] = useControlledState({
    componentName: 'CheckboxInput',
    controlledValue: controlledID,
    defaultValue: defaultID,
  })

  const [descriptionID] = useControlledState({
    componentName: 'CheckboxInput',
    controlledValue: controlledDescriptionID,
    defaultValue: defaultDescriptionID,
  })

  const [elementRef, setElementRef] = React.useState<HTMLInputElement | null>(
    null,
  )

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      /**
       * We explicitly focus the input due to browsers like Safari, which will only accept focus
       * on text-only inputs.
       *
       * @see {@link https://bugs.webkit.org/show_bug.cgi?id=22261}
       */
      event.currentTarget.focus()

      controlledOnChange?.(event)
    },
    [controlledOnChange],
  )

  React.useEffect(() => {
    if (!elementRef) {
      return
    }

    if (elementRef.indeterminate !== indeterminate) {
      elementRef.indeterminate = Boolean(indeterminate)
    }
  })

  return (
    <CheckboxInputLabel
      className={className}
      data-gourmet-checkbox-input=""
      data-value={props.value}
      {...(fullWidth && { 'data-full-width': '' })}
      {...(noFocusRing && { 'data-no-focus-ring': '' })}
    >
      <VisuallyHiddenInput
        {...props}
        {...(descriptionID && { 'aria-describedby': descriptionID })}
        {...(errorID && {
          'aria-errormessage': errorID,
          'aria-invalid': 'true',
        })}
        {...(labelID && { 'aria-labelledby': labelID })}
        data-gourmet-checkbox-input-input=""
        focusable
        id={inputID}
        onChange={onChange}
        ref={composeRefs([ref, setElementRef])}
        tabIndex={0}
        type="checkbox"
      />

      <Flex
        centerY={!description}
        data-gourmet-checkbox-input-control-container=""
        direction={withRightAlignedInput ? 'row-reverse' : 'row'}
        fullWidth={fullWidth}
        columnGap="space.050"
      >
        <span aria-hidden="true" data-gourmet-checkbox-input-control="" />

        {(label || description) && (
          <Flex direction="column" fullWidth>
            <Text
              data-gourmet-checkbox-input-label=""
              size="text-sm"
              weight="medium"
            >
              {label}
            </Text>
            {description != null && (
              <Text
                data-gourmet-checkbox-input-description=""
                id={defaultDescriptionID}
                size="text-sm"
                variant="secondary"
              >
                {description}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </CheckboxInputLabel>
  )
})

CheckboxInput.displayName = 'CheckboxInput'
CheckboxInput.defaultProps = {}

export { CheckboxInput, styles as CheckboxInputStyles }
export type { Props as CheckboxInputProps }
