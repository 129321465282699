import React, { forwardRef } from 'react'
import { Icon, iconDefaultProps, IconProps } from '../Icon'

type ArrowUpRightIconProps = IconProps

const ArrowUpRightIcon = forwardRef<SVGSVGElement, ArrowUpRightIconProps>(
  (props: ArrowUpRightIconProps, ref) => (
    <Icon {...props} ref={ref}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 17 17 7m0 0H7m10 0v10"
        fill="none"
      ></path>
    </Icon>
  ),
)

ArrowUpRightIcon.displayName = 'ArrowUpRightIcon'
ArrowUpRightIcon.defaultProps = iconDefaultProps

export type { ArrowUpRightIconProps }
export { ArrowUpRightIcon }
